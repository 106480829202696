import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { CaseActions } from '+shared/store/case';
import { isModalOpen, LayoutActions, ModalId } from '+shared/store/layout';
import { PartnerActions } from '+shared/store/partner';
import { 
  getAssignedBattery,
  getClaimBatteryData,
  getClaimBatteryOwnershipQueryErrorStatus,
  getClaimBatteryOwnershipQueryStatus,
  getIdentifyMissingBatteryQueryStatus,
} from '+shared/store/partner/partner.selectors';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { Modal } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { MissingBatteryClaimForm } from '../../components/MissingBatteryClaimForm';
import { MissingBatteryClaimSuccess } from '../../components/MissingBatteryClaimSuccess';
import { MissingBatteryIdentifyForm } from '../../components/MissingBatteryIdentifyForm';
import { MissingBatteryCaseReporting } from '../MissingBatteryCaseReporting';
import { ModalViews } from './types';

import './MissingBatteryModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  identifyMissingBatteryQueryStatus: getIdentifyMissingBatteryQueryStatus(state),
  claimBatteryOwnershipQueryErrorStatus: getClaimBatteryOwnershipQueryErrorStatus(state),
  claimBatteryOwnershipQueryStatus: getClaimBatteryOwnershipQueryStatus(state),
  assignedBattery: getAssignedBattery(state),
  claimBatteryData: getClaimBatteryData(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  identifyMissigBattery: PartnerActions.identifyMissigBattery,
  clearMissingBatteryState: PartnerActions.clearMissingBatteryState,
  claimBatteryOwnership: PartnerActions.claimMissingBatteryOwnership,
  clearCreatedCaseData: CaseActions.clearCreatedCase,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const MissingBatteryModalComponent: React.FC<Props> = ({
  isModalOpen,
  identifyMissingBatteryQueryStatus,
  claimBatteryOwnershipQueryStatus,
  claimBatteryOwnershipQueryErrorStatus,
  assignedBattery,
  claimBatteryData,
  actions,
}) => {
  const [isCaseReportingVisible, toggleCaseReportingVisibility] = React.useState<boolean>(false);
  const [modalView, setModalView] = React.useState<ModalViews>(ModalViews.IDENTIFY);

  const { track } = useTracking();

  React.useEffect(() => {
    modalViewHandler();
  }, [isCaseReportingVisible,
      claimBatteryOwnershipQueryStatus.success,
      identifyMissingBatteryQueryStatus.success,
    ],
  );

  React.useEffect(() => {
    actions.toggleModal(true, ModalId.MISSING_BATTERY);
  }, [modalView]);

  const modalViewHandler = () => {
    if (isCaseReportingVisible) { return setModalView(ModalViews.CASE); }
    if (claimBatteryOwnershipQueryStatus.success) {return setModalView(ModalViews.SUCCESS); }
    if (identifyMissingBatteryQueryStatus.success) { return setModalView(ModalViews.CLAIM); }
    return setModalView(ModalViews.IDENTIFY);
  };

  const closeMissingBatteryModal = () => {
    track(InteractionEvents.iconClick({
      category: EventCategory.MISSING_BATTERY,
      action: ActionName.CLOSE_MODAL,
    })); 
    actions.toggleModal(false);
    actions.clearMissingBatteryState();
    actions.clearCreatedCaseData();
    toggleCaseReportingVisibility(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeMissingBatteryModal}
      size={'small'}
    >
      {
        {
          [ModalViews.IDENTIFY]: <MissingBatteryIdentifyForm
            serialNumber={claimBatteryData.serialNumber}
            identifyMissingBattery={actions.identifyMissigBattery}
            queryState={identifyMissingBatteryQueryStatus}
            showCaseReporting={() => toggleCaseReportingVisibility(true)}
          />,
          [ModalViews.CLAIM]: <MissingBatteryClaimForm
            claimBatteryData={claimBatteryData}
            claimBatteryOwnership={actions.claimBatteryOwnership}
            queryState={claimBatteryOwnershipQueryStatus}
            queryError={claimBatteryOwnershipQueryErrorStatus}
            showCaseReporting={() => toggleCaseReportingVisibility(true)}
          />,
          [ModalViews.SUCCESS]: assignedBattery && <MissingBatteryClaimSuccess
            closeMissingBatteryModal={closeMissingBatteryModal}
            assignedBattery={assignedBattery}
            claimBatteryData={claimBatteryData}
            queryState={claimBatteryOwnershipQueryStatus}
          />,
          [ModalViews.CASE]: <MissingBatteryCaseReporting
            hideCaseReporting={() => toggleCaseReportingVisibility(false)}
            closeMissingBatteryModal={closeMissingBatteryModal}
          />,
        }[modalView]
      }
    </Modal>
  );
};

export const MissingBatteryModal = connect(mapStateToProps, mapDispatchToProps)(MissingBatteryModalComponent);
