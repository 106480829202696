import { CLOSE_LEAD_QUERY } from '+app/+lead/+overview/store';
import { LeadListRouteQueryParams, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store';
import { getRouteQueryParams } from '+app/router/store/router.selectors';
import { mapPathToParams, mapToState, matchPath, ofType, processQuery } from '+app/utils';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { LayoutActions } from '+shared/store/layout';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, of } from 'rxjs';
import { map, mapTo, mergeMap } from 'rxjs/operators';
import { LeadListPageActions } from './+leadList.actions';
import { getLeadStatusFilters, getSetupLeadStatusFilters } from './+leadList.selectors';
import {
  ASSIGN_PARTNER_TO_LEAD_QUERY,
  DECLINE_LEAD_QUERY,
  INBOX_LEAD_COLLECTION_GET_QUERY,
  LEAD_COLLECTION_GET_QUERY,
  SETUP_LEAD_COLLECTION_GET_QUERY,
} from './+leadList.state';

type Action$ = ActionsObservable<LeadListPageActions>;
type State$ = StateObservable<StoreState>;

export const getLeadCollection$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady, LeadListPageActions.triggerGetLeadList),
  mapToState(state$),
  mergeMap(state => forkJoin(
    of(state).pipe(
      matchPath(ROUTES.LEADS[0]),
      map(getRouteQueryParams),
      map(queryParams => queryParams as LeadListRouteQueryParams),
    ),
    of(state).pipe(
      map(getUserProfileCustomerNumber),
    ),
    of(state).pipe(
      map(getLeadStatusFilters),
    ),
  )),
  mergeMap(([queryParams, userProfileCustomerNumber, statusList]) => of({}).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      processQuery(
        LEAD_COLLECTION_GET_QUERY,
        () => LeadRepository.getLeadList(queryParams, userProfileCustomerNumber, statusList),
        { onSuccess: res => of(LeadListPageActions.setLeadList(res!.elements, res!.meta.totalResourceCount)) }),
    )),
  )),
);

export const getInboxLeadCollection$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadListPageActions.getInboxLeadList),
  mapToState(state$),
  mergeMap(state => of(state).pipe(
    // has to run on /leads also, as we need the current amount of inbox leads
    matchPath([ROUTES.LEADS[0], ROUTES.LEADS_INBOX[0]]),
    map(getRouteQueryParams),
    map(queryParams => queryParams as LeadListRouteQueryParams),
    mergeMap(queryParams => of({}).pipe(
      processQuery(
        INBOX_LEAD_COLLECTION_GET_QUERY,
        () => LeadRepository.getInboxLeadList(queryParams),
        { onSuccess: res => of(LeadListPageActions.setInboxLeadList(res!.elements, res!.meta.totalResourceCount)) }),
    )),
  )),
);

export const getInboxLeadCollectionOnRouteChange$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  mergeMap(state => of(state).pipe(
    matchPath(ROUTES.LEADS_INBOX[0]),
    map(getRouteQueryParams),
    map(queryParams => queryParams as LeadListRouteQueryParams),
    mergeMap(queryParams => of({}).pipe(
      processQuery(
        INBOX_LEAD_COLLECTION_GET_QUERY,
        () => LeadRepository.getInboxLeadList(queryParams),
        {
          onSuccess: res => concat(
            of(LeadListPageActions.setInboxLeadList(res!.elements, res!.meta.totalResourceCount)),
            of(LeadPageActions.getPartnersEmployeeList()),
          ),
        }),
    )),
  )),
);

export const getSetupLeadCollection$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady, LeadListPageActions.triggerGetLeadList),
  mapToState(state$),
  mergeMap(state => forkJoin(
    of(state).pipe(
      matchPath(ROUTES.SETUP_LEADS[0]),
      map(getRouteQueryParams),
      map(queryParams => queryParams as LeadListRouteQueryParams),
    ),
    of(state).pipe(
      map(getUserProfileCustomerNumber),
    ),
    of(state).pipe(
      map(getSetupLeadStatusFilters),
    ),
  )),
  mergeMap(([queryParams, userProfileCustomerNumber, statusList]) => of({}).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      processQuery(
        SETUP_LEAD_COLLECTION_GET_QUERY,
        () => LeadRepository.getSetupLeadList(queryParams, userProfileCustomerNumber, statusList),
        {
          onSuccess: res => {
            const leadListWithBatteryData = res.elements.map(leadElement => {
              const batteryData = leadElement.submissions[0] && leadElement.submissions[0].components;
              return (
                {
                  ...leadElement,
                  batteryData: batteryData && batteryData.batteryType && batteryData.batteryNominalCapacitykWh ? {
                    batteryType: batteryData.batteryType,
                    batteryNominalCapacitykWh: batteryData.batteryNominalCapacitykWh,
                  } : {},
                }
              );
            })
              .map(({ submissions, ...res }) => res);

            return (
              of(LeadListPageActions.setSetupLeadList(leadListWithBatteryData, res!.meta.totalResourceCount))
            );
          },
        },
      )),
    ),
  )),
);

export const assignPartnerToLead$ = (action$: Action$) => action$.pipe(
  ofType(LeadListPageActions.assignPartnerToLead),
  mergeMap((action) => of(action).pipe(
    processQuery(
      ASSIGN_PARTNER_TO_LEAD_QUERY,
      () => LeadRepository.patchAssignLeadsPartner(action.leadId, action.partnerSalesforceContactId),
      {
        onSuccess: () =>
          concat(
            of(LeadListPageActions.getInboxLeadList()),
            of(LayoutActions.toggleModal(false)),
            of(LeadPageActions.toggleAssignLeadModal(false)),
          ),
      }),
  )),
);

export const declineLead$ = (action$: Action$) => action$.pipe(
  ofType(LeadListPageActions.declineLead),
  mergeMap((action) => of(action).pipe(
    processQuery(
      DECLINE_LEAD_QUERY,
      () => LeadRepository.deleteDeclineLead(
        action.leadId,
        encodeURIComponent(action.reason),
      ),
      {
        onSuccess: () =>
          concat(
            of(LeadListPageActions.getInboxLeadList()),
            of(LayoutActions.toggleModal(false)),
            of(LeadPageActions.toggleDeclineLeadModal(false)),
          ),
      }),
  )),
);

export const reassignPartnerToLead$ = (action$: Action$) => action$.pipe(
  ofType(LeadListPageActions.reassignPartnerToLead),
  mergeMap((action) => of(action).pipe(
    processQuery(
      ASSIGN_PARTNER_TO_LEAD_QUERY,
      () => LeadRepository.patchReassignLeadsPartner(action.leadId, action.partnerSalesforceContactId),
      {
        onSuccess: () =>
          concat(
            of(LeadListPageActions.triggerGetLeadList()),
            of(LayoutActions.toggleModal(false)),
            of(LeadPageActions.toggleAssignLeadModal(false)),
          ),
      }),
  )),
);

export const clearAlertMessages$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.locationChange),
  mapToState(state$),
  mapPathToParams(ROUTES.LEADS[0], ROUTES.LEADS_INBOX[0]),
  mapTo(QueryActions.init(CLOSE_LEAD_QUERY)),
);

export const epics = combineEpics(
  getLeadCollection$,
  getInboxLeadCollection$,
  getInboxLeadCollectionOnRouteChange$,
  getSetupLeadCollection$,
  assignPartnerToLead$,
  reassignPartnerToLead$,
  declineLead$,
  clearAlertMessages$,
);
