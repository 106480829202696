import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './NewsReadTag.component.scss';

export const NewsReadTag: React.FC = () => (
  <div className={'news-read-tag'}>
    <p className={'news-read-tag__text'}>{I18n.t(T.newsChannel.tag.new)}</p>
  </div>
);
