import { DetailListTable, Widget } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeLeadHouseDetails } from './LeadOverviewHouseDetails.helper';

interface Props {
  lead: Lead;
}

export const LeadOverviewHouseDetails: React.FC<Props> = ({
  lead,
}) => (
    <Widget
      icon={<Icofont type={'house-on-hill'} />}
      heading={I18n.t(T.lead.boc._salessolution_.form.houseDetails.headline)}
    >
      <DetailListTable
        list={factorizeLeadHouseDetails(lead.roofDetails)}
        filterNil={false}
        lead={lead}
        hasBottomBorder={false}
      />
    </Widget>
  );
