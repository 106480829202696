import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';
import {  TableCell } from '@sonnen/shared-web';
import * as React from 'react';

import './CertificateListItem.component.scss';

interface Props {
  item: UserCertificate;
}

export const CertificateListItem: React.FC<Props> = ({ item : { certification } }) => (
  <div className={'c-certificate-list-item'}>
    <TableCell className={'c-certificate-list-item__cell'}>{certification}</TableCell>
  </div>
);
