import { Query } from '+shared/store/query';
import { Customer, CustomerData } from './types/customer.interface';

export const GET_CUSTOMER_LIST_QUERY = 'getCustomerListQuery';

export interface CustomerState {
  list: Customer[];
  [GET_CUSTOMER_LIST_QUERY]: Query<CustomerData>;
}

export const initialState: CustomerState = {
  list: [],
  [GET_CUSTOMER_LIST_QUERY]: {},
};
