import { DetailListTable, Widget } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getLeadAdditionalDetailsList } from './LeadOverviewAdditionalDetails.helper';

interface Props {
  lead: Lead;
}

export const LeadOverviewAdditionalDetails: React.FC<Props> = ({
  lead,
}) => (
  <Widget
    icon={<Icon.ThreeDotsCircle className={'c-lead-overview-partner-notes__icon'} />}
    heading={I18n.t(T.lead.boc._salessolution_.additionalDetails.heading)}
  >
    <DetailListTable
      list={getLeadAdditionalDetailsList(lead)}
      filterNil={false}
      lead={lead}
      hasBottomBorder={false}
    />
  </Widget>
);
