import { getCustomerContractPageState } from '+app/+customer/store/+customer.selectors';
import { ContractType } from '+shared/store/contract/contract.helpers';
import { getStatus } from '+shared/store/query/query.utils';
import { TimeHelper } from '@sonnen/shared-web';
import { isNil, isUndefined } from 'lodash/fp';
import { createSelector } from 'reselect';
import { GET_CONTRACT_QUERY } from './+contract.state';

export const getContract = createSelector(
  getCustomerContractPageState,
  contractPageState => contractPageState.contract,
);

export const getContractQueryStatus = createSelector(
  getCustomerContractPageState,
  contractPageState => getStatus(contractPageState[GET_CONTRACT_QUERY]),
);

const getContractStartAt = createSelector(
  getContract,
  contractDetailsState =>
    !isUndefined(contractDetailsState) ? contractDetailsState.startAt : undefined,
);

export const getContractStartAtDate = createSelector(
  getContractStartAt,
  startAt => !isNil(startAt) ? new Date(startAt) : undefined,
);

const getContractEndAt = createSelector(
  getContract,
  contractDetailsState => contractDetailsState?.endAt,
);

export const getContractEndAtDate = createSelector(
  getContractEndAt,
  endAt => !isNil(endAt) ? new Date(endAt) : undefined,
);

export const isContractActive = createSelector(
  getContractStartAtDate,
  startAt => startAt ? (TimeHelper.isBeforeNow(startAt)) : false,
);

export const getContractType = createSelector(
  getContract,
  contractDetailsState => contractDetailsState?.contractType,
);

export const isContractTypeAnySonnenFlat = createSelector(
  getContractType,
  contractType => contractType === ContractType.SONNEN_FLAT || contractType === ContractType.SONNEN_FLAT_X,
);
