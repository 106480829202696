import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeBatteryInstallationDetailList } from './BatteryInstallationCard.helper';

interface Props {
  battery: Battery;
  queryStatus: ReturnType<typeof getStatus>;
}

export const BatteryInstallationCard: React.FC<Props> = ({ battery, queryStatus }) => (
  <Widget
    icon={<Icofont type={'tools'} />}
    heading={I18n.t(T.customerSingle.batteryDetails.installation.headline)}
  >
    {queryStatus.pending && (
      <Loader className={'c-battery-live-card__loader'} />
    )}
    {queryStatus.success && battery && (
      <DetailList
        vertical={true}
        list={factorizeBatteryInstallationDetailList(battery)}
      />
    )}
    {queryStatus.error && (
      <WidgetWarning
        type={'warning'}
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.battery.error)}
      </WidgetWarning>
    )}
  </Widget>
);
