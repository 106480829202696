import { Customer } from '+shared/store/customer';
import { TableCell, TableStack } from '@sonnen/shared-web';
import * as React from 'react';
import { CustomersTableName } from '../CustomersTableName';
import { CustomersTableCustomerAddress } from '../CustomerTableCustomerAdress';

interface Props {
  customer: Customer;
  isConsentGiven: boolean;
}

export const CustomersTableWithoutSite: React.FC<Props> = ({
  customer,
  isConsentGiven,
}) => {
  return (
    <div
      className={'c-customers-table-user__row'}
      data-hj-suppress={true}
    >
      <TableCell
        className={'c-customers-table-site__table-cell'}
        stack={TableStack.HORIZONTAL}
      >
        <CustomersTableName
          firstName={customer.firstName}
          lastName={customer.lastName}
          name={customer.name}
          isFirst={true}
          isConsentGiven={isConsentGiven}
        />
        <CustomersTableCustomerAddress
          customer={customer}
        />
      </TableCell>
    </div>
  );
};
