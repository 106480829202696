import { EnergyUnit, OtherUnit } from '+app/App.constants';
import { ConfigurationForm } from '+lead-configuration/store/types';
import { getAreaCallingCode } from '+shared/components/Form/FormInputPhone/FormInputPhone.helper';
import { LeadSource } from '+shared/store/lead/types/lead.interface';
import { searchByKey } from '+utils/array.util';
import { formatEnergy } from '+utils/format.util';
import { formatEnergy as formatEnergyOld } from '+utils/format.util.old';
import { trim } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import {
  BatteryModelName,
  ConfigurationPvType,
  Lead,
  LeadAddress,
  LeadConfigurationAttributes,
  LeadConfigurationResponse,
  LeadConfigurationStatus,
  LeadCreateDto,
  LeadCustomerType,
  LeadProductType,
  SinglePhotovoltaicSystem,
} from './types';
import { LeadProductBattery } from './types/leadProductBattery.interface';

const getCountryCode = ({ deliveryAddress }: LeadCreateDto) => deliveryAddress ?
  getAreaCallingCode(deliveryAddress.country) : '';

export const mapLeadAttributesToDto = (lead: LeadCreateDto) => ({
  company: lead.customerType === LeadCustomerType.BUSINESS
    ? lead.company
    : undefined,
  salutation: lead.salutation,
  title: lead.title,
  first_name: lead.firstName,
  last_name: lead.lastName,
  email: lead.email,
  phone: lead.phone && lead.phone.length
    ? getCountryCode(lead) + lead.phone
    : lead.phone,
  mobile_phone: lead.mobilePhone && lead.mobilePhone.length
    ? getCountryCode(lead) + lead.mobilePhone
    : lead.mobilePhone,
  delivery_address: lead.deliveryAddress && {
    street: lead.deliveryAddress.street,
    zip_code: lead.deliveryAddress.zipCode,
    city: lead.deliveryAddress.city,
    country: lead.deliveryAddress.country,
    kind: lead.deliveryAddress.kind,
  },
  invoice_address: lead.invoiceAddress ? {
    street: lead.invoiceAddress.street,
    zip_code: lead.invoiceAddress.zipCode,
    city: lead.invoiceAddress.city,
    country: lead.invoiceAddress.country,
  } : undefined,
  agreedToGdpr: lead.agreedToGdpr,
  optedOutOfMarketingMailing: lead.optedOutOfMarketingMailing,
});

const getCommonPvSystemDataToDto = (photovoltaicSystem: SinglePhotovoltaicSystem, isMultiplePv = false) => ({
  type: photovoltaicSystem.type,
  peak_power: { ...photovoltaicSystem.peakPower },
  commissioning_date: photovoltaicSystem.commissioningDate,
  system_name: isMultiplePv ? photovoltaicSystem.systemName : undefined,
});

const mapSinglePvSystemToDto = (photovoltaicSystem: SinglePhotovoltaicSystem, isMultiplePv = false) =>
  photovoltaicSystem.type === ConfigurationPvType.SPECIFIC_YIELD ? ({
    ...getCommonPvSystemDataToDto(photovoltaicSystem, isMultiplePv),
    specific_yield_per_year: { ...photovoltaicSystem.specificYieldPerYear },
  }) : ({
    ...getCommonPvSystemDataToDto(photovoltaicSystem, isMultiplePv),
    inclination: { ...photovoltaicSystem.inclination },
    orientation: { ...photovoltaicSystem.orientation },
  });

export const mapConfigurationToDto = (configuration: LeadConfigurationAttributes) => ({
  total_consumption_per_year: { ...configuration.totalConsumptionPerYear },
  photovoltaic_system: Array.isArray(configuration.photovoltaicSystem)
    ? configuration.photovoltaicSystem.map(pvSystem => mapSinglePvSystemToDto(pvSystem, true))
    : mapSinglePvSystemToDto(configuration.photovoltaicSystem),
  power_plant: Array.isArray(configuration.photovoltaicSystem) ? {
    dso_consent_to_combine_photovoltaic_systems: configuration.powerPlant?.dsoConsentToCombinePhotovoltaicSystems,
  } : undefined,
  battery: {
    // TODO: replace capacityGross with a proper form name
    id: configuration.battery.capacityGross,
  },
  electric_car: configuration.electricCar ? {
    total_consumption_per_year: { ...configuration.electricCar?.totalConsumptionPerYear },
    mileage_per_year: { ...configuration.electricCar?.mileagePerYear },
  } : undefined,
  heat_pump: configuration.heatPump ? {
    total_consumption_per_year: { ...configuration.heatPump?.totalConsumptionPerYear },
  } : undefined,
});

export const mapLeadCustomerType = (lead: Lead): Lead => ({
  ...lead,
  customerType: lead.company
    ? LeadCustomerType.BUSINESS
    : LeadCustomerType.PRIVATE,
});

export const getInlineAddress = ({ city, street, zipCode }: LeadAddress): string =>
  (!(zipCode || city) && street)
    ? trim`${street && `${street}`}`
    : trim`${zipCode}${city && ` ${city}`}${street && `, ${street}`}`;

export const isLeadFlatOfferAccepted = (lead: Lead | undefined): boolean =>
  !isNil(lead) && lead.status && lead.status.summary.flatOfferAccepted
    ? lead.status.summary.flatOfferAccepted.isSet
    : false;

export const hasOfferStatus = (configuration: LeadConfigurationResponse | undefined): boolean =>
  !isNil(configuration)
    ? configuration.status === LeadConfigurationStatus.OFFER
    : false;

export const hasPreviewStatus = (configuration: LeadConfigurationResponse | undefined): boolean =>
  !isNil(configuration)
    ? configuration.status === LeadConfigurationStatus.PREVIEW
    : false;

export const hasDraftStatus = (configuration: LeadConfigurationResponse | undefined): boolean =>
  !isNil(configuration)
    ? configuration.status === LeadConfigurationStatus.DRAFT
    : false;

export const mapConfigurationToRecommendationDto = (form: ConfigurationForm) => ({
  total_consumption_per_year: {
    value: form.totalConsumptionPerYear,
    unit: EnergyUnit.KWH,
  },
  electric_car: form.eVehicle ? {
    total_consumption_per_year: { value: 0, unit: EnergyUnit.KWH },
    mileage_per_year: { value: 0, unit: OtherUnit.KM },
  } : undefined,
  heat_pump: form.heatPump ? {
    total_consumption_per_year: { value: 0, unit: EnergyUnit.KWH },
  } : undefined,
  photovoltaic_system: form.pvType === ConfigurationPvType.SPECIFIC_YIELD ? {
    type: ConfigurationPvType.SPECIFIC_YIELD,
    specific_yield_per_year: {
      value: form.specificYieldPerYear,
      unit: EnergyUnit.KWH_PER_KWP,
    },
    commissioning_date: form.commissioningDate,
  } : {
      type: ConfigurationPvType.DETAILED,
      inclination: {
        value: form.inclination,
        unit: OtherUnit.DEGREE,
      },
      orientation: {
        value: form.orientation,
        unit: OtherUnit.DEGREE,
      },
      commissioning_date: form.commissioningDate,
    },
  battery: {
    model_name: form.modelName,
  },
});

export const mapAutarkyToDto = (autarky: number) => ({
  power_plant: {
    expected_autarky: {
      value: autarky,
      unit: OtherUnit.PERCENT,
    },
  },
});

export const isSonnenLead = (lead: Lead) =>
  lead.source === LeadSource.SONNEN;

// PRODUCT BATTERIES

/**
 * @deprecated use `formatEnergy` from `format.util.tsx`
 */
export const formatToKwh = (capacity: number) => formatEnergyOld({
  whValue: capacity,
  unit: EnergyUnit.KWH,
  precision: 1,
});

export const batteryModelNameMap = (modelName: BatteryModelName) => ({
  [BatteryModelName.SONNENBATTERIE_10_AC]: I18n.t(T.lead.configuration._salessolution_.battery.modelAc),
  [BatteryModelName.SONNENBATTERIE_10_DC]: I18n.t(T.lead.configuration._salessolution_.battery.modelDc),
  [BatteryModelName.SONNENBATTERIE_ECO_8]: I18n.t(T.lead.configuration._salessolution_.battery.modelEco8),
  [BatteryModelName.SONNENBATTERIE_ECO_7]: I18n.t(T.lead.configuration._salessolution_.battery.modelEco7),
  [BatteryModelName.SONNENBATTERIE_ECO_6]: I18n.t(T.lead.configuration._salessolution_.battery.modelEco6),
  [BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_AC]:
    I18n.t(T.lead.configuration._salessolution_.battery.modelPerformanceAc),
  [BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC]:
    I18n.t(T.lead.configuration._salessolution_.battery.modelPerformanceDc),
})[modelName];

// if a battery has 'battery' included in products,
// it means its endOfSalesDate hasn't passed yet and it's still available for sale
export const isBatteryAvailableForSale = (battery: LeadProductBattery) =>
  !!battery.products.find(product => product.name === LeadProductType.BATTERY);

const splitByKwh = (capacityLabel: string) => capacityLabel.split('kWh');

export const formatLabelToNumber = (capacity: string) =>
  splitByKwh(capacity).length ? parseFloat(splitByKwh(capacity)[0]) : 0;

export const batteryCapacityLabelsMapper = (locale: string) => (key: string, productBatteryList: LeadProductBattery[]) => {
  const capacityLabel = searchByKey('id', key, productBatteryList, 'name');

  return batteryCapacityLabelMapper(locale)(capacityLabel);
};

export const batteryCapacityLabelMapper = (locale: string) => (capacityLabel: string) => {
  // we receive eg. '4kWh' from product catalogue and we need to parse it via formatToKwh
  // to keep the internationalization as previously. In order to pass it to this function we need to
  // retreive the capacity from this string and parse it to a number
  const extractedCapacityToNumber = formatLabelToNumber(capacityLabel);
  const splittedCapacityFiltered = splitByKwh(capacityLabel).filter(splittedString => splittedString !== '');
  const formatCapacity = formatEnergy({ locale, unit: EnergyUnit.KWH });

  if (splittedCapacityFiltered.length === 1) {
    return formatCapacity(extractedCapacityToNumber * 1000);
  }

  // we have also some examples (for eco 8) where the displayed text also contains some additional information
  // eg. '10 kWh (sonnenmodul)', so we have to check if it's there and display it
  if (splittedCapacityFiltered.length === 2) {
    return `${formatCapacity(extractedCapacityToNumber * 1000)}${splitByKwh(capacityLabel)[1]}`;
  }

  return '';
};
