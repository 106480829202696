import { Site } from '+app/shared/store/customer/types/customerSite.type';
import { hasPvSystem } from '+shared/store/battery/battery.helpers';
import { getCaseCollection } from '+shared/store/case';
import { isContractSonnenFlat, isContractSonnenFlatX } from '+shared/store/contract/contract.helpers';
import { Customer } from '+shared/store/customer';
import { StoreState } from '+shared/store/store.interface';
import { findElementById } from '+utils/array.util';
import { head, isUndefined } from 'lodash/fp';
import { getPrimaryBatterySystem } from '../+list/store/customer.helpers';

export const getCustomer = (customerId: string) => (collection: Customer[]) =>
  findElementById(collection, customerId);

export const getSite = (siteId?: string) => (customer: Customer | undefined) =>
  !isUndefined(customer)
    ? siteId ? findElementById(customer.sites, siteId) : head(customer.sites)
    : undefined;

export const getBattery = (batteryId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? batteryId ? findElementById(site.batterySystems, batteryId) : getPrimaryBatterySystem(site)
    : undefined;

export const getContract = (contractId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? contractId ? findElementById(site.contracts, contractId) : head(site.contracts)
    : undefined;

export const getCharger = (chargerId?: string) => (site: Site | undefined) =>
  !isUndefined(site)
    ? chargerId ? findElementById(site.chargers, chargerId) : head(site.chargers)
    : undefined;

export const getMeterInstallation = (site?: Site) =>
  site && site.contracts && site.contracts[0] && site.contracts[0].meterInstallation;

export const getAssetsAvailability = (site: Site | undefined, state: StoreState) => {
  const battery = getBattery()(site);
  const contract = getContract()(site);
  const cases = getCaseCollection(state);
  const charger = getCharger()(site);
  const sonnenFlat = isContractSonnenFlat(contract);
  const sonnenFlatX = isContractSonnenFlatX(contract);

  return {
    hasBattery: !!battery,
    hasContract: !!contract,
    hasPvSystem: hasPvSystem(battery),
    hasCases: !!cases.length,
    hasCharger: !!charger,
    hasSonnenFlat: !!sonnenFlat,
    hasSonnenFlatX: !!sonnenFlatX,
  };
};
