import { SiteStatistics } from '+app/shared/store/site/types/siteStatistics.interface';
import {
  aggregateMeasurement,
  BarChartSeries,
  factorizeBarChartSeries,
  PieChartSeries,
  StatisticsResolution,
  StatisticsSelectedDate,
  StatisticsSeriesKey,
  TimeHelper,
} from '@sonnen/shared-web';
import * as moment from 'moment';
import { ChartDataSeries } from '../types/chartDataSeries.interface';

export const transformToSiteStatisticsFilters = (statisticsSelectedDate: StatisticsSelectedDate) => {
  const { date, resolution, period } = statisticsSelectedDate;
  return ({
    start: moment(date).startOf(period).toDate(),
    end: moment(date).endOf(period).toDate(),
    resolution,
  });
};

export const defaultStatisticsFilters = {
  start: TimeHelper.getStartOfDate()(TimeHelper.todayDate),
  end: TimeHelper.getEndOfDate()(TimeHelper.todayDate),
  resolution: StatisticsResolution.HOUR,
};

export const transformStatisticsIntoSeries = (
  statistics: SiteStatistics,
  statisticsSelectedDate: StatisticsSelectedDate,
) => {
  const {
    consumedEnergy,
    producedEnergy,
    gridPurchaseEnergy,
    gridFeedinEnergy,
  } = statistics;

  const pieChart = {
    autonomy: {
      consumedEnergy: aggregateMeasurement(consumedEnergy) - aggregateMeasurement(gridPurchaseEnergy),
      gridPurchaseEnergy: aggregateMeasurement(gridPurchaseEnergy),
    },
    selfConsumption: {
      producedEnergy: aggregateMeasurement(producedEnergy) - aggregateMeasurement(gridFeedinEnergy),
      gridFeedinEnergy: aggregateMeasurement(gridFeedinEnergy),
    },
  } as PieChartSeries;

  const barChart = {
    producedEnergy: factorizeBarChartSeries(producedEnergy, statisticsSelectedDate),
    consumedEnergy: factorizeBarChartSeries(consumedEnergy, statisticsSelectedDate),
  } as BarChartSeries;

  return {pieChart, barChart};
};

  // TODO: move this and the method from CP to SW - connect in the future
export const mapDataSeries = (dataSeries: Partial<BarChartSeries | ChartDataSeries>) =>
  Object.keys(dataSeries).map(key => ({
    data: dataSeries[key],
    name: key,
  }));

export const visibleStatisticsSeriesKeys = [
  StatisticsSeriesKey.PRODUCED_ENERGY,
  StatisticsSeriesKey.CONSUMED_ENERGY,
];
