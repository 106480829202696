import { MeasurementMethod, SiteLiveStateAttributes, SiteStatistics } from '+shared/store/site/types';
import { EnergyFlowSeriesKey, MAX_FUTURE_DAYS, Measurement,
  Point, StatisticsSeriesKey, TimeHelper } from '@sonnen/shared-web';
import { isEmpty, isNil } from 'lodash/fp';
import * as moment from 'moment';
import { Battery } from '../../../../shared/store/battery';
import { SiteMeasurements } from '../../../../shared/store/site';
import { SiteForecastConsumption, SiteForecastData, SiteForecastProduction } from '../types/forecast.interface';

export const meterGridMeasurementMethods = [
  MeasurementMethod.METER_GRID,
  MeasurementMethod.METER_GRID_PV,
  MeasurementMethod.METER_GRID_PV_FEED_IN,
];

export const isUnsupportedBattery = (battery: Battery | undefined) =>
  !isNil(battery) && !isNil(battery.serialNumber)
    ? Number(battery.serialNumber[0]) > 3
    : false;

export const hasMeasurements = (measurements: SiteMeasurements | undefined) => Boolean(
  !isNil(measurements)
  && !isNil(measurements.measurementMethod)
  && (measurements.productionPower
    || measurements.consumptionPower
    || measurements.batteryUsoc
    || measurements.directUsagePower)
  && (measurements.productionPower.length
    || measurements.consumptionPower.length
    || measurements.batteryUsoc.length
    || measurements.directUsagePower.length)
  && measurements.measurementMethod !== 'meter-error',
);

export const getLiveSeriesPoint = (timestamp: string, value: number): Point => {
  return {
    x: TimeHelper.getUnixFromDate(new Date(timestamp)),
    y: value,
  };
};

export const analysisPointAccessor = ({ x, y }: Partial<Point>, _: number, all: Array<Partial<Point>>) => ({
  y: y!,
  x: (x! - all[0].x!) / 60,
});

export const getFirstForecastSeriesPoint = (date: moment.Moment) =>
 ({ x: date.startOf('day').unix(), y: null });

export const updateForecastSeries = (
  { forecastSeries, liveDataTimestamp, liveDataPower }:
  { forecastSeries: Point[], liveDataTimestamp: string, liveDataPower: number | undefined },
) => {
  if (!forecastSeries.length) { return []; }

  const liveSeriesPoint = getLiveSeriesPoint(
    liveDataTimestamp,
    liveDataPower || 0, // TODO handle using 0 if measurements data is delayed
  );

  return forecastSeries.length
    ? [
        getFirstForecastSeriesPoint(moment()),
        liveSeriesPoint,
        ...forecastSeries.filter(forecast => moment.unix(forecast.x).isSameOrAfter(moment.unix(liveSeriesPoint.x))),
      ]
    : [];
};

export const getLastDataPoint = (dataPoints: Point[]) =>
  dataPoints.length ? dataPoints.slice(-1)[0] : { x: moment().unix(), y: 0 };

export const getForecastStartDate = (liveState: SiteLiveStateAttributes | undefined): number => {
  if (liveState && moment(liveState.timestamp).isSame(moment(), 'day')) {
    return moment(liveState.timestamp).unix();
  }
  return moment().unix();
};

const filterCurrentDayForecast = (
  forecasts: SiteForecastData[],
  date: moment.Moment,
) => {
  const isToday = date.isSame(moment(), 'day');

  if (isToday) {
    return forecasts.filter(forecast => moment(forecast.forecastDatetime).isBetween(
      moment(),
      moment().endOf('day'),
    ));
  }

  return forecasts.filter(forecast => moment(forecast.forecastDatetime)
    .isSame(date, 'day'));
};

export const transformForecastData = (
  { date, lastDataPointTimestamp, lastDataPointPower, forecasts, forecastDataKey }:
  {
    date: moment.Moment,
    lastDataPointTimestamp: number,
    lastDataPointPower: number,
    forecasts: SiteForecastProduction[] | SiteForecastConsumption[],
    forecastDataKey: string,
  },
): Point[] => {
  if (!forecasts.length) {
    return [];
  }
  const isTodayOrFuture = date.isSameOrAfter(moment(), 'day');
  const isToday = date.isSame(moment(), 'day');
  const currentDayForecast = filterCurrentDayForecast(forecasts, date);

  return isTodayOrFuture
    ? [
        getFirstForecastSeriesPoint(date),
        ...isToday ? [{ x: lastDataPointTimestamp, y: lastDataPointPower }] : [],
        ...currentDayForecast.map((forecast) => ({
          x: moment(forecast.forecastDatetime).unix(),
          y: forecast[forecastDataKey],
        })),
      ]
    : [];
};

export const getLastForecastDate = (forecast: SiteForecastData[]): string | undefined => !isEmpty(forecast)
  ? forecast[forecast.length - 1].forecastDatetime
  : undefined;

export const calculateMaxFutureDate = (forecasts: SiteForecastData[]): number => {
  const lastForecastDate = moment(getLastForecastDate(forecasts)).valueOf(); // moment(undefined) returns current date.
  const maxDate = moment().add(MAX_FUTURE_DAYS, 'days').valueOf();

  return Math.min(lastForecastDate, maxDate);
};

export const getMaxForecastsDate = (
  production: SiteForecastData[] | undefined,
  consumption: SiteForecastData[] | undefined,
): Date => {
  switch (true) {
    case isEmpty(consumption) && isEmpty(production):
      return new Date();
    case isEmpty(production):
      return new Date(calculateMaxFutureDate(consumption!));
    case isEmpty(consumption):
      return new Date(calculateMaxFutureDate(production!));
    default:
      return new Date(Math.max(calculateMaxFutureDate(consumption!), calculateMaxFutureDate(production!)));
  }
};

export const isMKMeterUser = (measurementMethod: string) =>
  meterGridMeasurementMethods.some(method => method === measurementMethod);

export const hasMeasurement = (
  attributes: SiteMeasurements | SiteStatistics,
  attributeKey: EnergyFlowSeriesKey | StatisticsSeriesKey,
) => Boolean(
  !isEmpty(attributes[attributeKey])
  && attributes[attributeKey].some((point: Measurement) => !isNil(point)),
);
