import { queryReducer } from '+shared/store/query';
import { createReducer } from '../../utils';
import { REGISTER_ACTIONS, RegisterActions } from './+register.actions';
import { initialState, RegisterPageState, SUBMIT_REGISTRATION_QUERY, VERIFY_TOKEN_QUERY } from './+register.state';

export const reducer = createReducer<RegisterPageState, RegisterActions>(
  (state = initialState, action): RegisterPageState => {
    switch (action.type) {
      case REGISTER_ACTIONS.SET_REGISTER_CREDENTIALS:
        return {
          ...state,
          registration: {
            ...state.registration,
            email: action.email,
            invitationToken: action.token,
          },
        };
      case REGISTER_ACTIONS.SET_REGISTER_FORM:
        return {
          ...state,
          registration: {
            ...state.registration,
            ...action.form,
          },
        };
      default:
        return queryReducer(state, action, [
          SUBMIT_REGISTRATION_QUERY,
          VERIFY_TOKEN_QUERY,
        ]);
    }
  },
);
