import { StatusTile, StatusTileColors } from '+app/shared/components/StatusTile';
import * as React from 'react';

interface Props {
  message: string;
  color: StatusTileColors;
  tooltipText?: string;
}

export const CustomersTableStatusTile: React.FC<Props> = ({ message, color, tooltipText }) => (
  <div onClick={e => {e.stopPropagation(); }}>
    <StatusTile
      label={message}
      color={color}
      bold={true}
      tooltipText={tooltipText}
    />
  </div>
);
