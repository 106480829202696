import {
  NewsItem,
} from '+app/+newsMain/components/NewsItem/NewsItem.component';
import { setNewsUrl } from '+app/+newsMain/store/newsMain.helpers';
import { NewsInboxListRouteQueryParams, PATHS } from '+app/router';
import { getActiveNewsId, NewsMainActions } from '+app/shared/store/newsMain';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { News } from '+shared/store/firebase/types/news.interface';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, LinkButton, Modal, ModalLayout } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';

import './NotificationModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  activeNewsId: getActiveNewsId(state),
  userProfile: getUserProfile(state),
});

const mapDispatchToProps = mapActions({
  goToNewsDetails: (urlParams: NewsInboxListRouteQueryParams) => push(PATHS.NEWS_INBOX(urlParams)),
  setActiveNewsId: NewsMainActions.setActiveNewsId,
  markNewsAsRead: FirebaseActions.markNewsAsRead,
});

type Props =
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>
& {
  notification: News;
  isOpen: boolean;
  onClose: () => void;
};

export const NotificationModalComponent: React.FC<Props> = ({
  actions,
  notification,
  isOpen,
  onClose,
  userProfile,
}) => {
  const goToNewsInbox = (title: any, id: string) => {
    actions.goToNewsDetails(setNewsUrl(title, id));
    actions.setActiveNewsId(id);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onOutsideClick={onClose}
      className={'c-notification-modal'}
    >
      <ModalLayout
        title={I18n.t(T.news.notifications.modal.title)}
        footer={
          <>
            <LinkButton
              className={'c-notification-modal__footer-link'}
              onClick={() => {
                if (userProfile) {
                  actions.markNewsAsRead(notification.id, userProfile.id);
                }
                goToNewsInbox(notification.shortMessage, notification.id);
              }}>{I18n.t(T.news.notifications.modal.button.label.goTo)}</LinkButton>
            <Button
              label={I18n.t(T.news.notifications.modal.button.label.close)}
              theme={ButtonTheme.OUTLINE}
              size={ButtonSize.PRIMARY}
              onClick={onClose}
              className={'c-notification-modal__footer-button'}
            />
          </>
        }
      >
        <NewsItem
          key={uuid.v4()}
          item={notification}
          showPreviewOnly={false}
          newsPlacement={NewsPlacementType.NOTIFICATION_MODAL}
        />
      </ModalLayout>
    </Modal>
  );
};

export const NotificationModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationModalComponent);
