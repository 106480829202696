import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

import tutorialComponentHwStatus from '+assets/img/tutorial-component-hw-status.png';

export enum SlideType {
  TEXT_ONLY = 'textOnly',
  TEXT_AND_IMAGE = 'textAndImage',
  TEXT_AND_VIDEO = 'textAndVideo',
}

export interface CarouselSlide {
  tutorialId: string;
  slideNumber: number;
  slideName: string;
  slideType: SlideType;
  title: string;
  description: string;
  linkHref?: string;
  linkText?: string;
  imgSrc?: string;
  videoSrc?: string;
}

const getTutorialTranslations = () => {
  const translations = {
    orderFlowTitle: I18n.t(T.tutorialComponent.orderFlow.title),
    orderFlowDescription: I18n.t(T.tutorialComponent.orderFlow.description),
    orderFlowVideoLink: I18n.t(T.tutorialComponent.orderFlow.videoLink),
    orderFlowBottomLinkHref: I18n.t(T.tutorialComponent.orderFlow.bottomLinkHref),
    orderFlowBottomLinkText: I18n.t(T.tutorialComponent.orderFlow.bottomLinkText),

    statusManagementTitle: I18n.t(T.tutorialComponent.statusManagement.title),
    statusManagementDescription: I18n.t(T.tutorialComponent.statusManagement.description),
    statusManagementVideoLink: I18n.t(T.tutorialComponent.statusManagement.videoLink),
    statusManagementBottomLinkHref: I18n.t(T.tutorialComponent.statusManagement.bottomLinkHref),
    statusManagementBottomLinkText: I18n.t(T.tutorialComponent.statusManagement.bottomLinkText),

    sonnenLeadJourneyTitle: I18n.t(T.tutorialComponent.sonnenLeadJourney.title),
    sonnenLeadJourneyDescription: I18n.t(T.tutorialComponent.sonnenLeadJourney.description),
    sonnenLeadJourneyVideoLink: I18n.t(T.tutorialComponent.sonnenLeadJourney.videoLink),
    sonnenLeadJourneyBottomLinkHref: I18n.t(T.tutorialComponent.sonnenLeadJourney.bottomLinkHref),
    sonnenLeadJourneyBottomLinkText: I18n.t(T.tutorialComponent.sonnenLeadJourney.bottomLinkText),

    precommissioningTitle: I18n.t(T.tutorialComponent.precommissioning.title),
    precommissioningDescription: I18n.t(T.tutorialComponent.precommissioning.description),
    precommissioningVideoLink: I18n.t(T.tutorialComponent.precommissioning.videoLink),
    precommissioningBottomLinkHref: I18n.t(T.tutorialComponent.precommissioning.bottomLinkHref),
    precommissioningBottomLinkText: I18n.t(T.tutorialComponent.precommissioning.bottomLinkText),

    registrationToolsTitle: I18n.t(T.tutorialComponent.registrationTools.title),
    registrationToolsDescription: I18n.t(T.tutorialComponent.registrationTools.description),
    registrationToolsVideoLink: I18n.t(T.tutorialComponent.registrationTools.videoLink),
    registrationToolsBottomLinkHref: I18n.t(T.tutorialComponent.registrationTools.bottomLinkHref),
    registrationToolsBottomLinkText: I18n.t(T.tutorialComponent.registrationTools.bottomLinkText),

    meterPrepTitle: I18n.t(T.tutorialComponent.meterPrep.title),
    meterPrepDescription: I18n.t(T.tutorialComponent.meterPrep.description),
    meterPrepVideoLink: I18n.t(T.tutorialComponent.meterPrep.videoLink),
    meterPrepBottomLinkHref: I18n.t(T.tutorialComponent.meterPrep.bottomLinkHref),
    meterPrepBottomLinkText: I18n.t(T.tutorialComponent.meterPrep.bottomLinkText),

    taxationTitle: I18n.t(T.tutorialComponent.taxation.title),
    taxationDescription: I18n.t(T.tutorialComponent.taxation.description),
    taxationVideoLink: I18n.t(T.tutorialComponent.taxation.videoLink),
    
    assignBatteryTitle: I18n.t(T.tutorialComponent.assignBattery.title),
    assignBatteryDescription: I18n.t(T.tutorialComponent.assignBattery.description),
    assignBatteryVideoLink: I18n.t(T.tutorialComponent.assignBattery.videoLink),
    assignBatteryBottomLinkHref: I18n.t(T.tutorialComponent.assignBattery.bottomLinkHref),
    assignBatteryBottomLinkText: I18n.t(T.tutorialComponent.assignBattery.bottomLinkText),

    assignBatteryCreateCaseTitle: I18n.t(T.tutorialComponent.assignBatteryCreateCase.title),
    assignBatteryCreateCaseDescription: I18n.t(T.tutorialComponent.assignBatteryCreateCase.description),
    assignBatteryCreateCaseVideoLink: I18n.t(T.tutorialComponent.assignBatteryCreateCase.videoLink),
    assignBatteryCreateCaseBottomLinkHref: I18n.t(T.tutorialComponent.assignBatteryCreateCase.bottomLinkHref),
    assignBatteryCreateCaseBottomLinkText: I18n.t(T.tutorialComponent.assignBatteryCreateCase.bottomLinkText),

    hardwareStatusTitle: I18n.t(T.tutorialComponent.hardwareStatus.title),
    hardwareStatusDescription: I18n.t(T.tutorialComponent.hardwareStatus.description),

    multiplePvTitle: I18n.t(T.tutorialComponent.multiplePv.title),
    multiplePvDescription: I18n.t(T.tutorialComponent.multiplePv.description),
    multiplePvVideoLink: I18n.t(T.tutorialComponent.multiplePv.videoLink),
  };

  return translations;
};

const getSlidesContent = (): CarouselSlide[] => {
  const t = getTutorialTranslations();

  return [
    {
      tutorialId: 'leadDetails',
      slideNumber: 0,
      slideName: 'multiplePv',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.multiplePvTitle,
      description: t.multiplePvDescription,
      videoSrc: t.multiplePvVideoLink,
    },
    {
      tutorialId: 'leadDetails',
      slideNumber: 1,
      slideName: 'hardwareStatus',
      slideType: SlideType.TEXT_AND_IMAGE,
      title: t.hardwareStatusTitle,
      description: t.hardwareStatusDescription,
      imgSrc: tutorialComponentHwStatus,
    },
    {
      tutorialId: 'leadDetails',
      slideNumber: 2,
      slideName: 'orderFlow',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.orderFlowTitle,
      description: t.orderFlowDescription,
      videoSrc: t.orderFlowVideoLink,
      linkHref: t.orderFlowBottomLinkHref,
      linkText: t.orderFlowBottomLinkText,
    },
    {
      tutorialId: 'leadDetails',
      slideNumber: 3,
      slideName: 'sonnenLeadJourney',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.sonnenLeadJourneyTitle,
      description: t.sonnenLeadJourneyDescription,
      videoSrc: t.sonnenLeadJourneyVideoLink,
      linkHref: t.sonnenLeadJourneyBottomLinkHref,
      linkText: t.sonnenLeadJourneyBottomLinkText,
    },
    {
      tutorialId: 'leadDetails',
      slideNumber: 4,
      slideName: 'statusManagement',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.statusManagementTitle,
      description: t.statusManagementDescription,
      videoSrc: t.statusManagementVideoLink,
      linkHref: t.statusManagementBottomLinkHref,
      linkText: t.statusManagementBottomLinkText,
    },
    {
      tutorialId: 'setupConfigurator',
      slideNumber: 0,
      slideName: 'precommissioning',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.precommissioningTitle,
      description: t.precommissioningDescription,
      videoSrc: t.precommissioningVideoLink,
      linkHref: t.precommissioningBottomLinkHref,
      linkText: t.precommissioningBottomLinkText,
    },
    {
      tutorialId: 'setupConfigurator',
      slideNumber: 1,
      slideName: 'meterPrep',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.meterPrepTitle,
      description: t.meterPrepDescription,
      videoSrc: t.meterPrepVideoLink,
      linkHref: t.meterPrepBottomLinkHref,
      linkText: t.meterPrepBottomLinkText,
    },
    {
      tutorialId: 'setupConfigurator',
      slideNumber: 2,
      slideName: 'registrationTools',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.registrationToolsTitle,
      description: t.registrationToolsDescription,
      videoSrc: t.registrationToolsVideoLink,
      linkHref: t.registrationToolsBottomLinkHref,
      linkText: t.registrationToolsBottomLinkText,
    },
    {
      tutorialId: 'setupConfigurator',
      slideNumber: 3,
      slideName: 'statusManagement',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.statusManagementTitle,
      description: t.statusManagementDescription,
      videoSrc: t.statusManagementVideoLink,
      linkHref: t.statusManagementBottomLinkHref,
      linkText: t.statusManagementBottomLinkText,
    },
    {
      tutorialId: 'customersList',
      slideNumber: 0,
      slideName: 'taxation',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.taxationTitle,
      description: t.taxationDescription,
      videoSrc: t.taxationVideoLink,
    },
    {
      tutorialId: 'customersList',
      slideNumber: 1,
      slideName: 'assignBattery',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.assignBatteryTitle,
      description: t.assignBatteryDescription,
      videoSrc: t.assignBatteryVideoLink,
      linkHref: t.assignBatteryBottomLinkHref,
      linkText: t.assignBatteryBottomLinkText,
    },
    {
      tutorialId: 'customersList',
      slideNumber: 2,
      slideName: 'assignBatteryCreateCase',
      slideType: SlideType.TEXT_AND_VIDEO,
      title: t.assignBatteryCreateCaseTitle,
      description: t.assignBatteryCreateCaseDescription,
      videoSrc: t.assignBatteryCreateCaseVideoLink,
      linkHref: t.assignBatteryCreateCaseBottomLinkHref,
      linkText: t.assignBatteryCreateCaseBottomLinkText,
    },
    // {
    //   tutorialId: 'casesList',
    //   slideNumber: 0,
    //   slideName: 'salesConfigurator_invitation_to_preportal',
    //   slideType: SlideType.TEXT_AND_VIDEO,
    //   title: 'How to fill Customer?',
    //   description: '[CUSTOMER DESCRIPTION - SLIDE NR 1] Sed ut perspiciatis unde omnis iste ' +
    //     'natus error sit voluptatem accusantium ' +
    //     'doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore ' +
    //     'veritatis et quasi architecto beatae vitae dicta sunt explicabo. ',
    //   linkHref: '1link href',
    //   linkText: 'More on Help & support',
    //   videoSrc: 'https://www.youtube.com/embed/ANQuG853Q_k',
    // },
    // {
    //   tutorialId: 'casesList',
    //   slideNumber: 1,
    //   slideName: 'setupConfigurator_slide_status_management',
    //   slideType: SlideType.TEXT_AND_IMAGE,
    //   title: 'How to fill Customer?',
    //   description: '[CUSTOMER DESCRIPTION - SLIDE NR 2] Sed ut perspiciatis unde omnis iste natus error sit ' +
    //     'voluptatem accusantium doloremque ' +
    //     'laudantium, ' +
    //     'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta ' +
    //     'sunt ' +
    //     'explicabo. ',
    //   linkHref: '2link href',
    //   linkText: 'More on Help & support',
    //   imgSrc: 'https://i.ytimg.com/vi/ANQuG853Q_k/hqdefault.jpg',
    // },
    // {
    //   tutorialId: 'leadsInbox',
    //   slideNumber: 0,
    //   slideName: 'salesConfigurator_invitation_to_preportal',
    //   slideType: SlideType.TEXT_AND_VIDEO,
    //   title: 'How to fill Setup Data?',
    //   description: '[SETUP TOOLS DESCRIPTION - SLIDE NR 1] Sed ut perspiciatis unde omnis iste natus ' +
    //     'error sit voluptatem accusantium ' +
    //     'doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore ' +
    //     'veritatis et quasi architecto beatae vitae dicta sunt explicabo. ',
    //   linkHref: '1link href',
    //   linkText: 'More on Help & support',
    //   videoSrc: 'https://www.youtube.com/embed/vzqKKc59AF4',
    // },
    // {
    //   tutorialId: 'leadsInbox',
    //   slideNumber: 1,
    //   slideName: 'setupConfigurator_slide_status_management',
    //   slideType: SlideType.TEXT_AND_IMAGE,
    //   title: 'How to fill Setup Data?',
    //   description: '[SETUP TOOLS DESCRIPTION - SLIDE NR 2] Sed ut perspiciatis unde omnis iste natus ' +
    //     'error sit voluptatem accusantium doloremque ' +
    //     'laudantium, ' +
    //     'totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta ' +
    //     'sunt ' +
    //     'explicabo. ',
    //   linkHref: '2link href',
    //   linkText: 'More on Help & support',
    //   imgSrc: 'https://i.ytimg.com/vi/b-YCbUBvOio/hqdefault.jpg',
    // },
  ];
};

export const getSlides = (id: string): CarouselSlide[] => {
  return getSlidesContent().filter(item => item.tutorialId === id)
    .sort((a: CarouselSlide, b: CarouselSlide) => a.slideNumber - b.slideNumber);
};
