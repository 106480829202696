import * as classNames from 'classnames';
import * as React from 'react';

import './DsoRegistrationFormBreakLine.component.scss';

interface Props {
  className?: ClassValue;
}

export const DsoRegistrationFormBreakLine: React.FC<Props> = ({
  className,
}) => (
    <div className={classNames('c-dso-registration-form-break-line', className)} />
  );
