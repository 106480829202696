export enum EventHitType {
  MANUAL_TRIGGER = 'ManualTrigger',
  MANUAL_PAGEVIEW_TRIGGER = 'ManualPageViewTrigger',
}

export enum EventCategory {
  LEAD_CREATION = 'Lead creation',
  LEAD_DETAILS = 'Lead details',
  MOVE_TO_SETUP = 'Move to set up',
  SALES_CONFIGURATOR = 'Sales configurator',
  IMPACT_ANALYSIS = 'Impact Analysis',
  STATUSES = 'Statuses',
  // Partner Usage events
  MISSING_BATTERY = 'Missing battery',
  REPORT_CASE = 'Report case',
  CUSTOMER_LIST = 'Customer list',
  // Setup Tools events
  DSO_REGISTRATION = 'Setup Tools: DSO Registration',
  SETUP_DATA = 'Setup Tools: Setup Data',
  DSO_COMMISSIONING = 'Setup Tools: DSO Commissioning',
  PVR = 'Setup Tools: PVR ',
  VPP = 'Setup Tools: VPP',
  PRECOMMISSIONING = 'Setup Tools: Precommissioning',
  STANDARD_WIRING_DIAGRAM_DSO_REGISTERED = 'Setup Tools: Standard Wiring Diagram DSO Registered',
  CUSTOM_WIRING_DIAGRAM_DSO_REGISTERED = 'Setup Tools: Custom Wiring Diagram DSO Registered',
  SETUP_TOOLS_ERRORS = 'Setup Tools: Errors',
  SETUP_TOOLS_TUTORIAL = 'Setup Tools: Tutorial',
  HELP_VIDEOS = 'Help videos',
  CUSTOMER_DETAILS = 'Customer details',
  SONNEN_WORLD = 'Sonnen world',
}

export enum FormName {
  LEAD_CREATION = 'NewLead',
  SALES_CONFIGURATOR = 'NewConfiguration',
  DSO_REGISTRATION = 'DsoRegistration',
  SETUP_DATA = 'SetupData',
  IMPACT_ANALYSIS = 'ImpactAnalysis',
  CLAIM_BATTERY = 'ClaimBattery',
  IDENTIFY_SERIAL_NUMBER = 'IdentifySerialNumber',
  REPORT_CASE = 'ReportCase',
  SEARCH_CUSTOMER = 'SearchCustomer',
}

export enum ActionName {
  OPEN_MODAL = 'Open modal',
  CLOSE_MODAL = 'Close modal',
  OPEN_MODAL_ON_CASES_PAGE = 'Open modal on cases page',
  OPEN_MODAL_ON_CUSTOMER_LIST_PAGE = 'Open modal on customer list page',
  OPEN_MODAL_ON_CUSTOMER_PAGE = 'Open modal on customer page',
  OPEN_CASE_FORM = 'Open case form',
  DSO_GENERATED_DOCUMENTS = 'Setup Tools: DSO Generated Documents',
  DSO_REGENERATED_DOCUMENTS = 'Setup Tools: DSO Regenerated Documents',
  SETUP_DATA_OPENED_POPUP = 'Setup Tools: Setup Data Popup Opened',
  DSO_REGISTRATION_OPENED_POPUP = 'Setup Tools: DSO Registration Popup Opened',
  DSO_COMMISSIONING_OPENED_POPUP = 'Setup Tools: DSO Commissioning Popup Opened',
  DSO_COMMISSIONING_GENERATED_DOCUMENTS = 'Setup Tools: DSO Commissioning Generated Documents',
  DSO_COMMISSIONING_REGENERATED_DOCUMENTS = 'Setup Tools: DSO Commissioning Regenerated Documents',
  PVR_OPENED_POPUP = 'Setup Tools: PVR Popup Opened',
  VPP_OPENED_POPUP = 'Setup Tools: VPP Popup Opened',
  PRECOMMISSIONING_OPENED_POPUP = 'Setup Tools: Precommissioning Popup Opened',
  CLOSE_STATUS_MANAGER = 'Close status manager',
  RECOMMEND_LEAD_BUTTON_CLICKED = 'Recommend lead',
  START_NEW_CONFIGURATION = 'Start new configuration',
  OPEN_CASE_PREVIEW_MODAL = 'Open case preview modal',
  TOGGLE_SORT_SELECT = 'Toggle sort select',
  SHOW_SORT_SELECT = 'Show sort select',
  SELECT_SORT_OPTION = 'Select option',
  SEARCH_BY_NUMBER = 'Search by number',
  SEARCH_BY_WORD = 'Search by word',
  PLAY_VIDEO = 'Play video',
  SWITCH_DATE_PERIOD = 'Switch date period',
  NAV_LINK_CLICK = 'Nav link click',
  OPEN_SECTION = 'Open section',
  SHOW_NAV = 'Show nav',
}
