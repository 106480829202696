import { isLeadSonnenNowInterested } from '+app/+lead/store/+lead.helper';
import { TutorialTooltip } from '+shared/containers/TutorialTooltip';
import { TutorialTooltipType } from '+shared/containers/TutorialTooltip/TutorialTooltip.helper';
import { Lead, LeadStatusName } from '+shared/store/lead/types';
import { TooltipSide } from '+shared/store/tutorial/tutorial.state';
import { T } from '@sonnen/shared-i18n/service';
import { Bubble, Button, ButtonSize, ButtonTheme, ClickOutside, Icon, LinkButton, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { retrieveIsSet } from '../LeadOverviewHeader/LeadOverviewHeader.helper';

import './LeadOverviewInvitation.component.scss';

interface Props {
  lead: Lead;
  isPending: boolean;
  sendCGInvitation: (leadId: string) => void;
}

export const LeadOverviewInvitation: React.FC<Props> = ({
  lead,
  isPending,
  sendCGInvitation,
}) => {
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);

  const renderTooltip = (tooltipMsg: string) => (
    <ClickOutside
      onClick={() => toggleTooltipActivity(false)}
    >
      <div
        className={'c-lead-overview-invitation__tooltip'}
        onClick={() => toggleTooltipActivity(!isTooltipActive)}
      >
        <Icon.Info className={'c-lead-overview-invitation__info-icon'} />
        {isTooltipActive &&
          <div className={'c-lead-overview-invitation__bubble-wrapper'} >
            <Bubble
              side={'top-right'}
              isThemeDense={false}
            >
              {tooltipMsg}
            </Bubble>
          </div>
        }
      </div>
    </ClickOutside>
  );

  const renderLoader = () => isPending &&
    <div className={'c-lead-overview-invitation__loader'}>
      <Loader />
    </div>;

  return (
    <div className={'c-lead-overview-invitation'}>
      {retrieveIsSet(lead.status.summary[LeadStatusName.INVITATION_SENT]) && !isLeadSonnenNowInterested(lead)
        ? (
          <div className={'c-lead-overview-invitation__sent'}>
            {renderLoader()}
            <Icon.Checkmark className={'c-lead-overview-invitation__sent-icon'} />
            <div className={'c-lead-overview-invitation__sent-text'}>
              <div className={'c-lead-overview-invitation__sent-header'}>
                {I18n.t(T.lead.overview._salessolution_.header.invitation.sent)}
              </div>
              <LinkButton
                onClick={() => sendCGInvitation(lead.id)}
                className={'c-lead-overview-invitation__resend-button'}
              >
                {I18n.t(T.lead.overview._salessolution_.header.invitation.resend)}
              </LinkButton>
              {renderTooltip(I18n.t(T.lead.overview._salessolution_.header.invitation.tooltip))}
            </div>
          </div>
        ) : (
          <>
            <div className={'c-lead-overview-invitation__send-invitation'}>
              {renderLoader()}
              {/* <TutorialTooltip
                id={'invitationToPreportal'}
                tooltipSide={TooltipSide.BOTTOM_LEFT}
              > */}
                <Button
                  theme={ButtonTheme.OUTLINE}
                  label={I18n.t(T.lead.overview._salessolution_.header.invitation.sendInvitation)}
                  size={ButtonSize.NORMAL}
                  onClick={() => sendCGInvitation(lead.id)}
                  isDisabled={isLeadSonnenNowInterested(lead)}
                />
              {/* </TutorialTooltip> */}
            </div>
            {renderTooltip(
              isLeadSonnenNowInterested(lead)
                ? I18n.t(T.lead.overview._salessolution_.header.invitation.sonnenNowTooltip)
                : I18n.t(T.lead.overview._salessolution_.header.invitation.tooltip))}
          </>
        )}
    </div>
  );
};
