import { CountryFeatureName } from '+config/countryFlags';
import { CategorySelectItem, getCaseCategoryCollection } from '+shared/store/case';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import {
  DynamicField,
  SF_ACCOUNT_FIELD_NAME,
  SF_CONTRACT_FIELD_NAME,
} from '+shared/store/case/types/createCase.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, useCountryFeature } from '@sonnen/shared-web';
import { Formik, FormikProps } from 'formik';
import { isObject } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import {
  CaseReportFormField,
  CaseReportFormValues,
  FormCaseType,
  FormCaseTypeComponentData,
  FormCaseTypesComponentData,
} from './CaseReportForm.types';

const TOPIC_MAX_CHARACTERS = 200;

const getTypesComponentData = (activeSite?: CaseSite): FormCaseTypesComponentData => {
  const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

  const technicalCase = {
    [FormCaseType.TECHNICAL_CASE] : {
      // add new translations and clean up old ones in the i18n
      // name: I18n.t(T.report.form.categories.battery.name),
      key: FormCaseType.TECHNICAL_CASE,
      label: I18n.t(T.report.form.types.technicalCase.label),
      icon: <Icon.SonnenBattery />,
      description: I18n.t(T.report.form.types.technicalCase.description),
      disabled: !activeSite || (activeSite && !activeSite.battery),
    },
  };

  const contractCase = isCustomerContractFeatureEnabled ? {
    [FormCaseType.E_SERVICES] : {
      key: FormCaseType.E_SERVICES,
      label: I18n.t(T.report.form.types.eServices.label),
      icon: <Icon.Contract viewBox={{ w: 25, h: 25 }}/>,
      topics: getCaseCategoryCollection(),
      description: I18n.t(T.report.form.types.eServices.description),
      disabled: !activeSite || (activeSite && !activeSite.contract),
    },
  } : {};

  const otherCase = {
    [FormCaseType.OTHER] : {
      key: FormCaseType.OTHER,
      label: I18n.t(T.report.form.types.other.label),
      icon: <Icon.Tools />,
      description: I18n.t(T.report.form.types.other.description),
      disabled: !activeSite,
    },
  };

  return {
    ...technicalCase,
    ...contractCase,
    ...otherCase,
  };
};

const initialValues: CaseReportFormValues = {
  [CaseReportFormField.TOPIC]: '',
  [CaseReportFormField.ATTACHMENT]: null,
  [CaseReportFormField.TYPE]: undefined,
  [CaseReportFormField.DESCRIPTION]: '',
};

const isEServicesType = (typeKey?: FormCaseType) => typeKey === FormCaseType.E_SERVICES;

const generateSubject = (
  { prefix, type, topic }:
  { prefix: string, type: string, topic?: string },
  ) =>
  `${prefix ? `${prefix} | ` : ''}${type}${topic ? ` | ${topic}` : ''}`;

const getSubject = (
  { type, topic, batteryNumber, contractNumber }:
  {
    type: FormCaseTypeComponentData,
    topic: CategorySelectItem | string,
    batteryNumber: string,
    contractNumber?: string,
  },
) => {
  const contractPrefix = contractNumber || '';
  const subjectPrefix = isEServicesType(type.key) ? contractPrefix : batteryNumber;

  return generateSubject({
    prefix: subjectPrefix,
    type: type.label,
    topic: isObject(topic) ? topic.name : topic,
  });
};

const validationSchema = () => Yup.object({
  [CaseReportFormField.DESCRIPTION]: Yup.string()
    .required(I18n.t(T.report.inputRequiredError))
    .trim(I18n.t(T.report.fieldCannotBeEmpty)),
  [CaseReportFormField.TOPIC]: Yup.string()
    .required(I18n.t(T.report.errors.topicRequired))
    .max(TOPIC_MAX_CHARACTERS, I18n.t(T.report.errors.topicMaxLength, {number: TOPIC_MAX_CHARACTERS} )),
  [CaseReportFormField.TYPE]: Yup.object()
    .required(I18n.t(T.report.errors.caseTypeRequired)),

});

const getDynamicFields = (
  salesforceContractId?: string,
  salesforceAccountId?: string,
) => {
  const dynamicFields: DynamicField[] = [];

  if (salesforceContractId) {
    dynamicFields.push({
      field: SF_CONTRACT_FIELD_NAME,
      value: salesforceContractId,
    });
  }

  if (salesforceAccountId) {
    dynamicFields.push({
      field: SF_ACCOUNT_FIELD_NAME,
      value: salesforceAccountId,
    });
  }

  return dynamicFields;
};

const resetFormValues = (form: FormikProps<CaseReportFormValues>) => {
  form.setFieldValue(CaseReportFormField.TOPIC, '');
  form.setFieldValue(CaseReportFormField.TYPE, undefined);
  form.setFieldError(CaseReportFormField.TOPIC, '');
  form.setFieldError(CaseReportFormField.TYPE, '');
  form.setFieldTouched(CaseReportFormField.TOPIC, false);
  form.setFieldTouched(CaseReportFormField.TYPE, false);
};

export const CaseFormHelper = {
  getTypesComponentData,
  initialValues,
  validationSchema,
  getDynamicFields,
  generateSubject,
  getSubject,
  isEServicesType,
  resetFormValues,
};
