import { PersistedState } from 'redux-persist';
import { Customer } from '../../shared/store/customer';
import { Query } from '../../shared/store/query';

import * as analysis from '../+analysis/store';
import * as battery from '../+battery/store';
import * as contract from '../+contract/store';
import * as list from '../+list/store';
import * as overview from '../+overview/store';

export const GET_CUSTOMER_QUERY = 'getCustomerQuery';
export const PUT_CUSTOMER_NOTE_QUERY = 'putCustomerNoteQuery';

export interface CustomerPageCommonState extends PersistedState {
  customer?: Customer;
  [GET_CUSTOMER_QUERY]: Query;
  [PUT_CUSTOMER_NOTE_QUERY]: Query;
}

export interface CustomerPageState extends PersistedState {
  analysis: analysis.AnalysisPageState;
  battery: battery.BatteryPageState;
  contract: contract.ContractPageState;
  common: CustomerPageCommonState;
  overview: overview.OverviewPageState;
  list: list.CustomerListPageState;
}

export const initialCommonState: CustomerPageCommonState = {
  customer: undefined,
  [GET_CUSTOMER_QUERY]: {},
  [PUT_CUSTOMER_NOTE_QUERY]: {},
};

export const initialState: CustomerPageState = {
  analysis: analysis.initialState,
  battery: battery.initialState,
  contract: contract.initialState,
  common: initialCommonState,
  overview: overview.initialState,
  list: list.initialState,
};
