import { getLead } from '+app/+lead/store/+lead.selectors';
import { GET_LEAD_QUERY, LEAD_UPDATE_QUERY } from '+app/+lead/store/+lead.state';
import { PATHS, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { DocumentStatus, FlatDocumentType } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapPathToParams, mapToState, matchPath, ofType, polling, processQuery } from '+utils/index';
import { isStatusSet } from '+utils/status.util';
import { push } from 'connected-react-router';
import { isEmpty, last } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, iif, merge, of } from 'rxjs';
import { filter, ignoreElements, map, mapTo, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { ImpactAnalysisActions } from '../../+impactAnalysis/store/+impactAnalysis.actions';
import { LeadOverviewPageActions } from './+overview.actions';
import { getFlatOffers, setDsoValidationPostponed } from './+overview.helper';
import { getFailedDocument, getProductBatteryList, isOpeningDocument } from './+overview.selectors';
import {
  CLOSE_LEAD_QUERY,
  DELETE_OFFER_QUERY,
  GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
  GET_OFFER_LIST_QUERY,
  GET_PARTNER_NOTE_QUERY,
  GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
  GET_PRODUCT_BATTERY_LIST_QUERY,
  POST_PARTNER_NOTE_QUERY,
  RECALCULATE_CONFIGURATION_QUERY,
  SEND_CG_INVITATION_QUERY,
} from './+overview.state';

type Action$ = ActionsObservable<LeadOverviewPageActions>;
type State$ = StateObservable<StoreState>;

// FIXME: Needs refactoring since lead is requested on every LOCATION_CHANGE within declared routes.
// Temporarily getLead moved to CDM at LeadOverview.component.tsx

const getLeadDetails$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  filter(state => getLead(state) === undefined),
  mapPathToParams(
    ROUTES.LEAD_NEW[0],
    ROUTES.LEAD_IMPACT_ANALYSIS[0],
    ROUTES.SETUP_LEAD_IMPACT_ANALYSIS[0],
  ),
  filter(params => !isEmpty(params)),
  map(([leadId]) => LeadActions.getLead(leadId, GET_LEAD_QUERY)),
);

// this one doesn't check if lead data exists in store - it always fetches fresh data
const getFreshLeadDetails$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  mapPathToParams(
    ROUTES.LEAD_OFFER[0],
    ROUTES.SETUP_LEAD_OFFER[0],
    ROUTES.LEAD_CONFIGURATION_NEW[0],
    ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
    ROUTES.LEAD_HARDWARE_NEW[0],
    ROUTES.SETUP_LEAD_HARDWARE_NEW[0],
    ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
    ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],
  ),
  filter(params => !isEmpty(params)),
  map(([leadId]) => LeadActions.getLead(leadId, GET_LEAD_QUERY)),
);

const getOfferList$ = (action$: Action$, state$: State$) => merge(
  action$.pipe(ofType(LeadActions.setLead), map(action => action.lead.id)),
  action$.pipe(ofType(LeadOverviewPageActions.getOfferList), map(action => action.leadId)),
).pipe(
  mergeMap(leadId => of(leadId).pipe(
    mapToState(state$),
    mapPathToParams(
      // we want the offer list to be additionally fetched only on lead details page
      ROUTES.LEAD_OVERVIEW[0],
      ROUTES.SETUP_LEAD_OVERVIEW[0],
      ROUTES.LEAD_CONFIGURATION[0],
      ROUTES.SETUP_LEAD_CONFIGURATION[0],
      ROUTES.LEAD_CONFIGURATION_NEW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
      ROUTES.LEAD_HARDWARE_NEW[0],
      ROUTES.SETUP_LEAD_HARDWARE_NEW[0],
      // we want the offer list to be fetched for status manager modal
      ROUTES.LEADS[0],
    ),
    mergeMap(() => of({}).pipe(
      processQuery(
        GET_OFFER_LIST_QUERY,
        () => LeadRepository.getLeadOfferList(leadId),
        { onSuccess: res => of(LeadOverviewPageActions.getOfferListSuccess(leadId, res!.elements)) },
      ),
    )),
  )),
);

const getOfferConfigurationList$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.getOfferListSuccess),
  mergeMap(({ leadId, offers }) => merge(
    ...getFlatOffers(offers)
      .map(offer => of(LeadActions.getConfiguration(leadId, offer.configuration, offer.configuration))),
  )),
);

const deleteOffer$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.deleteOffer),
  mergeMap(({ leadId, offerId, isLastFlatOfferSent, isLastHardwareOnlyOfferSent, isLastFlatOnlyOffer }) => of({}).pipe(
    processQuery(
      DELETE_OFFER_QUERY,
      () => LeadRepository.deleteLeadOffer(leadId, offerId),
      {
        onSuccess: () => concat(
          iif(() => isLastFlatOfferSent,
            of(LeadPageActions.setOfferSentStatusInactive()),
          ),
          iif(() => isLastHardwareOnlyOfferSent,
            of(LeadPageActions.setHardwareOfferSentStatusInactive()),
          ),
          iif(() => isLastFlatOnlyOffer,
            of(LeadPageActions.setHardwareAlreadySoldStatusInactive()),
          ),
          of(LeadOverviewPageActions.deleteOfferSuccess(offerId)),
        ),
      },
    ),
  )),
);

const getOfferDocument$ = (action$: Action$, state$: State$) => action$.pipe(
  polling({
    startOn: LeadOverviewPageActions.getOfferDocument,
    stopOn: [
      LeadOverviewPageActions.openOfferDocument,
      LeadOverviewPageActions.documentOpened,
      LeadOverviewPageActions.documentFailed,
    ],
    interval: 1000,
  })(({ leadId, offerId, documentType }) => LeadRepository
    .getLeadOffer(leadId, offerId).pipe(
      map(res => res.element),
      map(offer => {
        switch (documentType) {
          case FlatDocumentType.CONFIGURATION:
            return last(offer.flatConfigurations)!;
          case FlatDocumentType.OFFER:
            return last(offer.flatOffers)!;
          default:
            throw new Error(`Unsupported document type: "${documentType}".`);
        }
      }),
      mergeMap(document => {
        if (document.status === DocumentStatus.FAILED) {
          return of(LeadOverviewPageActions.documentFailed(offerId, 'offer', documentType));
        }

        return of(document).pipe(
          filter(document => document.status === DocumentStatus.CREATED),
          switchMap(document => LeadRepository.getLeadOfferDocumentFile(leadId, offerId, documentType, document.id)),
          withLatestFrom(state$),
          filter(([_, state]) => isOpeningDocument(state)),
          map(([url]) => LeadOverviewPageActions.openOfferDocument(url)),
        );
      }),
    )));

const createDocument$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadOverviewPageActions.createDocument),
  mergeMap(action => of(action.leadId).pipe(
    mapToState(state$),
    map(getFailedDocument),
    filter(failedDocument => failedDocument !== undefined),
    mergeMap(failedDocument => of(action.leadId).pipe(
      mergeMap(leadId => {
        switch (failedDocument!.resourceType) {
          case 'offer':
            return LeadRepository.postLeadOfferDocument(
              leadId,
              failedDocument!.resourceId,
              failedDocument!.documentType as FlatDocumentType,
            );
          case 'impact-analysis':
            return LeadRepository.postLeadImpactAnalysisDocument(leadId, failedDocument!.resourceId);

          default:
            throw new Error('Invalid resource type');
        }
      }),
      map(() => {
        switch (failedDocument!.resourceType) {
          case 'impact-analysis':
            return ImpactAnalysisActions.downloadImpactAnalysisFile(
              action.leadId,
              failedDocument!.resourceId,
            );

          case 'offer':
            return LeadOverviewPageActions.getOfferDocument({
              leadId: action.leadId,
              offerId: failedDocument!.resourceId,
              documentType: failedDocument!.documentType as FlatDocumentType,
            });

          default:
            throw new Error('Invalid resource type');
        }
      }),
    )),
  )),
);

export const setPartnerNotes$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadOverviewPageActions.postPartnerNote),
  mergeMap((action) => of(action).pipe(
    mapToState(state$),
    mapPathToParams(ROUTES.LEAD_OVERVIEW[0], ROUTES.SETUP_LEAD_OVERVIEW[0]),
    filter(params => !isEmpty(params)),
    mergeMap(([leadId]) => of(leadId).pipe(
      processQuery(
        POST_PARTNER_NOTE_QUERY,
        () => LeadRepository.postPartnerNote(leadId, action.partnerNotes),
        { onSuccess: res => dataGuard(LeadPageActions.setPartnerNote)(res!.element) }),
    )),
  )),
);

export const getPartnerNotes$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadOverviewPageActions.getPartnerNote),
  mergeMap((action) => of(action).pipe(
    mapToState(state$),
    mapPathToParams(ROUTES.LEAD_OVERVIEW[0], ROUTES.SETUP_LEAD_OVERVIEW[0]),
    filter(params => !isEmpty(params)),
    mergeMap(([leadId]) => of(leadId).pipe(
      processQuery(
        GET_PARTNER_NOTE_QUERY,
        () => LeadRepository.getPartnerNote(leadId, action.noteId),
        { onSuccess: res => dataGuard(LeadOverviewPageActions.getPartnerNoteSuccess)(res!.element.content) }),
    )),
  )),
);

const recalculateConfiguration$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadOverviewPageActions.recalculateConfiguration),
  mergeMap(action => of(action).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      mapPathToParams(ROUTES.LEAD_CONFIGURATION[0], ROUTES.SETUP_LEAD_CONFIGURATION[0]),
      map(([leadId]) => ({ leadId })),
    )),
    mergeMap(({ leadId }) => of({}).pipe(
      processQuery(
        RECALCULATE_CONFIGURATION_QUERY,
        () => LeadRepository.patchRecalculateConfiguration(
          leadId,
          action.configurationId,
          action.semiIndirect,
          action.generationPlants,
          action.productionMeteringMandatory,
        ),
        { onSuccess: res => of(LeadActions.setConfiguration(res!.element)) },
      ),
    )),
  )),
);

const closeLead$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.closeLead),
  mergeMap(({ leadId, reason, status }) => of({}).pipe(
    processQuery(
      CLOSE_LEAD_QUERY,
      () => LeadRepository.postLeadStatus({ name: LeadStatusName.CLOSED_DEAD, reason }, leadId),
      { onSuccess: () => of(push(isStatusSet(status.summary.inSetup) ? PATHS.SETUP_LEADS() : PATHS.LEADS())) }),
  )),
);

const sendCGInvitation$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.sendCGInvitation),
  mergeMap(({ leadId }) => of({}).pipe(
    processQuery(
      SEND_CG_INVITATION_QUERY,
      () => LeadRepository.postLeadStatus({ name: LeadStatusName.INVITATION_SENT }, leadId),
      {
        onSuccess: () =>
          concat(
            of(LeadPageActions.setCGInvitationSent()),
            of(QueryActions.init(LEAD_UPDATE_QUERY)),
          ),
      }),
  )),
);

const setDsoChoicePostponed$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.setDsoChoicePostponed),
  map(({ leadId }) => setDsoValidationPostponed(leadId)),
  ignoreElements(),
);

const getProductAvailability$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(LeadOverviewPageActions.getProductAvailability),
  mergeMap(action => of(action).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      mapPathToParams(
        ROUTES.LEAD_OVERVIEW[0],
        ROUTES.LEAD_CONFIGURATION[0],
        ROUTES.SETUP_LEAD_OVERVIEW[0],
        ROUTES.SETUP_LEAD_CONFIGURATION[0],
      ),
      map(([leadId]) => ({ leadId })),
    )),
    mergeMap(({ leadId }) => of({}).pipe(
      processQuery(
        GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
        () => LeadRepository.getProductAvailability(leadId, action.dsoId, action.tsoName),
        { onSuccess: res => of(LeadOverviewPageActions.setProductAvailability(res!.elements)) },
      ),
    )),
  )),
);

const getProductAvailabilityForAddress$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.getProductAvailabilityForAddress),
  mergeMap(({ address }) => of({}).pipe(
    processQuery(
      GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
      () => LeadRepository.getProductAvailabilityForAddress(address),
      { onSuccess: res => of(LeadOverviewPageActions.setProductAvailabilityForAddress(res!.elements)) }),
  )),
);

// after each setting of the status we have to clear store, so we don't set it in wrong places
const clearAsyncOfferSentId$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.setAsyncFlatOfferSentStatus),
  map(() => LeadOverviewPageActions.clearAsyncOfferSentId()),
);

const getProductBatteryList$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  filter(state => isEmpty(getProductBatteryList(state))),
  mergeMap(state => of(state).pipe(
    matchPath([
      ROUTES.LEAD_OVERVIEW[0],
      ROUTES.LEAD_CONFIGURATION[0],
      ROUTES.SETUP_LEAD_CONFIGURATION[0],
      ROUTES.LEAD_CONFIGURATION_NEW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW[0],
      ROUTES.LEAD_HARDWARE_NEW[0],
      ROUTES.SETUP_LEAD_HARDWARE_NEW[0],
      ROUTES.LEAD_CONFIGURATION_NEW_FOR_HW[0],
      ROUTES.SETUP_LEAD_CONFIGURATION_NEW_FOR_HW[0],
      ROUTES.LEADS[0],
    ]),
    mergeMap(() => of({}).pipe(
      processQuery(
        GET_PRODUCT_BATTERY_LIST_QUERY,
        () => LeadRepository.getBatterryList(),
        {
          onSuccess: res => of(LeadOverviewPageActions.setProductBatteryList(res!.elements)),
        }),
    ),
    ),
  )),
);

const displayMaxOffersWarning$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.displayMaxOffersWarning),
  mergeMap(({ leadId }) =>  [
    push(PATHS.LEAD_CONFIGURATION({ leadId })),
    LayoutActions.toggleModal(false),
  ]),
);

const closeModalsOnSettingHardwareStatus$ = (action$: Action$) => action$.pipe(
  ofType(LeadOverviewPageActions.setHardwareOfferStatus),
  mapTo(LayoutActions.toggleModal(false)),
);

export const epics = combineEpics(
  getLeadDetails$,
  getOfferList$,
  getOfferConfigurationList$,
  deleteOffer$,
  getOfferDocument$,
  createDocument$,
  setPartnerNotes$,
  getPartnerNotes$,
  recalculateConfiguration$,
  closeLead$,
  getFreshLeadDetails$,
  sendCGInvitation$,
  setDsoChoicePostponed$,
  getProductAvailability$,
  getProductAvailabilityForAddress$,
  clearAsyncOfferSentId$,
  getProductBatteryList$,
  displayMaxOffersWarning$,
  closeModalsOnSettingHardwareStatus$,
);
