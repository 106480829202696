import { Query } from '+shared/store/query';
import { UserRegistration } from '../../shared/store/user';

export const SUBMIT_REGISTRATION_QUERY = 'submitRegistrationQuery';
export const VERIFY_TOKEN_QUERY = 'verifyTokenQuery';

export interface RegisterPageState {
  registration: UserRegistration;
  [SUBMIT_REGISTRATION_QUERY]: Query<any>;
  [VERIFY_TOKEN_QUERY]: Query<any>;
}

export const initialState: RegisterPageState = {
  registration: {
    email: '',
    invitationToken: '',
    password: '',
    passwordConfirmation: '',
    privacyPolicy: false,
    termsOfService: false,
  },
  [SUBMIT_REGISTRATION_QUERY]: {},
  [VERIFY_TOKEN_QUERY]: {},
};
