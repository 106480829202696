import { CurrencyUnit } from '+app/App.constants';
import { NON_BREAKING_SPACE } from '+utils/string.util';
import * as d3 from 'd3-format';
import { isFunction } from 'lodash/fp';
import * as React from 'react';
import { Attribute, AttributeFormatter, ComposedAttribute, CurrencySymbol } from '../attribute';

interface PriceFormatterProps {
  currency: keyof CurrencySymbol;
  value: number;
  unit?: string;
  decimals?: number;
  stripTrailingZeros?: boolean;
  children?: (attribute: Exclude<Attribute, 'currencySymbol'> & ComposedAttribute) => ComposedAttribute;
}

export const addDecimalSeparators = (
  value: string | number,
  currency: string = CurrencyUnit.CURRENCY_EUR,
) => {
  const transformedValue = d3.format(',')(parseFloat(value.toString()));

  if (currency === CurrencyUnit.CURRENCY_EUR) {
    return transformedValue.replace(/,/g, '.');
  }

  return transformedValue;
};

export const PriceFormatter = ({
   currency,
   children,
   stripTrailingZeros = false,
   ...rest
}: PriceFormatterProps) => (
  <AttributeFormatter {...{ currency, children, stripTrailingZeros, ...rest }}>
    {({ currencySymbol, value, ...rest }) => {
      const result: ComposedAttribute = {
        value,
        formatFn: v => `${addDecimalSeparators(v, currency)}${NON_BREAKING_SPACE}${currencySymbol}`,
      };

      if (isFunction(children)) {
        return children({ ...rest, ...result });
      }

      return result;
    }}
  </AttributeFormatter>
);
