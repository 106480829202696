import { SonnenDriveCarsFeaturedSlide, SonnenDriveCarsSlide } from '+drive/components';
import { T } from '@sonnen/shared-i18n/service';
import { Carousel } from '@sonnen/shared-web/src/components/Carousel/Carousel.component';
import { PageSubheadline } from '@sonnen/shared-web/src/components/PageSubheadline/PageSubheadline.component';
import { breakpointDown } from '@sonnen/shared-web/src/utils/breakpoints';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { getSlides } from './SonnenDriveCars.helper';

import './SonnenDriveCars.component.scss';

export const SonnenDriveCars: React.FC = () => {
  const slides = getSlides();

  return (
    <div className={'c-sonnen-drive-cars'}>
      <PageSubheadline>
        {I18n.t(T.drive.cars.title)}
      </PageSubheadline>
      <div className={'c-sonnen-drive-cars__carousel'}>
        <Carousel
          arrowsOnDesktop={false}
          arrowsOnMobile={false}
          autoplay={true}
          infinite={false}
          autoplaySpeed={10000}
          slidesToShow={3}
          slidesToScroll={3}
          speed={600}
          responsive={[
            {
              breakpoint: breakpointDown('MD'),
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
            {
              breakpoint: breakpointDown('XS'),
              settings: {
                autoplaySpeed: 5000,
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 300,
                initialSlide: 1,
              },
            },
          ]}
        >
          {slides.map(({
            featured,
            image,
            name,
            price,
            priceSubline,
            newPrice,
            newPriceSubline,
            amount,
            button,
          }) => {
  
            return (
              featured ?
                <SonnenDriveCarsFeaturedSlide
                  key={uuid.v4()}
                  image={image}
                  name={name}
                  price={price}
                  priceDescription={priceSubline}
                  amount={amount}
                  button={button}
                />
                :
                <SonnenDriveCarsSlide
                  key={uuid.v4()}
                  image={image}
                  name={name}
                  price={price}
                  priceSubline={priceSubline}
                  newPrice={newPrice}
                  newPriceSubline={newPriceSubline}
                  amount={amount}
                />
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};
