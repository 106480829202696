import { getAllFlatOffers, getFlatProduct } from '+app/+lead/+offer/store/+offer.helper';
import { getLeadCommonState, getLeadOverview } from '+app/+lead/store/+lead.selectors';
import { OfferProductStatus } from '+app/shared/store/lead/types';
import { getStatus } from '+shared/store/query/query.utils';
import { createSelector } from 'reselect';
import {
  CLOSE_LEAD_QUERY,
  DELETE_OFFER_QUERY,
  GET_LEAD_PRODUCT_AVAILABILITY_QUERY,
  GET_OFFER_LIST_QUERY,
  GET_PARTNER_NOTE_QUERY,
  GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY,
  GET_PRODUCT_BATTERY_LIST_QUERY,
  POST_PARTNER_NOTE_QUERY,
  RECALCULATE_CONFIGURATION_QUERY,
  SEND_CG_INVITATION_QUERY,
} from './+overview.state';

/**
 * @deprecated: Use selector from `+lead.selectors.tsx`
 */
export const getLeadData = createSelector(
  getLeadCommonState,
  state => state.lead,
);

/**
 * @deprecated: Use selector from `+lead.selectors.tsx`
 */
export const getLeadId = createSelector(
  getLeadData,
  lead => lead
    ? lead.id
    : null,
);

export const getOfferList = createSelector(
  getLeadOverview,
  state => state.offers,
);

export const getOfferConfigurationList = createSelector(
  getLeadOverview,
  state => state.configurations,
);

export const getOfferConfigurationListQueries = createSelector(
  getLeadOverview,
  state => state.configurationsQueries,
);

export const getOfferListQuery = createSelector(
  getLeadOverview,
  state => state[GET_OFFER_LIST_QUERY],
);

export const getOfferListQueryStatus = createSelector(
  getOfferListQuery,
  query => getStatus(query),
);

export const getLeadOfferDeleteQuery = createSelector(
  getLeadOverview,
  state => state[DELETE_OFFER_QUERY],
);

export const getLeadOfferDeleteQueryStatus = createSelector(
  getLeadOfferDeleteQuery,
  query => getStatus(query),
);

export const getLastUpdatedLeadSection = createSelector(
  getLeadOverview,
  state => state.lastUpdatedLeadSection,
);

export const isOpeningDocument = createSelector(
  getLeadOverview,
  state => state.isOpeningDocument,
);

export const getFailedDocument = createSelector(
  getLeadOverview,
  state => state.failedDocument,
);

export const getIsFailingDocument = createSelector(
  getLeadOverview,
  state => state.failedDocumentModal,
);

export const getDocumentUrl = createSelector(
  getLeadOverview,
  state => state.documentUrl,
);

export const getPartnerNoteQuery = createSelector(
  getLeadOverview,
  state => state[GET_PARTNER_NOTE_QUERY],
);

export const getPartnerNoteQueryStatus = createSelector(
  getPartnerNoteQuery,
  query => getStatus(query),
);

export const postPartnerNoteQuery = createSelector(
  getLeadOverview,
  state => state[POST_PARTNER_NOTE_QUERY],
);

export const postPartnerNoteQueryStatus = createSelector(
  postPartnerNoteQuery,
  query => getStatus(query),
);

export const getPartnerNotes = createSelector(
  getLeadOverview,
  state => state.partnerNotes,
);

export const getUpdatingConfigurationId = createSelector(
  getLeadOverview,
  state => state.updatingConfigurationId,
);

export const getRecalculateConfigurationQuery = createSelector(
  getLeadOverview,
  state => state[RECALCULATE_CONFIGURATION_QUERY],
);

export const getRecalculateConfigurationQueryStatus = createSelector(
  getRecalculateConfigurationQuery,
  getStatus,
);

export const getCloseLeadQuery = createSelector(
  getLeadOverview,
  state => state[CLOSE_LEAD_QUERY],
);

export const getCloseLeadQueryStatus = createSelector(
  getCloseLeadQuery,
  query => getStatus(query),
);

export const getLeadOverviewProductAvailability = createSelector(
  getLeadOverview,
  state => state.productAvailability,
);

export const getLeadOverviewProductAvailabilityQuery = createSelector(
  getLeadOverview,
  state => state[GET_LEAD_PRODUCT_AVAILABILITY_QUERY],
);

export const getLeadOverviewProductAvailabilityStatus = createSelector(
  getLeadOverviewProductAvailabilityQuery,
  query => getStatus(query),
);

export const getLeadOverviewProductAvailabilityForAddress = createSelector(
  getLeadOverview,
  state => state.productAvailabilityForAddress,
);

export const getLeadOverviewProductAvailabilityForAddressQuery = createSelector(
  getLeadOverview,
  state => state[GET_PRODUCT_AVAILABILITY_FOR_ADDRESS_QUERY],
);

export const getLeadOverviewProductAvailabilityForAddressStatus = createSelector(
  getLeadOverviewProductAvailabilityForAddressQuery,
  query => getStatus(query),
);

export const getAreAnyFlatOfferSent = createSelector(
  getLeadOverview,
  state => getAllFlatOffers(state.offers).some(offer => [
    OfferProductStatus.SENT,
    OfferProductStatus.ACCEPTED,
  ].includes(getFlatProduct(offer)!.status)),
);

export const getSendCGInvitationQuery = createSelector(
  getLeadOverview,
  state => state[SEND_CG_INVITATION_QUERY],
);

export const getSendCGInvitationQueryStatus = createSelector(
  getSendCGInvitationQuery,
  query => getStatus(query),
);

export const getNewLeadAddress = createSelector(
  getLeadOverview,
  state => state.newLeadAddress,
);

export const getNewAddressErrorActivity = createSelector(
  getLeadOverview,
  state => state.isNewAddressErrorActive,
);

export const getOfferIdToChangeStatus = createSelector(
  getLeadOverview,
  state => state.offerIdToChangeStatus,
);

export const getProductBatteryList = createSelector(
  getLeadOverview,
  state => state.productBatteryList,
);

export const getProductBatteryListQuery = createSelector(
  getLeadOverview,
  state => state[GET_PRODUCT_BATTERY_LIST_QUERY],
);

export const getProductBatteryListQueryStatus = createSelector(
  getProductBatteryListQuery,
  query => getStatus(query),
);

export const getDisplayMaxOffersWarning = createSelector(
  getLeadOverview,
  state => state.displayMaxOffersWarning,
);
