import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeDetailList } from './CustomerPvSystemWidget.helper';

interface Props {
  battery: Battery | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  className?: ClassValue;
}

export const CustomerPvSystemWidget: React.SFC<Props> = ({ className, queryStatus, battery }) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icofont type={'pv'} />}
    heading={I18n.t(T.customerSingle.overview.pvSystem.headline)}
  >
    {queryStatus.pending && (
      <Loader />
    )}
    {queryStatus.success && battery && (
      <DetailList list={factorizeDetailList(battery)} />
    )}
    {queryStatus.error && (
      <WidgetWarning 
        type={'warning'} 
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.pvSystem.error)}
      </WidgetWarning>
    )}
  </Widget>
);
