import {
  eco6BatteryCapacityMap,
  eco7BatteryCapacityMap,
  eco8BatteryCapacityMap,
  isEcoBattery,
} from '+app/+lead/+configuration/components/LeadConfigurationBatteryCapacities';
import { isFlatDirectConfiguration, isFlatXConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { isFlatOnlyOffer } from '+app/+lead/+offer/store/+offer.helper';
import { isConfigurationMultiplePv } from '+app/+lead/store/+lead.helper';
import { CurrencyUnit } from '+app/App.constants';
import { FeatureName } from '+config';
import {
  formatMeasurementEnergy,
  formatMeasurementPeakPower,
  formatPricePerKwh,
} from '+lead-configuration/components/LeadConfigurationPreview';
import { batteryModelNameMap } from '+shared/store/lead/lead.helpers';
import {
  BatteryModelName,
  LeadConfigurationResponseAttributes,
  LeadConfigurationResponsePvSystem,
  LeadOffer,
} from '+shared/store/lead/types';
import { formatCurrency, formatDate, formatDegree, formatPercentage } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { useFeature } from '@sonnen/shared-web';
import { isEmpty } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationLineType } from '../LeadConfigurationLine';

const isLargeConsumersFlagEnabled = useFeature(FeatureName.CONFIGURATION_LARGE_CONSUMERS).isEnabled;

export const factorizeConfMainDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit,
) => [
    // Free usage allowance
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.userAllowance)}:`,
      value: formatMeasurementEnergy(configuration.usageAllowancePerYear) +
        `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      type: LeadConfigurationLineType.LARGE,
    },
    // Basic Fee
    {
      title: isFlatDirectConfiguration(configuration) ?
        `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyFee)}:`
        : `${I18n.t(T.lead.configuration._salessolution_.preview.basicFee)}:`,
      value: formatCurrency(configuration.baseFeeGrossPerMonth.value, currency)
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
      type: LeadConfigurationLineType.LARGE,
    },
  ];

export const factorizeConfDetails = (conf: LeadConfigurationResponseAttributes, currency: CurrencyUnit) => [
  // Monthly pre-payment
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.monthlyPrepayment),
    value: formatCurrency(conf.initialAdvancePaymentGrossPerMonth.value, currency)
      + `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
    type: LeadConfigurationLineType.BOLD,
  },
  // Excess energy
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessEnergy),
    value: formatMeasurementEnergy(conf.powerPlant.expectedExcessEnergyPerYear)
      + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: LeadConfigurationLineType.LIGHT,
  },
  // Excess tariff
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.excessTarriff),
    value: formatPricePerKwh(conf.excessTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
    type: LeadConfigurationLineType.LIGHT,
  },
  // Expected cashback
  {
    title: I18n.t(T.lead.configuration._salessolution_.preview.expectedCashback),
    value: formatCurrency(conf.powerPlant.expectedCashbackGrossPerYear.value, currency)
      + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: LeadConfigurationLineType.BOLD,
  },
  // Expected unused free usage allowance
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUnusedUsageAllowance)}:`,
    value: formatMeasurementEnergy(conf.powerPlant.expectedUnusedUsageAllowancePerYear)
      + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    type: LeadConfigurationLineType.LIGHT,
  },
  // Cashback rate
  {
    title: `${I18n.t(T.lead.configuration._salessolution_.preview.cashbackRate)}:`,
    value: formatPricePerKwh(conf.cashbackTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
    type: LeadConfigurationLineType.LIGHT,
  },
  // VPP bonus
  {
    title: I18n.t(T.lead.configuration._salessolution_.vpp.title),
    value: conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusGrossPerYear ? (
      `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)} `
      + formatCurrency(conf.powerPlant.guaranteedVppBonusGrossPerYear.value, currency)
      + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
    ) : (
        I18n.t(T.lead.configuration._salessolution_.vpp.notEligible)
      ),
    type: LeadConfigurationLineType.BOLD,
  },
];

export const factorizeHouseholdDetails = (conf: LeadConfigurationResponseAttributes) => {
  const hasEVehicle = !isEmpty(conf?.expectedElectricityConsumption.electricCar);
  const hasHeatPump = !isEmpty(conf?.expectedElectricityConsumption.heatPump);

  return [
    {
      title: I18n.t(T.lead.configuration._salessolution_.preview.totalElectricityConsumption),
      value: formatMeasurementEnergy(conf.expectedElectricityConsumption.totalConsumptionPerYear)
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
    },
    {
      title: I18n.t(T.lead.configuration._salessolution_.preview.autarky)
        + getLargeConsumersMessage(hasEVehicle, hasHeatPump),
      value: formatPercentage(conf.powerPlant.expectedAutarky?.value, 2),
    },
  ];
};

const mapRoofDetailsName = (photovoltaicSystem:
  LeadConfigurationResponseAttributes['photovoltaicSystem'] | LeadConfigurationResponsePvSystem) =>
  photovoltaicSystem.inclination?.value
    ? I18n.t(T.lead.configuration._salessolution_.pv.orientationInclination)
    : I18n.t(T.lead.configuration._salessolution_.pv.specificYield);

const mapRoofDetailsValue = (photovoltaicSystem:
  LeadConfigurationResponseAttributes['photovoltaicSystem'] | LeadConfigurationResponsePvSystem) =>
  photovoltaicSystem.inclination?.value && photovoltaicSystem.orientation?.value
    ? `${formatDegree(photovoltaicSystem.orientation.value)}, ${formatDegree(photovoltaicSystem.inclination.value)}`
    : `${photovoltaicSystem.specificYieldPerYear?.value} ${photovoltaicSystem.specificYieldPerYear?.unit}`
    + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`;

export const factorizePvSystemDetails = (conf: LeadConfigurationResponseAttributes) => [
  {
    title: mapRoofDetailsName(conf.photovoltaicSystem),
    value: mapRoofDetailsValue(conf.photovoltaicSystem),
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.systemPower),
    value: formatMeasurementPeakPower(conf.photovoltaicSystem.peakPower),
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.commDate),
    value: formatDate(conf.photovoltaicSystem.commissioningDate),
  },
];

export const factorizeMultiplePvSystemDetails = (pvSystem: LeadConfigurationResponsePvSystem) => [
  {
    title: mapRoofDetailsName(pvSystem),
    value: mapRoofDetailsValue(pvSystem),
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.systemPower),
    value: formatMeasurementPeakPower(pvSystem.peakPower),
  },
  {
    title: I18n.t(T.lead.configuration._salessolution_.pv.commDate),
    value: formatDate(pvSystem.commissioningDate),
  },
];

const mapEcoBatteryCapacity = (capacity: number, batteryModel: BatteryModelName) => {
  switch (batteryModel) {
    case BatteryModelName.SONNENBATTERIE_ECO_6:
      return eco6BatteryCapacityMap(capacity);
    case BatteryModelName.SONNENBATTERIE_ECO_7:
      return eco7BatteryCapacityMap(capacity);
    case BatteryModelName.SONNENBATTERIE_ECO_8:
      return eco8BatteryCapacityMap(capacity);
    default:
      return undefined;
  }
};

export const factorizeBatteryDetails = (conf: LeadConfigurationResponseAttributes) => [
  {
    title: I18n.t(T.customerSingle.overview.battery.model),
    value: batteryModelNameMap(conf.battery.modelName.value),
  },
  {
    title: I18n.t(T.customerSingle.overview.battery.capacity),
    value: isEcoBattery(conf.battery.modelName.value)
      ? mapEcoBatteryCapacity(
        parseFloat(conf.battery.capacityGross.value.toString()) * 1000,
        conf.battery.modelName.value,
      )
      : formatMeasurementEnergy(conf.battery.capacityGross, 1),
  },
];

export const getImpactAnalysisNotAvailableMsg = (configuration: LeadConfigurationResponseAttributes) => {
  if (isFlatDirectConfiguration(configuration)) {
    return I18n.t(T.lead.overview._salessolution_.warning.featureAvailableSoon);
  }

  if (isConfigurationMultiplePv(configuration)) {
    return I18n.t(T.lead.overview._salessolution_.warning.iaNotAvailableForMultiplePV);
  }

  return undefined;
};

export const getLargeConsumersMessage = (hasEVehicle: boolean, hasHeatPump: boolean) => {
  if (!hasEVehicle && !hasHeatPump) {
    return '';
  }

  const includingMsg = ` ${I18n.t(T.lead.configuration._salessolution_.largeConsumers.including)} `;
  const eVehicleMsg = hasEVehicle ? I18n.t(T.lead.configuration._salessolution_.largeConsumers.eVehicle) : '';
  const heatPumpMsg = hasHeatPump ? I18n.t(T.lead.configuration._salessolution_.largeConsumers.heatPump) : '';
  const andMsg = hasEVehicle && hasHeatPump
    ? ` ${I18n.t(T.lead.configuration._salessolution_.largeConsumers.and)} `
    : '';
  return includingMsg + eVehicleMsg + andMsg + heatPumpMsg;
};

const isFlatDirectWithExistingHardware = (configuration: LeadConfigurationResponseAttributes | undefined, offer: LeadOffer) =>
  isFlatDirectConfiguration(configuration) && isFlatOnlyOffer(offer);

const isFlatDirectWithNewHardware = (configuration: LeadConfigurationResponseAttributes | undefined, offer: LeadOffer) =>
  isFlatDirectConfiguration(configuration) && !isFlatOnlyOffer(offer);

const isFlatXWithExistingHardware = (configuration: LeadConfigurationResponseAttributes | undefined, offer: LeadOffer) =>
  isFlatXConfiguration(configuration) && isFlatOnlyOffer(offer);

const isFlatXWithNewHardware = (configuration: LeadConfigurationResponseAttributes | undefined, offer: LeadOffer) =>
  isFlatXConfiguration(configuration) && !isFlatOnlyOffer(offer);

export const getProductName = (configuration: LeadConfigurationResponseAttributes | undefined, offer: LeadOffer): string => {
  if (isFlatDirectWithExistingHardware(configuration, offer)) {
    return I18n.t(T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatDirectWithExistingHardware);
  }
  if (isFlatDirectWithNewHardware(configuration, offer)) {
    return I18n.t(T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatDirectWithNewHardware);
  }
  if (isFlatXWithExistingHardware(configuration, offer)) {
    return I18n.t(T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatXWithExistingHardware);
  }
  if (isFlatXWithNewHardware(configuration, offer)) {
    return I18n.t(T.lead._salessolution_.configurations.configurationTileHeader.sonnenFlatXWithNewHardware);
  }
  return 'Unknown offer';
};
