import { CurrencyUnit, EnergyUnit, OtherUnit, PeakPowerUnit } from '+app/App.constants';

export type MeasurementUnit =
  | EnergyUnit
  | PeakPowerUnit
  | CurrencyUnit
  | OtherUnit
  ;

export interface LeadMeasurement<T extends MeasurementUnit, U = number | string> {
  value: U;
  unit: T;
  source?: string;
}

export const getMeasurementFallback = <T extends MeasurementUnit>(unit: T): LeadMeasurement<T> => ({
  value: 0,
  unit,
});
