import { Button, ButtonSize } from '@sonnen/shared-web';
import * as React from 'react';
import * as uuid from 'uuid/v4';

import { NewsFormCustomer } from '+app/+newsChannel/components/NewsFormCustomer/NewsFormCustomer.component';
import { NewsFormCustomerHelper } from '+app/+newsChannel/components/NewsFormCustomer/NewsFormCustomer.helpers';
import { withFirebase } from '+shared/components/FirebaseContext';
import { Firebase } from '+shared/store/firebase/firebase.client';
import { Language, NewsCP, NewsFormCP, Options, Portal } from '+shared/store/firebase/types';

import './SendNewsCustomer.component.scss';

type Props = {
  firebase: Firebase,
};

export const SendNewsCustomerComponent: React.FC<Props> = ({
  firebase,
}) => {
  const [isPending, setIsPending] = React.useState(false);
  const [formError, setFormError] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);

  const onSubmit = ({
    countries,
    excludeInRoutes,
    expiryDateTimestamp: formExpiryDateTimestamp,
    includeInRoutes,
    status: formStatus,
    messageLongUk,
    messageLongUs,
    messageLongDe,
    messageLongIt,
    messageShortUk,
    messageShortUs,
    messageShortDe,
    messageShortIt,
    options,
    optionsBatteriesControllerType,
    optionsBatteriesAccessories,
    optionsContractType,
    optionsTariffType,
  }: NewsFormCP) => {
    setIsPending(true);

    const id = uuid();
    const status = NewsFormCustomerHelper.STATUS.find(status => status.label === formStatus);

    const news: NewsCP = {
      countries,
      excludeInRoutes,
      expiryDateTimestamp: new Date(formExpiryDateTimestamp),
      id,
      includeInRoutes,
      levelValue: status && status.value || 1,
      messages: {
        [Language.UK]: {
          long: messageLongUk,
          short: messageShortUk,
        },
        [Language.US]: {
          long: messageLongUs,
          short: messageShortUs,
        },
        [Language.DE]: {
          long: messageLongDe,
          short: messageShortDe,
        },
        [Language.IT]: {
          long: messageLongIt,
          short: messageShortIt,
        },
      },
      portals: [Portal.CP],
      options,
      optionsDetails: {
        [Options.BATTERIES]: {
          controllerType: optionsBatteriesControllerType,
          accessories: optionsBatteriesAccessories,
        },
        [Options.CONTRACTS]: {
          contractType: optionsContractType,
          tariffType: optionsTariffType,
        },
      },
    };

    firebase.addNews(Portal.CP, news)
      .then(() => {
        setIsPending(false);
        setFormError('');
        setIsSuccess(true);
      })
      .catch((err: Error) => {
        setIsPending(false);
        setFormError(err.message);
      });
  };

  return isSuccess
    ? (
      <>
        <div className={'c-send-news-customer__success-text'}>
          Your news has been sent!
        </div>
        <Button
          type={'submit'}
          label={'Send Another News'}
          size={ButtonSize.SECONDARY}
          onClick={() => setIsSuccess(false)}
        />
      </>
    ) : (
      <NewsFormCustomer
        onSubmit={onSubmit}
        isLoading={isPending}
        formError={formError}
      />
    );
};

export const SendNewsCustomer = withFirebase(SendNewsCustomerComponent);
