import { getErrors } from '+setupTool/store/+setupTool.selectors';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon, Modal, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './SetupToolRefreshPageModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  errors: getErrors(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const SetupToolRefreshPageModalComponent: React.FC<Props> = ({ errors }) => {
  return !!(errors && errors.includes(I18n.t(T.setupTool.error.patchSubmission))) ? (
    <Modal
      className={'setup-tool-refresh-page-modal'}
      size={'small'}
      isOpen={true}
      onClose={() => window.location.reload()}
    >
      <ModalLayout>
        <Icon.DocumentUfo
          className={'setup-tool-refresh-page-modal__ufo-icon'}
        />
        <p className={'setup-tool-refresh-page-modal__title'}>
          {I18n.t(T.lead.overview._salessolution_.errorTitle)}
        </p>
        <p className={'setup-tool-refresh-page-modal__description'}>
          {I18n.t(T.setupTool.refreshPageModal.description)}
        </p>
        <Button
          name={'setup-tool-refresh-page-modal__button'}
          className={'setup-tool-refresh-page-modal__button'}
          type={'submit'}
          label={I18n.t(T.setupTool.refreshPageModal.buttonLabel)}
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.PRIMARY}
          onClick={() => window.location.reload()}
        />
      </ModalLayout>
    </Modal>
  ) : null;
};

export const SetupToolRefreshPageModal = connect(mapStateToProps, null)(SetupToolRefreshPageModalComponent);
