import { Data, MergedData } from '@coolio/json-api';

export const SF_ACCOUNT_FIELD_NAME = 'AccountId';
export const SF_CONTRACT_FIELD_NAME = 'Contract__c';

export enum CaseRecordType {
  TECHNICAL_SUPPORT_PROBLEM = 'TechnicalSupportProblem',
  E_SERVICES_INTERN = 'eServicesIntern',
  E_SERVICES_SYSTEM = 'eServicesSystem',
  PROTOTYPE_SALES = 'PrototypeSales',
  PRESALES = 'PreSales',
  SERVICE = 'Service',
  SALES = 'Sales',
  TECHNICAL_SUPPORT_CASE = 'TechnicalSupportCase',
  TECHNICAL_SUPPORT_MAJOR = 'TechnicalSupportMajor',
  E_SERVICES = 'eServices',
  E_SERVICES_SALES = 'eServicesSales',
}

export enum CasePriority {
  NORMAL = 'Normal',
  HIGH = 'High',
}

export enum CaseType {
  CHANGE = 'Change',
  COMMUNITY = 'Community',
  ENERGY = 'Energy',
  EXTENSION = 'Extension',
  INCIDENT = 'Incident',
  LUMENAZA = 'Lumenaza',
  INTEGRATION = 'Integration',
  PROBLEM = 'Problem',
  REPAIR = 'Repair',
  REQUEST = 'Request',
}

// Note: there are multiple more statuses, but in this case we need only "New"
export enum CaseStatus {
  NEW = 'New',
}

export enum CaseCountry {
  AU = 'AU',
  DACH = 'DACH',
  IT = 'IT',
  ROW = 'ROW',
  UK = 'UK',
  US = 'US',
}

export enum CaseOrigin {
  PHONE_B2B = 'Phone B2B',
  PHONE_B2C = 'Phone B2C',
  CHAT = 'Chat',
  APP = 'App',
  CUSTOMER_PORTAL = 'Customer Portal',
  EMAIL = 'Email',
  INTERNAL = 'Internal',
  MONITORING = 'Monitoring',
  PARTNER_PORTAL = 'Partner Portal',
  SERVICE_PORTAL = 'ServicePortal',
  WEBSITE = 'Website',
}

export enum CaseCategory {
  SERVICE_ACTIVATION = 'Service activation',
  CONTRACT_DATA_CHANGE = 'Contract data change',
  BOOKING = 'Booking',
  INVOICE = 'Invoice',
  METER_CHANGE = 'Meter change',
  DIRECT_MARKETING = 'Direct marketing',
  FLEXIBILITY_MARKETING = 'Flexibility marketing',
  PRODUCT_INFORMATION = 'Product information',
  TERMINATION = 'Termination',
  REMAINING = 'Remaining',
  PORTAL = 'Portal',
}

export enum CaseSubcategory {
  WELCOME_CALL = 'welcome call',
  INFORMATION_FOR_STARTING_DELIVERY  = 'Information for starting delivery',
  SPECIAL_REQUESTS = 'special requests',
  // and many more categories, but which would we like to use?
}

export interface DynamicField {
  field: string;
  value: string;
}

export interface CreateCaseAttributes {
  recordType: CaseRecordType;
  subject: string;
  description: string;
  type: CaseType;
  priority: CasePriority;
  status: CaseStatus;
  contactId: string;
  country: CaseCountry;
  origin: CaseOrigin;
  category?: CaseCategory | undefined;
  subcategory?: CaseSubcategory | undefined;
  // Note: asset/battery id
  asset?: string;
  dynamicFields?: DynamicField[];
}

export type CreateCaseData = Data<CreateCaseAttributes>;
export type CreateCase = MergedData<CreateCaseData>;
