import { Config } from '+config';
import { httpClient } from '+shared/network/network.client';
import { from } from 'rxjs';

const BASE_URL = `${Config.FIREBASE.firebaseFunctionsUrl}/news`;

const postNewsMarkAsRead = (newsId: string) => from(
  httpClient.post(`${BASE_URL}/mark-as-read/${newsId}`)
    .then(body => body.parsedBody()),
);

export const FirebaseRepository = {
  postNewsMarkAsRead,
};
