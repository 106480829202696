import * as React from 'react';

export const FirebaseContext = React.createContext({});

export const withFirebase = <P extends {}>(Component: React.ComponentType<P>) => (props: any) => (
  <FirebaseContext.Consumer>
    {firebase =>
      <Component
        {...props}
        firebase={firebase}
      />
    }
  </FirebaseContext.Consumer>
);
