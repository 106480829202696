import {
  CaseAggregatedStatus,
  CaseSortKey,
  CaseStatus,
} from '+shared/store/case/types/case.interface';
import { T } from '@sonnen/shared-i18n/service';

export const sortOptions = [
  { name: T.cases.list.header.lastModifiedAt, key: CaseSortKey.LAST_MODIFIED_AT },
  { name: T.cases.list.header.createdAt, key: CaseSortKey.CREATED_AT },
];

export const statusfilterAggregates = {
  [CaseAggregatedStatus.IN_PROGRESS]: [ 
    CaseStatus.WAITING_FOR_CUSTOMER_REPLY,
    CaseStatus.WAITING_FOR_EXTERNAL_SERVICE,
    CaseStatus.WAITING_FOR_INTERNAL_PROCESSING,
    CaseStatus.WAITING_FOR_FIELD_SERVICE,
    CaseStatus.WAITING_FOR_SPARE_PART_DELIVERY,
    CaseStatus.WAITING_FOR_CASE_RESOLUTION,
    CaseStatus.WAITING_FOR_REPAIR,
    CaseStatus.WAITING_FOR_ADMIN,
    CaseStatus.WAITING_FOR_DEVELOPMENT,
    CaseStatus.WORKAROUND,
    CaseStatus.OBSERVE,
  ],
  [CaseAggregatedStatus.RESOLVED]: [
    CaseStatus.DONE,
    CaseStatus.CLOSED, 
    CaseStatus.CANCELLED,
    CaseStatus.CLOSED_CANCELLED,
    CaseStatus.CLOSED_DONE,
    CaseStatus.DONE_PROD,
    CaseStatus.CANCELLED_PROD,
    CaseStatus.CLOSED_PROD,
    CaseStatus.CLOSED_DUPLICATE,
    CaseStatus.CLOSED_WAITING_FOR_CUSTOMER_REPLAY,
  ],
  [CaseAggregatedStatus.OPEN]: [
    CaseStatus.OPEN,
    CaseStatus.NEW,
  ],
};
