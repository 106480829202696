import { PATHS } from '+app/router';
import { LeadNew } from '+lead/containers';
import { Container, FormHeader } from '+shared/components';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

const mapStateToProps = () => ({
  // @TODO: Implement when neccessary
});

const mapDispatchToProps = mapActions({
  goToLeads: () => push(PATHS.LEADS()),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const LeadCreatorComponent: React.FC<Props> = ({ actions }) => (
  <>
    <FormHeader
      onClick={actions.goToLeads}
      title={I18n.t(T.lead._salessolution_.createTitle)}
      breadcrumb={I18n.t(T.customerSingle.header.breadcrumb)}
    />
    <Container>
      <LeadNew />
    </Container>
  </>
);

export const LeadCreator = connect(mapStateToProps, mapDispatchToProps)(LeadCreatorComponent);
