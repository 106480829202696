import { LeadCountry } from '+shared/store/lead/types';

const AreaCallingCodes = {
  DE: '+49',
  CH: '+41',
  AT: '+43',
};

export const getAreaCallingCode = (
  code: LeadCountry,
  defaultCode: number = 0,
) => code in AreaCallingCodes
  ? AreaCallingCodes[code]
  : defaultCode;
