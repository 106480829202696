import { T } from '@sonnen/shared-i18n/service';
import { Loadable, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './Login.component.scss';

const LoginLoader: React.SFC = () => (
  <div className={'c-login-loader'}>
    <Loader />
    <div className={'c-login-loader__headline'}>
      {I18n.t(T.general.login.title)}
    </div>
  </div>
);

export const Login: React.SFC = () => (
  <Loadable
    predicate={true}
    transition={'fade'}
    loader={<LoginLoader />}
  />
);
