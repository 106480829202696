import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export enum LeadStage {
  INBOX = 'inbox',
  OPEN = 'open',
  IN_SETUP = 'inSetup',
  CONTACTED = 'contacted',
  ON_SITE_VISIT_ARRANGED = 'onSiteVisitArranged',
  ON_SITE_VISIT_DONE = 'onSiteVisitDone',
  INVITATION_SENT = 'invitationSent',
  OFFER_SENT = 'offerSent',
  OFFER_ACCEPTED = 'offerAccepted',
  ORDER_CONFIRMED = 'orderConfirmed',
  HW_ALREADY_SOLD = 'hardwareAlreadySold',
  CLOSED_DEAD = 'closedDead',
  RESUBMISSION = 'resubmission',
  COMPLAINT = 'complaint',
  CANCELLATION = 'cancellation',
}

export enum LeadStatusName {
  INBOX = 'inbox',
  OPEN = 'open',
  IN_SETUP = 'inSetup',
  CONTACTED = 'contacted',
  ON_SITE_VISIT_ARRANGED = 'onSiteVisitArranged',
  ON_SITE_VISIT_DONE = 'onSiteVisitDone',
  INVITATION_SENT = 'invitationSent',
  HW_OFFER_SENT = 'hardwareOfferSent',
  FLAT_OFFER_SENT = 'flatOfferSent',
  /** For now this status update should not be displayed in the UI */
  HW_OFFER_ACCEPTED = 'hardwareOfferAccepted',
  FLAT_OFFER_ACCEPTED = 'flatOfferAccepted',
  HW_ORDER_CONFIRMED = 'hardwareOrderConfirmed',
  FLAT_ORDER_CONFIRMED = 'flatOrderConfirmed',
  HW_ALREADY_SOLD = 'hardwareAlreadySold',
  CLOSED_DEAD = 'closedDead',
  RESUBMISSION = 'resubmission',
  COMPLAINT = 'complaint',
  CANCELLATION = 'cancellation',
  DSO_REGISTRATION_COMPLETED = 'dsoRegistrationCompleted',
  DSO_COMMISSIONING_COMPLETED = 'dsoCommissioningCompleted',
  BNETZA_REGISTRATION_COMPLETED = 'bNetzARegistrationCompleted',
  TAX_OFFICE_REGISTRATION_COMPLETED = 'taxOfficeRegistrationCompleted',
  HW_COMMISSIONING_COMPLETED = 'hardwareCommissioningCompleted',
  DSO_METER_INSTALLED = 'dsoMeterInstalled',
  START_OF_DELIVERY_FIXED = 'startOfDeliveryFixed',
  VPP_PREPARATION_DONE = 'vppPreparationDone',
  GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED = 'guaranteeTermsAndConditionsConfirmed',
}

export const LeadStatusNameMapper = (name: LeadStatusName) => ({
  [LeadStatusName.OPEN]: I18n.t(T.shared._salessolution_.lead.status.open),
  [LeadStatusName.IN_SETUP]: I18n.t(T.shared._salessolution_.lead.status.inSetup),
  [LeadStatusName.CONTACTED]: I18n.t(T.shared._salessolution_.lead.status.contacted),
  [LeadStatusName.ON_SITE_VISIT_ARRANGED]: I18n.t(T.shared._salessolution_.lead.status.onSiteVisitArranged),
  [LeadStatusName.ON_SITE_VISIT_DONE]: I18n.t(T.shared._salessolution_.lead.status.onSiteVisitDone),
  [LeadStatusName.INVITATION_SENT]: I18n.t(T.shared._salessolution_.lead.status.invitationSent),
  [LeadStatusName.HW_OFFER_SENT]: I18n.t(T.shared._salessolution_.lead.status.hwOfferSent),
  [LeadStatusName.FLAT_OFFER_SENT]: I18n.t(T.shared._salessolution_.lead.status.flatOfferSent),
  [LeadStatusName.HW_OFFER_ACCEPTED]: I18n.t(T.shared._salessolution_.lead.status.hwOfferAccepted),
  [LeadStatusName.FLAT_OFFER_ACCEPTED]: I18n.t(T.shared._salessolution_.lead.status.flatOfferAccepted),
  [LeadStatusName.HW_ORDER_CONFIRMED]: I18n.t(T.shared._salessolution_.lead.status.hwOrderConfirmed),
  [LeadStatusName.FLAT_ORDER_CONFIRMED]: I18n.t(T.shared._salessolution_.lead.status.flatOrderConfirmed),
  [LeadStatusName.HW_ALREADY_SOLD]: I18n.t(T.shared._salessolution_.lead.status.hardwareAlreadySold),
  [LeadStatusName.CLOSED_DEAD]: I18n.t(T.shared._salessolution_.lead.status.closedDead),
  [LeadStatusName.RESUBMISSION]: I18n.t(T.shared._salessolution_.lead.status.resubmission),
  [LeadStatusName.COMPLAINT]: I18n.t(T.shared._salessolution_.lead.status.complaint),
  [LeadStatusName.CANCELLATION]: I18n.t(T.shared._salessolution_.lead.status.cancellation),
  [LeadStatusName.DSO_REGISTRATION_COMPLETED]: I18n.t(T.shared._salessolution_.lead.status.dsoRegistrationCompleted),
  [LeadStatusName.DSO_COMMISSIONING_COMPLETED]: I18n.t(T.shared._salessolution_.lead.status.dsoCommissioningCompleted),
  [LeadStatusName.BNETZA_REGISTRATION_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.status.bNetzARegistrationCompleted),
  [LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.status.taxOfficeRegistrationCompleted),
  [LeadStatusName.HW_COMMISSIONING_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.status.hardwareCommissioningCompleted),
  [LeadStatusName.DSO_METER_INSTALLED]: I18n.t(T.shared._salessolution_.lead.status.dsoMeterInstalled),
  [LeadStatusName.START_OF_DELIVERY_FIXED]: I18n.t(T.shared._salessolution_.lead.status.startOfDeliveryFixed),
  [LeadStatusName.VPP_PREPARATION_DONE]: I18n.t(T.shared._salessolution_.lead.status.vppPreparationDone),
})[name];

export interface StatusUpdate<S extends LeadStage, N extends LeadStatusName> {
  stage: S;
  name: N;
  isSet: boolean;
  createdAt: Date;
  reason?: string;
  description?: string;
  hierarchy?: number | null;
}

export type Inbox = StatusUpdate<LeadStage.INBOX, LeadStatusName.INBOX>;
export type Open = StatusUpdate<LeadStage.OPEN, LeadStatusName.OPEN>;
export type InSetup = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.IN_SETUP>;
export type Contacted = StatusUpdate<LeadStage.CONTACTED, LeadStatusName.CONTACTED>;
export type OnSiteVisitArranged = StatusUpdate<LeadStage.ON_SITE_VISIT_ARRANGED, LeadStatusName.ON_SITE_VISIT_ARRANGED>;
export type OnSiteVisitDone = StatusUpdate<LeadStage.ON_SITE_VISIT_DONE, LeadStatusName.ON_SITE_VISIT_DONE>;
export type InvitationSent = StatusUpdate<LeadStage.INVITATION_SENT, LeadStatusName.INVITATION_SENT>;
export type HardwareOfferSent = StatusUpdate<LeadStage.OFFER_SENT, LeadStatusName.HW_OFFER_SENT>;
export type FlatOfferSent = StatusUpdate<LeadStage.OFFER_SENT, LeadStatusName.FLAT_OFFER_SENT>;
export type HardwareOfferAccepted = StatusUpdate<LeadStage.OFFER_ACCEPTED, LeadStatusName.HW_OFFER_ACCEPTED>;
export type FlatOfferAccepted = StatusUpdate<LeadStage.OFFER_ACCEPTED, LeadStatusName.FLAT_OFFER_ACCEPTED>;
export type HardwareOrderConfirmed = StatusUpdate<LeadStage.ORDER_CONFIRMED, LeadStatusName.HW_ORDER_CONFIRMED>;
export type FlatOrderConfirmed = StatusUpdate<LeadStage.ORDER_CONFIRMED, LeadStatusName.FLAT_ORDER_CONFIRMED>;
export type HardwareAlreadySold = StatusUpdate<LeadStage.HW_ALREADY_SOLD, LeadStatusName.HW_ALREADY_SOLD>;
export type ClosedDead = StatusUpdate<LeadStage.CLOSED_DEAD, LeadStatusName.CLOSED_DEAD>;
export type Resubmission = StatusUpdate<LeadStage.RESUBMISSION, LeadStatusName.RESUBMISSION>;
export type Complaint = StatusUpdate<LeadStage.COMPLAINT, LeadStatusName.COMPLAINT>;
export type Cancellation = StatusUpdate<LeadStage.CANCELLATION, LeadStatusName.CANCELLATION>;
export type DsoRegistrationCompleted = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.DSO_REGISTRATION_COMPLETED>;
export type DsoCommissioningCompleted = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.DSO_COMMISSIONING_COMPLETED>;
export type BNetzARegistrationCompleted = StatusUpdate<LeadStage.IN_SETUP,
  LeadStatusName.BNETZA_REGISTRATION_COMPLETED>;
// TO DO: LeadStages below (without VPP) are probably not set properly => US: SON-2499
export type TaxOfficeRegistrationCompleted = StatusUpdate<LeadStage.IN_SETUP,
  LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED>;
export type HardwareCommissioningCompleted = StatusUpdate<LeadStage.IN_SETUP,
  LeadStatusName.HW_COMMISSIONING_COMPLETED>;
export type DsoMeterInstalled = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.DSO_METER_INSTALLED>;
export type StartOfDeliveryFixed = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.START_OF_DELIVERY_FIXED>;
export type VppPreparationDone = StatusUpdate<LeadStage.IN_SETUP, LeadStatusName.VPP_PREPARATION_DONE>;

export type LeadStatusUpdate =
  | Inbox
  | Open
  | InSetup
  | Contacted
  | OnSiteVisitArranged
  | OnSiteVisitDone
  | InvitationSent
  | HardwareOfferSent
  | FlatOfferSent
  | HardwareOfferAccepted
  | FlatOfferAccepted
  | HardwareOrderConfirmed
  | FlatOrderConfirmed
  | HardwareAlreadySold
  | ClosedDead
  | Resubmission
  | Complaint
  | Cancellation
  | DsoRegistrationCompleted
  | DsoCommissioningCompleted
  | BNetzARegistrationCompleted
  | TaxOfficeRegistrationCompleted
  | HardwareCommissioningCompleted
  | DsoMeterInstalled
  | StartOfDeliveryFixed
  | VppPreparationDone
  ;

export interface LeadStatus {
  currentStatus: LeadStatusUpdate;
  summary: {
    [LeadStatusName.INBOX]: Inbox | null;
    [LeadStatusName.OPEN]: Open | null;
    [LeadStatusName.IN_SETUP]: InSetup | null;
    [LeadStatusName.CONTACTED]: Contacted | null;
    [LeadStatusName.ON_SITE_VISIT_ARRANGED]: OnSiteVisitArranged | null;
    [LeadStatusName.ON_SITE_VISIT_DONE]: OnSiteVisitDone | null;
    [LeadStatusName.INVITATION_SENT]: InvitationSent | null;
    [LeadStatusName.HW_OFFER_SENT]: HardwareOfferSent | null;
    [LeadStatusName.FLAT_OFFER_SENT]: FlatOfferSent | null;
    [LeadStatusName.HW_OFFER_ACCEPTED]: HardwareOfferAccepted | null;
    [LeadStatusName.FLAT_OFFER_ACCEPTED]: FlatOfferAccepted | null;
    [LeadStatusName.HW_ORDER_CONFIRMED]: HardwareOrderConfirmed | null;
    [LeadStatusName.FLAT_ORDER_CONFIRMED]: FlatOrderConfirmed | null;
    [LeadStatusName.HW_ALREADY_SOLD]: HardwareAlreadySold | null;
    [LeadStatusName.CLOSED_DEAD]: ClosedDead | null;
    [LeadStatusName.RESUBMISSION]: Resubmission | null;
    [LeadStatusName.COMPLAINT]: Complaint | null;
    [LeadStatusName.CANCELLATION]: Cancellation | null;
    [LeadStatusName.DSO_REGISTRATION_COMPLETED]: DsoRegistrationCompleted | null;
    [LeadStatusName.DSO_COMMISSIONING_COMPLETED]: DsoCommissioningCompleted | null;
    [LeadStatusName.BNETZA_REGISTRATION_COMPLETED]: BNetzARegistrationCompleted | null;
    [LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED]: TaxOfficeRegistrationCompleted | null;
    [LeadStatusName.HW_COMMISSIONING_COMPLETED]: HardwareCommissioningCompleted | null;
    [LeadStatusName.DSO_METER_INSTALLED]: DsoMeterInstalled | null;
    [LeadStatusName.START_OF_DELIVERY_FIXED]: StartOfDeliveryFixed | null;
    [LeadStatusName.VPP_PREPARATION_DONE]: VppPreparationDone | null;
  };
}

// DTO's
export interface UpdateStatusDto {
  name: LeadStatusName;
  isSet?: boolean;
  createdAt?: Date;
  reason?: string;
  description?: string;
}

// Status groups
export type InSetupStatusUpdate =
  | DsoCommissioningCompleted
  | DsoRegistrationCompleted
  | BNetzARegistrationCompleted
  | TaxOfficeRegistrationCompleted
  | HardwareCommissioningCompleted
  | DsoMeterInstalled
  | VppPreparationDone
  | StartOfDeliveryFixed
  ;

export type HardwareStatusUpdate =
  | Contacted
  | OnSiteVisitArranged
  | OnSiteVisitDone
  | HardwareOfferSent
  | HardwareOfferAccepted
  | HardwareOrderConfirmed
  | HardwareAlreadySold
  ;

export type FlatStatusUpdate =
  | InvitationSent
  | FlatOfferSent
  | FlatOfferAccepted
  | FlatOrderConfirmed
  ;

export type IgnoredStatusUpdate = InvitationSent;

export type CommonStatusUpdate =
  Exclude<LeadStatusUpdate,
    | HardwareStatusUpdate
    | FlatStatusUpdate
    | InSetupStatusUpdate
    | IgnoredStatusUpdate
  >;

export const FLAT_OFFER_STATUS_NAMES: LeadStatusName[] = [
  LeadStatusName.INVITATION_SENT,
  LeadStatusName.FLAT_OFFER_SENT,
  LeadStatusName.FLAT_OFFER_ACCEPTED,
  LeadStatusName.FLAT_ORDER_CONFIRMED,
];

export const HARDWARE_OFFER_STATUS_NAMES: LeadStatusName[] = [
  LeadStatusName.CONTACTED,
  LeadStatusName.ON_SITE_VISIT_ARRANGED,
  LeadStatusName.ON_SITE_VISIT_DONE,
  LeadStatusName.HW_OFFER_SENT,
  LeadStatusName.HW_OFFER_ACCEPTED,
  LeadStatusName.HW_ORDER_CONFIRMED,
];

export const IN_SETUP_STATUS_NAMES: LeadStatusName[] = [
  LeadStatusName.DSO_COMMISSIONING_COMPLETED,
  LeadStatusName.DSO_REGISTRATION_COMPLETED,
  LeadStatusName.BNETZA_REGISTRATION_COMPLETED,
  LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED,
  LeadStatusName.HW_COMMISSIONING_COMPLETED,
  LeadStatusName.DSO_METER_INSTALLED,
  LeadStatusName.VPP_PREPARATION_DONE,
];

export const UNSUPPORTED_STATUS_NAMES: LeadStatusName[] = [
  LeadStatusName.HW_OFFER_ACCEPTED,
];
