import { CustomerBatteryUrlParams } from '+app/router/store/types/customerBatteryUrlParams.interface';
import { GET_SITE_LIVE_STATE_QUERY, SiteActions } from '+shared/store/site';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { mapActions } from '../../../utils/redux/mapActions.util';

import './LiveStateRefresher.component.scss';

type Props = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = mapActions({
  getSiteLiveState: SiteActions.getSiteLiveState,
});

const LiveStateRefresherComponent: React.FC<Props> = (props) => {
  const params = useParams<CustomerBatteryUrlParams>();

  const refresh = () => {
    props.actions.getSiteLiveState({queryKey: GET_SITE_LIVE_STATE_QUERY, siteId: params.siteId});
  };

  return (
    <div 
      className={'c-icon--refresh'} 
      onClick={refresh}
    >
      <Icon.Refresh />
    </div>
  );
};

export const LiveStateRefresher = connect(null, mapDispatchToProps)(LiveStateRefresherComponent);
