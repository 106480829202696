import { Widget } from '+shared/components';
import { goTo } from '+utils/browser.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonTheme, Icofont } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getCustomerPortalImage, getCustomerPortalLink } from './CustomerOldPortalWidget.helper';

import './CustomerOldPortalWidget.component.scss';

interface Props {
  batterySerialNumber?: string;
  className?: ClassValue;
}

export const CustomerOldPortalWidget: React.FC<Props> = ({
  batterySerialNumber, className,
}) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icofont type={'house-on-hill'} />}
    image={getCustomerPortalImage()}
    heading={I18n.t(T.customerSingle.overview.portal.headline)}
  >
    <span className={'c-customer-old-portal-widget__info'}>
      {I18n.t(T.customerSingle.overview.portal.description)}
    </span>
    <div className={'gtm-customer-old-portal-widget__button'}>
      <Button
        theme={ButtonTheme.OUTLINE}
        isBlock={true}
        label={I18n.t(T.customerSingle.overview.portal.link)}
        onClick={() => goTo(getCustomerPortalLink(batterySerialNumber), '_blank')}
      />
    </div>
  </Widget>
);
