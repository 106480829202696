import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

interface Props {
  item: Lead;
}

export const LeadListBattery: React.FC<Props> = ({ item }) => {
  return (
    <div className={`c-lead-list-battery`}>
      {item.batteryData && item.batteryData.batteryType
        ? (
          <>
            <p className={'c-lead-list-battery__paragraph'}>
              {item.batteryData.batteryType}
            </p>
            <p className={'c-lead-list-battery__paragraph'}>
              {item.batteryData.batteryNominalCapacitykWh + ' kWh'}
            </p>
          </>
        )
        : (
          <>
            <Media query={{ maxWidth: breakpointUp('LG') }}>
              {(isMedium: boolean) => isMedium ?
                (<p>[{I18n.t(T.lead.list._salessolution_.battery)}] -</p>)
                :
                (<p>-</p>)
              }
            </Media>
          </>
        )
      }
    </div>
  );
};
