import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { FormInputRadio } from '../FormInputRadio';

import './FormInputRadioGroup.component.scss';

interface RadioInput {
  label: string;
  value: unknown;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
}

interface Props<T> {
  name: string;
  text?: string;
  form: FormikProps<T>;
  className?: ClassValue;
  collection: RadioInput[];
  isVertical?: boolean;
  disabled?: boolean;
}

export const FormInputRadioGroup = <T extends any = any>({
  form,
  name,
  text,
  className,
  collection,
  isVertical,
  disabled = false,
}: Props<T>) => {
  return (
    <div className={classNames('c-form-input-radio-group', className)}>
      {text && <p className={'c-form-input-radio-group__text'}>{text}</p>}
      <div className={classNames('c-form-input-radio-group__container',
        { 'c-form-input-radio-group__container--vertical': isVertical })}
      >
        {collection.map(radioButton => (
          <FormInputRadio
            key={radioButton.label}
            form={form}
            label={radioButton.label}
            name={name}
            value={radioButton.value}
            className={'c-form-input-radio-group__item'}
            onClick={radioButton.onClick}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};
