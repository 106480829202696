import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { dataGuard, ofType, processQuery } from '../../../utils';
import { ChargerActions } from './charger.actions';
import { ChargerRepository } from './charger.repository';

type Action$ = ActionsObservable<ChargerActions>;

export const getChargerLiveState$ = (action$: Action$) => action$.pipe(
  ofType(ChargerActions.getChargerLiveState),
  mergeMap(({ queryKey, chargerId }) => of({}).pipe(
    processQuery(
      queryKey,
      () => ChargerRepository.getChargerLiveState(chargerId),
      { onSuccess: res => dataGuard(ChargerActions.setChargerLiveState)(res!.element) },
    ),
  )),
);

export const epics = combineEpics<any>(
  getChargerLiveState$,
);
