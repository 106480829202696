import { T } from '@sonnen/shared-i18n/service';
import { isNumber } from 'lodash';
import { defaults } from 'lodash/fp';
import * as moment from 'moment';
import { MomentInput } from 'moment';
import { I18n } from 'react-redux-i18n';
import { CurrencyUnit, EnergyUnit, HOUR_UNIX, MINUTE_UNIX, PeakPowerUnit } from '../App.constants';
import { provideCurrentLocale } from '../i18n/i18n.provider';

/**
 * @deprecated use localized `formatNumber` from `format.util.tsx` instead
 */
export const formatNumber = (
  { precision = 0, minPrecision = precision }:
  { precision?: number, minPrecision?: number },
) => (value: number): string =>
  value.toLocaleString(provideCurrentLocale(), {
    minimumFractionDigits: minPrecision,
    maximumFractionDigits: precision,
  });

/**
 * @deprecated use localized `formatDate` from `format.util.tsx` instead
 */
export const formatDate = (value: number | MomentInput, customFormat?: string): string =>
  (isNumber(value) ? moment.unix(value) : moment(value)).format(customFormat || 'L');

export const formatTime = (time: MomentInput): string =>
  moment(time).format('H:mm');

export const formatDuration = (duration: string): string => {
  let seconds = moment.duration(duration).asSeconds();
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  seconds = seconds % 60;
  minutes = minutes % 60;

  const pad = (num: number) => ('0' + num).slice(-2);

  const hoursString = hours > 0 ? `${pad(hours)}:` : '';

  return `${hoursString}${pad(minutes)}:${pad(seconds)}`;
};

export const formatTimeBetween = (start: MomentInput, end: MomentInput): string => {
  const seconds = Math.abs(moment(end).unix() - moment(start).unix());
  const hours = Math.floor(seconds / HOUR_UNIX);
  const minutes = Math.floor((seconds / MINUTE_UNIX) - (hours * MINUTE_UNIX));
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes} h`;
};

export const formatSerialNumber = (serialNumber: string) =>
  `# ${serialNumber}`;

/**
 * @deprecated use localized `formatPercentage` from `format.util.tsx` instead
 */
export const formatPercentage = (value: number, precision?: number) =>
  `${formatNumber({ precision })(value)}%`;

/**
 * @deprecated use localized `formatEnergy` from `format.util.tsx` instead
 */
export const formatEnergy = (
  { whValue, unit = EnergyUnit.WH, precision = 1, minPrecision = precision }:
  { whValue: number, unit: EnergyUnit, precision: number, minPrecision?: number },
) =>
  I18n.t(
    unit === EnergyUnit.KWH
      ? T.units.energyKwh
      : T.units.energyWh,
    {
      value: formatNumber({ precision, minPrecision })(
        unit === EnergyUnit.KWH ? whValue / 1000 : whValue,
      ),
    },
  );

/**
 * @deprecated use localized `formatPower` from `format.util.tsx` instead
 */
export const formatPower = (powerW: number, unit: EnergyUnit = EnergyUnit.W, precision = 0) =>
  I18n.t(
    unit === EnergyUnit.KW
      ? T.units.energykW
      : T.units.energyW,
    {
      value: formatNumber({ precision })
        (
          unit === EnergyUnit.KW
            ? powerW / 1000
            : powerW,
        ),
    },
  );

/**
 * @deprecated use localized `formatPeakPower` from `format.util.tsx` instead
 */
export const formatPeakPower = (wpValue: number, unit: PeakPowerUnit = PeakPowerUnit.WP, precision = 1) =>
  I18n.t(
    unit === PeakPowerUnit.KWP
      ? T.units.peakPowerKwp
      : T.units.peakPowerWp,
    {
      value: formatNumber({ precision })(
        unit === PeakPowerUnit.KWP
          ? wpValue / 1000
          : wpValue,
      ),
    },
  );

export const formatBatteryConsumption = (value: number) =>
  I18n.t(T.units.batteryConsumption, { value: formatNumber({ precision: 1 })(value) });

export const formatAmperage = (value: number) =>
  I18n.t(T.units.amperage, {
    value: formatNumber({ precision: value <= 0.01 ? 0 : value < 10 ? 1 : 0 })(value),
  });

export const checkEnergyUnit = (value: number) =>
  value < 1000 ? EnergyUnit.WH : EnergyUnit.KWH;

export const formatDegree = (value: number | string) =>
  `${value}°`;

interface FormatCurrencyOpts {
  isMinorUnit?: boolean;
  precision?: number;
}

export const formatCurrency = (
  value: number | string,
  currency: CurrencyUnit,
  opts?: FormatCurrencyOpts,
): string => {
  const { isMinorUnit, precision } = defaults({
    isMinorUnit: false,
    precision: 2,
  })(opts);
  const formattedValue = formatNumber({ precision })(Number(value));

  switch (currency) {
    case CurrencyUnit.CURRENCY_EUR:
      if (isMinorUnit) {
        return `${formattedValue} ct`;
      }
      return `${formattedValue} €`;
    default:
      return String(value);
  }
};

export const formatSquareMeters = (value: number | string) =>
  `${value} m²`;

export const formatTextWithSpecialCharacters = (text: string) =>
  text.replace( /ß/g, `<span style="text-transform: none">ß</span>`);
