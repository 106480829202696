import { StatusTileColors } from '+app/shared/components/StatusTile';
import { Battery } from '+shared/store/battery';
import { getBatteryModelWithCapacity } from '+shared/store/battery/battery.helpers';
import { getBatteryWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { TableCell } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { CustomersTableStatusTile } from '../CustomersTableStatusTile';

import './CustomersTableBattery.component.scss';

interface Props {
  battery: Battery;
  className?: ClassValue;
}

export const CustomersTableBattery: React.FC<Props> = ({
  battery,
  className,
}) => {
  const { serialNumber, installationDate, liveState: siteLiveState } = battery;
  const classes = classNames('c-customers-table-battery', className);
  const warnings = getBatteryWarnings({ battery, siteLiveState });

  return (
    <TableCell className={classes}>
      <div className={'c-customers-table-battery__container'}>
        <div className={'c-customers-table-battery__row'} data-hj-suppress={true}>
          <div className={'c-customers-table__paragraph c-customers-table-battery__p--bold'}>
            {getBatteryModelWithCapacity(battery)}
          </div>
          <div className={'c-customers-table__paragraph c-customers-table-battery__p--fixed'}>
            {I18n.t(T.dashboard.customersTable.serialNumber, { serialNumber })}
          </div>
        </div>
        <div className={'c-customers-table__warnings'}>
          {warnings.missingInstallationDate ? (
            <CustomersTableStatusTile
              message={warnings.missingInstallationDate.message}
              color={StatusTileColors.YELLOW}
            />
          ) : (
              I18n.t(T.dashboard.customersTable.installationDate, { installationDate: formatDate(installationDate!) })
            )}
          {warnings.batteryOffline && (
            <CustomersTableStatusTile
              message={warnings.batteryOffline.message}
              color={StatusTileColors.YELLOW}
            />
          )}
        </div>
      </div>
    </TableCell>
  );
};
