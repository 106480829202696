import { AddVideo } from '+app/+newsChannel/components/AddVideo';
import { VideoList } from '+app/+newsChannel/components/VideoList';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { getVideoList } from '+shared/store/firebase/firebase.selectors';
import { VideoFirebase } from '+shared/store/firebase/types';
import { isModalOpen, LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';
import { getFirstYoutubeVideo, getYoutubeVideoQueryStatus } from '+shared/store/youtube/youtube.selectors';
import { mapActions } from '+utils/redux/mapActions.util';
import { firestore } from 'firebase/app';
import * as React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: StoreState) => ({
  videoList: getVideoList(state),
  youtubeVideo: getFirstYoutubeVideo(state),
  youtubeVideoQueryStatus: getYoutubeVideoQueryStatus(state),
  isModalOpen: isModalOpen(state),
});

const mapDispatchToProps = mapActions({
  getCombinedVideoList: FirebaseActions.getCombinedVideoList,
  removeVideo: FirebaseActions.removeVideo,
  addVideo: FirebaseActions.addVideo,
  getYoutubeVideo: YoutubeActions.getYoutubeVideoList,
  clearYoutubeVideoList: YoutubeActions.clearYoutubeVideoList,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & FirebaseProps;

interface State {
  isPending: boolean;
}

export class VideoSectionComponent extends React.Component<Props, State> {
  state = {
    isPending: true,
  };

  componentDidMount() {
    const { firebase, actions } = this.props;

    firebase.getVideosList()
      .then((querySnapshot: firestore.QuerySnapshot) => {
        const videoList: VideoFirebase[] = [];

        querySnapshot.forEach((doc: any) => {
          const video: VideoFirebase = doc.data();
          videoList.push(video);
        });
        actions.getCombinedVideoList(videoList);
      })
      .catch((error: Error) => console.log('Firebase error:', error)) // @TODO display message for user
      .finally(() => this.setState({ isPending: false })); // @TODO move to store
  }

  deleteVideo = (videoId: string) => {
    const { firebase, actions } = this.props;

    firebase.deleteVideo(videoId)
      .catch((error: Error) => console.log('Firebase error:', error)) // @TODO display message for user
      .finally(() => actions.removeVideo(videoId));
  };

  render() {
    const {
      videoList,
      actions,
      youtubeVideo,
      firebase,
      youtubeVideoQueryStatus,
      isModalOpen,

    } = this.props;
    const { isPending } = this.state;

    return (
      <>
        <AddVideo
          youtubeVideoQueryStatus={youtubeVideoQueryStatus}
          getYoutubeVideo={actions.getYoutubeVideo}
          clearYoutubeVideoList={actions.clearYoutubeVideoList}
          addVideo={actions.addVideo}
          youtubeVideo={youtubeVideo}
          firebase={firebase}
          toggleModal={actions.toggleModal}
          isModalOpen={isModalOpen}

        />
        <VideoList
          items={videoList}
          isPending={isPending}
          deleteVideo={this.deleteVideo}
        />
      </>
    );
  }
}

export const VideoSection =
  connect(mapStateToProps, mapDispatchToProps)
  (withFirebase(VideoSectionComponent));
