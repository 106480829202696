import { getLeadDetailsList, LeadDetailsSection } from '+app/+lead/+overview/containers/LeadOverviewBasicDetails';
import { getLeadData } from '+app/+lead/+overview/store/+overview.selectors';
import {
  DsoRegistrationFormBreakLine,
  DsoRegistrationFormContainer,
  DsoRegistrationFormRadioGroup,
} from '+setupTool/+form/components';
import {
  DsoRegistrationCustomerDataFiles,
  DsoRegistrationCustomerDataInitial,
  DsoRegistrationCustomerDataSchema,
  saveableCustomerDataFields,
} from '+setupTool/+form/store/schemas';
import {
  DsoRegistrationCustomerDataInterface,
} from '+setupTool/+form/store/types';
import { getAdditionalFeatureVisibilityProp, VppDocumentationActions } from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SetupDataStepKey, SetupDataStepStatus, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { isEmptyValue, removeFile, sendFile } from '+setupTool/store/+setupTool.helpers';
import { getRegistrationSubject, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import {
  DatepickerArrowSide,
  DetailListTable,
  FormInput,
  FormInputDate,
  FormInputRadioGroup,
  FormInputSelect,
  FormSectionParagraph,
  FormUploadInput,
  FormWizardStep,
} from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { AdditionalFeatures, Dso } from '+shared/store/setupTool/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { filterByValue, searchByKey } from '+utils/array.util';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, Loader, PageSubheadline, WizardContext } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual, omitBy, pick, uniqBy } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  FileCategoryName,
  isOnlinePortalFieldName,
  radioInputCollection,
} from '../../store/+form.dictionary';
import {
  setAllFormFieldsTouched,
} from '../../store/+form.helpers';
import {
  getActiveStep,
  getCustomerDataForm,
  getDsoList,
  getGetDsoListQueryStatus,
  getPowerOfAttorneyDocumentSubmitQueryStatus,
  getUploadedDocuments,
} from '../../store/+form.selectors';
import '../DsoRegistrationDocuments/DsoRegistrationDocuments.component.scss';
import './DsoRegistrationCustomerData.component.scss';

interface OwnProps {
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLeadData(state),
  customerDataForm: getCustomerDataForm(state),
  dsoList: getDsoList(state),
  userId: getUserProfileId(state),
  submissionId: getSubmissionId(state),
  getDsoListQueryStatus: getGetDsoListQueryStatus(state),
  powerOfAttorneyDocumentSubmitQueryStatus: getPowerOfAttorneyDocumentSubmitQueryStatus(state),
  uploadedDocuments: getUploadedDocuments(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
});

const mapDispatchToProps = mapActions({
  setDsoCustomerData: DsoRegistrationFormActions.setDsoCustomerData,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  toggleModal: LayoutActions.toggleModal,
  setVppDsoReferenceNumberPvVisibility: VppDocumentationActions.setDsoReferenceNumberPvVisibility,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const isOnlinePortal = (portal: Dso) => !!portal && portal.portalType !== 'offline';

const DsoRegistrationCustomerDataComponent: React.FC<Props> = ({
  actions,
  customerDataForm,
  dsoList,
  userId,
  submissionId,
  getDsoListQueryStatus,
  powerOfAttorneyDocumentSubmitQueryStatus,
  lead,
  uploadedDocuments,
  activeStep,
  stepStatus,
  registrationSubject,
}) => {
  actions.toggleModal(true);
  const wizard = React.useContext(WizardContext);
  const formRef = React.useRef<FormikProps<DsoRegistrationCustomerDataInterface>>(null);
  const listOfDsoIDs = uniqBy([...dsoList], 'id').map(item => item.id);
  const [...leadData] = !!lead ? getLeadDetailsList(lead, false)
    .filter(el => el.id !== LeadDetailsSection.TITLE) : [];
  const validationSchema = DsoRegistrationCustomerDataSchema();

  React.useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_CUSTOMER_DATA);
    activeStep = SubmissionStep.SETUP_DATA_CUSTOMER_DATA;

    return saveValues;
  }, []);

  React.useEffect(() => {
    if (!formRef.current) {
      return;
    }
    const values = {
      ...formRef.current.values,
      ...(DsoRegistrationCustomerDataFiles.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData = documents
          && [uploadedDocuments[category]].map((item: any) => ({ 'id': item.id, 'fileName': item.fileName }));

        return { ...prev, [category]: documentsPreparedData };
      }, {})),
    };

    formRef.current.setValues(values);

  }, [uploadedDocuments]);

  React.useEffect(() => {
    if (!formRef.current) {
      return;
    }
    let values = formRef.current.values;

    if (customerDataForm) {
      values = {
        ...values,
        ...omitBy(customerDataForm, isEmptyValue),
      };
    }

    if (
      lead && lead.deliveryAddress && lead.phone) {
      values = {
        ...values,
        customer_salutation: lead.salutation,
        customer_first_name: lead.firstName,
        customer_last_name: lead.lastName,
        origin_customer_street_and_number: lead.invoiceAddress
          ? lead.invoiceAddress.street
          : lead.deliveryAddress.street,
        customer_zip_code: lead.invoiceAddress ? lead.invoiceAddress.zipCode : lead.deliveryAddress.zipCode,
        customer_city: lead.invoiceAddress ? lead.invoiceAddress.city : lead.deliveryAddress.city,
        customer_email: lead.email,
        customer_phone: lead.phone,
        origin_asset_street_and_number: lead.deliveryAddress.street,
        asset_zip_code: lead.deliveryAddress.zipCode,
        asset_city: lead.deliveryAddress.city,
      };
    }

    values = {
      ...values,
      ...(DsoRegistrationCustomerDataFiles.reduce((prev, category) => {
        const documents = uploadedDocuments[category];
        const documentsPreparedData = documents
          && [uploadedDocuments[category]].map((item: any) => ({ 'id': item.id, 'fileName': item.fileName }));

        return { ...prev, [category]: documentsPreparedData };
      }, {})),
    };

    formRef.current.setValues(values);

    if (stepStatus === SetupDataStepStatus.STARTED) {
      setAllFormFieldsTouched(formRef.current, [], [FileCategoryName.POWER_OF_ATTORNEY]);
    }

  }, [lead, customerDataForm]);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
      document.body.classList.add('body--disabled');
    }
  };

  const saveValues = () => {
    if (!formRef.current) {
      return;
    }
    const values = formRef.current.values;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
    const powerOfAttorneyTouched = formRef.current.touched[FileCategoryName.POWER_OF_ATTORNEY];
    const newStepStatus = invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;
    const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
      ({ ...prev, [fieldKey]: DsoRegistrationCustomerDataInitial[fieldKey] }), {});

    if (atLeastOneFieldTouched && customerDataForm
      && !isEqual(
        omitBy(pick(customerDataForm, saveableCustomerDataFields), isEmptyValue),
        omitBy(pick(values, saveableCustomerDataFields), isEmptyValue),
      )
    ) {
      actions.setSetupDataStatuses({ customerData: newStepStatus });
      actions.setDsoCustomerData({ ...values, ...invalidOrEmptyValues });
      actions.checkForm(registrationSubject);
      actions.saveData(activeStep, { [SetupDataStepKey.CUSTOMER_DATA]: newStepStatus });
    } else if (atLeastOneFieldTouched && (powerOfAttorneyTouched || newStepStatus !== stepStatus)) {
      actions.setSetupDataStatuses({ customerData: newStepStatus });
      actions.saveData(activeStep, { [SetupDataStepKey.CUSTOMER_DATA]: newStepStatus });
    }
  };

  return (
    <Formik
      initialValues={DsoRegistrationCustomerDataInitial}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={form => (
        <Form className={'c-form-wizard__form'}>
          <FormWizardStep
            onBeforeSubmit={() => {
              // Enable saving partially complete data
              onSubmit();
            }}
          >
            <DsoRegistrationFormContainer>
              <div className={'c-setup-tool__static-info'}>
                <PageSubheadline>
                  {I18n.t(T.setupTool.info.setupConfigurationInfo)}
                </PageSubheadline>
                <DetailListTable
                  list={leadData}
                  filterNil={true}
                  suppressRowValuesInHotjar={true}
                />
              </div>

              <div className={'c-form-section'}>
                <FormSectionParagraph>{I18n.t(T.setupTool.form.customerData)}</FormSectionParagraph>

                {/* DATE OF BIRTH */}
                <FormInputDate
                  minDate={new Date('1900-01-01')}
                  maxDate={moment().subtract('years', 18).toDate()}
                  className={'c-setup-tool-customer-data__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.birthdayDate)}
                  name={'customer_birthday'}
                  arrowPosition={DatepickerArrowSide.BOTTOM}
                />
              </div>
              <DsoRegistrationFormBreakLine />

              <DsoRegistrationFormRadioGroup>
                <FormInputRadioGroup
                  form={form}
                  name={'customer_type'}
                  text={I18n.t(T.setupTool.form.ownerOfProperty)}
                  collection={radioInputCollection}
                />
              </DsoRegistrationFormRadioGroup>
            </DsoRegistrationFormContainer>
            <DsoRegistrationFormContainer>
              {/* LOT NUMBER */}
              <FormInput
                className={'c-setup-tool-customer-data__item'}
                form={form}
                label={I18n.t(T.setupTool.form.lotNumber)}
                name={'lot_number'}
              />
              {/* DSO */}
              <FormInputSelect
                className={'c-setup-tool-customer-data__item'}
                form={form}
                label={I18n.t(T.setupTool.form.dsoRegistered)}
                collection={listOfDsoIDs}
                id={'setup-tool-customer-data__dso_id'}
                name={'dso_id'}
                onSelect={val => {
                  const item = searchByKey('id', val, dsoList);
                  form.setFieldValue(isOnlinePortalFieldName, isOnlinePortal(item));
                }}
                mapper={key => searchByKey('id', key, dsoList, 'name')}
                placeholder={I18n.t(T.setupTool.form.chooseDsoPlaceholder)}
                search={val =>
                  filterByValue(dsoList, 'name')(val).map(item => item.id)
                }
                noResultsComponent={!!getDsoListQueryStatus.pending ? <Loader /> : null}
              />
              <DsoRegistrationFormBreakLine />
              <BoldParagraph className={'c-setup-tool__upload-input-label'}>
                {I18n.t(T.setupTool.form.powerOfAttorney)} / <span>{I18n.t(T.setupTool.form.optional)}</span>
              </BoldParagraph>
              <FormUploadInput
                form={form}
                name={FileCategoryName.POWER_OF_ATTORNEY}
                accept={['application/pdf']}
                maxSize={20971520}
                maxSizeLabel={'20 MB'}
                label={I18n.t(T.setupTool.info.chooseFile)}
                placeholder={I18n.t(T.setupTool.info.fileUpTo, { extension: 'PDF', maxFileSize: '20 MB' })}
                isImmediatelySentToBackend={true}
                showErrorOnlyIfTouched={true}
                onUpload={(file) => {
                  if (lead && !!formRef.current) {
                    formRef.current.setTouched({ [FileCategoryName.POWER_OF_ATTORNEY]: true });
                    formRef.current.setFieldError(FileCategoryName.POWER_OF_ATTORNEY, '');
                    sendFile(
                      file,
                      FileCategoryName.POWER_OF_ATTORNEY,
                      userId,
                      lead.id,
                      submissionId,
                      actions.sendDsoDocumentsFile,
                    );
                  }
                }}
                isLoading={powerOfAttorneyDocumentSubmitQueryStatus.pending}
                onReset={(documentId) => {
                  if (documentId && !!formRef.current) {
                    formRef.current.setTouched({ [FileCategoryName.POWER_OF_ATTORNEY]: true });
                    removeFile(
                      documentId,
                      submissionId,
                      actions.removeDsoDocumentsFile,
                    );
                  }
                }}
              />
            </DsoRegistrationFormContainer>
          </FormWizardStep>
        </Form>
      )}
    />
  );
};

export const DsoRegistrationCustomerData = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationCustomerDataComponent);
