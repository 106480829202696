import { getStatus } from '+shared/store/query/query.utils';
import { getPVRegister } from '+shared/store/setupTool';
import { createSelector } from 'reselect';
import {
  PV_REGISTER_CONFIG_SUBMIT_QUERY,
  PV_REGISTER_MANUALLY_QUERY,
  PV_REGISTER_PATCH_SUBMISSION_QUERY,
} from './+pvRegister.state';

export const getPVRSubmission = createSelector(
  getPVRegister,
  state => state.submission,
);

export const getPVRSubmissionStatus = createSelector(
  getPVRSubmission,
  state => state ? state.status : '',
);

export const getRegisterPvrManuallyQuery = createSelector(
  getPVRegister,
  state => state[PV_REGISTER_MANUALLY_QUERY],
);

export const getRegisterPvrManuallyQueryStatus = createSelector(
  getRegisterPvrManuallyQuery,
  query => getStatus(query),
);

export const getPVRegistrationError = createSelector(
  getPVRegister,
  state => state.registrationError,
);

export const getPVRegistrationCaptcha = createSelector(
  getPVRegister,
  state => state.registrationCaptcha,
);

export const getPVRegisterDocuments = createSelector(
  getPVRegister,
  state => state.documents,
);

export const getPVRegisterDocumentsMultiuploadObject = createSelector(
  getPVRegisterDocuments,
  uploadedDocuments =>
    uploadedDocuments.reduce(
      (prev, document) => ({ ...prev, [document.upload.category]: prev[document.upload.category]
          ? [
            ...prev[document.upload.category],
            document,
          ]
          : [document],
      }),
      {},
    ),
);

export const getLatestPVRegisterDate = createSelector(
  getPVRegister,
  state => state.updatedAt,
);

export const getPVRPatchSubmissionQuery = createSelector(
  getPVRegister,
  state => state[PV_REGISTER_PATCH_SUBMISSION_QUERY],
);

export const getPVRPatchSubmissionQueryStatus = createSelector(
  getPVRPatchSubmissionQuery,
  state => getStatus(state),
);

export const getPVRConfigSubmitQuery = createSelector(
  getPVRegister,
  state => state[PV_REGISTER_CONFIG_SUBMIT_QUERY],
);

export const getPVRConfigSubmitQueryStatus = createSelector(
  getPVRConfigSubmitQuery,
  state => getStatus(state),
);
