import { CountryFeatureName } from '+config/countryFlags';
import {
  getPrimaryBatterySystem,
  getPrimaryContract,
} from '+customer-list/store/customer.helpers.tsx';
import { RoundButton } from '+shared/components';
import { CaseReportButton } from '+shared/components/CaseReportButton';
import { ActionName } from '+shared/GoogleTagManager';
import { Customer, Site } from '+shared/store/customer';
import { getStatus } from '+shared/store/query/query.utils';
import { Icon, TableCell, TableStack, useCountryFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { CustomersTableBattery } from '../CustomersTableBattery';
import { CustomersTableContract } from '../CustomersTableContract';
import { CustomersTableLocation } from '../CustomersTableLocation';
import { CustomersTableName } from '../CustomersTableName';
import { CustomersTableNoData } from '../CustomersTableNoData';
import { CustomersTableNote } from '../CustomersTableNote';

import './CustomersTableSite.component.scss';

interface Props {
  customer: Customer;
  site: Site;
  isFirst: boolean;
  isConsentGiven: boolean;
  contractQueryStatus: ReturnType<typeof getStatus>;
  onCaseReportButtonClick: () => void;
}

export const CustomersTableSite: React.FC<Props> = ({
  customer,
  site,
  isFirst,
  isConsentGiven,
  contractQueryStatus,
  onCaseReportButtonClick,
}) => {
  const battery = getPrimaryBatterySystem(site);
  const contract = getPrimaryContract(site);
  const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

  return (
    <div
      className={'c-customers-table-site'}
      data-testid={`c-customers-table-site-${site.id}`}
    >
      <TableCell
        className={'c-customers-table-site__table-cell'}
        stack={TableStack.HORIZONTAL}
      >
        <CustomersTableName
          firstName={customer.firstName}
          lastName={customer.lastName}
          name={customer.name}
          isFirst={isFirst}
          isConsentGiven={isConsentGiven}
        />
        <CustomersTableLocation
          site={site}
        />
        {!battery ? <CustomersTableNoData /> : (
          <CustomersTableBattery
            key={battery.id}
            battery={battery}
            className={'c-customers-table-site__battery'}
          />
        )}
        {isCustomerContractFeatureEnabled &&
          (!contract ? <CustomersTableNoData /> : (
            <CustomersTableContract
              key={contract.id}
              contract={contract}
              className={'c-customers-table-site__contract'}
            />
          )
        )}
        <TableCell className={'c-customers-table-site__note'}>
          {isFirst && isConsentGiven && (
            <CustomersTableNote
              key={'customer-note'}
              customerId={customer.id}
              customerNote={customer.installerNote || ''}
            />
          )}
        </TableCell>
        <TableCell className={'c-customers-table-site__case'}>
          <CaseReportButton
            isLoading={contractQueryStatus.pending}
            onClick={onCaseReportButtonClick}
            gtmActionName={ActionName.OPEN_MODAL_ON_CUSTOMER_LIST_PAGE}
          >
            <RoundButton
              label={<Icon.Plus className="case-report-button__icon"/>}
            />
          </CaseReportButton>
        </TableCell>
      </TableCell>
    </div>
  );
};
