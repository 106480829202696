// @TODO: Move to shared-web
import * as React from 'react';

import './ContractParagraph.component.scss';

export const ContractParagraph: React.FC = ({ children }) => (
  <p className={'c-contract-paragraph'}>
    {children}
  </p>
);
