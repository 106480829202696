import { RequestAccessForm } from '+app/+account/+company/components';
import { mapActions } from '+app/utils';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { CaseActions as SharedCaseActions, getCaseSubmitQueryStatus, getCreatedCaseNumber } from '+shared/store/case';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileSalesforceContactId } from '+shared/store/user/user.selectors';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './RequestAccess.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  queryStatus: getCaseSubmitQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  submitRequestAccess: SharedCaseActions.createCase,
  clearCaseStore: SharedCaseActions.clearCreatedCase,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

const RequestAccessComponent: React.FC<Props> = ({
  actions,
  openModalId,
  queryStatus,
  contactId,
  caseNumber,
}) => {
  const onOpen = () =>
    actions.toggleModal(true, ModalId.REQUEST_ACCESS);

  const onClose = () => {
    actions.clearCaseStore();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
  };

  const onTryAgain = () => {
    actions.clearCaseStore();
    setFormState(FormState.FORM);
  };

  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (queryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }

    if (queryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }
  }, [queryStatus]);

  return (
    <div className={'c-request-access'}>
      <PageSubheadline smallGap={true}>
        <div className={'c-request-access__wrapper'}>
          {I18n.t(T.companyAccount.requestAccess.title)}
          <LinkButton
            onClick={onOpen}
            className={'c-request-access__button'}
          >
            {I18n.t(T.companyAccount.requestAccess.buttons.open)}
          </LinkButton>
        </div>
      </PageSubheadline>
      <FormModal
        isModalOpen={openModalId === ModalId.REQUEST_ACCESS}
        status={formState}
        headlineSuccess={I18n.t(T.companyAccount.requestAccess.status.success, {caseId: caseNumber})}
        headlineFailure={I18n.t(T.companyAccount.requestAccess.status.failure)}
        onClose={onClose}
        onTryAgain={onTryAgain}
        defaultForm={
          <RequestAccessForm
            submitForm={actions.submitRequestAccess}
            onClose={onClose}
            isLoading={queryStatus.pending}
            contactId={contactId}
          />
        }
      />
    </div>
  );
};

export const RequestAccess = connect(mapStateToProps, mapDispatchToProps)(RequestAccessComponent);
