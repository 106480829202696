import { LeadForm } from '+app/+lead/store/types';
import { FormInput, FormInputIcon } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { Loader, WarningText } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadNewManualDeliveryAddress.component.scss';

interface Props {
  form: FormikProps<LeadForm>;
  isLoading: boolean;
}

export const LeadNewManualDeliveryAddress: React.FC<Props> = ({ form, isLoading }) => {
  const isFieldNotEmpty = (field: string) => (
    get(field)(form.values.deliveryAddress)
  );

  const hasFieldError = (field: string) => (
    get(field)(form.errors.deliveryAddress)
  );
  return (
    <>
      <div className={'c-lead-new-manual-delivery-address'}>
        {isLoading &&
          <div className={'c-lead-new-manual-delivery-address__loader'}>
            <Loader />
          </div>
        }
        
        <div className={'c-lead-new-manual-delivery-address__street-container'}>
          <div className={'c-lead-new-manual-delivery-address__street-wrapper'}>
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.street)}
              name={'deliveryAddress.street'}
              isRequired={true}
              icon={(isFieldNotEmpty('street') && !hasFieldError('street')) ?
                FormInputIcon.CHECKMARK : undefined
              }
            />
          </div>

          <div className={'c-lead-new-manual-delivery-address__house-no-wrapper'}>
            <FormInput
              form={form}
              label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.houseNo)}
              name={'deliveryAddress.houseNumber'}
              icon={(isFieldNotEmpty('houseNumber') && !hasFieldError('houseNumber')) ?
                FormInputIcon.CHECKMARK : FormInputIcon.WARNING
              }
              warningMessage={I18n.t(T.lead.boc._salessolution_.form.personalDetails.houseNoWarning)}
            />
          </div>
        </div>

        <FormInput
          form={form}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCode)}
          name={'deliveryAddress.zipCode'}
          isRequired={true}
          icon={FormInputIcon.WARNING}
          warningMessage={I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCodeWarning)}
        />

        <FormInput
          form={form}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.city)}
          name={'deliveryAddress.city'}
          isRequired={true}
          icon={(isFieldNotEmpty('city') && !hasFieldError('city')) ?
            FormInputIcon.CHECKMARK : undefined
          }
        />

      </div>
      <WarningText
        text={I18n.t(T.lead.boc._salessolution_.form.personalDetails.addressWarning)}
      />
    </>
  );
};
