import { trimFieldValues } from '+setupTool/+form/store/+form.helpers';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/index';
import { VPP_DOCUMENTATION_ACTIONS, VppDocumentationActions } from './+vppDocumentation.actions';
import { VppRegisterStatus } from './+vppDocumentation.dictionary';
import {
  initialState,
  VPP_CONFIG_SUBMIT_QUERY,
  VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
} from './+vppDocumentation.state';
import { VppDocumentationState } from './types';

export const reducer = createReducer<VppDocumentationState, VppDocumentationActions | VppDocumentationActions>(
  (state = initialState, action): VppDocumentationState => {
    switch (action.type) {
      case VPP_DOCUMENTATION_ACTIONS.SET_INITIAL_STATE:
        return {
          ...initialState,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_FIELDS:
        return {
          ...state,
          fields: {
            ...state.fields,
            ...trimFieldValues(action.fields),
          },
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_DSO_REFERENCE_NUMBER_PV_VISIBILITY:
        return {
          ...state,
          showDsoReferenceNumberPv: action.showDsoReferenceNumberPv,
          fields: {
            ...state.fields,
            dso_reference_number_pv:
              (!action.showDsoReferenceNumberPv && state.submissionStatus !== VppRegisterStatus.FINISH)
                ? undefined
                : state.fields.dso_reference_number_pv,
          },
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_VISIBILITY:
        return {
          ...state,
          showCompletionNotification: action.showCompletionNotification,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_UPLOADED_DOCUMENTS:
        return {
          ...state,
          uploadedDocuments: action.documents,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_DOCUMENT:
        return {
          ...state,
          completionNotificationDocument: action.document,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_MODIFICATION_REGENERATED_AT:
        return {
          ...state,
          completionNotificationRegeneratedAt: action.regeneratedAt,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_SUBMISSION_STATUS:
        return {
          ...state,
          submissionStatus: action.submissionStatus,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_VPP_LABELS_FOR_FLAT_DIRECT:
        return {
          ...state,
          vppLabelsForFlatDirect: action.vppLabelsForFlatDirect,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_PRODUCTION_METER_FIELD_VISIBILITY:
        return {
          ...state,
          productionMeterField: action.productionMeterField,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_METER_CABINET_PREPARED_FIELD:
        return {
          ...state,
          meterCabinetPreparedField: action.meterCabinetPreparedField,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_FASTENING_TYPE_GRID_FIELD:
        return {
          ...state,
          fasteningTypeConsumptionField: action.fasteningTypeConsumptionField,
        };
      case VPP_DOCUMENTATION_ACTIONS.SET_VPP_ADDITIONAL_FEATURES:
        return {
          ...state,
          vppAdditionalFeatures: action.vppAdditionalFeatures,
        };
      default:
        return queryReducer(state, action, [
          VPP_CONFIG_SUBMIT_QUERY,
          VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
        ]);
    }
  },
);
