import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './config/serviceWorker';

import '@sonnen/shared-web/_fonts.scss';
import './index.scss';

import { App } from '+app/App.component';
import { Reporter } from '+utils/reporter.util';

Reporter.init();
ReactDOM.render(<App />, document.getElementById('root'));

// when we will really need Service Worker, just register it and have fun...
serviceWorker.unregister();
