import { NumberField } from '+app/+lead/+configuration/store/types';
import { getLeadImpactAnalysis } from '+app/+lead/store/+lead.selectors';
import { CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY } from '+lead/+impactAnalysis/store/+impactAnalysis.state';
import { StoreState } from '+shared/store/store.interface';
import { fromMajorToMinor } from '+utils/currency.util';
import { createSelector } from 'reselect';
import {
  mapImpactAnalysisConsumptionFormToDto,
  mapImpactAnalysisCostsFormToDto,
  mapPdfCreationFormToDto,
} from './+impactAnalysis.helper';

export const getConsumptionForm = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.consumptionForm,
);

export const getConsumptionFormForView = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.consumptionForm
    ? {
      ...impactAnalysis.consumptionForm,
      baselineTariffPerKwh: impactAnalysis.consumptionForm.baselineTariffPerKwh
        ? fromMajorToMinor(impactAnalysis.consumptionForm.baselineTariffPerKwh)
        : '' as NumberField,
    }
    : undefined,
);

export const getImpactAnalysisList = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.impactAnalysisList,
);

export const getCostsForm = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.costsForm,
);

export const getProductSetupForm = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.productSetupForm,
);

export const getPdfCreationForm = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.pdfCreationForm,
);

export const getImpactAnalysis = createSelector(
  getConsumptionForm,
  // getProductSetupForm,
  getCostsForm,
  getPdfCreationForm,
  (consumptionForm, costsForm, pdfCreationForm) => (
    !consumptionForm || !costsForm || !pdfCreationForm
  ) ? undefined : ({
    consumption: mapImpactAnalysisConsumptionFormToDto(consumptionForm),
    costs: mapImpactAnalysisCostsFormToDto(costsForm),
    selectedPages: mapPdfCreationFormToDto(pdfCreationForm),
  }),
);

export const getImpactAnalysisRecommendationDto = createSelector(
  getConsumptionForm,
  // getProductSetupForm,
  getCostsForm,
  (consumptionForm, costsForm) => (
    !consumptionForm || !costsForm
  ) ? undefined : ({
    costs: mapImpactAnalysisCostsFormToDto(costsForm),
    consumption: mapImpactAnalysisConsumptionFormToDto(consumptionForm),
  }),
);

export const getImpactAnalysisRecommendation = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis.impactAnalysisRecommendation,
);

export const getImpactAnalysisRecommendationQuery = createSelector(
  getLeadImpactAnalysis,
  impactAnalysis => impactAnalysis[CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY],
);

export const getStepAccessMap = createSelector(
  getConsumptionForm,
  // getProductSetupForm,
  getCostsForm,
  (consumption) => ({
    consumption: true,
    productSetup: consumption !== undefined,
    // costs: productSetup !== undefined,
    costs: consumption !== undefined,
    calculationResults: consumption !== undefined,
    pdfCreation: consumption !== undefined,
  }),
);
