import { DetailList } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { SiteLiveState } from '+shared/store/site/types';
import { BatteryCell } from '@sonnen/shared-web';
import * as React from 'react';
import { factorizeBatteryLiveDetailList } from './BatteryLiveCardBody.helper';

import './BatteryLiveCardBody.component.scss';

interface Props {
  battery: Battery;
  siteLiveState: SiteLiveState;
  hasBackupDevice: boolean;
}

export const BatteryLiveCardBody: React.FC<Props> = ({
  battery,
  siteLiveState,
  hasBackupDevice,
}) => {
  const { backupPowerBuffer } = battery;
  const { batteryInverterState, batteryUsoc, online } = siteLiveState;
  const batteryChargeLevel = !!online ? batteryUsoc : 0;
  const batteryBackupLevel = backupPowerBuffer ? backupPowerBuffer : 0;
  const batteryStatus = (!!online && batteryInverterState) ? batteryInverterState : undefined;

  return(
    <div className={'c-battery-live-card-body'}>
      <div className={'c-battery-live-card-body__cell-wrapper'}>
        <BatteryCell
          chargeLevel={batteryChargeLevel}
          backupLevel={batteryBackupLevel}
          backupSlider={batteryBackupLevel}
          status={batteryStatus}
          hasBackupDevice={hasBackupDevice}
        />
      </div>
      <DetailList
        vertical={true} 
        list={factorizeBatteryLiveDetailList(battery, siteLiveState)}
        filterNil={true}
      />
    </div>
  );
};
