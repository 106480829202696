import { range } from 'lodash/fp';

type GetPagesCountOptions =  {
  recordsPerPage?: number,
  recordsTotal?: number,
};

export const minMaxBoundaries = 5;
export const middleBoundaries = 2;

export const getPagesCount = ({ recordsTotal, recordsPerPage}: GetPagesCountOptions = {}) =>
  Math.ceil((recordsTotal || 0) / (recordsPerPage || 1));

export const getPages = (min: number) => (max: number) =>
  range(min, max + 1);

export const getPagesToRender = (activePage: number) => (pages: number[]) => {
  const { length } = pages;

  if (isPaginationHead(activePage)) {
    return pages.slice(0, minMaxBoundaries);
  }

  if (isPaginationTail(activePage, length)) {
    return pages.slice(length - minMaxBoundaries, length);
  }

  return pages.slice(activePage - middleBoundaries - 1, activePage + middleBoundaries);
};

export const isPaginationHead = (activePage: number) =>
  0 < activePage && activePage < minMaxBoundaries;

export const isPaginationTail = (activePage: number, length: number) =>
  length - minMaxBoundaries + 1 < activePage && activePage <= length;
