import { T } from '@sonnen/shared-i18n/service';
import { EnergyUnit } from '@sonnen/shared-web';
import { isNumber } from 'lodash';
import { MomentInput } from 'moment';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { PeakPowerUnit } from '../App.constants';

export type FormatNumberOptions = { locale: string; precision?: number; minPrecision?: number };
export type FormatNumber<T = unknown> = (opts: FormatNumberOptions & T) => (value: string | number) => string;

/**
 * Formats provided value for given locale
 *
 * `formatNumber :: Opts -> (string | number) -> string`
 */
export const formatNumber: FormatNumber = opts => value =>
  Number(value).toLocaleString(opts.locale, {
    minimumFractionDigits: opts.minPrecision || opts.precision,
    maximumFractionDigits: opts.precision || 0,
  });

/**
 * Formats provided number into percentage value for given locale
 *
 * `formatPercentage :: Opts -> (string | number) -> string`
 */
export const formatPercentage: FormatNumber = opts => value =>
 `${formatNumber(opts)(value)}%`;

/**
 * Formats provided power value for given locale and energy unit `W`, `kW`, etc...
 *
 * `formatPower :: Opts -> (string | number) -> string`
 */
export const formatPower: FormatNumber<{ unit?: EnergyUnit }> = ({ locale, unit = EnergyUnit.W, precision = 0 }) => value =>
  I18n.t(
    unit === EnergyUnit.KW
      ? T.units.energykW
      : T.units.energyW,
    {
      value: formatNumber({ locale, precision })(
        unit === EnergyUnit.KW
          ? Number(value) / 1000
          : Number(value)),
    },
  );

/**
 * Formats provided peak power value for given locale and energy unit `W`, `kW`, etc...
 *
 * `formatPeakPower :: Opts -> (string | number) -> string`
 */
export const formatPeakPower: FormatNumber<{ unit?: PeakPowerUnit }> = ({ locale, unit = PeakPowerUnit.WP, precision = 1 }) => value =>
  I18n.t(
    unit === PeakPowerUnit.KWP
      ? T.units.peakPowerKwp
      : T.units.peakPowerWp,
    {
      value: formatNumber({ locale, precision })(
        unit === PeakPowerUnit.KWP
          ? Number(value) / 1000
          : Number(value),
      ),
    },
  );

/**
 * Formats provided energy value for given locale and energy unit
 *
 * `formatEnergy :: Opts -> (string | number) -> string`
 */
export const formatEnergy: FormatNumber<{ unit?: EnergyUnit }> = ({ locale, unit = EnergyUnit.WH, precision = 1, minPrecision }) => value =>
  I18n.t(
    unit === EnergyUnit.KWH
      ? T.units.energyKwh
      : T.units.energyWh,
    {
      value: formatNumber({ locale, precision, minPrecision })(
        unit === EnergyUnit.KWH
          ? Number(value) / 1000
          : Number(value),
      ),
    },
  );

/**
 * Formats provided date value for given locale and energy unit
 *
 * `formatDate :: Opts -> (string | number) -> string`
 */
export const formatDate = (value: number | MomentInput, customFormat?: string): string =>
  (isNumber(value)
    ? moment.unix(value)
    : moment(value)
  ).format(customFormat || 'L');
