import { EnergyUnit, OtherUnit, PeakPowerUnit } from '+app/App.constants';
import { Data, MergedData } from '@coolio/json-api';
import { BatteryModelName } from './leadBattery.interface';
import { LeadMeasurement } from './leadMeasurement.interface';

export enum ConfigurationPvType {
  SPECIFIC_YIELD = 'SPECIFIC_YIELD',
  DETAILED = 'DETAILED',
}

export interface SinglePhotovoltaicSystem {
  type: ConfigurationPvType;
  peakPower: LeadMeasurement<PeakPowerUnit.KWP>;
  inclination?: LeadMeasurement<OtherUnit.DEGREE>;
  orientation?: LeadMeasurement<OtherUnit.DEGREE>;
  specificYieldPerYear?: LeadMeasurement<EnergyUnit.KWH_PER_KWP>;
  commissioningDate: string;
  systemName?: string;
  systemKey?: string;
}

export interface LeadConfigurationAttributes {
  totalConsumptionPerYear: LeadMeasurement<EnergyUnit.KWH>;
  sonnenEstimatedAutarky: LeadMeasurement<OtherUnit.PERCENT, number>;
  photovoltaicSystem: SinglePhotovoltaicSystem | SinglePhotovoltaicSystem[];
  battery: {
    capacityGross: LeadMeasurement<EnergyUnit.KWH>;
    modelName: BatteryModelName;
  };
  powerPlant?: {
    expectedAutarky?: LeadMeasurement<OtherUnit.PERCENT, number>;
    dsoConsentToCombinePhotovoltaicSystems: boolean;
  };
  electricCar?: {
    totalConsumptionPerYear?: LeadMeasurement<EnergyUnit.KWH>;
    mileagePerYear?: LeadMeasurement<OtherUnit.KM>;
  };
  heatPump?: {
    totalConsumptionPerYear?: LeadMeasurement<EnergyUnit.KWH>;
  };
  valueAddedTax: LeadMeasurement<OtherUnit.PERCENT, number>;
}

export type LeadOfferConfigData = Data<LeadConfigurationAttributes>;
export type LeadOfferConfig = MergedData<LeadOfferConfigData>;
