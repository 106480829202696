import { Battery } from '+shared/store/battery';
import { Data, ListMetaData, SortOrder } from '@coolio/json-api';
import { CaseRecordType } from './createCase.interface';

export enum CaseAggregatedStatus {
  OPEN = 'open',
  RESOLVED = 'resolved',
  IN_PROGRESS = 'inProgress',
}

export const caseStatusFilterGroup = [
  CaseAggregatedStatus.OPEN,
  CaseAggregatedStatus.IN_PROGRESS,
  CaseAggregatedStatus.RESOLVED,
 ];

export enum CaseStatus {
  OPEN = 'Open',
  WAITING_FOR_CUSTOMER_REPLY = 'In Progress - Waiting for Reply by Customer',
  WAITING_FOR_EXTERNAL_SERVICE = 'Waiting for external Service',
  WAITING_FOR_INTERNAL_PROCESSING = 'Waiting for internal processing',
  WAITING_FOR_FIELD_SERVICE = 'Waiting for Field Service',
  WAITING_FOR_SPARE_PART_DELIVERY = 'Waiting for Spare Part Delivery',
  WAITING_FOR_CASE_RESOLUTION = 'Waiting for problem case resolution',
  WAITING_FOR_REPAIR = 'Waiting for Repair',
  WAITING_FOR_ADMIN = 'Waiting for Admin',
  WAITING_FOR_DEVELOPMENT = 'Waiting for Development',
  WORKAROUND = 'Workaround',
  OBSERVE = 'Observe',
  DONE = 'Done',
  DONE_PROD = 'done', // TODO align done statuses in SF or make API case insensitive
  CANCELLED = 'Cancelled',
  CANCELLED_PROD = 'cancelled', // TODO align cancel statuses in SF or make API case insensitive
  CLOSED_DONE = 'Closed - Done',
  CLOSED_CANCELLED = 'Closed - Cancelled',
  CLOSED = 'Closed',
  CLOSED_PROD = 'closed', // TODO align closed statuses in SF or make API case insensitive
  CLOSED_DUPLICATE = 'Closed - Duplicate',
  CLOSED_WAITING_FOR_CUSTOMER_REPLAY = 'Closed - Waiting for Customer Reply',
  NEW = 'New',
}

export enum CaseSortKey {
  CREATED_AT = 'created_date',
  LAST_MODIFIED_AT = 'last_modified_date',
}

export const CaseSortOrder: Record<CaseSortKey, SortOrder> = {
  [CaseSortKey.CREATED_AT]: SortOrder.DESCENDING,
  [CaseSortKey.LAST_MODIFIED_AT]: SortOrder.DESCENDING,
};

export type CaseType =
  | 'customer'
  | 'service'
  ;

export interface CaseContact {
  id: string;
  firstName: string;
  lastName: string;
}

export interface CaseAccount {
  id: string;
  firstName: string;
  lastName: string;
}

export type CaseAttributes = {
  account: CaseAccount;
  battery: Battery;
  caseNumber: string;
  description: string;
  id: string;
  lastModifiedDate: string;
  origin: string;
  recordType: CaseRecordType;
  salesforceCaseId: string;
  status: CaseStatus;
  subject: string;
  createdDate: Date;
};

export interface CaseMetaData extends ListMetaData {}

export type CaseData = Data<CaseAttributes>;
export type Case = CaseAttributes;
