import {
  hasFlatOfferAcceptedStatus,
  hasFlatOfferConfirmedStatus,
  hasPVRegisteredStatus,
} from '+app/+lead/store/+lead.helper';
import { Widget, WidgetAccordion } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import { isUndefined } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewCloseLeadForm } from '../../containers/LeadOverviewCloseLeadForm';
import { LeadOverviewRecommendLead } from '../LeadOverviewRecommendLead';

import './LeadOverviewLeadManager.component.scss';

const isCloseLeadDisabled = (lead: Lead) => {
  const conditions = [
    hasFlatOfferAcceptedStatus,
    hasFlatOfferConfirmedStatus,
    hasPVRegisteredStatus,
  ];

  return conditions.some(meets => meets(lead));
};

interface Props {
  lead: Lead;
}

export const LeadOverviewLeadManager: React.FC<Props> = ({ lead }) => {
  const [isCloseLeadAccordionOpen, setCloseLeadAccordionOpen] = React.useState<boolean>(false);
  const [isRecommendLeadAccordionOpen, setRecommendLeadAccordionOpen] = React.useState<boolean>(false);

  const toggleCloseLeadAccordion = (shouldOpen?: boolean) => {
    const stateToSet = !isUndefined(shouldOpen)
      ? shouldOpen
      : !isCloseLeadAccordionOpen;
    setCloseLeadAccordionOpen(stateToSet);
  };

  return (
    <Widget
      className={'c-lead-overview-lead-magager'}
      heading={I18n.t(T.lead.boc._salessolution_.leadManager.headline)}
      icon={<Icon.Settings className={'c-lead-overview-lead-magager__icon'} />}
    >
      <ul>
        <WidgetAccordion
          className={'c-lead-overview-lead-magager__accordion'}
          title={I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.title)}
          isOpen={isRecommendLeadAccordionOpen}
          onHeaderClick={() => setRecommendLeadAccordionOpen(!isRecommendLeadAccordionOpen)}
        >
          <LeadOverviewRecommendLead />
        </WidgetAccordion>

        <WidgetAccordion
          className={'c-lead-overview-lead-magager__accordion'}
          title={I18n.t(T.lead.boc._salessolution_.leadManager.closeLead.title)}
          isDisabled={isCloseLeadDisabled(lead)}
          isOpen={isCloseLeadAccordionOpen}
          onHeaderClick={() => toggleCloseLeadAccordion()}
        >
          <LeadOverviewCloseLeadForm
            onCancel={() => toggleCloseLeadAccordion(false)}
          />
        </WidgetAccordion>
      </ul>
    </Widget>
  );
};
