import * as packageJson from '../../package.json';
import { Env } from './environment';

export interface AppConfiguration {
  VERSION: string;
  AUTHORIZED_DOMAINS: string[];
  SENTRY_DSN: string;
  DEVELOPMENT_MODE: boolean;
}

// Assume that localhost means development mode
const isInDevelopmentMode = () => [
  'localhost',
  '0.0.0.0',
].indexOf(window.location.hostname) >= 0;

export const getAppConfig = (env: Env): AppConfiguration => {
  switch (env) {
    case Env.TEST:
    case Env.DEVELOPMENT:
    case Env.STAGING:
    case Env.PRODUCTION:
    default:
      return {
        VERSION: packageJson.version,
        // TODO: add a prod domain for marking news as read once it's there
        AUTHORIZED_DOMAINS: [
          'sonnen.de',
          'localhost',
          'us-central1-sonnenportal-dev.cloudfunctions.net',
          'us-central1-sonnenportal-production.cloudfunctions.net',
        ],
        SENTRY_DSN: 'https://155e1db2d66c46648a06308f8463d55c@sentry.sonnenbatterie.de/39',
        DEVELOPMENT_MODE: isInDevelopmentMode(),
      };
  }
};
