import { WarningBox } from '+shared/components/WarningBox';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ContractEnergyMeterError.component.scss';

export const ContractEnergyMeterError: React.FC = () => (
  <div className={'c-contract-energy-meter-error'}>
    <div className={'o-grid'}>
      <div className={'o-grid__column'}>
        <div className={'c-contract-energy-meter-error__wrapper'}>
          <WarningBox warning={I18n.t(T.customerSingle.contract.sonnenFlat.energyMeter.apiError)} />
        </div>
      </div>
    </div>
  </div>
);
