import { FormikProps } from 'formik';

export interface FormInputProps<T> {
  name: string;
  label?: string;
  info?: string;
  placeholder?: string;
  step?: number | 'any';
  form: FormikProps<T>;
  type?: 'number' | 'text' | 'date' | 'password';
  unit?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: ClassValue;
  hasNoGap?: boolean;
  id?: string;
  isRequired?: boolean;
  icon?: FormInputIcon;
  warningMessage?: string;
  bottomWarningText?: string;
  onClick?: () => void;
  readOnly?: boolean;
}

export enum FormInputIcon {
  CHECKMARK = 'checkmark',
  WARNING = 'warning',
}
