import { getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { DsoCommissioningActions } from '+app/+setupTool/+dsoCommissioning/store/+dsoCommissioning.actions';
import { getDsoCommissioningFields } from '+app/+setupTool/+dsoCommissioning/store/+dsoCommissioning.selectors';
import { saveableDsoCommissioningFields } from '+app/+setupTool/+dsoCommissioning/store/schemas';
import { DsoSummaryFiles } from '+app/+setupTool/+form/components';
import { DsoRegistrationFormActions } from '+app/+setupTool/+form/store/+form.actions';
import { FileCategoryName } from '+app/+setupTool/+form/store/+form.dictionary';
import {
  getFormFulfillment,
  getPvrConfirmationDocumentSubmitQueryStatus,
} from '+app/+setupTool/+form/store/+form.selectors';
import { getErrors, getRegistrationSubject, getSubmissionId } from '+app/+setupTool/store/+setupTool.selectors';
import { LEAD_IN_SETUP_STAGE, PATHS } from '+app/router';
import { PVRegisterInfoStatus, PvRegisterStatus } from '+setupTool/+pvRegister/store/+pvRegister.dictionary';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { Overlay, Ref } from '+setupTool/components/Overlay';
import { DsoCommissioningDataForm } from '+setupTool/containers/CommissioningDataForm';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { isEmptyValue, removeFile, sendFile } from '+setupTool/store/+setupTool.helpers';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import {
  DetailListTable,
  FormBanner,
  FormCheckbox,
  FormErrorBanner,
  FormSectionParagraph,
  FormUploadInput,
} from '+shared/components';
import { isImpersonated } from '+shared/store/auth/auth.selectors';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { formatDate } from '+utils/format.util.old';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Button, Icofont, Icon, Loadable, Loader, PageSubheadline, SideInfo } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual, omitBy, pick } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { PVRegisterActions } from '../../store/+pvRegister.actions';
import {
  getLatestPVRegisterDate,
  getPVRConfigSubmitQueryStatus,
  getPVRegisterDocuments,
  getPVRegisterDocumentsMultiuploadObject,
  getPVRegistrationError,
  getPVRPatchSubmissionQueryStatus,
  getPVRSubmission,
  getRegisterPvrManuallyQueryStatus,
} from '../../store/+pvRegister.selectors';
import { getPVRegisterInitial, getPVRegisterSchema } from '../../store/schemas';
import {
  ImpersonateManualUploadFormInterface,
  PVRegisterFormInterface,
} from '../../store/types';

import { QueryActions } from '../../../../shared/store/query';
import { PV_REGISTER_CONFIG_SUBMIT_QUERY, PV_REGISTER_PATCH_SUBMISSION_QUERY } from '../../store/+pvRegister.state';
import './PVRegister.component.scss';
import { mapInfoStatus } from './PVRegister.helper';

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  userId: getUserProfileId(state),
  getPVRPatchSubmissionQueryStatus: getPVRPatchSubmissionQueryStatus(state),
  getPVRConfigSubmitQueryStatus: getPVRConfigSubmitQueryStatus(state),
  pvrConfirmationDocumentSubmitQueryStatus: getPvrConfirmationDocumentSubmitQueryStatus(state),
  registerPvrManuallyStatus: getRegisterPvrManuallyQueryStatus(state),
  errors: getErrors(state),
  submissionId: getSubmissionId(state),
  isSetupDataFulfilled: getFormFulfillment(state),
  pvRegistrationError: getPVRegistrationError(state),
  pvRegisterSubmission: getPVRSubmission(state),
  fields: getDsoCommissioningFields(state),
  latestDocumentsGenerationDate: getLatestPVRegisterDate(state),
  documents: getPVRegisterDocuments(state),
  documentsMultiuploadObject: getPVRegisterDocumentsMultiuploadObject(state),
  registrationSubject: getRegistrationSubject(state),
  isImpersonated: isImpersonated(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  goToSetupDashboard: (leadId: string) => push(PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)),
  goToSetupData: (leadId: string) => push(PATHS.SETUP_TOOL_SETUP_DATA({ leadId }, LEAD_IN_SETUP_STAGE)),
  submitRegisterPV: PVRegisterActions.submitRegisterPV,
  registerPvrManually: PVRegisterActions.registerPvrManually,
  saveData: SetupToolActions.saveSubmission,
  saveSubmissionAndRegisterPV: PVRegisterActions.saveSubmissionAndRegisterPV,
  clearQuery: QueryActions.init,
  setFields: DsoCommissioningActions.setFields,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
  getLead: LeadActions.getLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

type InfoProps = {
  status: PVRegisterInfoStatus;
  border?: boolean;
};

const InfoText = ({ status, border }: InfoProps) => (
  <FormSectionParagraph>
    <div className={classNames('c-dso-registration-summary__help', {
      'c-dso-registration-summary__help--border': !!border,
    })}>
      <Icon.Info className={'c-dso-registration-summary__help-icon'} />
      <p>
        {mapInfoStatus(status)}
      </p>
    </div>
  </FormSectionParagraph>
);

const PVRegisterComponent: React.FC<Props> = ({
  actions,
  leadId,
  userId,
  getPVRPatchSubmissionQueryStatus,
  getPVRConfigSubmitQueryStatus,
  pvrConfirmationDocumentSubmitQueryStatus,
  registerPvrManuallyStatus,
  errors,
  isSetupDataFulfilled,
  pvRegisterSubmission,
  pvRegistrationError,
  latestDocumentsGenerationDate,
  documents,
  documentsMultiuploadObject,
  fields,
  submissionId,
  registrationSubject,
  isImpersonated,
}) => {
  const overlayRef = React.useRef<Ref>(null);
  const formRef = React.useRef<FormikProps<PVRegisterFormInterface>>(null);
  const impersonateManualUploadFormRef = React.useRef<FormikProps<ImpersonateManualUploadFormInterface>>(null);
  const validationSchema = getPVRegisterSchema(registrationSubject);
  const pvRegisterInitial = getPVRegisterInitial(registrationSubject);
  const showForm = !pvRegisterSubmission;
  const pvRegisterStatusIsSuccess = pvRegisterSubmission && (pvRegisterSubmission.status === PvRegisterStatus.FINISH
    || pvRegisterSubmission.status === PvRegisterStatus.FINISH_MANUALLY);
  const numRequiredDocumentsForManualPVR = registrationSubject === RegistrationSubjectType.BATTERY ? 1 : 2;
  const areDocumentsGenerated = documents.length === numRequiredDocumentsForManualPVR && pvRegisterStatusIsSuccess;
  const isFormSent = pvRegisterSubmission
    && pvRegisterSubmission.status === (PvRegisterStatus.IN_PROGRESS || PvRegisterStatus.TRIGGERED);
  const pvRegisterFailed = pvRegisterSubmission && (pvRegisterSubmission.status === PvRegisterStatus.ERROR
    || pvRegisterSubmission.status === PvRegisterStatus.CAPTCHA
    || pvRegisterSubmission.status === PvRegisterStatus.SKIPPED);
  const isSubmissionSavedAndRequestingForm =
    getPVRConfigSubmitQueryStatus.pending ||
    (getPVRConfigSubmitQueryStatus.success &&
      !isFormSent &&
      !areDocumentsGenerated);
  const isLoaderDisplayed = getPVRConfigSubmitQueryStatus.pending;

  React.useEffect(() => {
    if (getPVRPatchSubmissionQueryStatus.success) {
      actions.clearQuery(PV_REGISTER_PATCH_SUBMISSION_QUERY);
    }
  }, [getPVRPatchSubmissionQueryStatus]);

  React.useEffect(() => {
    if (leadId && getPVRConfigSubmitQueryStatus.success) {
      actions.getLead(leadId, LEAD_UPDATE_QUERY);
    }
  }, [getPVRConfigSubmitQueryStatus]);

  React.useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
      actions.clearQuery(PV_REGISTER_PATCH_SUBMISSION_QUERY);
      actions.clearQuery(PV_REGISTER_CONFIG_SUBMIT_QUERY);
    };
  }, []);

  React.useEffect(() => {
    if (impersonateManualUploadFormRef.current) {
      impersonateManualUploadFormRef.current.setValues({
        [FileCategoryName.PVR_CONFIRMATION]: documentsMultiuploadObject[FileCategoryName.PVR_CONFIRMATION],
      });
    }
  }, [documents]);

  const onSubmit = (values: PVRegisterFormInterface) => {
    if (values && !isEqual(fields, values)) {
      actions.setFields({
        ...fields,
        battery_commissioning_date: values.battery_commissioning_date,
        battery_commissioning_date_readonly_pvr: moment(values.battery_commissioning_date).format('DD.MM.YYYY'),
        dso_reference_number: values.dso_reference_number,
        dso_reference_number_readonly_pvr: values.dso_reference_number,
        pv_commissioning_date: values.pv_commissioning_date,
        pv_commissioning_date_readonly_pvr: moment(values.pv_commissioning_date).format('DD.MM.YYYY'),
        toc1: values.toc1,
        toc2: values.toc2,
      });
      actions.saveSubmissionAndRegisterPV();
    } else {
      actions.submitRegisterPV();
    }
  };

  return (
    <Overlay
      className={'c-setup-tool__dso-summary'}
      title={I18n.t(T.setupTool.step.pvRegister)}
      close={() => {
        actions.goToSetupDashboard(leadId!);

        const values = formRef.current && formRef.current.values;
        const invalidFieldKeys = formRef.current && Object.keys(formRef.current.errors) || [];
        const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
          ({...prev, [fieldKey]: pvRegisterInitial[fieldKey]}), {});

        if (values && !isEqual(
          pick(omitBy(fields, isEmptyValue), saveableDsoCommissioningFields),
          pick(omitBy(values, isEmptyValue), saveableDsoCommissioningFields),
        )) {
          actions.setFields({
            ...fields,
            battery_commissioning_date: values.battery_commissioning_date,
            dso_reference_number: values.dso_reference_number,
            pv_commissioning_date: values.pv_commissioning_date,
            ...invalidOrEmptyValues,
          });
          actions.saveData(SubmissionStep.PV_REGISTER);
        }
      }}
      ref={overlayRef}>
        {showForm && <div className={'c-overlay__inner-content c-setup-tool__help-content'}>
          <SideInfo className={'c-help__side-info'}>
            <div className={`c-help__side-info-icon`}><Icon.Info /></div>
            <div>
              <h3>{I18n.t(T.setupTool.pvRegister.help.header)}</h3>
              <p>
                <Translate
                  value={
                    registrationSubject === RegistrationSubjectType.BATTERY
                      ? T.setupTool.pvRegister.help.textBatteryOnly
                      : T.setupTool.pvRegister.help.text
                  }
                  dangerousHTML={true}
                />
              </p>
              <br />
              <a onClick={() => actions.goToSetupData(leadId!)}>
                {I18n.t(T.setupTool.pvRegister.help.link)}
              </a>
            </div>
          </SideInfo>
        </div>}
        <ErrorBox errors={errors} />
        <div className={'c-dso-commissioning-form__container'}>
          {showForm ? <Formik
              initialValues={pvRegisterInitial}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={true}
              innerRef={formRef}
              onSubmit={onSubmit}
              render={form => (
                <Form>
                  <DsoCommissioningDataForm form={form} alwaysShowPvCommissioningDate={true} />
                  {areDocumentsGenerated || <InfoText status={PVRegisterInfoStatus.NOT_SENT} border={true} />}
                  <FormSectionParagraph>
                    <div className={'c-pv-register__checkbox'}>
                      <FormCheckbox
                        form={form}
                        name={'toc1'}
                        label={I18n.t(
                          registrationSubject === RegistrationSubjectType.BATTERY
                            ? T.setupTool.pvRegister.fields.agreement1BatteryOnly
                            : T.setupTool.pvRegister.fields.agreement1,
                        )}
                      />
                    </div>
                  </FormSectionParagraph>
                  <FormSectionParagraph>
                    <div className={'c-pv-register__checkbox'}>
                      <FormCheckbox
                        form={form}
                        name={'toc2'}
                        label={I18n.t(
                          registrationSubject === RegistrationSubjectType.BATTERY
                            ? T.setupTool.pvRegister.fields.agreement2BatteryOnly
                            : T.setupTool.pvRegister.fields.agreement2,
                        )}
                      />
                    </div>
                  </FormSectionParagraph>
                  <div className={'c-dso-commissioning-form__submit-button-container'}>
                    {!isSubmissionSavedAndRequestingForm && <Button
                      type={'submit'}
                      label={I18n.t(T.setupTool.pvRegister.submit)}
                      isLoading={getPVRPatchSubmissionQueryStatus.pending}
                      isDisabled={!form.isValid || !isSetupDataFulfilled}
                    />}
                  </div>
                </Form>
              )}
          /> : <>
              <div className={'c-setup-tool__static-info'}>
                <PageSubheadline>
                  {I18n.t(T.setupTool.pvRegister.form.header)}
                </PageSubheadline>
                <DetailListTable
                  list={[
                    {
                      label: I18n.t(T.setupTool.dsoCommissioning.form.dsoReferenceNumber),
                      value: fields.dso_reference_number_readonly_pvr || fields.dso_reference_number,
                    },
                    {
                      label: I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate),
                      value: fields.pv_commissioning_date_readonly_pvr
                        || moment(fields.pv_commissioning_date).format('DD.MM.YYYY'),
                    },
                    {
                      label: I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate),
                      value: fields.battery_commissioning_date_readonly_pvr
                        || moment(fields.battery_commissioning_date).format('DD.MM.YYYY'),
                    },
                  ]}
                />
              </div>
              {areDocumentsGenerated || <InfoText status={PVRegisterInfoStatus.SENT} />}
            </>
          }
          {isLoaderDisplayed && <Loader />}
          {isFormSent && (
            <p className={'c-setup-tool__dso-summary-status c-setup-tool__dso-summary-status--submit-success'}>
              <Icofont type={'tick'} /> {I18n.t(T.setupTool.pvRegister.status.sent)}
            </p>
          )}
          {!!(pvRegistrationError && pvRegistrationError.response) && <FormErrorBanner
            isVisible={!!pvRegistrationError}
            error={pvRegistrationError.response.message}
          />}
        </div>
        {latestDocumentsGenerationDate && areDocumentsGenerated && <div className={'c-overlay__inner-content'}>

          <FormSectionParagraph>
            {I18n.t(T.setupTool.pvRegister.documentsHeader)}
            <span className={'c-setup-tool__dso-summary-status'}>
              <Icofont type={'tick'} /> {I18n.t(T.setupTool.pvRegister.status.success)}
            </span>
          </FormSectionParagraph>
          <h4>{I18n.t(T.setupTool.pvRegister.createdAt)} {formatDate(latestDocumentsGenerationDate)}</h4>

          <DsoSummaryFiles
            documents={documents}
            pending={false}
          />

          <InfoText border={true} status={
            registrationSubject === RegistrationSubjectType.BATTERY
              ? PVRegisterInfoStatus.GENERATED_BATTERY_ONLY
              : PVRegisterInfoStatus.GENERATED}
          />
        </div>}

        {!!(isImpersonated && (pvRegisterFailed || pvRegisterStatusIsSuccess)) &&
        <div className={'c-overlay__inner-content'}>
          <Formik
            initialValues={{
              [FileCategoryName.PVR_CONFIRMATION]: '',
            }}
            innerRef={impersonateManualUploadFormRef}
            onSubmit={() => undefined}
            render={form => (<>
              {(!pvRegisterFailed || pvRegisterStatusIsSuccess) && <FormBanner
                isVisible={true}
                type={'info'}
                message={I18n.t(T.setupTool.pvRegister.impersonateReuploadHeader)}
                description={I18n.t(
                  T.setupTool.pvRegister.impersonateReuploadDescription,
                  {numDocsNeeded: numRequiredDocumentsForManualPVR, numDocsUploaded: documents ? documents.length : 0},
                )}
                icon={<Icon.Info/>}
              />}
              <FormUploadInput
                form={form}
                name={FileCategoryName.PVR_CONFIRMATION}
                maxNumFiles={numRequiredDocumentsForManualPVR}
                accept={['application/pdf']}
                maxSize={20971520}
                maxSizeLabel={'20 MB'}
                label={I18n.t(T.setupTool.info.chooseFile)}
                placeholder={I18n.t(T.setupTool.info.fileUpTo, { extension: 'PDF', maxFileSize: '20 MB' })}
                isImmediatelySentToBackend={true}
                showErrorOnlyIfTouched={true}
                onUpload={(file) =>
                  leadId && sendFile(
                  file,
                  FileCategoryName.PVR_CONFIRMATION,
                  userId,
                  leadId,
                  submissionId,
                  actions.sendDsoDocumentsFile,
                  )}
                isLoading={pvrConfirmationDocumentSubmitQueryStatus.pending}
                onReset={(documentId) => {
                  if (documents && documents.length > 0 && documentId) {
                    removeFile(
                      documentId,
                      submissionId,
                      actions.removeDsoDocumentsFile,
                    );
                  }
                }}
              />
              {!!(pvRegisterFailed && !pvRegisterStatusIsSuccess) &&
                <div className={'c-dso-commissioning-form__submit-button-container'}>
                  <Button
                    type={'submit'}
                    label={I18n.t(T.setupTool.pvRegister.registerPvrManually)}
                    isLoading={registerPvrManuallyStatus.pending}
                    isDisabled={!documents || documents.length !== numRequiredDocumentsForManualPVR}
                    onClick={() => {
                      if (submissionId) {
                        actions.registerPvrManually(submissionId);
                      }
                    }}
                  />
                </div>
              }
            </>)}
          />
        </div>}
    </Overlay>
  );
};

export const PVRegister = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PVRegisterComponent);
