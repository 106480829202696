import { FormBanner } from '+shared/components';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import * as uuid from 'uuid';
import './ErrorBox.component.scss';

interface Props {
  errors: string[];
}

export const ErrorBox: React.FC<Props> = ({
  errors,
}) => {
  return !!(errors && errors.length) ? (
    <div className={'setup-tool-error-box'}>
      {errors.map((error) => {
        return (
          <FormBanner
            key={uuid.v4()}
            isVisible={true}
            type={'error'}
            message={error}
            icon={<Icon.CloseCircle/>}
          />
        );
      })}
    </div>
  ) : null;
};
