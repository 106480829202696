import { compose } from 'redux';
import { storeProvider } from '../shared/store/store.provider';
import { getCurrentLocale } from './i18n.selectors';

/**
 * @deprecated don't use! - will be removed in the future
 */
export const provideCurrentLocale = compose(
  getCurrentLocale,
  storeProvider.getState,
);
