import { LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationBattery } from '../../components/LeadConfigurationBattery';
import { LeadConfigurationInputsWithRecommendation, LeadConfigurationPv } from '../../containers';
import {
  formFields,
  shouldSkipRecommendation,
} from '../../containers/LeadConfigurationForm/LeadConfigurationForm.helper';
import { ConfigurationForm } from '../../store/types';

import './LeadConfigurationHardware.component.scss';

interface Props {
  form: FormikProps<ConfigurationForm>;
  productBatteryList: LeadProductBattery[];
  // this one is when we're adding flat tariff to an existing offer
  existingOffer?: LeadOffer;
  // here we're creating another bundle offer while HW only was previously accepted/confirmed
  previouslyAcceptedHardwareOffer?: LeadOffer;
  isHardwareAlreadySold?: boolean;
}

export const LeadConfigurationHardware: React.FC<Props> = ({
  form,
  productBatteryList,
  existingOffer,
  previouslyAcceptedHardwareOffer,
  isHardwareAlreadySold,
}) => {
  React.useEffect(() => {
    if (existingOffer || previouslyAcceptedHardwareOffer) {
      const battery = productBatteryList.find(battery =>
        [existingOffer?.battery, previouslyAcceptedHardwareOffer?.battery].includes(battery.id));

      form.setFieldValue(formFields.MODEL_NAME, battery?.modelName);
      form.setFieldValue(formFields.CAPACITY_GROSS, battery?.id);
    }
  }, [existingOffer, previouslyAcceptedHardwareOffer]);

  const renderRecommendationSubheadline = () => {
    if (form.values.multiplePv) {
      return I18n.t(T.lead.configuration._salessolution_.hardware.multiplePvHeadline);
    }

    if (shouldSkipRecommendation({
      batteryName: form.values[formFields.MODEL_NAME],
      commissioningDate: form.values[formFields.COMMISSIONING_DATE],
    })) {
      return I18n.t(T.lead.configuration._salessolution_.hardware.pvAndCapacity);
    }

    return I18n.t(T.lead.configuration._salessolution_.hardware.pleaseWaitForRecommendation);
  };

  return (
    <div className={'c-lead-configuration-hardware'}>
      <div className={'c-lead-configuration-hardware__headline'}>
        <PageSubheadline>
          {I18n.t(T.lead.configuration._salessolution_.hardware.title)}
        </PageSubheadline>
      </div>
      <div className={'c-lead-configuration-hardware__subheadline'}>
        {I18n.t(T.lead.configuration._salessolution_.hardware.pvSystem)}
      </div>
      <LeadConfigurationPv form={form} />
      <div className={'c-lead-configuration-hardware__subheadline'}>
        {I18n.t(T.lead.configuration._salessolution_.hardware.sonnenBattery)}
      </div>
      <LeadConfigurationBattery
        form={form}
        productBatteryList={productBatteryList}
        existingOffer={existingOffer}
        previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
        isHardwareAlreadySold={isHardwareAlreadySold}
      />
      {!form.values.multiplePv && (
        <>
          <div className={'c-lead-configuration-hardware__subheadline'}>
            {renderRecommendationSubheadline()}
          </div>
          <LeadConfigurationInputsWithRecommendation
            form={form}
            existingOffer={existingOffer}
            previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
          />
        </>
      )}
    </div >
  );
};
