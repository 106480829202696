import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export const DeclineLeadSchema = () => 
  Yup.object().shape({
    declineReason: Yup
      .string()
      .max(255, I18n.t(T.lead.list._salessolution_.declineModal.maxChars))
      .required(I18n.t(T.register.alert.requiredField)),
  });
