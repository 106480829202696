import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { get, isArray, isEmpty } from 'lodash/fp';
import * as React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid/v4';

import './FormUploadInput.component.scss';

interface UploadedItem {
  id?: string;
  fileName: string;
}

interface Props<T> extends DropzoneOptions {
  name: string;
  label?: string;
  title?: string;
  placeholder?: string;
  showFiles?: boolean;
  isLoading?: boolean;
  form: FormikProps<T>;
  children?: React.ReactNode;
  className?: ClassValue;
  onUpload?: (acceptedFiles: T) => void;
  onReset?: (documentId?: string) => void;
  maxNumFiles?: number;
  maxSize?: number;
  maxSizeLabel?: string;
  attachmentError?: string;
  isImmediatelySentToBackend?: boolean;
  showErrorOnlyIfTouched?: boolean;
}

export const FormUploadInput = <T extends any = any>({
  form,
  name,
  showFiles = true,
  label = I18n.t(T.general.placeholders.uploadInput),
  title,
  accept = ['image/*', 'application/pdf'],
  placeholder,
  children,
  multiple = false,
  onUpload,
  onReset,
  isLoading = false,
  maxNumFiles = 1,
  maxSize,
  maxSizeLabel,
  attachmentError = '',
  className,
  isImmediatelySentToBackend = false,
  showErrorOnlyIfTouched = false,
  ...rest
}: Props<T>) => {

  const onDrop = React.useCallback(async acceptedFiles => {
    if (acceptedFiles.length === 0) { return; }

    if (onUpload) {
      try {
        const response = await onUpload(acceptedFiles);

        if (!isImmediatelySentToBackend) {
          form.setFieldValue(name, isArray(form.values[name])
            ? [...form.values[name], response]
            : response,
          );
          form.setFieldTouched(name, true);
        }

      } catch (error) {

        // @TODO
        console.error('Something went wrong on upload file', error);
      }
    }
  }, []);

  const onDropRejected = (files: File[]) => {
    if (files && maxSize && files[0].size > maxSize) {
      form.setFieldError(name, I18n.t(T.general.errors.maximumFileSize, { size: maxSizeLabel }));
    } else {
      form.setFieldError(name, I18n.t(T.lead.overview._salessolution_.errorTitle));
    }

    if (showErrorOnlyIfTouched) {
      form.setFieldTouched(name, true);
    }
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone({ disabled: isLoading, onDrop, onDropRejected, multiple, accept, maxSize, ...rest });
  const uploadFieldValue = form.values[name];
  const isFileUploaded = !isEmpty(uploadFieldValue);
  const numFilesUploaded = isArray(uploadFieldValue) ?
    uploadFieldValue.length : (isEmpty(uploadFieldValue) ? 0 : 1);
  const fieldValueIsArrayOfOne = isArray(uploadFieldValue) && numFilesUploaded === 1;
  const singleFilename = fieldValueIsArrayOfOne
    ? uploadFieldValue[0].fileName
    : (typeof uploadFieldValue === 'string' && uploadFieldValue !== '') ? uploadFieldValue : null;

  const error = get(name)(form.errors);
  const isTouched = get(name)(form.touched);
  const hasError = showErrorOnlyIfTouched ? (!!error && isTouched) : !!error;

  const multiuploadDeleteHandler = (item: UploadedItem) => {
    form.setFieldValue(name, form.values[name].filter((elem: UploadedItem) => elem.id !== item.id));

    if (onReset) {
      onReset(item.id);
    }
  };

  return (
    <>
      {title && <p className={'c-form-upload-input__headline'}>{title}</p>}
      <div className={classNames('c-form-upload-input', className,
        placeholder ? 'c-form-upload-input--with-placeholder' : '')}
      >
        <div className={'c-form-upload-input__content'}>
          {(!isFileUploaded || numFilesUploaded < maxNumFiles) && (
            <>
              <div
                {...getRootProps()}
                className={classNames('c-form-upload-input__drag-area',
                  {'c-form-upload-input__field--error': !!error},
                  {'c-form-upload-input__drag-area--active': isDragActive},
                  )}
              >
                <input name={name} {...getInputProps()} />
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className={
                    classNames('c-form-upload-input__text')
                  }>
                    <Icon.Download className={'c-form-upload-input__upload-icon'} />
                    {children ? children : (
                      <>
                        <label className={'c-form-upload-input__title'}>{label}</label>
                        {placeholder && <p className={'c-form-upload-input__placeholder'}>{placeholder}</p>}
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {isFileUploaded && numFilesUploaded > 1  && (
            <div className={'c-form-upload-input__files--multiple'}>
                {
                  form.values[name].map((item: UploadedItem, index: number) => {
                    return !!item.fileName && (
                      <div key={uuid()} className={'c-form-upload-input__row'}>
                        <div className={'c-form-upload-input__file-container'}>
                          <Icon.Contract className={'c-dso-registration-file__file-icon'}/>
                          <div className={'c-form-upload-input__filename--multiple'}>{item.fileName}</div>
                        </div>
                        <button
                          type={'button'}
                          onClick={() => multiuploadDeleteHandler(item)}
                        >
                          <Icon.Close className={'c-form-upload-input__remove-icon'}/>
                        </button>
                      </div>
                    );
                  })
                }
            </div>
          )}

          { singleFilename && (
            <div className={'c-form-upload-input__files'}>
              <div key={singleFilename} className={'c-form-upload-input__row'}>
                <div className={'c-form-upload-input__file-container'}>
                  <Icon.Contract className={'c-dso-registration-file__file-icon'} />
                  <div className={'c-form-upload-input__filename'}>{singleFilename}</div>
                </div>
                <button
                  type={'button'}
                  onClick={() => {
                    form.setFieldValue(name, '');

                    if (onReset) {
                      if (fieldValueIsArrayOfOne) {
                        onReset(form.values[name][0].id);
                        return;
                      }
                      onReset();
                    }
                  }}
                >
                  <Icon.Close className={'c-form-upload-input__remove-icon'} />
                </button>
              </div>
            </div>
          )}
          <div className={'c-form-upload-input__error'}>
            {!!hasError && (
              <div className={'c-form-upload-input__error-message'}>
                {error} {attachmentError && ` ${attachmentError}`}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
