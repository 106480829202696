import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import {
  isCommonStatusUpdate,
  isFlatSentStatusUpdate,
  isFlatStatusUpdate,
  isHardwareConfirmedActive,
  isHardwareStatusUpdate,
} from '+shared/store/lead/leadStatus.helpers';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusNameMapper } from '+shared/store/lead/types/leadStatus.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getLatestStatusForEachGroup } from './LeadCurrentStatus.helper';
import { LeadStatusGroup } from './LeadCurrentStatus.interface';

import './LeadCurrentStatus.component.scss';

interface Props {
  lead: Lead;
  setModalOpen: (arg: boolean) => void;
  compact?: boolean;
  className?: ClassValue;
}

export const LeadCurrentStatus: React.FC<Props> = ({
  lead,
  compact = false,
  setModalOpen,
  className,
}) => {
  const {
    [LeadStatusGroup.COMMON]: commonStatus,
    [LeadStatusGroup.FLAT]: flatStatus,
    [LeadStatusGroup.HARDWARE]: hardwareStatus,
  } = getLatestStatusForEachGroup(lead.status.summary);

  const openStatusModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setModalOpen(true);
  };

  return (
    <div className={classNames('lead-current-status', className)}>
      <div className={classNames('lead-current-status__tiles-wrapper', {
        'lead-current-status__tiles-wrapper--compact': compact,
      })}>
        {isHardwareStatusUpdate(hardwareStatus) && (
          <StatusTile
            additionalClass={'lead-current-status__tile'}
            compact={compact}
            label={LeadStatusNameMapper(hardwareStatus.name)}
            color={hardwareStatus.isSet
              ? StatusTileColors.GREEN
              : StatusTileColors.GRAY}
          />
        )}

        {isFlatStatusUpdate(flatStatus) && (
          <StatusTile
            additionalClass={'lead-current-status__tile'}
            compact={compact}
            label={LeadStatusNameMapper(flatStatus.name)}
            color={flatStatus.isSet
              ? StatusTileColors.GREEN
              : StatusTileColors.GRAY}
            tooltipText={isFlatSentStatusUpdate(flatStatus) && !flatStatus.isSet
              ? I18n.t(T.lead._salessolution_.status.flatOffer.sentOfferDeleted)
              : undefined}
          />
        )}

        {isCommonStatusUpdate(commonStatus)
          && !isFlatStatusUpdate(flatStatus)
          && !isHardwareStatusUpdate(hardwareStatus)
          && (
          <StatusTile
            additionalClass={'lead-current-status__tile'}
            compact={compact}
            label={LeadStatusNameMapper(commonStatus.name)}
            color={StatusTileColors.GREEN}
          />
        )}
      </div>

      <Button
        label={isHardwareConfirmedActive(lead)
          ? I18n.t(T.lead._salessolution_.status.button.moveToSetup)
          : I18n.t(T.lead._salessolution_.status.button.update)}
        onClick={event => openStatusModal(event)}
        size={compact ? ButtonSize.PILL : ButtonSize.NORMAL}
        theme={isHardwareConfirmedActive(lead) ? ButtonTheme.PRIMARY : ButtonTheme.OUTLINE}
        className={'lead-current-status__button'}
      />
    </div>
  );
};
