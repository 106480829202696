import { getOpenAddressEdit } from '+app/+lead/store/+lead.selectors';
import { isSonnenLead } from '+shared/store/lead/lead.helpers';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import * as React from 'react';
import { connect } from 'react-redux';
import { LeadOverviewAdditionalDetails, LeadOverviewBasicDetails, LeadOverviewLeadManager } from '../../components';
import { LeadOverviewEnergyConsumptionDetails } from '../../components/LeadOverviewEnergyConsumptionDetails';
import { LeadOverviewHouseDetails } from '../../components/LeadOverviewHouseDetails';
import { LeadOverviewPvSystemDetails } from '../../components/LeadOverviewPvSystemDetails';
import { LeadOverviewSonnenNotes } from '../../components/LeadOverviewSonnenNotes';
import { LeadOverviewPartnerNotes } from '../LeadOverviewPartnerNotes';

const mapStateToProps = (state: StoreState) => ({
  openAddressEdit: getOpenAddressEdit(state),
});

interface ComponentProps {
  lead: Lead;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ComponentProps
  ;

export const LeadOverviewDetailsWidgetsComponent: React.FC<Props> = ({
  lead,
  openAddressEdit,
}) => (
    <div className={'o-grid'}>
      <div className={'o-grid__column o-grid__column--sm-12 o-grid__column--md-6'}>
        <div className={'c-lead-overview__grid-item'}>
          <LeadOverviewBasicDetails
            lead={lead}
            openDeliveryAddress={openAddressEdit === true}
          />
        </div>
        {isSonnenLead(lead) && (
          <div className={'c-lead-overview__grid-item'}>
            <LeadOverviewAdditionalDetails lead={lead} />
          </div>
        )}
        {isSonnenLead(lead) && (
          <div className={'c-lead-overview__grid-item'}>
            <LeadOverviewEnergyConsumptionDetails lead={lead} />
          </div>
        )}
      </div>
      <div className={'o-grid__column o-grid__column--sm-12 o-grid__column--md-6'}>
        {isSonnenLead(lead) && (
          <div className={'c-lead-overview__grid-item'}>
            <LeadOverviewHouseDetails lead={lead} />
          </div>
        )}
        {isSonnenLead(lead) && (
          <div className={'c-lead-overview__grid-item'}>
            <LeadOverviewPvSystemDetails lead={lead} />
          </div>
        )}
        <div className={'c-lead-overview__grid-item'}>
          <LeadOverviewPartnerNotes lead={lead} />
        </div>
        {isSonnenLead(lead) && (
          <div className={'c-lead-overview__grid-item'}>
            <LeadOverviewSonnenNotes lead={lead} />
          </div>
        )}
        <div className={'c-lead-overview__grid-item'}>
          <LeadOverviewLeadManager lead={lead} />
        </div>
      </div>
    </div>
  );

export const LeadOverviewDetailsWidgets = connect(mapStateToProps)(LeadOverviewDetailsWidgetsComponent);
