import { Config } from '+config';
import {
  DsoRegistrationInstallerDataInterfaceForCreatingInstaller,
  GetDSOsInterface,
} from '+setupTool/+form/store/types/dsoRegistrationForm.interface';
import { PreCommissioningInterface } from '+setupTool/+preCommissioning/store/types';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types/vppDocumentation.interface';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { httpClient } from '+shared/network/setupToolsNetwork.client';
import { createQueryString } from '+utils/index';
import { ContentType } from '@coolio/http';
import { from } from 'rxjs';
import { FieldGroups, mapFieldGroups, mapFields } from './setupTool.helpers';
import {
  DsoListResponse,
  DsoRegistrationFormResponse,
  DsoRegistrationPatchSubmissionResponse,
  DsoSubmissionData,
} from './types';

const postRegisterDso = (config: DsoSubmissionData) => {
  return from(httpClient.post<{ data: DsoRegistrationFormResponse }>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/`, {
    body: {
      customer_id: config.customerId,
    },
  }).then(res => res.parsedBody()));
};

const patchSubmission = (
  config: DsoSubmissionData,
  submissionId: string,
  registrationSubject: RegistrationSubjectType,
  dsoReferenceNumberPvVisible: boolean,
  isFasteningTypeConsumptionVisible: boolean,
  isMeterCabinetPreparedVisible: boolean,
  isProductionMeterVisible: boolean,
) => {
  const fieldGroups = mapFieldGroups(config, dsoReferenceNumberPvVisible);
  return from(httpClient.patch<{ data: DsoRegistrationPatchSubmissionResponse }>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}`, {
    body: {
      field_groups: fieldGroups,
      fields: mapFields(
        config,
        registrationSubject,
        dsoReferenceNumberPvVisible,
        isFasteningTypeConsumptionVisible,
        isMeterCabinetPreparedVisible,
        isProductionMeterVisible,
      ),
      deleted_field_keys: (
        !dsoReferenceNumberPvVisible && (fieldGroups && fieldGroups.includes(FieldGroups.SETUP_DATA))
      )
        ? ['dso_reference_number_pv']
        : [],
      setup_data_statuses: config.stepStatuses,
    },
  },
  ).then(res => res.parsedBody()));
};

const patchRegistrationTypeSubmission = (subject: string, submissionId: string) => {
  return from(httpClient.patch<{}>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/registration/subject`, {
    body: {
      subject,
    },
  },
  ).then(res => res.parsedBody()));
};

const postSendDsoDocument = (userId: string, customerId: string, submissionId: string, category: string, file: any) => {
  const body = new FormData();
  body.append('file', file);
  body.append('category', category);

  return from(httpClient.post<any>(`${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/uploads`, {
    headers: {
      'Content-Type': ContentType.MULTIPART_FORM,
    },
    body,
  }).then(res => res.parsedBody()));
};

const removeDsoDocument = (
  submissionId: string,
  documentId: string,
) => {

  return from(httpClient.remove<any>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/documents/${documentId}`,
    {},
  ).then(res => res.parsedBody()));
};

const postRegisterPV = (submissionId: string) => {
  return from(httpClient.post<any>(  /* @TODO */
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/register-pvr`, {
    body: {
      fields: {},
    },
  }).then(res => res.parsedBody()));
};

const postRegisterPVManually = (submissionId: string) => {
  return from(httpClient.post<any>(  /* @TODO */
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/register-pvr-manually`, {
    body: {
      fields: {},
    },
  }).then(res => res.parsedBody()));
};

const postVpp = (submissionId: string) => {
  return from(httpClient.post<{ data: DsoRegistrationFormResponse}>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/perform-vpp`).then(res => res.parsedBody()));
};

const postSolveCaptchaPV = (id: string, solution: string, submissionId: string) => {
  return from(httpClient.post<any>(  /* @TODO */
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/solve-captcha`, {
    body: {
      id,
      solution,
    },
  }).then(res => res.parsedBody()));
};

const getDsoList = () => {
  return from(httpClient.get<DsoListResponse>(
    `${Config.DSO_REGISTRATION_API_URL}/dsos`)
    .then(res => res.parsedBody()));
};

const getSubmission = (id: string) => {
  return from(httpClient.get<any>( /* @TODO WAIT FOR API CHANGES*/
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${id}`)
    .then(res => res.parsedBody()));
};

const getSubmissionByCustomerId = (customerId: string) => {
  return from(httpClient.get<any>( /* @TODO WAIT FOR API CHANGES*/
    `${Config.DSO_REGISTRATION_API_URL}/submissions?customerId=${customerId}`)
    .then(res => res.parsedBody()));
};

const getPreCommissioningDataByCustomerId = (customerId: string) => {
  return from(httpClient.get<any>(
    `${Config.DSO_REGISTRATION_API_URL}/precommissioning/${customerId}`)
    .then(res => res.parsedBody()));
};

const putPreCommissioningDataByCustomerId = (customerId: string, fields: PreCommissioningInterface) => {
  return from(httpClient.put<any>(
    `${Config.DSO_REGISTRATION_API_URL}/precommissioning/${customerId}`, {
    body: {
      customer_first_name: fields.customerFirstName,
      customer_last_name: fields.customerLastName,
      customer_phone_number: fields.customerPhoneNumber,
      customer_email_address: fields.customerEmailAddress,
      customer_number: fields.customerNumber,
      partner_company_name: fields.partnerCompanyName,
      partner_company_number: fields.partnerCompanyNumber,
      asset_street_name_and_number: fields.assetStreetNameAndNumber,
      asset_zip_code: fields.assetZipCode,
      asset_city: fields.assetCity,
      asset_country_code: fields.assetCountryCode,
    },
  },
  ).then(res => res.parsedBody()));
};

const getPVModuleVendors = (pageNumber: number) => from(
  httpClient.get<any>(`${Config.COMPONENTS_DB_API_URL}/pv_module_vendors?page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getPVModules = (vendorId: string, pageNumber: number) => from(
  httpClient.get<any>(`${Config.COMPONENTS_DB_API_URL}/pv_module_vendors/${vendorId}/modules?page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getPVInverterVendors = (pageNumber: number) => from(
  httpClient.get<any>(`${Config.COMPONENTS_DB_API_URL}/inverter_vendors?page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getPVInverters = (vendorId: string, pageNumber: number) => from(
  httpClient.get<any>(`${Config.COMPONENTS_DB_API_URL}/inverter_vendors/${vendorId}/inverters?page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getBatteryNames = (pageNumber: number) => from(
  httpClient.get<any>(
    `${Config.COMPONENTS_DB_API_URL}/batteries?page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getBatteries = (name: string, pageNumber?: number) => from(
  httpClient.get<any>(
    `${Config.COMPONENTS_DB_API_URL}/batteries?name=${name}&page=${pageNumber}`)
    .then(res => res.parsedBody()));

const getInstallers = () => from(
  httpClient.get<any>(
    `${Config.DSO_REGISTRATION_API_URL}/installers`)
    .then(res => res.parsedBody()));

const removeInstallers = (installerIds: string[]) => {
  return from(httpClient.post<any>(
    `${Config.DSO_REGISTRATION_API_URL}/installers/delete`,
    {
      body: { ids: installerIds },
    },
  ).then(res => res.parsedBody()));
};

const submitInstaller = (values: DsoRegistrationInstallerDataInterfaceForCreatingInstaller) => {
  return from(
    httpClient.post<any>(
      `${Config.DSO_REGISTRATION_API_URL}/installers/`,
      {
        body: { ...values },
      },
    ).then(res => res.parsedBody()));
};

const dsoGenerateDocuments = (submissionId: string, steps: SubmissionStep[]) => from(
  httpClient.post<{ data: DsoRegistrationFormResponse }>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/regenerate-documents`,
    {
      body: {
        steps,
      },
    },
  ).then(res => res.parsedBody()));

const generateVppCompletionNotification = (submissionId: string) => from(
  httpClient.post<{ data: DsoRegistrationFormResponse }>(
    `${Config.DSO_REGISTRATION_API_URL}/submissions/${submissionId}/regenerate-vpp-completion-notification`,
    {
      body: {},
    },
  ).then(res => res.parsedBody()));

export const DsoRegisterRepository = {
  postRegisterDso,
  patchSubmission,
  patchRegistrationTypeSubmission,
  postSendDsoDocument,
  removeDsoDocument,
  postRegisterPV,
  postRegisterPVManually,
  postVpp,
  getDsoList,
  getSubmission,
  getSubmissionByCustomerId,
  getPreCommissioningDataByCustomerId,
  putPreCommissioningDataByCustomerId,
  getPVModuleVendors,
  getPVModules,
  getPVInverterVendors,
  getPVInverters,
  getBatteryNames,
  getBatteries,
  getInstallers,
  removeInstallers,
  submitInstaller,
  dsoGenerateDocuments,
  generateVppCompletionNotification,
};
