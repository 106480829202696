import { ChargerLiveState } from '+shared/store/charger';
import { Query } from '+shared/store/query';
import { SiteCharger, SiteChargerData } from '+shared/store/site';
import { PersistedState } from 'redux-persist';

export const GET_CHARGERS_QUERY = 'getChargersQuery';

interface Charger extends SiteCharger {
  liveState?: ChargerLiveState;
}

export interface OverviewPageState extends PersistedState {
  charger?: Charger;
  [GET_CHARGERS_QUERY]: Query<SiteChargerData>;
}

export const initialState: OverviewPageState = {
  charger: undefined,
  [GET_CHARGERS_QUERY]: {},
};
