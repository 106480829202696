import { FeatureName } from '+config';
import { Container, NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { Portal } from '+shared/store/firebase/types/news.interface';
import { T } from '@sonnen/shared-i18n/service';
import { useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LogoutFromFirebase } from '../../components/LogoutFromFirebase';
import { LoginToFirebase } from '../LoginToFirebase';
import { NewsHistory } from '../NewsHistory';
import { SendNewsCustomer } from '../SendNewsCustomer';
import { SendNewsPartner } from '../SendNewsPartner';
import { VideoSection } from '../VideoSection';

import './NewsChannel.component.scss';

type Props = FirebaseProps;

interface State {
  isFirebaseAuthenticated: boolean;
  activeTab: string;
}

export class NewsChannelComponent extends React.PureComponent<Props, State> {
  state = {
    isFirebaseAuthenticated: this.props.firebase.isFirebaseAuthenticated(),
    activeTab: 'send-news',
  };

  onLogin = () =>
    this.setState({ isFirebaseAuthenticated: true });

  onLogout = () =>
    this.setState({ isFirebaseAuthenticated: false });

  renderTabNav = () => {
    const navLinkProps = { border: false, type: 'desktop' as NavLinkType, faded: true };
    const { activeTab } = this.state;

    return (
      <TabNav
        items={(
          <>
            <NavLink {...navLinkProps}>
              <span
                className={`c-news-channel__nav-link ${activeTab === 'send-news' && 'is-active'}`}
                onClick={() => this.setState({activeTab: 'send-news'})}
              >
                {I18n.t(T.news.channel.tabs.sendNewsPartnerPortal)}
              </span>
            </NavLink>
            <NavLink {...navLinkProps} >
              <span
                className={`c-news-channel__nav-link ${activeTab === 'historyPP' && 'is-active'}`}
                onClick={() => this.setState({activeTab: 'historyPP'})}
              >
                {I18n.t(T.news.channel.tabs.historyOfPPNews)}
              </span>
            </NavLink>
            <NavLink {...navLinkProps}>
              <span
                className={`c-news-channel__nav-link ${activeTab === 'send-news-cp' && 'is-active'}`}
                onClick={() => this.setState({activeTab: 'send-news-cp'})}
              >
                {I18n.t(T.news.channel.tabs.sendNewsCustomerPortal)}
              </span>
            </NavLink>
            <NavLink {...navLinkProps} >
              <span
                className={`c-news-channel__nav-link ${activeTab === 'historyCP' && 'is-active'}`}
                onClick={() => this.setState({activeTab: 'historyCP'})}
              >
                {I18n.t(T.news.channel.tabs.historyOfCPNews)}
              </span>
            </NavLink>
            <NavLink {...navLinkProps} >
              <span
                className={`c-news-channel__nav-link ${activeTab === 'video' && 'is-active'}`}
                onClick={() => this.setState({activeTab: 'video'})}
              >
                {I18n.t(T.news.channel.tabs.videos)}
              </span>
            </NavLink>
          </>
        )}
      />
    );
  };

  render() {
    const { activeTab, isFirebaseAuthenticated } = this.state;

    return (
      <>
        <PageHeader
          className={'c-news-channel'}
          tabNav={isFirebaseAuthenticated && this.renderTabNav()}
          isExpanded={true}
        >
          <h1 className={'c-news-channel__title'}>
            {I18n.t(T.news.channel.title)}
          </h1>
          {isFirebaseAuthenticated &&
            <LogoutFromFirebase onLogout={this.onLogout}/>
          }
        </PageHeader>
        <Container>
          <div className={'o-grid'}>
              {isFirebaseAuthenticated
                ? (
                  <>
                    {activeTab === 'send-news' && (
                      <div className={'o-grid__column o-grid__column--md-8'}>
                        <SendNewsPartner />
                      </div>
                    )}
                    {activeTab === 'send-news-cp' && (
                      <div className={'o-grid__column o-grid__column--md-12'}>
                        <SendNewsCustomer />
                      </div>
                    )}
                    {activeTab === 'historyPP' && (
                      <div className={'o-grid__column o-grid__column--md-12'}>
                        <NewsHistory newsPortal={Portal.SP}/>
                      </div>
                    )}
                    {activeTab === 'historyCP' && (
                      <div className={'o-grid__column o-grid__column--md-12'}>
                        <NewsHistory newsPortal={Portal.CP}/>
                      </div>
                    )}
                    {activeTab === 'video' && (
                      <div className={'o-grid__column o-grid__column--md-12'}>
                        <VideoSection />
                      </div>
                    )}
                  </>
                ) : (
                  <div className={'o-grid__column o-grid__column--md-5'}>
                    <LoginToFirebase onLogin={this.onLogin} />
                  </div>
                )
              }
          </div>
        </Container>
      </>
    );
  }
}

export const NewsChannel = withFirebase(NewsChannelComponent);
