import { CustomerActions } from '+customer/store/+customer.actions';
import { ChargerActions } from '+shared/store/charger/charger.actions';
import { SiteActions } from '+shared/store/site';
import { StoreState } from '+shared/store/store.interface';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { mapPathToParams, mapToState, ofType } from '../../../utils';
import { getChargerId } from './+overview.selector';
import { GET_CHARGERS_QUERY } from './+overview.state';

type Action$ = ActionsObservable<RouterActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getChargerList$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer),
  mapToState(state$),
  mapPathToParams(ROUTES.CUSTOMER[0]),
  map(([_, siteId]) => SiteActions.getSiteChargerList({
    queryKey: GET_CHARGERS_QUERY,
    siteId,
  })),
);

export const getChargerLiveState$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSiteChargerList),
  mapToState(state$),
  mergeMap( state => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER[0]),
    map(() => getChargerId(state)),
    map(chargerId =>
      chargerId ?
        ChargerActions.getChargerLiveState({
          queryKey: GET_CHARGERS_QUERY,
          chargerId,
        })
      : ChargerActions.stopCharger(),
    ),
  )),
);

export const epics = combineEpics(
  getChargerList$,
  getChargerLiveState$,
);
