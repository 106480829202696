import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadOverviewHeaderTitle.component.scss';

interface Props {
  lead: Lead;
}

export const LeadOverviewHeaderTitle: React.FC<Props> =
  ({
    lead,
  }) => (
    <div className={'c-lead-overview-header-title'}>
      <p className={'c-lead-overview-header-title__lead-name'} data-hj-suppress={true}>
        {`${lead.company
          ? lead.company.name
          : `${lead.firstName ? lead.firstName : '-'} ${lead.lastName ? lead.lastName : '-'}`}`}
      </p>

      <p className={'c-lead-overview-header-title__customer-number'} data-hj-suppress={true}>
        {`${I18n.t(T.lead.overview._salessolution_.header.customerNumber)} ${lead.customerNumber}`}
      </p>
    </div>
  );
