import { Battery } from '+shared/store/battery';
import { SiteLiveState } from '+shared/store/site/types';
import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { BackupBoxCard } from '../BackupBoxCard';
import { HeaterCard } from '../HeaterCard';

interface Props {
  battery: Battery;
  siteLiveState: SiteLiveState;
  isHeaterConnected: boolean;
  isBackupBoxConnected: boolean;
}

export const Accessories: React.FC<Props> = ({
  battery,
  siteLiveState,
  isHeaterConnected,
  isBackupBoxConnected,
}) => {
  const heater = {
    timestamp: siteLiveState.timestamp,
    power: siteLiveState.heaterPower,
    waterTemperature: siteLiveState.heaterWaterTemp,
    maxWaterTemperature: battery.heaterMaxTemperature,
  };
  const backupBox = {
    isActive: siteLiveState.backupActive,
    nominalPower: battery.backupNominalPower,
    lastPowerOutageAt: battery.lastPowerOutageAt,
    powerBuffer: battery.backupPowerBuffer,
    deviceType: battery.backupDeviceType,
  };

  return (
    <>
      <PageSubheadline>
        {I18n.t(T.customerSingle.batteryDetails.accessories)}
      </PageSubheadline>
      <div className={'o-grid'}>
        {isHeaterConnected && (
          <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}>
            <HeaterCard heater={heater}/>
          </div>
        )}
        {isBackupBoxConnected && (
          <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}>
            <BackupBoxCard backupBox={backupBox} />
          </div>
        )}
      </div>
    </>
  );
};
