import { SetupToolStepsList } from '+setupTool/components';
import { SetupToolStep } from '+setupTool/store/types';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './SetupToolTable.component.scss';

interface Props {
  headline?: string;
  description?: string;
  isH1Headline?: boolean;
  isCollapsed?: boolean;
  additionalHeaderContent?: React.ReactNode;
  items: SetupToolStep[];
}

export const SetupToolTableComponent: React.FC<Props> = ({
  headline,
  description,
  isH1Headline,
  isCollapsed,
  additionalHeaderContent,
  items,
}) => {

  const [setupToolDashboardItemCollapsed, setSetupToolDashboardItemCollapsed] = React.useState(isCollapsed || false);

  return items.some(item => !!item) ? (
    <>
      <div className={classNames(`c-setup-tool-step-list-table`, {
        'c-setup-tool-step-list-table--collapsed': setupToolDashboardItemCollapsed,
      })}>
        <div className={'c-list-table__header'}>
          <div className={'c-list-table__header__main-content'}>
            {isH1Headline ? (
              <h1 className={'c-list-table__headline'}>{headline}</h1>
            ) : (
              <h2 className={'c-list-table__headline'}>{headline}</h2>
            )}
            <p className={'c-list-table__description'}>{description}</p>
            <span
              className={'c-list-table__arrow'}
              onClick={() => setSetupToolDashboardItemCollapsed(!setupToolDashboardItemCollapsed)}
            />
          </div>
        </div>
        {(!setupToolDashboardItemCollapsed && additionalHeaderContent)
          && (<div className={'c-list-table__header__additional-content'}>
            {additionalHeaderContent}
          </div>)
        }
        {!setupToolDashboardItemCollapsed && (<div className={'c-list-table__table'}>
          <SetupToolStepsList
            items={items}
          />
        </div>)}
      </div>
    </>
  ) : null;
};

export const SetupToolTable = connect(
)(SetupToolTableComponent);
