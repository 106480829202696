import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';

import './FormInputRadioBox.component.scss';

interface Props<T, U> {
  name: string;
  label: string;
  value: U;
  icon: React.ReactNode;
  form: FormikProps<T>;
  description?: string;
  disabled?: boolean;
  radioBoxGroupDisabled?: boolean;
  className?: ClassValue;
  onCheck?: (val: U) => void;
}

export const FormInputRadioBox = <T extends any = any, U extends any = any>({
  form,
  name,
  label,
  value,
  icon,
  description,
  disabled = false,
  onCheck,
}: Props<T, U>) => {
  const id = name + '-' + label;

  return (
    <div className={classNames('c-form-input-radio-box', {
      'c-form-input-radio-box--large': !!description,
      'c-form-input-radio-box--disabled': disabled,
    })}>
      <input
        id={id}
        className={classNames('c-form-input-radio-box__input', {
          'c-form-input-radio-box__input--disabled': disabled,
        })}
        type={'radio'}
        checked={get(name)(form.values) === value}
        disabled={disabled}
        onChange={() => {
          form.setFieldValue(name, value);
          form.setFieldTouched(name);
          if (onCheck) { onCheck(value); }
        }}
      />
      <div className={classNames('c-form-input-radio-box__box', {
        'c-form-input-radio-box__box--disabled': disabled,
      })}>
        <div className={classNames('c-form-input-radio-box__icon', {
          'c-form-input-radio-box__icon--large': !!description,
        })}>
          {icon}
        </div>
        <span className={classNames('c-form-input-radio-box__label', {
          'c-form-input-radio-box__label--large': !!description,
        })}>
          {label}
        </span>
        <span className={'c-form-input-radio-box__description'}>
          {description}
        </span>
      </div>
    </div>
  );
};
