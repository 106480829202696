import { saveableDsoCommissioningFields } from '+setupTool/+dsoCommissioning/store/schemas';
import {
  getSaveableMeasuringDeviceFields,
  getSaveablePvSystemFields,
  getSaveableSonnenBatteryFields,
  saveableCustomerDataFields,
  saveableDocumentFields,
  saveableInstallerDataFields,
} from '+setupTool/+form/store/schemas';
import { getSaveableVppDocumentationFields } from '+setupTool/+vppDocumentation/store/schemas';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { isNil, pick, uniqWith } from 'lodash';
import { LeadProductType } from '../lead/types/leadProductType.interface';
import {
  BatteriesResponseAttributes,
  BatteryNames,
  DsoSubmissionData,
  DsoSubmissionFormAttributes,
  PVInverterResponse,
  PVModuleResponse,
} from './types';

export const mapFields = (
  config: DsoSubmissionData,
  registrationSubject: RegistrationSubjectType,
  dsoReferenceNumberPvVisible: boolean,
  isFasteningTypeConsumptionVisible: boolean,
  isMeterCabinetPreparedVisible: boolean,
  isProductionMeterVisible: boolean,
) => {
  switch (config.step) {
    case SubmissionStep.SETUP_DATA_CUSTOMER_DATA:
      return pick(config.customerData, saveableCustomerDataFields);
    case SubmissionStep.SETUP_DATA_INSTALLER_DATA:
      return pick(config.installerData, saveableInstallerDataFields);
    case SubmissionStep.SETUP_DATA_MEASURING_DEVICE:
      return pick(config.measuringDevice, getSaveableMeasuringDeviceFields(registrationSubject));
    case SubmissionStep.SETUP_DATA_PV_SYSTEM:
      return {
        dc_coupled_battery: null,
        ...pick(config.pvSystem, getSaveablePvSystemFields(registrationSubject, config.pvSystem.pv_type)),
      };
    case SubmissionStep.SETUP_DATA_SONNEN_BATTERY:
      return pick(config.sonnenBattery, getSaveableSonnenBatteryFields(registrationSubject));
    case SubmissionStep.SETUP_DATA_UPLOAD_DOCUMENTS:
      return pick(config.documents, saveableDocumentFields);
    case SubmissionStep.SETUP_DATA_PV_SYSTEM_AND_SONNEN_BATTERY:
      return {
        dc_coupled_battery: null,
        ...pick(config.pvSystem, getSaveablePvSystemFields(registrationSubject, config.pvSystem.pv_type)),
        ...pick(config.sonnenBattery, getSaveableSonnenBatteryFields(registrationSubject)),
      };
    case SubmissionStep.DSO_COMMISSIONING_DATA:
    case SubmissionStep.PV_REGISTER:
      return pick(config.commissioningFields, saveableDsoCommissioningFields);
    case SubmissionStep.VPP_DOCUMENTATION:
      return pick(config.vppFields, getSaveableVppDocumentationFields(
        dsoReferenceNumberPvVisible,
        isFasteningTypeConsumptionVisible,
        isMeterCabinetPreparedVisible,
        config.vppFields.virtual_pv_system,
        isProductionMeterVisible,
      ));
    default:
      return null;
  }
};

export enum FieldGroups {
  SETUP_DATA = 'setup_data',
  DSO_REGISTRATION = 'dso_registration',
  DSO_COMMISSIONING = 'dso_commissioning',
  PVR = 'pvr',
  VPP = 'vpp',
}

export const mapFieldGroups = (config: DsoSubmissionData, dsoReferenceNumberPvVisible: boolean) => {
  switch (config.step) {
    case SubmissionStep.SETUP_DATA_CUSTOMER_DATA:
    case SubmissionStep.SETUP_DATA_INSTALLER_DATA:
    case SubmissionStep.SETUP_DATA_MEASURING_DEVICE:
    case SubmissionStep.SETUP_DATA_PV_SYSTEM:
    case SubmissionStep.SETUP_DATA_SONNEN_BATTERY:
    case SubmissionStep.SETUP_DATA_UPLOAD_DOCUMENTS:
    case SubmissionStep.SETUP_DATA_PV_SYSTEM_AND_SONNEN_BATTERY:
      return [FieldGroups.SETUP_DATA];
    case SubmissionStep.DSO_COMMISSIONING_DATA:
      return [FieldGroups.DSO_COMMISSIONING];
    case SubmissionStep.PV_REGISTER:
      return [FieldGroups.PVR];
    case SubmissionStep.VPP_DOCUMENTATION:
      return [FieldGroups.VPP];
    default:
      return null;
  }
};

export const dsoId = (config: DsoSubmissionFormAttributes) =>
  config.customerData && config.customerData.dso_id;

export const mapPVModuleVendors = (collection: any[]) => collection.map(v => ({
  id: v.attributes.id,
  name: v.attributes.name,
}));

export const mapPVModules = (collection: any[]) => collection.map(v => ({
  id: v.attributes.id,
  name: v.attributes.pvModuleType,
  peakPowerkWp: v.attributes.peakPowerWp / 1000,
}));

export const mapPVModule = (elem: PVModuleResponse) => ({
  id: elem.id,
  name: elem.pvModuleType,
});

export const mapPVInverterVendors = (collection: any[]) => collection.map(v => ({
  id: v.attributes.id,
  name: v.attributes.name,
}));

export const mapPVInverters = (collection: any[]) => collection.map(v => ({
  id: v.attributes.id,
  name: v.attributes.inverterType,
}));

export const mapPVInverter = (elem: PVInverterResponse) => ({
  id: elem.id,
  name: elem.inverterType,
});

export const getUniqueNames = (collection: BatteryNames[]) =>
  uniqWith(collection, (v, o) => v.name === o.name);

export const mapBatteryNames = (collection: BatteriesResponseAttributes[]) =>
  getUniqueNames(collection.map((v, index) => ({
    name: v.attributes.name,
    couplingType: v.attributes.couplingType,
  })));

export const mapBatteries = (collection: BatteriesResponseAttributes[]) => collection.map(v => ({
  id: v.attributes.id,
  nominalCapacitykWh: v.attributes.nominalCapacitykWh,
  name: v.attributes.name,
}));
