import { Attachment, CaseStatus } from '+shared/components';
import { CaseDetail } from '+shared/components/CaseDetail';
import { Case } from '+shared/store/case';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Card, Icon } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CaseCard.component.scss';

type Props = {
  caseItem: Case;
};

export const CaseCard: React.FC<Props> = ({
  caseItem: {
    account: {firstName, lastName},
    caseNumber,
    // commissionAssistantAttachmentUrl,
    createdDate,
    description,
    lastModifiedDate,
    subject,
    status,
  },
}) => (
  <div className="c-case-card">
    <Card
      header={
        <div className={'c-case-card__header'}>
          <Icon.OpenFolder className={'c-case-card__header-icon'} />
          <div className={'c-case-card__header-text'}>{`${I18n.t(T.cases.modal.caseId)}${caseNumber}`}</div>
          <div className={'c-case-card__tooltip c-case-card__text'}>
            <CaseStatus status={status}/>
            </div>
        </div>
      }
    >
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-2 o-grid__column--no-gap'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.createdAt)}
            value={!isNil(createdDate) ? formatDate(createdDate) : '-'}
            isGap={true}
          />
          <CaseDetail
            label={I18n.t(T.cases.modal.lastModifiedAt)}
            value={!isNil(lastModifiedDate) ? formatDate(lastModifiedDate) : '-'}
            isGap={true}
          />
        </div>
        <div className={'o-grid__column o-grid__column--md-3 o-grid__column--no-gap'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.reporter)}
            value={`${firstName} ${lastName}`}
            isBold={true}
            isGap={true}
          />
          <CaseDetail
            label={I18n.t(T.cases.modal.subject)}
            value={subject}
            isBold={true}
            isGap={true}
          />
        </div>
        <div className={'o-grid__column o-grid__column--md-4 o-grid__column--no-gap'}>
          <CaseDetail
            label={I18n.t(T.cases.modal.caseDescription)}
            value={description}
            isGap={true}
          />
        </div>
        <div className={'o-grid__column o-grid__column--md-3 o-grid__column--no-gap'}>
          <div className={'c-case-card__container'}>
            {/* TODO waiting for API */}
            {/* <Attachment url={commissionAssistantAttachmentUrl} /> */}
          </div>
        </div>
      </div>
    </Card>
  </div>
);
