import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { FileCategoryName } from '../+form.dictionary';
import { DsoRegistrationDocumentsInterface } from '../types';

export const DsoRegistrationDocumentsSchema = () => Yup.object().shape({
  [FileCategoryName.SITE_PLAN]: Yup
    .string()
    .required(I18n.t(T.register.alert.requiredField)),
  [FileCategoryName.WIRING_DIAGRAM]: Yup
    .string()
      .when([FileCategoryName.STANDARD_SOLAR_CIRCUIT_DIAGRAM], {
          is: false,
          then: Yup
              .string()
              .required(I18n.t(T.register.alert.requiredField)),
      }),
});

export const DsoRegistrationDocumentsInitial: DsoRegistrationDocumentsInterface = {
  standardSolarCircuitDiagram: false,
  [FileCategoryName.SITE_PLAN]: '',
  [FileCategoryName.WIRING_DIAGRAM]: '',
  [FileCategoryName.ADDITIONAL_DOCUMENTS]: '',
};

export const DsoRegistrationDocumentsFiles = [
  FileCategoryName.SITE_PLAN,
  FileCategoryName.WIRING_DIAGRAM,
  FileCategoryName.ADDITIONAL_DOCUMENTS,
];

export const saveableDocumentFields = ['standardSolarCircuitDiagram'];
