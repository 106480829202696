import { Support } from '+app/+help/+faqAndContact/containers/Support/Support.component';
import { Container } from '+shared/components';
import { FormattedText } from '+shared/components/FormattedText';
import { T } from '@sonnen/shared-i18n/service';
import { Accordion, PageSubheadline, Select, SideInfo } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import * as uuid from 'uuid/v4';
import { checkAccordionOpen, getHelpItems, getSelectedTopic } from './Help.helper';
import { HelpTopic } from './Help.types';

import './Help.component.scss';

type Props = RouteComponentProps<{}>;

const SupportElement: React.FC<{ children: string }> = ({ children }) => (
  <p className={'c-help__support'}>
    <FormattedText textClassNames={{ b: 'c-help__support--bold' }}>
      {children}
    </FormattedText>
  </p>
);

const SupportPhone: React.FC = ({ children }) => (
  <SupportElement>{I18n.t(T.help.call, { phone: children })}</SupportElement>
);
const SupportAvailability: React.FC = ({ children }) => (
  <SupportElement>{I18n.t(T.help.availability, { availability: children })}</SupportElement>
);
const SupportEmail: React.FC = ({ children }) => (
  <SupportElement>{I18n.t(T.help.email, { email: children })}</SupportElement>
);

export class Help extends React.PureComponent<Props> {
  render() {
    const helpItems = getHelpItems();
    const selectedHelpTopic = getSelectedTopic();

    return (
      <Container className={'c-help'}>
        <div className={'c-help__content'}>
          <div className={'c-help__section'}>
            {!!helpItems.length && (
              <>
                <PageSubheadline smallGap={true}>
                  {I18n.t(T.help.faq)}
                </PageSubheadline>
                <h3 className={'c-help__bold-header'}>{I18n.t(T.help.selectTopic)}</h3>
                <div className={'gtm-help__faq-menu'}>
                  <Select
                    className={'c-help__select'}
                    items={helpItems}
                    itemsSelected={selectedHelpTopic}
                    itemFactory={(item: HelpTopic) => item.title}
                    onSelect={(item: HelpTopic) => window.location.hash = item.hash}
                  />
                </div>
                <div className={'gtm-help__faq-topic'}>
                {selectedHelpTopic && selectedHelpTopic.questions.map((q, index) => (
                  <Accordion
                    key={uuid()}
                    isOpen={checkAccordionOpen(index)}
                    header={(
                      <p className={'c-help__accordion-title'}>
                        {q.question}
                      </p>
                    )}
                  >
                    <div
                      className={'c-help__answer'}
                      dangerouslySetInnerHTML={{ __html: q.answer }}
                    />
                  </Accordion>
                ))}
                </div>
              </>
            )}
          </div>
          <div className={'c-help__spacer'} />
          <div className={'c-help__section'}>
            <PageSubheadline smallGap={true}>
              {I18n.t(T.help.support)}
            </PageSubheadline>
            <Support />
            <SideInfo className={'c-help__side-info'}>
              <h3>{I18n.t(T.help.technicalSupport.title)}</h3>
              <p className={'c-help__support'}>{I18n.t(T.help.technicalSupport.description)}</p>
              <SupportPhone>{I18n.t(T.help.technicalSupport.phone)}</SupportPhone>
              <SupportAvailability>{I18n.t(T.help.technicalSupport.availability)}</SupportAvailability>
              <p className={'c-help__support'}>{I18n.t(T.help.technicalSupport.cases)}</p>
            </SideInfo>
            <SideInfo className={'c-help__side-info'}>
              <h3>{I18n.t(T.help.sonnenEservicesSupport.title)}</h3>
              <p className={'c-help__support'}>{I18n.t(T.help.sonnenEservicesSupport.description)}</p>
              <SupportPhone>{I18n.t(T.help.sonnenEservicesSupport.phone)}</SupportPhone>
              <SupportAvailability>{I18n.t(T.help.sonnenEservicesSupport.availability)}</SupportAvailability>
            </SideInfo>
            <SideInfo className={'c-help__side-info'}>
              <h3>{I18n.t(T.help.meterExchangeSupport.title)}</h3>
              <p className={'c-help__support'}>{I18n.t(T.help.meterExchangeSupport.description)}</p>
              <SupportPhone>{I18n.t(T.help.meterExchangeSupport.phone)}</SupportPhone>
              <SupportAvailability>{I18n.t(T.help.meterExchangeSupport.availability)}</SupportAvailability>
              <SupportEmail>{I18n.t(T.help.meterExchangeSupport.email)}</SupportEmail>
            </SideInfo>
            <SideInfo className={'c-help__side-info'}>
              <h3>{I18n.t(T.help.partnerSupport.title)}</h3>
              <p className={'c-help__support'}>{I18n.t(T.help.partnerSupport.description)}</p>
              <SupportPhone>{I18n.t(T.help.partnerSupport.phone)}</SupportPhone>
              <SupportAvailability>{I18n.t(T.help.partnerSupport.availability)}</SupportAvailability>
              <SupportEmail>{I18n.t(T.help.partnerSupport.email)}</SupportEmail>
            </SideInfo>
          </div>
        </div>
      </Container>
    );
  }
}
