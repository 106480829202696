import { StatusName } from '+setupTool/store/+setupTool.dictionary';
import { Props as StatusTileProps, StatusTile } from '+shared/components/StatusTile';
import * as classNames from 'classnames';
import * as React from 'react';
import './SetupToolStepStatus.component.scss';

interface OwnProps {
  status: StatusName;
}

type Props =
  StatusTileProps
  & OwnProps
  ;

export const SetupToolStepStatus: React.FC<Props> = ({ status, ...statusTileProps }) => (
  <StatusTile
    additionalClass={classNames(`c-setup-tool-step-status--${status}`, 'c-setup-tool-step-status')}
    {...statusTileProps}
  />
);
