import { Container, Portal } from '+shared/components';
import { Icon } from '@sonnen/shared-web/src/components/Icon/index';
import * as classNames from 'classnames';
import * as React from 'react';

import './Overlay.component.scss';

interface Props {
  title?: string;
  close?: () => void;
  children: React.ReactNode;
  className?: string;
}
export type Ref = HTMLDivElement;

export const Overlay = React.forwardRef<Ref, Props>(({ children, title, close, className }, ref) => (
  <>
    <Portal>
      <div
        className={classNames('c-overlay', className)}
        ref={ref}
      >
        <Container className={'c-overlay__dialog'} id={'c-overlay-setup'}>
          <div className={'c-overlay__content c-overlay__content--setup'}>
            {close && <div className={'c-overlay__close-container'}>
              <button
                onClick={close}
                className={'c-overlay__close-icon'}
              >
                <Icon.Close />
              </button>
            </div>}
            {title && <h1 className={'c-overlay__title'}>
              {title}
            </h1>}
            {children}
          </div>
        </Container>
      </div>
    </Portal>
  </>),
);
