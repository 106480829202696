import { T } from '@sonnen/shared-i18n/service';
import { Card, DefaultParagraph, Icofont, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './Download.component.scss';

export const Download: React.SFC = () => (
  <section className={'c-download'}>
    <div className={'c-download__card'}>
      <Card>
        <Icofont
          className={'c-download__icon'}
          type={'download'}
        />
        <div className={'c-download__paragraph'}>
          <DefaultParagraph>
            {I18n.t(T.download.description)}
          </DefaultParagraph>
        </div>
        <Loader />
      </Card>
    </div>
  </section>
);
