import { NotFound } from '+app/+static';
import { UserRole } from '+shared/store/user';
import * as React from 'react';
import { Omit } from 'react-redux';

interface RestrictionLogicProps {
  children: React.ReactNode;
  allowedRoles: UserRole[];
  userRoles: UserRole[];
  replaceWith?: React.ReactNode; // Use to display 404 page or just replace content with custom message.
}

export const checkRoleRestriction = (userRoles: UserRole[], allowedRoles: UserRole[]) =>
  userRoles && userRoles.some(role => allowedRoles.includes(role) || role === UserRole.SUPER_USER);

export const RestrictedToRoles: React.FC<RestrictionLogicProps> = ({
  children,
  allowedRoles,
  userRoles,
  replaceWith,
}) => checkRoleRestriction(userRoles, allowedRoles)
    ? (<>{children}</>)
    : (<>{replaceWith}</>);

export const RestrictedToRolesOr404: React.FC<Omit<RestrictionLogicProps, 'replaceWith'>> =
  ({ children, allowedRoles, userRoles }) => (
    <RestrictedToRoles allowedRoles={allowedRoles} userRoles={userRoles} replaceWith={<NotFound />}>
      {children}
    </RestrictedToRoles>
  );

export const restrictedToRoles = <P extends object>(
  Component: React.ComponentType<P>,
  allowedRoles: UserRole[],
  userRoles: UserRole[],
  replaceWith?: React.ReactNode,
) =>
  class RestrictedToRolesHOC extends React.Component<P> {
    render = () => (
      <RestrictedToRoles allowedRoles={allowedRoles} userRoles={userRoles} replaceWith={replaceWith}>
        <Component {...this.props as P} />
      </RestrictedToRoles>
    );
  };

export const restrictedToRolesOr404 = <P extends object>(
  Component: React.ComponentType<P>,
  allowedRoles: UserRole[],
  userRoles: UserRole[],
) => restrictedToRoles(Component, allowedRoles, userRoles, <NotFound />);
