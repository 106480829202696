
import { CategoryName, SelectCategory } from '+app/shared/store/firebase/types';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getAddVideoCategories = () => [
    { label: I18n.t(T.videosSubpage.categories.portal), hash: CategoryName.PORTAL},
    { label: I18n.t(T.videosSubpage.categories.other), hash: CategoryName.OTHER},
    { label: I18n.t(T.videosSubpage.categories.installation), hash: CategoryName.INSTALLATION},
    { label: I18n.t(T.videosSubpage.categories.sonnenBattery), hash: CategoryName.SONNEN_BATTERY},
    { label: I18n.t(T.videosSubpage.categories.sonnenFlat), hash: CategoryName.SONNEN_FLAT},
    { label: I18n.t(T.videosSubpage.categories.sonnenCharger), hash: CategoryName.SONNEN_CHARGER},
    { label: I18n.t(T.videosSubpage.categories.sonnenDrive), hash: CategoryName.SONNEN_DRIVE},
  ];

export const getCategories = (): SelectCategory[] => [
    { label: I18n.t(T.videosSubpage.categories.allVideos), hash: CategoryName.ALL_VIDEOS},
    ...getAddVideoCategories(),
  ];
