import {
  FormInputEventType,
  FormInputSubscriptionContext,
  FormInputSubscriptionPayload,
} from '+shared/hooks/useDispatchInputEvent';
import * as React from 'react';

interface Props<T> {
  children: React.ReactNode;
  onChange?: (payload: FormInputSubscriptionPayload<T>) => void;
  onBlur?: (payload: FormInputSubscriptionPayload<T>) => void;
}

const dispatchAction = <T extends any>({ onBlur, onChange }: Props<T>) => (action: { type: string; payload: any }) => {
  switch (action.type) {
    case FormInputEventType.ON_BLUR:
      if (onBlur) onBlur(action.payload);
      return;
    case FormInputEventType.ON_CHANGE:
      if (onChange) onChange(action.payload);
      return;
    default:
      return;
  }
};

export const FormFieldObserver = <T extends any>(props: Props<T>) => (
  <FormInputSubscriptionContext.Provider value={dispatchAction(props)}>
    {props.children}
  </FormInputSubscriptionContext.Provider>
);
