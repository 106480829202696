import { Config } from '+config';
import { from } from 'rxjs';
import { jsonApiClient } from '../../network/network.client';

const postRole = (roleName: string, contactId: string) => from(
    jsonApiClient.post(`${Config.API_URL}/roles`)
    .ofType('roles')
    .withAttributes({
      name: roleName,
    })
    .withRelationship({
      uuid: contactId,
      name: 'contact',
      type: 'contact',
    })
    .send(),
);

// @TODO refaktor roleId is mandatory
const deleteRole = (roleId?: string) => from(
  jsonApiClient.remove(`${Config.API_URL}/roles/${roleId}`)
    .send(),
);

export const RoleRepository = {
  postRole,
  deleteRole,
};
