import { NewsItem } from '+app/+newsMain/components';
import {
  NewsContainer,
} from '+app/+newsMain/containers/NewsContainer/NewsContainer.component';
import { NewsInboxListRouteQueryParams, PATHS } from '+app/router';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { Container } from '+shared/components';
import { withFirebase } from '+shared/components/FirebaseContext/Firebase.context';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { fetchReadNews } from '+shared/store/firebase/firebase.helpers';
import {
  getFirebaseNewsList,
  getFirebaseReadNewsList,
  getFirebaseReadNewsListFetched,
} from '+shared/store/firebase/firebase.selectors';
import { getActiveNewsId, NewsMainActions } from '+shared/store/newsMain';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, LinkButton, MediaQuery } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';

import './NewsInbox.component.scss';

const mapStateToProps = (state: StoreState) => ({
  newsList: getFirebaseNewsList(state),
  activeNewsId: getActiveNewsId(state),
  userProfile: getUserProfile(state),
  readNewsList: getFirebaseReadNewsList(state),
  isReadNewsListFetched: getFirebaseReadNewsListFetched(state),
});

const mapDispatchToProps = mapActions({
  setReadNewsList: FirebaseActions.setReadNewsList,
  goToNewsInbox: (urlParams: NewsInboxListRouteQueryParams) => push(PATHS.NEWS_INBOX(urlParams)),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & FirebaseProps;

export const NewsInboxComponent: React.FC<Props> = ({
  activeNewsId,
  newsList,
  actions,
  firebase,
  userProfile,
  readNewsList,
  isReadNewsListFetched,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const newsIdFromUrl = urlParams.get('newsId');
  const page = urlParams.get('page') ? Number(urlParams.get('page')) : undefined;

  const isNewsRead = (id: string) => readNewsList.map(readNews => readNews.newsId).includes(id);

  React.useEffect(() => {
    if (!isReadNewsListFetched && userProfile) {
      fetchReadNews(userProfile.id, firebase, actions.setReadNewsList);
    }
  }, []);

  return (
    <div className={'news-inbox'}>
      <Container className={'news-inbox__container'}>
        <Media query={MediaQuery.UP_NAV}>
          {(isDesktopMedia: boolean) =>
            isDesktopMedia || !newsIdFromUrl
              ? (
                <aside className={'news-inbox__sidebar'}>
                  <NewsContainer
                    paginationPage={page}
                    newsPlacement={NewsPlacementType.NEWS_INBOX_SIDEBAR}
                  />
                </aside>
              )
              : null
          }
        </Media>

        <Media query={MediaQuery.UP_NAV}>
          {(isDesktopMedia: boolean) =>
            isDesktopMedia || newsIdFromUrl
              ? (
                <section className={'news-inbox__content'}>
                  {!isDesktopMedia && (
                    <div className={'news-inbox__go-back'}>
                      <LinkButton
                        onClick={() => actions.goToNewsInbox({
                          newsId: undefined,
                          newsTitle: undefined,
                          page: urlParams.get('page') || undefined,
                        })}
                        icon={<Icon.Angle />}
                      >
                        {I18n.t(T.news.channel.goBackToList)}
                      </LinkButton>
                    </div>
                  )}

                  {newsList && newsList.filter(newsItem => newsItem.id === activeNewsId).map((newsItem: any) => {
                    return (
                      <NewsItem
                        key={uuid.v4()}
                        item={newsItem}
                        showPreviewOnly={false}
                        newsPlacement={NewsPlacementType.NEWS_INBOX_DETAIL}
                        isRead={isNewsRead(newsItem.id)}
                      />
                    );
                  })}
                </section>
              )
              : null
          }
        </Media>
      </Container>
    </div>
  );
};

export const NewsInbox =
  connect(mapStateToProps, mapDispatchToProps)
    (withFirebase(NewsInboxComponent));
