import { Icofont } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, Form, Formik, FormikProps } from 'formik';
import { debounce } from 'lodash/fp';
import * as React from 'react';
import { FormFieldError } from '../Form/FormFieldError';

import './SearchField.component.scss';

interface Props {
  value?: string;
  placeholder: string;
  isAutoFocus?: boolean;
  isAutoSearch?: boolean;
  isFullWidth?: boolean;
  validationSchema?: any;
  onSearch: (search: string) => void;
  onChange?: () => void;
}

export type SearchFieldForm = {
  search: string,
};

export const SearchField: React.FC<Props> = ({ 
  value = '',
  placeholder,
  isAutoFocus = true,
  isAutoSearch = true,
  isFullWidth = false,
  validationSchema,
  onChange,
  onSearch,
}) => {
  const handleSubmit = ({search}: SearchFieldForm) => onSearch(search.trim());

  const debounceSearch = React.useCallback(
    debounce(500, ({search}: SearchFieldForm) => handleSubmit({search})), [],
  );

  const handleChange = (form: FormikProps<SearchFieldForm>, value: string) => {
    form.setFieldValue('search', value);
    if (onChange) { onChange(); }
    if (isAutoSearch) { debounceSearch({search: value}); }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{search: value}}
      onSubmit={handleSubmit}
      render={(form) =>
        <div>
          <Form className={classNames('c-search-field', {
            'c-search-field--full-width': isFullWidth,
          })}>
            <Field
              name={'search'}
              className={'c-search-field__input'}
              placeholder={placeholder}
              autoComplete={'off'}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(form, event.target.value)}
              autoFocus={isAutoFocus}
            /> 
            <button
              className={'c-search-field__button'}
              type={'submit'}
            >
              <Icofont
                className={'c-search-field__button-icon'}
                type={'magnifying-glass'}
              />
            </button>
          </Form>
          {validationSchema && (
            <FormFieldError
              form={form}
              name={'search'}
            />
          )}
        </div>
      }
    />
  );
};
