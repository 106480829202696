import { getLeadData, getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import {
  getFasteningTypeConsumptionFieldVisibility,
  getMeterCabinetPreparedFieldVisibility,
  getProductionMeterFieldVisibility,
  getVppAdditionalFeatures,
  getVppLabelsForFlatDirect,
} from '+app/+setupTool/+vppDocumentation/store/+vppDocumentation.selectors';
import { PATHS } from '+app/router';
import { getDsoCommissioningPatchSubmissionQueryStatus } from '+setupTool/+dsoCommissioning';
import { counterSuspension, getTypeOfGridReferenceMeter } from '+setupTool/+form';
import {
  DsoRegistrationFormContainer,
  DsoRegistrationFormRadioGroup,
} from '+setupTool/+form/components';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import {
  FileCategoryName,
} from '+setupTool/+form/store/+form.dictionary';
import {
  getMeterPreparationPicturesDocumentSubmitQueryStatus,
  getWiringDiagramDocumentSubmitQueryStatus,
} from '+setupTool/+form/store/+form.selectors';
import { DsoRegistrationRadioType } from '+setupTool/+form/store/types';
import { VppCompletionNotification } from '+setupTool/+vppDocumentation/containers';
import {
  getVppCompletionNotificationVisibility,
  getVppConfigQueryStatus,
  getVppDocumentationFields,
  getVppDocumentationInitial,
  getVppDocumentationSchema,
  getVppDsoReferenceNumberPvVisibility,
  getVppSubmissionStatus,
  getVppUploadedDocuments,
  VppDocumentationActions,
  VppDocumentationFiles,
  VppDocumentationInterface,
  VppRegisterStatus,
} from '+setupTool/+vppDocumentation/store';
import { getProductionMeterCollection } from '+setupTool/+vppDocumentation/store/+vppDocumentation.helpers';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { Overlay } from '+setupTool/components/Overlay';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { removeFile, sendFile } from '+setupTool/store/+setupTool.helpers';
import { getErrors, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import {
  DatepickerArrowSide,
  DetailListTable, FormCheckbox, FormInput,
  FormInputDate, FormInputRadioGroup, FormInputSelect, FormSectionParagraph, FormUploadInput,
} from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { insertIf, searchByKey } from '+utils/array.util';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import {
  BoldParagraph,
  Bubble,
  Button,
  Icofont,
  Icon, Loadable,
  Loader,
  PageSubheadline,
  SideInfo,
} from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty, isEqual, isUndefined, omit, omitBy } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import * as uuid from 'uuid/v4';

import { AdditionalFeatures } from '+shared/store/setupTool/types';
import './VppDocumentation.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLeadData(state),
  leadId: getLeadId(state),
  userId: getUserProfileId(state),
  submissionId: getSubmissionId(state),
  submissionStatus: getVppSubmissionStatus(state),
  fields: getVppDocumentationFields(state),
  uploadedDocuments: getVppUploadedDocuments(state),
  meterPreparationPicturesDocumentSubmitQueryStatus: getMeterPreparationPicturesDocumentSubmitQueryStatus(state),
  wiringDiagramDocumentSubmitQueryStatus: getWiringDiagramDocumentSubmitQueryStatus(state),
  errors: getErrors(state),
  isDsoReferenceNumberPvVisible: getVppDsoReferenceNumberPvVisibility(state),
  isCompletionNotificationVisible: getVppCompletionNotificationVisibility(state),
  isLoaderVisible:
    getVppConfigQueryStatus(state).pending
    || getDsoCommissioningPatchSubmissionQueryStatus(state).pending,
  shouldRefetchLeadData: getVppConfigQueryStatus(state).success
    || getDsoCommissioningPatchSubmissionQueryStatus(state).success,
  isVppLabelsForFlatDirect: getVppLabelsForFlatDirect(state),
  isMeterCabinetPreparedFieldVisible: getMeterCabinetPreparedFieldVisibility(state),
  isFasteningTypeConsumptionFieldVisible: getFasteningTypeConsumptionFieldVisibility(state),
  vppAdditionalFeatures: getVppAdditionalFeatures(state),
  isProductionMeterVisible: getProductionMeterFieldVisibility(state),
});

const mapDispatchToProps = mapActions({
  goToSetupDashboard: (leadId: string, leadStage?: string) => push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  clearQuery: QueryActions.init,
  toggleModal: LayoutActions.toggleModal,
  setFields: VppDocumentationActions.setFields,
  setSubmissionStatus: VppDocumentationActions.setSubmissionStatus,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
  saveData: SetupToolActions.saveSubmission,
  saveDataAndPerformVpp: VppDocumentationActions.saveDataAndPerformVpp,
  getLead: LeadActions.getLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const VppDocumentationComponent: React.FC<Props> = ({
  actions,
  leadId,
  leadStage,
  lead,
  userId,
  submissionId,
  submissionStatus,
  fields,
  uploadedDocuments,
  meterPreparationPicturesDocumentSubmitQueryStatus,
  wiringDiagramDocumentSubmitQueryStatus,
  errors,
  isDsoReferenceNumberPvVisible,
  isCompletionNotificationVisible,
  isLoaderVisible,
  shouldRefetchLeadData,
  isVppLabelsForFlatDirect,
  isMeterCabinetPreparedFieldVisible,
  isFasteningTypeConsumptionFieldVisible,
  vppAdditionalFeatures,
  isProductionMeterVisible,
}) => {
  const formRef = React.useRef<FormikProps<VppDocumentationInterface>>({} as any);
  const isFormVisible = submissionStatus !== VppRegisterStatus.FINISH;
  const typeOfGridReferenceMeterCollection = getTypeOfGridReferenceMeter().map(i => i.value);
  const validationSchema = getVppDocumentationSchema(
    isDsoReferenceNumberPvVisible,
    isCompletionNotificationVisible,
    isMeterCabinetPreparedFieldVisible,
    isFasteningTypeConsumptionFieldVisible,
    !!fields.virtual_pv_system,
    isProductionMeterVisible,
  );
  const vppDocumentationInitial = getVppDocumentationInitial(isDsoReferenceNumberPvVisible);

  React.useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
    };
  }, []);

  React.useEffect(() => {
    if (leadId && shouldRefetchLeadData) {
      actions.getLead(leadId, LEAD_UPDATE_QUERY);
    }
  }, [shouldRefetchLeadData]);

  const isFormChanged = <T extends object>(allValues: T) => {
    const allValuesFiltered = allValues && omitBy(allValues, (value, key) => isUndefined(value)
      || VppDocumentationFiles.includes(key as any)
      || value as any === '');
    const fieldValuesFiltered = omitBy(fields, (value, key) => isUndefined(value)
      || VppDocumentationFiles.includes(key as any)
      || value === '');

    return isFormVisible && !isEqual(allValuesFiltered, fieldValuesFiltered) &&
      (submissionStatus === VppRegisterStatus.IN_PROGRESS || !isEqual(vppDocumentationInitial, allValues));
  };

  const setSynchronizedDocuments = () => {
    if (isEmpty(formRef.current)) {
      return;
    }

    const values = {
      ...formRef.current.values,
      ...(VppDocumentationFiles
        .reduce((prev, category) => {
          const documents = uploadedDocuments[category];
          const documentsPreparedData = documents
            && uploadedDocuments[category].map((item: any) => ({ 'id': item.id, 'fileName': item.fileName }));

          return { ...prev, [category]: documentsPreparedData };
        }, {})),
    };

    formRef.current.setValues(values);
  };

  const setSynchronizedFieldValuesAndDocuments = () => {
    if (isEmpty(formRef.current)) {
      return;
    }

    const savedValues = { ...fields };

    let values = {
      ...formRef.current.values,
      ...omitBy(savedValues, isUndefined),
    };

    values = {
      ...values,
      ...(VppDocumentationFiles
        .reduce((prev, category) => {
          const documents = uploadedDocuments[category];
          const documentsPreparedData = documents
            && uploadedDocuments[category].map((item: any) => ({ 'id': item.id, 'fileName': item.fileName }));

          return { ...prev, [category]: documentsPreparedData };
        }, {})),
    };

    formRef.current.setValues(values);
  };

  React.useEffect(() => {
    if (fields && formRef.current && !isEmpty(formRef.current)) {
      setSynchronizedDocuments();
    }
  },
    [
      uploadedDocuments[FileCategoryName.METER_PICTURES],
      uploadedDocuments[FileCategoryName.WIRING_DIAGRAM_VPP],
      uploadedDocuments[FileCategoryName.COMPLETION_NOTIFICATION],
    ]);

  React.useEffect(() => {
    if (fields && formRef.current && !isEmpty(formRef.current)) {
      setSynchronizedFieldValuesAndDocuments();
    }
  }, [
    fields.pv_commissioning_date,
    fields.battery_commissioning_date,
    fields.production_meter,
    fields.number_production_meter,
    fields.fastening_type_grid,
    fields.fastening_type_consumption,
    fields.meter_number,
    fields.type_of_grid_reference_meter,
    fields.dso_reference_number_pv,
    fields.pv_reduction,
    fields.meter_cabinet_prepared,
    fields.virtual_pv_system,
    fields.virtual_pv_system_subsystem_1_name,
    fields.virtual_pv_system_subsystem_2_name,
    fields.virtual_pv_system_subsystem_1_commissioning_date,
    fields.virtual_pv_system_subsystem_2_commissioning_date,
  ]);

  const onSubmit = (allValues: VppDocumentationInterface) => {
    actions.setFields(allValues);
    actions.saveDataAndPerformVpp(allValues);
  };

  const onClose = () => {
    actions.goToSetupDashboard(leadId!, leadStage);
    if (
      formRef &&
      formRef.current
    ) {
      const allValues = formRef.current.values;

      if (isFormChanged(allValues)) {
        actions.setFields(allValues);
        actions.setSubmissionStatus(VppRegisterStatus.IN_PROGRESS);
        actions.saveData(SubmissionStep.VPP_DOCUMENTATION);
      }
    }
  };

  const renderTooltip = (
    tooltipMsg: string,
  ) => (
      <div className={'c-vpp-documentation__tooltip'}>
        <Icon.Info className={'c-vpp-documentation__info-icon'} />
        <div className={'c-vpp-documentation__bubble-wrapper'}>
          <Bubble
            side={'top-left'}
            isThemeDense={false}
          >
            {tooltipMsg}
          </Bubble>
        </div>
      </div>
    );

  return (
    <Overlay
      className={'c-setup-tool__dso-summary'}
      title={isVppLabelsForFlatDirect
        ? I18n.t(T.setupTool.step.vppDocumentationFlatDirect)
        : I18n.t(T.setupTool.step.vppDocumentation)}
      close={onClose}
    >
      {isFormVisible &&
        <div className={'c-overlay__inner-content c-setup-tool__help-content'}>

          {/* todo unify SideInfo for register and commissioning */}

          <SideInfo className={'c-help__side-info'}>
            <div className={`c-help__side-info-icon`}><Icon.Info /></div>
            <div>
              <h3>{isVppLabelsForFlatDirect
                ? I18n.t(T.setupTool.vppDocumentation.help.headerFlatDirect)
                : I18n.t(T.setupTool.vppDocumentation.help.header)}</h3>
              <p>
                <Translate
                  value={isVppLabelsForFlatDirect
                    ? T.setupTool.vppDocumentation.help.textFlatDirect
                    : T.setupTool.vppDocumentation.help.text}
                  dangerousHTML={true} />
              </p>
              <br />
            </div>
          </SideInfo>
        </div>
      }

      <ErrorBox errors={errors} />

      <div className={'c-dso-commissioning-form__container'}>
        {isFormVisible ? <Formik
          initialValues={vppDocumentationInitial}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          innerRef={formRef}
          onSubmit={onSubmit}
          render={form => (
            <Form>
              <DsoRegistrationFormContainer>
                <FormSectionParagraph>{I18n.t(T.setupTool.vppDocumentation.form.header)}</FormSectionParagraph>
                {!!fields.virtual_pv_system ? (
                  <>
                    <FormInputDate
                      className={'c-dso-commissioning-data__item'}
                      form={form}
                      label={I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                        subsystemName: fields.virtual_pv_system_subsystem_1_name,
                      })}
                      name={'virtual_pv_system_subsystem_1_commissioning_date'}
                      minDate={new Date('1900-01-01')}
                      arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
                    />
                    <FormInputDate
                      className={'c-dso-commissioning-data__item'}
                      form={form}
                      label={I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                        subsystemName: fields.virtual_pv_system_subsystem_2_name,
                      })}
                      name={'virtual_pv_system_subsystem_2_commissioning_date'}
                      minDate={new Date('1900-01-01')}
                      arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
                    />
                  </>
                ) : (
                    <FormInputDate
                      className={'c-dso-commissioning-data__item'}
                      form={form}
                      label={I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate)}
                      name={'pv_commissioning_date'}
                      minDate={new Date('1900-01-01')}
                      arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
                    />
                  )
                }
                <FormInputDate
                  className={'c-dso-commissioning-data__item'}
                  form={form}
                  label={I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate)}
                  name={'battery_commissioning_date'}
                  minDate={new Date('1900-01-01')}
                  arrowPosition={DatepickerArrowSide.BOTTOM_RIGHT}
                />
              </DsoRegistrationFormContainer>

              {isProductionMeterVisible ? (
                <DsoRegistrationFormContainer>
                  <FormSectionParagraph
                    className={'c-form-section-paragraph--without-extra-margin c-form-section-paragraph--smaller'}>
                    {I18n.t(T.setupTool.form.productionMeterQuestion)}
                  </FormSectionParagraph>

                  <DsoRegistrationFormRadioGroup>
                    <FormInputRadioGroup
                      form={form}
                      name={'production_meter'}
                      text={''}
                      collection={getProductionMeterCollection()}
                    />
                  </DsoRegistrationFormRadioGroup>

                  {/* TODO this field shouldn't be sent to API when YES or val should be null/empty string*/}
                  {form.values.production_meter === DsoRegistrationRadioType.YES && (
                    <FormInput
                      className={'c-dso-commissioning-data__item'}
                      form={form}
                      label={I18n.t(T.setupTool.form.meterProductionNumber)}
                      name={'number_production_meter'}
                    />
                  )}

                </DsoRegistrationFormContainer>
              ) : null
              }
            
              <DsoRegistrationFormContainer>
                <FormInputSelect
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={isVppLabelsForFlatDirect
                    ? I18n.t(T.setupTool.form.typeOfGridMeterMountingFlatDirect)
                    : I18n.t(T.setupTool.form.typeOfGridMeterMounting)}
                  id={'setup-tool-measuring-device__fastening_type_grid'}
                  name={'fastening_type_grid'}
                  collection={counterSuspension}
                  placeholder={I18n.t(T.setupTool.selectPlaceholder)}
                />

                {isFasteningTypeConsumptionFieldVisible ? (
                  <FormInputSelect
                    className={'c-setup-tool-measuring-device__item'}
                    form={form}
                    label={I18n.t(T.setupTool.form.typeOfConsumptionMeterMounting)}
                    id={'setup-tool-measuring-device__fastening_type_consumption'}
                    name={'fastening_type_consumption'}
                    collection={counterSuspension}
                    placeholder={I18n.t(T.setupTool.selectPlaceholder)}
                  />
                ) : null
                }

                <FormInput
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.meterNumber)}
                  name={'meter_number'}
                />

                <FormInputSelect
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.typeOfGridReferenceMeter)}
                  collection={typeOfGridReferenceMeterCollection}
                  id={'setup-tool-measuring-device__type_of_grid_reference_meter'}
                  name={'type_of_grid_reference_meter'}
                  mapper={key => searchByKey('value', key, getTypeOfGridReferenceMeter(), 'name')}
                  placeholder={I18n.t(T.setupTool.selectPlaceholder)}
                />

                {!!isDsoReferenceNumberPvVisible && <FormInput
                  className={'c-dso-commissioning-data__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.dsoReferenceNumberPv)}
                  info={I18n.t(T.setupTool.form.dsoReferenceNumberPvTooltip)}
                  name={'dso_reference_number_pv'}
                />}
              </DsoRegistrationFormContainer>
              <DsoRegistrationFormContainer
                className={'c-dso-registration-container--with-tooltip'}>
                <>
                  <BoldParagraph
                    className={'c-setup-tool__upload-input-label c-setup-tool__upload-input-label--picture-format'}>
                    {isVppLabelsForFlatDirect
                      ? I18n.t(T.setupTool.form.meterPreparationPicturesFlatDirect)
                      : I18n.t(T.setupTool.form.meterPreparationPictures)
                    }
                  </BoldParagraph>
                  {renderTooltip(isVppLabelsForFlatDirect
                    ? I18n.t(T.setupTool.form.meterPreparationPicturesTooltipFlatDirect)
                    : I18n.t(T.setupTool.form.meterPreparationPicturesTooltip))
                  }
                  <FormUploadInput
                    form={form}
                    name={FileCategoryName.METER_PICTURES}
                    maxNumFiles={3}
                    accept={['image/jpg', 'image/jpeg', 'image/png', 'image/bmp']}
                    maxSize={20971520}
                    maxSizeLabel={'20 MB'}
                    label={I18n.t(T.setupTool.info.chooseFile)}
                    placeholder={I18n.t(T.setupTool.info.fileUpTo, {
                      extension: 'JPG, JPEG, PNG, BMP',
                      maxFileSize: '20 MB',
                    })}
                    isImmediatelySentToBackend={true}
                    showErrorOnlyIfTouched={true}
                    onUpload={(file) =>
                      lead && sendFile(
                        file,
                        FileCategoryName.METER_PICTURES,
                        userId,
                        lead.id,
                        submissionId,
                        actions.sendDsoDocumentsFile,
                      )}
                    isLoading={meterPreparationPicturesDocumentSubmitQueryStatus.pending}
                    onReset={(documentId) => {

                      const document = uploadedDocuments[FileCategoryName.METER_PICTURES];

                      if (document && documentId) {
                        removeFile(
                          documentId,
                          submissionId,
                          actions.removeDsoDocumentsFile,
                        );
                      }
                    }}
                  />

                  <BoldParagraph className={'c-setup-tool__upload-input-label'}>
                    {I18n.t(T.setupTool.form.wiringDiagram)}
                  </BoldParagraph>
                  {renderTooltip(isVppLabelsForFlatDirect
                    ? I18n.t(T.setupTool.form.wiringDiagramTooltipFlatDirect)
                    : I18n.t(T.setupTool.form.wiringDiagramTooltip))}
                  <FormUploadInput
                    form={form}
                    name={FileCategoryName.WIRING_DIAGRAM_VPP}
                    accept={['application/pdf']}
                    maxSize={20971520}
                    maxSizeLabel={'20 MB'}
                    label={I18n.t(T.setupTool.info.chooseFile)}
                    placeholder={I18n.t(T.setupTool.info.fileUpTo, { extension: 'PDF', maxFileSize: '20 MB' })}
                    isImmediatelySentToBackend={true}
                    showErrorOnlyIfTouched={true}
                    onUpload={(file) =>
                      lead && sendFile(
                        file,
                        FileCategoryName.WIRING_DIAGRAM_VPP,
                        userId,
                        lead.id,
                        submissionId,
                        actions.sendDsoDocumentsFile,
                      )}
                    isLoading={wiringDiagramDocumentSubmitQueryStatus.pending}
                    onReset={(documentId) => {
                      if (documentId) {
                        removeFile(
                          documentId,
                          submissionId,
                          actions.removeDsoDocumentsFile,
                        );
                      }
                    }}
                  />
                  {!!isCompletionNotificationVisible &&
                    <VppCompletionNotification form={form as FormikProps<unknown>} />
                  }
                </>

              </DsoRegistrationFormContainer>

              <FormSectionParagraph>
                <div className={'c-vpp-documentation__checkbox'}>
                  <FormCheckbox
                    form={form}
                    name={'pv_reduction'}
                    label={isVppLabelsForFlatDirect
                      ? I18n.t(T.setupTool.vppDocumentation.fields.agreement1FlatDirect)
                      : I18n.t(T.setupTool.vppDocumentation.fields.agreement1)}
                  />
                </div>
              </FormSectionParagraph>

              {isMeterCabinetPreparedFieldVisible ? (
                <FormSectionParagraph>
                  <div className={'c-vpp-documentation__checkbox'}>
                    <FormCheckbox
                      form={form}
                      name={'meter_cabinet_prepared'}
                      label={I18n.t(T.setupTool.vppDocumentation.fields.agreement2)}
                    />
                  </div>
                </FormSectionParagraph>
              ) : null}

              {!isLoaderVisible && <div className={'c-dso-commissioning-form__submit-button-container'}>
                <Button
                  type={'submit'}
                  label={I18n.t(T.setupTool.vppDocumentation.submit)}
                  isLoading={false}
                  isDisabled={!form.isValid}
                />
              </div>}
            </Form>
          )}
        /> : <>
            <div className={'c-setup-tool__static-info'}>
              <PageSubheadline>
                {I18n.t(T.setupTool.vppDocumentation.form.header)}
              </PageSubheadline>
              <Loadable predicate={!fields}>
                <DetailListTable
                  list={[
                    ...insertIf(
                      !!fields.virtual_pv_system,
                      {
                        label: I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                          subsystemName: fields.virtual_pv_system_subsystem_1_name,
                        }),
                        value: moment(fields.virtual_pv_system_subsystem_1_commissioning_date).format('DD.MM.YYYY'),
                      },
                    ),
                    ...insertIf(
                      !!fields.virtual_pv_system,
                      {
                        label: I18n.t(T.setupTool.vppDocumentation.form.virtualPvSystemSubsystemName, {
                          subsystemName: fields.virtual_pv_system_subsystem_2_name,
                        }),
                        value: moment(fields.virtual_pv_system_subsystem_2_commissioning_date).format('DD.MM.YYYY'),
                      },
                    ),
                    ...insertIf(
                      !fields.virtual_pv_system,
                      {
                        label: I18n.t(T.setupTool.dsoCommissioning.form.pvCommissioningDate),
                        value: moment(fields.pv_commissioning_date).format('DD.MM.YYYY'),
                      },
                    ),
                    {
                      label: I18n.t(T.setupTool.dsoCommissioning.form.batteryCommissioningDate),
                      value: moment(fields.battery_commissioning_date).format('DD.MM.YYYY'),
                    },
                    ...insertIf(
                      !!vppAdditionalFeatures?.includes(AdditionalFeatures.PRODUCTION_METER_FIELD),
                      {
                        label: I18n.t(T.setupTool.form.productionMeterQuestion),
                        value: fields.production_meter,
                      },
                    ),
                    ...insertIf(
                      !!vppAdditionalFeatures?.includes(AdditionalFeatures.PRODUCTION_METER_FIELD),
                      {
                        label: I18n.t(T.setupTool.form.meterProductionNumber),
                        value: fields.number_production_meter,
                      },
                    ),
                    {
                      label: isVppLabelsForFlatDirect
                        ? I18n.t(T.setupTool.form.typeOfGridMeterMountingFlatDirect)
                        : I18n.t(T.setupTool.form.typeOfGridMeterMounting),
                      value: fields.fastening_type_grid,
                    },
                    ...insertIf(
                      !!vppAdditionalFeatures?.includes(AdditionalFeatures.FASTENING_TYPE_CONSUMPTION_FIELD),
                      {
                        label: I18n.t(T.setupTool.form.typeOfConsumptionMeterMounting),
                        value: fields.fastening_type_consumption,
                      },
                    ),
                    {
                      label: I18n.t(T.setupTool.form.meterNumber),
                      value: fields.meter_number,
                    },
                    {
                      label: I18n.t(T.setupTool.form.typeOfGridReferenceMeter),
                      value: getTypeOfGridReferenceMeter()
                        .find(x => x.value === fields.type_of_grid_reference_meter)!.name,
                    },
                    ...insertIf(
                      !!vppAdditionalFeatures?.includes(AdditionalFeatures.DSO_REFERENCE_NUMBER_PV_FIELD),
                      {
                        label: I18n.t(T.setupTool.form.dsoReferenceNumberPv),
                        value: fields.dso_reference_number_pv,
                      },
                    ),
                  ]}
                />
              </Loadable>
            </div>
          </>
        }
        {isLoaderVisible && <Loader />}
      </div>

      {
        isFormVisible || <div className={'c-overlay__inner-content c-overlay__inner-content--without-top-padding'}>
          <FormSectionParagraph>
            {I18n.t(T.setupTool.vppDocumentation.documentsHeader)}
          </FormSectionParagraph>

          <div className={'c-dso-registration-summary__container-files'}>
            {Array.isArray(fields.SUBMISSION_VPP_METER_PICTURE)
              && fields.SUBMISSION_VPP_METER_PICTURE.map((item: any) =>
                (<div key={uuid()} className={'c-dso-registration-file__row'}>
                  <div className={'c-dso-registration-file__file-container'}>
                    <Icon.Contract className={'c-dso-registration-file__file-icon'} />
                    <span className={'c-link__label'}>{item.fileName}</span>
                  </div>
                </div>))}
            <div className={'c-dso-registration-file__row'}>
              <div className={'c-dso-registration-file__file-container'}>
                <Icon.Contract className={'c-dso-registration-file__file-icon'} />
                <span className={'c-link__label'}>
                  {
                    (fields.SUBMISSION_VPP_WIRING_DIAGRAM
                      && fields.SUBMISSION_VPP_WIRING_DIAGRAM[0]
                      && fields.SUBMISSION_VPP_WIRING_DIAGRAM[0].fileName)
                    || I18n.t(T.setupTool.form.wiringDiagramDefaultFileName)
                  }
                </span>
              </div>
            </div>
            {(fields[FileCategoryName.COMPLETION_NOTIFICATION]
              && fields[FileCategoryName.COMPLETION_NOTIFICATION][0]) &&
              <div className={'c-dso-registration-file__row'}>
                <div className={'c-dso-registration-file__file-container'}>
                  <Icon.Contract className={'c-dso-registration-file__file-icon'} />
                  <span className={'c-link__label'}>
                    {fields[FileCategoryName.COMPLETION_NOTIFICATION][0].fileName}
                  </span>
                </div>
              </div>
            }
          </div>

          <FormSectionParagraph>
            <div className={'c-setup-tool__dso-summary-status c-setup-tool__dso-summary-status--submit-success'}>
              <Icofont type={'tick'} />
              <span>
                {isVppLabelsForFlatDirect
                  ? I18n.t(T.setupTool.vppDocumentation.status.successFlatDirect)
                  : I18n.t(T.setupTool.vppDocumentation.status.success)}
              </span>
            </div>
          </FormSectionParagraph>
        </div>
      }

    </Overlay >
  );
};

export const VppDocumentation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VppDocumentationComponent);
