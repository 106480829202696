import { T } from '@sonnen/shared-i18n/service';
import { pvSystemKeyRegExp } from '@sonnen/shared-web/src/utils/pvSystemKeyInput';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

const meterIdRegExp = /^([a-zA-Z0-9\-]+)$/;

export const OfferFormSchema = () => Yup.object().shape({
  meterId: Yup
    .string()
    .nullable()
    .matches(meterIdRegExp, () => I18n.t(T.lead.offer._salessolution_.utilityData.meterIdError)),
  pvSystemKey1: Yup
    .string()
    .trim()
    .matches(pvSystemKeyRegExp, () => I18n.t(T.lead.offer._salessolution_.validation.pvSystemKey.invalidFormat)),
  pvSystemKey2: Yup
    .string()
    .trim()
    .matches(pvSystemKeyRegExp, () => I18n.t(T.lead.offer._salessolution_.validation.pvSystemKey.invalidFormat)),
});

export const formFields: Record<string, string> = {
  METER_ID: 'meterId',
  PROVIDER_ID: 'providerId',
  // @TODO: map fields correctly
  PV_SYSTEM_KEY_1: 'pvSystemKey1',
  PV_SYSTEM_KEY_2: 'pvSystemKey2',
};
