import { EnergyUnit, OtherUnit } from '+app/App.constants';
import { LeadConfigurationAttributes } from '+shared/store/lead/types';
import { getStatus } from '+shared/store/query/query.utils';
import { isNumber } from 'lodash/fp';
import { createSelector } from 'reselect';
import { getLeadConfiguration } from '../../store/+lead.selectors';
import { PvKind } from '../containers/LeadConfigurationPv/LeadConfigurationPv.helper';
import { createSinglePvSystem } from './+configuration.helper';
import {
  CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_SAVE_QUERY,
  CONFIGURATION_SUBMIT_QUERY,
  SET_CONFIGURATION_PROPOSAL_QUERY,
} from './+configuration.state';

export const getConfigurationSubmitQuery = createSelector(
  getLeadConfiguration,
  state => state[CONFIGURATION_SUBMIT_QUERY],
);

export const getConfigurationSubmitQueryStatus = createSelector(
  getConfigurationSubmitQuery,
  query => getStatus(query),
);

export const getConfigurationForm = createSelector(
  getLeadConfiguration,
  state => state.configurationForm,
);

export const getConfigurationProposal = createSelector(
  getLeadConfiguration,
  state => state.configurationProposal,
);

export const getConfiguration = createSelector(
  getConfigurationForm,
  configurationForm => configurationForm
    ? ({
      totalConsumptionPerYear: {
        value: configurationForm.totalConsumptionPerYear,
        unit: EnergyUnit.KWH,
      },
      photovoltaicSystem: configurationForm.multiplePv
        ? [createSinglePvSystem(configurationForm, PvKind.FIRST_PV),
        createSinglePvSystem(configurationForm, PvKind.SECOND_PV)]
        : createSinglePvSystem(configurationForm, PvKind.FIRST_PV),
      battery: {
        // TODO: adjust when LeadConfigurationAttributes returns id only
        capacityGross: isNumber(configurationForm.capacityGross)
          ? {
            value: configurationForm.capacityGross / 1000,
            unit: EnergyUnit.KWH,
          }
          : configurationForm.capacityGross,
        modelName: configurationForm.modelName,
      },
      electricCar: configurationForm.eVehicle ? {
        totalConsumptionPerYear: {
          value: 0,
          unit: EnergyUnit.KWH,
        },
        mileagePerYear: {
          value: 0,
          unit: OtherUnit.KM,
        },
      } : undefined,
      heatPump: configurationForm.heatPump ? {
        totalConsumptionPerYear: {
          value: 0,
          unit: EnergyUnit.KWH,
        },
      } : undefined,
    }) as LeadConfigurationAttributes
    : undefined,
);

export const getAccessMap = createSelector(
  getLeadConfiguration,
  state => ({
    canAccessPreview: Boolean(state.configurationProposal),
  }),
);

export const getConfigurationRecommendationSubmitQuery = createSelector(
  getLeadConfiguration,
  state => state[CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY],
);

export const getConfigurationRecommendationSubmitQueryStatus = createSelector(
  getConfigurationRecommendationSubmitQuery,
  query => getStatus(query),
);

export const getConfigurationHint = createSelector(
  getLeadConfiguration,
  state => state.configurationHint,
);

export const getConfigurationPeakPowerHint = createSelector(
  getConfigurationHint,
  state => state ? state.peakPower : undefined,
);

export const getConfigurationCapacityGrossHint = createSelector(
  getConfigurationHint,
  state => state ? state.capacityGross : undefined,
);

export const getSetConfigurationProposalQuery = createSelector(
  getLeadConfiguration,
  state => state[SET_CONFIGURATION_PROPOSAL_QUERY],
);

export const getSetConfigurationProposalQueryStatus = createSelector(
  getSetConfigurationProposalQuery,
  query => getStatus(query),
);

export const getSaveConfigurationQuery = createSelector(
  getLeadConfiguration,
  state => state[CONFIGURATION_SAVE_QUERY],
);

export const getSaveConfigurationQueryStatus = createSelector(
  getSaveConfigurationQuery,
  query => getStatus(query),
);
