import { CLOSE_LEAD_QUERY } from '+app/+lead/+overview/store';
import { getCloseLeadQueryStatus } from '+app/+lead/+overview/store/+overview.selectors';
import { getChangeLeadStatusQuery, getChangeLeadStatusQueryStatus } from '+app/+lead/store/+lead.selectors';
import { NewsSection } from '+app/+newsChannel/containers/NewsSection';
import { LeadListRouteQueryParams, PATHS, ROUTES } from '+app/router';
import { restrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { UserRole } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { Alert } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { get } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { InboxLeadListHeader } from '../../components/LeadListHeader/LeadListHeader.component';
import {
  getInboxLeadsCollectionTotalCount,
} from '../../store/+leadList.selectors';
import { LeadInbox } from '../LeadInbox';
import { LeadList } from '../LeadList';
import { LeadsAlertType, mapAlertMessage } from './Leads.helper';

import { LeadStatusName } from '+shared/store/lead/types';
import './Leads.component.scss';

const mapStateToProps = (state: StoreState) => ({
  inboxLeadCollectionTotalCount: getInboxLeadsCollectionTotalCount(state),
  closeLeadQueryStatus: getCloseLeadQueryStatus(state),
  userRoles: getUserProfileRoles(state),
  changeLeadStatusQuery: getChangeLeadStatusQuery(state),
  changeLeadStatusQueryStatus: getChangeLeadStatusQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToCreateLead: (event: React.SyntheticEvent<HTMLButtonElement>) => push(PATHS.LEAD_NEW()),
  clearQuery: QueryActions.init,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<LeadListRouteQueryParams>
  ;

type Alert = {
  type: LeadsAlertType;
  isVisible: boolean;
};

const LeadsComponent: React.FC<Props> = ({
  actions,
  inboxLeadCollectionTotalCount,
  userRoles,
  closeLeadQueryStatus,
  changeLeadStatusQuery,
  changeLeadStatusQueryStatus,
}) => {
  const { trackFunction } = useTracking();

  React.useEffect(() => {
    return () => {
      actions.clearQuery(CLOSE_LEAD_QUERY);
    };
  }, []);

  const isMovedToSetup = () => changeLeadStatusQueryStatus.success && changeLeadStatusQuery.response &&
    changeLeadStatusQuery.response.find((resElement: any) => resElement.raw.meta?.name === LeadStatusName.IN_SETUP);

  const alertMap: Alert[] = [
    {
      type: LeadsAlertType.LEAD_CLOSED,
      isVisible: closeLeadQueryStatus.success,
    },
    {
      type: LeadsAlertType.MOVED_TO_SETUP,
      isVisible: isMovedToSetup(),
    },
  ];

  const activeAlert = alertMap.find(alert => alert.isVisible);
  const activeAlertType = get('type')(activeAlert);
  const isAlertVisible = get('isVisible')(activeAlert);

  const goToCreateLead = React.useCallback(trackFunction(actions.goToCreateLead)((event) => (
    InteractionEvents.buttonClick({
      category: EventCategory.LEAD_CREATION,
      action: 'Start Lead creation',
      event,
    })
  )), [trackFunction, actions.goToCreateLead]);

  return (
    <>
      <Alert
        message={mapAlertMessage(activeAlertType)}
        isOpen={isAlertVisible}
      />

      {userRoles && <NewsSection userRoles={userRoles} />}
      <InboxLeadListHeader
        buttonAction={goToCreateLead}
        inboxLeadsTotalCount={inboxLeadCollectionTotalCount}
        userRoles={userRoles}
      />
      <Switch>
        <Route path={ROUTES.LEADS_INBOX}
          component={restrictedToRolesOr404(LeadInbox, [UserRole.COMPANY_COORDINATOR], userRoles)} />
        <Route path={ROUTES.LEADS} component={LeadList} />
      </Switch>
    </>
  );
};

export const Leads = connect(mapStateToProps, mapDispatchToProps)(LeadsComponent);
