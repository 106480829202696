import { WidgetSimple } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { checkboxTextGenerator } from '..';
import { getIconByStatus } from '../LeadEditStatusModal.helper';

import './LeadEditStatusSonnenFlat.component.scss';

interface LeadEditStatusSonnenFlatProps {
  leadStatusSummary: Lead['status']['summary'];
  userCompanyName: string;
}

export const LeadEditStatusSonnenFlat: React.FC<LeadEditStatusSonnenFlatProps> = ({
  userCompanyName,
  leadStatusSummary,
}) => (
    <div className={'c-lead-edit-status-modal__widget-wrapper'}>
      <WidgetSimple
        heading={I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.header)}
        subheading={I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.subheader)}
      >
        <div className={'c-lead-edit_status-modal__automatic-statuses-container'}>
          <div className={'c-lead-edit_status-modal__automatic-status'}>
            {getIconByStatus(leadStatusSummary.flatOfferSent)}
            {checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.sent),
              userCompanyName, true,
            )}
          </div>
          <div className={'c-lead-edit_status-modal__automatic-status'}>
            {getIconByStatus(leadStatusSummary.flatOfferAccepted)}
            {checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.accepted),
              I18n.t(T.lead.list._salessolution_.editStatusModal.flatStatuses.customer),
            )}
          </div>
          <div className={'c-lead-edit_status-modal__automatic-status'}>
            {getIconByStatus(leadStatusSummary.flatOrderConfirmed)}
            {checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.sonnenFlat.statuses.confirmed),
              I18n.t(T.lead.list._salessolution_.editStatusModal.flatStatuses.sonnen),
            )}
          </div>
        </div>
      </WidgetSimple>
    </div>
  );
