import * as moment from 'moment';

export const enum DatepickerArrowSide {
  TOP = 'top',
  BOTTOM = 'bottom',
  TOP_RIGHT = 'top-right',
  BOTTOM_RIGHT = 'bottom-right',
}

export const getWeekDaysTranslations = () => moment.weekdays();
export const getWeekDaysShortTranslations = () => moment.weekdaysMin();
export const getMonthTranslations = () => moment.months();
export const getMonthShortTranslations = () => moment.monthsShort().map((month: string) => month.replace('.', ''));
