import { CustomerRouteParams } from '+app/router';
import {
  backupPowerBuffer,
  getBattery,
  getBatteryElectricUnits,
  getBatteryQueryStatus,
  isBackupDeviceTypeIsBackupBoxOrProtect,
  isHeaterStatusConnected,
} from '+customer-battery/store/+battery.selectors';
import { getSiteLiveState, getSiteLiveStateQueryStatus } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Accessories, BatteryInstallationCard, BatteryLiveCard, BatteryTechSpecsCard } from '../../components';

type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState) => ({
  battery: getBattery(state),
  batteryElectricUnits: getBatteryElectricUnits(state),
  siteLiveState: getSiteLiveState(state),
  siteLiveStateQueryStatus: getSiteLiveStateQueryStatus(state),
  batteryQueryStatus: getBatteryQueryStatus(state),
  isHeaterConnected: isHeaterStatusConnected(state),
  isBackupProperType: isBackupDeviceTypeIsBackupBoxOrProtect(state),
  powerBuffer: backupPowerBuffer(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteProps
  ;

class CustomerBatteryComponent extends React.PureComponent<Props> {
  render() {
    const {
      battery,
      batteryElectricUnits,
      siteLiveState,
      batteryQueryStatus,
      siteLiveStateQueryStatus,
      isHeaterConnected,
      isBackupProperType,
      powerBuffer,
    } = this.props;

    const isBackupBoxConnected = isBackupProperType && !isNil(powerBuffer);
    const isAccessoriesSectionVisible = isHeaterConnected || isBackupBoxConnected;

    return (
      <>
        {(battery && siteLiveState) && (
          <>
            <div className={'o-grid'}>
              <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}>
                <BatteryLiveCard
                  battery={battery}
                  siteLiveState={siteLiveState}
                  queryStatus={siteLiveStateQueryStatus}
                />
              </div>
              <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}>
                <BatteryTechSpecsCard
                  battery={battery}
                  batteryElectricUnits={batteryElectricUnits}
                  queryStatus={batteryQueryStatus}
                />
              </div>
              <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}>
                <BatteryInstallationCard
                  battery={battery}
                  queryStatus={batteryQueryStatus}
                />
              </div>
            </div>

            {isAccessoriesSectionVisible && (
              <Accessories
                battery={battery}
                siteLiveState={siteLiveState}
                isHeaterConnected={isHeaterConnected}
                isBackupBoxConnected={isBackupBoxConnected}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export const CustomerBattery = connect(mapStateToProps)(CustomerBatteryComponent);
