import { ListTableHeaderItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getPartnerEmployeeListTableHeaders = (): ListTableHeaderItem[] => [
  { label: I18n.t(T.lead.list._salessolution_.assignModal.salesPersonName) },
  { label: '' }, // TODO: location here
];

export type LeadListAssignModalFormType = {
  chosenPartnerId: string;
};
