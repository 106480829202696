import { BATTERY_ACTIONS, BatteryActions } from '../../../shared/store/battery';
import { queryReducer } from '../../../shared/store/query';
import { createPersistedReducer } from '../../../utils';
import { CUSTOMER_ACTIONS, CustomerActions } from '../../store/+customer.actions';
import {
  BatteryPageState,
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_QUERY,
  GET_BATTERY_STATUSES_QUERY,
  initialState,
  PUT_BATTERY_NOTE_QUERY,
} from './+battery.state';

export const reducer = createPersistedReducer<BatteryPageState, BatteryActions | CustomerActions>(
  (state = initialState, action): BatteryPageState => {
    switch (action.type) {
      case BATTERY_ACTIONS.SET_BATTERY:
        return {
          ...state,
          battery: action.battery,
        };
      case BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS:
        return {
          ...state,
          batteryElectricUnits: action.batteryElectricUnits,
        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          GET_BATTERY_QUERY,
          GET_BATTERY_ELECTRIC_UNITS_QUERY,
          PUT_BATTERY_NOTE_QUERY,
          GET_BATTERY_STATUSES_QUERY,
        ]);
    }
  },
)({
  key: 'customer-page-battery',
  blacklist: [
    GET_BATTERY_QUERY,
    GET_BATTERY_ELECTRIC_UNITS_QUERY,
    PUT_BATTERY_NOTE_QUERY,
  ],
});
