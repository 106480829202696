import { LeadListRouteQueryParams } from '+app/router';
import { ActionsUnion, createAction } from '+app/utils';
import { Lead, LeadStatusName } from '+shared/store/lead/types';

export enum LEAD_LIST_PAGE_ACTIONS {
  GET_LEAD_LIST = '[+Lead] GET_LEAD_LIST',
  SET_LEAD_LIST = '[+Lead] SET_LEAD_LIST',

  SET_LEAD_STATUS_FILTERS = '[+Lead] SET_LEAD_STATUS_FILTERS',
  SET_SETUP_LEAD_STATUS_FILTERS = '[+Lead] SET_SETUP_LEAD_STATUS_FILTERS',
  SET_LEAD_PREVIOUS_STATUS_FILTERS = '[+Lead] SET_LEAD_PREVIOUS_STATUS_FILTERS',
  SET_SETUP_LEAD_PREVIOUS_STATUS_FILTERS = '[+Lead] SET_SETUP_LEAD_PREVIOUS_STATUS_FILTERS',
  CLEAR_LEAD_STATUS_FILTERS = '[+Lead] CLEAR_LEAD_STATUS_FILTERS',
  CLEAR_SETUP_LEAD_STATUS_FILTERS = '[+Lead] CLEAR_SETUP_LEAD_STATUS_FILTERS',
  CLEAR_PREVIOUS_LEAD_STATUS_FILTERS = '[+Lead] CLEAR_PREVIOUS_LEAD_STATUS_FILTERS',
  CLEAR_PREVIOUS_SETUP_LEAD_STATUS_FILTERS = '[+Lead] CLEAR_PREVIOUS_SETUP_LEAD_STATUS_FILTERS',

  SET_INBOX_LEAD_LIST = '[+Lead] SET_INBOX_LEAD_LIST',
  GET_INBOX_LEAD_LIST = '[+Lead] GET_INBOX_LEAD_LIST',
  SET_SETUP_LEAD_LIST = '[+Lead] SET_SETUP_LEAD_LIST',
  ASSIGN_PARTNER_TO_LEAD = '[+Lead] ASSIGN_PARTNER_TO_LEAD',
  REASSIGN_PARTNER_TO_LEAD = '[+Lead] REASSIGN_PARTNER_TO_LEAD_IN_LEAD_LIST',
  DECLINE_LEAD = '[+Lead] DECLINE_LEAD',
  TRIGGER_GET_LEAD_LIST = '[+Lead] TRIGGER_GET_LEAD_LIST',
  GET_FILTERED_LEAD_LIST = '[+Lead] GET_FILTERED_LEAD_LIST',
}

export const LeadListPageActions = {
  getLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.GET_LEAD_LIST,
    ({ queryKey, queryParams, statusList }: {
      queryKey: string,
      queryParams: LeadListRouteQueryParams,
      statusList?: LeadStatusName[],
    }) =>
      ({ queryKey, queryParams, statusList }),
  ),
  setLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_LEAD_LIST,
    (collection: Lead[], total?: number) => ({ collection, total }),
  ),
  setLeadStatusFilters: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_LEAD_STATUS_FILTERS,
    (statusFilters: LeadStatusName[]) => ({ statusFilters }),
  ),
  setSetupLeadStatusFilters: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_STATUS_FILTERS,
    (statusFilters: LeadStatusName[]) => ({ statusFilters }),
  ),
  setLeadPreviousStatusFilters: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_LEAD_PREVIOUS_STATUS_FILTERS,
    (statusFilters: LeadStatusName[]) => ({ statusFilters }),
  ),
  setSetupLeadPreviousStatusFilters: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_PREVIOUS_STATUS_FILTERS,
    (statusFilters: LeadStatusName[]) => ({ statusFilters }),
  ),
  clearLeadStatusFilters: createAction(LEAD_LIST_PAGE_ACTIONS.CLEAR_LEAD_STATUS_FILTERS),
  clearSetupLeadStatusFilters: createAction(LEAD_LIST_PAGE_ACTIONS.CLEAR_SETUP_LEAD_STATUS_FILTERS),
  clearPreviousLeadStatusFilters: createAction(LEAD_LIST_PAGE_ACTIONS.CLEAR_PREVIOUS_LEAD_STATUS_FILTERS),
  clearPreviousSetupLeadStatusFilters: createAction(LEAD_LIST_PAGE_ACTIONS.CLEAR_PREVIOUS_SETUP_LEAD_STATUS_FILTERS),
  getInboxLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.GET_INBOX_LEAD_LIST,
  ),
  setInboxLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_INBOX_LEAD_LIST,
    (collection: Lead[], total?: number) => ({ collection, total }),
  ),
  setSetupLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.SET_SETUP_LEAD_LIST,
    (collection: Lead[], total?: number) => ({ collection, total }),
  ),
  assignPartnerToLead: createAction(
    LEAD_LIST_PAGE_ACTIONS.ASSIGN_PARTNER_TO_LEAD,
    (leadId: string, partnerSalesforceContactId: string) => ({ leadId, partnerSalesforceContactId }),
  ),
  reassignPartnerToLead: createAction(
    LEAD_LIST_PAGE_ACTIONS.REASSIGN_PARTNER_TO_LEAD,
    (leadId: string, partnerSalesforceContactId: string) => ({ leadId, partnerSalesforceContactId }),
  ),
  declineLead: createAction(
    LEAD_LIST_PAGE_ACTIONS.DECLINE_LEAD,
    (leadId: string, reason: string) => ({ leadId, reason }),
  ),
  triggerGetLeadList: createAction(
    LEAD_LIST_PAGE_ACTIONS.TRIGGER_GET_LEAD_LIST,
  ),
};

export type LeadListPageActions = ActionsUnion<typeof LeadListPageActions>;
