import { StaticActions } from '+app/+static/store';
import { AVAILABLE_LANGUAGES, Locale } from '+app/i18n/i18n.config';
import { getLanguageFromCurrentLocale } from '+app/i18n/i18n.selectors';
import { PATHS } from '+app/router';
import { mapActions } from '+app/utils';
import { T } from '@sonnen/shared-i18n/service';
import { LanguageSelect } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { I18nActions } from '../../../i18n';
import { Footer, FooterNavItem, FooterSocial } from '../../components';
import { StoreState } from '../../store/store.interface';

interface ComponentProps {
  isTransparent: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  currentLanguage: getLanguageFromCurrentLocale(state),
});

const mapDispatchToProps = mapActions({
  setLocale: I18nActions.setLocale,
  getDocument: StaticActions.getDocument,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & ComponentProps
  ;

export class LayoutFooterComponent extends React.PureComponent<Props> {
  renderFooterNavItems = ({ actions, currentLanguage }: Props) => (
    <>
      <FooterNavItem>
        <LanguageSelect
          selected={currentLanguage}
          langs={AVAILABLE_LANGUAGES}
          onChange={locale => actions.setLocale(locale as Locale)}
        />
      </FooterNavItem>
      <FooterNavItem
        isExternal={true}
        action={I18n.t(T.general.footerNavigation.termsOfUseLink)}
      >
        {I18n.t(T.general.footerNavigation.termsOfUse)}
      </FooterNavItem>
      <FooterNavItem 
        isExternal={true}
        action={I18n.t(T.general.footerNavigation.imprintLink)}
      > 
        {I18n.t(T.general.footerNavigation.imprint)}
      </FooterNavItem>
      <FooterNavItem
        isExternal={true}
        action={I18n.t(T.general.footerNavigation.jointControllerLink)}
      >
        {I18n.t(T.general.footerNavigation.jointController)}
      </FooterNavItem>
    </>
  );

  renderSocialItems = () => (
    <>
      <FooterSocial
        icon={'fb'}
        link={I18n.t(T.general.socialProfiles.facebookLink)}
      />
      <FooterSocial
        icon={'tw'}
        link={I18n.t(T.general.socialProfiles.twitterLink)}
      />
      <FooterSocial
        icon={'yt'}
        link={I18n.t(T.general.socialProfiles.youtubeLink)}
      />
    </>
  );

  render() {
    const { isTransparent } = this.props;

    return (
      <Footer
        isTransparent={isTransparent}
        copyrightNotice={I18n.t(T.general.copyrights)}
        navItems={this.renderFooterNavItems(this.props)}
        socialItems={this.renderSocialItems()}
      />
    );
  }
}

export const LayoutFooter = connect(mapStateToProps, mapDispatchToProps)(LayoutFooterComponent);
