export enum Env {
  TEST = 'test',
  DEVELOPMENT = 'development',
  DEMO = 'demo',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export const getFilteredEnvironment = (env: Env) => {
  switch (env) {
    case Env.TEST:
    case Env.DEVELOPMENT:
    case Env.DEMO:
    case Env.STAGING:
    case Env.PRODUCTION:
      return env;
    default:
      throw new Error(`Invalid environment: ${env}`);
  }
};

export const getEnvironment = () => getFilteredEnvironment(process.env.CONFIG as Env || Env.PRODUCTION);
