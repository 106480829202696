import { GtmSuccess } from '+app/shared/GoogleTagManager/events/event.types';
import { FormStatus } from '+shared/components/FormStatus';
import { FormEvents, useTracking } from '+shared/GoogleTagManager';
import { useSuccessTracking } from '+shared/hooks/useStatusTracking';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CaseReportSuccess.component.scss';

interface Props {
  onClose: () => void;
  onMore?: () => void;
  gtm?: GtmSuccess;
  caseNumber: string | undefined;
}
  
export const CaseReportSuccess: React.FC<Props> = ({
  onClose,
  onMore,
  caseNumber,
  gtm,
}) => {
  const { track } = useTracking();

  useSuccessTracking(() => { 
    if (gtm) {
      track(FormEvents.success({
        category: gtm.category,
        formName: gtm.formName,
      }));
    }
  }, gtm && gtm.queryStatus);

  return (
    <FormStatus
      headline={I18n.t(T.report.success.headline, {caseId: caseNumber})}
      isSuccess={true}
      footer={
        <div className={'c-case-report-success__buttons'}>
          {onMore && <Button
            label={I18n.t(T.report.success.moreBtn)}
            size={ButtonSize.NORMAL}
            theme={ButtonTheme.LIGHT}
            onClick={onMore}
          />}
          <Button
            label={I18n.t(T.report.success.doneBtn)}
            size={ButtonSize.NORMAL}
            onClick={onClose}
          />
        </div>
      }
    >
      <DefaultParagraph>{I18n.t(T.report.success.paragraph)}</DefaultParagraph>
    </FormStatus>
  );
};
