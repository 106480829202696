import { getCustomerOverviewPageState } from '+app/+customer/store/+customer.selectors';
import { getStatus } from '+shared/store/query/query.utils';
import { createSelector } from 'reselect';
import { GET_CHARGERS_QUERY } from './+overview.state';

export const getCharger = createSelector(
  getCustomerOverviewPageState,
  overviewPageState => overviewPageState.charger,
);

export const getChargerId = createSelector(
  getCharger,
  chargerState => chargerState?.id,
);

export const getChargersQueryStatus = createSelector(
  getCustomerOverviewPageState,
  overviewPageState => getStatus(overviewPageState[GET_CHARGERS_QUERY]),
);
