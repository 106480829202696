import { getLatestDocumentsGenerationDate } from '+setupTool/+dsoRegistration/store/+dsoRegistration.selectors';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep, SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { DsoRegisterRepository, getConfiguration } from '+shared/store/setupTool';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapToState, ofType, processQuery } from '+utils/index';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, iif, of, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DsoRegistrationActions } from './+dsoRegistration.actions';
import { DSO_REGISTRATION_DOCUMENT_CATEGORIES, GENERATE_DOCUMENTS_QUERY } from './+dsoRegistration.state';

type Action$ = ActionsObservable<DsoRegistrationActions>;
type State$ = StateObservable<StoreState>;

const generateDocuments$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationActions.generateDocuments),
  mapToState(state$),
  mergeMap(state => zip(
    of(getConfiguration(state)),
    of(getLatestDocumentsGenerationDate(state)),
  )),
  mergeMap(([config, dsoDocumentsGenerationDate]) => of({}).pipe(
    processQuery(
      GENERATE_DOCUMENTS_QUERY,
      () =>
        DsoRegisterRepository.dsoGenerateDocuments(config.submissionId!, [SubmissionStep.DSO_REGISTRATION]), {
        onSuccess: res => concat(
          iif(
            () => !dsoDocumentsGenerationDate,
            iif(
              () => res.data.fields.standardSolarCircuitDiagram,
              of(DsoRegistrationActions.trackDsoRegistrationStandardWiring()),
              of(DsoRegistrationActions.trackDsoRegistrationCustomWiring()),
            ),
          ),
          dataGuard(DsoRegistrationActions.setDsoRegistrationGeneratedDocuments)
          (res!.data.documents.filter(document =>
            document.stepType === SubmissionStep.DSO_REGISTRATION
            && DSO_REGISTRATION_DOCUMENT_CATEGORIES.includes(document.upload.category),
          )),
          dataGuard(DsoRegistrationActions.setDsoRegistrationModificationDate)
          (res!.data.stepsUpdatedAt[SubmissionStepCamelCase.DSO_REGISTRATION]),
        ),
      }),
  )),
);

const setGeneratedDocuments$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setGeneratedDocuments),
  map(action => action.data),
  map(documents => documents.filter(document =>
    document.stepType === SubmissionStep.DSO_REGISTRATION
    && DSO_REGISTRATION_DOCUMENT_CATEGORIES.includes(document.upload.category),
  )),
  mergeMap(dataGuard(DsoRegistrationActions.setDsoRegistrationGeneratedDocuments)),
);

const setLatestModification$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setLatestModificationDate),
  map(action => action.data[SubmissionStepCamelCase.DSO_REGISTRATION]),
  mergeMap(dataGuard(DsoRegistrationActions.setDsoRegistrationModificationDate)),
);

export const epics = combineEpics(
  generateDocuments$,
  setGeneratedDocuments$,
  setLatestModification$,
);
