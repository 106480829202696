import { Contact } from '+shared/store/contact/types';
import {
  Lead,
  LeadAddressValidationResponse,
  LeadConfigurationResponse,
  LeadMetaData,
  LeadStatusName,
} from '+shared/store/lead/types';
import { LeadAddressDetails, LeadAutosuggestion } from '+shared/store/lead/types/leadAutosuggestion.interface';
import { Query } from '+shared/store/query';
import { PersistedState } from 'redux-persist';
import * as configuration from '../+configuration/store';
import * as hardware from '../+hardware/store';
import * as impactAnalysis from '../+impactAnalysis/store';
import * as leadList from '../+list/store';
import * as offer from '../+offer/store';
import * as overview from '../+overview/store';
import { LeadForm } from './types';

export const LEAD_SUBMIT_QUERY = 'leadSubmitQuery';
export const GET_LEAD_QUERY = 'getLeadQuery';
export const LEAD_UPDATE_QUERY = 'leadUpdateQuery';
export const CHANGE_LEAD_STATUS_QUERY = 'changeLeadStatusQuery';
export const ASSIGN_PARTNER_TO_LEAD_QUERY = 'assignPartnerToLeadQuery';
export const DECLINE_LEAD_QUERY = 'declineLeadQuery';
export const GET_ADDRESS_AUTOSUGGESTIONS_QUERY = 'getAddressAutosuggestionsQuery';
export const GET_ADDRESS_DETAILS_QUERY = 'getAddressADetailsQuery';
export const VALIDATE_LEAD_ADDRESS_QUERY = 'validateLeadAddressQuery';

export interface LeadPagePartnerAssignmentState {
  selectedLead?: Lead;
  isAssignLeadModalOpen: boolean;
  isDeclineLeadModalOpen: boolean;
}

export interface LeadPageCommonState extends PersistedState {
  leadForm?: LeadForm;
  lead?: Lead;
  userPermissions: LeadMetaData['permissions'];
  partnerEmployeeList: Contact[];
  partnerAssignment: LeadPagePartnerAssignmentState;
  addressAutosuggestions: LeadAutosuggestion[];
  addressDetails?: LeadAddressDetails;
  isAddressValid: boolean | undefined;
  openAddressEdit: boolean | undefined;
  leadStatusToBeChanged?: LeadStatusName;
  [LEAD_SUBMIT_QUERY]: Query<any>;
  [GET_LEAD_QUERY]: Query<any>;
  [LEAD_UPDATE_QUERY]: Query<LeadConfigurationResponse>;
  [CHANGE_LEAD_STATUS_QUERY]: Query<any>;
  [ASSIGN_PARTNER_TO_LEAD_QUERY]: Query<any>;
  [DECLINE_LEAD_QUERY]: Query<any>;
  [GET_ADDRESS_AUTOSUGGESTIONS_QUERY]: Query<any>;
  [GET_ADDRESS_DETAILS_QUERY]: Query<any>;
  [VALIDATE_LEAD_ADDRESS_QUERY]: Query<LeadAddressValidationResponse>;
}

export interface LeadPageState {
  common: LeadPageCommonState;
  list: leadList.LeadListPageState;
  offer: offer.LeadOfferPageState;
  overview: overview.LeadOverviewPageState;
  configuration: configuration.ConfigurationPageState;
  hardware: hardware.HardwarePageState;
  impactAnalysis: impactAnalysis.ImpactAnalysisState;
}

export const initialPartnerAssignmentState = {
  selectedLead: undefined,
  isAssignLeadModalOpen: false,
  isDeclineLeadModalOpen: false,
};

export const initialUserPermissions = {
  notes: {
    create: false,
  },
  leads: {
    editPersonalData: false,
  },
};

export const initialCommonState: LeadPageCommonState = {
  leadForm: undefined,
  lead: undefined,
  userPermissions: initialUserPermissions,
  partnerEmployeeList: [],
  isAddressValid: undefined,
  openAddressEdit: undefined,
  partnerAssignment: initialPartnerAssignmentState,
  addressAutosuggestions: [],
  addressDetails: undefined,
  leadStatusToBeChanged: undefined,
  [LEAD_SUBMIT_QUERY]: {},
  [GET_LEAD_QUERY]: {},
  [LEAD_UPDATE_QUERY]: {},
  [CHANGE_LEAD_STATUS_QUERY]: {},
  [ASSIGN_PARTNER_TO_LEAD_QUERY]: {},
  [DECLINE_LEAD_QUERY]: {},
  [GET_ADDRESS_AUTOSUGGESTIONS_QUERY]: {},
  [GET_ADDRESS_DETAILS_QUERY]: {},
  [VALIDATE_LEAD_ADDRESS_QUERY]: {},
};

export const initialState: LeadPageState = {
  common: initialCommonState,
  list: leadList.initialState,
  offer: offer.initialState,
  overview: overview.initialState,
  configuration: configuration.initialState,
  hardware: hardware.initialState,
  impactAnalysis: impactAnalysis.initialState,
};
