import {
  DsoRegistrationRadioType,
} from '+setupTool/+form/store/types';

export const isOnlinePortalFieldName = 'is_online_portal';

export enum FileCategoryName {
  POWER_OF_ATTORNEY = 'SUBMISSION_POWER_OF_ATTORNEY',
  SITE_PLAN = 'SUBMISSION_SITE_PLAN',
  WIRING_DIAGRAM = 'SUBMISSION_WIRING_DIAGRAM',
  WIRING_DIAGRAM_VPP = 'SUBMISSION_VPP_WIRING_DIAGRAM',
  METER_PICTURES = 'SUBMISSION_VPP_METER_PICTURE',
  COMPLETION_NOTIFICATION = 'SUBMISSION_VPP_COMPLETION_NOTIFICATION',
  ADDITIONAL_DOCUMENTS = 'SUBMISSION_ADDITIONAL_DOCUMENTS',
  PVR_CONFIRMATION = 'SUBMISSION_pvr_confirmation',
  STANDARD_SOLAR_CIRCUIT_DIAGRAM = 'standardSolarCircuitDiagram',
  STANDARD_SOLAR_CIRCUIT_DIAGRAM_VPP = 'standard_solar_circuit_diagram_vpp',
}

export const DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY = 'submitDsoRegisterFormConfigQuery';
export const SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY = 'sendSitePlanDocumentConfigQuery';
export const SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY = 'sendWiringDiagramDocumentConfigQuery';
export const SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY = 'sendMeterPicturesDocumentConfigQuery';
export const SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY = 'sendCompletionNotificationDocumentConfigQuery';
export const SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY = 'sendAdditionalDocumentDocumentConfigQuery';
export const SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY = 'sendPowerOfAttorneyDocumentConfigQuery';
export const SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY = 'sendPvrConfirmationDocumentSubmitQuery';

export const REMOVE_DOCUMENT_SUBMIT_QUERY = 'removeDocumentConfigQuery';

export const GET_DSO_LIST_QUERY = 'getDsoListQuery';

export const GET_PV_MODULE_VENDORS_QUERY = 'getPVModuleVendorsQuery';
export const GET_PV_MODULES_QUERY = 'getPVModulesQuery';
export const GET_PV_MODULE_QUERY = 'getPVModuleQuery';

export const GET_PV_INVERTER_VENDORS_QUERY = 'getPVInverterVendorsQuery';
export const GET_PV_INVERTERS_QUERY = 'getPVInvertersQuery';
export const GET_PV_INVERTER_QUERY = 'getPVInverterQuery';

export const GET_BATTERIES_QUERY = 'getBatteriesQuery';
export const GET_BATTERY_NAMES_QUERY = 'getBatteryNamesQuery';

export const GET_INSTALLERS_QUERY = 'getInstallersQuery';
export const SUBMIT_INSTALLER_QUERY = 'submitInstallerQuery';
export const REMOVE_INSTALLERS_QUERY = 'removeInstallersQuery';

export const SEND_FILES_QUERY = {
  [FileCategoryName.POWER_OF_ATTORNEY]: SEND_POWER_OF_ATTORNEY_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.SITE_PLAN]: SEND_SITE_PLAN_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.WIRING_DIAGRAM]: SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.WIRING_DIAGRAM_VPP]: SEND_WIRING_DIAGRAM_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.METER_PICTURES]: SEND_METER_PICTURES_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.COMPLETION_NOTIFICATION]: SEND_COMPLETION_NOTIFICATION_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.ADDITIONAL_DOCUMENTS]: SEND_ADDITIONAL_DOCUMENT_SUBMIT_QUERY,
  [FileCategoryName.PVR_CONFIRMATION]: SEND_PVR_CONFIRMATION_DOCUMENT_SUBMIT_QUERY,
};

export const REMOVE_FILES_QUERY = REMOVE_DOCUMENT_SUBMIT_QUERY;

export const radioInputCollection = [
  {
    value: DsoRegistrationRadioType.YES,
    label: DsoRegistrationRadioType.YES,
  },
  {
    value: DsoRegistrationRadioType.NO,
    label: DsoRegistrationRadioType.NO,
  },
];

export const MEASURING_CONCEPT_SURPLUS = 'measuring_concept_surplus';
export const FEED_IN_MANAGEMENT_07 = 'feed_in_management_0.7';
export const FEED_IN_MANAGEMENT_FRE = 'feed_in_management_fre';
