import { Container } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { 
  SonnenDriveBenefits, 
  SonnenDriveCars, 
  SonnenDriveFooter, 
  SonnenDriveProcess, 
  SonnenDrivePromo, 
  SonnenDriveTestimonials,
} from '../components';

export const Drive: React.SFC = () => (
  <Container>
    <PageHeadline>{I18n.t(T.drive.pageTitle)}</PageHeadline>
    <SonnenDrivePromo />
    <SonnenDriveBenefits />
    <SonnenDriveCars />
    <SonnenDriveProcess />
    <SonnenDriveTestimonials />
    <SonnenDriveFooter />
  </Container>
);
