import { LeadAttributes, LeadConfigurationResponseAttributes } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { isFlatDirectConfiguration } from '../../store/+configuration.helper';
import { LeadConfigurationBox } from '../LeadConfigurationBox';
import {
  factorizeFirstBoxDetails,
  factorizeFourthBoxDetails,
  factorizeSecondBoxDetails,
  factorizeThirdBoxDetails,
  getCurrencyForMarket,
} from './LeadConfigurationPreview.helper';

import './LeadConfigurationPreview.component.scss';

interface Props {
  configProposal: LeadConfigurationResponseAttributes;
  lead: LeadAttributes;
}

export const LeadConfigurationPreview: React.FC<Props> = ({ configProposal }) => {
  const currency = getCurrencyForMarket(configProposal ? configProposal.market : undefined);
  const factorizedConfDetails = [
    factorizeFirstBoxDetails(configProposal, currency),
    factorizeSecondBoxDetails(configProposal, currency),
    factorizeThirdBoxDetails(configProposal, currency),
    factorizeFourthBoxDetails(configProposal, currency),
  ];
  const shouldDisplayBasicFeeWarning = (boxIndex: number) =>
    boxIndex === 0 && isFlatDirectConfiguration(configProposal);

  return (
    <div className={'c-lead-configuration-preview'}>
      {factorizedConfDetails
        .map((factorizedConfDetail, index) =>
          <div className={'c-lead-configurtion-preview__box'} key={`configuration-preview-${index}`}>
            <LeadConfigurationBox
              parameters={factorizedConfDetail}
              key={index}
              warningText={shouldDisplayBasicFeeWarning(index) ?
                I18n.t(T.lead.configuration._salessolution_.preview.basicFeeWarning)
                : undefined}
            />
          </div>,
        )}
    </div>
  );
};
