import { createQueryString, getHashParams, getQueryParams, redirectTo } from '+app/utils';
import { Config } from '+config';
import { toCamelCase } from '+utils/toCamelCase.util';
import { HttpResponseError } from '@coolio/http';
import { isNil } from 'lodash';
import * as uuid from 'uuid';
import { AuthConfig, AuthContext, AuthParams, AuthSalesforceParams } from './auth.state';

export class DecodeStateError extends Error {
  constructor() {
    super(`State is not a valid base64 string or JSON inside it is malformed`);
  }
}

export class InvalidVerifierCodeError extends Error {
  constructor() {
    super('State has invalid verifier code');
  }
}

const param = (key: string, value: string | undefined) => value
  ? `${key}=${encodeURIComponent(value)}`
  : '';

export const loginUrlFactory = async (config: AuthConfig) =>
  `${Config.AUTH.AUTH_URL}/oauth/authorize?${[
    param('client_id', config.clientId),
    param('response_type', config.responseType),
    param('redirect_uri', config.redirectUri),
    param('code_challenge', await createCodeChallenge(config.verifier!)),
    param('code_challenge_method', 'S256'),
    param('state', config.state),
  ].filter(Boolean).join('&')}`;

export const loginSalesforceUrlFactory = (isImpostor?: boolean) => (config: AuthConfig) =>
  `${isImpostor
    ? Config.AUTH_SALESFORCE.SALESFORCE_TECH_URL
    : Config.AUTH_SALESFORCE.SALESFORCE_URL}/services/oauth2/authorize?${[
      param('response_type', config.responseType),
      param('client_id', config.clientId),
      param('redirect_uri', config.redirectUri),
      param('scope', config.scope),
      param('state', config.state),
      param('nonce', uuid()),
    ].filter(Boolean).join('&')}`;

const typedArrayToSafeBase64 = (array: Uint8Array): string =>
  btoa(array.reduce((data, byte) => data + String.fromCharCode(byte), ''))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

const stringToTypedArray = (str: string): Uint8Array => {
  const array = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    array[i] = str.charCodeAt(i);
  }
  return array;
};

export const createCodeVerifier = (): string =>
  typedArrayToSafeBase64(crypto.getRandomValues(new Uint8Array(96)));

export const createCodeChallenge = (codeVerifier: string): PromiseLike<string> => {
  return crypto.subtle.digest('SHA-256', stringToTypedArray(codeVerifier))
    .then(result => typedArrayToSafeBase64(new Uint8Array(result)));
};

export const getLoginConfig = (verifier: string): AuthConfig => ({
  redirectUri: getRedirectUri(),
  responseType: 'code',
  clientId: Config.AUTH.CLIENT_ID,
  scope: 'read',
  verifier,
  state: encodeState({
    url: window.location.pathname + window.location.search,
  }, verifier),
});

export const getLoginSalesforceConfig = (impostorId?: string): AuthConfig => ({
  redirectUri: getRedirectUri(),
  responseType: 'token id_token',
  clientId: Config.AUTH_SALESFORCE.SALESFORCE_CLIENT_ID,
  scope: 'openid',
  state: impostorId
    ? JSON.stringify({ user_id: impostorId, context: 'salesforce' })
    : JSON.stringify({ context: 'salesforce' }),
});

export const encodeState = (state: any, verifier: string): string =>
  btoa(JSON.stringify({ s: state, v: verifier }));

export const decodeState = <T extends any = any>(stateBase64: string, verifier?: string): T => {
  try {
    const stateJson = JSON.parse(atob(stateBase64));
    // hotfix when verifier is undefined - refresh the site without the params in the url
    if (verifier === undefined) {
      window.location.href = window.location.origin;
    }
    if (verifier && stateJson.v === verifier) {
      return stateJson.s;
    }
  } catch {
    throw new DecodeStateError();
  }
  throw new InvalidVerifierCodeError();
};

export const getRedirectUri = () => window.location.origin + '/';

export const isDefaultAuthorizationContext =
  (params: any): params is AuthParams =>
    !!(params && params.code && params.state);

export const isSalesForceAuthorizationContext =
  (params: any): params is AuthSalesforceParams =>
    !!(params.state && JSON.parse(params.state).context === 'salesforce');

export const getAuthContext = (params: AuthParams | AuthSalesforceParams | {}): AuthContext =>
  isDefaultAuthorizationContext(params)
    ? AuthContext.DEFAULT
    : isSalesForceAuthorizationContext(params)
      ? AuthContext.SALESFORCE
      : AuthContext.NONE;

export const getUrlAuthParams = ({ search, hash }: Location): AuthParams | AuthSalesforceParams | {} => ({
  ...getQueryParams<AuthParams>(search),
  ...getHashParams<AuthSalesforceParams>(hash),
});

export const logOut = () => redirectTo(`${Config.AUTH.AUTH_URL}/users/sign_out` + createQueryString({
  redirect_uri: getRedirectUri(),
}));

const toUrlEncoded = (obj: object) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && !isNil(obj[p])) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};
