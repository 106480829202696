import { getAssetsAvailability } from '+app/+customer/store/+customer.helper';
import { CountryFeatureName } from '+config/countryFlags';
import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { CaseReportButton } from '+shared/components/CaseReportButton';
import {
  ActionName,
  EventCategory,
  GoogleTagEventData,
  GoogleTagUrlHistory,
  InteractionEvents,
  useTracking,
} from '+shared/GoogleTagManager';
import { Battery } from '+shared/store/battery';
import { Contract } from '+shared/store/contract';
import { Customer, Site } from '+shared/store/customer';
import { getStatus } from '+shared/store/query/query.utils';
import { trim } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, Icofont, Icon, LinkButton, Select, Status, StatusType, useCountryFeature } from '@sonnen/shared-web';
import { EventCreator } from '@sonnen/shared-web/src/analytics/components/AnalyticsTracker';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { CustomerRouteParams, PATHS } from '../../../router';

import './CustomerHeader.component.scss';

interface Props {
  customer: Customer;
  contract: Contract;
  battery: Battery;
  title: string;
  routeParams: CustomerRouteParams;
  siteSelected: Site;
  sites: Site[];
  assetsAvailability: ReturnType<typeof getAssetsAvailability>;
  onGoBack: () => void;
  onSiteChange: (site: Site) => void;
  currentSite: Site;

  contractQueryStatus: ReturnType<typeof getStatus>;
  onCaseReportButtonClick: () => void;
}

const renderBreadcrumb = (onGoBack: Props['onGoBack']) => (
  <LinkButton
    onClick={onGoBack}
    icon={<Icon.Angle />}
  >
    {I18n.t(T.customerSingle.header.breadcrumb)}
  </LinkButton>
);

const renderTabNav = (
  routeParams: CustomerRouteParams,
  assetsAvailability: ReturnType<typeof getAssetsAvailability>,
  track: (eventCreator: EventCreator<GoogleTagEventData, GoogleTagUrlHistory>) => void,
) => {
  const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

  const createNavLinkProps = (navName: string) => ({
    border: false,
    type: 'desktop' as NavLinkType,
    faded: true,
    onClick: (
      event: React.SyntheticEvent<HTMLAnchorElement>,
    ) => track(InteractionEvents.linkClick({
      category: EventCategory.CUSTOMER_DETAILS,
      action: `${ActionName.NAV_LINK_CLICK} - ${navName}`,
      event,
    })),
  });

  return (
    <TabNav
      items={(
        <>
          <NavLink {...createNavLinkProps('dashboard')} action={PATHS.CUSTOMER(routeParams)}>
            <Icofont type={'dashboard'} className={'c-customer-header__nav-icon'} />
            {I18n.t(T.customerSingle.header.navigation.overview)}
          </NavLink>
          {assetsAvailability.hasBattery && (
            <NavLink {...createNavLinkProps('battery')} action={PATHS.CUSTOMER_BATTERY(routeParams)}>
              <Icofont type={'sonnenbatterie'} className={'c-customer-header__nav-icon'} />
              {I18n.t(T.customerSingle.header.navigation.batteryDetails)}
            </NavLink>
          )}
          {assetsAvailability.hasBattery && (
            <NavLink {...createNavLinkProps('analysis')} action={PATHS.CUSTOMER_ANALYSIS(routeParams)}>
              <Icofont type={'diagram'} className={'c-customer-header__nav-icon'} />
              {I18n.t(T.customerSingle.header.navigation.analysis)}
            </NavLink>
          )}
          {assetsAvailability.hasContract && isCustomerContractFeatureEnabled && (
            <NavLink {...createNavLinkProps('contract')} action={PATHS.CUSTOMER_CONTRACT(routeParams)}>
              <Icofont type={'contract'} className={'c-customer-header__nav-icon'} />
              {I18n.t(T.customerSingle.header.navigation.contract)}
            </NavLink>
          )}
          {assetsAvailability.hasCases && (
            <NavLink {...createNavLinkProps('cases')} action={PATHS.CUSTOMER_CASES(routeParams)}>
              <Icofont type={'warning'} className={'c-customer-header__nav-icon'} />
              {I18n.t(T.customerSingle.header.navigation.cases)}
            </NavLink>
          )}
        </>
      )}
    />
  );
};

const factorizeItem = (site: Site, index: number, selected: boolean) => selected
  ? trim`${site.city} ${site.street}`
  : trim`${site.street} ${site.city}`;

export const CustomerHeader: React.SFC<Props> = ({
  title,
  routeParams,
  sites,
  siteSelected,
  assetsAvailability,
  onGoBack,
  onSiteChange,
  contractQueryStatus,
  onCaseReportButtonClick,
}) => {
  const { track } = useTracking();

  return (
    <PageHeader
      className={'c-customer-header'}
      breadcrumb={renderBreadcrumb(onGoBack)}
      tabNav={renderTabNav(routeParams, assetsAvailability, track)}
      isExpanded={true}
    >
      <h1 className={'c-customer-header__title'} data-hj-suppress={true}>
        {title}
      </h1>
      <div className={'c-customer-header__address'} data-hj-suppress={true}>
        {sites.length === 1 ? (
          <span className={'c-customer-header__address-info'}>
            {factorizeItem(siteSelected, 0, true)}
          </span>
        ) : (
          <>
            <Status
              type={StatusType.WARNING}
              hasDot={false}
              label={I18n.t(T.customerSingle.header.sitesAvailable, { total: sites.length })}
            />
            <Select
              items={sites}
              itemsSelected={siteSelected}
              itemFactory={factorizeItem}
              onSelect={onSiteChange}
            />
          </>
        )}
      </div>
      <CaseReportButton
        isLoading={contractQueryStatus.pending}
        onClick={onCaseReportButtonClick}
        gtmActionName={ActionName.OPEN_MODAL_ON_CUSTOMER_PAGE}
      >
        <Button
          size={ButtonSize.SECONDARY}
          label={
            <>
              <Icon.OpenFolder className={'c-page-header__icon'} />
              {I18n.t(T.report.addCase)}
            </>
          }
        />
      </CaseReportButton>
    </PageHeader>
  );
};
