import { Document } from '+shared/store/setupTool/types';
import { ActionsUnion, createAction } from '+utils/index';

export enum DSO_REGISTRATION_ACTIONS {
  GENERATE_DOCUMENTS = '[+DsoRegistration] GENERATE_DOCUMENTS',
  GENERATE_DOCUMENTS_SUCCESS = '[+DsoRegistration] GENERATE_DOCUMENTS_SUCCESS',

  TRACK_DSO_REGISTRATION_STANDARD_WIRING = '[+DsoRegistration] TRACK_DSO_REGISTRATION_STANDARD_WIRING',
  TRACK_DSO_REGISTRATION_CUSTOM_WIRING = '[+DsoRegistration] TRACK_DSO_REGISTRATION_CUSTOM_WIRING',

  SET_GENERATED_DOCUMENTS = '[+DsoRegistration] SET_DSO_REGISTRATION_GENERATED_DOCUMENTS',
  SET_MODIFICATION_DATE = '[+DsoRegistration] SET_DSO_REGISTRATION_MODIFICATION_DATE',
}

export const DsoRegistrationActions = {
  generateDocuments: createAction(
    DSO_REGISTRATION_ACTIONS.GENERATE_DOCUMENTS,
  ),
  trackDsoRegistrationStandardWiring: createAction(
    DSO_REGISTRATION_ACTIONS.TRACK_DSO_REGISTRATION_STANDARD_WIRING,
  ),
  trackDsoRegistrationCustomWiring: createAction(
    DSO_REGISTRATION_ACTIONS.TRACK_DSO_REGISTRATION_CUSTOM_WIRING,
  ),
  setDsoRegistrationGeneratedDocuments: createAction(
    DSO_REGISTRATION_ACTIONS.SET_GENERATED_DOCUMENTS,
    (documents: Document[]) => ({ documents }),
  ),
  setDsoRegistrationModificationDate: createAction(
    DSO_REGISTRATION_ACTIONS.SET_MODIFICATION_DATE,
    (date: string) => ({ date }),
  ),
};

export type DsoRegistrationActions = ActionsUnion<typeof DsoRegistrationActions>;
