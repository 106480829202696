import { createReducer } from '../../../utils';
import { LAYOUT_ACTIONS, LayoutActions } from './layout.actions';
import { initialState, LayoutState } from './layout.state';

type Actions = LayoutActions;

export const reducer = createReducer<LayoutState, Actions>(
  (state = initialState, action): LayoutState => {
    switch (action.type) {
      case LAYOUT_ACTIONS.TOGGLE_NAVIGATION:
        return {
          ...state,
          isNavOpened: action.isActive,
        };
      case LAYOUT_ACTIONS.TOGGLE_MODAL:
        return {
          ...state,
          isModalOpen: action.isModalOpen,
          openModalId: action.openModalId,
        };
      case LAYOUT_ACTIONS.IS_LANGUAGE_LOADING:
        return {
          ...state,
          isLanguageLoading: action.isLoading,
        };
      default:
        return state;
    }
  });
