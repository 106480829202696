export const MINUTE_UNIX = 60;
export const HOUR_UNIX = 60 * MINUTE_UNIX;
export const DAY_UNIX = 24 * HOUR_UNIX;

export enum EnergyUnit {
  W = 'W',
  KW = 'kW',
  KWH = 'kWh',
  KWH_PER_KWP = 'kWh/kWp',
  WH = 'Wh',
}

export enum PeakPowerUnit {
  KWP = 'kWp',
  WP = 'Wp',
}

export enum CurrencyUnit {
  CURRENCY_EUR = 'EUR',
}

export enum OtherUnit {
  PERCENT = 'percent',
  DEGREE = 'degree',
  DATE = 'date',
  GEOMETRY = 'geometry',
  STRING = 'string',
  SQUARE_METER = 'm2',
  KM = 'km',
}

export const TOTAL_RESOURCE_COUNT_PER_PAGE = 10;
