import { Query } from '+shared/store/query';
import { VideoYoutube } from './types/youtube.interface';

export const GET_YOUTUBE_VIDEO_LIST_QUERY = 'getYoutubeVideoListQuery';

export interface YoutubeState {
  videoList: VideoYoutube[];
  [GET_YOUTUBE_VIDEO_LIST_QUERY]: Query<any>;
}

export const initialState: YoutubeState = {
  videoList: [],
  [GET_YOUTUBE_VIDEO_LIST_QUERY]: {},
};
