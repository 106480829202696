import { FileCategoryName } from '+setupTool/+form/store';
import {
  CouplingType,
  DsoRegistrationSetupDataStatusesInterface,
} from '+setupTool/+form/store/types';
import { SetupDataStepKey, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { RegistrationSubjectType, SetupToolStepsUpdatedAt } from '+setupTool/store/types';

export enum DocumentSource {
  INPUT = 'input',
  OUTPUT = 'output',
}

export enum AdditionalFeatures {
  DSO_REFERENCE_NUMBER_PV_FIELD = 'dsoReferenceNumberPvField',
  VPP_COMPLETION_NOTIFICATION = 'vppCompletionNotification',
  VPP_LABELS_FOR_FLAT_DIRECT = 'vppLabelsForFlatDirect',
  METER_CABINET_PREPARED_FIELD = 'meterCabinetPreparedField',
  FASTENING_TYPE_CONSUMPTION_FIELD = 'fasteningTypeConsumptionField',
  PRODUCTION_METER_FIELD = 'productionMeterField',
}

export interface Document {
  id: string;
  status: boolean;
  source: DocumentSource;
  upload: {
    id: string;
    url: string;
    category: FileCategoryName;
  };
  fileName: string;
  stepType: SubmissionStep;
}
export interface Dso {
  portalType: string;
  salesSolutionId: string;
  name: string;
  docSpringId: string;
  id: string;
}

export interface Installers {
  id: string;
  company: string;
  firstName: string;
  lastName: string;
  fullName: string;
  companyStreet: string;
  companyStreetNumber: string;
  zipCode: string;
  city: string;
  email: string;
  phone: string;
  certificationNumber: string;
  certificationAuthority: string;
}

export interface DsoRegistrationFormResponse {
  id: string;
  userId: string;
  customerId: string;
  documents: Document[];
  createdAt: string;
  updatedAt: string;
  fields: any;
  pvr: any;
  vpp: object;
  vppCapable: boolean;
  additionalFeatures: AdditionalFeatures[];
  vppAdditionalFeatures: AdditionalFeatures[] | null;
  stepsUpdatedAt: SetupToolStepsUpdatedAt;
  setupDataStatuses: DsoRegistrationSetupDataStatusesInterface;
  registrationSubject: RegistrationSubjectType;
}

export interface DsoRegistrationPatchSubmissionResponse {
  modifiedSetupDataStepKeys: SetupDataStepKey[];
  submission: DsoRegistrationFormResponse;
}

export interface DsoListResponse {
  dsos: Dso[];
}

export interface PVModuleVendorsResponse {
  id: string;
  name: string;
  type: string;
}

export interface PVModuleResponse {
  id: string;
  pvModuleType: string;
  vendorId: string;
  type: string;
  peakPowerWp: number;
}
export interface PVInverterVendorsResponse {
  id: string;
  name: string;
  type: string;
}

export interface PVInverterResponse {
  id: string;
  inverterType: string;
  vendorId: string;
  type: string;
}

export interface BatteryNames {
  name: string;
}

export interface BatteriesResponseAttributes {
  attributes: {
    id: string;
    name: string;
    nominalCapacitykWh: number;
    couplingType: CouplingType;
  };
}
