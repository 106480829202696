import { CaseListRouteQueryParams } from '+app/router';
import { QueryTypes } from '+shared/store/graphQL/types/graphQL.types';
import { SortOrder } from '+shared/store/graphQL/types/graphQL.types';
import { xorDecrypt } from '+utils/crypto.util';
import { camelCase, isUndefined, omitBy } from 'lodash/fp';

const RESULTS_PER_PAGE = 10;

// TODO
const caseQueryFields = [
  'id',
  'caseNumber',
  'createdDate',
  'lastModifiedDate',
  'description',
  'subject',
  'status',
  'recordType',
  'createdDate',
  {
    account: [
      'firstName',
      'lastName',
    ],
  },
];

export const defaultCaseQueryArguments = {
  first: RESULTS_PER_PAGE,
  order: {lastModifiedDate: SortOrder.DESC},
};

const calculateOffset = (page: number) => (page - 1) * RESULTS_PER_PAGE;

export const generateCaseQueryArguments = (
  routeParams: CaseListRouteQueryParams,
  userProfileCustomerNumber: string,
) => {
  const { order, status, customerId, page, search } = routeParams;
  const formatedOrder = order ? { order: {[camelCase(order)]: SortOrder.DESC }} : undefined;
  const formatedStatus = status ? { status: status.split(',') } : undefined;
  const formatedCustomerId = customerId ? { customer: {id: customerId} } : undefined;
  const offset = page ? { offset: calculateOffset(Number(page)) } : undefined;
  const formatedSearch = search ? { search: xorDecrypt(search, userProfileCustomerNumber) } : undefined;

  return omitBy(isUndefined, {
    ...defaultCaseQueryArguments,
    ...formatedOrder,
    ...formatedStatus,
    ...formatedCustomerId,
    ...formatedSearch,
    ...offset,
  });
};

export const generateCaseQueryParams = (routeParams: CaseListRouteQueryParams, userProfileCustomerNumber: string) => {
  const args = routeParams
    ? generateCaseQueryArguments(routeParams, userProfileCustomerNumber)
    : defaultCaseQueryArguments;

  return ({
    type: QueryTypes.CASES,
    args,
    fields: caseQueryFields,
  });
};
