import { isArray, isNil } from 'lodash/fp';

export class ExtendableError extends Error {
  constructor(
    public name: string,
    message: string,
  ) {
    super(message);
  }
}

export const renderErrorMessage =
  (errors: string | string[] | undefined) =>
  (renderComponent: (errorMessage: string, i?: number) => JSX.Element): JSX.Element | JSX.Element[] | null =>
    isArray(errors)
      ? errors.map(renderComponent)
      : !isNil(errors)
        ? renderComponent(errors)
        : null;
