import { setHintCategory, setHintColor } from '+app/+newsChannel/containers/NewsSection/News.helper';
import { DeleteButton } from '+shared/components/DeleteButton';
import { GenericNews, Language, ListItemNews, NewsCP } from '+shared/store/firebase/types/news.interface';
import { getNewsTimestamp } from '+utils/news.util';
import { Icon, LinkButton, NewsMessage, StatusDecorator, TableCell } from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';

import './NewsListTableItem.component.scss';

interface Props {
  item: ListItemNews<NewsCP>;
  deleteNews: (news: GenericNews) => void;
  openNewsEditMode: (news: GenericNews) => void;
}

export const CPNewsListTableItem: React.FC<Props> = ({ item, deleteNews, openNewsEditMode }) => {
  const sortedMessages = Object.keys(item.messages)
    .sort(key => key === Language.UK ? 0 : 1)
    .map(key => item.messages[key]);

  return (
    <>
      {item.isActive && (
        <div className={'c-news-list-table-item__status-decorator'}>
          <StatusDecorator type={setHintColor(item.levelValue)} />
        </div>
      )}
      <div className={'c-news-list-table-item'}>
        <TableCell className={'c-news-list-table-item__cell'}>
          {setHintCategory(item.levelValue)}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {item.isActive
            ? <span className={'c-news-list-table-item__active'}>Active</span>
            : <span className={'c-news-list-table-item__inactive'}>Inactive</span>}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {moment(getNewsTimestamp(item) * 1000).format('DD/MM/YY')}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            {sortedMessages.map(({ short }) => short).find(msg => !!msg) || ''}
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            <NewsMessage
              message={sortedMessages.map(({ long }) => long).find(msg => !!msg) || ''}
            />
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__icon-wrapper'}>
            <LinkButton
              onClick={() => openNewsEditMode(item)}
              className={'c-news-list-table-item__icon-with-text'}
            >
              <Icon.Pen className={'c-news-list-table-item__icon'}/>
              Edit
            </LinkButton>
          </div>
          <DeleteButton onDelete={() => deleteNews(item)}/>
        </TableCell>
      </div>
    </>
  );
};
