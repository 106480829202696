import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { createSelector } from 'reselect';
import { 
  ACCOUNT_PASSWORD_QUERY, 
  COMPANY_DOCS_UPLOAD_QUERY,
  COMPANY_LOGO_UPLOAD_QUERY, 
  GET_CERTIFICATES_QUERY,
  PROFILE_LOGO_UPLOAD_QUERY,
} from './+account.state';

export const getAccountPageState = (state: StoreState) => state.page.account;

export const getAccountPasswordQuery = createSelector(
  getAccountPageState,
  state => state[ACCOUNT_PASSWORD_QUERY],
);

export const getAccountPasswordQueryStatus = createSelector(
  getAccountPasswordQuery,
  query => getStatus(query),
);

export const getUploadCompanyLogoQuery = createSelector(
  getAccountPageState,
  state => state[COMPANY_LOGO_UPLOAD_QUERY],
);

export const getUploadCompanyLogoQueryStatus = createSelector(
  getUploadCompanyLogoQuery,
  query => getStatus(query),
);

export const getUploadProfileLogoQuery = createSelector(
  getAccountPageState,
  state => state[PROFILE_LOGO_UPLOAD_QUERY],
);

export const getUploadProfileLogoQueryStatus = createSelector(
  getUploadProfileLogoQuery,
  query => getStatus(query),
);

export const getUploadCompanyDocsQuery = createSelector(
  getAccountPageState,
  state => state[COMPANY_DOCS_UPLOAD_QUERY],
);

export const getUploadCompanyDocsQueryStatus = createSelector(
  getUploadCompanyDocsQuery,
  query => getStatus(query),
);

export const getCertificates = createSelector(
  getAccountPageState,
  state => state.certificates,
);

export const getCertificatesQuery = createSelector(
  getAccountPageState,
  state => state[GET_CERTIFICATES_QUERY],
);

export const getCertificatesQueryStatus = createSelector(
  getCertificatesQuery,
  query => getStatus(query),
);
