import { createReducer } from '../../../utils';
import { QueryActions, queryReducer } from '../query';
import { 
  initialState, 
  SYNC_QUERY, 
  SyncState,
} from './sync.state';

type Actions = QueryActions;

export const reducer = createReducer<SyncState, Actions>(
  (state = initialState, action): SyncState => {
    switch (action.type) {
      default:
        return queryReducer(state, action, [SYNC_QUERY]);
    }
  });
