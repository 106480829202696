import { getCustomerBatteryPageState } from '+customer/store/+customer.selectors';
import { BatteryType } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';
import { createSelector } from 'reselect';
import {
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_QUERY,
  PUT_BATTERY_NOTE_QUERY,
} from './+battery.state';

/**
 * BATTERY SELECTORS
 * ------------------------------
 */
export const getBattery = createSelector(
  getCustomerBatteryPageState,
  batteryPageState => batteryPageState.battery,
);

export const getBatteryQueryStatus = createSelector(
  getCustomerBatteryPageState,
  batteryPageState => getStatus(batteryPageState[GET_BATTERY_QUERY]),
);

export const getNoteSubmitQuery = createSelector(
  getCustomerBatteryPageState,
  state => state[PUT_BATTERY_NOTE_QUERY],
);

export const getNoteSubmitQueryStatus = createSelector(
  getNoteSubmitQuery,
  query => getStatus(query),
);

export const isEatonBattery = createSelector(
  getBattery,
  data => (data && data.controllerType) === BatteryType.EATON,
);

const heaterConnectionStatus = createSelector(
  getBattery,
  battery => battery?.heaterConnectionStatus,
);

export const isHeaterStatusConnected = createSelector(
  heaterConnectionStatus,
  heaterStatus => !!heaterStatus,
);

const backupDeviceType = createSelector(
  getBattery,
  battery => battery?.backupDeviceType,
);

export const isBackupDeviceTypeIsBackupBoxOrProtect = createSelector(
  backupDeviceType,
  type => type === 'backupbox' || type === 'protect',
);

export const backupPowerBuffer = createSelector(
  getBattery,
  battery => battery?.backupPowerBuffer,
);

export const getBatteryInstallationDate = createSelector(
  getBattery,
  battery => battery?.installationDate,
);

/**
 * BATTERY ELECTRIC UNITS SELECTORS
 * ------------------------------
 */
export const getBatteryElectricUnits = createSelector(
  getCustomerBatteryPageState,
  batteryPageState => batteryPageState.batteryElectricUnits,
);

export const getBatteryElectricUnitsQueryStatus = createSelector(
  getCustomerBatteryPageState,
  batteryPageState => getStatus(batteryPageState[GET_BATTERY_ELECTRIC_UNITS_QUERY]),
);
