import { firestore } from 'firebase';
import { Firebase } from './firebase.client';
import { ReadNewsPP } from './types/news.interface';

export const fetchReadNews = (
  userId: string, firebase: Firebase, callBackAction: (newsList: ReadNewsPP[]) => void) =>
  firebase.getReadNewsCollectionByUser(userId)
    .then((querySnapshot: firestore.QuerySnapshot) => {
      const readNewsList: ReadNewsPP[] = [];

      querySnapshot.forEach((doc: any) => {
        const singleReadNews: ReadNewsPP = doc.data();
        readNewsList.push(singleReadNews);
      });

      callBackAction(readNewsList);
    })
    .catch((error: Error) => console.log('Firebase error:', error));
