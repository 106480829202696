import { Account } from '+app/+account';
import { CaseList } from '+app/+case';
import { Contact } from '+app/+contact';
import { Customer } from '+app/+customer';
import { Dashboard } from '+app/+dashboard';
import { Drive } from '+app/+drive';
import { HelpPage } from '+app/+help';
import { Lead, SetupLead } from '+app/+lead';
import { NewsChannel } from '+app/+newsChannel';
import { NewsInbox } from '+app/+newsMain/+newsInbox';
import { Register, RegisterFailure, RegisterSuccess } from '+app/+register';
import { Download, Login, Logout, NotFound } from '+app/+static';
import { CountryFeatureName } from '+config/countryFlags';
import { FeatureName } from '+config/featureFlags';
import { CustomerList } from '+customer/+list';
import { FirebaseSubscribe } from '+shared/containers/FirebaseSubscribe';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { StoreState } from '+shared/store/store.interface';
import { useCountryFeature, useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { PUBLIC_ROUTES, ROUTES } from './routes';

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: isAuthenticated(state),
  isSetupPhaseFeatureEnabled: useCountryFeature(CountryFeatureName.SETUP_PHASE).isEnabled,
});

type Props =
  & ReturnType<typeof mapStateToProps>;

interface AuthenticatedRoutingProps {
  isSetupPhaseFeatureEnabled: boolean;
}

const AuthenticatedRouting: React.FC<AuthenticatedRoutingProps> = ({ isSetupPhaseFeatureEnabled }) => {
  const isRolloutEnabled = useFeature(FeatureName.ROLLOUT).isEnabled;

  const getSetupRoute = () => {
    const setupRoute = <Route path={[ROUTES.SETUP_LEADS[0]]} component={SetupLead} />;

    if (!isRolloutEnabled) {
      return setupRoute;
    }

    return isSetupPhaseFeatureEnabled ? setupRoute : null;
  };

  return (
    <>
      <FirebaseSubscribe />
      <Switch>
        <Route exact={true} path={ROUTES.ROOT}>
          <Redirect to={ROUTES.DASHBOARD[0]} />
        </Route>
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.NEWS_INBOX} component={NewsInbox} />
        <Route exact={true} path={ROUTES.CUSTOMERS} component={CustomerList} />
        <Route path={ROUTES.CUSTOMER} component={Customer} />
        <Route path={ROUTES.CASES} component={CaseList} />
        <Route path={[ROUTES.LEADS[0]]} component={Lead} />
        {getSetupRoute()}
        <Route path={ROUTES.HELP} component={HelpPage} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        <Route path={ROUTES.LOGOUT} component={Logout} />
        <Route path={ROUTES.CONTACTS} component={Contact} />
        <Route path={ROUTES.DRIVE} component={Drive} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

const PublicRouting: React.FC = () => (
  <Switch>
    <Route exact={true} path={PUBLIC_ROUTES.REGISTER} component={Register} />
    <Route path={PUBLIC_ROUTES.REGISTER_FAILURE} component={RegisterFailure} />
    <Route path={PUBLIC_ROUTES.REGISTER_SUCCESS} component={RegisterSuccess} />
    <Route path={PUBLIC_ROUTES.DOWNLOAD} component={Download} />
    <Route path={PUBLIC_ROUTES.LOGIN} component={Login} />
    <Route path={PUBLIC_ROUTES.NEWS_CHANNEL} component={NewsChannel} />
    <Route path={ROUTES.LOGOUT} component={Logout} />
    <Route component={NotFound} />
  </Switch>
);

const RoutingComponent: React.FC<Props> = ({ isAuthenticated, isSetupPhaseFeatureEnabled }) =>
  isAuthenticated
    ? <AuthenticatedRouting isSetupPhaseFeatureEnabled={isSetupPhaseFeatureEnabled} />
    : <PublicRouting />;

export const Routing = connect(mapStateToProps)(RoutingComponent);
