import { CaseCategory } from '+shared/store/case/types/createCase.interface';
import { UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export interface RequestPartnerAccessForm {
  companyName?: string;
  userName?: string;
  userLastName?: string;
  email?: string;
  roles?: UserRole[];
}

const setRequestAccessDesc = (
    companyName: string | undefined,
    userName: string | undefined,
    userLastName: string | undefined,
    email: string | undefined,
    roles: UserRole[] | undefined,
  ) =>
    `Company Name: ${companyName}; 
    User Name: ${userName};
    User Last Name: ${userLastName};
    User email: ${email};
    Roles: ${roles};`;

const requestAccessInitValues = {
  companyName: '',
  userName: '',
  userLastName: '',
  email: '',
  description: '',
  attachment: null,
  roles: [],
};

const requestAccessSchema = () => Yup.object({
  companyName: Yup.string()
    .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
  userName: Yup.string()
    .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
  userLastName: Yup.string()
    .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
  email: Yup.string().email()
    .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField)),
  roles: Yup.array()
    .required(I18n.t(T.companyAccount.requestAccess.alert.atLeastOneRole)),
});

const requestAccessTitle = 'Request for a new Partner Portal User';

const getRolesCollection = () => 
  Object.keys(UserRole).filter(role => role !== 'SUPER_USER').map(role => (
      {
        value: UserRole[role], 
        label: I18n.t(T.myAccount.personalDetails.rolesNames[UserRole[role]]),
      }
    ),
  );

export const RequestAccessFormHelper = {
  requestAccessInitValues,
  requestAccessSchema,
  requestAccessTitle,
  getRolesCollection,
  setRequestAccessDesc,
};
