import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ofType, processQuery } from '../../../utils';
import { GraphQLRepository } from '../graphQL/graphQL.repository';
import { CaseActions } from './case.actions';
import { generateCaseQueryParams } from './case.helper';
import { CaseRepository } from './case.repository';

type Action$ = ActionsObservable<CaseActions>;
type State$ = StateObservable<StoreState>;

export const createCase$ = (action$: Action$) => action$.pipe(
  ofType(CaseActions.createCase),
  mergeMap(({ queryKey, createCaseAttributes }) => of({}).pipe(
    processQuery(
      queryKey,
      () => CaseRepository.createCase(createCaseAttributes),
      // TODO: handle properly types here, why the response is not properly parsed?
      // should be mergedData
      { onSuccess: res => of(CaseActions.setCreatedCase(res!.element)) },
    ),
  )),
);

export const getCaseList$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CaseActions.getCaseList),
  mergeMap(({ queryKey, queryParams }) => of({})
  .pipe(
    map(() => generateCaseQueryParams(queryParams, getUserProfileCustomerNumber(state$.value))),
    processQuery(
      queryKey,
      (query) => GraphQLRepository.getCaseList(query),
      { onSuccess: res => {
          return of(CaseActions.setCaseList(res!.list, res!.totalCount));
        },
      },
    ),
  )),
);

export const epics = combineEpics<any>(
  getCaseList$,
  createCase$,
);
