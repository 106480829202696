import { getLead } from '+app/+lead/store/+lead.selectors';
import { FormErrorBanner, FormInputRadio, FormInputSelect } from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { WarningText } from '@sonnen/shared-web';
import { Button, ButtonSize, ButtonTheme, LinkButton } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import { getCloseLeadQueryStatus } from '../../store/+overview.selectors';
import {
  CloseLeadForm,
  CloseLeadIssueType,
  CloseLeadSchema,
  getCloseLeadIssuesCollection,
  initialValues,
  mapDropdownItems,
  mapReasonToTranslation,
} from './LeadOverviewCloseLeadForm.helper';

import './LeadOverviewCloseLeadForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  closeLeadQueryStatus: getCloseLeadQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  closeLead: LeadOverviewPageActions.closeLead,
});

interface LeadOverviewCloseLeadFormProps {
  onCancel: () => void;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & LeadOverviewCloseLeadFormProps
  ;

const LeadOverviewCloseLeadFormComponent: React.FC<Props> = ({
  actions,
  onCancel,
  lead,
  closeLeadQueryStatus,
}) => {
  const closeLeadIssuesCollection = getCloseLeadIssuesCollection();

  const onFieldValueChange = (form: FormikProps<CloseLeadForm>) =>
    (payload: FormInputSubscriptionPayload<CloseLeadForm>) => {
      if (payload.name === 'issueType' && form.values.issueType !== payload.value) {
        form.setFieldValue('reason', '');
        form.setFieldTouched('reason', false);
      }
    };

  const onSubmit = (values: CloseLeadForm) => {
    if (lead) {
      actions.closeLead(lead.id, values.reason, lead.status);
    }
  };

  const renderElement = (form: FormikProps<CloseLeadForm>, issue: CloseLeadIssueType) => {
    const isActive = form.values.issueType === issue.type;

    return (
      <div key={`issue-${issue.label}`}>
        <FormInputRadio
          form={form}
          label={issue.label}
          name={'issueType'}
          value={issue.type}
          className={classNames('c-overview-close-lead__radio', { 'is-active': isActive })}
        />
        {isActive &&
          <FormInputSelect
            form={form}
            collection={mapDropdownItems(issue.type)}
            mapper={key => mapReasonToTranslation(key)}
            placeholder={I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.placeholder)}
            name={'reason'}
            id={'lead-overview-close-lead'}
          />
        }
      </div>
    );
  };

  return (
    <>
      {<Formik
        initialValues={initialValues}
        validationSchema={CloseLeadSchema}
        onSubmit={onSubmit}
        render={(form) =>
          <form
            onSubmit={form.handleSubmit}
            className={'c-overview-close-lead'}
          >
            <p>{I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.pickReasonBelow)}</p>
            <FormFieldObserver<CloseLeadForm> onChange={onFieldValueChange(form)}>
              <div className={'c-overview-close-lead__radio-group'}>
                {closeLeadIssuesCollection.map(issue => renderElement(form, issue))}
              </div>
            </FormFieldObserver>
            <FormErrorBanner
              isVisible={closeLeadQueryStatus.error}
              error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
            />
            <WarningText
              text={I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.warning)}
            />
            <div className={'c-overview-close-lead__line'} />
            <div className={'c-overview-close-lead__buttons'}>
              <LinkButton
                onClick={onCancel}
                className={'c-overview-close-lead__button-cancel'}
              >
                {I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.button.cancel)}
              </LinkButton>
              <Button
                label={I18n.t(T.lead.overview._salessolution_.leadManager.closeLead.button.submit)}
                theme={ButtonTheme.PRIMARY}
                size={ButtonSize.SECONDARY}
                type={'submit'}
                className={'c-overview-close-lead__button-submit'}
              />
            </div>
          </form>
        }
      />}
    </>
  );
};

export const LeadOverviewCloseLeadForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadOverviewCloseLeadFormComponent);
