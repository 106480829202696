import { ActionsUnion, createAction } from '+app/utils/redux';
import { ListItemNews, News, ReadNewsPP, Video, VideoFirebase } from './types';

export enum FIREBASE_ACTIONS {
  SET_NEWS_LIST = '[Firebase] SET_NEWS_LIST',
  SET_READ_NEWS_LIST = '[Firebase] SET_READ_NEWS_LIST',
  SET_NOTIFICATIONS_LIST = '[Firebase] SET_NOTIFICATIONS_LIST',
  GET_COMBINED_FB_AND_YT_VIDEO_LIST = '[Firebase] GET_COMBINED_FB_AND_YT_VIDEO_LIST',
  SET_COMBINED_FB_AND_YT_VIDEO_LIST = '[Firebase] SET_COMBINED_FB_AND_YT__VIDEO_LIST',
  ADD_VIDEO = '[Firebase] ADD_VIDEO',
  REMOVE_VIDEO = '[Firebase] REMOVE_VIDEO',
  MARK_NEWS_AS_READ = '[Firebase] MARK_NEWS_AS_READ',
  MARK_NEWS_AS_READ_SUCCESS = '[Firebase] MARK_NEWS_AS_READ_SUCCESS',
}

export const FirebaseActions = {
  setNewsList: createAction(
    FIREBASE_ACTIONS.SET_NEWS_LIST,
    (newsList: ListItemNews[]) => ({ newsList }),
  ),
  setReadNewsList: createAction(
    FIREBASE_ACTIONS.SET_READ_NEWS_LIST,
    (readNewsList: ReadNewsPP[]) => ({ readNewsList }),
  ),
  setNotificationsList: createAction(
    FIREBASE_ACTIONS.SET_NOTIFICATIONS_LIST,
    (notificationsList: Array<ListItemNews<News>>) => ({ notificationsList }),
  ),
  getCombinedVideoList: createAction(
    FIREBASE_ACTIONS.GET_COMBINED_FB_AND_YT_VIDEO_LIST,
    (videoListFirebase: VideoFirebase[]) => ({ videoListFirebase }),
  ),
  setCombinedVideoList: createAction(
    FIREBASE_ACTIONS.SET_COMBINED_FB_AND_YT_VIDEO_LIST,
    (videoList: Video[]) => ({ videoList }),
  ),
  addVideo: createAction(
    FIREBASE_ACTIONS.ADD_VIDEO,
    (video: VideoFirebase) => ({ video }),
  ),
  removeVideo: createAction(
    FIREBASE_ACTIONS.REMOVE_VIDEO,
    (videoId: string) => ({ videoId }),
  ),
  markNewsAsRead: createAction(
    FIREBASE_ACTIONS.MARK_NEWS_AS_READ,
    (newsId: string, userId: string) => ({ newsId, userId }),
  ),
  markNewsAsReadSuccess: createAction(
    FIREBASE_ACTIONS.MARK_NEWS_AS_READ_SUCCESS,
  ),
};

export type FirebaseActions = ActionsUnion<typeof FirebaseActions>;
