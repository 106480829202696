import { CountryFeatureName } from '+config/countryFlags';
import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CaseReportSite.component.scss';

interface Props {
  name: string;
  address: string | undefined;
  batterySerialNumber: string | undefined;
  contractNumber: string | undefined;
  button: React.ReactNode | undefined;
}

export const CaseReportSite: React.FC<Props> = ({
  name,
  address = '',
  batterySerialNumber = '-',
  contractNumber = '-',
  button,
}) => {
  const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

  return (
    <div className={'c-case-report-site'}>
      <div className={'c-case-report-site__info-wrapper'}>
        <div className={'c-case-report-site__info'}>
          {I18n.t(T.report.site.name.label)}:
          <span className={'c-case-report-site__value'} data-hj-suppress={true}> {`${name}, ${address}`}</span>
        </div>
        <div className={'c-case-report-site__info'}>
          {I18n.t(T.report.site.battery.label)}:
          <span className={'c-case-report-site__value'}> {batterySerialNumber}</span>
        </div>
        {isCustomerContractFeatureEnabled && (
          <div className={'c-case-report-site__info'}>
            {I18n.t(T.report.site.contract.label)}:
            <span className={'c-case-report-site__value'}> {contractNumber}</span>
          </div>
        )}
      </div>
      {button && <div className={'c-case-report-site__button-wrapper'}>{button}</div>}
    </div>
  );
};
