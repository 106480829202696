import { IMPACT_ANALYSIS_ACTIONS } from '+lead/+impactAnalysis/store/+impactAnalysis.actions';
import {
  DSO_REGISTRATION_ACTIONS,
} from '+setupTool/+dsoRegistration/store/+dsoRegistration.actions';
import { DSO_REGISTRATION_FORM_ACTIONS } from '+setupTool/+form/store';
import { SETUP_TOOL_ACTIONS } from '+setupTool/store/+setupTool.actions';
import { getAnalyticsLastAction } from '+shared/store/analytics/analytics.selectors';
import { QUERY_ACTIONS } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { connect } from 'react-redux';
import { reduxTrackerFactory } from '../GoogleTagManager.helper';
import {
  createImpactAnalysisToTrackingEvent,
  customWiringToTrackingEvent,
  setupDataCompletedTrackingEvent,
  setupToolsErrorEvent,
  standardWiringToTrackingEvent,
  stateSymbol,
  successQueryToTrackingEvent,
} from './ReduxActionTracker.helper';

const mapStateToProps = (state: StoreState) => {
  const lastAction = getAnalyticsLastAction(state);

  return {
    lastAction: lastAction ? {
      ...lastAction,
      [stateSymbol]: state,
    } : null,
  };
};

const {
  ReduxActionTracker: ReduxActionTrackerComponent,
  analyticsReducer,
} = reduxTrackerFactory(s => s
  .register(QUERY_ACTIONS.SUCCESS, successQueryToTrackingEvent)
  .register(DSO_REGISTRATION_ACTIONS.TRACK_DSO_REGISTRATION_STANDARD_WIRING, standardWiringToTrackingEvent)
  .register(DSO_REGISTRATION_ACTIONS.TRACK_DSO_REGISTRATION_CUSTOM_WIRING, customWiringToTrackingEvent)
  .register(DSO_REGISTRATION_FORM_ACTIONS.TRACK_SETUP_DATA_COMPLETED, setupDataCompletedTrackingEvent)
  .register(SETUP_TOOL_ACTIONS.APPEND_ERROR, setupToolsErrorEvent)
  .register(IMPACT_ANALYSIS_ACTIONS.CREATE_IMPACT_ANALYSIS, createImpactAnalysisToTrackingEvent));

export const ReduxActionTracker = connect(mapStateToProps)(ReduxActionTrackerComponent);
export { analyticsReducer };
