import { FormikProps } from 'formik';
import { get } from 'lodash/fp';

export interface FieldValidation {
  error: string;
  hasError: boolean;
}

export function validateField <T>({ name, form }: { name: string, form: FormikProps<T> }): FieldValidation {
  const error = get(name)(form.errors);
  const isTouched = get(name)(form.touched);
  const hasError = !!error && isTouched;

  return {
    error,
    hasError,
  };
}

/**
 * Validates form on next tick of event-loop (async with minimum delay).
 * Required by Formik.
 * @param form Formik form
 */
export const validateForm = (form: FormikProps<any>) =>
  setTimeout(() => form.validateForm(), 0);

export const maxAttachmentSize = {
  value: 4048576,
  label: '4MB',
};

export const ACCEPT_IMAGES_AND_PDF = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
