import { CASE_ACTIONS, CaseActions } from '+shared/store/case/case.actions';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux';
import { defaultTo } from 'lodash/fp';
import { CASE_COLLECTION_GET_QUERY, CaseState, CREATE_CASE_QUERY, initialState } from './case.state';

export const reducer = createReducer<CaseState, CaseActions>(
  (state = initialState, action): CaseState => {
    switch (action.type) {
      case CASE_ACTIONS.SET_CASE_LIST:
        return {
          ...state,
          collection: action.collection,
          totalCount: defaultTo(state.totalCount)(action.total),
        };
      case CASE_ACTIONS.SET_CREATED_CASE:
        return {
          ...state,
          createdCase: action.createCaseAttributes,
        };
      case CASE_ACTIONS.CLEAR_CREATED_CASE:
        return {
          ...state,
          createdCase: undefined,
          [CREATE_CASE_QUERY]: {},
        };
      case CASE_ACTIONS.SET_CASE_REPORT_ACTIVE_SITE:
        return {
          ...state,
          activeSite: action.activeSite,
        };
      default:
        return queryReducer(state, action, [
          CREATE_CASE_QUERY,
          CASE_COLLECTION_GET_QUERY,
        ]);
    }
  },
);
