import classNames from 'classnames';
import * as React from 'react';

import './FooterSocial.component.scss';

const SOCIAL_ICON = {
  fb: 'icofont-facebook',
  tw: 'icofont-twitter',
  yt: 'icofont-youtube',
};

interface Props {
  className?: ClassValue;
  icon: keyof typeof SOCIAL_ICON;
  link: string;
}

export const FooterSocial: React.SFC<Props> = ({ icon, link, className }) => (
  <a
    className={classNames(
      'c-footer-social',
      `c-footer-social--${icon}`,
      className,
    )}
    href={link}
    target={'_blank'}
    rel={'noopener noreferrer'}
  >
    <i className={SOCIAL_ICON[icon]} />
  </a>
);
