import { ListTableHeaderItem } from '+shared/components';
import { Portal } from '+shared/store/firebase/types/news.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getTableHeaders = (newsPortal: Portal): ListTableHeaderItem[] => {
  if (newsPortal === Portal.CP) {
    return [
      { label: I18n.t(T.news.channel.table.headers.newsType) },
      { label: I18n.t(T.news.channel.table.headers.isActive) },
      { label: I18n.t(T.news.channel.table.headers.expiryDate) },
      { label: I18n.t(T.news.channel.table.headers.title) },
      { label: I18n.t(T.news.channel.table.headers.message) },
      { label: I18n.t(T.news.channel.table.headers.actions)},
    ];
  }
  return [
    { label: I18n.t(T.news.channel.table.headers.title) },
    { label: I18n.t(T.news.channel.table.headers.message) },
    { label: I18n.t(T.news.channel.table.headers.roles) },
    { label: I18n.t(T.news.channel.table.headers.dateCreated) },
    { label: I18n.t(T.news.channel.table.headers.expiryDate) },
    { label: I18n.t(T.news.channel.table.headers.notificationExpiryDate) },
    { label: I18n.t(T.news.channel.table.headers.actions)},
  ];
};
