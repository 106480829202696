import { Contract, ContractData } from '+shared/store/contract';
import { Query } from '+shared/store/query';
import { PersistedState } from 'redux-persist';

export const GET_CONTRACT_QUERY = 'getContractQuery';

export interface ContractPageState extends PersistedState {
  contract?: Contract;
  [GET_CONTRACT_QUERY]: Query<ContractData>;
}

export const initialState: ContractPageState = {
  contract: undefined,
  [GET_CONTRACT_QUERY]: {},
};
