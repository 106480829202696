import { LeadOffer, LeadProductType, OfferProductStatus } from '+shared/store/lead/types';
import { isEmpty } from 'lodash';

export const getFlatProduct = (offer: LeadOffer) => offer.products.find(product =>
  [LeadProductType.FLAT_X, LeadProductType.FLAT_DIRECT].includes(product.productType),
);

export const getAllFlatOffers = (offers: LeadOffer[]) => offers.filter(offer => getFlatProduct(offer));

export const getHardwareProduct = (offer: LeadOffer) => offer.products.find(product =>
  product.productType === LeadProductType.BATTERY,
);

export const getProductForStatusChange = (offer: LeadOffer) => {
  const flatProduct = getFlatProduct(offer);

  if (!flatProduct) {
    return getHardwareProduct(offer);
  }

  return flatProduct;
};

export const getOfferProductStatus = (offer: LeadOffer, productType: LeadProductType) =>
  offer.products.find(product => product.productType === productType)?.status;

export const isHardwareStatusConfirmed = (offer: LeadOffer): boolean => getHardwareProduct(offer)?.status === OfferProductStatus.CONFIRMED;

export const isFlatOnlyOffer = (offer: LeadOffer): boolean => !!getFlatProduct(offer) && !getHardwareProduct(offer);

export const isAnyOfferFlatOnly = (offers: LeadOffer[]): boolean => !isEmpty(offers.filter(isFlatOnlyOffer));
