import { Form, FormErrorBanner, FormInput } from '+shared/components';
import { EventCategory, FormName, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ConfigurationPageActions } from '../../store';
import { getConfigurationProposal, getSetConfigurationProposalQueryStatus } from '../../store/+configuration.selectors';
import { ConfigurationAutarkySchema } from '../../store/schemas/configurationAutarkyForm.schema';
import { formFields } from './LeadConfigurationAutarkyForm.helper';

import './LeadConfigurationAutarkyForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  getSetConfigurationProposalQueryStatus: getSetConfigurationProposalQueryStatus(state),
  configProposal: getConfigurationProposal(state),
});

const mapDispatchToProps = mapActions({
  updateAutarky: ConfigurationPageActions.updateAutarky,
});

interface ComponentProps {
  expectedAutarky: React.ReactText;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const LeadConfigurationAutarkyFormComponent: React.FC<Props> = ({
  actions,
  getSetConfigurationProposalQueryStatus,
  expectedAutarky,
  configProposal,
}) => {
  const formRef = React.useRef<FormikProps<{ autarky: React.ReactText }>>({} as any);
  const { track } = useTracking();

  const estimatedAutarkyUpperLimit = configProposal && configProposal.powerPlant.estimatedAutarkyUpperLimit.value;

  React.useEffect(() => {
    formRef.current.setFieldTouched(formFields.AUTARKY);
  }, []);

  const onSubmit = (values: any) => {
    actions.updateAutarky(parseFloat(values[formFields.AUTARKY]));
  };

  const onSubmitButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    track(InteractionEvents.buttonClick({
      category: EventCategory.SALES_CONFIGURATOR,
      action: 'Save new autarky',
      event,
    }));
  };

  const gtmErrorProps = {
    category: EventCategory.SALES_CONFIGURATOR,
    formName: FormName.SALES_CONFIGURATOR,
    queryStatus: getSetConfigurationProposalQueryStatus,
  };

  return (
    <Formik
      initialValues={{ [formFields.AUTARKY]: expectedAutarky }}
      validateOnChange={true}
      validationSchema={ConfigurationAutarkySchema(estimatedAutarkyUpperLimit!)}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={form => (
        <Form className={classNames('c-lead-configuration-autarky-form', {
          'c-lead-configuration-autarky-form--error': form.errors.autarky,
        })}>
          <div className={'c-lead-configuration-autarky-form__inputs'}>
            <FormInput
              className={'c-lead-configuration-autarky-form__input'}
              form={form}
              label={I18n.t(T.lead.configuration._salessolution_.configurations.autarkyLabel)}
              name={formFields.AUTARKY}
              type={'number'}
              unit={'%'}
              hasNoGap={true}
            />
            <div className={classNames('c-lead-configuration-autarky-form__button', {
              'c-lead-configuration-autarky-form__button--error': !isEmpty(form.errors),
            })}>
              <Button
                label={I18n.t(T.lead.configuration._salessolution_.configurations.saveNewAutarky)}
                theme={ButtonTheme.OUTLINE}
                size={ButtonSize.SECONDARY}
                type={'submit'}
                isLoading={getSetConfigurationProposalQueryStatus.pending}
                isDisabled={form.dirty && !form.isValid}
                onClick={onSubmitButtonClick}
                isBlock={true}
              />
            </div>
          </div>

          <FormErrorBanner
            isVisible={getSetConfigurationProposalQueryStatus.error}
            error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
            gtm={gtmErrorProps}
          />
        </Form>
      )}
    />
  );
};

export const LeadConfigurationAutarkyForm =
  connect(mapStateToProps, mapDispatchToProps)(LeadConfigurationAutarkyFormComponent);
