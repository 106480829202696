import { getBattery, getSite } from '+app/+customer/store/+customer.helper';
import { CustomerActions } from '+customer/store';
import { Battery, BatteryActions } from '+shared/store/battery';
import { GET_SITE_LIVE_STATE_QUERY, SiteActions } from '+shared/store/site';
import { StoreState } from '+shared/store/store.interface';
import { defaultTo, flow } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { mapPathToParams, mapToState, ofType } from '../../../utils';
import { getSelectedCustomer } from '../../store/+customer.selectors';
import {
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  GET_BATTERY_QUERY,
} from './+battery.state';

type Action$ = ActionsObservable<RouterActions>;
type State$ = StateObservable<StoreState>;

export const getBatteryDetails$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer),
  mapToState(state$),
  mergeMap(state => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER[0], ROUTES.CUSTOMER_BATTERY[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      map(() => getSelectedCustomer(state)),
      mergeMap(customer => of(customer).pipe(
        filter(() => !!customer),
        map(flow(
          getSite(siteId),
          getBattery(),
          defaultTo({} as Battery),
        )),
        map((battery) => ({
          battery,
          siteId,
        })),
      )),
    )),
  )),
  mergeMap(({ battery, siteId }) => merge(
    of(BatteryActions.getBattery({ queryKey: GET_BATTERY_QUERY, batteryId: battery.id })),
    of(SiteActions.getSiteLiveState({ queryKey: GET_SITE_LIVE_STATE_QUERY, siteId })),
    of(BatteryActions.getBatteryElectricUnits({ 
      queryKey: GET_BATTERY_ELECTRIC_UNITS_QUERY,
      batteryId: battery.id,
    })),
  )),
);

export const epics = combineEpics(
  getBatteryDetails$,
);
