export enum ChargerCar {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum ChargerStatus {
  AVAILABLE = 'AVAILABLE',
  CHARGING = 'CHARGING',
  FAULTED = 'FAULTED',
  PREPARING = 'PREPARING',
  OFFLINE = 'OFFLINE', 
}

export const onlineStatuses = [
  ChargerStatus.AVAILABLE,
  ChargerStatus.CHARGING,
  ChargerStatus.PREPARING,
];
