import { FeatureName } from '+config';
import { Widget, WidgetWarning } from '+shared/components';
import { LiveStateRefresher } from '+shared/containers/LiveStateRefresher';
import { Battery } from '+shared/store/battery';
import { hasBatteryBackupDevice } from '+shared/store/battery/battery.helpers';
import { getBatteryWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { getStatus } from '+shared/store/query/query.utils';
import { SiteLiveState } from '+shared/store/site/types';
import { T } from '@sonnen/shared-i18n/service';
import { Feature } from '@sonnen/shared-web';
import { CardNotification, Icofont, LinkButton, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { BatteryLiveCardBody } from '../BatteryLiveCardBody';

import './BatteryLiveCard.component.scss';

interface Props {
  battery: Battery;
  siteLiveState: SiteLiveState;
  queryStatus: ReturnType<typeof getStatus>;
}
const renderNotification = (
  battery: Battery, siteLiveState: SiteLiveState,
): React.ReactNode => {
  const warnings = getBatteryWarnings({ battery, siteLiveState });

  return (
    warnings.batteryOffline
      ? (
        <CardNotification
          type={'warning'}
          icon={<Icofont type={'offline'} />}
        >
          {warnings.batteryOffline.message}
        </CardNotification>
      ) : (
        <CardNotification
          type={'success'}
          icon={<Icofont type={'tick'} />}
        >
          {I18n.t(T.customerSingle.batteryDetails.batteryLive.notifications.online)}
        </CardNotification>
      )
  );
};

export const BatteryLiveCard: React.FC<Props> = ({
  battery,
  siteLiveState,
  queryStatus,
}) => {
  const hasBackupDevice = hasBatteryBackupDevice(battery);

  return(
    <Widget
      className={'c-battery-live-card'}
      icon={<Icofont type={'sonnenbatterie'} />}
      heading={I18n.t(T.customerSingle.batteryDetails.batteryLive.headline)}
      notification={renderNotification(battery, siteLiveState)}
      customAction={
        <div className={'c-battery-live-card__actions'}>
          {/* @TODO remove feature flat when backup modal will be implemented */}
          <Feature name={FeatureName.EDIT_BACKUP_BUFFER}>
            {hasBackupDevice && (
              <LinkButton>
                {I18n.t(T.general.basicActions.edit)}
              </LinkButton>
            )}
          </Feature>
          <LiveStateRefresher/>
        </div>
      }
    >
      {queryStatus.pending && (
        <Loader className={'c-battery-live-card__loader'} />
      )}
      {!queryStatus.error && battery && (
        <BatteryLiveCardBody
          battery={battery}
          siteLiveState={siteLiveState}
          hasBackupDevice={hasBackupDevice}
        />
      )}
      {queryStatus.error && (
        <WidgetWarning
          type={'warning'}
          icon={'warning'}
        >
          {I18n.t(T.customerSingle.overview.battery.error)}
        </WidgetWarning>
      )}
    </Widget>
  );
};
