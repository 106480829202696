const clone = (collection: any) => Array.isArray(collection)
  ? collection.slice(0)
  : Object.assign({}, collection);

type IdArray = Array<{ id: any }>;

// CollectionKey - either id property of object in array or object's key
type CK<R> = R extends IdArray ? R[0]['id'] : keyof R;
// CollectionElement - child of collection selected either from array or object
// If no key has been passed, use the whole passed collection as value
type CE<R, K> = K extends undefined
  ? R
  : R extends IdArray ? R[0] : (K extends keyof R ? R[K] : never);

export function updateCollection<T extends V10, R,
  K1 extends CK<R>,
  K2 extends CK<V1> | undefined = undefined,
  K3 extends CK<V2> | undefined = undefined,
  K4 extends CK<V3> | undefined = undefined,
  K5 extends CK<V4> | undefined = undefined,
  K6 extends CK<V5> | undefined = undefined,
  K7 extends CK<V6> | undefined = undefined,
  K8 extends CK<V7> | undefined = undefined,
  K9 extends CK<V8> | undefined = undefined,
  K10 extends CK<V9> | undefined = undefined,
  V1 = CE<R, K1>,
  V2 = CE<V1, K2>,
  V3 = CE<V2, K3>,
  V4 = CE<V3, K4>,
  V5 = CE<V4, K5>,
  V6 = CE<V5, K6>,
  V7 = CE<V6, K7>,
  V8 = CE<V7, K8>,
  V9 = CE<V8, K9>,
  V10 = CE<V9, K10>>(
  collection: R,
  key: [K1, K2?, K3?, K4?, K5?, K6?, K7?, K8?, K9?, K10?],
  element: T,
): R {
  const parts = key;
  const newCollection = clone(collection);
  let c = newCollection;
  for (let i = 0; i < parts.length - 1; i++) {
    const part = parts[i];
    const index = Array.isArray(c) ? c.findIndex(e => e.id === part) : part;
    c[index] = clone(c[index]);
    c = c[index];
  }
  c[parts[parts.length - 1]] = element;

  return newCollection;
}
