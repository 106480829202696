import { createSelector } from 'reselect';
import { getStatus } from '../query/query.utils';
import { StoreState } from '../store.interface';
import { CASE_COLLECTION_GET_QUERY, CREATE_CASE_QUERY } from './case.state';

export const getCaseState = (state: StoreState) => state.shared.case;

export const getCreateCaseQueryStatus = createSelector(
  getCaseState,
  state => getStatus(state[CREATE_CASE_QUERY]),
);

const getCreatedCase = createSelector(
  getCaseState,
  state => state.createdCase,
);

export const getCreatedCaseNumber = createSelector(
  getCreatedCase,
  state => state?.caseNumber,
);

export const getCaseSubmitQueryStatus = createSelector(
  getCaseState,
  state => getStatus(state[CREATE_CASE_QUERY]),
);

export const getCaseActiveSite = createSelector(
  getCaseState,
  state => state.activeSite, 
);

export const getCaseCollection = createSelector(
  getCaseState,
  state => state.collection,
);

export const getCaseCollectionTotalCount = createSelector(
  getCaseState,
  state => state.totalCount,
);

export const getCaseCollectionQuery = createSelector(
  getCaseState,
  state => state[CASE_COLLECTION_GET_QUERY],
);

export const getCaseCollectionQueryStatus = createSelector(
  getCaseCollectionQuery,
  query => getStatus(query),
);
