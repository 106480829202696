import { CurrencyUnit } from '+app/App.constants';
import { CostsFormVersion } from '+lead/+impactAnalysis/store/+impactAnalysis.interface';
import { PriceFormatter } from '+lead/+impactAnalysis/utils/formatters/price';
import {
  LeadImpactAnalysisRecommendationResponse,
} from '+shared/store/lead/types/leadImpactAnalysisRecommendationResponse.interface';
import { NON_BREAKING_SPACE } from '+utils/string.util';
import * as React from 'react';
import { Amortization, PageDetailedCalculation20YearsData } from './ResultsCalculation20Years.types';

export const isAmortizationOver20Years = (amortizationTimeFrame?: Amortization) =>
  !amortizationTimeFrame || amortizationTimeFrame.years > 19;

export const isAmortizationLessThan1Year = (amortizationTimeFrame?: Amortization) =>
  amortizationTimeFrame &&
  amortizationTimeFrame.years === 0 &&
  amortizationTimeFrame.months === 0;

export const getSignByValue = (value: number | undefined) => {
  if (!value) {
    return undefined;
  }
  if (value > 0) {
    return '+';
  }
  return '-';
};

export const mapToPageData = (
  data: LeadImpactAnalysisRecommendationResponse,
  version?: CostsFormVersion,
): PageDetailedCalculation20YearsData => {
  const isDetailedVersion = version === CostsFormVersion.DETAILED;

  return {
    hardwareCostsAfter20Years: {
      cumulative: data.hardwareCostsAfter20Years.cumulative.value,
      investmentCosts: {
        cumulative: data.hardwareCostsAfter20Years.investmentCosts.cumulative.value,
        acquisitionCostsPVSAndBattery:
          isDetailedVersion && data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPvsAndBattery?.value
          || undefined,
        installationCostsPVSAndBattery:
          isDetailedVersion && data.hardwareCostsAfter20Years.investmentCosts.installationCostsPvsAndBattery?.value
          || undefined,
        otherOneOffCosts:
          isDetailedVersion && data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts
            ? hideIfZero(data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts.value)
            : undefined,
      },
      operatingCosts: {
        cumulative: data.hardwareCostsAfter20Years.operatingCosts.cumulative.value,
        maintenanceAndReplacementCosts:
          isDetailedVersion && data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts?.value
          || undefined,
        otherRunningCosts:
          isDetailedVersion && data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts
            ? hideIfZero(data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts.value)
            : undefined,
      },
      allowableTaxation: data.hardwareCostsAfter20Years.deductibleVatTotal?.value
      || undefined,
    },
    sonnenFlatBenefitsAfter20Years: {
      cumulative: data.sonnenFlatCostsAndBenefitsAfter20Years.cumulative.value,
      monthlyPrepaymentExcessEnergy: data.sonnenFlatCostsAndBenefitsAfter20Years.monthlyPrepayment.value,
      cashbackSonnenFlat: data.sonnenFlatCostsAndBenefitsAfter20Years.cashbackSonnenFlat.value,
      vppBonus: data.sonnenFlatCostsAndBenefitsAfter20Years.vppBonus.value,
    },
    powerBillSavings: {
      cumulative: data.powerBillSavings.value,
    },
    savingsAfter20Years: data.savingsOver20Years.value,
    cumulativeSonnenFlatVatToBePaid: data.cumulativeSonnenFlatVatToBePaid.value,
  };
};

const Value: React.FC<{
  value?: number;
  currency?: CurrencyUnit | 'ct';
  sign?: '+' | '-';
  textComponent: React.FC<{ children: React.ReactNode, bold?: boolean }>;
  bold?: boolean;
}> = ({
  value,
  sign,
  currency = CurrencyUnit.CURRENCY_EUR,
  textComponent: TextComponent,
  bold = false,
}) => (value === 0 || value) ? (
  <div className="c-impact-analysis-results-value-wrapper">
    <TextComponent bold={bold}>
      {sign && value !== 0 ? `${sign}${NON_BREAKING_SPACE}` : ''}<PriceFormatter
        value={value}
        currency={currency}
        decimals={0}
      />
    </TextComponent>
  </div>
) : <span />;

export const SubHeading: React.FC<{
  text: string;
  value?: number;
  currency?: CurrencyUnit;
  sign?: '+' | '-';
}> = ({
  text,
  value,
  currency = CurrencyUnit.CURRENCY_EUR,
  sign = '+',
}) => (
  <div className="c-impact-analysis-results-sub-heading-row">
    <IndentedBoundedText>{text}</IndentedBoundedText>
    <Value
      value={value}
      currency={currency}
      sign={sign}
      textComponent={IndentedText}
    />
  </div>
);

export const HeadingCostRow: React.FC<{
  text: string;
  value?: number;
  currency?: CurrencyUnit;
  sign?: '+' | '-' | undefined;
  boldHeading?: boolean;
  boldValue?: boolean;
}> = ({
  text,
  value,
  currency = CurrencyUnit.CURRENCY_EUR,
  sign = '+',
  boldHeading = false,
  boldValue = true,
}) => (
  <div className="c-impact-analysis-results-calculation-description-heading">
    <HeadingText bold={boldHeading}>{text}</HeadingText>
    <Value
      value={value}
      bold={boldValue}
      currency={currency}
      sign={sign}
      textComponent={HeadingText}
    />
  </div>
);

export const DetailedCostRow: React.FC<{
  text: string;
  value?: number;
  currency?: CurrencyUnit;
  sign?: '+' | '-';
}> = ({
  text,
  value,
  currency = CurrencyUnit.CURRENCY_EUR,
  sign,
}) => (
  <div className="c-impact-analysis-results-inner-row">
    <IndentedBoundedText>{text}</IndentedBoundedText>
    <Value
      value={value}
      currency={currency}
      sign={sign}
      textComponent={IndentedText}
    />
  </div>
);

export const HeadingText: React.FC<{ children: React.ReactNode, bold?: boolean }> = ({ children, bold }) => (
  <div className={bold ? 'c-impact-analysis-results-calculation-description-heading-bold' : undefined}>
    {children}
  </div>
);

export const IndentedText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div>
    {children}
  </div>
);

export const IndentedBoundedText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="c-impact-analysis-results-text-box">
    <IndentedText>
      {children}
    </IndentedText>
  </div>
);

const hideIfZero = (value: number | undefined) => value === 0 ? undefined : value;
