import {
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
  StatisticsSelectedDate,
} from '@sonnen/shared-web';
import { AnyAction } from 'redux';
import { ActionsUnion, createAction } from '../../../utils';
import { ChartDataSeries, ChartDataSeriesKey } from './types/chartDataSeries.interface';
import { SiteForecastConsumption, SiteForecastProduction } from './types/forecast.interface';

export enum ANALYSIS_ACTIONS {
  SET_DAY_CHART_DATE = '[Analysis] SET_DAY_CHART_DATE',
  SET_DATA_SERIES = '[Analysis] SET_DATA_SERIES',
  TOGGLE_DATA_SERIES = '[Analysis] TOGGLE_DATA_SERIES',
  SET_FORECAST_PRODUCTION_FULL = '[Analysis] SET_FORECAST_PRODUCTION_FULL',
  SET_FORECAST_CONSUMPTION_FULL = '[Analysis] SET_FORECAST_CONSUMPTION_FULL',
  SET_STATISTICS_DATE = '[Analysis] SET_STATISTICS_DATE',
  SET_PIE_CHART_SERIES = '[Analysis] SET_PIE_CHART_SERIES',
  SET_BAR_CHART_SERIES = '[Analysis] SET_BAR_CHART_SERIES',
  CLEAR_FORECAST_DATA = '[Analysis] CLEAR_FORECAST_DATA',
  TRIGGER_LIVE_STATE = '[Analysis] TRIGGER_LIVE_STATE',
}

export const AnalysisActions = {
  toggleDataSeries: createAction(
    ANALYSIS_ACTIONS.TOGGLE_DATA_SERIES,
    (dataSeriesKey: ChartDataSeriesKey) => ({ dataSeriesKey }),
  ),
  setDataSeries: createAction(
    ANALYSIS_ACTIONS.SET_DATA_SERIES,
    (dataSeries: Partial<ChartDataSeries>) => ({ dataSeries }),
  ),
  setDayChartDate: createAction(
    ANALYSIS_ACTIONS.SET_DAY_CHART_DATE,
    (date: Date) => ({ date }),
  ),
  setForecastProductionFull: createAction(
    ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION_FULL,
    (forecastProductionFull: SiteForecastProduction[]) => ({ forecastProductionFull }),
  ),
  setForecastConsumptionFull: createAction(
    ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION_FULL,
    (forecastConsumptionFull: SiteForecastConsumption[]) => ({ forecastConsumptionFull }),
  ),
  setStatisticsDate: createAction(
    ANALYSIS_ACTIONS.SET_STATISTICS_DATE,
    (statisticsSelectedDate: StatisticsSelectedDate) => ({ statisticsSelectedDate }),
  ),
  setPieChartSeries: createAction(
    ANALYSIS_ACTIONS.SET_PIE_CHART_SERIES,
    (pieChartSeries: PieChartSeries) => ({ pieChartSeries }),
  ),
  setBarChartSeries: createAction(
    ANALYSIS_ACTIONS.SET_BAR_CHART_SERIES,
    (barChartSeries: BarChartSeries) => ({ barChartSeries }),
  ),
  clearForecastData: createAction(ANALYSIS_ACTIONS.CLEAR_FORECAST_DATA),
  triggerLiveState: createAction(ANALYSIS_ACTIONS.TRIGGER_LIVE_STATE),
};

export function isSetDateAction(action: AnyAction):
  action is ReturnType<typeof AnalysisActions.setDayChartDate | typeof AnalysisActions.setStatisticsDate> {
    return action.type === ANALYSIS_ACTIONS.SET_DAY_CHART_DATE
      || action.type === ANALYSIS_ACTIONS.SET_STATISTICS_DATE;
}

export type AnalysisActions = ActionsUnion<typeof AnalysisActions>;
