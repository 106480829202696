import { PreCommissioningInterface } from '+setupTool/+preCommissioning/store/types';
import { FormBanner } from '+shared/components';
import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as fontLight from '@sonnen/shared-web/src/fonts/brandontext/BrandonText-Light.ttf';
import * as fontMedium from '@sonnen/shared-web/src/fonts/brandontext/BrandonText-Medium.ttf';
import * as fontRegular from '@sonnen/shared-web/src/fonts/brandontext/BrandonText-Regular.ttf';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import iconWarning from '../../../../assets/img/setup-tools/iconWarning.png';

import './PreCommissioningDocument.component.scss';

Font.register({
  family: 'BrandonText',
  fontWeight: 'normal',
  src: fontRegular,
});

Font.register({
  family: 'BrandonTextLight',
  fontWeight: 'light',
  src: fontLight,
});

Font.register({
  family: 'BrandonTextBold',
  fontWeight: 'bold',
  src: fontMedium,
});

const styles = StyleSheet.create({
  page: {
    color: '#181a27',
    fontFamily: 'BrandonText',
    fontSize: 9,
    padding: '20 53',
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 45,
    width: '100%',
  },
  pageTitle: {
    fontSize: 21,
    fontFamily: 'BrandonTextLight',
    marginBottom: 18,
  },
  table: {
    marginBottom: 6,
    marginTop: 24,
  },
  tableHeader: {
    borderBottomColor: '#c5c6c9',
    borderBottomWidth: 1,
    fontSize: 12,
    padding: '2 0',
  },
  tableRow: {
    borderBottomColor: '#c5c6c9',
    borderBottomWidth: 1,
    flexDirection: 'row',
    padding: '12 0',
  },
  tableRowLabel: {
    color: '#8b8c93',
    width: 170,
  },
  tableRowValue: {
    flexDirection: 'row',
    fontFamily: 'BrandonTextBold',
  },
  tableRowIcon: {
    height: 14,
    marginLeft: 8,
    padding: 1,
    width: 14,
  },
  note: {
    borderColor: '#c5c6c9',
    borderWidth: 1,
    flexDirection: 'row',
    padding: '7 6',
  },
  noteIcon: {
    height: 14,
    marginRight: 8,
    marginTop: 3,
    padding: 1,
    width: 14,
  },
  noteText: {
    width: 450,
  },
  dateCreatedContainer: {
    display: 'flex',
  },
  dateCreated: {
    fontFamily: 'BrandonTextBold',
  },
  partnerLogo: {
    display: 'flex',
    maxHeight: 30,
    maxWidth: 375,
  },
});

interface Props {
  fields: PreCommissioningInterface;
}

export const PreCommissioningDocument: React.FC<Props> = ({fields}) => {
  const generationDate = moment().format('DD.MM.YYYY');

  const addressString =
    fields.assetZipCode + ' '
    + fields.assetCity + ', '
    + fields.assetCountryCode + '\n'
    + fields.assetStreetNameAndNumber;

  const fileName =
    `${I18n.t(T.setupTool.preCommissioning.fileNamePrefix)}_${fields.customerFirstName}-${fields.customerLastName}.pdf`;

  return (
    <div data-hj-suppress={true}>
      <PDFDownloadLink
        className={'c-dso-registration-file__row c-dso-registration-file__row--precommissioning'}
        document={
          <Document>
            <Page size="A4" style={styles.page}>
              <View style={styles.header}>
                <Text style={styles.dateCreatedContainer}>
                  {I18n.t(T.setupTool.preCommissioning.pdfDocument.createdOn)} <Text
                  style={styles.dateCreated}>{generationDate}</Text>
                </Text>
                {!!fields.partnerCompanyLogotype && <Image
                  src={'data:image/jpeg;image/png;base64,' + fields.partnerCompanyLogotype}
                  style={styles.partnerLogo}
                />}
              </View>

              <View>
                <Text style={styles.pageTitle}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.title)}</Text>
                <Text>{I18n.t(T.setupTool.preCommissioning.pdfDocument.description)}</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.tableHeader}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.heading1)}</Text>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.firstName)}</Text>
                  <Text style={styles.tableRowValue}>{fields.customerFirstName}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.lastName)}</Text>
                  <Text style={styles.tableRowValue}>{fields.customerLastName}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>
                    {I18n.t(T.setupTool.preCommissioning.pdfDocument.phoneNumber)}
                  </Text>
                  <Text style={styles.tableRowValue}>{fields.customerPhoneNumber}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text
                    style={styles.tableRowLabel}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.emailAddress)}</Text>
                  <Text style={styles.tableRowValue}>{fields.customerEmailAddress}</Text>
                </View>
              </View>

              <View style={styles.table}>
                <Text style={styles.tableHeader}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.heading2)}</Text>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>
                    {I18n.t(T.setupTool.preCommissioning.pdfDocument.sonnenCustomerID)}</Text>
                  <View style={styles.tableRowValue}>
                    <Text>{fields.customerNumber}</Text>
                    <Image
                      src={iconWarning}
                      style={styles.tableRowIcon}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.note}>
                <Image
                  src={iconWarning}
                  style={styles.noteIcon}
                />
                <Text style={styles.noteText}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.infoBox1)}</Text>
              </View>

              <View style={styles.table}>
                <Text style={styles.tableHeader}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.heading3)}</Text>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>
                    {I18n.t(T.setupTool.preCommissioning.pdfDocument.installationAddress)}
                  </Text>
                  <Text style={styles.tableRowValue}>{addressString}</Text>
                </View>
              </View>

              <View style={styles.table}>
                <Text style={styles.tableHeader}>{I18n.t(T.setupTool.preCommissioning.pdfDocument.heading4)}</Text>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>
                    {I18n.t(T.setupTool.preCommissioning.pdfDocument.partnerName)}
                  </Text>
                  <Text style={styles.tableRowValue}>{fields.partnerCompanyName}</Text>
                </View>
                <View style={styles.tableRow}>
                  <Text style={styles.tableRowLabel}>
                    {I18n.t(T.setupTool.preCommissioning.pdfDocument.partnerID)}
                  </Text>
                  <View style={styles.tableRowValue}>
                    <Text>{fields.partnerCompanyNumber}</Text>
                    <Image
                      src={iconWarning}
                      style={styles.tableRowIcon}
                    />
                  </View>
                </View>
              </View>

              <View style={styles.note}>
                <Image
                  src={iconWarning}
                  style={styles.noteIcon}
                />
                <Text style={styles.noteText}>
                  {I18n.t(T.setupTool.preCommissioning.pdfDocument.infoBox2,
                    { partnerCompany: fields.partnerCompanyName})}</Text>
              </View>
            </Page>
          </Document>
        }
        fileName={fileName} >
        {({ blob, url, loading, error }) => (loading ? (error ? (
              <FormBanner
                isVisible={true}
                type={'error'}
                id={'documentErrorBanner'}
                message={I18n.t(T.setupTool.preCommissioning.generatingDocumentError)}
                icon={<Icon.CloseCircle className={'c-precommisioning-document__form-banner-icon'}/>}
              />
            ) : I18n.t(T.setupTool.preCommissioning.loadingDocument))
            : <>
              <span className={'c-dso-registration-file__file-container'}>
                  <span className={'c-dso-registration-file__file-icon'} onClick={e => e.preventDefault()}>
                    <Icon.Contract/></span>
                  <span className={'sw-link'}>{fileName}</span>
                </span>
              <span className={'c-dso-registration-file__empty-container'} onClick={e => e.preventDefault()}/>
              <span className={'c-dso-registration-file__download'}>
                  <span className={'sw-link'}>{I18n.t(T.setupTool.form.download)}</span>
                  <Icon.Download className={'c-dso-registration-file__download-icon sw-link__icon'} />
                </span>
            </>
        )}
      </PDFDownloadLink>
    </div>
  );
};
