import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import avatar1 from '../../../../assets/img/sonnenDrive/sonnen-drive-avatar-1.png';
import avatar2 from '../../../../assets/img/sonnenDrive/sonnen-drive-avatar-2.png';
import avatar3 from '../../../../assets/img/sonnenDrive/sonnen-drive-avatar-4.png';

export interface CarouselSlide {
  avatar: string;
  opinion: string;
  author: string;
}

export const getSlides = (): CarouselSlide[] => [
  {
    avatar: avatar1,
    opinion: I18n.t(T.drive.reviews.one.text),
    author: I18n.t(T.drive.reviews.one.name),
  },
  {
    avatar: avatar2,
    opinion: I18n.t(T.drive.reviews.two.text),
    author: I18n.t(T.drive.reviews.two.name),
  },
  {
    avatar: avatar3,
    opinion: I18n.t(T.drive.reviews.three.text),
    author: I18n.t(T.drive.reviews.three.name),
  },
];
