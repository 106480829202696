import { T } from '@sonnen/shared-i18n/service';
import { Icon, LinkButton, TooltipConfirmation } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './DeleteButton.component.scss';

type Props = {
  onDelete: () => void;
};

export const DeleteButton: React.FC<Props> = ({ onDelete }) => {
  const [isPopupConfirmationOpen, togglePopupConfirmation] = React.useState<boolean>(false);

  return (
    <div className={'c-delete-button'}>
      <LinkButton
        className={'c-delete-button__icon-with-text'}
        onClick={() => togglePopupConfirmation(!isPopupConfirmationOpen)}
      >
        <Icon.Trash className={'c-delete-button__icon'}/>
        {I18n.t(T.general.basicAcions.delete)}
      </LinkButton>
      <TooltipConfirmation
        isOpen={isPopupConfirmationOpen}
        closeAction={() => togglePopupConfirmation(false)}
        confirmAction={onDelete}
        confirmationText={I18n.t(T.general.basicActions.confirmPopup.message)}
        confirmBtnText={I18n.t(T.general.basicActions.confirmPopup.yes)}
        cancelBtnText={I18n.t(T.general.basicActions.confirmPopup.no)}
      />
    </div>
  );
};
