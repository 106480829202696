import { Config } from '+config';
import { httpClient } from '+shared/network/network.client';
import { from } from 'rxjs';
import { Document } from './types';

const getDocument = ({
  country,
  documentType: type,
  language,
}: Document) => from(
  httpClient.get<string>(Config.DOCS_API, {
    query: {
      type,
      language,
      country,
    },
  }).then(res => res.parsedBody()));

export const StaticRepository = {
  getDocument,
};
