import { getLeadOfferData } from '+app/+lead/+offer/store/+offer.selectors';
import { getOfferList, getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { Form, FormErrorBanner, FormSubmitButton } from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { EventCategory, FormName, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { LayoutActions } from '+shared/store/layout';
import { BatteryModelName } from '+shared/store/lead/types/leadBattery.interface';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { isStatusSet } from '+utils/status.util';
import { T } from '@sonnen/shared-i18n/service';
import { Card } from '@sonnen/shared-web';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import { defaultTo } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationConsumption } from '../../components';
import { LeadConfigurationHardware } from '../../components/LeadConfigurationHardware';
import {
  CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_SUBMIT_QUERY,
  ConfigurationPageActions,
} from '../../store';
import {
  getConfigurationForm,
  getConfigurationProposal,
  getConfigurationRecommendationSubmitQuery,
  getConfigurationRecommendationSubmitQueryStatus,
  getConfigurationSubmitQuery,
  getConfigurationSubmitQueryStatus,
} from '../../store/+configuration.selectors';
import { configurationFormInitial, ConfigurationSchema } from '../../store/schemas';
import { ConfigurationForm } from '../../store/types';
import { getFormFieldsForEachPv, PvKind } from '../LeadConfigurationPv/LeadConfigurationPv.helper';
import {
  findAcceptedHwProduct,
  formFields,
  mapLeadErrorTitleToTranslatedError,
  scrolltoError,
  shouldSkipRecommendation,
} from './LeadConfigurationForm.helper';

import './LeadConfigurationForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  configurationForm: getConfigurationForm(state),
  getConfigurationSubmitQueryStatus: getConfigurationSubmitQueryStatus(state),
  configurationSubmitQuery: getConfigurationSubmitQuery(state),
  configurationRecommendationSubmitQuery: getConfigurationRecommendationSubmitQuery(state),
  configurationRecommendationSubmitQueryStatus: getConfigurationRecommendationSubmitQueryStatus(state),
  existingConfigurationProposal: getConfigurationProposal(state),
  productBatteryList: getProductBatteryList(state),
  offer: getLeadOfferData(state),
  offers: getOfferList(state),
  lead: getLead(state),
});

const mapDispatchToProps = mapActions({
  setConfigurationForm: ConfigurationPageActions.setConfigurationForm,
  removeConfiguration: ConfigurationPageActions.removeConfiguration,
  createRecommendation: ConfigurationPageActions.createRecommendation,
  setupClearConfigurationHint: ConfigurationPageActions.setupClearConfigurationHint,
  clearQuery: QueryActions.init,
  scrollToTop: LayoutActions.scrollToTop,
  clearConfigurationData: ConfigurationPageActions.clearData,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const LeadConfigurationFormComponent: React.FC<Props> = ({
  actions,
  configurationForm,
  getConfigurationSubmitQueryStatus,
  configurationSubmitQuery,
  configurationRecommendationSubmitQuery,
  configurationRecommendationSubmitQueryStatus,
  existingConfigurationProposal,
  productBatteryList,
  offer,
  offers,
  lead,
}) => {
  const initialValues = defaultTo(configurationFormInitial(!isStatusSet(lead?.status.summary.hardwareAlreadySold)))(configurationForm);
  const [configurationErrors, setConfigurationErrors] = React.useState<string[]>([]);
  const submitButtonLabel = existingConfigurationProposal
    ? I18n.t(T.lead.configuration._salessolution_.recalculate)
    : I18n.t(T.lead.configuration._salessolution_.calculateButton);
  const { track } = useTracking();

  React.useEffect(() => {
    if (getConfigurationSubmitQueryStatus.success) {
      actions.clearQuery(CONFIGURATION_SUBMIT_QUERY);
    }
  }, [getConfigurationSubmitQueryStatus]);

  React.useEffect(() => {
    const configurationSubmitErrors = configurationSubmitQuery.error
      && configurationSubmitQuery.error.response.parsedBody();

    const configurationRecommendationSubmitErrors = configurationRecommendationSubmitQuery.error &&
      configurationRecommendationSubmitQuery.error.response &&
      configurationRecommendationSubmitQuery.error.response.parsedBody();

    Promise.all([configurationSubmitErrors, configurationRecommendationSubmitErrors]).then(resArray => {
      const errors = resArray.map(res => res ? res.errors[0] : {});

      const filteredErrors = errors
        .filter((error) => !isEmpty(error.title))
        .map(({ title, detail }) =>
          mapLeadErrorTitleToTranslatedError(title || '', detail || ''))
        .filter(
          // only unique keys
          (v: string, i: number, a: string[]) => a.indexOf(v) === i,
        );

      setConfigurationErrors(filteredErrors);
      scrolltoError();
    });
  }, [getConfigurationSubmitQueryStatus.error, configurationRecommendationSubmitQueryStatus.error]);

  const onFieldValueChange = (form: FormikProps<ConfigurationForm>) =>
    (payload: FormInputSubscriptionPayload<ConfigurationForm>) => {
      const { name, value } = payload;
      const { values: formValues } = form;

      const ignoredFields = [
        formFields.CAPACITY_GROSS,
        formFields.PEAK_POWER,
      ];

      const batteryName = (name === formFields.MODEL_NAME) ?
        value as BatteryModelName
        : formValues[formFields.MODEL_NAME];

      const commissioningDate = (name === formFields.COMMISSIONING_DATE) ?
        value as number
        : formValues[formFields.COMMISSIONING_DATE];

      if ((ignoredFields as string[]).includes(name)) {
        return;
      }

      if (name === formFields.NEW_BATTERY) {
        form.setFieldValue(formFields.MODEL_NAME, BatteryModelName.SONNENBATTERIE_10_AC);
        form.setFieldValue(formFields.CAPACITY_GROSS, '');
        form.setFieldTouched(formFields.CAPACITY_GROSS, false);
        return;
      }

      if (name === formFields.MODEL_NAME) {
        form.setFieldValue(formFields.CAPACITY_GROSS, '');
        form.setFieldTouched(formFields.CAPACITY_GROSS, false);
      }

      if (existingConfigurationProposal && name === formFields.MULTIPLE_PV) {
        actions.clearConfigurationData();

        // after submitting all form fields are set to touched and new fields appear with errors
        // so it's necessary to set them back to untouched
        Object.values(getFormFieldsForEachPv(PvKind.SECOND_PV)).forEach(fieldName =>
          form.setFieldTouched(fieldName, false),
        );
        form.setFieldTouched(formFields.DSO_CONSENT_TO_COMBINE_PHOTOVOLTAIC_SYSTEMS, false);
      }

      if (shouldSkipRecommendation({ batteryName, commissioningDate })
        || formValues.multiplePv || (name === formFields.MULTIPLE_PV && !value)) {
        actions.clearQuery(CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY);
        return;
      }

      actions.setupClearConfigurationHint();
      const formValuesChanged = {
        ...formValues,
        [name]: value,
      };

      actions.createRecommendation(formValuesChanged);
    };

  const onSubmit = (values: ConfigurationForm) => {
    if (existingConfigurationProposal) {
      actions.removeConfiguration(existingConfigurationProposal.id);
    }
    if (offer) {
      const battery = productBatteryList.find(battery => battery.id === offer.battery);

      if (!battery) {
        return;
      }

      actions.setConfigurationForm({
        ...values,
        capacityGross: battery?.id,
        modelName: battery?.modelName,
      });
    } else {
      actions.setConfigurationForm(values);
    }
    actions.scrollToTop();
  };

  const onSubmitButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    track(InteractionEvents.buttonClick({
      category: EventCategory.SALES_CONFIGURATOR,
      action: existingConfigurationProposal ? 'Recalculate' : 'Calculate',
      event,
    }));
  };

  const gtmErrorProps = {
    category: EventCategory.SALES_CONFIGURATOR,
    formName: FormName.SALES_CONFIGURATOR,
    queryStatus: [
      getConfigurationSubmitQueryStatus,
      configurationRecommendationSubmitQueryStatus,
    ],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ConfigurationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      render={form => (
        <Form className={'c-lead-configuration-form'}>
          <Card footerContent={
            <div className={'c-lead-configuration-form__button-wrapper'} >
              <FormSubmitButton
                label={submitButtonLabel}
                isSubmitting={getConfigurationSubmitQueryStatus.pending}
                onClick={onSubmitButtonClick}
              />
            </div>
          }>
            <div className={'c-lead-configuration-form__container'}>
              <div className={'c-lead-configuration-form__title'}>
                {I18n.t(T.lead.configuration._salessolution_.formTitle)}
              </div>
              <div className={'c-lead-configuration-form__subtitle'}>
                {I18n.t(T.lead.configuration._salessolution_.formSubtitle)}
              </div>

              <FormFieldObserver<ConfigurationForm> onChange={onFieldValueChange(form)}>
                <LeadConfigurationConsumption form={form} />
                <LeadConfigurationHardware
                  form={form}
                  productBatteryList={productBatteryList}
                  existingOffer={offer}
                  previouslyAcceptedHardwareOffer={offers.find(offer => findAcceptedHwProduct(offer.products))}
                  isHardwareAlreadySold={isStatusSet(lead?.status.summary.hardwareAlreadySold)}
                />
              </FormFieldObserver>

              {configurationErrors
                .map((translationKey: string) => (
                  <FormErrorBanner
                    key={translationKey}
                    isVisible={
                      getConfigurationSubmitQueryStatus.error || configurationRecommendationSubmitQueryStatus.error}
                    error={translationKey}
                    gtm={gtmErrorProps}
                  />
                ))
              }
            </div>
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadConfigurationForm =
  connect(mapStateToProps, mapDispatchToProps)(LeadConfigurationFormComponent);
