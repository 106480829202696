import { PATHS } from '+app/router';
import { getLeadId } from '+lead/+overview/store/+overview.selectors';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import {
  getPreCommissioningFields,
  getPreCommissioningMarkDocumentGenerationQueryStatus,
  getPreCommissioningStatus,
} from '+setupTool/+preCommissioning/store/+preCommissioning.selectors';
import { PreCommissioningInitial } from '+setupTool/+preCommissioning/store/schemas';
import { PreCommissioningStatus } from '+setupTool/+preCommissioning/store/types';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { getErrors } from '+setupTool/store/+setupTool.selectors';
import { DetailListTable, Widget } from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icofont, Icon, Modal, ModalLayout } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { Form, Formik } from 'formik';
import { values } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import './PreCommissioning.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  fields: getPreCommissioningFields(state),
  status: getPreCommissioningStatus(state),
  documentGenerationQueryStatus: getPreCommissioningMarkDocumentGenerationQueryStatus(state),
  errors: getErrors(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  goToSetupDashboard: (leadId: string, leadStage?: string) => push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  getLead: LeadActions.getLead,
  markGenerationOfPreCommissioningDocument: PreCommissioningActions.markGenerationOfPreCommissioningDocument,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const PreCommissioningComponent: React.FC<Props> = ({
  actions,
  leadId,
  fields,
  status,
  documentGenerationQueryStatus,
  errors,
  leadStage,
}) => {
  const addressString =
    fields.assetZipCode + ' '
    + fields.assetCity + ', '
    + fields.assetCountryCode + '\n'
    + fields.assetStreetNameAndNumber;

  const closePreCommissioningModal = () => {
    actions.goToSetupDashboard(leadId!, leadStage);
  };

  const isFormComplete = !values(fields).includes('');
  if (!isFormComplete || documentGenerationQueryStatus.success) {
    closePreCommissioningModal();
  }

  return isFormComplete ? (
    <Modal
      id={'setup-tool-precommissioning_modal'}
      isOpen={true}
      onClose={closePreCommissioningModal}
      className={'sw-modal-layout--setup-precommissioning'}
    >
      <div className={'precommissioning-modal'}>
        <Formik
          initialValues={PreCommissioningInitial}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={() => {
            if (leadId) {
              actions.markGenerationOfPreCommissioningDocument(leadId, fields);
            }
          }}
          render={form => (
            <Form className={'precommissioning-modal-form'}>
              <ModalLayout
                title={I18n.t(T.setupTool.step.preCommissioning)}
                footer={
                  <Button
                    name={'precommissioning-modal-form__generate-button'}
                    className={'precommissioning-modal-form__generate-button'}
                    type={'submit'}
                    label={status === PreCommissioningStatus.NEEDS_REGENERATION
                      ? I18n.t(T.setupTool.preCommissioning.regenerateButton)
                      : I18n.t(T.setupTool.preCommissioning.generateButton)
                    }
                    size={ButtonSize.NORMAL}
                    theme={ButtonTheme.PRIMARY}
                    isLoading={documentGenerationQueryStatus.pending}
                  />
                }
              >
                <>
                  <p className={'precommissioning-modal-form__description'}>
                    <Translate value={T.setupTool.preCommissioning.description} dangerousHTML={true} />
                  </p>

                  <ErrorBox errors={errors} />

                  <Widget
                    icon={<Icofont type={'user'} />}
                    className={'precommissioning-modal-form__widget'}
                    heading={I18n.t(T.setupTool.preCommissioning.headings.customerData)}
                  >
                    <DetailListTable
                      list={[
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.firstName),
                          value: fields.customerFirstName,
                        },
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.lastName),
                          value: fields.customerLastName,
                        },
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.phoneNumber),
                          value: fields.customerPhoneNumber,
                        },
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.emailAddress),
                          value: fields.customerEmailAddress,
                        },
                      ]}
                      suppressRowValuesInHotjar={true}
                    />
                  </Widget>

                  <Widget
                    icon={<Icofont type={'user'} />}
                    className={'precommissioning-modal-form__widget'}
                    heading={I18n.t(T.setupTool.preCommissioning.headings.customerID)}
                  >
                    <DetailListTable
                      list={[
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.sonnenCustomerID),
                          value: fields.customerNumber,
                          tooltipText: I18n.t(T.setupTool.preCommissioning.tooltips.sonnenCustomerID),
                          isClickableTooltip: false,
                          isHoverableTooltip: true,
                          tooltipBubbleSide: 'bottom-right',
                        },
                      ]}
                      hasBottomBorder={false}
                      suppressRowValuesInHotjar={true}
                    />
                  </Widget>

                  <Widget
                    icon={<Icon.HouseOnHill className={'precommissioning-modal-form__widget-icon'} />}
                    className={'precommissioning-modal-form__widget'}
                    heading={I18n.t(T.setupTool.preCommissioning.headings.installationAddress)}
                  >
                    <DetailListTable
                      list={[
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.installationAddress),
                          value: addressString,
                        },
                      ]}
                      hasBottomBorder={false}
                      suppressRowValuesInHotjar={true}
                    />
                  </Widget>

                  <Widget
                    icon={<Icon.NotesWithPencil className={'precommissioning-modal-form__widget-icon'} />}
                    className={'precommissioning-modal-form__widget'}
                    heading={I18n.t(T.setupTool.preCommissioning.headings.sonnenBatteryPartnerInformation)}
                  >
                    <DetailListTable
                      list={[
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.partnerName),
                          value: fields.partnerCompanyName,
                        },
                        {
                          label: I18n.t(T.setupTool.preCommissioning.labels.partnerID),
                          value: fields.partnerCompanyNumber,
                          tooltipText: I18n.t(T.setupTool.preCommissioning.tooltips.partnerID),
                          isClickableTooltip: false,
                          isHoverableTooltip: true,
                          tooltipBubbleSide: 'bottom-right',
                        },
                      ]}
                      suppressRowValuesInHotjar={true}
                    />
                  </Widget>
                </>
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  ) : null;
};

export const PreCommissioning = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreCommissioningComponent);
