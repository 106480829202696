import { SelectCategory } from '+shared/store/firebase/types';

const MATCH_YOUTUBE_ID = /youtube\.com\/watch\?v\=.*/;

export const youtubeLink = 'youtubeLink';
export const videoCategory = 'videoCategory';
export interface AddVideoForm {
  [youtubeLink]: string;
  [videoCategory]: SelectCategory;
}

export const isYoutubeLink = (value: string) => 
  MATCH_YOUTUBE_ID.test(value);

export const getYouTubeId = (value: string) => {
  const match = MATCH_YOUTUBE_ID.exec(value);
  const splittedUrl = match && match.length && match[0].split('=');
  return splittedUrl && splittedUrl.length === 2 && splittedUrl[1];
};

export const validate = (values: AddVideoForm) => {
  const errors = {};

  if (!values[youtubeLink] || !values[youtubeLink].length) {
    errors[youtubeLink] = 'Youtube link cannot be empty';
  }

  if (values[youtubeLink] && !isYoutubeLink(values[youtubeLink])) {
    errors[youtubeLink] = 'Enter correct Youtube link with video id';
  }

  if (!values[videoCategory]) {
    errors[videoCategory] = 'Choose category';
  }

  return errors;
};
