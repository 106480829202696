import { LeadForm } from '+app/+lead/store/types';
import { FormCheckbox, FormInput, FormInputSelect, FormSectionParagraph } from '+shared/components';
import { countries } from '+shared/store/lead';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadNewInvoiceAddress.component.scss';

interface Props {
  form: FormikProps<LeadForm>;
}

export const LeadNewInvoiceAddress: React.FC<Props> = ({ form }) => (
  <div className={'c-lead-new-invoice-address'}>
    <FormSectionParagraph>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.invoiceAddress)}
    </FormSectionParagraph>
    <div className={'c-lead-new-invoice-address__checkbox'}>
      <FormCheckbox
        form={form}
        label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.anotherAddressCheckbox)}
        name={'hasDifferentInvoiceAddress'}
      />
    </div>

    {form.values.hasDifferentInvoiceAddress && (
      <>
        <FormInputSelect
          form={form}
          collection={countries}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.country)}
          name={'invoiceAddress.country'}
          mapper={key => I18n.t(T.lead.boc._salessolution_.dictionary.countries[key.toLowerCase()])}
          isRequired={true}
        />

        <FormInput
          form={form}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.street)}
          name={'invoiceAddress.street'}
          isRequired={true}
        />

        <FormInput
          form={form}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCode)}
          name={'invoiceAddress.zipCode'}
          isRequired={true}
        />

        <FormInput
          form={form}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.city)}
          name={'invoiceAddress.city'}
          isRequired={true}
        />
      </>
    )}
  </div>
);
