import { LeadNewContactDetails } from '+app/+lead/components/LeadNewContactDetails';
import { LeadNewCustomerDetails } from '+app/+lead/components/LeadNewCustomerDetails';
import { LeadNewInvoiceAddress } from '+app/+lead/components/LeadNewInvoiceAddress';
import { LeadNewManualDeliveryAddress } from '+app/+lead/components/LeadNewManualDeliveryAddress';
import { getAddressDetailsQueryStatus } from '+app/+lead/store/+lead.selectors';
import { LeadForm } from '+app/+lead/store/types';
import { FormInputSelect, FormSectionParagraph } from '+shared/components';
import { countries } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import * as Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadAutosuggestedAddress } from '../LeadAutosuggestedAddress';

import './LeadNewForm.component.scss';

const mapStateToProps = (state: StoreState) => ({
  addressDetailsQueryStatus: getAddressDetailsQueryStatus(state),
});

const mapDispatchToProps = mapActions({});

interface ComponentProps {
  form: FormikProps<LeadForm>;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

export const LeadNewFormComponent: React.FC<Props> = ({
  form,
  addressDetailsQueryStatus,
}) => {
  const [shouldInputsAppear, setInputsAppear] = React.useState<boolean>(false);

  return (
    <Masonry
      className={'c-lead-new-form c-lead-new-form__grid'}
      elementType={'div'}
      options={{ percentPosition: true, transitionDuration: 0 }}
    >
      <div className={'c-lead-new-form__grid-item'}>
        <LeadNewCustomerDetails form={form} />
      </div>

      <div className={'c-lead-new-form__grid-item'}>
        <FormSectionParagraph>
          {I18n.t(T.lead.boc._salessolution_.form.personalDetails.deliveryAddress)}
        </FormSectionParagraph>

        <FormInputSelect
          form={form}
          collection={countries}
          label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.country)}
          name={'deliveryAddress.country'}
          mapper={key => I18n.t(T.lead.boc._salessolution_.dictionary.countries[key.toLowerCase()])}
          isRequired={true}
        />

        <LeadAutosuggestedAddress
          form={form}
          setInputsAppear={setInputsAppear}
          shouldInputsAppear={shouldInputsAppear}
        />
        {shouldInputsAppear &&
          <LeadNewManualDeliveryAddress
            form={form}
            isLoading={addressDetailsQueryStatus.pending}
          />
        }
      </div>

      <div className={'c-lead-new-form__grid-item'}>
        <LeadNewContactDetails form={form} />
        <LeadNewInvoiceAddress form={form} />
      </div>
    </Masonry>
  );
};

export const LeadNewForm =
  connect(mapStateToProps, mapDispatchToProps)(LeadNewFormComponent);
