import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { PATHS } from '../../../router';

import './HelpHeader.component.scss';

const renderTabNav = () => {
  const navLinkProps = { border: false, type: 'desktop' as NavLinkType, faded: true };

  return (
    <TabNav
      items={(
        <>
          <NavLink {...navLinkProps} action={PATHS.HELP()}>
            <Icon.Info className={'c-help-header__icon'}/>
            {I18n.t(T.helpAndSupportPage.header)}
          </NavLink>
          <NavLink {...navLinkProps} action={PATHS.VIDEOS()}>
            <Icon.Play className={'c-help-header__icon'}/>
            {I18n.t(T.videosSubpage.header)}
          </NavLink>
        </>
      )}
    />
  );
};

// @TODO create a component HeaderWithTabs for HelpHeader, AccountHeader etc.
export const HelpHeader: React.FC = () =>
  <PageHeader
    className={'c-help-header'}
    tabNav={renderTabNav()}
    isExpanded={true}
  >
    <h1 className={'c-help-header__title'}>
      {I18n.t(T.help.headline)}
    </h1>
  </PageHeader>;
