import { Config } from '+config';
import * as app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { VideoFirebase } from './types';
import { News, NewsCP, Portal } from './types/news.interface';

export interface FirebaseProps {
  firebase: Firebase;
}

export class Firebase {
  firestore: app.firestore.Firestore;
  auth: app.auth.Auth;

  constructor() {
    app.initializeApp(Config.FIREBASE);
    app.auth().setPersistence(app.auth.Auth.Persistence.NONE);
    this.firestore = app.firestore();
    this.auth = app.auth();
  }

  // ** Authentication **
  isFirebaseAuthenticated = () => this.auth.currentUser ? true : false;

  firebaseLogin = (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  firestoreSignout = () => this.auth.signOut();

  // ** News **
  newsCollection = (newsPortal: Portal) => this.firestore.collection(
    newsPortal === Portal.SP ? 'partnerPortalNews' : 'news');
  readNewsCollection = () => this.firestore.collection('readPartnerPortalNews');

  readNewsCollectionByUser = (partnerId: string) => this.readNewsCollection()
    .where('userId', '==', partnerId);

  currentNewsCollection = (newsPortal: Portal) => this.newsCollection(newsPortal)
    .orderBy('levelValue');

  addNews = (newsPortal: Portal, data: News | NewsCP) =>
    this.newsCollection(newsPortal).doc(data.id).set(data);

  editNews = (newsPortal: Portal, data: News | NewsCP) =>
    this.newsCollection(newsPortal).doc(data.id).update(data);

  deleteNews = (newsPortal: Portal, data: News | NewsCP) =>
    this.newsCollection(newsPortal).doc(data.id).delete();

  getSortedByExpiryDateNewsList = (newsPortal: Portal) => this.newsCollection(newsPortal)
    .get();

  getSortedByExpiryDateNotificationsList = (newsPortal: Portal) => this.newsCollection(newsPortal)
    .get();

  getCurrentNewsList = (newsPortal: Portal) => this.currentNewsCollection(newsPortal)
    .get();

  getCurrentNotificationsList = (newsPortal: Portal) => this.currentNewsCollection(newsPortal)
    .get();

  getReadNewsCollectionByUser = (partnerId: string) => this.readNewsCollectionByUser(partnerId)
    .get();

  // ** Videos **
  videosCollection = () => this.firestore.collection('videos');

  getVideosList = () => this.videosCollection().get();

  addVideo = (video: VideoFirebase) =>
    this.videosCollection().doc(video.id).set(video);

  deleteVideo = (id: string) =>
    this.videosCollection().doc(id).delete();
}
