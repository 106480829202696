import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { LeadListRouteQueryParams, PATHS } from '+app/router';
import { getRouteQueryParams } from '+app/router/store/router.selectors';
import { LeadPageActions } from '+lead/store/+lead.actions';
import {
  getAssignLeadQueryStatus,
  getDeclineLeadQueryStatus,
  getIsAssignLeadModalOpen,
  getIsDeclineLeadModalOpen,
  getPartnerEmployeeList,
  getSelectedLead,
} from '+lead/store/+lead.selectors';
import {
  Container,
  PageBlock,
  Pagination,
  PaginationSummary,
} from '+shared/components';
import { Tutorial } from '+shared/containers/Tutorial';
import { LayoutActions } from '+shared/store/layout';
import { isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileSalesforceContactId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { InboxLeadListTable } from '../../components/InboxLeadListTable';
import { LeadInboxEmpty } from '../../components/LeadInboxEmpty';
import { LeadListAssignModal } from '../../components/LeadListAssignModal';
import { LeadListDeclineModal } from '../../components/LeadListDeclineModal';
import { LeadListPageActions } from '../../store';
import {
  getInboxLeadCollection,
  getInboxLeadsCollectionQueryStatus,
  getInboxLeadsCollectionTotalCount,
} from '../../store/+leadList.selectors';

import './LeadInbox.component.scss';

const mapStateToProps = (state: StoreState) => ({
  inboxLeadCollection: getInboxLeadCollection(state),
  inboxLeadCollectionTotalCount: getInboxLeadsCollectionTotalCount(state),
  inboxLeadsCollectionQueryStatus: getInboxLeadsCollectionQueryStatus(state),
  routerQueryParams: getRouteQueryParams(state) as Required<LeadListRouteQueryParams>,
  isModalOpen: isModalOpen(state),
  chosenInboxLead: getSelectedLead(state),
  isAssignLeadModalOpen: getIsAssignLeadModalOpen(state),
  isDeclineLeadModalOpen: getIsDeclineLeadModalOpen(state),
  partnerEmployeeList: getPartnerEmployeeList(state),
  userProfileSalesforceContactId: getUserProfileSalesforceContactId(state),
  assignLeadQueryStatus: getAssignLeadQueryStatus(state),
  declineLeadQueryStatus: getDeclineLeadQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  pushLeadsPage: (queryParams: Required<LeadListRouteQueryParams>) => push(PATHS.LEADS_INBOX(queryParams)),
  toggleAssignLeadModal: LeadPageActions.toggleAssignLeadModal,
  toggleDeclineLeadModal: LeadPageActions.toggleDeclineLeadModal,
  toggleModal: LayoutActions.toggleModal,
  setChosenInboxLead: LeadPageActions.setSelectedLead,
  assignPartnerToLead: LeadListPageActions.assignPartnerToLead,
  declineLead: LeadListPageActions.declineLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<LeadListRouteQueryParams>
  ;

const LeadInboxComponent: React.FC<Props> = ({
  actions,
  inboxLeadCollection,
  inboxLeadCollectionTotalCount,
  inboxLeadsCollectionQueryStatus,
  routerQueryParams,
  isModalOpen,
  chosenInboxLead,
  isAssignLeadModalOpen,
  partnerEmployeeList,
  userProfileSalesforceContactId,
  isDeclineLeadModalOpen,
  assignLeadQueryStatus,
  declineLeadQueryStatus,
}) => {
  const shouldShowPagination = inboxLeadsCollectionQueryStatus.success || inboxLeadsCollectionQueryStatus.error;

  const onPageChange = (page: number | undefined) =>
    actions.pushLeadsPage({ ...routerQueryParams, page: String(page) });

  return (
    <Container>
      {/* <Tutorial id={'leadsInbox'} /> */}
      <PaginationSummary
        i18n={T.lead.list._salessolution_.leadsSummary}
        page={routerQueryParams.page}
        recordsTotal={inboxLeadCollectionTotalCount}
      />
      {inboxLeadCollectionTotalCount === 0 && !inboxLeadsCollectionQueryStatus.pending
        ? <LeadInboxEmpty />
        : (<>
          <PageBlock>
            <InboxLeadListTable
              items={inboxLeadCollection}
              isPending={inboxLeadsCollectionQueryStatus.pending}
              toggleAssignLeadModal={actions.toggleAssignLeadModal}
              toggleModal={actions.toggleModal}
              setChosenInboxLead={actions.setChosenInboxLead}
              toggleDeclineLeadModal={actions.toggleDeclineLeadModal}
            />
          </PageBlock>
          {shouldShowPagination && (
            <PageBlock className={'c-inbox-lead-list__pagination'}>
              <Pagination
                page={routerQueryParams.page}
                recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
                recordsTotal={inboxLeadCollectionTotalCount}
                onPageChange={onPageChange}
              />
            </PageBlock>
          )}

          <LeadListAssignModal
            isModalOpen={isModalOpen && isAssignLeadModalOpen}
            toggleAssignLeadModal={actions.toggleAssignLeadModal}
            toggleModal={actions.toggleModal}
            chosenLead={chosenInboxLead}
            partnerEmployeeList={partnerEmployeeList}
            assignPartner={actions.assignPartnerToLead}
            userProfileSalesforceContactId={userProfileSalesforceContactId}
            isPending={assignLeadQueryStatus.pending}
            isError={assignLeadQueryStatus.error}
          />
          <LeadListDeclineModal
            isModalOpen={isModalOpen && isDeclineLeadModalOpen}
            toggleDeclineLeadModal={actions.toggleDeclineLeadModal}
            toggleModal={actions.toggleModal}
            chosenInboxLead={chosenInboxLead}
            declineLead={actions.declineLead}
            isPending={declineLeadQueryStatus.pending}
            isError={declineLeadQueryStatus.error}
          />
        </>)}
    </Container>
  );
};

export const LeadInbox = connect(mapStateToProps, mapDispatchToProps)(LeadInboxComponent);
