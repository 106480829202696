import { isNil } from 'lodash/fp';

export const arraify = <T extends any>(maybeArray?: T | T[]): T[] =>
  maybeArray !== undefined && maybeArray !== null
    ? Array.isArray(maybeArray) ? maybeArray : [maybeArray]
    : [];

export const findElementById = <T extends { id: string }>(array: T[], id: string) =>
  array.find(e => e.id === id);

export const searchByKey = (key: string, value: any, inputArray: any, returnValue?: string) => {
  for (const item of inputArray) {
    if (item[key] === value) {
      return returnValue ? item[returnValue] : item;
    }
  }
};

export const fillArrayWithNumber = (length: number, str = false) =>
  Array(length)
    .fill(0)
    .map((_, i) => str ? (i + 1).toString() : i + 1);

export const filterByValue = <T extends any>(items: T[], key: string) => (value: string) =>
  items
    .filter((item: T) => !!item[key] && item[key].toLowerCase().indexOf(value.toLowerCase()) > -1);

export const sortAlphabetically = (key: string) => (a: object, b: object) => {
  const valA = a[key].toUpperCase();
  const valB = b[key].toUpperCase();
  return valA < valB ? -1 : valA > valB ? 1 : 0;
};

export const sort = (key: string) => (a: object, b: object) => {
  const valA = a[key];
  const valB = b[key];
  return valA < valB ? -1 : valA > valB ? 1 : 0;
};

export const sortByDate = (key: string) => (a: object, b: object) => {
  const dateA = new Date(a[key]);
  const dateB = new Date(b[key]);
  return dateA.valueOf() - dateB.valueOf();
};

export const isNotNil = <T, >(value: T | null | undefined): value is T => !isNil(value);

export const insertIf = <T extends any>(condition: boolean, ...elements: T[]) =>
  condition ? elements : [];

export const getValueArrayFromEnum = <T, >(E: T): T[] =>
  Object.entries(E).map(([, value]) => value);
