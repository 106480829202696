import { CaseReportError } from '+app/shared/components/CaseReportError';
import { CaseReportSuccess } from '+app/shared/components/CaseReportSuccess';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { EventCategory, FormName } from '+shared/GoogleTagManager';
import {
  CaseActions,
  getCreateCaseQueryStatus,
  getCreatedCaseNumber,
} from '+shared/store/case';
import { 
  getClaimBatteryData,
} from '+shared/store/partner/partner.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileSalesforceContactId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { MissingBatteryCaseForm } from '../../components/MissingBatteryCaseForm';

const mapStateToProps = (state: StoreState) => ({
  claimBatteryData: getClaimBatteryData(state),
  caseCreateQueryStatus: getCreateCaseQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
});

const mapDispatchToProps = mapActions({
  createCase: CaseActions.createCase,
  clearCreatedCase: CaseActions.clearCreatedCase,
});

interface CaseReportingProps {
  hideCaseReporting: () => void;
  closeMissingBatteryModal: () => void;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & CaseReportingProps
  ;

export const MissingBatteryCaseReportingComponent: React.FC<Props> = ({
  claimBatteryData,
  caseCreateQueryStatus,
  hideCaseReporting,
  closeMissingBatteryModal,
  contactId,
  caseNumber,
  actions,
}) => {
  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (caseCreateQueryStatus.success) { return setFormState(FormState.FORM_SUCCESS); }
    if (caseCreateQueryStatus.error) { return setFormState(FormState.FORM_ERROR); }
    return setFormState(FormState.FORM);
  }, [caseCreateQueryStatus]);

  const onTryAgain = () => {
    actions.clearCreatedCase();
    setFormState(FormState.FORM);
  };

  const gtmProps = {
    category: EventCategory.MISSING_BATTERY,
    formName: FormName.REPORT_CASE,
    queryStatus: caseCreateQueryStatus,
  };

  const gtmErrorProps = {
    ...gtmProps,
    failureMessage: I18n.t(T.report.error.headline),
  };

  return (
    <>
      {{[FormState.FORM]:
        <MissingBatteryCaseForm
          claimBatteryData={claimBatteryData}
          hideCaseReporting={hideCaseReporting}
          submitCaseForm={actions.createCase}
          isQueryPending={caseCreateQueryStatus.pending}
          contactId={contactId}
        />,
        [FormState.FORM_ERROR]:
          <CaseReportError
            onTryAgain={onTryAgain}
            gtm={gtmErrorProps}
          />,
        [FormState.FORM_SUCCESS]:
          <CaseReportSuccess
            onClose={closeMissingBatteryModal}
            caseNumber={caseNumber}
            gtm={gtmProps}
          />,
      }[formState]}
    </>
  );
};

export const MissingBatteryCaseReporting =
  connect(mapStateToProps, mapDispatchToProps)(MissingBatteryCaseReportingComponent);
