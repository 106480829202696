import { CaseAggregatedStatus, CaseStatus } from '+shared/store/case';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { StatusTileColors } from '../StatusTile';

export const setCaseStatusAggregated = (status: CaseAggregatedStatus) => (
  {[CaseAggregatedStatus.OPEN]: I18n.t(T.cases.statusFilter.open),
    [CaseAggregatedStatus.IN_PROGRESS]: I18n.t(T.cases.statusFilter.inProgress),
    [CaseAggregatedStatus.RESOLVED]: I18n.t(T.cases.statusFilter.resolved),
  }[status]
);

export const setCaseStatusAggregatedColor = (status: CaseAggregatedStatus) => (
  {[CaseAggregatedStatus.OPEN]: StatusTileColors.BLUE,
    [CaseAggregatedStatus.IN_PROGRESS]: StatusTileColors.YELLOW,
    [CaseAggregatedStatus.RESOLVED]: StatusTileColors.GREEN,
  }[status]
);

export const setCaseStatus = (status: CaseStatus) => (
  {[CaseStatus.OPEN]: I18n.t(T.cases.statusName.open),
    [CaseStatus.WAITING_FOR_CUSTOMER_REPLY]: I18n.t(T.cases.statusName.waitingForCustomerReply),
    [CaseStatus.WAITING_FOR_EXTERNAL_SERVICE]: I18n.t(T.cases.statusName.waitingForExternalService),
    [CaseStatus.WAITING_FOR_INTERNAL_PROCESSING]: I18n.t(T.cases.statusName.waitingForInternalService),
    [CaseStatus.WAITING_FOR_FIELD_SERVICE]: I18n.t(T.cases.statusName.waitingForFieldService),
    [CaseStatus.WAITING_FOR_SPARE_PART_DELIVERY]: I18n.t(T.cases.statusName.waitingForSparePartDelivery),
    [CaseStatus.OBSERVE]: I18n.t(T.cases.statusName.observe),
    [CaseStatus.DONE]: I18n.t(T.cases.statusName.done),
    [CaseStatus.CANCELLED]: I18n.t(T.cases.statusName.cancelled),
    [CaseStatus.CLOSED]: I18n.t(T.cases.statusName.closed),
    [CaseStatus.NEW]: I18n.t(T.cases.statusName.new),
  }[status]
);
