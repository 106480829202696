import { arraify } from '+app/utils';
import { HttpResponseError } from '@coolio/http';
import { Action } from 'redux';
import { concat, Observable, of, OperatorFunction } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { QueryActions } from '../../shared/store/query';

type ObservableFunction<Result, Arg1 = undefined, Arg2 = undefined, Arg3 = undefined> = (
  arg1: Arg1,
  arg2?: Arg2,
  arg3?: Arg3,
) => Observable<Result>;

type ExtraProcessQueryParams<Input, Response> = {
  onSuccess?: ObservableFunction<any, Response, Input> | Array<ObservableFunction<any, Response, Input>>;
  onFailure?: ObservableFunction<any, HttpResponseError, Input>
  | Array<ObservableFunction<any, HttpResponseError, Input>>;
};

type MakeQueryParams<Response> = {
  call: () => Observable<Response>
  onSuccess?: ObservableFunction<any, Response> | Array<ObservableFunction<any, Response>>;
  onFailure?: ObservableFunction<any, HttpResponseError>
  | Array<ObservableFunction<any, HttpResponseError>>;
};

/**
 * @deprecated use `makeQuery` instead
 */
export const processQuery = <Input, Response>(
  queryName: string,
  queryObservable: ObservableFunction<Response, Input>,
  { onSuccess, onFailure }: ExtraProcessQueryParams<Input, Response> = {},
): OperatorFunction<Input, undefined> =>
  mergeMap((input: Input) => concat(
    of(QueryActions.pending(queryName)),
    queryObservable(input)
      .pipe(
        mergeMap((response: Response) => concat(
          ...arraify(onSuccess).map(func => func(response, input)),
          of(QueryActions.success(queryName, response)),
        )),
        catchError(error => concat(
          ...arraify(onFailure).map(func => func(error, input)),
          of(QueryActions.failure(queryName, error)),
        )),
      ),
    ),
  );

export const makeQuery = (queryName: string) =>
  <Response extends unknown>({ call, onSuccess, onFailure }: MakeQueryParams<Response>): Observable<Action> =>
    concat(
      of(QueryActions.pending(queryName)),
      call().pipe(
        mergeMap(response => concat(
          ...arraify(onSuccess).map(func => func(response)),
          of(QueryActions.success(queryName, response)),
        )),
        catchError(error => concat(
          ...arraify(onFailure).map(func => func(error)),
          of(QueryActions.failure(queryName, error)),
        )),
      ),
    );
