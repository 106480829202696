import { getStatus } from '+shared/store/query/query.utils';
import { AxisOrientation, BarChartView, DataContainer, DataContainerTooltipExtension, GridLines } from '@kanva/charts';
import { AxisView, BarChartView as BarChartViewComponent, ChartGridView } from '@kanva/charts-react';
import { Point, rgba, TextAlign, View } from '@kanva/core';
import { Kanva, View as ViewComponent } from '@kanva/react';
import { 
  barChartGridPaint,
  BarChartSeries,
  barChartStyle,
  barChartTimeLabelAccessor,
  barChartxAxisStyle,
  barChartyAxisLabelPaint,
  CHART_FONT,
  labelAccessorEvery,
  Loader,
  MediaQuery,
  resolveBarXPosition,
  SharedChartColors,
  StatisticsSelectedDate,
  TimeUnit,
  Views,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { compose } from 'redux';
import { mapDataSeries } from '../../store/helpers/+analysisStatistics.helpers';
import { setYAxisPrecision } from './AnalysisBarChart.helper';
import { mobileLayout, webLayout } from './AnalysisBarChart.layout';

import './AnalysisBarChart.component.scss';

interface Props {
  dataSeries: BarChartSeries;
  statisticsSelectedDate: StatisticsSelectedDate;
  queryStatus: ReturnType<typeof getStatus>;
  tooltipExtension: DataContainerTooltipExtension | undefined;
  onMount: (x: number) => (view: View<any>) => void;
}

export class AnalysisBarChart extends React.PureComponent<Props> {
  readonly dataContainer = new DataContainer<Point>()
    .setYAxisParameters({
      tickCount: 5,
      roundTo: 1000,
      labelAccessor: (value: number) => (value / 1000) + ' kWh',
    });

  setDataSeries = (data: ReturnType<typeof mapDataSeries>, period: TimeUnit) => {
    this.dataContainer
      .setData(data)
      .setXAxisParameters({
        isGrouped: true,
        labelAccessor: compose(
          barChartTimeLabelAccessor(period),
          labelAccessorEvery(
            period === TimeUnit.MONTH || period === TimeUnit.DAY
              ? 3
              : 1,
          ),
        ),
      });
  };

  handleMount = (view: View<any>) => {
    const { onMount, statisticsSelectedDate  } = this.props;
    const position = resolveBarXPosition(this.dataContainer, statisticsSelectedDate);
    const { absoluteX } = (view as BarChartView).getCanvasPositionForPoint({ x: position, y: 0 });
    onMount(absoluteX)(view);
  };

  componentDidMount() {
    const { tooltipExtension, dataSeries, statisticsSelectedDate } = this.props;
    const data = mapDataSeries(dataSeries);

    if (tooltipExtension) {
      this.dataContainer.addExtension(tooltipExtension);
    }

    setYAxisPrecision(statisticsSelectedDate, this.dataContainer);
    this.setDataSeries(data, statisticsSelectedDate.period);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { dataSeries, statisticsSelectedDate } = this.props;
    
    if (prevProps.dataSeries !== dataSeries) {
      setYAxisPrecision(statisticsSelectedDate, this.dataContainer);
      const data = mapDataSeries(dataSeries);
      this.setDataSeries(data, statisticsSelectedDate.period);
    }
  }

  componentWillUnmount() {
    if (this.props.tooltipExtension) {
      this.dataContainer.removeExtension(this.props.tooltipExtension);
    }
  }

  render() {
    const desktopChart = {
      borders: { xAxis: 3, yAxis: 3 },
      layout: webLayout,
      yLabel: barChartyAxisLabelPaint.clone(),
    };
    const mobileChart = {
        borders: { xAxis: 1, yAxis: 1 },
        layout: mobileLayout,
        yLabel: barChartyAxisLabelPaint.clone()
          .setFont({
            ...CHART_FONT,
            fontSize: 8,
          }),
      };

    return (
      <Media query={MediaQuery.UP_SM}>
        {(isDesktop: boolean) => (
          <div className={'c-analysis-bar-chart'}>
            <Kanva
              className={'c-analysis-bar-chart__canvas'}
              enablePointerEvents={true}
            >
              <ViewComponent
                layoutParams={isDesktop 
                  ? desktopChart.layout.chartWrapper
                  : mobileChart.layout.chartWrapper
                }
                borderColor={rgba(SharedChartColors.BLACK, .1)}
              >
                <ChartGridView
                  layoutParams={isDesktop
                    ? desktopChart.layout.grid
                    : mobileChart.layout.grid
                  }
                  dataContainer={this.dataContainer}
                  gridLines={GridLines.HORIZONTAL}
                  style={{ paint: barChartGridPaint }}
                />
                <BarChartViewComponent
                  layoutParams={isDesktop
                    ? desktopChart.layout.barChart
                    : mobileChart.layout.barChart
                  }
                  dataContainer={this.dataContainer}
                  style={barChartStyle}
                  onMount={this.handleMount}
                />
              </ViewComponent>
              <AxisView
                id={Views.X_AXIS}
                border={{ top: isDesktop
                  ? desktopChart.borders.xAxis
                  : mobileChart.borders.xAxis,
                }}
                borderColor={rgba(SharedChartColors.DARK, .1)}
                layoutParams={isDesktop 
                  ? desktopChart.layout.xAxis
                  : mobileChart.layout.xAxis
                }
                dataContainer={this.dataContainer}
                orientation={AxisOrientation.HORIZONTAL}
                style={barChartxAxisStyle}
              />
              <AxisView
                id={Views.Y_AXIS}
                border={{ left: isDesktop
                  ? desktopChart.borders.yAxis
                  : mobileChart.borders.yAxis,
                }}
                borderColor={rgba(SharedChartColors.DARK, .1)}
                layoutParams={isDesktop
                  ? desktopChart.layout.yAxis
                  : mobileChart.layout.yAxis
                }
                dataContainer={this.dataContainer}
                orientation={AxisOrientation.VERTICAL}
                style={{
                  labelPaint: isDesktop
                  ? desktopChart.yLabel.clone().setTextAlign(TextAlign.LEFT)
                  : mobileChart.yLabel.clone().setTextAlign(TextAlign.LEFT),
                }}
              />
            </Kanva>
            {/* TODO: create component for this loader (bar chart and day chart) */}
            <div className={classNames('c-analysis-bar-chart__loader-container', {
              'is-active': this.props.queryStatus.pending,
            })}>
              <Loader className={'c-analysis-bar-chart__loader'} />
            </div>
          </div>
        )}
      </Media>
    );
  }
}
