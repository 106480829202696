import { Icon } from '@sonnen/shared-web/src/components/Icon';
import { IconProps } from '@sonnen/shared-web/src/components/Icon/models/Icon.model';
import * as React from 'react';

import './IconWarningCircle.component.scss';

export const IconWarningCircleComponent = ({ color = '#fff', width = 14, height = 14 }: IconProps) => (
  <div className="c-warning-in-circle">
      <Icon.Warning color={color} width={width} height={height} />
  </div>
);
