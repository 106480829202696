import { ActionsUnion, createAction } from '+app/utils';

export enum NEWS_MAIN_ACTIONS {
  SET_ACTIVE_NEWS_ID = '[NewsMain] SET_ACTIVE_NEWS_ID',
}

export const NewsMainActions = {
  setActiveNewsId: createAction(
    NEWS_MAIN_ACTIONS.SET_ACTIVE_NEWS_ID,
    (activeNewsId: string) => ({activeNewsId}),
  ),
};

export type NewsMainActions = ActionsUnion<typeof NewsMainActions>;
