import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { BatteryElectricUnits, BatteryWithProduct } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeBatteryTechSpecsDetailList } from './BatteryTechSpecsCard.helper';

interface Props {
  battery: BatteryWithProduct;
  batteryElectricUnits: BatteryElectricUnits[];
  queryStatus: ReturnType<typeof getStatus>;
}

export const BatteryTechSpecsCard: React.SFC<Props> = ({ battery, batteryElectricUnits, queryStatus }) => (
  <Widget
    icon={<Icofont type={'sonnenbatterie-with-lupe'} />}
    heading={I18n.t(T.customerSingle.batteryDetails.techSpecs.headline)}
  >
    {queryStatus.pending && (
      <Loader className={'c-battery-live-card__loader'} />
    )}
    {queryStatus.success && battery && (
      <DetailList
        vertical={true}
        list={factorizeBatteryTechSpecsDetailList(battery, batteryElectricUnits)}
        filterNil={true}
      />
    )}
    {queryStatus.error && (
      <WidgetWarning
        type={'warning'}
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.battery.error)}
      </WidgetWarning>
    )}
  </Widget>
);
