import { first, isEmpty } from 'lodash/fp';
import { createSelector } from 'reselect';
import { getStatus } from '../query/query.utils';
import { StoreState } from '../store.interface';
import { GET_YOUTUBE_VIDEO_LIST_QUERY } from './youtube.state';

export const getYoutube = (state: StoreState) => state.shared.youtube;

export const getYoutubeVideoList = createSelector(
  getYoutube,
  youtube => youtube.videoList,
);

export const isYoutubeVideoListEmpty = createSelector(
  getYoutubeVideoList,
  videoList => isEmpty(videoList),
);

export const getFirstYoutubeVideo = createSelector(
  getYoutubeVideoList,
  videoList => first(videoList),
);

export const getYoutubeVideoQueryStatus = createSelector(
  getYoutube,
  youtube => getStatus(youtube[GET_YOUTUBE_VIDEO_LIST_QUERY]),
);
