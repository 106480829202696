import { Form, FormInput } from '+shared/components';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Card, PageHeadline, PageSubheadline, WizardContext } from '@sonnen/shared-web';
import { Formik } from 'formik';
import { defaultTo } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { ConsumptionForm } from '../../store/+impactAnalysis.interface';
import { getConsumptionFormForView } from '../../store/+impactAnalysis.selectors';
import { ImpactAnalysisConsumptionFormSchema } from '../../store/schemas';
import { defaultValues, formFields } from './LeadImpactAnalysisConsumption.helper';

import './LeadImpactAnalysisConsumption.component.scss';

const mapDispatchToProps = mapActions({
  setConsumption: ImpactAnalysisActions.setConsumptionForm,
});

const mapStateToProps = (state: StoreState) => ({
  consumption: getConsumptionFormForView(state),
});

type Props = ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>;

const LeadImpactAnalysisConsumptionComponent: React.FC<Props> = ({
  actions,
  consumption,
}) => {
  const wizard = React.useContext(WizardContext);

  const onSubmit = (values: ConsumptionForm) => {
    actions.setConsumption(values);
    if (wizard.next) {
      wizard.next();
    }
  };

  const initialValues = defaultTo(defaultValues)(consumption);

  return (

    <Formik<ConsumptionForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ImpactAnalysisConsumptionFormSchema}
      render={form => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <PageHeadline>
              {I18n.t(T.lead.impactAnalysis._salessolution_.consumption.title)}
            </PageHeadline>

            <PageSubheadline noBorder={true}>
              {I18n.t(T.lead.impactAnalysis._salessolution_.consumption.electricityCostDetails)}
            </PageSubheadline>

            <div className={'c-impact-analysis-consumption-input-group'}>
              <div className={'c-impact-analysis-consumption__column'} >
                <FormInput
                  label={I18n.t(T.lead.impactAnalysis._salessolution_.consumption.currentHouseholdElectricity)}
                  className={'c-impact-analysis-consumption-input'}
                  type={'number'}
                  unit={I18n.t(T.units.centsPerKwh)}
                  name={formFields.CURRENT_TARIFF_GROSS}
                  form={form}
                />
              </div>
              <div className={'c-impact-analysis-consumption__column'} >
                <FormInput
                  label={I18n.t(T.lead.impactAnalysis._salessolution_.consumption.currentElectricityBaseFee)}
                  className={'c-impact-analysis-consumption-input'}
                  type={'number'}
                  unit={I18n.t(T.units.currencyPerMonth, { value: '€' })}
                  name={formFields.CURRENT_ELECTRICITY_COSTS_GROSS}
                  form={form}
                />
              </div>
            </div>

            <PageSubheadline noBorder={true}>
              {I18n.t(T.lead.impactAnalysis._salessolution_.consumption.electricityPriceIncrease)}
            </PageSubheadline>

            <div className={'c-impact-analysis-consumption__column'} >
              <FormInput
                type={'number'}
                className={'c-impact-analysis-consumption-input'}
                unit={I18n.t(T.units.percentPerYear)}
                label={I18n.t(
                  T.lead.impactAnalysis._salessolution_.consumption.expectedElectricityPriceIncreasePerYear,
                )}
                name={formFields.AVERAGE_ELECTRICITY_PRICE_INCREASE}
                form={form}
              />
            </div>
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadImpactAnalysisConsumption = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadImpactAnalysisConsumptionComponent);
