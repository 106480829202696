import { LeadCountry, LeadCustomerSalutation, LeadCustomerType, LeadDetailedAddress } from '+shared/store/lead/types';
import { specialLetters } from '+utils/string.util';
import { getCharactersTranslations } from '+utils/validation.util';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { LeadForm } from '../types';

const DELIVERY_STREET_MAX_CHAR = 244;
const DELIVERY_HOUSE_NUMBER_MAX_CHAR = 10;
const INVOICE_STREET_MAX_CHAR = 255;

const ZIP_CODE_MAX_CHAR = 5;
const ZIP_CODE_MIN_CHAR = 5;
const CITY_MAX_CHAR = 40;

const TITLE_MAX_CHAR = 255;
const FIRST_NAME_MAX_CHAR = 30;
const LAST_NAME_MAX_CHAR = 50;
const EMAIL_MAX_CHAR = 80;
const COMPANY_MAX_CHAR = 255;
const PHONE_MAX_CHAR = 36;

const requireOneOfTwo = <T1 extends any, T2>(first: T1, second: T2) =>
  isNil(first)
    ? !isNil(second)
    : true;

const cityRegExp = new RegExp(`^(^$|[a-zA-Z${specialLetters}/() -]+)$`);
const phoneRegExp = /^([ 0-9\+\-\(\)]+)$/;

export const DeliveryAddressAttributesSchema = () => {
  const charactersTranslations = getCharactersTranslations();

  return {
    street: Yup
      .string()
      .trim()
      .max(DELIVERY_STREET_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: DELIVERY_STREET_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    zipCode: Yup
      .string()
      .trim()
      .matches(/^(^$|[0-9]+)$/, I18n.t(T.validation.allowedCharacters, { characters: charactersTranslations.numbers }))
      .max(ZIP_CODE_MAX_CHAR, I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCodeError))
      .min(ZIP_CODE_MIN_CHAR, I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCodeError))
      .required(I18n.t(T.register.alert.requiredField)),
    city: Yup
      .string()
      .trim()
      .matches(cityRegExp,
        I18n.t(T.validation.allowedCharacters, {
          characters: `${charactersTranslations.letters}, - , /, (), ${charactersTranslations.space}`,
        }),
      )
      .max(CITY_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: CITY_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    country: Yup
      .string()
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
    houseNumber: Yup
      .string()
      .trim()
      .matches(
        /^(^$|[a-zA-Z0-9 \-\/]+)$/,
        I18n.t(T.validation.allowedCharacters, {
          characters: `${charactersTranslations.numbers},
        ${charactersTranslations.letters}, - , /, ${charactersTranslations.space}`,
        }),
      )
      .max(DELIVERY_HOUSE_NUMBER_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: DELIVERY_HOUSE_NUMBER_MAX_CHAR })),
  };
};

export const InvoiceAddressAttributesSchema = () => {
  const charactersTranslations = getCharactersTranslations();
  return {
    street: Yup
      .string()
      .trim()
      .max(INVOICE_STREET_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: INVOICE_STREET_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    zipCode: Yup
      .string()
      .trim()
      .matches(/^(^$|[0-9]+)$/, I18n.t(T.validation.allowedCharacters, { characters: charactersTranslations.numbers }))
      .max(ZIP_CODE_MAX_CHAR, I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCodeError))
      .min(ZIP_CODE_MIN_CHAR, I18n.t(T.lead.boc._salessolution_.form.personalDetails.zipCodeError))
      .required(I18n.t(T.register.alert.requiredField)),
    city: Yup
      .string()
      .trim()
      .matches(cityRegExp,
        I18n.t(T.validation.allowedCharacters, {
          characters: `${charactersTranslations.letters}, - , /, (), ${charactersTranslations.space}`,
        }),
      )
      .max(CITY_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: CITY_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    country: Yup
      .string()
      .trim()
      .required(I18n.t(T.register.alert.requiredField)),
  };
};

export const LeadCustomerTypeSchema = () =>
  Yup.object({
    customerType: Yup
      .string()
      .matches(/(private|business)/)
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const LeadTitleSchema = () =>
  Yup.object({
    title: Yup
      .string()
      .trim()
      .max(TITLE_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: TITLE_MAX_CHAR })),
  });

const CompanyAttributesSchema = () => ({
  name: Yup
    .string()
    .max(COMPANY_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: COMPANY_MAX_CHAR }))
    .required(I18n.t(T.register.alert.requiredField)),
});

export const LeadCompanySchema = () =>
  Yup.object({
    company: Yup
      .object(CompanyAttributesSchema()),
  });

export const LeadNameSchema = () =>
  Yup.object({
    salutation: Yup
      .string()
      .trim()
      .matches(/(mr|ms)/)
      .required(I18n.t(T.register.alert.requiredField)),
    firstName: Yup
      .string()
      .trim()
      .max(FIRST_NAME_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: FIRST_NAME_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    lastName: Yup
      .string()
      .trim()
      .max(LAST_NAME_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: LAST_NAME_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const LeadEmailSchema = () =>
  Yup.object({
    email: Yup
      .string()
      .trim()
      .matches(
        // @NOTE: currently supporting only ASCII characters in emails (no unicode support)
        /^[\x00-\x7F]+$/,
        I18n.t(T.lead.boc._salessolution_.form.personalDetails.nationalLettersValidation),
      )
      .max(EMAIL_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: EMAIL_MAX_CHAR }))
      .email(I18n.t(T.lead.boc._salessolution_.form.personalDetails.emailValidation))
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const LeadPhoneSchema = (leadMobilePhone?: string | null) => {
  const charactersTranslations = getCharactersTranslations();

  return (
    Yup.object({
      phone: Yup
        .string()
        .trim()
        .matches(phoneRegExp,
          I18n.t(T.validation.allowedCharacters, {
            characters: `${charactersTranslations.numbers}, +, - , (), ${charactersTranslations.space}`,
          }),
        )
        .max(PHONE_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: PHONE_MAX_CHAR }))
        .test(
          'has-mobile-phone',
          I18n.t(T.lead.boc._salessolution_.form.personalDetails.atLeastOnePhoneNumber),
          function (phone: string) {
            return requireOneOfTwo(
              leadMobilePhone || this.parent.mobilePhone,
              phone,
            );
          }),
    })
  );
};

export const LeadMobilePhoneSchema = (leadPhone?: string | null) => {
  const charactersTranslations = getCharactersTranslations();

  return (
    Yup.object({
      mobilePhone: Yup
        .string()
        .trim()
        .matches(phoneRegExp,
          I18n.t(T.validation.allowedCharacters, {
            characters: `${charactersTranslations.numbers}, +, - , (), ${charactersTranslations.space}`,
          }),
        )
        .max(PHONE_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: PHONE_MAX_CHAR }))
        .test(
          'has-phone',
          I18n.t(T.lead.boc._salessolution_.form.personalDetails.atLeastOnePhoneNumber),
          function (mobilePhone: string) {
            return requireOneOfTwo(
              leadPhone || this.parent.phone,
              mobilePhone,
            );
          }),
    })
  );
};

export const LeadDeliveryAdressSchema = () =>
  Yup.object({
    deliveryAddress: Yup
      .object(DeliveryAddressAttributesSchema()),
  });

export const LeadAutosuggestionAdressSchema = () =>
  Yup.object({
    autosuggestedAddress: Yup
      .string()
      .required(I18n.t(T.register.alert.requiredField)),
  });

export const LeadInvoiceAdressSchema = () =>
  Yup.object({
    invoiceAddress: Yup
      .object(InvoiceAddressAttributesSchema()),
  });

export const LeadCreationFormSchema = () => {
  const charactersTranslations = getCharactersTranslations();

  return (
    Yup.object()
      .concat(LeadNameSchema())
      .concat(LeadTitleSchema())
      .concat(LeadCustomerTypeSchema())
      .concat(LeadCompanySchema())
      .concat(LeadEmailSchema())
      .concat(LeadPhoneSchema())
      .concat(LeadMobilePhoneSchema())
      .concat(LeadDeliveryAdressSchema())
      .concat(LeadInvoiceAdressSchema())
      .concat(LeadAutosuggestionAdressSchema())
      .shape({
        company: Yup
          .string()
          .max(COMPANY_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: COMPANY_MAX_CHAR }))
          .when('customerType', {
            is: LeadCustomerType.BUSINESS,
            then: Yup
              .string()
              .required(I18n.t(T.register.alert.requiredField)),
          }),
        invoiceAddress: Yup
          .object()
          .when('hasDifferentInvoiceAddress', {
            is: true,
            then: Yup
              .object()
              .shape(InvoiceAddressAttributesSchema())
              .required(I18n.t(T.register.alert.requiredField)),
          }),
        hasDifferentInvoiceAddress: Yup.boolean(),
        agreedToGdpr: Yup
          .boolean()
          .oneOf([true], I18n.t(T.register.alert.requiredField)),
        agreedToMarketingMailing: Yup
          .boolean(),
      })
  );
};

export const leadAddressInitial: LeadDetailedAddress = {
  street: '',
  houseNumber: '',
  zipCode: '',
  city: '',
  country: LeadCountry.DE,
};

export const leadFormInitial: LeadForm = {
  customerType: LeadCustomerType.PRIVATE,
  salutation: LeadCustomerSalutation.MR,
  title: '',
  company: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobilePhone: '',
  deliveryAddress: leadAddressInitial,
  invoiceAddress: leadAddressInitial,
  hasDifferentInvoiceAddress: false,
  agreedToGdpr: false,
  agreedToMarketingMailing: false,
  autosuggestedAddress: '',
};
