import { hasFlatOfferAcceptedStatus } from '+app/+lead/store/+lead.helper';
import {
  LeadCompanySchema,
  LeadDeliveryAdressSchema,
  LeadEmailSchema,
  LeadInvoiceAdressSchema,
  LeadMobilePhoneSchema,
  LeadNameSchema,
  LeadPhoneSchema,
  LeadTitleSchema,
} from '+lead/store/schemas';
import { ModalId } from '+shared/store/layout';
import { countries, salutation } from '+shared/store/lead';
import {
  Lead,
  LeadAddress,
  LeadCustomerSalutation,
  LeadCustomerType,
  LeadDso,
} from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { T } from '@sonnen/shared-i18n/service';
import { isEmpty, isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';

export const isLeadDataMissing = (lead: Lead) => getLeadDetailsList(lead)
  .map((el) => el.isFilledField !== undefined ? !el.isFilledField : false)
  .reduce((acc, el) => acc || el);

export enum LeadDetailsSection {
  CUSTOMER_TYPE = 'customer-type',
  TITLE = 'title',
  NAME = 'name',
  COMPANY_NAME = 'company-name',
  CUSTOMER_NUMBER = 'customer-number',
  PHONE = 'phone',
  MOBILE_PHONE = 'mobile-phone',
  EMAIL = 'email',
  DSO = 'dso',
  DELIVERY_ADDRESS = 'delivery-address',
  INVOICE_ADDRESS = 'invoice-address',
}

const nilToEmptyString = (value: string | null) => isNil(value) ? '' : value;

const isDsoEditable = (lead: Lead, dsoList?: LeadDso[]) => {
  const isMultipleDsoAvailable = dsoList && dsoList.length > 1;

  return lead.dso ? isMultipleDsoAvailable : !isEmpty(dsoList);
};

export const getLeadDetailsList = (
  lead: Lead,
  showDso: boolean = true,
  dsoList?: LeadDso[],
  toggleModal?: (isModalOpen: boolean, openModalId?: ModalId) => void,
) => ([
  // CUSTOMER TYPE
  {
    id: LeadDetailsSection.CUSTOMER_TYPE,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.type),
    value: lead.customerType
      ? I18n.t(T.lead.boc._salessolution_.dictionary.customerType[lead.customerType])
      : lead.customerType,
  },
  // TITLE
  {
    id: LeadDetailsSection.TITLE,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.title),
    value: lead.title,
    editableFields: { title: lead.title },
    validationSchema: LeadTitleSchema,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
  },
  // NAME
  {
    id: LeadDetailsSection.NAME,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.name),
    value: lead.firstName && lead.lastName && lead.salutation
      ? `${
      I18n.t(T.lead.boc._salessolution_.dictionary.salutation[lead.salutation])} ${lead.firstName} ${lead.lastName}`
      : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField),
    editableFields: {
      salutation: lead.salutation ? lead.salutation : LeadCustomerSalutation.MR,
      firstName: nilToEmptyString(lead.firstName),
      lastName: nilToEmptyString(lead.lastName),
    },
    options: {
      salutation: {
        values: salutation,
        translation: T.lead.boc._salessolution_.dictionary.salutation,
      },
    },
    warning: {
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.offerUpdated),
    },
    validationSchema: LeadNameSchema,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
    isFilledField: !!lead.firstName && !!lead.lastName,
  },
  // COMPANY NAME
  {
    id: LeadDetailsSection.COMPANY_NAME,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.company),
    value: lead.customerType === LeadCustomerType.BUSINESS ?
      lead.company
        ? lead.company.name
        : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField)
      : null,
    editableFields: lead.company && { company: { name: nilToEmptyString(lead.company.name) } },
    warning: {
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.offerUpdated),
    },
    validationSchema: LeadCompanySchema,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
    isFilledField: lead.company && !!lead.company.name,
  },
  // CUSTOMER NUMBER
  {
    id: LeadDetailsSection.CUSTOMER_NUMBER,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.customerNumber.title),
    value: lead.customerNumber,
    tooltipText: I18n.t(T.lead.boc._salessolution_.form.personalDetails.customerNumber.tooltip),
    isTooltipWide: true,
  },
  // PHONE
  {
    id: LeadDetailsSection.PHONE,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.phone),
    value: lead.phone
      ? lead.phone
      : lead.mobilePhone ? '-'
        : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField),
    editableFields: { phone: nilToEmptyString(lead.phone) },
    warning: {
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.offerUpdated),
    },
    validationSchema: LeadPhoneSchema(lead.mobilePhone),
    isFilledField: !!lead.phone || !!lead.mobilePhone,
  },
  // MOBILE PHONE
  {
    id: LeadDetailsSection.MOBILE_PHONE,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.mobilePhone),
    value: lead.mobilePhone
      ? lead.mobilePhone
      : lead.phone ? '-'
        : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField),
    editableFields: { mobilePhone: nilToEmptyString(lead.mobilePhone) },
    warning: {
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.offerUpdated),
    },
    validationSchema: LeadMobilePhoneSchema(lead.phone),
    isFilledField: !!lead.phone || !!lead.mobilePhone,
  },
  // EMAIL
  {
    id: LeadDetailsSection.EMAIL,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.email),
    value: lead.email ? lead.email : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField),
    editableFields: { email: nilToEmptyString(lead.email) },
    validationSchema: LeadEmailSchema,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
    isFilledField: !!lead.email,
  },
  // DSO
  {
    id: LeadDetailsSection.DSO,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.dso),
    value: lead.dso
      ? lead.dso.name
      : !hasFlatOfferAcceptedStatus(lead)
        ? I18n.t(T.lead.boc._salessolution_.form.personalDetails.noDsoChosen)
        : undefined,
    tooltipText: lead.dso && dsoList && (dsoList.length === 1)
      ? I18n.t(T.lead.boc._salessolution_.form.personalDetails.dsoTooltip)
      : undefined,
    editableFields: isDsoEditable(lead, dsoList) ? { dsoId: '' } : undefined,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
    isFilledField: !hasFlatOfferAcceptedStatus(lead) ? !!lead.dso : undefined,
    customEditHandle: toggleModal ? () => toggleModal(true, ModalId.DSO_VALIDATION) : undefined,
  },
  // DELIVERY ADDRESS
  {
    id: LeadDetailsSection.DELIVERY_ADDRESS,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.deliveryAddress),
    value: lead.deliveryAddress
      && lead.deliveryAddress.zipCode
      && lead.deliveryAddress.city
      && lead.deliveryAddress.street
      ? `${lead.deliveryAddress.zipCode} ${lead.deliveryAddress.city}, ${lead.deliveryAddress.country}
      \n${lead.deliveryAddress.street}`
      : I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryField),
    editableFields: lead.deliveryAddress && {
      deliveryAddress: {
        zipCode: nilToEmptyString(lead.deliveryAddress.zipCode),
        city: nilToEmptyString(lead.deliveryAddress.city),
        country: lead.deliveryAddress.country,
        street: nilToEmptyString(lead.deliveryAddress.street),
      },
    },
    options: {
      country: {
        values: countries,
        translation: T.lead.boc._salessolution_.dictionary.countries,
      },
    },
    warning: {
      configurationMade: I18n.t(T.lead.overview._salessolution_.editForm.warnings.recalculated),
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.recalculatedOfferSent),
    },
    validationSchema: LeadDeliveryAdressSchema,
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
    isFilledField: lead.deliveryAddress
      && !!lead.deliveryAddress.zipCode
      && !!lead.deliveryAddress.city
      && !!lead.deliveryAddress.street,
  },
  // INVOICE ADDRESS
  {
    id: LeadDetailsSection.INVOICE_ADDRESS,
    label: I18n.t(T.lead.boc._salessolution_.form.personalDetails.invoiceAddress),
    value: lead.invoiceAddress
      ? `${lead.invoiceAddress.zipCode} ${lead.invoiceAddress.city}, ${lead.invoiceAddress.country}
        \n${lead.invoiceAddress.street}`
      : null,
    editableFields: lead.invoiceAddress && {
      invoiceAddress: {
        zipCode: nilToEmptyString(lead.invoiceAddress.zipCode),
        city: nilToEmptyString(lead.invoiceAddress.city),
        country: lead.invoiceAddress.country,
        street: nilToEmptyString(lead.invoiceAddress.street),
      },
    },
    warning: {
      offerSent: I18n.t(T.lead.overview._salessolution_.editForm.warnings.offerUpdated),
    },
    validationSchema: LeadInvoiceAdressSchema,
    options: {
      country: {
        values: countries,
        translation: T.lead.boc._salessolution_.dictionary.countries,
      },
    },
    editableUntilStatus: LeadStatusName.FLAT_OFFER_ACCEPTED,
  },
].filter(elem => (showDso || elem.id !== LeadDetailsSection.DSO)));

// @TODO: as values are converted from Nil to Empty String, function need to be updated to work properly
export const isFullAddress = <T extends Partial<LeadAddress>>(value: T): value is Required<T> => {
  return ('street' in value) && ('city' in value) && ('country' in value) && ('zipCode' in value);
};

export const hasFullDeliveryAddress = <T extends { deliveryAddress: Partial<LeadAddress> } | null>(
  lead: T,
): lead is T & { deliveryAddress: LeadAddress } => {
  if (!lead) {
    return false;
  }
  const { kind, ...requriedAddressFields } = lead.deliveryAddress;
  const areSomeAddressFieldsEmpty = Object.values(requriedAddressFields).some(val => val === '');
  if (areSomeAddressFieldsEmpty) {
    return false;
  }
  return isFullAddress(lead.deliveryAddress);
};
