import { DetailListType } from '+shared/components';
import { SiteLiveState } from '+shared/store/site/types';
import { formatPower } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';

export const factorizeDetailList = (siteLiveState: SiteLiveState): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.overview.analysis.currentConsumption),
    value: !isNil(siteLiveState.consumptionPower)
      ? formatPower(siteLiveState.consumptionPower)
      : undefined,
  },
  {
    label: I18n.t(T.customerSingle.overview.analysis.currentProduction),
    value: !isNil(siteLiveState.productionPower)
      ? formatPower(siteLiveState.productionPower)
      : undefined,
  },
];
