import { DsoCommissioningActions, getDsoCommissioningProps } from '+app/+setupTool/+dsoCommissioning/store/';
import {
  DsoRegistrationFormActions,
  getBatteryNames,
  getBatteryNamesNonUniqueCount,
  getCustomerDataProps, getDocumentsFileProps,
  getInstallerDataProps,
  getInstallers,
  getMeasuringDeviceProps,
  getPvSystemProps,
  getSonnenBatteryProps,
  makeGetBatteries,
} from '+app/+setupTool/+form/store';
import { getPvRegisterProps, PVRegisterActions } from '+app/+setupTool/+pvRegister/store';
import {
  getAdditionalFeatureVisibilityProp,
  getVppDocumentationProps,
  getVppSubmissionStatusFromStepsUpdatedAt,
  VppDocumentationActions,
} from '+app/+setupTool/+vppDocumentation/store';
import { dataGuard, mapToState, sort, sortAlphabetically } from '+app/utils';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SetupDataStepKey } from '+setupTool/store/+setupTool.dictionary';
import { mapActionTypeToErrorMessage } from '+setupTool/store/+setupTool.helpers';
import { DsoRegisterActions } from '+shared/store/setupTool';
import { ofType } from '+utils/operators/ofType.operator';
import { processQuery } from '+utils/operators/processQuery.operator';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, iif, merge, of, range } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { StoreState } from '../store.interface';
import {
  getUniqueNames,
  mapBatteries,
  mapBatteryNames,
  mapPVInverters,
  mapPVInverterVendors,
  mapPVModules,
  mapPVModuleVendors,
} from './setupTool.helpers';
import { DsoRegisterRepository } from './setupTool.repository';
import { AdditionalFeatures } from './types/dsoRegistrationResponse.interface';

type Action$ = ActionsObservable<DsoRegisterActions>;
type State$ = StateObservable<StoreState>;

const clearDataBeforePostRegisterDso$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegisterActions.postRegisterDso),
  mapToState(state$),
  mergeMap((state) => of(
    DsoRegistrationFormActions.clearData(),
    SetupToolActions.clearConfigurationData(),
  )),
);

const postRegisterDso$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postRegisterDso),
  mergeMap(({ data, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.postRegisterDso(data), {
      onSuccess: res => {
        const customerDataProps = getCustomerDataProps(res!.data.fields);
        const pvSystemProps = getPvSystemProps(res!.data.fields, res!.data.registrationSubject);
        const sonnenBatteryProps = getSonnenBatteryProps(res!.data.fields, res!.data.registrationSubject);
        const measuringDeviceProps = getMeasuringDeviceProps(res!.data.fields);
        const documentsFileProps = getDocumentsFileProps(res!.data);
        const installerDataProps = getInstallerDataProps(res!.data.fields);
        const dsoCommissioningFieldsProps = getDsoCommissioningProps(res!.data);
        const pvRegisterProps = getPvRegisterProps(res!.data);
        const vppDocumentationProps = getVppDocumentationProps(res!.data);
        const vppSubmissionStatus = getVppSubmissionStatusFromStepsUpdatedAt(res!.data.stepsUpdatedAt);
        return merge(
          of(DsoRegisterActions.postRegisterDsoSuccess(res!)),
          dataGuard(DsoRegistrationFormActions.setDsoCustomerData)(customerDataProps),
          dataGuard(DsoRegistrationFormActions.setDsoPvSystem)(pvSystemProps),
          dataGuard(DsoRegistrationFormActions.setDsoSonnenBattery)(sonnenBatteryProps),
          dataGuard(DsoRegistrationFormActions.setDsoMeasuringDevice)(measuringDeviceProps),
          dataGuard(DsoRegistrationFormActions.setDsoDocumentsFile)(documentsFileProps),
          dataGuard(DsoRegistrationFormActions.setDsoInstallerData)(installerDataProps),
          dataGuard(DsoCommissioningActions.setFields)(dsoCommissioningFieldsProps),
          dataGuard(PVRegisterActions.init)(pvRegisterProps),
          dataGuard(VppDocumentationActions.setFields)(vppDocumentationProps),
          dataGuard(VppDocumentationActions.setSubmissionStatus)(vppSubmissionStatus),
          dataGuard(VppDocumentationActions.setDsoReferenceNumberPvVisibility)(
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.DSO_REFERENCE_NUMBER_PV_FIELD, res!.data),
          ),
          dataGuard(VppDocumentationActions.setCompletionNotificationVisibility)(
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.VPP_COMPLETION_NOTIFICATION, res!.data),
          ),
          dataGuard(VppDocumentationActions.setMeterCabinetPreparedField)(
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.METER_CABINET_PREPARED_FIELD, res!.data),
          ),
          dataGuard(VppDocumentationActions.setProductionMeterFieldVisibility)(
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.PRODUCTION_METER_FIELD, res!.data),
          ),
          dataGuard(VppDocumentationActions.setFasteningTypeConsumptionField)(
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.FASTENING_TYPE_CONSUMPTION_FIELD, res!.data),
          ),
          dataGuard(VppDocumentationActions.setVppAdditionalFeatures)(res!.data.vppAdditionalFeatures),
          of(SetupToolActions.setSubmissionId(res!.data.id)),
          of(SetupToolActions.setRegistrationSubjectType(res!.data.registrationSubject)),
          of(SetupToolActions.setVppCapability(res!.data.vppCapable)),
        );
      },
      onFailure: err => merge(
        of(DsoRegisterActions.postRegisterDsoFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.postRegisterDso.type))),
      ),
    }),
  )),
);

const postSendDsoDocument$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postSendDsoDocument),
  mergeMap(({ userId, customerId, submissionId, category, file, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.postSendDsoDocument(
      userId, customerId, submissionId, category, file), {
      onSuccess: res => {
        const submission = res.data;

        return merge(
          dataGuard(SetupToolActions.setUploadedDocuments)(submission.documents.filter((doc: any) => doc.status)),
          dataGuard(SetupToolActions.setGeneratedDocuments)(submission.documents.filter((doc: any) => doc.status)),
          of(DsoRegisterActions.postSendDsoDocumentSuccess(res)),
        );
      },
      onFailure: err => merge(
        dataGuard(SetupToolActions.getSetupToolSubmissionData)(customerId),
        of(DsoRegisterActions.postSendDsoDocumentFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.postSendDsoDocument.type))),
      ),
    }),
  )),
);

const removeDsoDocument$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.removeDsoDocument),
  mergeMap(({ submissionId, documentId, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.removeDsoDocument(
      submissionId, documentId), {
      onSuccess: res => {
        const submission = res.data;

        return merge(
          dataGuard(SetupToolActions.setUploadedDocuments)(submission.documents.filter((doc: any) => doc.status)),
          dataGuard(SetupToolActions.setGeneratedDocuments)(submission.documents.filter((doc: any) => doc.status)),
          of(DsoRegisterActions.removeDsoDocumentSuccess(res)),
        );
      },
      onFailure: err => merge(
        of(DsoRegisterActions.removeDsoDocumentFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.removeDsoDocument.type))),
      ),
    }),
  )),
);

const postRegisterPV$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postRegisterPV),
  mergeMap(({ submissionId, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.postRegisterPV(submissionId), {
      onSuccess: res => of(DsoRegisterActions.postRegisterPVSuccess(res)),
      onFailure: err => merge(
        of(DsoRegisterActions.postRegisterPVFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.postRegisterPV.type))),
      ),
    }),
  )),
);

const registerPvrManually$ = (action$: Action$) => action$.pipe(
  ofType(PVRegisterActions.registerPvrManually),
  mergeMap(({ submissionId, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.postRegisterPVManually(submissionId), {
      onSuccess: res => of(DsoRegisterActions.postRegisterPVSuccess(res)),
      onFailure: err => merge(
        of(DsoRegisterActions.postRegisterPVFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(PVRegisterActions.registerPvrManually.type))),
      ),
    }),
  )),
);

const postVpp$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postVpp),
  mergeMap(({ submissionId, queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.postVpp(submissionId), {
      onSuccess: res => of(DsoRegisterActions.postVppSuccess(res.data)),
      onFailure: err => merge(
        of(DsoRegisterActions.postVppFailure(err)),
        of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.postVpp.type))),
      ),
    }),
  )),
);

export const getDsoList$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getDsoList),
  mergeMap(({ queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getDsoList(), {
      onSuccess: res => of(
        DsoRegistrationFormActions.setDsoList(res!.dsos),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getDsoList.type),
      )),
    }),
  )),
);

export const getPVModuleVendors$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVModuleVendors),
  mergeMap(({ queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVModuleVendors(1), {
      onSuccess: res => iif(
        () => res.data.length < res.meta.totalItems,
        merge(
          of(DsoRegistrationFormActions.appendPVModuleVendors(mapPVModuleVendors(res.data))),
          range(2, Math.floor(res.meta.totalItems / res.meta.itemsPerPage)).pipe(
            map(pageNum => DsoRegistrationFormActions.getPVModuleVendorsPerPage(pageNum)),
          ),
        ),
        concat(
          of(DsoRegistrationFormActions.appendPVModuleVendors(mapPVModuleVendors(res.data))),
          of(DsoRegistrationFormActions.setPvModuleVendorsAllFetched(true)),
        ),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVModuleVendors.type),
      )),
    }),
  )),
);

export const getPVModuleVendorsPerPage$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVModuleVendorsPerPage),
  mergeMap(({ queryKey, pageNum }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVModuleVendors(pageNum), {
      onSuccess: res => concat(
        of(DsoRegistrationFormActions.appendPVModuleVendors(mapPVModuleVendors(res.data))),
        iif(
          () => pageNum >= Math.ceil(res.meta.totalItems / res.meta.itemsPerPage),
          of(DsoRegistrationFormActions.setPvModuleVendorsAllFetched(true)),
        ),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVModuleVendors.type),
      )),
    })),
  ),
);

export const getPVModules$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVModules),
  mergeMap(({ queryKey, vendorId }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVModules(vendorId, 1), {
      onSuccess: res => iif(
        () => res.data.length < res.meta.totalItems,
        merge(
          of(DsoRegistrationFormActions.appendPVModules(
            vendorId,
            mapPVModules(res.data).sort(sortAlphabetically('name'))),
          ),
          range(2, Math.floor(res.meta.totalItems / res.meta.itemsPerPage)).pipe(
            map(pageNum => DsoRegistrationFormActions.getPVModulesPerPage(vendorId, pageNum)),
          ),
        ),
        of(DsoRegistrationFormActions.appendPVModules(
          vendorId,
          mapPVModules(res.data).sort(sortAlphabetically('name')),
        )),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVModules.type),
      )),
    }),
  )),
);

export const getPVModulesPerPage$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVModulesPerPage),
  mergeMap(({ queryKey, vendorId, pageNum }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVModules(vendorId, pageNum), {
      onSuccess: res => of(DsoRegistrationFormActions.appendPVModules(
        vendorId,
        mapPVModules(res.data).sort(sortAlphabetically('name')),
      )),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVModules.type),
      )),
    })),
  ),
);

export const getPVInverterVendors$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVInverterVendors),
  mergeMap(({ queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVInverterVendors(1), {
      onSuccess: res => iif(
        () => res.data.length < res.meta.totalItems,
        merge(
          of(DsoRegistrationFormActions.appendPVInverterVendors(mapPVInverterVendors(res.data))),
          range(2, Math.floor(res.meta.totalItems / res.meta.itemsPerPage)).pipe(
            map(pageNum => DsoRegistrationFormActions.getPVInverterVendorsPerPage(pageNum)),
          ),
        ),
        concat(
          of(DsoRegistrationFormActions.appendPVInverterVendors(mapPVInverterVendors(res.data))),
          of(DsoRegistrationFormActions.setPvInverterVendorsAllFetched(true)),
        ),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVInverterVendors.type),
      )),
    }),
  )),
);

export const getPVInverterVendorsPerPage$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVInverterVendorsPerPage),
  mergeMap(({ queryKey, pageNum }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVInverterVendors(pageNum), {
      onSuccess: res => concat(
        of(DsoRegistrationFormActions.appendPVInverterVendors(mapPVInverterVendors(res.data))),
        iif(
          () => pageNum >= Math.ceil(res.meta.totalItems / res.meta.itemsPerPage),
          of(DsoRegistrationFormActions.setPvInverterVendorsAllFetched(true)),
        ),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVInverterVendors.type),
      )),
    })),
  ),
);

export const getPVInverters$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVInverters),
  mergeMap(({ queryKey, vendorId }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVInverters(vendorId, 1), {
      onSuccess: res => iif(
        () => res.data.length < res.meta.totalItems,
        merge(
          of(DsoRegistrationFormActions.appendPVInverters(
            vendorId,
            mapPVInverters(res.data).sort(sortAlphabetically('name'))),
          ),
          range(2, Math.floor(res.meta.totalItems / res.meta.itemsPerPage)).pipe(
            map(pageNum => DsoRegistrationFormActions.getPVInvertersPerPage(vendorId, pageNum)),
          ),
        ),
        of(DsoRegistrationFormActions.appendPVInverters(
          vendorId,
          mapPVInverters(res.data).sort(sortAlphabetically('name')),
        )),
      ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVInverters.type),
      )),
    }),
  )),
);

export const getPVInvertersPerPage$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getPVInvertersPerPage),
  mergeMap(({ queryKey, vendorId, pageNum }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getPVInverters(vendorId, pageNum), {
      onSuccess: res => of(DsoRegistrationFormActions.appendPVInverters(
        vendorId,
        mapPVInverters(res.data).sort(sortAlphabetically('name')),
      )),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getPVInverters.type),
      )),
    })),
  ),
);

// TODO refactor similar to PVModules and Inverters, so that the requests are sent in parallel and not in series
export const getBatteryNames$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getBatteryNames),
  withLatestFrom(state$),
  mergeMap(([{ queryKey, pageNum = 1 }, storeState]) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getBatteryNames(pageNum), {
      onSuccess: res =>
        iif(
          () => getBatteryNamesNonUniqueCount(storeState) + res.data.length < res.meta.totalItems,
          of(
            DsoRegistrationFormActions.setBatteryNames(
              getUniqueNames([...getBatteryNames(storeState), ...mapBatteryNames(res.data)]),
            ),
            DsoRegistrationFormActions.setBatteryNamesNonUniqueCount(
              getBatteryNamesNonUniqueCount(storeState) + res.data.length,
            ),
            DsoRegistrationFormActions.getBatteryNames(pageNum + 1),
          ),
          of(
            DsoRegistrationFormActions.setBatteryNames(
              getUniqueNames(
                [...getBatteryNames(storeState), ...mapBatteryNames(res.data)].sort(sortAlphabetically('name')),
              ),
            ),
            DsoRegistrationFormActions.setBatteryNamesNonUniqueCount(
              getBatteryNamesNonUniqueCount(storeState) + res.data.length,
            ),
          ),
        ),
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getBatteryNames.type),
      )),
    })),
  ),
);

// TODO refactor similar to PVModules and Inverters, so that the requests are sent in parallel and not in series
export const getBatteries$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getBatteries),
  withLatestFrom(state$),
  mergeMap(([{ queryKey, name, pageNum = 1 }, storeState]) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getBatteries(name, pageNum), {
      onSuccess: res => {
        const batteries = makeGetBatteries(name)(storeState);
        return iif(
          () => batteries
            ? batteries.length + res.data.length < res.meta.totalItems
            : res.data.length < res.meta.totalItems,
          of(
            DsoRegistrationFormActions.setBatteries(
              name,
              batteries ? [...batteries, ...mapBatteries(res.data)] : mapBatteries(res.data),
            ),
            DsoRegistrationFormActions.getBatteries(name, pageNum + 1),
          ),
          of(
            DsoRegistrationFormActions.setBatteries(
              name,
              batteries
                ? [...batteries, ...mapBatteries(res.data)].sort(sort('nominalCapacitykWh'))
                : mapBatteries(res.data).sort(sort('nominalCapacitykWh')),
            ),
          ),
        );
      },
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getBatteries.type),
      )),
    })),
  ),
);

export const getInstallers$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegistrationFormActions.getInstallers),
  mergeMap(({ queryKey }) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.getInstallers(), {
      onSuccess: res => {
        const modifiedResData = [...res.data].map(
          item => ({ ...item, fullName: item.firstName + ' ' + item.lastName }));
        return of(DsoRegistrationFormActions.setInstallers(modifiedResData));
      },
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.getInstallers.type),
      )),
    }),
  )),
);

export const submitInstaller$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationFormActions.submitInstaller),
  withLatestFrom(state$),
  mergeMap(([{ queryKey, values }, storeState]) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.submitInstaller(values), {
      onSuccess: res => {
        const modifiedResData = { ...res.data, fullName: res.data.firstName + ' ' + res.data.lastName };
        const existingInstallerList = getInstallers(storeState);
        return of(DsoRegistrationFormActions.setInstallers([...existingInstallerList, modifiedResData]));
      },
    }),
  )),
);

export const removeInstallers$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationFormActions.removeInstallers),
  withLatestFrom(state$),
  mergeMap(([{ queryKey, installerIds }, storeState]) => of({}).pipe(
    processQuery(queryKey, () => DsoRegisterRepository.removeInstallers(installerIds), {
      onSuccess: res => {
        const modifiedResData = [...res.data]
          .map(item => ({ ...item, fullName: item.firstName + ' ' + item.lastName }));
        return of(DsoRegistrationFormActions.setInstallers(modifiedResData));
      },
      onFailure: err => of(SetupToolActions.appendError(
        mapActionTypeToErrorMessage(DsoRegistrationFormActions.removeInstallers.type),
      )),
    }),
  )),
);

const patchSubmission$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.patchSubmission),
  mergeMap(({
    data,
    submissionId,
    registrationSubject,
    dsoReferenceNumberPvVisible,
    queryKey,
    fasteningTypeConsumptionVisible,
    meterCabinetPreparedVisible,
    productionMeterVisible,
  }) => of({}).pipe(
    processQuery(
      queryKey,
      () => DsoRegisterRepository.patchSubmission(
        data,
        submissionId,
        registrationSubject,
        dsoReferenceNumberPvVisible,
        fasteningTypeConsumptionVisible,
        meterCabinetPreparedVisible,
        productionMeterVisible,
      ),
      {
        onSuccess: res => {
          const customerDataProps = getCustomerDataProps(res!.data.submission.fields);
          const pvSystemProps = getPvSystemProps(res!.data.submission.fields, res!.data.submission.registrationSubject);
          const sonnenBatteryProps = getSonnenBatteryProps(
            res!.data.submission.fields,
            res!.data.submission.registrationSubject,
          );
          const measuringDeviceProps = getMeasuringDeviceProps(res!.data.submission.fields);
          const documentsFileProps = getDocumentsFileProps(res!.data.submission);
          const installerDataProps = getInstallerDataProps(res!.data.submission.fields);
          const dsoCommissioningFieldsProps = getDsoCommissioningProps(res!.data.submission);
          const pvRegisterProps = getPvRegisterProps(res!.data.submission);
          const vppDocumentationProps = getVppDocumentationProps(res!.data.submission);
          const vppSubmissionStatus = getVppSubmissionStatusFromStepsUpdatedAt(res!.data.submission.stepsUpdatedAt);
          const dsoReferenceNumberPvVisible =
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.DSO_REFERENCE_NUMBER_PV_FIELD, res!.data.submission);
          const vppCompletionNotificationVisible =
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.VPP_COMPLETION_NOTIFICATION, res!.data.submission);
          const meterCabinetPreparedField =
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.METER_CABINET_PREPARED_FIELD, res!.data.submission);
          const fasteningTypeConsumptionField = getAdditionalFeatureVisibilityProp(
            AdditionalFeatures.FASTENING_TYPE_CONSUMPTION_FIELD, res!.data.submission);
          const productionMeterFieldVisible = 
            getAdditionalFeatureVisibilityProp(AdditionalFeatures.PRODUCTION_METER_FIELD, res!.data.submission);
          
          const formUpdatingActions = (steps => {
            const actionsArray = [];

            if (steps.includes(SetupDataStepKey.CUSTOMER_DATA)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoCustomerData)(customerDataProps));
            }
            if (steps.includes(SetupDataStepKey.PV_SYSTEM)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoPvSystem)(pvSystemProps));
            }
            if (steps.includes(SetupDataStepKey.SONNEN_BATTERY)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoSonnenBattery)(sonnenBatteryProps));
            }
            if (steps.includes(SetupDataStepKey.MEASURING_DEVICE)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoMeasuringDevice)(measuringDeviceProps));
            }
            if (steps.includes(SetupDataStepKey.UPLOAD_DOCUMENTS)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoDocumentsFile)(documentsFileProps));
            }
            if (steps.includes(SetupDataStepKey.INSTALLER_DATA)) {
              actionsArray.push(dataGuard(DsoRegistrationFormActions.setDsoInstallerData)(installerDataProps));
            }

            return merge(...actionsArray);
          })(res.data.modifiedSetupDataStepKeys);

          return merge(
            of(DsoRegistrationFormActions.setSetupDataStatuses(res!.data.submission.setupDataStatuses)),
            of(SetupToolActions.setRegistrationSubjectType(res!.data.submission.registrationSubject)),
            formUpdatingActions,
            dataGuard(DsoCommissioningActions.setFields)(dsoCommissioningFieldsProps),
            dataGuard(PVRegisterActions.init)(pvRegisterProps),
            dataGuard(VppDocumentationActions.setFields)(vppDocumentationProps),
            dataGuard(VppDocumentationActions.setSubmissionStatus)(vppSubmissionStatus),
            dataGuard(VppDocumentationActions.setDsoReferenceNumberPvVisibility)(dsoReferenceNumberPvVisible),
            dataGuard(VppDocumentationActions.setCompletionNotificationVisibility)(vppCompletionNotificationVisible),
            dataGuard(VppDocumentationActions.setProductionMeterFieldVisibility)(productionMeterFieldVisible),
            dataGuard(VppDocumentationActions.setMeterCabinetPreparedField)(meterCabinetPreparedField),
            dataGuard(VppDocumentationActions.setFasteningTypeConsumptionField)(fasteningTypeConsumptionField),
            dataGuard(VppDocumentationActions.setVppAdditionalFeatures)(res!.data.submission.vppAdditionalFeatures),
            dataGuard(SetupToolActions.setUploadedDocuments)(
              res!.data.submission.documents.filter((doc: any) => doc.status),
            ),
            of(DsoRegisterActions.patchSubmissionSuccess(res!)),
          );
        },
        onFailure: err => merge(
          of(DsoRegisterActions.patchSubmissionFailure(err)),
          of(SetupToolActions.appendError(mapActionTypeToErrorMessage(DsoRegisterActions.patchSubmission.type))),
        ),
      },
    ),
  )),
);

export const epics = combineEpics(
  clearDataBeforePostRegisterDso$,
  postRegisterDso$,
  postSendDsoDocument$,
  removeDsoDocument$,
  postRegisterPV$,
  registerPvrManually$,
  postVpp$,
  getDsoList$,
  getPVModuleVendors$,
  getPVModuleVendorsPerPage$,
  getPVModules$,
  getPVModulesPerPage$,
  getPVInverterVendors$,
  getPVInverterVendorsPerPage$,
  getPVInverters$,
  getPVInvertersPerPage$,
  getBatteryNames$,
  getBatteries$,
  getInstallers$,
  submitInstaller$,
  removeInstallers$,
  patchSubmission$,
);
