import {
  createUseStatusTracking,
  ErrorTrackingStatusMap,
  SuccessTrackingStatusMap,
  TrackingStatusMap,
} from './createUseStatusTracking.util';

export * from './createUseStatusTracking.util';

const matchEvery = <T, >(predicate: (value: T) => boolean, queryStatus: T[]): boolean =>
  queryStatus.every(predicate);
const matchAny = <T, >(predicate: (value: T) => boolean, queryStatus: T[]): boolean =>
  queryStatus.findIndex(predicate) !== -1;

const extractPendingStatus = <T extends TrackingStatusMap>(queryStatus: T | T[]) => {
  if (Array.isArray(queryStatus)) {
    return matchAny(qs => qs.pending, queryStatus);
  }

  return queryStatus.pending;
};

export const useSuccessTracking = createUseStatusTracking<SuccessTrackingStatusMap | SuccessTrackingStatusMap[]>({
  extractPendingStatus,
  extractTrackedStatus: (queryStatus) => {
    if (Array.isArray(queryStatus)) {
      return matchEvery(qs => qs.success, queryStatus);
    }

    return queryStatus.success;
  },
});
export const useErrorTracking = createUseStatusTracking<ErrorTrackingStatusMap | ErrorTrackingStatusMap[]>({
  extractPendingStatus,
  extractTrackedStatus: (queryStatus) => {
    if (Array.isArray(queryStatus)) {
      return matchAny(qs => qs.error, queryStatus);
    }

    return queryStatus.error;
  },
});
