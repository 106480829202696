import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './MissingBatteryModalLayout.component.scss';

interface Props {
  canSubmit: boolean;
  isLoading: boolean;
  buttonLabel: string;
  onSubmit?: () => void;
  goBack?: () => void;
}

export const MissingBatteryModalLayout: React.FC<Props> = ({
  children,
  canSubmit,
  isLoading,
  buttonLabel,
  onSubmit,
  goBack,
}) => (
  <ModalLayout 
    title={I18n.t(T.missingBattery.modalHeader)}
    footer={
      <div className={'c-missing-battery-modal-layout__footer'}>
        {!!goBack && (
          <Button
            label={I18n.t(T.general.basicActions.goBack)}
            theme={ButtonTheme.TRANSPARENT}
            size={ButtonSize.NORMAL}
            onClick={goBack}
          />
        )}
        <Button
          label={buttonLabel}
          theme={ButtonTheme.PRIMARY}
          size={ButtonSize.NORMAL}
          type={'submit'}
          isDisabled={!canSubmit || isLoading}
          isLoading={isLoading}
          onClick={onSubmit}
        />
      </div>
    }
  >
    {children}
  </ModalLayout>
);
