import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { SetupLeadEditStatus } from '+app/+lead/store/types';
import { mapActions } from '+app/utils';
import { WidgetSimple } from '+shared/components';
import { FormStatusCheckbox } from '+shared/components/Form/FormStatusCheckbox';
import { LeadActions } from '+shared/store/lead';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { insertIf } from '+utils/array.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon, Modal, ModalLayout } from '@sonnen/shared-web';
import * as classnames from 'classnames';
import { Form, Formik } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SetupInfoIconTooltip } from '../SetupInfoIconTooltip';
import {
  formFields,
  mapUserStatusToInitialValues,
} from './SetupLeadEditStatusModal.helper';

import './SetupLeadEditStatusModal.component.scss';

const mapDispatchToProps = mapActions({
  getLead: LeadActions.getLead,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & {
    isModalOpen: boolean;
    lead: Lead;
    toggleEditModal: (arg: boolean) => void;
    toggleModal: (arg: boolean) => void;
    updateStatuses: (...status: LeadStatusName[]) => void;
    userCompanyName: string;
  };

export const SetupLeadEditStatusModalComponent: React.FC<Props> = ({
  isModalOpen,
  toggleEditModal,
  toggleModal,
  lead,
  updateStatuses,
  userCompanyName,
  actions,
}) => {
  React.useEffect(() => {
    return () => {
      closeLeadEditStatusModal();
    };
  }, []);

  const closeLeadEditStatusModal = () => {
    toggleModal(false);
    toggleEditModal(false);
  };

  const onSubmit = (values: SetupLeadEditStatus) => {
    if (lead) {
      updateStatuses(
        ...insertIf(
          values.dsoRegistrationCompleted !== initialValues.dsoRegistrationCompleted,
          LeadStatusName.DSO_REGISTRATION_COMPLETED,
        ),
        ...insertIf(
          values.dsoCommissioningCompleted !== initialValues.dsoCommissioningCompleted,
          LeadStatusName.DSO_COMMISSIONING_COMPLETED,
        ),
        ...insertIf(
          values.bNetzARegistrationCompleted !== initialValues.bNetzARegistrationCompleted,
          LeadStatusName.BNETZA_REGISTRATION_COMPLETED,
        ),
        ...insertIf(
          values.taxOfficeRegistrationCompleted !== initialValues.taxOfficeRegistrationCompleted,
          LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED,
        ),
        ...insertIf(
          values.hardwareCommissioningCompleted !== initialValues.hardwareCommissioningCompleted,
          LeadStatusName.HW_COMMISSIONING_COMPLETED,
        ),
        ...insertIf(
          values.dsoMeterInstalled !== initialValues.dsoMeterInstalled,
          LeadStatusName.DSO_METER_INSTALLED,
        ),
      );
    }
  };

  const initialValues = mapUserStatusToInitialValues(lead);

  const checkboxTextGenerator = (label: string, owner: string) => (
    <>
      <p className={'c-setup-lead-edit-status-modal__status-name'}>{label}</p>
      <p className={'c-setup-lead-edit-status-modal__status-owner'}>{owner}</p>
    </>
  );

  return (
    <Modal
      id={'setup-tool-status_management_modal'}
      isOpen={isModalOpen}
      onClose={closeLeadEditStatusModal}
      className={'sw-modal-layout--setup-statuses'}
    >
      <div className={'c-setup-lead-edit-status-modal'} data-hj-suppress={true}>
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={onSubmit}
          render={form => (
            <Form className={'c-setup-lead-edit-status-modal-form'}>
              <ModalLayout
                title={`${lead.firstName} ${lead.lastName}`}
                footer={
                  <>
                    {form.dirty &&
                      <div className={'c-setup-lead-edit-status-modal__warning-wrapper'}
                        id={'setup-tool-lead_status_save_warning'}>
                        <Icon.Warning className={'c-setup-lead-edit-status-modal__warning-icon'} />
                        <p>
                          {I18n.t(T.setupTool.statusManagement.warning)}
                        </p>
                      </div>
                    }
                    <div className={'c-setup-lead-edit-status-modal__buttons'}>
                      <div className={'c-setup-lead-edit-status-modal__buttons-wrapper'}>
                        <Button
                          name={'setup-tool-lead_status_save_button'}
                          className={classnames('c-setup-lead-edit-status-modal__footer-button', {
                            'gtm-save-sm-button': form.dirty,
                          })}
                          type={'submit'}
                          label={I18n.t(T.setupTool.statusManagement.button.save)}
                          size={ButtonSize.NORMAL}
                          theme={ButtonTheme.PRIMARY}
                          isDisabled={!form.dirty}
                        />
                      </div>
                    </div>
                  </>
                }
              >
                <div className={'c-setup-lead-edit-status-modal__input'}>
                  <p className={'c-setup-lead-edit-status-modal__subheadline'}>
                    {I18n.t(T.setupTool.statusManagement.subheadline)}
                  </p>

                  <p className={'c-setup-lead-edit-status-modal__description'}>
                    {I18n.t(T.setupTool.statusManagement.description)}
                  </p>
                </div>

                <div className={'c-setup-lead-edit-status-modal__section'}>
                  <WidgetSimple
                    heading={I18n.t(T.setupTool.statusManagement.section.header.registration)}
                  >
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.dsoRegistrationCompleted)}`,
                          userCompanyName)}
                        name={formFields.DSO_REGISTRATION_COMPLETED}
                        initialValue={initialValues.dsoRegistrationCompleted}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.DSO_REGISTRATION_COMPLETED} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.dsoCommissioningCompleted)}`,
                          userCompanyName)}
                        name={formFields.DSO_COMMISSIONING_COMPLETED}
                        initialValue={initialValues.dsoCommissioningCompleted}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.DSO_COMMISSIONING_COMPLETED} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.bNetzARegistrationCompleted)}`,
                          `${userCompanyName}/${I18n.t(T.setupTool.statusManagement.status.owner.customer)}`)}
                        name={formFields.BNETZA_REGISTRATION_COMPLETED}
                        initialValue={initialValues.bNetzARegistrationCompleted}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.BNETZA_REGISTRATION_COMPLETED} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.taxOfficeRegistrationCompleted)}`,
                          `${I18n.t(T.setupTool.statusManagement.status.owner.customer)}`)}
                        name={formFields.TAX_OFFICE_REGISTRATION_COMPLETED}
                        initialValue={initialValues.taxOfficeRegistrationCompleted}
                        isStatusAutomatic={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.TAX_OFFICE_REGISTRATION_COMPLETED} />
                    </div>
                  </WidgetSimple>
                </div>

                <div className={'c-setup-lead-edit-status-modal__section'}>
                  <WidgetSimple
                    heading={I18n.t(T.setupTool.statusManagement.section.header.hardwareSetup)}
                  >
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.hardwareCommissioningCompleted)}`,
                          userCompanyName)}
                        name={formFields.HW_COMMISSIONING_COMPLETED}
                        initialValue={initialValues.hardwareCommissioningCompleted}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.HW_COMMISSIONING_COMPLETED} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.dsoMeterInstalled)}`,
                          userCompanyName)}
                        name={formFields.DSO_METER_INSTALLED}
                        initialValue={initialValues.dsoMeterInstalled}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.DSO_METER_INSTALLED} />
                    </div>
                  </WidgetSimple>
                </div>

                <div className={'c-setup-lead-edit-status-modal__section'}>
                  <WidgetSimple
                    heading={I18n.t(T.setupTool.statusManagement.section.header.sonnenFlatSetup)}
                  >
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(`${I18n.t(T.shared._salessolution_.lead.status.flatOfferSent)}`,
                          userCompanyName)}
                        name={formFields.FLAT_OFFER_SENT}
                        initialValue={initialValues.flatOfferSent}
                        isStatusAutomatic={true}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.FLAT_OFFER_SENT} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.flatOfferAccepted)}`,
                          `${I18n.t(T.setupTool.statusManagement.status.owner.customer)}`)}
                        name={formFields.FLAT_OFFER_ACCEPTED}
                        initialValue={initialValues.flatOfferAccepted}
                        isStatusAutomatic={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.FLAT_OFFER_ACCEPTED} />
                    </div>
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.flatOrderConfirmed)}`,
                          `${I18n.t(T.setupTool.statusManagement.status.owner.Sonnen)}`)}
                        name={formFields.FLAT_ORDER_CONFIRMED}
                        initialValue={initialValues.flatOrderConfirmed}
                        isStatusAutomatic={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.FLAT_ORDER_CONFIRMED} />
                    </div>
                    {/* TODO uncomment when Date of Delivery status will be needed
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.startOfDeliveryFixed)}`,
                          `${I18n.t(T.setupTool.statusManagement.status.owner.Sonnen)}`)}
                        name={formFields.START_OF_DELIVERY_FIXED}
                        initialValue={initialValues.startOfDeliveryFixed}
                        isStatusAutomatic={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.START_OF_DELIVERY_FIXED} />
                    </div> */}
                    <div className={'c-setup-lead-edit-status-modal__row'}>
                      <FormStatusCheckbox
                        form={form}
                        label={checkboxTextGenerator(
                          `${I18n.t(T.shared._salessolution_.lead.status.vppPreparationDone)}`,
                          userCompanyName)}
                        name={formFields.VPP_PREPARATION_DONE}
                        initialValue={initialValues.vppPreparationDone}
                        isStatusAutomatic={true}
                        suppressLabelInHotjar={true}
                      />
                      <SetupInfoIconTooltip statusName={formFields.VPP_PREPARATION_DONE} />
                    </div>
                  </WidgetSimple>
                </div>
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export const SetupLeadEditStatusModal = connect(
  null,
  mapDispatchToProps,
)(SetupLeadEditStatusModalComponent);
