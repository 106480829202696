import { PeakPowerUnit } from '+app/App.constants';
import { LeadMeasurement } from './leadMeasurement.interface';

export enum LeadPvSystemStatus {
  INSTALLED = 'installed',
  PLANNED = 'planned',
  PLANNED_EXTENSTION = 'planned_extension',
}

export interface LeadPvSystem {
  status?: LeadPvSystemStatus | null;
  commissioningDate?: string | null;
  peakPower?: LeadMeasurement<PeakPowerUnit.KWP> | null;
}
