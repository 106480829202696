import { ActionBoxColors } from '+app/+lead/store/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationActionBox } from '../LeadConfigurationActionBox';
import { LeadConfigurationLine } from '../LeadConfigurationLine';
import {
  LeadConfigurationTile,
  LeadConfigurationTileProps,
  LeadConfigurationTileStatus,
} from '../LeadConfigurationTile';
import { mapConfigurationTileStatusForHardwareOffer, renderStatus } from '../LeadConfigurationTile/LeadConfigurationTile.helper';
import { factorizeBatteryDetails } from './LeadHardwareConfigurationTile.helper';

import './LeadHardwareConfigurationTile.component.scss';

interface OwnProps {
  battery: LeadProductBattery;
  isTileActionDisabled: boolean;
  isStatusUpdateDisabled?: boolean;
  addSonnenTarriff: () => void;
  isAddFlatDisabled: boolean;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
  createdAt: string;
  offerId?: string;
}

type Props =
  & Omit<LeadConfigurationTileProps,
    'isOfferCreationDisabled' |
    'creationDate' |
    'offerButtonLabel' |
    'productName'
  >
  & OwnProps
  ;

export const LeadHardwareConfigurationTile: React.FC<Props> = ({
  battery,
  isTileActionDisabled,
  status = LeadConfigurationTileStatus.PREVIEW,
  isStatusUpdateDisabled = false,
  isAddFlatDisabled,
  createdAt,
  onHardwareStatusModalOpen,
  addSonnenTarriff,
  offerId,
  ...restTileProps
}) => {
  const { locale } = useLocaleContext();
  const sonnenBatteryDetails = factorizeBatteryDetails(locale)(battery);
  const mappedHardwareStatus = mapConfigurationTileStatusForHardwareOffer(status);

  return (
    <>
      <LeadConfigurationTile
        creationDate={createdAt}
        isTileActionDisabled={isTileActionDisabled}
        productName={I18n.t(T.lead._salessolution_.configurations.product.hardwareWithoutContract)}
        status={mappedHardwareStatus} // @Future: change place of displaying status
        {...restTileProps}
        offerId={offerId}
      >
        <LeadConfigurationActionBox
          onClickAction={addSonnenTarriff}
          mainColor={ActionBoxColors.BLUE}
          hoverable={true}
          hasLeftBorder={true}
          disabled={isAddFlatDisabled}
        >
          <p className={'c-lead-hardware-configuration-tile__header-headline'}>
            {I18n.t(T.lead.hardware._salessolution_.configurationTile.addTariff.headline)}
          </p>
          <p className={'c-lead-hardware-configuration-tile__header-description'}>
            {I18n.t(T.lead.hardware._salessolution_.configurationTile.addTariff.subheadline)}
          </p>
          <div className={'c-lead-hardware-configuration-tile__tariff-button'}>
            <Button
              className={`c-inbox-lead-list-table-item__cta`}
              size={ButtonSize.SECONDARY}
              label={I18n.t(T.lead.hardware._salessolution_.configurationTile.addTariff.button)}
              theme={ButtonTheme.OUTLINE}
              isDisabled={isAddFlatDisabled}
            />
          </div>
        </LeadConfigurationActionBox>

        <div className={'c-lead-hardware-configuration-tile'}>
          <div className={'c-lead-hardware-configuration-tile__header'}>
            <div className={'c-lead-hardware-configuration-tile__header-status-wrapper'}>
              <p className={'c-lead-hardware-configuration-tile__header-headline'}>
                {I18n.t(T.lead.hardware._salessolution_.configurationTile.headline.hardware)}
              </p>
              {renderStatus(mappedHardwareStatus)}
            </div>
            <p className={'c-lead-hardware-configuration-tile__header-subheadline'}>
              {I18n.t(T.lead.hardware._salessolution_.configurationTile.subheadline.sonnenBatterie)}
            </p>
          </div>
          <div className={'c-lead-hardware-configuration-tile__details'}>
            {sonnenBatteryDetails.map(({ title, value, type }, index) =>
              <LeadConfigurationLine
                title={title}
                value={value}
                type={type}
                key={`detail-${index}`}
              />,
            )}
          </div>
          <div className={'c-lead-hardware-configuration-tile__status-button-wrapper'}>
            <Button
              label={I18n.t(T.lead.hardware._salessolution_.form.statusChange.button.updateStatus)}
              onClick={onHardwareStatusModalOpen}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.OUTLINE}
              isDisabled={isStatusUpdateDisabled}
            />
          </div>
        </div>
      </LeadConfigurationTile>
    </>
  );
};
