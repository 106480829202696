import { SupportForm } from '+app/+help/+faqAndContact/components/SupportForm/SupportForm.component';
import { mapActions } from '+app/utils';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { CaseActions, getCaseSubmitQueryStatus, getCreatedCaseNumber } from '+shared/store/case';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileSalesforceContactId } from '+shared/store/user/user.selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import { SupportFormResult } from '../../components/SupportFormResult';

const mapStateToProps = (state: StoreState) => ({
  queryStatus: getCaseSubmitQueryStatus(state),
  contactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
});

const mapDispatchToProps = mapActions({
  createCase: CaseActions.createCase,
  resetCreationProcess: CaseActions.clearCreatedCase,
});

interface State {
  formState: FormState;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

export class SupportComponent extends React.PureComponent<Props, State> {
  state = {
    formState: FormState.FORM,
  };

  componentDidUpdate() {
    const { queryStatus } = this.props;

    if (queryStatus.success) {
      this.setState({ formState: FormState.FORM_SUCCESS});
    }

    if (queryStatus.error) {
      this.setState({ formState: FormState.FORM_ERROR});
    }
  }

  componentWillUnmount() {
    const { actions } = this.props; 
    const { formState } = this.state;

    if (formState !== FormState.FORM) {
      actions.resetCreationProcess();
      this.setState({ formState: FormState.FORM});
    }
  }

  onSendAnother = () => {
    const { actions } = this.props; 
    const { formState } = this.state;

    if (formState !== FormState.FORM) {
      actions.resetCreationProcess();
      this.setState({ formState: FormState.FORM});
    }
  };
  
  render() {
    const { actions, queryStatus, contactId, caseNumber } = this.props; 
    const { formState } = this.state; 

    return (
      <>
        {{
          [FormState.FORM]: (
            <SupportForm
              submitForm={actions.createCase}
              isLoading={queryStatus.pending}
              status={formState}
              contactId={contactId}
            />
          ),
          [FormState.FORM_ERROR]: <SupportFormResult onTryAgain={this.onSendAnother} />,
          [FormState.FORM_SUCCESS]: (
            <SupportFormResult 
              isSuccess={true} 
              onTryAgain={this.onSendAnother}
              caseNumber={caseNumber}
            />
          ),
        }[formState]}
      </>
    );
  }
}

export const Support = connect(mapStateToProps, mapDispatchToProps)(SupportComponent);
