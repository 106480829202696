import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CustomersTableAccessBadge.component.scss';

export const CustomersTableAccessBadge: React.FC = () => (
  <div className={'c-customers-table-access-badge'}>
    <Icon.Padlock className={'c-customers-table-access-badge__icon'}/>
    {I18n.t(T.customerSingle.accessDenied.label)}
  </div>
);
