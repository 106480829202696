import { ModalVideo } from '@sonnen/shared-web';
import * as React from 'react';

interface Props {
  videoId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const VideoModal: React.FC<Props> = ({ videoId, isOpen, onClose }) => (
  <ModalVideo
    isOpen={isOpen}
    onClose={onClose}
    onOutsideClick={onClose}
  >
    <iframe 
      width="560" 
      height="315" 
      src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
      frameBorder="0" 
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowFullScreen={true}
    />
  </ModalVideo>
);
