import { ListItemNews } from '+shared/store/firebase/types/news.interface';
import { orderBy } from 'lodash';

export const convertToSlug = (text: string) => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const removeDuplicateSpaces = (message: string) =>
  message
    .replace(/\&nbsp;/g, ' ')
    .replace(/  +/g, ' ')
    .trim();

export const removeHtmlTags = (message: string) =>
  message.replace(/(<([^>]+)>)/gi, ' ');

export const sortList = (list: ListItemNews[], key: string = 'date.seconds') =>
  orderBy(list, key, 'desc');

export const setNewsUrl = (title: string, id: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return  {
    newsTitle: convertToSlug(title),
    newsId: id,
    page: urlParams.get('page') || undefined,
  };
};
