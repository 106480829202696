import { ActionsUnion, createAction } from '+utils/redux';
import { BatteryElectricUnits, BatteryWithProduct } from './types';
import { BatteryStatuses } from './types/batteryStatuses.interface';

export enum BATTERY_ACTIONS {
  GET_BATTERY = '[Battery] GET_BATTERY',
  SET_BATTERY = '[Battery] SET_BATTERY',
  GET_BATTERY_ELECTRIC_UNITS = '[Battery] GET_BATTERY_ELECTRIC_UNITS',
  SET_BATTERY_ELECTRIC_UNITS = '[Battery] SET_BATTERY_ELECTRIC_UNITS',
  GET_BATTERY_STATUSES = '[Battery] GET_BATTERY_STATUSES',
  SET_BATTERY_STATUSES = '[Battery] SET_BATTERY_STATUSES',
}

export const BatteryActions = {
  getBattery: createAction(
    BATTERY_ACTIONS.GET_BATTERY,
    ({ queryKey, batteryId }: { queryKey: string, batteryId: string | undefined }) => ({ queryKey, batteryId }),
  ),
  setBattery: createAction(
    BATTERY_ACTIONS.SET_BATTERY,
    (battery?: BatteryWithProduct) => ({ battery }),
  ),
  getBatteryElectricUnits: createAction(
    BATTERY_ACTIONS.GET_BATTERY_ELECTRIC_UNITS,
    ({ queryKey, batteryId }: { queryKey: string, batteryId: string }) => ({ queryKey, batteryId }),
  ),
  setBatteryElectricUnits: createAction(
    BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS,
    (batteryElectricUnits: BatteryElectricUnits[]) => ({ batteryElectricUnits }),
  ),
  getBatteryStatuses: createAction(
    BATTERY_ACTIONS.GET_BATTERY_STATUSES,
    ({ queryKey, batteryId, start, end }: { queryKey: string, batteryId: string, start: Date, end: Date }) =>
      ({ queryKey, batteryId, start, end }),
  ),
  setBatteryStatuses: createAction(
    BATTERY_ACTIONS.SET_BATTERY_STATUSES,
    (batteryStatuses: BatteryStatuses[]) => ({ batteryStatuses }),
  ),
};

export type BatteryActions = ActionsUnion<typeof BatteryActions>;
