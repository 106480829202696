import { StatusManagementTooltipLocation } from '+app/+lead/+list/store/+leadList.state';
import { SetupLeadEditStatusModal } from '+app/+lead/components/SetupLeadEditStatusModal';
import { TutorialTooltip } from '+shared/containers/TutorialTooltip';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { SetupLeadStatusTooltipWrapper } from '../SetupLeadStatusTooltipWrapper';
import './SetupLeadStatuses.component.scss';

interface Props {
  lead: Lead;
  statuses: {
    registration: string[],
    hardwareSetup: string[],
    sonnenFlatSetup: string[],
    registrationCompleted: boolean,
    hardwareSetupCompleted: boolean,
    sonnenFlatSetupCompleted: boolean,
  };
  isModalOpen: boolean;
  toggleModal: (arg: boolean) => void;
  changeLeadStatus: (...status: LeadStatusName[]) => void;
  userCompanyName: string;
  gtmClassName?: string;
  location?: StatusManagementTooltipLocation;
}

export const SetupLeadStatuses = ({
  lead,
  statuses,
  isModalOpen,
  toggleModal,
  changeLeadStatus,
  userCompanyName,
  gtmClassName,
  location,
}: Props) => {
  const [isEditStatusModalOpen, setEditStatusModalOpen] = React.useState<boolean>(false);

  return (
    <div className={`c-setup-lead-statuses`}>

      <SetupLeadStatusTooltipWrapper statuses={statuses.registration}>
        <div className={`c-setup-lead-statuses__item c-setup-lead-statuses__item--registration`}>
          <SetupLeadStatusIcon completionStatus={statuses.registrationCompleted
            ? 'completed'
            : (statuses.registration.length ? 'inProgress' : 'notStarted')}
          />
          <span className={'c-setup-lead-statuses__item__text'}>
            {I18n.t(T.setupTool.statusManagement.section.header.registration)}
          </span>
        </div>
      </SetupLeadStatusTooltipWrapper>

      <SetupLeadStatusTooltipWrapper statuses={statuses.hardwareSetup}>
        <div className={`c-setup-lead-statuses__item c-setup-lead-statuses__item--hardware_setup`}>
          <SetupLeadStatusIcon completionStatus={statuses.hardwareSetupCompleted
            ? 'completed'
            : (statuses.hardwareSetup.length ? 'inProgress' : 'notStarted')}
          />
          <span className={'c-setup-lead-statuses__item__text'}>
            {I18n.t(T.setupTool.statusManagement.section.header.hardwareSetup)}
          </span>
        </div>
      </SetupLeadStatusTooltipWrapper>

      <SetupLeadStatusTooltipWrapper statuses={statuses.sonnenFlatSetup.slice(-1)}>
        <div className={`c-setup-lead-statuses__item c-setup-lead-statuses__item--sonnenflat_setup`}>
          <SetupLeadStatusIcon completionStatus={statuses.sonnenFlatSetupCompleted
            ? 'completed'
            : (statuses.sonnenFlatSetup.length ? 'inProgress' : 'notStarted')}
          />
          <span className={'c-setup-lead-statuses__item__text'}>
            {I18n.t(T.setupTool.statusManagement.section.header.sonnenFlatSetup)}
          </span>
        </div>
      </SetupLeadStatusTooltipWrapper>

      {/* <TutorialTooltip id={location === StatusManagementTooltipLocation.SETUP_LIST ?
        'statusManagementList' : 'statusManagementHeader'}> */}
        <Button
          theme={ButtonTheme.OUTLINE}
          label={I18n.t(T.setupTool.statusManagement.button.update)}
          size={ButtonSize.NORMAL}
          className={classNames('c-setup-lead-statuses__button', gtmClassName)}
          onClick={event => {
            event.stopPropagation();
            setEditStatusModalOpen(true);
            toggleModal(true);
          }}
        />
      {/* </TutorialTooltip> */}

      {<div onClick={e => e.stopPropagation()}>
        <SetupLeadEditStatusModal
          isModalOpen={isEditStatusModalOpen && isModalOpen}
          toggleEditModal={setEditStatusModalOpen}
          toggleModal={toggleModal}
          lead={lead}
          updateStatuses={changeLeadStatus}
          userCompanyName={userCompanyName}
        />
      </div>}
    </div>
  );
};

interface CompletionStatus {
  completionStatus: 'completed' | 'inProgress' | 'notStarted';
}

const SetupLeadStatusIcon = ({ completionStatus }: CompletionStatus) => {
  return completionStatus === 'completed'
    ? <Icon.TickCircle
      className={'c-setup-lead-statuses__item__icon c-setup-lead-statuses__item__icon--completed'}
      color={'#BCDC66'} />
    : completionStatus === 'inProgress'
      ? <Icon.TwoThirdsOfCircle
        className={'c-setup-lead-statuses__item__icon c-setup-lead-statuses__item__icon--in-progress'}
        color={'#BCDC66'} />
      : <Icon.Zigzag
        className={'c-setup-lead-statuses__item__icon c-setup-lead-statuses__item__icon--empty'}
        color={'#C5C6C9'} />;
};
