import * as React from 'react';
import { CustomerAnalysisEnergyFlow } from '../CustomerAnalysisEnergyFlow';
import { CustomerAnalysisStatistics } from '../CustomerAnalysisStatistics';

export const CustomerAnalysis: React.FC = ({}) => (
  <section className={'c-customer-analysis'}>
    <CustomerAnalysisEnergyFlow />
    <CustomerAnalysisStatistics />
  </section>
);
