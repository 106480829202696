import { makeEventDecorator } from '@sonnen/shared-web';
import {
  EventHitType,
  formatCategory,
  formatLabelParts,
  formatPageView,
} from './eventDecorator';

enum GoogleTagRequestStage {
  INIT = 'init',
  SUCCESS = 'success',
  ERROR = 'error',
}

type GoogleTagRequestEvent = {
  requestName: string;
  stage: GoogleTagRequestStage;
};

const formatRequestName = () => ({ requestName, stage }: GoogleTagRequestEvent) => `${requestName} - ${stage}`;

const requestEventDecorator = makeEventDecorator({
  category: formatCategory,
  action: formatRequestName,
  label: formatLabelParts,
  page: formatPageView,
});

const getRequestPageView = (requestName: string, page: string) => `/request-${requestName}${page}`;

export const RequestEvents = {
  init: requestEventDecorator(({ category, requestName, labelParts }) => {
    const page = getRequestPageView(requestName, `/${GoogleTagRequestStage.INIT}`);

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      requestName,
      stage: GoogleTagRequestStage.INIT,
      labelParts,
      page,
    };
  }),
  success: requestEventDecorator(({ category, requestName }) => {
    const page = getRequestPageView(requestName, `/${GoogleTagRequestStage.SUCCESS}`);

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      requestName,
      stage: GoogleTagRequestStage.SUCCESS,
      labelParts: [],
      page,
    };
  }),
  error: requestEventDecorator(({ category, requestName, error }) => {
    const page = getRequestPageView(requestName, `/${GoogleTagRequestStage.ERROR}`);

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      requestName,
      stage: GoogleTagRequestStage.ERROR,
      labelParts: [error],
      page,
    };
  }),
};
