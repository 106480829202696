import * as React from 'react';

export const useElementDimensions = () => {
  const unitElementRef = React.useRef<HTMLDivElement>(null);
  const [unitDimensions, setDimensions] = React.useState<DOMRect>();

  React.useEffect(() => {
    const element = unitElementRef && unitElementRef.current;

    if (element) {
      const dimensions = element.getBoundingClientRect();
      setDimensions(dimensions);
    }
  }, [unitElementRef]);

  return {
    unitElementRef,
    unitDimensions,
  };
};
