import { createReducer } from '+utils/redux/createReducer.util';
import { TimeHelper } from '@sonnen/shared-web';
import { compose } from 'lodash/fp';
import { queryReducer } from '../../../shared/store/query';
import { SITE_ACTIONS, SiteActions } from '../../../shared/store/site';
import { CUSTOMER_ACTIONS, CustomerActions } from '../../store/+customer.actions';
import { ANALYSIS_ACTIONS, AnalysisActions } from './+analysis.actions';
import {
  AnalysisPageState,
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_SITE_MEASUREMENTS_QUERY,
  initialState,
} from './+analysis.state';

export const reducer = createReducer<AnalysisPageState, SiteActions | AnalysisActions | CustomerActions>(
  (state = initialState, action): AnalysisPageState => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITE_MEASUREMENTS:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            isUnsupportedBattery: false,
            siteMeasurements: action.siteMeasurements,
          },
        };
      case ANALYSIS_ACTIONS.SET_DAY_CHART_DATE: 
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
              dataSeries: {
              ...initialState.dayChart.dataSeries,
            },
            selectedDate: compose(
              TimeHelper.getUnixFromDate,
              TimeHelper.getStartOfDate(),
            )(action.date),
          },
        };
      case ANALYSIS_ACTIONS.SET_DATA_SERIES:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            dataSeries: {
            ...state.dayChart.dataSeries,
            ...action.dataSeries,
            },
          },
        };
      case ANALYSIS_ACTIONS.TOGGLE_DATA_SERIES:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            selectedDataSeriesKeys:
              state.dayChart.selectedDataSeriesKeys.includes(action.dataSeriesKey)
                ? state.dayChart.selectedDataSeriesKeys.filter(dataSerie => dataSerie !== action.dataSeriesKey)
                : [ ...state.dayChart.selectedDataSeriesKeys, action.dataSeriesKey],
          },

        };
      case CUSTOMER_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialState,
        };
      case ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION_FULL:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            forecastProductionFull: action.forecastProductionFull,
          },
        };
      case ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION_FULL:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            forecastConsumptionFull: action.forecastConsumptionFull,
          },
        };
      case ANALYSIS_ACTIONS.CLEAR_FORECAST_DATA:
        return {
          ...state,
          dayChart: {
            ...state.dayChart,
            forecastProductionFull: [],
            forecastConsumptionFull: [],
            dataSeries: {
              ...state.dayChart.dataSeries,
              forecastConsumptionPower: [],
              forecastProductionPower: [],
            },
          },
        };
      case ANALYSIS_ACTIONS.SET_STATISTICS_DATE:
        return {
          ...state,
          statistics: {
            ...state.statistics,
            selectedDate: action.statisticsSelectedDate,
          },
        };
      case ANALYSIS_ACTIONS.SET_PIE_CHART_SERIES:
        return {
          ...state,
          statistics: {
            ...state.statistics,
            pieChartSeries: action.pieChartSeries,
          },
        };
      case ANALYSIS_ACTIONS.SET_BAR_CHART_SERIES:
        return {
          ...state,
          statistics: {
            ...state.statistics,
            barChartSeries: action.barChartSeries,
          },
        };
      default:
        return queryReducer(state, action, [
          GET_SITE_MEASUREMENTS_QUERY,
          GET_FORECAST_PRODUCTION_QUERY,
          GET_FORECAST_CONSUMPTION_QUERY,
        ]);
    }
  },
);
