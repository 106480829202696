import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, DefaultParagraph, Icon, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import './SupportFormResult.component.scss';

interface Props {
  onTryAgain: () => void;
  isSuccess?: boolean;
  caseNumber?: string;
}

export const SupportFormResult: React.FC<Props> = ({ onTryAgain, isSuccess, caseNumber }) => (
  <div className={'c-support-form-result__body'}>
    {isSuccess
      ? <Icon.PaperPlane className={'c-form-status__icon'} />
      : <Icon.Error className={'c-form-status__icon'}/>}
    <div className={'c-form-status__headline'}>
      <PageSubheadline
        smallGap={true}
        noBorder={true}
      >
        {isSuccess
          ? I18n.t(T.help.supportForm.status.success.headline, { caseId: caseNumber })
          : I18n.t(T.help.supportForm.status.error.headline)}
      </PageSubheadline>
    </div>
    <div className={'c-form-status__body'}>
      {isSuccess 
        ? <DefaultParagraph>{I18n.t(T.help.supportForm.status.success.paragraph)}</DefaultParagraph>
        : (
          <>
            <DefaultParagraph className={'c-case-report-error__paragraph'}>
              {I18n.t(T.help.supportForm.status.error.paragraph)}
            </DefaultParagraph>
            <DefaultParagraph>
              {I18n.t(T.help.supportForm.status.error.lowerParagraph)}&nbsp;
              <a href={`mailto:${I18n.t(T.help.supportForm.status.error.supportEmail)}`}>
                {I18n.t(T.help.supportForm.status.error.supportEmail)}
              </a>
            </DefaultParagraph>
          </>
        )
      }
    </div>
    <Button
      className={'c-support-form-result__button'}
      onClick={onTryAgain}
      size={ButtonSize.NORMAL}
      label={isSuccess 
          ? I18n.t(T.help.supportForm.buttons.sendAnotherRequest) 
          : I18n.t(T.help.supportForm.buttons.tryAgain)
      }
    />
  </div>
);
