import { ListTableHeaderItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getSetupLeadListTableHeaders = (): ListTableHeaderItem[] => [
  { label: I18n.t(T.lead.list._salessolution_.name) },
  { label: I18n.t(T.lead.list._salessolution_.address) },
  { label: I18n.t(T.lead.list._salessolution_.battery) },
  { label: I18n.t(T.lead.list._salessolution_.status) },
  { label: I18n.t(T.lead.list._salessolution_.nextStep) },
];
