import { RouterActions } from '+app/router/store';
import { isLocationChangeAction } from '+app/router/store/router.actions';
import { isPathChangingFromCustomerToAnother } from '+app/router/store/router.selectors';
import { StoreState } from '+shared/store/store.interface';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { iif, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GET_SITE_LIVE_STATE_QUERY } from '.';
import { dataGuard, ofType, polling, processQuery  } from '../../../utils';
import { SiteActions } from './site.actions';
import { validateMeasurementsFilters } from './site.helpers';
import { SiteRepository } from './site.repository';

type Action$ = ActionsObservable<SiteActions>;
type State$ = StateObservable<StoreState>;

export const getMeasurements$ = (action$: Action$) => action$.pipe(
  ofType(SiteActions.getSiteMeasurements),
  mergeMap(({ queryKey, siteId, start, end }) => of({}).pipe(
    processQuery(
      queryKey,
      () => SiteRepository.getSiteMeasurements(siteId, validateMeasurementsFilters(start, end)),
      { onSuccess: res => dataGuard(SiteActions.setSiteMeasurements)(res!.element) },
    ),
  )),
);

export const getSiteChargerList$ = (action$: Action$) => action$.pipe(
  ofType(SiteActions.getSiteChargerList),
  mergeMap(({ queryKey, siteId }) => of({}).pipe(
    processQuery(
      queryKey,
      () => SiteRepository.getSiteChargers(siteId),
      { onSuccess: res => dataGuard(SiteActions.setSiteChargerList)(res!.elements) },
    ),
  )),
);

export const getSiteStatistics$ = (action$: Action$) => action$.pipe(
  ofType(SiteActions.getSiteStatistics),
  mergeMap(({ queryKey, siteId, filters }) => of({}).pipe(
    processQuery(
      queryKey,
      () => SiteRepository.getSiteStatistics(siteId, filters),
      { onSuccess: res => dataGuard(SiteActions.setSiteStatistics)(res!.element) },
    ),
  )),
);

export const getSite$ = (action$: Action$) => action$.pipe(
  ofType(SiteActions.getSite),
  mergeMap(({ queryKey, siteId }) => of({}).pipe(
    processQuery(
      queryKey,
      () => SiteRepository.getSite(siteId),
      { onSuccess: res => dataGuard(SiteActions.setSite)(res!.element) },
    ),
  )),
);

export const triggerSiteLiveStatePolling$ = (action$: ActionsObservable<SiteActions>) => action$.pipe(
  polling({
    startOn: SiteActions.startPolling,
    stopOn: [SiteActions.stopPolling],
    interval: 5000,
  })(({ siteId }) => of({}).pipe(
    processQuery(
      // TODO: pass this queryKey as parameter if possible
      GET_SITE_LIVE_STATE_QUERY,
      () => SiteRepository.getSiteLiveState(siteId),
      { onSuccess: res => dataGuard(SiteActions.setSiteLiveState)(res!.element) },
    )),
  ),
);

export const clearSite$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.locationChange, RouterActions.changeCustomerSite),
  withLatestFrom(state$),
  filter(([action, state]) =>
    isLocationChangeAction(action) ? isPathChangingFromCustomerToAnother(state) : true,
  ),
  map(() => SiteActions.clearSite()),
);

export const getSiteLiveState$ = (action$: Action$) => action$.pipe(
  ofType(SiteActions.getSiteLiveState),
  mergeMap(({ siteId, queryKey }) => iif(
    () => !!siteId,
    of({}).pipe(
      processQuery(
        queryKey,
        () => SiteRepository.getSiteLiveState(siteId),
        {
          onSuccess: res => dataGuard(SiteActions.setSiteLiveState)(res!.element),
          onFailure: _ => of(SiteActions.setSiteLiveState(undefined)),
        },
      ),
    ),
    of(SiteActions.setSiteLiveState(undefined)),
  )),
);

export const epics = combineEpics<any>(
  getSiteChargerList$,
  getMeasurements$,
  getSiteStatistics$,
  getSite$,
  triggerSiteLiveStatePolling$,
  getSiteLiveState$,
  clearSite$,
);
