import { formatQueryFields } from './formaters/grahpQLFields.formatter';
import { formatQueryArgs } from './formaters/graphQLArgs.formatter';
import { CaseEdge, CasesQueryResult } from './types';
import { QueryParams } from './types/graphQL.types';

export const generateQuery = (params: QueryParams) => {
  const { type, fields, args } = params;
  const formatedArgs = args ? `(${formatQueryArgs(args)})` : '';
  const formatedFields = `{
    totalCount,
    edges{
      node{
        ${formatQueryFields(fields)}
      }cursor}
    }`;
  return `{${type}${formatedArgs}${formatedFields}}`;
};

export const getCasesQueryResults = ({ data }: CasesQueryResult) => {
  if (data) {
    const { edges, totalCount } = data!.cases;

    return ({
      list: edges.map((edge: CaseEdge) => ({...edge.node })), 
      totalCount, 
    });
  }

  return ({
    list: [],
    totalCount: 0,
  });
};
