import { StatusTileColors } from '+app/shared/components/StatusTile';
import { StatusType } from '@sonnen/shared-web';

export const setLabelColor = (type: StatusType) => ({
  'default': StatusTileColors.GRAY,
  'warning': StatusTileColors.YELLOW,
  'danger': StatusTileColors.RED,
  'success': StatusTileColors.GREEN,
  'info': StatusTileColors.BLUE,
})[type];
