import { DefaultParagraph } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './CaseDetail.component.scss';

interface Props {
  label: string;
  value: string;
  isBold?: boolean;
  isGap?: boolean;
}

export const CaseDetail: React.FC<Props> = ({ label, value, isBold = false, isGap = false }) => (
  <div className={classNames('c-case-detail', {'c-case-detail--gap': isGap})} data-hj-suppress={true}>
    <DefaultParagraph className={'c-case-detail__label'}>
      {label}
    </DefaultParagraph>
    <DefaultParagraph className={classNames('c-case-detail__value', {'c-case-detail__value--bold': isBold})}>
      {value}
    </DefaultParagraph>
  </div>
);
