import { Config } from '+config/index';
import { isUndefined } from 'lodash';
import { from } from 'rxjs';
import { jsonApiClient } from '../../network/network.client';
import { UserPasswordForm } from './types/userPassword.interface';
import { UserRegistration } from './types/userRegistration.interface';
import { CompanyData, UserData, UserProfileExtendedData } from './user.state';

const getUserMe = () => from(
  jsonApiClient.get<UserData>(`${Config.API_URL}/users/me`)
    .send());

const getUserCompany = (id: string) => from(
  jsonApiClient.get<CompanyData>(`${Config.API_URL}/users/${id}/company`)
    .send());

const putUserCompanyLogo = (userId: string, attachment: string | null) => {
  const attributes = attachment
    ? { logotype: { encoded_file: attachment } }
    : { logotype: 'null' };
  return from(jsonApiClient.put(`${Config.API_URL}/users/${userId}/company`)
    .ofType('users')
    .withAttributes(attributes)
    .send());
};

const putUserCompanyDocs = (
  userId: string,
  termsAndConditions?: string | null,
  cancellationPolicy?: string | null,
  dataPrivacyPolicy?: string | null,
) => {
  const attributes: any = {};

  if (!isUndefined(termsAndConditions)) {
    attributes.terms_and_conditions = termsAndConditions
      ? { encoded_file: termsAndConditions }
      : 'null';
  }

  if (!isUndefined(cancellationPolicy)) {
    attributes.cancellation_policy = cancellationPolicy
      ? { encoded_file: cancellationPolicy }
      : 'null';
  }

  if (!isUndefined(dataPrivacyPolicy)) {
    attributes.data_privacy_policy = dataPrivacyPolicy
      ? { encoded_file: dataPrivacyPolicy }
      : 'null';
  }

  return from(jsonApiClient.patch(`${Config.API_URL}/users/${userId}/company`)
    .ofType('users')
    .withAttributes(attributes)
    .send());
};

const getUserProfile = (id: string) => from(
  jsonApiClient.get<UserProfileExtendedData>(`${Config.API_URL}/users/${id}/profile`)
    .send());

const putUserProfileImage = (id: string, attachment: string | null) => {
  const attributes = attachment
    ? { image: { encoded_image: attachment } }
    : { image: attachment };
  return from(jsonApiClient.put(`${Config.API_URL}/users/${id}/profile`)
    .ofType('users')
    .withAttributes(attributes)
    .send());
};

const putUserRegistration = (data: UserRegistration) => from(
  jsonApiClient.put(`${Config.API_URL}/users/registration`)
    .ofType('users')
    .withAttributes({
      email: data.email.trim(),
      password: data.password.trim(),
      password_confirmation: data.passwordConfirmation.trim(),
      terms_of_service: data.termsOfService,
      privacy_policy: data.privacyPolicy,
      invitation_token: data.invitationToken,
    })
    .send());

const putUserPassword = (id: string, data: UserPasswordForm) => from(
  jsonApiClient.put(`${Config.API_URL}/users/${id}/password`)
    .ofType('users')
    .withAttributes({
      previous_password: data.previousPassword,
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    })
    .send(),
);

export const UserRepository = {
  getUserMe,

  getUserProfile,
  putUserProfileImage,

  putUserRegistration,
  putUserPassword,

  getUserCompany,
  putUserCompanyLogo,
  putUserCompanyDocs,
};
