import { DetailListTable, Widget } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getLeadPvSystemDetailsList } from './LeadOverviewPvSystemDetails.helper';

import './LeadOverviewPvSystemDetails.component.scss';

interface Props {
  lead: Lead;
}

export const LeadOverviewPvSystemDetails: React.FC<Props> = ({
  lead,
}) => (
    <Widget
      icon={<Icofont type={'pv'} className={'c-lead-overview-pv-system__icon'} />}
      heading={I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.headline)}
    >
      <DetailListTable
        list={getLeadPvSystemDetailsList(lead.pvSystem)}
        filterNil={false}
        lead={lead}
        hasBottomBorder={false}
      />
    </Widget>
  );
