import { Form, FormInput, FormInputRadioGroup, FormSectionParagraph } from '+shared/components';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Card, PageHeadline, PageSubheadline, WizardContext } from '@sonnen/shared-web';
import { Formik, FormikProps } from 'formik';
import { defaultTo } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { ImpactAnalysisActions } from '../../store/+impactAnalysis.actions';
import { CostsForm, CostsFormVersion } from '../../store/+impactAnalysis.interface';
import { getCostsForm } from '../../store/+impactAnalysis.selectors';
import { ImpactAnalysisCostsFormSchema } from '../../store/schemas';
import { defaultValues, formFields } from './LeadImpactAnalysisCosts.helper';

import './LeadImpactAnalysisCosts.component.scss';

const mapDispatchToProps = mapActions({
  setCosts: ImpactAnalysisActions.setCostsForm,
});

const mapStateToProps = (state: StoreState) => ({
  costs: getCostsForm(state),
});

type Props = ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>;

interface ImpactAnalysisCostsForm {
  form: FormikProps<CostsForm>;
  currency: string;
}

const ImpactAnalysisCostsDetailed: React.FC<ImpactAnalysisCostsForm> = ({ form, currency }) => {
  return (
    <div>
      <PageSubheadline noBorder={true}>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.oneoffInvestmentCosts)}
      </PageSubheadline>

      <FormSectionParagraph>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.pvSystem)}
      </FormSectionParagraph>

      <div>
        <div className={'c-impact-analysis-costs-input-group'}>
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.pvSystemCosts)}
              name={formFields.DETAILED.PV_SYSTEM_COSTS}
              form={form}
              type={'number'}
              unit={currency}
            />
          </div>

          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.inverterAndOtherComponentsCosts)}
              name={formFields.DETAILED.INVERTER_AND_OTHER_COMPONENTS_COSTS}
              form={form}
              type={'number'}
              unit={currency}
            />
          </div>
        </div>
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.pvSystemInstallationCosts)}
            name={formFields.DETAILED.PV_SYSTEM_INSTALLATION_COSTS}
            form={form}
            type={'number'}
            unit={currency}
          />
        </div>
      </div>

      <FormSectionParagraph>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.sonnenBatterie)}
      </FormSectionParagraph>

      <div className={'c-impact-analysis-costs-input-group'}>
        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.sonnenBatterieCosts)}
            name={formFields.DETAILED.SONNEN_BATTERIE_COSTS}
            form={form}
            type={'number'}
            unit={currency}
          />
        </div>

        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.sonnenBatterieInstallationCosts)}
            name={formFields.DETAILED.SONNEN_BATTERIE_INSTALLATION_COSTS}
            form={form}
            type={'number'}
            unit={currency}
          />
        </div>
      </div>

      <FormSectionParagraph>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.meter)}
      </FormSectionParagraph>

      <div className={'c-impact-analysis-costs__column'}>
        <FormInput
          className={'c-impact-analysis-costs-input'}
          label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.meterConversionCosts)}
          name={formFields.DETAILED.METER_CONVERSION_COSTS}
          form={form}
          type={'number'}
          unit={currency}
        />
      </div>

      <FormSectionParagraph>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.additionalInstallationCosts)}
      </FormSectionParagraph>

      <div className={'c-impact-analysis-costs__column'}>
        <FormInput
          className={'c-impact-analysis-costs-input'}
          label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.otherInstallationCosts)}
          name={formFields.DETAILED.OTHER_INSTALLATION_COSTS}
          form={form}
          type={'number'}
          unit={currency}
        />
      </div>

      <PageSubheadline noBorder={true}>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.runningCosts)}
      </PageSubheadline>

      <div>
        <FormSectionParagraph>
          {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.maintenanceCosts)}
        </FormSectionParagraph>

        <div className={'c-impact-analysis-costs-input-group'}>
          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.annualInsuranceCosts)}
              name={formFields.DETAILED.ANNUAL_INSURANCE_COSTS}
              form={form}
              type={'number'}
              unit={currency}
            />
          </div>

          <div className={'c-impact-analysis-costs__column'}>
            <FormInput
              className={'c-impact-analysis-costs-input'}
              label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.annualMaintenanceCosts)}
              name={formFields.DETAILED.ANNUAL_MAINTENANCE_COSTS}
              form={form}
              type={'number'}
              unit={currency}
            />
          </div>
        </div>

        <FormSectionParagraph>
          {I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.additionalRunningCosts)}
        </FormSectionParagraph>

        <div className={'c-impact-analysis-costs__column'}>
          <FormInput
            className={'c-impact-analysis-costs-input'}
            label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.detailed.annualAdditionalCosts)}
            name={formFields.DETAILED.ANNUAL_ADDITIONAL_COSTS}
            form={form}
            type={'number'}
            unit={currency}
          />
        </div>
      </div>
    </div>
  );
};

const ImpactAnalysisCostsBasic: React.FC<ImpactAnalysisCostsForm> = ({ form, currency }) => {
  return (
    <>
      <PageSubheadline noBorder={true}>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.oneoffInvestmentCosts)}
      </PageSubheadline>

      <div className={'c-impact-analysis-costs__column'}>
        <FormInput
          className={'c-impact-analysis-costs-input'}
          label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.totalOneoffInvestmentCosts)}
          name={formFields.BASIC.TOTAL_ONE_OFF_INVESTMENT_COSTS}
          form={form}
          type={'number'}
          unit={currency}
        />
      </div>

      <PageSubheadline noBorder={true}>
        {I18n.t(T.lead.impactAnalysis._salessolution_.costs.runningCosts)}
      </PageSubheadline>

      <div className={'c-impact-analysis-costs__column'}>
        <FormInput
          className={'c-impact-analysis-costs-input'}
          label={I18n.t(T.lead.impactAnalysis._salessolution_.costs.annualRunningCosts)}
          name={formFields.BASIC.ANNUAL_RUNNING_COSTS}
          form={form}
          type={'number'}
          unit={currency}
        />
      </div>
    </>
  );
};

const LeadImpactAnalysisCostsComponent: React.FC<Props> = ({
  actions,
  costs,
  dispatch,
}) => {
  const { track } = useTracking();
  const wizard = React.useContext(WizardContext);

  const onSubmit = (values: CostsForm) => {
    actions.setCosts(values);
    if (wizard.next) {
      wizard.next();
    }
  };

  React.useEffect(() => {
    dispatch(ImpactAnalysisActions.clearImpactAnalysisRecommendation());
  }, []);

  // @TODO: REPLACE WITH DYNAMIC VALUE
  const currency = '€';

  const renderForm = (form: FormikProps<CostsForm>) => {
    switch (form.values.version) {
      case CostsFormVersion.BASIC:
        return <ImpactAnalysisCostsBasic form={form} currency={currency} />;
      case CostsFormVersion.DETAILED:
        return <ImpactAnalysisCostsDetailed form={form} currency={currency} />;
      default:
        return null;
    }
  };

  const initialValues = defaultTo(defaultValues)(costs);

  const onDetailedCostClick = (event: React.SyntheticEvent<HTMLButtonElement>) =>
    track(InteractionEvents.buttonClick({
      category: EventCategory.IMPACT_ANALYSIS,
      action: 'Detailed costs chosen',
      event,
    }));

  return (
    <Formik<CostsForm>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ImpactAnalysisCostsFormSchema}
      render={form => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <PageHeadline>
              {I18n.t(T.lead.impactAnalysis._salessolution_.costs.title)}
            </PageHeadline>

            <PageSubheadline noBorder={true}>
              {I18n.t(T.lead.impactAnalysis._salessolution_.costs.versionQuestion)}
            </PageSubheadline>

            <div className={'c-impact-analysis-costs-question'}>
              <FormInputRadioGroup
                name={formFields.VERSION}
                form={form}
                collection={[
                  {
                    label: I18n.t(T.lead.impactAnalysis._salessolution_.costs.version.basic),
                    value: CostsFormVersion.BASIC,
                  },
                  {
                    label: I18n.t(T.lead.impactAnalysis._salessolution_.costs.version.detailed),
                    value: CostsFormVersion.DETAILED,
                    onClick: onDetailedCostClick,
                  },
                ]}
              />
            </div>

            {renderForm(form)}
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadImpactAnalysisCosts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadImpactAnalysisCostsComponent);
