import * as Yup from 'yup';
import { ConfigurationForm } from '../types';
import {
  configurationBatteryFormInitial,
  ConfigurationBatterySchema,
} from './configurationBatteryForm.schema';
import {
  configurationConsumptionFormInitial,
  ConfigurationConsumptionSchema,
} from './configurationConsumptionForm.schema';
import {
  configurationPvFormInitial,
  ConfigurationPvSchema,
} from './configurationPvForm.schema';

export const ConfigurationSchema = () => Yup.object()
  .concat(ConfigurationConsumptionSchema())
  .concat(ConfigurationPvSchema())
  .concat(ConfigurationBatterySchema());

export const configurationFormInitial = (isNewBatteryAllowed: boolean): ConfigurationForm => ({
  ...configurationConsumptionFormInitial,
  ...configurationPvFormInitial,
  ...configurationBatteryFormInitial(isNewBatteryAllowed),
});
