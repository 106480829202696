import { LeadForm } from '+app/+lead/store/types';
import { FormInput, FormInputPhone, FormSectionParagraph } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

const getCountryCode = ({ values }: FormikProps<LeadForm>) => values.deliveryAddress.country;

interface Props {
  form: FormikProps<LeadForm>;
}

export const LeadNewContactDetails: React.FC<Props> = ({ form }) => (
  <>
    <FormSectionParagraph>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.contactDetails)}
    </FormSectionParagraph>

    <FormInput
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.customerEmail)}
      name={'email'}
      isRequired={true}
      bottomWarningText={I18n.t(T.lead.boc._salessolution_.form.personalDetails.emailWarning)}
    />

    <FormInputPhone
      country={getCountryCode(form)}
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.phone)}
      name={'phone'}
      isRequired={true}
    />

    <FormInputPhone
      country={getCountryCode(form)}
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.mobilePhone)}
      name={'mobilePhone'}
    />
  </>
);
