import { isLiveDataDelayed } from '+shared/store/site/site.helpers';
import { SiteLiveState } from '+shared/store/site/types/siteLiveState.interface';
import {
  AreaChartView,
  DataContainer,
  DataContainerTransformExtensionOptions,
  XYPoint,
  YValuesMatch,
} from '@kanva/charts';
import { View } from '@kanva/core';
import {
  AnalysisLastXYSeriePosition,
  EnergyFlowSeries,
  EnergyFlowSeriesKey,
  Point,
  SharedChartColors,
  TimeHelper,
} from '@sonnen/shared-web';
import { isEmpty, isNil, last } from 'lodash/fp';
import * as moment from 'moment';
import { DeepPartial } from 'redux';
import { analysisPointAccessor } from '../../store/helpers/+analysis.helpers';
import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';

export const getUnixDateFromNormalizedX = (normalizedX: number, unixStartOfDay: number) =>
  normalizedX * 60 + unixStartOfDay;

export const getVisibleDataSeriesKeys = (
  selectedDate: Date,
  tooltipEventMatch: YValuesMatch | undefined,
  selectedDataSeriesKeys: ChartDataSeriesKey[],
): ChartDataSeriesKey[] => {
  if (!tooltipEventMatch) {
    return [];
  }

  const isRealDataAvailable = realDataAvailable(selectedDataSeriesKeys, tooltipEventMatch);

  const selectedSeriesKeys: ChartDataSeriesKey[] = isRealDataAvailable
    ? Object.assign([], selectedDataSeriesKeys)
    : [];

  if (!isRealDataAvailable && selectedDataSeriesKeys.includes(EnergyFlowSeriesKey.CONSUMPTION_POWER)) {
    selectedSeriesKeys.push(EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER);
  }

  if (!isRealDataAvailable && selectedDataSeriesKeys.includes(EnergyFlowSeriesKey.PRODUCTION_POWER)) {
    selectedSeriesKeys.push(EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER);
  }

  return Object.entries(tooltipEventMatch.values).filter(([key, value]) =>
    selectedSeriesKeys.includes(key as EnergyFlowSeriesKey))
      .map(([key, value]) => key) as EnergyFlowSeriesKey[];
};

const realDataAvailable = (
  selectedDataSeriesKeys: ChartDataSeriesKey[],
  tooltipEventMatch: YValuesMatch,
) => selectedDataSeriesKeys.some(key => tooltipEventMatch.values[key] !== undefined);

export const getXYFromAreaChartSeries = (
  // TODO: Consider this chartView type
  chartView: View<any> | AreaChartView | undefined,
  dataSeries: EnergyFlowSeries,
  serieKey: EnergyFlowSeriesKey,
) => {
  const serie: Point[] = dataSeries[serieKey];

  if (
    !chartView
    || !serie
    || !last(serie)
    || !TimeHelper.isToday(TimeHelper.getDateFromUnixTime(serie[0].x || 0))
  ) { return; }

  const x = last(serie)!.x || 0;
  const y = last(serie)!.y || 0;
  const lastXYPoint = analysisPointAccessor({x, y}, serie.length, serie);

  const canvasPosition = (chartView as AreaChartView).getCanvasPositionForPoint(lastXYPoint);
  const offset = 19; // Note: offset is set due to paddings and margins in the charts

  return ({
    x: canvasPosition.absoluteX,
    y: isNil(canvasPosition.absoluteY) || isNaN(canvasPosition.absoluteY) ? 0 : canvasPosition.absoluteY + offset,
  });
};

const hasLastXYSeriePosition = (lastXYSeriePosition: XYPoint<number> | undefined) =>
  lastXYSeriePosition && lastXYSeriePosition!.x !== 0 && lastXYSeriePosition!.y !== 0;

export const getVisibleLiveBubblesData = (
  selectedDataSeriesKeys: ChartDataSeriesKey[],
  lastXYSeriePosition: AnalysisLastXYSeriePosition,
  siteLiveData: SiteLiveState | undefined,
  date: moment.MomentInput,
  isEaton: boolean,
  isMK1: boolean,
  isLoading: boolean,
) => {
  const isSeriesVisible = createIsSeriesVisible(selectedDataSeriesKeys);

  const latestLiveDate: string | undefined = siteLiveData?.timestamp;
  const isLiveDelayed = isLiveDataDelayed(latestLiveDate);

  if (isLiveDelayed || isEaton || isMK1 || isLoading || !TimeHelper.isToday(date)) { return []; }

  const visibleBubblesData = [];
  const lastXYSeriePositionForProduction = lastXYSeriePosition[EnergyFlowSeriesKey.PRODUCTION_POWER];
  const lastXYSeriePositionForConsumption = lastXYSeriePosition[EnergyFlowSeriesKey.CONSUMPTION_POWER];

  const isReversed = Boolean(
    siteLiveData
    && siteLiveData.timestamp
    && new Date(siteLiveData.timestamp).getHours() >= 20,
  );

  if (hasLastXYSeriePosition(lastXYSeriePositionForProduction)
    && isSeriesVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)) {
    visibleBubblesData.push({
      color: SharedChartColors.PRODUCTION,
      value: siteLiveData && siteLiveData.productionPower || '-',
      position: lastXYSeriePositionForProduction || { x: 0, y: 0 },
      isReversed,
    });
  }

  if (hasLastXYSeriePosition(lastXYSeriePositionForConsumption)
    && isSeriesVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)) {
    visibleBubblesData.push({
      color: SharedChartColors.CONSUMPTION,
      value: siteLiveData && siteLiveData.consumptionPower || '-',
      position: lastXYSeriePositionForConsumption || { x: 0, y: 0 },
      isReversed,
    });
  }

  return visibleBubblesData;
};

export const createIsSeriesVisible = (selectedDataSeriesKeys: ChartDataSeriesKey[]) =>
  (series: ChartDataSeriesKey) => selectedDataSeriesKeys.some(s => s === series);

export const mobileScaleOptions: DeepPartial<DataContainerTransformExtensionOptions> = {
  scale: {
    drag: true,
    scroll: false,
    selectArea: false,
  },
};

export const desktopScaleOptions: DeepPartial<DataContainerTransformExtensionOptions> = {
  scale: {
    drag: false,
    scroll: false,
    selectArea: true,
    minSelectedAreaThreshold: { x: 50, y: 0 },
  },
};

export const resolveAreaXPosition = (dataSeriesKey: string) =>
  (dataContainer: DataContainer<any>, selectedDate: moment.MomentInput) => {
    const series = dataContainer.getDataSeries(dataSeriesKey);
    if (isNil(series)) {
      return 0;
    }

    const data = series!.data.filter(point => !isNil(point.y));
    if (isEmpty(data)) {
      return 0;
    }
    const isChartFullyFilled = series.data.length === 24 * 60;

    return TimeHelper.isToday(selectedDate) && !isChartFullyFilled
      ? last(data)!.x
      : data[Math.floor(data.length / 2)].x + 1;
  };

export const hasSeries = (series: EnergyFlowSeries) => (
  !!(series && Object.keys(series).find(serieKey => {
  const serie = series[serieKey];

  return !isEmpty(serie);
  }))
);
