import { mapActions } from '+app/utils/redux/mapActions.util';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { ListItemNews, News, Portal } from '+shared/store/firebase/types/news.interface';
import { StoreState } from '+shared/store/store.interface';
import { UserRole } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import { TimeHelper } from '@sonnen/shared-web';
import { firestore } from 'firebase/app';
import * as React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state: StoreState) => ({
  userRoles: getUserProfileRoles(state),
});

const mapDispatchToProps = mapActions({
  setNewsList: FirebaseActions.setNewsList,
  setNotificationsList: FirebaseActions.setNotificationsList,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & FirebaseProps
  ;

class FirebaseSubscribeComponent extends React.PureComponent<Props> {
  unsubscribeNews?: () => void;

  constructor(props: Props) {
    super(props);

    window.addEventListener('beforeunload', () => {
      if (this.unsubscribeNews) {
        this.unsubscribeNews();
      }
    });
  }

  componentDidMount() {
    if (this.props.userRoles.length) {
      this.subscribeNews();
    }
  }

  componentDidUpdate() {
    if (this.props.userRoles.length && !this.unsubscribeNews) {
      this.subscribeNews();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeNews) {
      this.unsubscribeNews();
    }
  }

  subscribeNews = () => {
    const { firebase, actions, userRoles } = this.props;

    this.unsubscribeNews = firebase.currentNewsCollection(Portal.SP)
      .onSnapshot((querySnapshot: firestore.QuerySnapshot) => {
        const newsList: Array< ListItemNews<News> > = [];
        const notificationsList: Array< ListItemNews<News> > = [];

        querySnapshot.forEach((doc: any) => {
          const news: News = doc.data();
          const notifications: News = doc.data();
          const shouldDisplayNews = (
            news.expiryDate && news.expiryDate >= TimeHelper.todayFormated || news.expiryDate === '')
            && (news.roles
              ? userRoles.some(role => news.roles.indexOf(role) > -1
                || role === UserRole.SUPER_USER)
              : true);

          if (shouldDisplayNews) {
            newsList.push({ ...news, isActive: true });
          }

          const shouldDisplayNotifications = (news.isDisplayedAsNotification
            && news.notificationExpiryDate && news.notificationExpiryDate >= TimeHelper.todayFormated)
            && (news.roles
              ? userRoles.some(role => news.roles.indexOf(role) > -1
                || role === UserRole.SUPER_USER)
              : true);

          if (shouldDisplayNotifications) {
            notificationsList.push({ ...notifications, isActive: true });
          }
        });
        actions.setNewsList(newsList);
        actions.setNotificationsList(notificationsList);
      },
        (error: any) => console.log('Firebase error:', error),
      );
  };

  render() {
    return null;
  }
}

export const FirebaseSubscribe =
  connect(mapStateToProps, mapDispatchToProps)
  (withFirebase(FirebaseSubscribeComponent));
