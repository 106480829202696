import { makeEventDecorator } from '@sonnen/shared-web';
import {
  EventHitType,
  formatActionParts,
  formatCategory,
  formatLabelParts,
  formatPageView,
} from './eventDecorator';

enum FormActionType {
  CLIENT_ERROR = 'Client Error',
  SERVER_ERROR = 'Server Error',
  SUCCESS = 'Success',
  SUCCESS_NO_RESULTS = 'No Results',
}

type FormEventInputCommon = {
  category: string;
  formName: string;
};

type FormClientErrorEventInput = {
  errors: { [field: string]: string };
} & FormEventInputCommon;

export type FormServerErrorEventInput = {
  error: string;
} & FormEventInputCommon;

export type FormSuccessEventInput = {
  noResult?: boolean;
} & FormEventInputCommon;

const formEventDecorator = makeEventDecorator({
  category: formatCategory,
  action: formatActionParts,
  label: formatLabelParts,
  page: formatPageView,
});

const getFormPageView = (formName: string, page: string) => `/form-${formName}${page}`;

export const FormEvents = {
  clientError: formEventDecorator(({ category, formName, errors }: FormClientErrorEventInput) => {
    const errorFields = Object.keys(errors).join(',');
    const page = getFormPageView(formName, '/client-error');

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Form', formName, FormActionType.CLIENT_ERROR],
      labelParts: [errorFields],
      page,
    };
  }),
  serverError: formEventDecorator(({ category, formName, error }: FormServerErrorEventInput) => {
    const page = getFormPageView(formName, '/server-error');

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Form', formName, FormActionType.SERVER_ERROR],
      labelParts: [error],
      page,
    };
  }),
  success: formEventDecorator(({ category, formName, noResult }: FormSuccessEventInput) => { 
    const page = getFormPageView(formName, '/success');
    const formActionType = noResult ? FormActionType.SUCCESS_NO_RESULTS : FormActionType.SUCCESS;

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Form', formName, formActionType],
      labelParts: [],
      page,
    };
  }),
};
