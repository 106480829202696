import { InboxLeadListPartnerName } from '+app/+lead/+list/components/InboxLeadListPartnerName';
import { LeadEditStatusModal } from '+app/+lead/components/LeadEditStatusModal';
import { LEAD_IN_SETUP_STAGE } from '+app/router';
import { LeadOverviewHeaderWrapper } from '+lead/+overview/components';
import { LeadOverviewHeaderTitle } from '+lead/+overview/components/LeadOverviewHeaderTitle';
import { LeadCurrentStatus } from '+lead/components/LeadCurrentStatus';
import { companyCoordinatorRoles, isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { AccordionButton } from '+shared/components';
import { RestrictedToRoles } from '+shared/components/RestrictedToRoles';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { Contact } from '+shared/store/contact/types';
import { ModalId } from '+shared/store/layout';
import { isSonnenLead } from '+shared/store/lead/lead.helpers';
import { Lead } from '+shared/store/lead/types';
import { getStatus } from '+shared/store/query/query.utils';
import { UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { isEmpty } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewInvitation } from '../LeadOverviewInvitation';
import { getAssignedPartner } from './LeadOverviewHeader.helper';

import './LeadOverviewHeader.component.scss';

interface Props {
  lead: Lead;
  partnerEmployeeList?: Contact[];
  userRoles?: UserRole[];
  breadcrumbAction: () => void;
  getPartnersEmployeeList?: () => void;
  leadStage?: string;
  toggleModal: (isModalOpen: boolean, openModalId?: ModalId) => void;
  isModalOpen: boolean;
  toggleAssignLeadModal: (arg: boolean) => void;
  setChosenLead: (lead: Lead) => void;
  sendCGInvitation: (leadId: string) => void;
  sendCGInvitationQueryStatus: ReturnType<typeof getStatus>;
  salesConfigurationWarning?: boolean;
}

export const LeadOverviewHeader: React.FC<Props> =
  ({
    lead,
    leadStage,
    breadcrumbAction,
    isModalOpen,
    toggleModal,
    userRoles,
    partnerEmployeeList,
    getPartnersEmployeeList,
    toggleAssignLeadModal,
    setChosenLead,
    sendCGInvitation,
    sendCGInvitationQueryStatus,
    salesConfigurationWarning,
  }) => {
    const [isEditStatusModalOpen, setEditStatusModalOpen] = React.useState<boolean>(false);
    const [isMobileAccordionOpen, setMobileAccordionOpen] = React.useState<boolean>(false);
    const assignedPartner = partnerEmployeeList && getAssignedPartner(partnerEmployeeList, lead);
    const { trackFunction } = useTracking();

    React.useEffect(() => {
      if (getPartnersEmployeeList && isEmpty(partnerEmployeeList)) {
        getPartnersEmployeeList();
      }
    }, []);

    const openLeadEditStatusModal = trackFunction((event) => {
      setEditStatusModalOpen(true);
      toggleModal(true, ModalId.LEAD_EDIT_STATUS);
    })((event) => {
      return InteractionEvents.buttonClick({
        category: EventCategory.STATUSES,
        action: 'Update & Move to set up from Lead Overview',
        event,
      });
    });

    const openLeadAssignModal = () => {
      toggleAssignLeadModal(true);
      toggleModal(true);
      setChosenLead(lead);
    };

    const displayReassignBtn = () => (
      <button
        className={'c-lead-overview-header__reassign'}
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          openLeadAssignModal();
        }}
      >
        <Icon.Pen className={'c-lead-overview-header__reassign-icon'} />
      </button>
    );

    const displayCurrentStatus = () => leadStage !== LEAD_IN_SETUP_STAGE &&
      <LeadCurrentStatus lead={lead} setModalOpen={openLeadEditStatusModal} />;

    const displayAssignedPartner = () => userRoles &&
      <RestrictedToRoles
        allowedRoles={companyCoordinatorRoles}
        userRoles={userRoles}
      >
        <div className={'c-lead-overview-header__partner-wrapper'}>
          {assignedPartner ? (
            <div className={classNames('c-lead-overview-header__partner')}>
              <InboxLeadListPartnerName
                partner={assignedPartner}
                showRoles={false}
                compact={true}
                whiteSpacesBreak={false}
              />
            </div>
          ) : (
              <p className={'c-lead-overview-header__unassigned'}>
                {I18n.t(T.lead.list._salessolution_.unassigned)}
              </p>
            )}
          {displayReassignBtn()}
        </div>
      </RestrictedToRoles>;

    const displayLeadRelatedStatuses = () =>
      <>
        <div className={'c-lead-overview-header__lead-statuses'}>
          {isSonnenLead(lead) ?
            <StatusTile
              label={I18n.t(T.lead.overview._salessolution_.header.leadFromSonnen)}
              color={StatusTileColors.BLUE}
            /> : null}
          {lead.automaticallyConverted ?
            <StatusTile
              label={I18n.t(T.lead.overview._salessolution_.header.automaticallyConverted)}
              color={StatusTileColors.BLUE}
            /> : null}
          {isLeadSonnenNowInterested(lead) ?
            <StatusTile
              label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
              color={StatusTileColors.YELLOW}
            /> : null}
        </div>

        {
          (isSonnenLead(lead) || isLeadSonnenNowInterested(lead)) || (
            lead.automaticallyConverted
          ) ?
            <div className={'c-lead-overview-header__separator'} /> : null
        }
      </>;

    return (
      <>
        <LeadOverviewHeaderWrapper
          lead={lead}
          breadcrumbAction={breadcrumbAction}
          leadStage={leadStage}
          salesConfigurationWarning={salesConfigurationWarning}
        >
          <Media query={{ minWidth: breakpointUp('LG') }}>
            {(isMedium: boolean) => isMedium ? (
              <div className={'c-lead-overview-header'}>
                <div className={'c-lead-overview-header__upper'}>
                  <LeadOverviewHeaderTitle lead={lead} />

                  {(leadStage !== LEAD_IN_SETUP_STAGE ||
                    (userRoles === companyCoordinatorRoles && !!assignedPartner)) &&
                    displayAssignedPartner()
                  }
                </div>
                <div className={'c-lead-overview-header__lower'}>
                  <div className={'c-lead-overview-header__lower-right'}>
                    {displayLeadRelatedStatuses()}

                    <LeadOverviewInvitation
                      lead={lead}
                      isPending={sendCGInvitationQueryStatus.pending}
                      sendCGInvitation={sendCGInvitation}
                    />
                  </div>

                  {(leadStage !== LEAD_IN_SETUP_STAGE ||
                    (userRoles === companyCoordinatorRoles && !!assignedPartner)) &&
                    displayCurrentStatus()
                  }
                </div>
              </div>
            ) : (
                <div className={'c-lead-overview-header'}>
                  <div className={'c-lead-overview-header__mobile-upper'}>
                    <LeadOverviewHeaderTitle lead={lead} />

                    <div className={'c-lead-overview-header__accordion-wrapper'}>
                      <AccordionButton
                        isAccordionOpen={isMobileAccordionOpen}
                        setAccordionOpen={setMobileAccordionOpen}
                        messageOpen={I18n.t(T.lead.overview._salessolution_.header.accordion.showLess)}
                        messageClosed={I18n.t(T.lead.overview._salessolution_.header.accordion.showMore)}
                      />
                    </div>
                  </div>

                  <div className={'c-lead-overview-header__mobile-lower'}>
                    {isMobileAccordionOpen &&
                      <>
                        <div className={'c-lead-overview-header__accordion'}>
                          <div className={'c-lead-overview-header__mobile-lower-left'}>
                            {(leadStage !== LEAD_IN_SETUP_STAGE ||
                              (userRoles === companyCoordinatorRoles && !!assignedPartner)) &&
                              displayAssignedPartner()
                            }
                            <div className={classNames('', {
                              'c-lead-overview-header__lead-statuses--mobile':
                                ((isSonnenLead(lead) || isLeadSonnenNowInterested(lead))),
                            })}>

                              {displayLeadRelatedStatuses()}
                            </div>
                          </div>

                          <div className={'c-lead-overview-header__mobile-lower-right'}>
                            <LeadOverviewInvitation
                              lead={lead}
                              isPending={sendCGInvitationQueryStatus.pending}
                              sendCGInvitation={sendCGInvitation}
                            />
                          </div>
                        </div>
                        <div className={'c-lead-overview-header__separator--bottom'} />
                      </>
                    }

                    {(leadStage !== LEAD_IN_SETUP_STAGE ||
                      (userRoles === companyCoordinatorRoles && !!assignedPartner)) &&
                      <div className={'c-lead-overview__offer-statuses'}>
                        {displayCurrentStatus()}
                      </div>
                    }
                  </div>
                </div>
              )}
          </Media>
        </LeadOverviewHeaderWrapper>

        <LeadEditStatusModal
          isModalOpen={isEditStatusModalOpen && isModalOpen}
          toggleEditModal={setEditStatusModalOpen}
          toggleModal={toggleModal}
          lead={lead}
        />
      </>
    );
  };
