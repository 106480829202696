import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { ROUTES } from '+app/router/routes';
import { LeadActions } from '+shared/store/lead';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { DocumentStatus } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapPathToParams, mapToState, ofType, polling, processQuery } from '+utils/index';
import { last } from 'lodash';
import { isEmpty } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of, throwError } from 'rxjs';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOpeningDocument } from '../../+overview/store/+overview.selectors';
import { ImpactAnalysisActions } from './+impactAnalysis.actions';
import { getImpactAnalysis, getImpactAnalysisRecommendationDto } from './+impactAnalysis.selectors';
import {
  CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY,
  DELETE_IMPACT_ANALYSIS_QUERY,
  GET_IMPACT_ANALYSIS_LIST_QUERY,
  SEND_IMPACT_ANALYSIS_QUERY,
} from './+impactAnalysis.state';

type Action$ = ActionsObservable<ImpactAnalysisActions>;
type State$ = StateObservable<StoreState>;

const createImpactAnalysis$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(ImpactAnalysisActions.createImpactAnalysis),
  mergeMap(({ leadId, offerId }) => of(leadId).pipe(
    mapToState(state$),
    map(getImpactAnalysis),
    mergeMap(impactAnalysis => !impactAnalysis
      ? throwError(new Error('createImpactAnalysis$ :: cannot create impact analysis'))
      : of(impactAnalysis),
    ),
    processQuery(
      SEND_IMPACT_ANALYSIS_QUERY,
      impactAnalysis => LeadRepository.postLeadImpactAnalysis(leadId, offerId, impactAnalysis),
      { onSuccess: (res) => of(ImpactAnalysisActions.downloadImpactAnalysisFile(leadId, res.element.id)) },
    ),
  ),
  ),
);

const getImpactAnalysisDocument$ = (action$: Action$, state$: State$) => action$.pipe(
  polling({
    startOn: ImpactAnalysisActions.downloadImpactAnalysisFile,
    stopOn: [
      LeadOverviewPageActions.openOfferDocument,
      LeadOverviewPageActions.documentOpened,
      LeadOverviewPageActions.documentFailed,
    ],
    interval: 1000,
  })(({ leadId, impactAnalysisId }) => LeadRepository
    .getLeadImpactAnalysis(leadId, impactAnalysisId).pipe(
      map(res => last(res.element.documents)),
      mergeMap(document => {
        if (document !== undefined && document.status === DocumentStatus.FAILED) {
          return of(LeadOverviewPageActions.documentFailed(impactAnalysisId, 'impact-analysis', 'main'));
        }

        return of(document).pipe(
          filter(document => document !== undefined && document.status === DocumentStatus.CREATED),
          switchMap(document => LeadRepository.getLeadImpactAnalysisDocumentFile(
            leadId,
            impactAnalysisId,
            document!.id,
          )),
          withLatestFrom(state$),
          filter(([_, state]) => isOpeningDocument(state)),
          map(([url]) => LeadOverviewPageActions.openOfferDocument(url)),
        );
      }),
    )));

const getImpactAnalysisList$ = (action$: Action$, state$: State$) => merge(
  action$.pipe(ofType(LeadActions.setLead), map(action => action.lead.id)),
  action$.pipe(ofType(ImpactAnalysisActions.getImpactAnalysisList), map(action => action.leadId)),
).pipe(
  mergeMap(leadId => of(leadId).pipe(
    mapToState(state$),
    // we want the IA to be additionally fetched only on some pages
    mapPathToParams(
      ROUTES.LEAD_OVERVIEW[0],
      ROUTES.SETUP_LEAD_OVERVIEW[0],
      ROUTES.LEAD_CONFIGURATION[0],
      ROUTES.SETUP_LEAD_CONFIGURATION[0],
      ROUTES.LEAD_IMPACT_ANALYSIS[0],
      ROUTES.SETUP_LEAD_IMPACT_ANALYSIS[0],
    ),
    filter(params => !isEmpty(params)),
    mergeMap(() => of({}).pipe(
      processQuery(
        GET_IMPACT_ANALYSIS_LIST_QUERY,
        () => LeadRepository.getLeadImpactAnalysisList(leadId),
        { onSuccess: res => of(ImpactAnalysisActions.getImpactAnalysisListSuccess(leadId, res!.elements)) },
      ),
    )),
  )),
);

const deleteLeadImpactAnalysis$ = (action$: Action$) => action$.pipe(
  ofType(ImpactAnalysisActions.deleteImpactAnalysis),
  mergeMap(({ leadId, impactAnalysisId }) => of({}).pipe(
    processQuery(
      DELETE_IMPACT_ANALYSIS_QUERY,
      () => LeadRepository.deleteLeadImpactAnalysis(leadId, impactAnalysisId),
      { onSuccess: () => dataGuard(ImpactAnalysisActions.deleteImpactAnalysisSuccess)(impactAnalysisId) },
    ),
  )),
);

const sendLeadImpactAnalysis$ = (action$: Action$) => action$.pipe(
  ofType(ImpactAnalysisActions.sendImpactAnalysis),
  mergeMap(({ leadId, impactAnalysisId }) => of({}).pipe(
    processQuery(
      DELETE_IMPACT_ANALYSIS_QUERY,
      () => LeadRepository.patchLeadImpactAnalysis(leadId, impactAnalysisId),
      { onSuccess: res => of(ImpactAnalysisActions.setImpactAnalysis(leadId, res!.element)) },
    ),
  )),
);

const sendLeadImpactAnalysisRecommendation$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(ImpactAnalysisActions.createImpactAnalysisRecommendation),
  mergeMap(({ leadId, offerId }) => of({}).pipe(
    mapToState(state$),
    map(getImpactAnalysisRecommendationDto),
    mergeMap(impactAnalysis => !impactAnalysis
      ? throwError(new Error('createImpactAnalysis$ :: cannot create impact analysis recommendation'))
      : of(impactAnalysis),
    ),
    processQuery(
      CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY,
      dto => LeadRepository.postLeadImpactAnalysisRecommendation(leadId, offerId, dto),
      { onSuccess: res => of(ImpactAnalysisActions.createImpactAnalysisRecommendationSuccess(leadId, res!.meta)) },
    ),
  )),
);

export const epics = combineEpics(
  createImpactAnalysis$,
  getImpactAnalysisDocument$,
  getImpactAnalysisList$,
  deleteLeadImpactAnalysis$,
  sendLeadImpactAnalysis$,
  sendLeadImpactAnalysisRecommendation$,
);
