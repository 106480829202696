import {
  setCaseStatusAggregated,
  setCaseStatusAggregatedColor,
} from '+shared/components/CaseStatus/CaseStatus.component.helper';
import {
  CaseAggregatedStatus,
  CaseStatus,
  caseStatusFilterGroup,
} from '+shared/store/case/types/case.interface';
import { keys } from 'lodash/fp';
import { statusfilterAggregates } from './CaseList.const';

export const getStringifiedStatuses = (statuses: CaseAggregatedStatus[]) => statuses
  .reduce((list, status) =>
    list.concat(statusfilterAggregates[status]), [] as CaseStatus[],
  ).join(',');

const formatedStatuses = keys(CaseAggregatedStatus).map(status => ({
  name: CaseAggregatedStatus[status],
  stringified: getStringifiedStatuses([CaseAggregatedStatus[status]]),
}));

export const getInitialFilters = (queryStatus: string = '') => formatedStatuses
  .filter(status => queryStatus.includes(status.stringified))
  .map(status => status.name);

export const getStatusGroups = () => caseStatusFilterGroup
  .map(status => ({
    name: status,
    label: setCaseStatusAggregated(status),
    color: setCaseStatusAggregatedColor(status),
  }));

export const getAggregatedName = (status: CaseStatus) => caseStatusFilterGroup.find(
  aggregatedStatus => statusfilterAggregates[aggregatedStatus].includes(status),
);

export const CaseListHelper = {
  getStringifiedStatuses,
  getInitialFilters,
  getStatusGroups,
  getAggregatedName,
};
