import {
  LeadDso,
  LeadOffer,
  LeadProductAvailability,
  LeadProductType,
  Product,
  ProductAvailability,
} from '+shared/store/lead/types';
import { isEmpty, pick } from 'lodash';

const getLeadsWithDsoValidationPostponed = (): string[] => {
  const storageDsoValidation = localStorage && localStorage.getItem('dsoValidationPostponed');

  return storageDsoValidation ? JSON.parse(storageDsoValidation) : [];
};

export const isDsoValidationPostponed = (leadId: string): boolean => {
  const leadsArray = getLeadsWithDsoValidationPostponed();

  return leadsArray.includes(leadId);
};

export const setDsoValidationPostponed = (newLeadId: string) => {
  const leadsArray = getLeadsWithDsoValidationPostponed();
  if (!leadsArray.find(lead => lead === newLeadId)) {
    leadsArray.push(newLeadId);
  }
  localStorage.setItem('dsoValidationPostponed', JSON.stringify(leadsArray));
};

export const removeDsoValidationPostponed = (leadId: string) => {
  const leadsArray = getLeadsWithDsoValidationPostponed();
  const filteredArray = leadsArray.filter(lead => lead !== leadId);
  localStorage.setItem('dsoValidationPostponed', JSON.stringify(filteredArray));
};

export const isProductUnavailable = (dso: LeadProductAvailability, productName: LeadProductType): boolean => {
  const products: Product[] = [];

  if (!isEmpty(dso.tsos)) {
    dso.tsos.map(tso => {
      const product = tso.products.find(product => product.name === productName);

      if (product) {
        products.push(product);
      }
    });
  }
  const isAnyUnavailable = products.some(product => product.available === ProductAvailability.UNAVAILABLE);
  return isAnyUnavailable;
};

// @Note: Flat X or FlatDirect product unavailable
export const isDsoBlackListed = (
  productAvailability: LeadProductAvailability[],
  assignedDso?: LeadDso | null,
): boolean => {
  const dso = assignedDso && productAvailability.find(dso => dso.id === assignedDso.id);
  if (!dso) {
    return false;
  }

  const isFlatxProductUnavailable = isProductUnavailable(dso, LeadProductType.FLAT_X);
  const isFlatDirectUnavailable = isProductUnavailable(dso, LeadProductType.FLAT_DIRECT);

  const isBlacklisted = isFlatxProductUnavailable && isFlatDirectUnavailable;
  return isBlacklisted;
};

const hasBatteryProduct = (offer: LeadOffer) => offer.products
  .some(product => (product.productType === LeadProductType.BATTERY));
export const hasFlatXProduct = (offer: LeadOffer) => offer.products
  .some(product => (product.productType === LeadProductType.FLAT_X));
export const hasFlatDirectProduct = (offer: LeadOffer) => offer.products
  .some(product => (product.productType === LeadProductType.FLAT_DIRECT));

export const getFlatOffers = (offers: LeadOffer[]) => offers
  .filter(offer => hasFlatXProduct(offer) || hasFlatDirectProduct(offer));

export const isHardwareOnlyOffer = (offer: LeadOffer) => {

  if (!hasBatteryProduct(offer)) {
    return false;
  }

  return !hasFlatXProduct(offer) && !hasFlatDirectProduct(offer);
};

export const isFlatProduct = (productType: LeadProductType) =>
  [LeadProductType.FLAT_X, LeadProductType.FLAT_DIRECT].includes(productType);

export const getDsoFromProductAvailability = (
  productAvailability: LeadProductAvailability,
): LeadDso =>
  pick(productAvailability, ['id', 'name']);

export const mapProductAvailabilityToDsoList = (
  productAvailability: LeadProductAvailability[],
): LeadDso[] =>
  productAvailability.map(getDsoFromProductAvailability);
