import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { getConfigurationById } from '+app/+lead/store/+lead.helper';
import { mapActions } from '+app/utils';
import { FeatureName } from '+config';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { useFeature, WarningText, WarningTextSize, WarningTextTheme } from '@sonnen/shared-web';
import { Button, ButtonSize, Modal, ModalLayout } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewVppQuestion } from '../../components/LeadOverviewVppQuestion';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import { getOfferConfigurationList, getUpdatingConfigurationId } from '../../store/+overview.selectors';
import {
  getVppQuestions,
  VppQuestionsForm,
  VppQuestionsInitialValues,
  VppQuestionType,
} from './LeadOverviewVppCheckModal.helper';

import './LeadOverviewVppCheckModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
  configurationId: getUpdatingConfigurationId(state),
  configurations: getOfferConfigurationList(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  clearUpdatingConfigurationId: LeadOverviewPageActions.clearConfigurationId,
  recalculateConfiguration: LeadOverviewPageActions.recalculateConfiguration,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const LeadOverviewVppCheckModalComponent: React.FC<Props> = ({
  openModalId,
  configurationId,
  configurations,
  actions,
}) => {
  const configuration = getConfigurationById(configurations)(configurationId!);
  const vppQuestions = getVppQuestions(configuration);
  
  const { isEnabled: vppQuestionForFlatDirect } = useFeature(FeatureName.VPP_QUESTION_FOR_FLAT_DIRECT);

  const onClose = () => {
    actions.toggleModal(false);
    actions.clearUpdatingConfigurationId();
  };

  const onSubmit = (values: VppQuestionsForm) => {
    const formValues = {
      ...values,
      productionMeteringMandatory: isFlatDirectConfiguration(configuration) && vppQuestionForFlatDirect
        ? values.productionMeteringMandatory : undefined,
    };

    actions.recalculateConfiguration(configurationId!, formValues);
    onClose();
  };

  const isAnyQuestionAnsweredTrue = (form: FormikProps<VppQuestionsForm>) => Object.values(form.values).includes(true);

  return (
    <Modal
      isOpen={openModalId === ModalId.VPP_BONUS_CHECK}
      onClose={onClose}
      size={'medium'}
    >
      <div className={isFlatDirectConfiguration(configuration) && vppQuestionForFlatDirect
        ? 'c-lead-vpp-check-modal'
        : 'c-lead-vpp-check-modal--shorter'}
      >
        <Formik
          initialValues={VppQuestionsInitialValues}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={onSubmit}
          render={form => (
            <Form className={'c-lead-vpp-check-modal__form'}>
              <ModalLayout
                title={I18n.t(T.lead.offer._salessolution_.vppCheck.headline)}
                subtitle={I18n.t(T.lead.offer._salessolution_.vppCheck.headlineQuestion)}
                footer={
                  <div className={'c-lead-vpp-check-modal__buttons'}>
                    <div className={'c-lead-vpp-check-modal__buttons-wrapper'}>
                      <Button
                        className={'c-lead-vpp-check-modal__footer-button'}
                        label={I18n.t(T.general.basicActions.save)}
                        size={ButtonSize.NORMAL}
                        type={'submit'}
                        isDisabled={!!isFlatDirectConfiguration(configuration) && isAnyQuestionAnsweredTrue(form)}
                      />
                    </div>
                  </div>
                }
              >
                <div className={'c-lead-vpp-check-modal__questions-container'}>
                  {vppQuestions.map((vppQuestion: VppQuestionType, index: number) =>
                    <LeadOverviewVppQuestion
                      form={form}
                      question={vppQuestion.question}
                      optionType={vppQuestion.optionType}
                      key={`vpp-${index}`}
                      questionsFormValues={{
                        semiIndirect: form.values.semiIndirect,
                        generationPlants: form.values.generationPlants,
                        productionMeteringMandatory: form.values.productionMeteringMandatory,
                      }}
                    />,
                  )}
                </div>

                {isAnyQuestionAnsweredTrue(form) && (
                  <div className={'c-lead-vpp-check-modal__warning'}>
                    <WarningText
                      text={isFlatDirectConfiguration(configuration)
                        ? I18n.t(T.lead.offer._salessolution_.vppCheck.flatDirectError)
                        : I18n.t(T.lead.offer._salessolution_.vppCheck.error)
                      }
                      size={WarningTextSize.MEDIUM}
                      theme={isFlatDirectConfiguration(configuration) ? WarningTextTheme.ERROR : WarningTextTheme.WARNING}
                    />
                  </div>
                )}
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  );
};

export const LeadOverviewVppCheckModal =
  connect(mapStateToProps, mapDispatchToProps)(LeadOverviewVppCheckModalComponent);
