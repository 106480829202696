import { GroupName } from '+shared/components/Form/FormRichTextInput/FormRichTextInput.types';
import { Language, NewsCP, NewsFormCP, RouteName, STATUS } from '+shared/store/firebase/types';
import { formatDate } from '+utils/format.util.old';
import { getNewsTimestamp } from '+utils/news.util';

export enum FieldName {
  ID = 'id',
  COUNTRIES = 'countries',
  EXCLUDE_IN_ROUTES = 'excludeInRoutes',
  EXPIRY_DATE_TIMESTAMP = 'expiryDateTimestamp',
  MESSAGE_LONG_UK = 'messageLongUk',
  MESSAGE_LONG_US = 'messageLongUs',
  MESSAGE_LONG_DE = 'messageLongDe',
  MESSAGE_LONG_IT = 'messageLongIt',
  MESSAGE_SHORT_UK = 'messageShortUk',
  MESSAGE_SHORT_US = 'messageShortUs',
  MESSAGE_SHORT_DE = 'messageShortDe',
  MESSAGE_SHORT_IT = 'messageShortIt',
  INCLUDE_IN_ROUTES = 'includeInRoutes',
  STATUS = 'status',
  OPTIONS = 'options',
  OPTIONS_BATTERIES_CONTROLLER_TYPE = 'optionsBatteriesControllerType',
  OPTIONS_BATTERIES_ACCESSORIES = 'optionsBatteriesAccessories',
  OPTIONS_CONTRACT_TYPE = 'optionsContractType',
  OPTIONS_TARIFF_TYPE = 'optionsTariffType',
}

type Status = {
  label: STATUS;
  value: number;
};

const Status = [
  { label: STATUS.DANGER, value: 1 },
  { label: STATUS.WARNING, value: 2 },
  { label: STATUS.INFO, value: 3 },
] as Status[];

const statusCollection = Status.map(level => level.label);

const initialValues: NewsFormCP = {
  [FieldName.COUNTRIES]: [],
  [FieldName.EXPIRY_DATE_TIMESTAMP]: '',
  [FieldName.EXCLUDE_IN_ROUTES]: [],
  [FieldName.INCLUDE_IN_ROUTES]: [],
  [FieldName.MESSAGE_LONG_UK]: '',
  [FieldName.MESSAGE_LONG_US]: '',
  [FieldName.MESSAGE_LONG_DE]: '',
  [FieldName.MESSAGE_LONG_IT]: '',
  [FieldName.MESSAGE_SHORT_UK]: '',
  [FieldName.MESSAGE_SHORT_US]: '',
  [FieldName.MESSAGE_SHORT_DE]: '',
  [FieldName.MESSAGE_SHORT_IT]: '',
  [FieldName.STATUS]: STATUS.INFO,
  [FieldName.OPTIONS]: [],
  [FieldName.OPTIONS_BATTERIES_CONTROLLER_TYPE]: [],
  [FieldName.OPTIONS_BATTERIES_ACCESSORIES]: [],
  [FieldName.OPTIONS_CONTRACT_TYPE]: [],
  [FieldName.OPTIONS_TARIFF_TYPE]: [],
};

const richTextInputToolbarConfig = {
  display: [GroupName.INLINE_STYLE_BUTTONS, GroupName.BLOCK_TYPE_BUTTONS, GroupName.LINK_BUTTONS],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
  ],
  BLOCK_TYPE_DROPDOWN: [],
  BLOCK_TYPE_BUTTONS: [{label: 'UL', style: 'unordered-list-item'}],
};

const RouteNameLabels: Record<RouteName, string> = {
  [RouteName.DASHBOARD]: 'Dashboard',
  [RouteName.ANALYSIS]: 'Analysis',
  [RouteName.LIVE_STATE]: 'Live State',
  [RouteName.COMMUNITY]: 'sonnenCommunity',
  [RouteName.FLAT_OVERVIEW]: 'sonnenFlat overview',
  [RouteName.TARIFF_OVERVIEW]: '[energy / sonnenStrom / Energy tariff] overview',
  [RouteName.ENERGY_OVERVIEW]: '[energiaIt / sonnenFlatIt] overview',
  [RouteName.BATTERY_OVERVIEW]: 'sonnen Battery overview',
};

const getRouteLabel = (route: RouteName): string => RouteNameLabels[route];

const getStatusFromLevelValue = (levelValue: number) => {
  const status = Status.find(status => status.value === levelValue);
  return status && status.label;
};

const mapNewsToForm = (news: NewsCP): NewsFormCP => ({
  ...initialValues,
  [FieldName.ID]: news.id || initialValues[FieldName.ID],
  [FieldName.OPTIONS]: news.options || initialValues[FieldName.OPTIONS],
  [FieldName.COUNTRIES]: news.countries || initialValues[FieldName.COUNTRIES],
  [FieldName.EXCLUDE_IN_ROUTES]: news.excludeInRoutes || initialValues[FieldName.EXCLUDE_IN_ROUTES],
  [FieldName.INCLUDE_IN_ROUTES]: news.includeInRoutes || initialValues[FieldName.INCLUDE_IN_ROUTES],
  [FieldName.MESSAGE_SHORT_UK]: news.messages[Language.UK].short || initialValues[FieldName.MESSAGE_SHORT_UK],
  [FieldName.MESSAGE_SHORT_US]: news.messages[Language.US].short || initialValues[FieldName.MESSAGE_SHORT_US],
  [FieldName.MESSAGE_SHORT_DE]: news.messages[Language.DE].short || initialValues[FieldName.MESSAGE_SHORT_DE],
  [FieldName.MESSAGE_SHORT_IT]: news.messages[Language.IT].short || initialValues[FieldName.MESSAGE_SHORT_IT],
  [FieldName.STATUS]: getStatusFromLevelValue(news.levelValue) || initialValues[FieldName.STATUS],
  [FieldName.EXPIRY_DATE_TIMESTAMP]: formatDate(new Date(getNewsTimestamp(news) * 1000), 'YYYY-MM-DD')
    || initialValues[FieldName.EXPIRY_DATE_TIMESTAMP],
  [FieldName.OPTIONS_BATTERIES_CONTROLLER_TYPE]: news.optionsDetails.batteries.controllerType
    || initialValues[FieldName.OPTIONS_BATTERIES_CONTROLLER_TYPE],
  [FieldName.OPTIONS_BATTERIES_ACCESSORIES]: news.optionsDetails.batteries.accessories
    || initialValues[FieldName.OPTIONS_BATTERIES_ACCESSORIES],
  [FieldName.OPTIONS_CONTRACT_TYPE]: news.optionsDetails?.contracts?.contractType
    || initialValues[FieldName.OPTIONS_CONTRACT_TYPE],
  [FieldName.OPTIONS_TARIFF_TYPE]: news.optionsDetails?.contracts?.tariffType
    || initialValues[FieldName.OPTIONS_TARIFF_TYPE],
});

export const NewsFormCustomerHelper = {
  getRouteLabel,
  initialValues,
  STATUS: Status,
  statusCollection,
  richTextInputToolbarConfig,
  mapNewsToForm,
};
