import { DsoRegistrationFormBreakLine } from '+setupTool/+form/components';
import { FormInput, FormSectionParagraph } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { isOnlinePortalFieldName } from '../../store/+form.dictionary';
import { DsoRegistrationCredentials, DsoRegistrationInstallerDataInterface } from '../../store/types/dsoRegistrationForm.interface';

interface Props<T extends DsoRegistrationCredentials> {
  form: FormikProps<T>;
  isOnlinePortal: boolean;
}

export const DsoRegistrationLoginFields = <T extends DsoRegistrationCredentials>({ form, isOnlinePortal }: Props<T>) => {
  React.useEffect(() => {
    // TODO better way to do proper validation
    // TODO this two filed are no 'touched' after submit
    if (isOnlinePortal !== form.values[isOnlinePortalFieldName]) {
      form.setFieldValue(isOnlinePortalFieldName, isOnlinePortal);
    }
  }, [form.values.password, form.values.user_name]);

  return (
    <>
      <FormSectionParagraph>{'Netzbetreiber-Portal'}</FormSectionParagraph>
      <FormInput
        className={'c-dso-registration-installer-data__item'}
        form={form}
        placeholder={I18n.t(T.setupTool.installerData.userName)}
        label={I18n.t(T.setupTool.installerData.userName)}
        name={'user_name'}
      />

      <DsoRegistrationFormBreakLine />

      <FormInput
        className={'c-dso-registration-installer-data__item'}
        form={form}
        placeholder={I18n.t(T.setupTool.installerData.password)}
        label={I18n.t(T.setupTool.installerData.password)}
        name={'password'}
      />
    </>
  );
};
