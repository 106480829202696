import {
  getContractType,
  isContractActive,
  isContractTypeAnySonnenFlat,
} from '+app/+customer/+contract/store/+contract.selectors';
import { getMeterInstallation, getSite } from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { CustomerRouteParams } from '+app/router';
import { ContractType } from '+app/shared/store/contract/contract.helpers';
import {
  getSiteLiveStateQueryStatus,
  getSiteStatistics,
} from '+app/shared/store/site/site.selectors';
import {
  getSiteConsumedEnergyFormated,
  getSiteGridPurchasedEnergyFormated,
} from '+app/shared/store/site/site.selectors';
import { FeatureName } from '+config/featureFlags';
import { DetailListTable } from '+shared/components';
import { WarningBox } from '+shared/components/WarningBox';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline, useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import { ContractEnergyMeterSection } from '../../components';
import { ContractEnergyMeterError } from '../../components/ContractEnergyMeterError';
import { getContract, getContractQueryStatus } from '../../store/+contract.selectors';
import {
  getDefaultContractDetailsItems,
  getFlatProductDetailsItems,
  getMaxEnergyAmount,
} from './CustomerContract.helper';

type Props = ReturnType<typeof mapStateToProps>;
type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const meterInstallation = getMeterInstallation(site);

  return {
    contract: getContract(state),
    constractStatisticStatus: getSiteLiveStateQueryStatus(state),
    totalStatistics: getSiteStatistics(state),
    isAnySonnenFlat: isContractTypeAnySonnenFlat(state),
    contractType: getContractType(state),
    contractStatus: getContractQueryStatus(state),
    isContractActive: isContractActive(state),
    consumedEnergy: getSiteConsumedEnergyFormated(state),
    gridPurchaseEnergy: getSiteGridPurchasedEnergyFormated(state),
    meterInstallation,
  };
};

const CustomerContractComponent: React.FC<Props> = ({
  contract,
  isAnySonnenFlat,
  constractStatisticStatus,
  contractStatus,
  isContractActive,
  consumedEnergy,
  gridPurchaseEnergy,
  meterInstallation,
}) => {
  const total = (contract && contract.contractType === ContractType.SONNEN_FLAT_X)
    ? gridPurchaseEnergy
    : consumedEnergy;
  const shouldDisplayContractEnergyMeterSection = useFeature(FeatureName.CONTRACT_ENERGY_METER_SECTION).isEnabled;

  return (
    <div className={'c-customer-contract'}>
      {contractStatus.error
        ? <WarningBox />
        : contract && (
          <>
            {isAnySonnenFlat && isContractActive && (
              <>
                {constractStatisticStatus.error && <ContractEnergyMeterError />}
                {total && shouldDisplayContractEnergyMeterSection &&
                  <ContractEnergyMeterSection
                    maxEnergyAmount={getMaxEnergyAmount(contract.amountOfFreeEnergy)}
                    total={total}
                    isGrayOut={constractStatisticStatus.error}
                  />}
              </>
            )}
            <div className={'o-grid'}>
              <section className={'o-grid__column o-grid__column--md-6'}>
                <PageSubheadline mediumGap={true}>
                  {I18n.t(T.customerSingle.contract.sonnenFlat.secondaryHeadline)}
                </PageSubheadline>
                <DetailListTable
                  list={isAnySonnenFlat
                    ? getFlatProductDetailsItems(contract, meterInstallation)
                    : getDefaultContractDetailsItems(contract)
                  }
                  filterNil={true}
                  isValueEllipsis={false}
                />
              </section>
            </div>
          </>
        )
      }
    </div>
  );
};

export const CustomerContract = connect(mapStateToProps)(CustomerContractComponent);
