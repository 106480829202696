import { formFields } from '+app/+lead/components/SetupLeadEditStatusModal';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getSetupLeadStatusTooltipTranslations = () => ({
  [formFields.DSO_REGISTRATION_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.tooltipStatus.dsoRegistrationCompleted),
  [formFields.DSO_COMMISSIONING_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.tooltipStatus.dsoCommissioningCompleted),
  [formFields.BNETZA_REGISTRATION_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.tooltipStatus.bNetzARegistrationCompleted),
  [formFields.TAX_OFFICE_REGISTRATION_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.tooltipStatus.taxOfficeRegistrationCompleted),
  [formFields.HW_COMMISSIONING_COMPLETED]:
    I18n.t(T.shared._salessolution_.lead.tooltipStatus.hardwareCommissioningCompleted),
  [formFields.DSO_METER_INSTALLED]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.dsoMeterInstalled),
  [formFields.FLAT_OFFER_SENT]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.flatOfferSent),
  [formFields.FLAT_OFFER_ACCEPTED]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.flatOfferAccepted),
  [formFields.FLAT_ORDER_CONFIRMED]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.flatOrderConfirmed),
  [formFields.START_OF_DELIVERY_FIXED]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.startOfDeliveryFixed),
  [formFields.VPP_PREPARATION_DONE]: I18n.t(T.shared._salessolution_.lead.tooltipStatus.vppPreparationDone),
});
