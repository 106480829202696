import { LeadForm } from '+app/+lead/store/types';
import { FormInput, FormInputRadio, FormInputSelect, FormSectionParagraph } from '+shared/components';
import { salutation } from '+shared/store/lead';
import { LeadCustomerType } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadNewCustomerDetails.component.scss';

interface Props {
  form: FormikProps<LeadForm>;
}

export const LeadNewCustomerDetails: React.FC<Props> = ({ form }) => (
  <>
    <FormSectionParagraph>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.customerDetails)}
    </FormSectionParagraph>

    <div className={'c-lead-new-customer-details__radio-group'}>
      <FormInputRadio
        form={form}
        label={I18n.t(T.lead.boc._salessolution_.dictionary.customerType[LeadCustomerType.PRIVATE])}
        name={'customerType'}
        value={LeadCustomerType.PRIVATE}
      />
      <FormInputRadio
        form={form}
        label={I18n.t(T.lead.boc._salessolution_.dictionary.customerType[LeadCustomerType.BUSINESS])}
        name={'customerType'}
        value={LeadCustomerType.BUSINESS}
      />
    </div>

    <FormInputSelect
      form={form}
      collection={salutation}
      name={'salutation'}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.salutation)}
      mapper={key => I18n.t(T.lead.boc._salessolution_.dictionary.salutation[key])}
      isRequired={true}
    />

    <FormInput
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.title)}
      name={'title'}
    />

    {form.values.customerType === LeadCustomerType.BUSINESS && (
      <FormInput
        form={form}
        label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.company)}
        name={'company'}
        isRequired={true}
      />
    )}

    <FormInput
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.firstName)}
      name={'firstName'}
      isRequired={true}
    />

    <FormInput
      form={form}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.lastName)}
      name={'lastName'}
      isRequired={true}
    />
  </>
);
