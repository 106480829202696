import { PATHS } from '+app/router';
import { FeatureName } from '+config';
import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { checkRoleRestriction } from '+shared/components/RestrictedToRoles';
import { UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, Feature, Icon, MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import './LeadListHeader.component.scss';

interface Props {
  buttonAction: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  inboxLeadsTotalCount: number;
  userRoles: UserRole[];
}

const navLinkProps = {
  border: false,
  faded: true,
  type: 'desktop' as NavLinkType,
};

export const InboxLeadListHeader: React.FC<Props> = ({ buttonAction, inboxLeadsTotalCount, userRoles }) => (
  <PageHeader
    tabNav={
      checkRoleRestriction(userRoles, [UserRole.COMPANY_COORDINATOR]) ?
        <TabNav
          items={
            <>
              <NavLink {...navLinkProps} action={PATHS.LEADS_INBOX()}>
                <Icon.Envelope className={'c-lead-list-header__nav-icon'} />
                {I18n.t(T.lead.list._salessolution_.leadsInbox)} ({inboxLeadsTotalCount})
                  </NavLink>
              <NavLink {...navLinkProps} action={PATHS.LEADS()}>
                <Icon.ManWithDocument className={'c-lead-list-header__nav-icon'} />
                {I18n.t(T.lead.list._salessolution_.pageHeadline)}
              </NavLink>
            </>
          }
        />
        : null
    }
    isExpanded={true}
  >
    <div className={'c-lead-list-header__headline'}>
      <span>{I18n.t(T.lead.list._salessolution_.leadsHeadline)}</span>

      <Media query={MediaQuery.UP_XS}>
        {(isMedium: boolean) => (
          isMedium ? (
            <Button
              label={I18n.t(T.lead.list._salessolution_.newLeadButton)}
              size={ButtonSize.SECONDARY}
              isNotElevatedOnHover={true}
              onClick={buttonAction}
            />
          ) : (
              <button className={'c-lead-list-header__button--mobile'} onClick={buttonAction}>
                <Icon.Plus className={'c-lead-list-header__icon'} />
              </button>
            )
        )}
      </Media>
    </div>
  </PageHeader>
);
