import { DetailList, Widget } from '+shared/components';
import { useLocaleContext } from '+utils/react/locale.provider';
import { T } from '@sonnen/shared-i18n/service';
import { CardNotification, Icofont } from '@sonnen/shared-web';
import { capitalize } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeBackupBoxDetailList, generateBoxHeader } from './BackupBoxCard.helper';
import { BackupBoxCard as BackupBoxCardInterface } from './types/BackupBoxCard.types';

interface Props {
  backupBox: BackupBoxCardInterface;
}

export const BackupBoxCard: React.FC<Props> = ({ backupBox }) => {
  const { locale } = useLocaleContext();

  return (
    <Widget
      icon={<Icofont type={'backup-box'} />}
      heading={generateBoxHeader(backupBox.deviceType)}
      notification={
        <CardNotification
          type={'success'}
          icon={<Icofont type={'tick'} />}
        >
          {capitalize(I18n.t(T.general.states.connected))}
        </CardNotification>
      }
    >
      <DetailList
        vertical={true}
        list={factorizeBackupBoxDetailList(locale)(backupBox)}
        filterNil={true}
      />
    </Widget>
  );
};
