import { GenericNews, ListItemNews, News, Portal } from '+shared/store/firebase/types/news.interface';
import * as moment from 'moment';

export const isSPNews = (news: GenericNews): news is News => news.portals.includes(Portal.SP);

export const isSPListNews = (news: ListItemNews): news is ListItemNews<News> =>
  news.portals.includes(Portal.SP);

export const getNewsTimestamp = (news: GenericNews): number => {
  if (isSPNews(news)) {
    return moment(news.expiryDate).isSameOrAfter(news.notificationExpiryDate)
      ? (moment(news.expiryDate || 0).valueOf() / 1000)
      : (moment(news.notificationExpiryDate || 0).valueOf() / 1000);
  } else {
    return news.expiryDateTimestamp instanceof Date
      ? news.expiryDateTimestamp.getTime() / 1000
      : news.expiryDateTimestamp.seconds || 0;
  }
};

export const getNewsStatus = (news: GenericNews): boolean => {
  let newsDate;

  if (isSPNews(news)) {
    if (!news.expiryDate) {
      return true;
    }

    if (!news.notificationExpiryDate || moment(news.expiryDate).isSameOrAfter(news.notificationExpiryDate)) {
      newsDate = news.expiryDate;
    } else {
      newsDate = news.notificationExpiryDate;
    }
  } else {
    newsDate = news.expiryDateTimestamp;
  }

  return moment(newsDate).isSameOrAfter(moment().format('YYYY-MM-DD'));
};
