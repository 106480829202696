import { ConsumptionForm } from '../../store/+impactAnalysis.interface';

export const defaultValues: ConsumptionForm = {
  baselineTariffPerKwh: '',
  baselineFeePerMonth: '',
  averageElectricityPriceIncreasePerYear: 3,
};

export const formFields: Record<string, keyof typeof defaultValues> = {
  CURRENT_TARIFF_GROSS: 'baselineTariffPerKwh',
  CURRENT_ELECTRICITY_COSTS_GROSS: 'baselineFeePerMonth',
  AVERAGE_ELECTRICITY_PRICE_INCREASE: 'averageElectricityPriceIncreasePerYear',
};
