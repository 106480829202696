import { NewsFormCustomerHelper } from '+app/+newsChannel/components/NewsFormCustomer/NewsFormCustomer.helpers';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import {
  GenericNews,
  Language,
  News,
  NewsCP,
  NewsFormCP,
  Options,
  Portal,
} from '+shared/store/firebase/types/news.interface';
import { isSPNews } from '+utils/news.util';
import { T } from '@sonnen/shared-i18n/service';
import {  ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { NewsFormCustomer } from '../../components/NewsFormCustomer';
import { NewsFormPartner } from '../../components/NewsFormPartner';

import './EditNews.component.scss';

interface OwnProps {
  news?: GenericNews;
  isModalOpen: boolean;
  closeModal: () => void;
  onSuccessfullyEdit: (news: GenericNews) => void;
  newsPortal: Portal;
}

type Props =
  & OwnProps
  & FirebaseProps
  ;

export class EditNewsComponent extends React.Component<Props> {
  state = {
    success: false,
    loading: false,
    error: '',
    formState: FormState.FORM,
  };

  onPPSubmit = (values: News) => {
    this.setState({ loading: true });

    const { firebase } = this.props;
    const { level, tag } = values;
    const levelValue = level ? level.value : 0;
    const tagValue = tag ? tag.value : '';

    firebase.editNews(Portal.SP, { ...values, levelValue, tagValue })
    .then(() => this.setState({
      loading: false,
      success: true,
      error: '',
      formState: FormState.FORM_SUCCESS,
    }, () => this.props.onSuccessfullyEdit({
      ...values,
      levelValue,
      tagValue,
    })))
    .catch((err: Error) => this.setState({
      loading: false,
      error: err.message,
      formState: FormState.FORM_ERROR,
    }));
  };

  isCPEditSubmit = (form: NewsFormCP): form is NewsFormCP & NewsCP => !!form.id;

  onCPSubmit = (values: NewsFormCP) => {
    const { firebase } = this.props;
    if (!this.isCPEditSubmit(values)) {
      return this.setState({
        loading: false,
        error: 'Couldn\'t find news ID',
        formState: FormState.FORM_ERROR,
      });
    }
    this.setState({ loading: true });

    const status = NewsFormCustomerHelper.STATUS.find(status => status.label === values.status);

    const news: NewsCP = {
      countries: values.countries,
      excludeInRoutes: values.excludeInRoutes,
      expiryDateTimestamp: new Date(values.expiryDateTimestamp),
      id: values.id,
      includeInRoutes: values.includeInRoutes,
      levelValue: status && status.value || 1,
      messages: {
        [Language.UK]: {
          long: values.messageLongUk,
          short: values.messageShortUk,
        },
        [Language.US]: {
          long: values.messageLongUs,
          short: values.messageShortUs,
        },
        [Language.DE]: {
          long: values.messageLongDe,
          short: values.messageShortDe,
        },
        [Language.IT]: {
          long: values.messageLongIt,
          short: values.messageShortIt,
        },
      },
      portals: [Portal.CP],
      options: values.options,
      optionsDetails: {
        [Options.BATTERIES]: {
          controllerType: values.optionsBatteriesControllerType,
          accessories: values.optionsBatteriesAccessories,
        },
        [Options.CONTRACTS]: {
          contractType: values.optionsContractType,
          tariffType: values.optionsTariffType,
        },
      },
    };

    firebase.editNews(Portal.CP, news)
      .then(() => this.setState({
        loading: false,
        success: true,
        error: '',
        formState: FormState.FORM_SUCCESS,
      }, () => this.props.onSuccessfullyEdit(news)))
      .catch((err: Error) => this.setState({
        loading: false,
        error: err.message,
        formState: FormState.FORM_ERROR,
      }));
  };

  showEmptyForm = () => this.setState({ success: false });

  onClose = () => {
    this.props.closeModal();
    this.setState({ formState: FormState.FORM, error: '' });
  };

  onTryAgain = () => this.setState({ formState: FormState.FORM });

  render() {
    const { news, isModalOpen } = this.props;
    const { loading, error, formState } = this.state;

    return(
      <FormModal
        isModalOpen={isModalOpen}
        className={'sw-edit-news__modal'}
        size={'medium'}
        status={formState}
        headlineSuccess={I18n.t(T.news.channel.modal.success)}
        headlineFailure={I18n.t(T.news.channel.modal.error)}
        onClose={this.onClose}
        onTryAgain={this.onTryAgain}
        defaultForm={
          news && <ModalLayout title={I18n.t(T.news.channel.modal.title.editNews)}>
            {isSPNews(news) ? (
              <NewsFormPartner
                onSubmit={this.onPPSubmit}
                loading={loading}
                error={error}
                isEdit={true}
                news={news}
              />
            ) : (
              <NewsFormCustomer
                onSubmit={this.onCPSubmit}
                isLoading={loading}
                formError={error}
                isEdit={true}
                news={news}
              />)}
          </ModalLayout>
        }
      />
    );
  }
}

export const EditNews = withFirebase(EditNewsComponent);
