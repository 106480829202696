import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { BatteryElectricUnits, BatteryWithProduct } from '+shared/store/battery';
import { getStatus } from '+shared/store/query/query.utils';
import { SiteLiveState } from '+shared/store/site/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeDetailList, isSB10Battery } from './CustomerBatteryWidget.helper';

import sonnenBatterieSB10Img from '+assets/img/sonnen-batterie-sB10.jpg';
import sonnenBatterieImg from '+assets/img/sonnen-batterie.jpg';

import './CustomerBatteryWidget.scss';

interface Props {
  battery: BatteryWithProduct | undefined;
  siteLiveState: SiteLiveState;
  batteryElectricUnits: BatteryElectricUnits[];
  queryStatus: ReturnType<typeof getStatus>;
  action?: () => void;
  className?: ClassValue;
}

export const CustomerBatteryWidget: React.SFC<Props> = ({
  action,
  battery,
  siteLiveState,
  batteryElectricUnits,
  className,
  queryStatus,
}) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icofont type={'sonnenbatterie'} />}
    heading={I18n.t(T.customerSingle.overview.battery.headline)}
    image={isSB10Battery(battery) ? sonnenBatterieSB10Img : sonnenBatterieImg}
    action={action}
  >
    {queryStatus.pending && (
      <Loader />
    )}
    {queryStatus.success && battery && (
      <DetailList
        list={factorizeDetailList(battery, siteLiveState, batteryElectricUnits)}
        filterNil={true}
      />
    )}
    {queryStatus.error && (
      <WidgetWarning
        type={'warning'}
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.battery.error)}
      </WidgetWarning>
    )}
  </Widget>
);
