import { DsoRegistrationFormResponse } from '+shared/store/setupTool/types';
import { DsoCommissioningInterface } from './types';

export const getDsoCommissioningProps = (data: DsoRegistrationFormResponse): DsoCommissioningInterface => {
  return (
    data.pvr
    && data.pvr.fields
    && data.pvr.fields.dsoReferenceNumber
    && data.pvr.fields.pvCommissioningDate
    && data.pvr.fields.batteryCommissioningDate
  ) ? ({
    dso_reference_number: data.fields.dsoReferenceNumber,
    pv_commissioning_date: data.fields.pvCommissioningDate,
    battery_commissioning_date: data.fields.batteryCommissioningDate,
    dso_reference_number_readonly_pvr: data.pvr.fields.dsoReferenceNumber,
    pv_commissioning_date_readonly_pvr: data.pvr.fields.pvCommissioningDate,
    battery_commissioning_date_readonly_pvr: data.pvr.fields.batteryCommissioningDate,
  }) : ({
    dso_reference_number: data.fields.dsoReferenceNumber,
    pv_commissioning_date: data.fields.pvCommissioningDate,
    battery_commissioning_date: data.fields.batteryCommissioningDate,
  });
};
