import {
  FEED_IN_MANAGEMENT_07,
  FEED_IN_MANAGEMENT_FRE,
  MEASURING_CONCEPT_SURPLUS,
} from '+setupTool/+form/store/+form.dictionary';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { DsoRegistrationMeasuringDeviceInterface } from '../types';

export const DsoRegistrationMeasuringDeviceSchemaPvAndBattery = () => Yup.object().shape({
  meter_number: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters))
    .required(I18n.t(T.register.alert.requiredField)),
  type_of_grid_reference_meter: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  measuring_concept: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  feed_in_management: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  serial_number_FRE: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters)),
  house_connection_power_A: Yup
    .number()
    .integer(I18n.t(T.validation.wholeNumber))
    .min(0, I18n.t(T.validation.greaterThanOrEqual))
    .max(999, I18n.t(T.validation.lessThanOrEqual))
    .nullable(true)
    .default(null)
    .required(I18n.t(T.register.alert.requiredField)),
  meter_fuse: Yup
    .number()
    .integer(I18n.t(T.validation.wholeNumber))
    .min(0, I18n.t(T.validation.greaterThanOrEqual))
    .nullable(true)
    .default(null)
    .required(I18n.t(T.register.alert.requiredField)),
  meter_fastening_type: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
});

export const DsoRegistrationMeasuringDeviceSchemaBatteryOnly = () => Yup.object().shape({
  meter_number: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters))
    .required(I18n.t(T.register.alert.requiredField)),
  type_of_grid_reference_meter: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  serial_number_FRE: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters)),
  house_connection_power_A: Yup
    .number()
    .integer(I18n.t(T.validation.wholeNumber))
    .min(0, I18n.t(T.validation.greaterThanOrEqual))
    .max(999, I18n.t(T.validation.lessThanOrEqual))
    .nullable(true)
    .default(null)
    .required(I18n.t(T.register.alert.requiredField)),
  meter_fuse: Yup
    .number()
    .integer(I18n.t(T.validation.wholeNumber))
    .min(0, I18n.t(T.validation.greaterThanOrEqual))
    .nullable(true)
    .default(null)
    .required(I18n.t(T.register.alert.requiredField)),
  meter_fastening_type: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
});

export const getDsoRegistrationMeasuringDeviceSchema = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationMeasuringDeviceSchemaPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationMeasuringDeviceSchemaBatteryOnly;
      break;
    default:
      return DsoRegistrationMeasuringDeviceSchemaPvAndBattery;
  }
};

export const DsoRegistrationMeasuringDeviceInitialPvAndBattery: DsoRegistrationMeasuringDeviceInterface = {
  meter_number: '',
  type_of_grid_reference_meter: '',
  measuring_concept: MEASURING_CONCEPT_SURPLUS,
  feed_in_management: FEED_IN_MANAGEMENT_07,
  serial_number_FRE: '',
  house_connection_power_A: null,
  meter_fuse: null,
  meter_fastening_type: '',
};

export const DsoRegistrationMeasuringDeviceInitialBatteryOnly: DsoRegistrationMeasuringDeviceInterface = {
  meter_number: '',
  type_of_grid_reference_meter: '',
  serial_number_FRE: '',
  house_connection_power_A: null,
  meter_fuse: null,
  meter_fastening_type: '',
};

export const getDsoRegistrationMeasuringDeviceInitial = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationMeasuringDeviceInitialPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationMeasuringDeviceInitialBatteryOnly;
      break;
    default:
      return DsoRegistrationMeasuringDeviceInitialPvAndBattery;
  }
};

export const saveableMeasuringDeviceFieldsPvAndBattery = [
  'meter_number',
  'type_of_grid_reference_meter',
  'measuring_concept',
  'feed_in_management',
  'serial_number_FRE',
  'house_connection_power_A',
  'meter_fuse',
  'meter_fastening_type',
];

export const saveableMeasuringDeviceFieldsBatteryOnly = [
  'meter_number',
  'type_of_grid_reference_meter',
  'serial_number_FRE',
  'house_connection_power_A',
  'meter_fuse',
  'meter_fastening_type',
];

export const getSaveableMeasuringDeviceFields = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return saveableMeasuringDeviceFieldsPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return saveableMeasuringDeviceFieldsBatteryOnly;
      break;
    default:
      return saveableMeasuringDeviceFieldsPvAndBattery;
  }
};
