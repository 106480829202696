import { StatusManagementTooltipLocation } from '+app/+lead/+list/store/+leadList.state';
import {
  LeadListBattery,
  LeadListLocation,
  LeadListName,
  SetupLeadStatuses,
} from '+lead/+list/components';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { getActiveStatusManagementStatuses } from '+setupTool/store/+setupTool.helpers';
import { ButtonDirection } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { breakpointDown, Status, StatusType, TableCell } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './SetupLeadListTableItem.component.scss';

const mapDispatchToProps = mapActions({
  changeLeadStatus: LeadPageActions.changeLeadStatus,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

interface OwnProps {
  item: Lead;
  onLeadClick: (leadId: string) => void;
  onLeadOfferClick: (leadId: string) => void;
  isStatusModalOpen: boolean;
  toggleStatusModal: (arg: boolean) => void;
  userCompanyName: string;
}

export const SetupLeadListTableItemComponent: React.FC<Props> = ({
 actions,
 item,
 onLeadClick,
 onLeadOfferClick,
 isStatusModalOpen,
 toggleStatusModal,
 userCompanyName,
}) => {
  const changeLeadStatus = (leadId: string) =>
    (...statusNames: LeadStatusName[]) =>
      actions.changeLeadStatus(leadId, statusNames);

  const configurationButton = () =>
    <button
      className={'c-lead-list-table-item__round-btn-wrapper'}
      onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        onLeadOfferClick(item.id);
      }}
    >
      <p>{I18n.t(T.lead.list._salessolution_.startSetupConfigurationButton)}</p>
      <div
        className={
          'c-round-button c-round-button--info c-lead-list-table-item__round-btn'}
        style={{'--size': 22, '--direction': ButtonDirection.RIGHT} as React.CSSProperties}
      >
        <i className={'c-round-button__inner'}>
          <span className={'icofont-angle'}/>
        </i>
      </div>
    </button>;

  const SetupLeadListTableItemComponent = (tableClassName: string) => <>
    <div className={`c-lead-list-table-item c-lead-list-table-item--setup`} onClick={() => onLeadClick(item.id)}>
      <Media query={{ maxWidth: breakpointDown('LG')}}>
        {(isMedium: boolean) => isMedium ? (
          <>
            <TableCell className={`c-lead-list-table-item__cell c-lead-list-table-item__cell--mobile-top`}>
              <LeadListName item={item}/>
              <LeadListLocation item={item}/>
              <LeadListBattery item={item}/>
            </TableCell>

            <TableCell className={`c-lead-list-table-item__cell c-lead-list-table-item__cell--mobile-bottom`}>
              <SetupLeadStatuses
                lead={item}
                statuses={getActiveStatusManagementStatuses(item.status)}
                isModalOpen={isStatusModalOpen}
                toggleModal={toggleStatusModal}
                changeLeadStatus={changeLeadStatus(item.id)}
                userCompanyName={userCompanyName}
                gtmClassName={'gtm-open-sm-button--list'}
                location={StatusManagementTooltipLocation.SETUP_LIST}
              />

              {configurationButton()}
            </TableCell>
          </>
          ) : (
          <>
            <TableCell className={`c-lead-list-table-item__cell ${tableClassName}`}>
              <LeadListName item={item}/>
            </TableCell>
            <TableCell className={`c-lead-list-table-item__cell ${tableClassName}`}>
              <LeadListLocation item={item}/>
            </TableCell>
            <TableCell className={`c-lead-list-table-item__cell ${tableClassName}`}>
              <LeadListBattery item={item}/>
            </TableCell>
            <TableCell className={`c-lead-list-table-item__cell ${tableClassName}-status`}>
              <SetupLeadStatuses
                lead={item}
                statuses={getActiveStatusManagementStatuses(item.status)}
                isModalOpen={isStatusModalOpen}
                toggleModal={toggleStatusModal}
                changeLeadStatus={changeLeadStatus(item.id)}
                userCompanyName={userCompanyName}
                gtmClassName={'gtm-open-sm-button--list'}
                location={StatusManagementTooltipLocation.SETUP_LIST}
              />
            </TableCell>
            <TableCell className={
              `c-lead-list-table-item__cell ${tableClassName}-configuration`}>
              {configurationButton()}
            </TableCell>
          </>
        )}
      </Media>
    </div>
    {item.isNewlyAssigned &&
    <Status
      type={StatusType.SUCCESS}
      label={I18n.t(T.lead.list._setuptool_.newLead)}
    />
    }

    {isLeadSonnenNowInterested(item) &&
      <Status
        type={StatusType.WARNING}
        label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
      />
    }
  </>;

  return (
    <>
      {SetupLeadListTableItemComponent('c-lead-list-table-item__cell--setup')}
    </>
  );
};

export const SetupLeadListTableItem = connect(
  null,
  mapDispatchToProps)
(SetupLeadListTableItemComponent);
