import { useInputEvent } from '+shared/hooks/useInputEvent';
import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { validateField } from '../Form.helpers';
import { FormFieldError } from '../FormFieldError';
import { FormFieldLabel } from '../FormFieldLabel';

import './FormTextarea.component.scss';

export interface FormTextareaProps<T> {
  name: string;
  label?: string;
  placeholder?: string;
  form: FormikProps<T>;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: ClassValue;
  hasNoGap?: boolean;
  id?: string;
  validate?: any;
  isRequired?: boolean;
}

const ObservableInputElement = ({ form, field, ...props }: FieldProps) => {
  const { onBlur, onChange } = useInputEvent(field);

  return (
    <textarea
      {...props}
      {...field}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export const FormTextarea = <T extends any = any>({
  form,
  name,
  children,
  label,
  placeholder,
  disabled = false,
  className,
  hasNoGap = false,
  id,
  validate,
  isRequired = false,
}: FormTextareaProps<T>) => {
  const validation = validateField({name, form});

  return (
    <div className={classNames('c-form-textarea', className, {
      'c-form-textarea--no-gap': hasNoGap && !validation.hasError,
    })}>
      {label &&
        <FormFieldLabel
          label={label}
          isDisabled={disabled}
          isRequired={isRequired}
        />
      }
      <div className={'c-form-textarea__container'}>
        <Field
          component={ObservableInputElement}
          className={classNames('c-form-textarea__field', {
            'c-form-textarea__field--error': validation.hasError,
            'c-form-textarea__field--disabled': disabled,
          })}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          validate={validate}
        />
        <div className={'c-form-textarea__component'}>
          {children}
        </div>
      </div>
      <FormFieldError name={name} form={form}/>
    </div>
  );
};
