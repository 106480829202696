import { getLeadData } from '+lead/+overview/store/+overview.selectors';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { getCompletionNotificationDocumentSubmitQueryStatus } from '+setupTool/+form/store/+form.selectors';
import {
  getVppCompletionNotificationDocument,
  getVppCompletionNotificationRegeneratedAt,
  getVppGenerateCompletionNotificationQueryStatus,
  VppDocumentationActions,
} from '+setupTool/+vppDocumentation/store';
import { removeFile, sendFile } from '+setupTool/store/+setupTool.helpers';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { FormUploadInput } from '+shared/components';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { BoldParagraph, Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import './VppCompletionNotification.component.scss';

interface OwnProps<T> {
  form: FormikProps<T>;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLeadData(state),
  userId: getUserProfileId(state),
  submissionId: getSubmissionId(state),
  completionNotificationDocument: getVppCompletionNotificationDocument(state),
  completionNotificationRegeneratedAt: getVppCompletionNotificationRegeneratedAt(state),
  generateCompletionNotificationQueryStatus: getVppGenerateCompletionNotificationQueryStatus(state),
  completionNotificationDocumentQueryStatus: getCompletionNotificationDocumentSubmitQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  generateCompletionNotification: VppDocumentationActions.generateCompletionNotification,
  sendDsoDocumentsFile: DsoRegistrationFormActions.sendDsoDocumentsFile,
  removeDsoDocumentsFile: DsoRegistrationFormActions.removeDsoDocumentsFile,
});

type Props<T> =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps<T>;

const VppCompletionNotificationComponent = <T, >({
  lead,
  userId,
  submissionId,
  completionNotificationDocument,
  completionNotificationRegeneratedAt,
  generateCompletionNotificationQueryStatus,
  completionNotificationDocumentQueryStatus,
  actions,
  form,
}: Props<T>) => {
  const isCompletionNotificationUploadVisible = !!completionNotificationRegeneratedAt;
  const [isDownloadButtonClicked, setDownloadButtonClicked] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      const completionNotificationDocumentLink = document.getElementById('vpp-completion-notification__download-link');
      if (isDownloadButtonClicked && completionNotificationDocumentLink) {
        completionNotificationDocumentLink.click();
      }
    }, 1000);
  }, [completionNotificationDocument]);

  return (
    <div className={'vpp-completion-notification'}>
      <p className={'vpp-completion-notification__title'}>
        {I18n.t(T.setupTool.vppDocumentation.completionNotification.title)}
      </p>
      <p className={'vpp-completion-notification__description'}>
        {I18n.t(T.setupTool.vppDocumentation.completionNotification.description)}
      </p>
      <Button
        className={'vpp-completion-notification__download-button'}
        size={ButtonSize.SECONDARY}
        theme={ButtonTheme.OUTLINE}
        label={I18n.t(T.setupTool.form.download)}
        isLoading={generateCompletionNotificationQueryStatus.pending}
        onClick={() => {
          actions.generateCompletionNotification();
          setDownloadButtonClicked(true);
        }}
      />
      {!!completionNotificationDocument &&
        <a
          id={'vpp-completion-notification__download-link'}
          href={completionNotificationDocument.upload.url}
          download={completionNotificationDocument.fileName}
        />
      }
      {isCompletionNotificationUploadVisible && <div>
        <p className={'vpp-completion-notification__description'}>
          {I18n.t(T.setupTool.vppDocumentation.completionNotification.pdfDescription)}
        </p>
        <BoldParagraph className={'c-setup-tool__upload-input-label'}>
          {I18n.t(T.setupTool.vppDocumentation.completionNotification.upload)}
        </BoldParagraph>
        <FormUploadInput
          form={form}
          name={FileCategoryName.COMPLETION_NOTIFICATION}
          accept={['application/pdf']}
          maxSize={20971520}
          maxSizeLabel={'20 MB'}
          label={I18n.t(T.setupTool.info.chooseFile)}
          placeholder={I18n.t(T.setupTool.info.fileUpTo, { extension: 'PDF', maxFileSize: '20 MB' })}
          isImmediatelySentToBackend={true}
          showErrorOnlyIfTouched={true}
          onUpload={(file) =>
            lead && sendFile(
            file,
            FileCategoryName.COMPLETION_NOTIFICATION,
            userId,
            lead.id,
            submissionId,
            actions.sendDsoDocumentsFile,
            )}
          isLoading={completionNotificationDocumentQueryStatus.pending}
          onReset={(documentId) => {
            if (documentId) {
              removeFile(
                documentId,
                submissionId,
                actions.removeDsoDocumentsFile,
              );
            }
          }}
        />
      </div>}
    </div>
  );
};

export const VppCompletionNotification = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VppCompletionNotificationComponent);
