import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as routerHelper from '../../router/store/router.helpers';
import { StoreState } from '../../shared/store/store.interface';

export const matchPath = (pathsToMatch: string[] | string) => {
  const matchRegexpsForPath = routerHelper.matchPath(pathsToMatch);
  return (o: Observable<StoreState>) => o.pipe(
    filter(state => matchRegexpsForPath(state.router.location.pathname)),
  );
};
