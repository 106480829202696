import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

export const CaseListTableNoResults: React.FC = () => (
  <InfoBanner
    icon={<Icon.Ufo />}
    title={I18n.t(T.cases.list.noResultsHeadline)}
    subtitle={I18n.t(T.cases.list.noResultsSubHeadline)}
    isThemeCard={true}
  />
);
