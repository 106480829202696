import { Config } from '+config';
import { provideCurrentLocale } from '../../../../i18n/i18n.provider';

import customerPortalImageDe from '+assets/img/customer-portal-de.svg';
import customerPortalImageEn from '+assets/img/customer-portal-en.svg';

export const getCustomerPortalLink = (batterySerialNumber?: string) => batterySerialNumber
  ? `${Config.OLD_PORTAL_URL}/${batterySerialNumber}`
  : `${Config.OLD_PORTAL_URL}`;

export const getCustomerPortalImage = (): string => {
  const activeLocale = provideCurrentLocale();
  switch (activeLocale) {
    case 'de':
      return customerPortalImageDe;
    default:
      return customerPortalImageEn;
  }
};
