import ImageTableOfContents from '+assets/img/impact-analysis/pages-preview/1-table-of-contents.jpg';
import
ImageFinancialAnalysisDetailedCalculation
  from '+assets/img/impact-analysis/pages-preview/10-financial-analysis-detailed-calculation-20-years.jpg';
import
ImageFinancialAnalysisAmortizationChart
  from '+assets/img/impact-analysis/pages-preview/11-financial-analysis-amortization-chart.jpg';
import
ImageEnvironmentalContribution
  from '+assets/img/impact-analysis/pages-preview/12-environmental-contribution.jpg';
import ImageYourSpecialist from '+assets/img/impact-analysis/pages-preview/13-your-specialist.jpg';
import ImageProductsAndBenefits from '+assets/img/impact-analysis/pages-preview/2-products-and-benefits.jpg';
import ImageProductsExplanation from '+assets/img/impact-analysis/pages-preview/3-products-explanation.jpg';
import ImageProductsInteraction from '+assets/img/impact-analysis/pages-preview/4-products-interaction.jpg';
import ImageSonnenFlatInfo from '+assets/img/impact-analysis/pages-preview/5-sonnenFlat-info.jpg';
import ImageSonnenFlatContract from '+assets/img/impact-analysis/pages-preview/6-sonnenFlat-contract.jpg';
import ImageEnergyFutureStandard from '+assets/img/impact-analysis/pages-preview/7-without-sonnenFlat.png';
import ImageEnergyFutureSonnenFlat from '+assets/img/impact-analysis/pages-preview/8-sonnenFlat-cashback.png';
import
ImageFinancialAnalysisCurrentProviderVsSonnen
  from '+assets/img/impact-analysis/pages-preview/9-financial-analysis-current-provider-vs-sonnen.jpg';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import { PdfCreationForm } from '../../store/+impactAnalysis.interface';

export const formFields = {
  pages: {
    tableOfContentsPage: {
      name: 'pages.tableOfContentsPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.tableOfContents,
      isRemovable: false,
      previewImage: ImageTableOfContents,
    },
    // ---------------------
    productsAndBenefitsPage: {
      name: 'pages.productsAndBenefitsPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.productsAndBenefits,
      isRemovable: true,
      previewImage: ImageProductsAndBenefits,
    },
    // ---------------------
    productsExplanationPage: {
      name: 'pages.productsExplanationPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.productsExplanation,
      isRemovable: true,
      previewImage: ImageProductsExplanation,
    },
    productsInteractionPage: {
      name: 'pages.productsInteractionPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.productsInteraction,
      isRemovable: true,
      previewImage: ImageProductsInteraction,
    },
    // ---------------------
    sonnenFlatInfoPage: {
      name: 'pages.sonnenFlatInfoPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.sonnenFlatInfo,
      isRemovable: true,
      previewImage: ImageSonnenFlatInfo,
    },
    sonnenFlatContractPage: {
      name: 'pages.sonnenFlatContractPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.sonnenFlatContract,
      isRemovable: true,
      previewImage: ImageSonnenFlatContract,
    },
    // ---------------------
    energyFutureStandardPage: {
      name: 'pages.energyFutureStandardPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.energyFutureStandard,
      isRemovable: true,
      previewImage: ImageEnergyFutureStandard,
    },
    energyFutureSonnenFlatPage: {
      name: 'pages.energyFutureSonnenFlatPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.energyFutureSonnenFlat,
      isRemovable: true,
      previewImage: ImageEnergyFutureSonnenFlat,
    },
    // ---------------------
    financialAnalysisCurrentProviderVsSonnenPage: {
      name: 'pages.financialAnalysisCurrentProviderVsSonnenPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.financialAnalysisCurrentProviderVsSonnen,
      isRemovable: true,
      previewImage: ImageFinancialAnalysisCurrentProviderVsSonnen,
    },
    financialAnalysisDetailedCalculation20YearsPage: {
      name: 'pages.financialAnalysisDetailedCalculation20YearsPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.financialAnalysisDetailedCalculation20Years,
      isRemovable: true,
      previewImage: ImageFinancialAnalysisDetailedCalculation,
    },
    financialAnalysisAmortizationChartPage: {
      name: 'pages.financialAnalysisAmortizationChartPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.financialAnalysisAmortizationChart,
      isRemovable: true,
      previewImage: ImageFinancialAnalysisAmortizationChart,
    },
    // ---------------------
    environmentalContributionPage: {
      name: 'pages.environmentalContributionPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.environmentalContribution,
      isRemovable: true,
      previewImage: ImageEnvironmentalContribution,
    },
    // ---------------------
    yourSpecialistPage: {
      name: 'pages.yourSpecialistPage',
      label: T.lead.impactAnalysis._salessolution_.pdfCreation.pages.yourSpecialist,
      isRemovable: false,
      previewImage: ImageYourSpecialist,
    },
  },
};

export const defaultValues: PdfCreationForm = {
  pages: {
    tableOfContentsPage: true,
    // ---------------------
    productsAndBenefitsPage: true,
    // ---------------------
    productsExplanationPage: true,
    productsInteractionPage: true,
    // ---------------------
    sonnenFlatInfoPage: true,
    sonnenFlatContractPage: true,
    energyFutureStandardPage: true,
    energyFutureSonnenFlatPage: true,
    // ---------------------
    financialAnalysisCurrentProviderVsSonnenPage: true,
    financialAnalysisDetailedCalculation20YearsPage: true,
    financialAnalysisAmortizationChartPage: true,
    // ---------------------
    environmentalContributionPage: true,
    // ---------------------
    yourSpecialistPage: true,
  },
};

export const defaultValuesNotProfitable: PdfCreationForm = {
  pages: {
    tableOfContentsPage: true,
    // ---------------------
    productsAndBenefitsPage: true,
    // ---------------------
    productsExplanationPage: true,
    productsInteractionPage: true,
    // ---------------------
    sonnenFlatInfoPage: true,
    sonnenFlatContractPage: true,
    energyFutureStandardPage: false,
    energyFutureSonnenFlatPage: false,
    // ---------------------
    financialAnalysisCurrentProviderVsSonnenPage: true,
    financialAnalysisDetailedCalculation20YearsPage: true,
    financialAnalysisAmortizationChartPage: true,
    // ---------------------
    environmentalContributionPage: true,
    // ---------------------
    yourSpecialistPage: true,
  },
};

const onEnergyFutureStandardValueChange = (form: FormikProps<PdfCreationForm>) =>
  ({ value }: FormInputSubscriptionPayload<PdfCreationForm>) => {
    form.setFieldValue(formFields.pages.energyFutureSonnenFlatPage.name, value);
  };

const onEnergyFutureSonnenFlatValueChange = (form: FormikProps<PdfCreationForm>) =>
  ({ value }: FormInputSubscriptionPayload<PdfCreationForm>) => {
    form.setFieldValue(formFields.pages.energyFutureStandardPage.name, value);
  };

export const getOnChangeFunction = (pageKey: string, form: FormikProps<PdfCreationForm>) => {
  switch (formFields.pages[pageKey]) {
    case formFields.pages.energyFutureStandardPage:
      return onEnergyFutureStandardValueChange(form);
    case formFields.pages.energyFutureSonnenFlatPage:
      return onEnergyFutureSonnenFlatValueChange(form);
    default:
      return;
  }
};
