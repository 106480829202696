import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import './SetupToolInfo.component.scss';

interface Props {
  contentText: string;
  hasCloseButton?: boolean;
}

export const SetupToolInfo: React.FC<Props> = ({
  contentText,
  hasCloseButton = true,
}) => {
  const [setupToolInfoDisabled, setSetupToolInfoDisabled] = React.useState(false);

  return !setupToolInfoDisabled ? (
    <div className={`c-setup-tool-info`}>
      <div className={'c-setup-tool-info__content'}>
        <Icon.InfoCircle className={'c-setup-tool-info__icon'} />

        <p className={'c-setup-tool-info__text'}>
          <Translate value={contentText} dangerousHTML={true} />
        </p>
      </div>

      {hasCloseButton &&
        <Button
          className={'c-setup-tool-info__button'}
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.OUTLINE}
          label={I18n.t(T.setupTool.infoBox.buttonLabel)}
          onClick={() => setSetupToolInfoDisabled(true)}
        />
      }
    </div>
  ) : null;
};
