import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { ContactListRouteQueryParams, LeadListRouteQueryParams, PATHS } from '+app/router';
import { getRouteQueryParams } from '+app/router/store/router.selectors';
import {
  Container,
  PageBlock,
  Pagination,
  PaginationSummary,
  SearchField,
} from '+shared/components';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { PageHeadline } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import { ContactListTable } from '../../components/ContactListTable';
import {
  getContactCollection,
  getContactsCollectionQueryStatus,
  getContactsCollectionTotalCount,
} from '../../store/+contactList.selectors';

import './ContactList.component.scss';

const mapStateToProps = (state: StoreState) => ({
  contactCollection: getContactCollection(state),
  contactCollectionTotalCount: getContactsCollectionTotalCount(state),
  contactCollectionQueryStatus: getContactsCollectionQueryStatus(state),
  routerQueryParams: getRouteQueryParams(state) as Required<ContactListRouteQueryParams>,
});

const mapDispatchToProps = mapActions({
  pushContactsPage: (queryParams: ContactListRouteQueryParams) => push(PATHS.CONTACTS(queryParams)),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<LeadListRouteQueryParams>
  ;

const ContactListComponent: React.FC<Props> = ({
  actions,
  contactCollection,
  contactCollectionTotalCount,
  contactCollectionQueryStatus,
  routerQueryParams,
}) => {
  const shouldShowPagination = contactCollectionQueryStatus.success || contactCollectionQueryStatus.error;

  const onPageChange = (page: number | undefined) =>
    actions.pushContactsPage({ ...routerQueryParams, page: String(page) });

  const onSearch = (search?: string) =>
    actions.pushContactsPage({ search, page: '1' });

  return (
    <Container>
      <PageBlock>
        <PageHeadline smallGap={true}>
          <div className={'c-contact-list-headline__inner'}>
            {I18n.t(T.contacts.title)}
          </div>
        </PageHeadline>
      </PageBlock>
      <PageBlock
        desktopDirection={'row'}
        mobileDirection={'row'}
      >
        <SearchField
          value={routerQueryParams.search}
          onSearch={onSearch}
          placeholder={I18n.t(T.contacts.searchPlaceholder)}
        />
      </PageBlock>
      <PageBlock
        desktopDirection={'row'}
        mobileDirection={'row'}
      >
        <PaginationSummary
          i18n={T.contacts.list.contactsSummary}
          page={routerQueryParams.page}
          recordsTotal={contactCollectionTotalCount}
        />
      </PageBlock>
      <PageBlock>
        <ContactListTable
          items={contactCollection}
          isPending={contactCollectionQueryStatus.pending}
        />
      </PageBlock>
      {shouldShowPagination && (
        <PageBlock className={`c-contact-list__pagination`}>
          <Pagination
            page={routerQueryParams.page}
            recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
            recordsTotal={contactCollectionTotalCount}
            onPageChange={onPageChange}
          />
        </PageBlock>
      )}
    </Container>
  );
};

export const ContactList = connect(mapStateToProps, mapDispatchToProps)(ContactListComponent);
