import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep, SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { mapActionTypeToErrorMessage } from '+setupTool/store/+setupTool.helpers';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { DsoRegisterActions, DsoRegisterRepository } from '+shared/store/setupTool';
import { Document } from '+shared/store/setupTool/types';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapToState, ofType, processQuery } from '+utils/index';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { merge, of, timer } from 'rxjs';
import { exhaustMap, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { PVRegisterActions } from './+pvRegister.actions';
import { POLLING_TIME, PvRegisterStatus } from './+pvRegister.dictionary';
import { getPVRSubmission } from './+pvRegister.selectors';
import {
  PV_REGISTER_CONFIG_SUBMIT_QUERY,
  PV_REGISTER_PATCH_SUBMISSION_QUERY,
  PV_REGISTER_POLLING_SUBMIT_QUERY,
} from './+pvRegister.state';

type Action$ = ActionsObservable<PVRegisterActions>;
type State$ = StateObservable<StoreState>;

const saveSubmissionAndRegisterPV$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(PVRegisterActions.saveSubmissionAndRegisterPV),
  mapToState(state$),
  map((state) => SetupToolActions.saveSubmissionWithCallback(
    DsoRegisterActions.postRegisterPV({
      submissionId: getSubmissionId(state)!,
      queryKey: PV_REGISTER_CONFIG_SUBMIT_QUERY,
    }),
    {
      step: SubmissionStep.PV_REGISTER,
      query: PV_REGISTER_PATCH_SUBMISSION_QUERY,
    },
  )),
);

const registerPV$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(PVRegisterActions.submitRegisterPV),
  mapToState(state$),
  map((state) => DsoRegisterActions.postRegisterPV({
      submissionId: getSubmissionId(state)!,
      queryKey: PV_REGISTER_CONFIG_SUBMIT_QUERY,
    }),
  ),
);

const registerPVSuccess$ = (action$: Action$) => action$.pipe(
  ofType(PVRegisterActions.submitRegisterPVFinished),
  map(action => action.submission),
  mergeMap((submission) => of(PVRegisterActions.submitRegisterPVSuccess(submission))),
);

const setGeneratedDocuments$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setGeneratedDocuments),
  map(action => action.data),
  map(documents => documents.filter(document => document.stepType === SubmissionStep.PV_REGISTER
    || (document.upload && document.upload.category === FileCategoryName.PVR_CONFIRMATION),
  )),
  mergeMap(dataGuard(PVRegisterActions.setPVRegisterGeneratedDocuments)),
);

const setLatestModification$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setLatestModificationDate),
  map(action => action.data[SubmissionStepCamelCase.PV_REGISTER]),
  mergeMap(dataGuard(PVRegisterActions.setPVRegisterModificationDate)),
);

const registerPVError$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postRegisterPVFailure),
  map(action => action.error),
  map(PVRegisterActions.submitRegisterPVError),
);

const getSubmissionsSuccess$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(PVRegisterActions.getSubmissionsSuccess, PVRegisterActions.getSubmissionsFailure),
  mapToState(state$),
  map(getPVRSubmission),
  filter(submission => !!submission
    && submission.status !== (PvRegisterStatus.IN_PROGRESS || PvRegisterStatus.TRIGGERED)),
  mergeMap(submission => {
    let action;

    switch (submission!.status) {
      case PvRegisterStatus.ERROR:
      case PvRegisterStatus.CAPTCHA:
      case PvRegisterStatus.SKIPPED:
        action = PVRegisterActions.submitRegisterPVError();
        break;
      default:
        action = PVRegisterActions.submitRegisterPVFinished(submission);
    }

    return merge(
      of(action),
      of(PVRegisterActions.stopGettingSubmissions()),
    );
  }),
);

const getSubmissionsPolling$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postRegisterPVSuccess, PVRegisterActions.startGettingSubmissions),
  switchMap(params => (
    timer(0, POLLING_TIME).pipe(
      takeUntil(action$.pipe(
        ofType(PVRegisterActions.stopGettingSubmissions),
      )),
      exhaustMap(() => of({}).pipe(processQuery(
        PV_REGISTER_POLLING_SUBMIT_QUERY, /* @TODO STORE IT IN VARIABLE*/
        () => DsoRegisterRepository.getSubmission(params.response.data.id), {
        onSuccess: res => merge(
          of(
            PVRegisterActions.getSubmissionsSuccess(res.data),
          ),
          dataGuard(
            PVRegisterActions.setPVRegisterGeneratedDocuments,
          )(res.data.documents.filter((doc: Document) => doc.stepType === SubmissionStep.PV_REGISTER
            || (doc.upload && doc.upload.category === FileCategoryName.PVR_CONFIRMATION),
          )),
          dataGuard(
            PVRegisterActions.setPVRegisterModificationDate,
          )(res.data.stepsUpdatedAt[SubmissionStepCamelCase.PV_REGISTER]),
        ),
        onFailure: err => merge(
          of(PVRegisterActions.getSubmissionsFailure(err)),
          of(SetupToolActions.appendError(mapActionTypeToErrorMessage(PVRegisterActions.startGettingSubmissions.type))),
        ),
      },
      ))),
    )
  )),
);

export const epics = combineEpics(
  saveSubmissionAndRegisterPV$,
  registerPV$,
  registerPVSuccess$,
  registerPVError$,
  getSubmissionsSuccess$,
  getSubmissionsPolling$,
  setGeneratedDocuments$,
  setLatestModification$,
);
