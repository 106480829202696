import { SiteStatistics } from '+shared/store/site/types';
import { StatisticsResolution, StatisticsSelectedDate, StatisticsSeriesKey, TimeHelper } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { hasMeasurement } from './+analysis.helpers';

export const transformToSiteStatisticsFilters = (statisticsSelectedDate: StatisticsSelectedDate) => {
  const { date, resolution, period } = statisticsSelectedDate;
  return ({
    start: moment(date).startOf(period).toDate(),
    end: moment(date).endOf(period).toDate(),
    resolution,
  });
};

export const defaultStatisticsFilters = {
  start: TimeHelper.getStartOfDate()(TimeHelper.todayDate),
  end: TimeHelper.getEndOfDate()(TimeHelper.todayDate),
  resolution: StatisticsResolution.HOUR,
};

export const hasSiteStatistics = (statistics: SiteStatistics | undefined) => Boolean(
  !isNil(statistics)
  && !isNil(statistics.measurementMethod)
  && (hasMeasurement(statistics, StatisticsSeriesKey.PRODUCED_ENERGY)
    || hasMeasurement(statistics, StatisticsSeriesKey.CONSUMED_ENERGY)
    || hasMeasurement(statistics, StatisticsSeriesKey.GRID_FEEDIN_ENERGY)
    || hasMeasurement(statistics, StatisticsSeriesKey.GRID_PURCHASE_ENERGY))
  && statistics.measurementMethod !== 'meter-error',
);
