import { FormStatus } from '+shared/components/FormStatus';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './FormFailure.component.scss';

interface Props {
  onTryAgain: () => void;
  headline: string;
}

export const FormFailure: React.FC<Props> = ({ onTryAgain, headline }) => (
  <FormStatus
    isSuccess={false}
    headline={headline}
    footer={
      <Button
        label={I18n.t(T.general.basicActions.tryAgain)}
        size={ButtonSize.NORMAL}
        onClick={onTryAgain}
        className={'c-form-failure__button'}
      />
    }
  />
);
