import { LeadConfigurationActionBox } from '+app/+lead/components/LeadConfigurationActionBox';
import { ActionBoxColors } from '+app/+lead/store/types';
import { mapActions } from '+app/utils';
import { RoundButton } from '+shared/components';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewPageActions } from '../../store/+overview.actions';

import './LeadOverviewVppActionBox.component.scss';

interface ComponentProps {
  configurationId: string;
  isVppCheckDone: boolean;
  isConfigurationTileDisabled: boolean;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  setConfigurationId: LeadOverviewPageActions.setConfigurationId,
});

export const LeadOverviewVppActionBoxComponent: React.FC<Props> = ({
  isVppCheckDone,
  actions,
  configurationId,
  isConfigurationTileDisabled,
}) => {
  const themeColor = isVppCheckDone ? ActionBoxColors.GREEN : ActionBoxColors.BLUE;
  const isDisabled = !isVppCheckDone && isConfigurationTileDisabled;

  const handleOnClick = () => {
    if (isVppCheckDone) {
      return;
    } else {
      actions.toggleModal(true, ModalId.VPP_BONUS_CHECK);
      actions.setConfigurationId(configurationId);
    }
  };

  return (
    <LeadConfigurationActionBox
      onClickAction={handleOnClick}
      mainColor={themeColor}
      hoverable={!isVppCheckDone}
      hasLeftBorder={!isVppCheckDone && !isDisabled}
      disabled={isDisabled}
    >
      <div className={'c-lead-configuration-vpp-action-box'}>
        <div className={'c-lead-configuration-vpp-action-box__upper'}>
          <div className={classNames('c-lead-configuration-vpp-action-box__header', {
            'c-lead-configuration-vpp-action-box__header--no-margin': isVppCheckDone,
          })}>
            <div className={classNames({
              'c-lead-configuration-vpp-action-box__header--gray': isDisabled,
              'c-lead-configuration-vpp-action-box__header--blue': !isVppCheckDone && !isDisabled,
            })}>
              {I18n.t(T.lead._salessolution_.vppQuestions.box.header)}
            </div>
            {isVppCheckDone && (
              <StatusTile
                label={I18n.t(T.lead._salessolution_.vppQuestions.box.vppCheckDone)}
                color={StatusTileColors.GREEN}
                noMargin={true}
              />
            )}
          </div>
          <div className={'c-lead-configuration-vpp-action-box__body'}>
            {!isVppCheckDone &&
              <p className={classNames('c-lead-configuration-vpp-action-box__body-text', {
                'c-lead-configuration-vpp-action-box__body-text--gray': isDisabled,
              })}>
                {I18n.t(T.lead._salessolution_.vppQuestions.box.fillBeforeOffer)}
              </p>
            }
          </div>
        </div>
        <div className={'c-lead-configuration-vpp-action-box__action-container'}>
          {!isVppCheckDone && (
            <RoundButton
              label={<Icon.Plus className={'c-lead-configuration-vpp-action-box__icon-start'} />}
              color={isDisabled ? 'inactive' : 'info'}
            />
          )}
        </div>
      </div>
    </LeadConfigurationActionBox>
  );
};

export const LeadOverviewVppActionBox =
  connect(null, mapDispatchToProps)(LeadOverviewVppActionBoxComponent);
