import { VideoYoutube } from '+app/shared/store/youtube/types/youtube.interface';

export interface Video extends VideoYoutube {
  id: string;
  youtubeId: string;
  categories: CategoryName[];
}

export interface VideoFirebase {
  id: string;
  youtubeId: string;
  categories: CategoryName[];
}

export interface SelectCategory {
  label: string;
  hash: string;
}

export enum CategoryName {
  ALL_VIDEOS = '',
  PORTAL = 'portal',
  OTHER = 'other',
  INSTALLATION = 'installation',
  SONNEN_BATTERY = 'sonnen-battery',
  SONNEN_FLAT = 'sonnen-flat',
  SONNEN_CHARGER = 'sonnen-charger',
  SONNEN_DRIVE = 'sonnen-drive',
}
