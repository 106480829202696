import { Customer } from '+shared/store/customer/types/customer.interface';
import { Query } from '+shared/store/query/index';
import { PersistedState } from 'redux-persist';

export const GET_COLLECTION_QUERY = 'getCollectionQuery';

export interface CustomerListPageState extends PersistedState {
  collection: Customer[];
  getCollectionQuery: Query<any>;
  installedBatteriesCount: number;
  totalResourceCount: number;
}

export const initialState = {
  collection: [],
  getCollectionQuery: {},
  installedBatteriesCount: 0,
  totalResourceCount: 0,
};
