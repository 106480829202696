import { CustomerNote, FormState } from '+app/+customer/containers/CustomerNote';
import { Widget, WidgetWarning } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon, LinkButton, Loader } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n'; 

import './CustomerNoteWidget.component.scss';

interface Props {
  customerId: string;
  customerNote: string;
}

export const CustomerNoteWidget: React.FC<Props> = ({ customerId, customerNote }) => (
  <CustomerNote
    customerId={customerId}
    customerNote={customerNote}
    renderForm={({ onFormSubmit, note, editNote, formState, isEditActive }) => (
      <Widget
        icon={<Icon.NotesWithPencil className={'c-customer-note-widget__icon'} />}
        heading={I18n.t(T.customerSingle.overview.notes.headline)}
        customAction={(!isEditActive && customerNote) &&
          <LinkButton onClick={editNote}>
            {I18n.t(T.general.basicActions.edit)}
          </LinkButton>
        }
      >
        <Formik
          initialValues={{ note: note || customerNote }}
          onSubmit={onFormSubmit}
          render={(form) => (
            <Form>
              <FormTextarea
                form={form}
                placeholder={I18n.t(T.customerSingle.overview.notes.placeholder)}
                name={'note'}
                disabled={(!isEditActive && !!customerNote) || formState === FormState.FORM_PENDING}
              >
                {formState === FormState.FORM_PENDING && <Loader className={'c-customer-note-widget-form__loader'} />}
              </FormTextarea>
              {(isEditActive || !customerNote) && (
                <div className={'c-customer-note-widget-form__btn gtm-customer-note-widget-form__btn'}>
                  <Button
                    type={'submit'}
                    theme={ButtonTheme.PRIMARY}
                    size={ButtonSize.PILL}
                    label={I18n.t(T.general.basicActions.save)}
                    isDisabled={ formState === FormState.FORM_PENDING }
                  />
                </div>
              )}
            </Form>
          )}
        />
      </Widget>
    )}
    renderError={(error) => (
      <WidgetWarning
        type={'warning'}
        icon={'warning'}
      >
        {error}
      </WidgetWarning>
    )}
  />
);
