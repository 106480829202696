import { LeadStatusName, OfferProductStatus } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const mapProductStatusToLeadStatus = (hardwareStatus: OfferProductStatus) => ({
  [OfferProductStatus.SENT]: LeadStatusName.HW_OFFER_SENT,
  [OfferProductStatus.ACCEPTED]: LeadStatusName.HW_OFFER_ACCEPTED,
  [OfferProductStatus.CONFIRMED]: LeadStatusName.HW_ORDER_CONFIRMED,
})[hardwareStatus];

export const hardwareProductStatusNameMapper = (hardwareStatus: OfferProductStatus) => ({
  [OfferProductStatus.SENT]: I18n.t(T.shared._salessolution_.lead.status.hwOfferSent),
  [OfferProductStatus.ACCEPTED]: I18n.t(T.shared._salessolution_.lead.status.hwOfferAccepted),
  [OfferProductStatus.CONFIRMED]: I18n.t(T.shared._salessolution_.lead.status.hwOrderConfirmed),
})[hardwareStatus];
