import { ContactListRouteQueryParams } from '+app/router';
import { Config } from '+config';
import { from } from 'rxjs';
import { jsonApiClient } from '../../network/network.client';
import {
  ContactCertificateData,
  ContactData,
} from './types';

const BASE_URL = `${Config.API_URL}/contacts?include=roles&page[size]=10`;

const getContactList = ({ page, search }: ContactListRouteQueryParams = {}) => {
  const searchParam = search ? `%${search}%` : undefined;

  return from(
    jsonApiClient.getList<ContactData>(`${BASE_URL}`)
      .filter('email', searchParam)
      .pageNumber(page)
      .resolveIncluded()
      .send(),
  );
};

const getContactCertificateList = (id: string) => from(
  jsonApiClient.getList<ContactCertificateData>(`${Config.API_URL}/contacts/${id}/certificates`)
    .send());

export const ContactRepository = {
  getContactList,
  getContactCertificateList,
};
