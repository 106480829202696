import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeDetailList } from './CustomerAnalysisWidget.helper';

import analysisSvg from '+assets/img/analysis.svg';
import { SiteLiveState } from '+shared/store/site/types';

interface Props {
  siteLiveState: SiteLiveState;
  queryStatus: ReturnType<typeof getStatus>;
  action?: () => void;
  className?: ClassValue;
}

export const CustomerAnalysisWidget: React.SFC<Props> = ({
  siteLiveState, queryStatus, action, className,
}) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icofont type={'diagram'} />}
    heading={I18n.t(T.customerSingle.overview.analysis.headline)}
    image={analysisSvg}
    action={action}
  >
    {queryStatus.pending && (
      <Loader />
    )}
    {queryStatus.success && siteLiveState && (
      <DetailList list={factorizeDetailList(siteLiveState)}/>
    )}
    {queryStatus.error && (
      <WidgetWarning 
        type={'warning'} 
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.analysis.error)}
      </WidgetWarning>
    )}
  </Widget>
);
