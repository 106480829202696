import * as classNames from 'classnames';
import * as React from 'react';

import './RoundButton.component.scss';

export enum ButtonDirection {
  TOP = 90,
  RIGHT = 180,
  BOTTOM = 270,
  LEFT = 0,
}

interface Props {
  label: React.ReactNode;
  onClick?: (e?: any) => void;
  size?: number;
  direction?: ButtonDirection;
  color?: 'info' | 'success' | 'warning' | 'danger' | 'inactive' | 'inactive-dark';
  type?: 'button' | 'submit' | 'reset';
  className?: ClassValue;
  outline?: boolean;
}

export const RoundButton: React.SFC<Props> = ({
  label,
  onClick,
  size = 22,
  direction = ButtonDirection.RIGHT,
  color = 'info',
  type,
  className,
  outline,
}) => {
  const classes = classNames('c-round-button', {
    [`c-round-button--${color}`]: color,
    [`c-round-button--outline`]: outline,
  }, className);

  return (
    <button
      className={classes}
      onClick={onClick}
      style={{ '--size': size, '--direction': direction } as React.CSSProperties}
      type={type}
    >
      <div className="c-round-button__inner">
        {label}
      </div>
    </button>
  );
};
