import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LogoutFromFirebase.component.scss';

interface OwnProps {
  onLogout: () => void;
}

type Props =
  & FirebaseProps
  & OwnProps
  ;

interface Error {
  code: string;
  message: string;
}

export class LogoutFromFirebaseComponent extends React.Component<Props> {
  state = {
    error: '',
  };

  logout = () => {
    const { firebase, onLogout } = this.props;
    firebase.firestoreSignout()
      .then(() => onLogout())
      .catch((err: Error) => this.setState({error: err.message}));
  };

  render() {
    return (
      <>
        <Button
          className={'c-logout-from-firebase__button'}
          type={'submit'}
          label={I18n.t(T.news.channel.logout)}
          size={ButtonSize.NORMAL}
          onClick={this.logout}
        />
        {this.state.error}
      </>
    );
  }
}

export const LogoutFromFirebase = withFirebase(LogoutFromFirebaseComponent);
