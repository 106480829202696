import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';

import './CaseReportButton.component.scss';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  gtmActionName: ActionName;
  onClick: () => void;
}

export const CaseReportButton: React.FC<Props> = 
  ({ onClick, children, isLoading, gtmActionName }) => {
    const { track } = useTracking();
    
    const handleClick = (event: React.SyntheticEvent<any>) => {
      event.stopPropagation();
      onClick();
      track(InteractionEvents.buttonClick({
        category: EventCategory.REPORT_CASE,
        action: gtmActionName,
        event,
      }));
    };

    return (
      isLoading ? <Loader /> : <div onClick={handleClick}>{children}</div>
    );
  };
