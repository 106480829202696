export const redirectTo = (uri: string) =>
  window.location.assign(uri);

export const goTo = (uri: string, target?: string) =>
  window.open(uri, target);

export const setURL = (url: string = '') =>
  history.replaceState({}, document.title, url ? url : '/');

export const isEdgeBrowser = () => window.navigator.userAgent.toLocaleLowerCase().indexOf('edge') > -1;

export const isFirefoxBrowser = () => window.navigator.userAgent.toLocaleLowerCase().indexOf('firefox') > -1;
