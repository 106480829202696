import { formatTime } from '+utils/format.util.old';

type CategoryName = string;
type ActionPart = string;
type LabelPart = string | number;
type VirtualPage = string;

export type GoogleTagUrlHistory = {
  previousUrl: string;
  currentUrl: string;
};

type GoogleTagCategoryEvent = {
  category: CategoryName;
};

type GoogleTagActionPartsEvent = {
  actionParts: ActionPart[];
};

type GoogleTagLabelPartsEvent = {
  labelParts: LabelPart[];
};

type GoogleTagPageEvent = {
  page: VirtualPage;
};

export const formatUrl = (urlString: string) => {
  const url = new URL(urlString);

  return `${url.origin}${url.pathname}`;
};

export const formatCategory = () => ({ category }: GoogleTagCategoryEvent) => category;

export const formatActionParts = () => ({ actionParts }: GoogleTagActionPartsEvent) => actionParts.join(' - ');

export const formatLabelParts = ({ previousUrl, currentUrl }: GoogleTagUrlHistory) =>
  ({ labelParts }: GoogleTagLabelPartsEvent) => [
    formatTime(Date.now()),
    formatUrl(previousUrl),
    formatUrl(currentUrl),
    ...labelParts,
  ].join(' | ');

export const formatPageView = ({ currentUrl }: GoogleTagUrlHistory) => ({ page }: GoogleTagPageEvent) => {
  const { pathname: currentPathname } = new URL(currentUrl);

  const isVirtual = page.length > 0;
  const prefix = isVirtual ? '/virtual' : '';
  const pathname = isVirtual && currentPathname === '/' ? '/index' : currentPathname;

  return `${prefix}${pathname}${page}`
    .split('/')
    .map(encodeURIComponent)
    .join('/');
};
