import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { dataGuard, isNotNil, ofType, processQuery } from '../../../utils';
import { BatteryActions } from './battery.actions';
import { BatteryRepository } from './battery.repository';

type Action$ = ActionsObservable<BatteryActions>;

export const getBattery$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBattery),
    mergeMap(({ batteryId, queryKey }) => isNotNil(batteryId)
      ? of(batteryId).pipe(processQuery(queryKey, BatteryRepository.getBattery, {
        onSuccess: res => dataGuard(BatteryActions.setBattery)(res!.element),
        onFailure: _ => of(BatteryActions.setBattery(undefined)),
      }))
      : of(BatteryActions.setBattery(undefined))),
  );

export const getBatteryElectricUnits$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBatteryElectricUnits),
    mergeMap(({ batteryId, queryKey }) => of(batteryId).pipe(
      processQuery(queryKey, BatteryRepository.getBatteryElectricUnits, {
        onSuccess: res => dataGuard(BatteryActions.setBatteryElectricUnits)(res!.elements),
      }),
    )),
  );

export const getBatteryStatuses$ = (action$: Action$) =>
  action$.pipe(
    ofType(BatteryActions.getBatteryStatuses),
    mergeMap(({ batteryId, queryKey, start, end }) => of(batteryId).pipe(
      processQuery(
        queryKey,
        () => BatteryRepository.getBatteryStatuses(batteryId, start, end),
        {
          onSuccess: res => dataGuard(BatteryActions.setBatteryStatuses)(res!.elements),
        },
      ),
    )),
  );

export const epics = combineEpics(
  getBattery$,
  getBatteryElectricUnits$,
  getBatteryStatuses$,
);
