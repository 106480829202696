import { getSelectedCustomerQueryStatus } from '+app/+customer/store/+customer.selectors';
import { getBattery, isEatonBattery } from '+customer-battery/store/+battery.selectors';
import {
  getAnalysisWarnings,
  getWarningMessages,
} from '+shared/store/customer/warnings/warnings.factory';
import { getSiteLiveState } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import {
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  SimpleOnScaleListenerArgs,
  TooltipEvent,
  TooltipEventHandler,
} from '@kanva/charts';
import { View } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisLastXYSeriePosition,
  AnalysisLiveBubbles,
  AnalysisTooltip,
  BASE_TICK_COUNT,
  Card,
  EnergyFlowSeriesKey,
  factorizeTooltipDate,
  Icon,
  InfoBanner,
  LinkButton,
  MediaQuery,
  prepareTooltipData,
  TimeHelper,
  TimeUnit,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { compose, isNil } from 'lodash/fp';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { CustomerWarnings } from '../../../components';
import { AnalysisChartCrosshair } from '../../components/AnalysisChartCrosshair';
import { AnalysisDayChart } from '../../components/AnalysisDayChart';
import { AnalysisTooltipItem } from '../../components/AnalysisTooltipItem';
import {
  getDataSeries,
  getSelectedDataSeriesKeys,
  getSelectedDate,
  getSiteMeasurements,
  getSiteMeasurementsQueryStatus,
  hasForecast,
  hasSiteMeasurements,
  isMK1,
} from '../../store/+analysis.selector';
import {
  chartTooltipStyles,
  handleTooltipTouchEvent,
  seriesKeyTranslationMap,
} from '../../store/helpers/tooltip.helpers';
import { CustomerAnalysisDayToolbar } from '../CustomerAnalysisDayToolbar';
import {
  desktopScaleOptions,
  getVisibleDataSeriesKeys,
  getVisibleLiveBubblesData,
  getXYFromAreaChartSeries,
  hasSeries,
  mobileScaleOptions,
} from './CustomerAnalysisEnergyFlow.helper';

import './CustomerAnalysisEnergyFlow.component.scss';

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface State {
  tooltipEvent?: TooltipEvent;
  dayChartView?: View<any>;
  scale: number;
  isChartZoomed: boolean;
  lastXYSeriePosition: AnalysisLastXYSeriePosition;
}

const mapStateToProps = (state: StoreState) => ({
  selectedDate: getSelectedDate(state),
  selectedDataSeriesKeys: getSelectedDataSeriesKeys(state),
  dataSeries: getDataSeries(state),
  hasSiteMeasurements: hasSiteMeasurements(state),
  siteMeasurementsQueryStatus: getSiteMeasurementsQueryStatus(state),
  customerQueryStatus: getSelectedCustomerQueryStatus(state),
  siteMeasurements: getSiteMeasurements(state),
  battery: getBattery(state),
  isEaton: isEatonBattery(state),
  isMK1: isMK1(state),
  siteLiveData: getSiteLiveState(state),
  hasForecast: hasForecast(state),
});

const mapDispatchToProps = mapActions({
  // @TODO
});

class CustomerAnalysisEnergyFlowComponent extends React.PureComponent<Props, State> {
  private tooltipExtension: DataContainerTooltipExtension;
  private transformExtension: DataContainerTransformExtension;
  private lastXYPositionTimeout: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      tooltipEvent: undefined,
      dayChartView: undefined,
      scale: 0,
      isChartZoomed: false,
      lastXYSeriePosition: {
        [EnergyFlowSeriesKey.CONSUMPTION_POWER]: { x: 0, y: 0 },
        [EnergyFlowSeriesKey.PRODUCTION_POWER]: { x: 0, y: 0 },
      },
    };

    this.tooltipExtension = new DataContainerTooltipExtension({
      onTooltipEvent: this.handleTooltipEvent,
    });

    this.transformExtension = new DataContainerTransformExtension({
      scale: {
        limit: {
          x: [1, 10],
        },
        listener: this.handleScale,
        listenerThreshold: 0.001,
      },
    });
  }

  componentDidMount() {
    this.setLastXYPositions();

    // NOTE: This is to continuously update the positions because of zooming, fetching live data etc
    // TODO: Consider another way to update it - some listener, callback on zoom etc
    this.lastXYPositionTimeout = window.setInterval(() => {
      this.setLastXYPositions();
    }, 300);
  }

  componentWillUnmount() {
    clearTimeout(this.lastXYPositionTimeout);
  }

  componentDidUpdate(prevProps: Props) {
    if (TimeHelper.getUnixFromDate(prevProps.selectedDate) !== TimeHelper.getUnixFromDate(this.props.selectedDate)
      && this.transformExtension) {
    this.transformExtension.setScale({ x: 1, y: 1 });
    }

    if (prevProps.dataSeries !== this.props.dataSeries) {
      this.setLastXYPositions();
    }
  }

  handleTooltipEvent: TooltipEventHandler = (event) => {
    if (this.state.tooltipEvent === event) {
      return;
    }

    if (!Object.values(event.match.values).every(point => point && isNil(point.y))) {
      this.setState({ tooltipEvent: event });
    }
  };

  handleTooltipPositionChange = (x: number) => (view?: View<any>) => {
    if (this.tooltipExtension && view) {
      this.setState({ dayChartView: view });
      this.tooltipExtension.simulateAbsoluteCanvasPosition(view as any, { x, y: 0 });
    }
  };

  fromPointer: React.PointerEventHandler = ({ nativeEvent }) => {
    const { tooltipEvent } = this.state;

    if (tooltipEvent) {
      this.handleTooltipPositionChange(nativeEvent.pageX - tooltipEvent.pointerEvent.offset.left);
    }
  };

  handleScale = ({ scaleX, dataContainers }: SimpleOnScaleListenerArgs) => {
    const scale = Math.floor(Math.log2(scaleX));

    this.setZoomState(scaleX > 1);

    if (this.state.scale === scale) {
      return;
    }

    dataContainers.forEach(container => {
      const axisParams = container.getXAxisParameters();

      axisParams.tickCount = 1 + (BASE_TICK_COUNT - 1) * Math.pow(2, scale);
      container.setXAxisParameters(axisParams);
    });

    this.setState({ scale });
  };

  setZoomState = (isChartZoomed: boolean) => this.setState({ isChartZoomed });

  setLastXYPosition = (
    seriesKey: EnergyFlowSeriesKey,
  ) => {
    const position = getXYFromAreaChartSeries(
      this.state.dayChartView,
      this.props.dataSeries,
      seriesKey,
    );

    if (!position) { return; }

    this.setState(prevState => ({
      lastXYSeriePosition: {
        ...prevState.lastXYSeriePosition,
        [seriesKey]: {
          x: position.x,
          // TODO: Introduce isShifted property
          // to move only label, not a dot
          y: position.y,
        },
      },
    }));
  };

  setLastXYPositions = () => {
    this.setLastXYPosition(EnergyFlowSeriesKey.CONSUMPTION_POWER);
    this.setLastXYPosition(EnergyFlowSeriesKey.PRODUCTION_POWER);
  };

  render() {
    const {
      dataSeries,
      selectedDate,
      selectedDataSeriesKeys,
      hasSiteMeasurements,
      siteMeasurementsQueryStatus,
      siteMeasurements,
      battery,
      isEaton,
      isMK1,
      siteLiveData,
      customerQueryStatus,
      hasForecast,
    } = this.props;
    const {
      tooltipEvent,
      dayChartView,
      isChartZoomed,
      lastXYSeriePosition,
    } = this.state;

    const visibleDataSeriesKeys = getVisibleDataSeriesKeys(
      selectedDate,
      tooltipEvent && tooltipEvent.match,
      selectedDataSeriesKeys,
    );

    const analysisWarnings = compose(getWarningMessages, getAnalysisWarnings)({ siteMeasurements, battery });
    const warnings = [...analysisWarnings];
    const canShowForecast =
      !!(siteLiveData && siteLiveData.online)
      && hasForecast
      && TimeHelper.isTodayOrAfter(selectedDate)
      && dataSeries;
    const canShowChart =
      customerQueryStatus.pending
      || siteMeasurementsQueryStatus.pending
      || (hasSiteMeasurements
      || canShowForecast);

    return (
      <section className={'c-customer-analysis-energy-flow'}>
        <CustomerWarnings warnings={warnings}/>
        <Card
          header={<CustomerAnalysisDayToolbar />}
          noHeaderGap={true}
        >
          {canShowChart ? (
            <>
              <Media query={MediaQuery.UP_MD}>
                <>
                  <div className={classNames('c-customer-analysis-energy-flow__reset-wrapper', {
                    'c-customer-analysis-energy-flow__reset-wrapper--visible': isChartZoomed})}
                  >
                    <LinkButton
                      onClick={() => this.transformExtension.setScale({ x: 1, y: 1 })}
                      icon={<Icon.Arrow />}
                      iconClass={'c-analysis-energy-flow__reset-icon'}
                    >
                      {I18n.t(T.customerSingle.analysis.dayChart.resetZoom)}
                    </LinkButton>
                  </div>
                </>
              </Media>
              <div
                className={'c-customer-analysis-energy-flow__content'}
                onTouchMove={(e: React.TouchEvent) =>
                  handleTooltipTouchEvent(e, dayChartView, this.tooltipExtension, tooltipEvent)}
              >
                <div className={'c-customer-analysis-energy-flow__tooltip-wrapper'}>
                  <AnalysisTooltip
                    type={'horizontal'}
                    isVisible={tooltipEvent && siteMeasurementsQueryStatus.success}
                    header={factorizeTooltipDate(
                      TimeUnit.DAY,
                      tooltipEvent && tooltipEvent.match.primary.x,
                      selectedDate,
                    )}
                  >
                    {prepareTooltipData(
                      tooltipEvent && tooltipEvent.match.values,
                      seriesKeyTranslationMap,
                      visibleDataSeriesKeys,
                      chartTooltipStyles,
                    ).map(props => <AnalysisTooltipItem key={props.seriesKey} {...props}/>)}
                  </AnalysisTooltip>
                </div>
                <div className={'c-customer-analysis-energy-flow__overflow-container'}>
                  <Media query={MediaQuery.UP_MD}>
                    {(isDesktop: boolean) => isDesktop ? (
                      <AnalysisDayChart
                        key={siteMeasurementsQueryStatus.success.toString()}
                        tooltipExtension={this.tooltipExtension}
                        transformExtension={this.transformExtension}
                        selectedDate={selectedDate}
                        dataSeries={dataSeries}
                        measurementsQueryStatus={siteMeasurementsQueryStatus}
                        customerQueryStatus={customerQueryStatus}
                        selectedDataSeriesKeys={selectedDataSeriesKeys}
                        scaleOptions={desktopScaleOptions}
                        isChartZoomed={isChartZoomed}
                        onMount={this.handleTooltipPositionChange}
                      />
                    ) : (
                      <AnalysisDayChart
                        key={siteMeasurementsQueryStatus.success.toString()}
                        tooltipExtension={this.tooltipExtension}
                        transformExtension={this.transformExtension}
                        selectedDate={selectedDate}
                        dataSeries={dataSeries}
                        measurementsQueryStatus={siteMeasurementsQueryStatus}
                        customerQueryStatus={customerQueryStatus}
                        selectedDataSeriesKeys={selectedDataSeriesKeys}
                        scaleOptions={mobileScaleOptions}
                        isChartZoomed={isChartZoomed}
                        onMount={this.handleTooltipPositionChange}
                      />
                    )
                  }
                  </Media>
                  {
                    hasSeries(dataSeries) &&
                    <AnalysisLiveBubbles
                      data={getVisibleLiveBubblesData(
                        selectedDataSeriesKeys,
                        lastXYSeriePosition,
                        siteLiveData,
                        selectedDate,
                        isEaton,
                        isMK1,
                        siteMeasurementsQueryStatus.pending,
                      )}
                    />
                  }
                </div>
                <AnalysisChartCrosshair
                  tooltipEvent={tooltipEvent}
                  isVisible={tooltipEvent && siteMeasurementsQueryStatus.success}
                  offset={-18}
                  desktopHeight={480}
                  mobileHeight={300}
                />
              </div>
            </>
          ) : (
            <InfoBanner
              icon={<Icon.BrokenBattery />}
              title={I18n.t(T.customerSingle.analysis.dayChart.noDataHeadline)}
              subtitle={I18n.t(T.customerSingle.analysis.dayChart.noDataSubheadline)}
            />
          )}
        </Card>
      </section>
    );
  }
}

export const CustomerAnalysisEnergyFlow =
  connect(mapStateToProps, mapDispatchToProps)
  (CustomerAnalysisEnergyFlowComponent);
