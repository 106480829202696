import { Bubble, DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import './SetupInfoIconTooltip.component.scss';
import { getSetupTooltipContentTranslations } from './SetupInfoIconTooltip.const';

interface Props {
  statusName: string;
}

export const SetupInfoIconTooltip: React.FC<Props> = ({
  statusName,
}) => {
  const setupTooltipContentTranslations = getSetupTooltipContentTranslations();

  return setupTooltipContentTranslations[statusName] && (
    <div className={'c-setup-info-icon-tooltip'}>
      <div className={'c-setup-info-icon-tooltip__trigger'}>
        <Icon.Info/>
      </div>
      <div className={'c-setup-info-icon-tooltip__bubble'}>
        <Bubble
          side={'bottom'}
        >
          <DefaultParagraph>
            {setupTooltipContentTranslations[statusName].title1 &&
              <p className={'c-setup-info-icon-tooltip__bubble__title'}>
                {setupTooltipContentTranslations[statusName].title1}
              </p>
            }
            {setupTooltipContentTranslations[statusName].description1 &&
              <p className={'c-setup-info-icon-tooltip__bubble__description'}>
                {setupTooltipContentTranslations[statusName].description1}
              </p>
            }
            {setupTooltipContentTranslations[statusName].title2 &&
              <p className={'c-setup-info-icon-tooltip__bubble__title'}>
                {setupTooltipContentTranslations[statusName].title2}
              </p>
            }
            {setupTooltipContentTranslations[statusName].description2 &&
              <p className={'c-setup-info-icon-tooltip__bubble__description'}>
                {setupTooltipContentTranslations[statusName].description2}
              </p>
            }
          </DefaultParagraph>
        </Bubble>
      </div>
    </div>
  );
};
