import { hasMeasurements } from '+app/+customer/+analysis/store/helpers/+analysis.helpers';
import { SiteMeasurements } from '+shared/store/site';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { formatDate } from '../../../../utils/format.util.old';
import { Battery } from '../../battery/types/battery.interface';
import { WarningFactory } from './warnings.types';

type AnalysisWarningFactoryProps = {
  battery: Battery | undefined;
  siteMeasurements: SiteMeasurements;
};

export const missingLatestAnalysisDataFactory: WarningFactory<AnalysisWarningFactoryProps> =
  ({siteMeasurements, battery}) =>
    !hasMeasurements(siteMeasurements) && battery ? ({
      message: !Date.parse(battery.lastMeasurementAt)
        ? I18n.t(T.warning.missingLatestAnalysisData)
        : I18n.t(T.warning.missingLatestAnalysisDataSince, {
          time: formatDate(battery.lastMeasurementAt, 'L LTS'),
        }),
    }) : undefined;
