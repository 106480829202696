import { ResetPasswordForm } from '+app/+account/+personal/components';
import { AccountPageActions } from '+app/+account/store';
import { getAccountPasswordQueryStatus } from '+app/+account/store/+account.selector';
import { mapActions } from '+app/utils';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './ResetPassword.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  queryStatus: getAccountPasswordQueryStatus(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  submitForm: AccountPageActions.editPassword,
  resetPasswordQuery: AccountPageActions.resetPasswordQuery,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

const ResetPasswordComponent: React.FC<Props> = ({ actions, openModalId, queryStatus }) => {
  const onOpen = () => actions.toggleModal(true, ModalId.RESET_PASSWORD);
  const onClose = () => {
    actions.resetPasswordQuery();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
  };
  const onTryAgain = () => {
    actions.resetPasswordQuery();
    setFormState(FormState.FORM);
  };

  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (queryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }
    if (queryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }
  }, [queryStatus]);

  return (
    <div className={'c-reset-password'}>
      <PageSubheadline smallGap={true}>
        <div className={'c-reset-password__wrapper'}>
          {I18n.t(T.resetPassword.title)}
          <LinkButton
            onClick={onOpen}
            className={'c-reset-password__button'}
          >
            {I18n.t(T.resetPassword.buttons.open)}
          </LinkButton>
        </div>
      </PageSubheadline>
      <FormModal
        isModalOpen={openModalId === ModalId.RESET_PASSWORD}
        status={formState}
        headlineSuccess={I18n.t(T.resetPassword.status.success)}
        headlineFailure={I18n.t(T.resetPassword.status.failure)}
        onClose={onClose}
        onTryAgain={onTryAgain}
        defaultForm={
          <ResetPasswordForm
            submitForm={actions.submitForm}
            onClose={onClose}
            isLoading={queryStatus.pending}
          />
        }
      />
    </div>
  );
};

export const ResetPassword = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordComponent);
