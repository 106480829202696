import { CountryFeatureName, FeatureName } from '+config';
import { useCountryFeature, useFeature } from '@sonnen/shared-web';
import * as React from 'react';

interface RolloutProps {
  countryFeatureName: CountryFeatureName;
}

export const RolloutLimiter: React.FC<RolloutProps> = ({ children, countryFeatureName }) => {
  const isRolloutEnabled = useFeature(FeatureName.ROLLOUT).isEnabled;
  const isCountryFeatureEnabled = useCountryFeature(countryFeatureName).isEnabled;

  // NOTE: just pass through if the rollout flag is disabled
  if (!isRolloutEnabled) {
    return <>{children}</>;
  }

  return isCountryFeatureEnabled ? <>{children}</> : null;
};
