import { batteryCapacityLabelMapper, batteryModelNameMap } from '+shared/store/lead/lead.helpers';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationLineType } from '../LeadConfigurationLine';

export const factorizeBatteryDetails = (locale: string ) => (battery: LeadProductBattery) => [
  // Model
  {
    title: `${I18n.t(T.lead.hardware._salessolution_.configurationTile.details.model)}`,
    value: batteryModelNameMap(battery.modelName),
    type: LeadConfigurationLineType.LARGE,
  },
  // Capacity
  {
    title: `${I18n.t(T.lead.hardware._salessolution_.configurationTile.details.capacity)}`,
    value: batteryCapacityLabelMapper(locale)(battery.name),
    type: LeadConfigurationLineType.LARGE,
  },
];
