import { getPrecision } from '+utils/number.util';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

const parseReactTextToFloat = (reactText: React.ReactText) => parseFloat(reactText.toString());
const minValue = 0;
const errorMsg = (maxValue: number) =>
  `${I18n.t(T.lead.configuration._salessolution_.configurations.outOfRange)} (${minValue} - ${maxValue}%)`;

export const ConfigurationAutarkySchema = (maxValue: React.ReactText) => Yup.object().shape({
    autarky: Yup
      .number()
      .required(I18n.t(T.register.alert.requiredField))
      .min(minValue, errorMsg(parseReactTextToFloat(maxValue)))
      .max(parseReactTextToFloat(maxValue), errorMsg(parseReactTextToFloat(maxValue)))
      .test('decimal-places', I18n.t(T.validation.lessDecimalPlaces), val => getPrecision(val) <= 2),
  });
