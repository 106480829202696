import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { FormFieldError } from '../FormFieldError';
import { FormFieldLabel } from '../FormFieldLabel';
import { FormInputRadioBox } from '../FormInputRadioBox';

import './FormInputRadioBoxGroup.component.scss';

export interface RadioInput {
  label: string;
  key: unknown;
  description?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
}

interface Props<T> {
  name: string;
  form: FormikProps<T>;
  collection: Array<RadioInput | undefined>;
  className?: ClassValue;
  label?: string;
  onCheck?: () => void;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const FormInputRadioBoxGroup = <T extends any = any>({
  form,
  name,
  collection,
  label,
  className,
  onCheck,
  isRequired = false,
  isDisabled = false,
}: Props<T>) => (
  <div className={classNames('c-form-input-radio-box-group', className)}>
    {label &&
      <FormFieldLabel
        label={label}
        isRequired={isRequired}
        isDisabled={isDisabled}
      />
    }
    <div className={'c-form-input-radio-box-group__container'}>
      {collection.map(radioButton => radioButton ? (
        <FormInputRadioBox
          key={radioButton.label}
          form={form}
          label={radioButton.label}
          description={radioButton.description}
          name={name}
          value={radioButton}
          disabled={radioButton.disabled}
          icon={radioButton.icon}
          onCheck={onCheck}
        />
      ) : null)}
    </div>
    <FormFieldError
      form={form}
      name={name}
    />
  </div>
);
