import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export const missingBatteryCaseSchema = () => Yup.object({
  serialNumber: Yup.string()
    .required(I18n.t(T.missingBattery.validateSerialNumber.noEmptyValues)),
  postalCode: Yup.string(),
  customerLastName: Yup.string(),
  pvPeakPower: Yup.number(),
  description: Yup.string()
    .required(I18n.t(T.missingBattery.validateDescription.noEmptyValues)),
});
