import { Config } from '+config';
import { from } from 'rxjs';
import { jsonApiClient, partnerPortalJsonApiClient } from '../../network';
import { CaseData } from './types/case.interface';
import { CreateCaseAttributes } from './types/createCase.interface';
import { CreatedCaseData } from './types/createCaseResponse.interface';

const createCase = (createCaseAttributes: CreateCaseAttributes) => from( 
  partnerPortalJsonApiClient.post<CreatedCaseData>(`${Config.PARTNER_PORTAL_API_URL}/cases`)
  .ofType('cases')
  .withAttributes(createCaseAttributes)
  .send());

const getCaseStatistics = () => from(
  jsonApiClient.get<CaseData>(`${Config.API_URL}/cases/statistics`)
    .send(),
);

export const CaseRepository = {
  getCaseStatistics,
  createCase,
};
