import {
  isOnlinePortalFieldName,
} from '+setupTool/+form/store/+form.dictionary';
import { getCharactersTranslations } from '+utils/validation.util';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { DsoRegistrationInstallerDataInterface } from '../types';

const PHONE_MAX_CHAR = 100;
const ZIP_CODE_MAX_CHAR = 100;

export const DsoRegistrationInstallerDataSchema = () => {
  const charactersTranslations = getCharactersTranslations();

  return Yup.object().shape({
    user_name: Yup
      .string()
      .when(isOnlinePortalFieldName, {
        is: (val) => val,
        then: Yup
          .string()
          .trim()
          .required(I18n.t(T.register.alert.requiredField)),
      }),
    password: Yup
      .string()
      .when(isOnlinePortalFieldName, {
        is: (val) => val,
        then: Yup
          .string()
          .trim()
          .required(I18n.t(T.register.alert.requiredField)),
      }),
    installer_company: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_first_name: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_last_name: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_company_street: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_company_street_number: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_zip_code: Yup
      .string()
      .trim()
      .max(ZIP_CODE_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: ZIP_CODE_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_city: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_email: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_phone: Yup
      .string()
      .trim()
      .max(PHONE_MAX_CHAR, I18n.t(T.validation.maxCharacters, { max: PHONE_MAX_CHAR }))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_certification_number: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
    installer_certification_authority: Yup
      .string()
      .trim()
      .max(100, I18n.t(T.validation.maxCharacters, { max: 100}))
      .required(I18n.t(T.register.alert.requiredField)),
  });
};

export const DsoRegistrationInstallerDataInitial: DsoRegistrationInstallerDataInterface = {
  installer_company: '',
  installer_first_name: '',
  installer_last_name: '',
  installer_company_street: '',
  installer_company_street_number: '',
  installer_zip_code: '',
  installer_city: '',
  installer_email: '',
  installer_phone: '',
  installer_certification_number: '',
  installer_certification_authority: '',
  user_name: '',
  password: '',
  [isOnlinePortalFieldName]: false,
};

export const saveableInstallerDataFields = [
  'installer_company',
  'installer_first_name',
  'installer_last_name',
  'installer_company_street',
  'installer_company_street_number',
  'installer_zip_code',
  'installer_city',
  'installer_email',
  'installer_phone',
  'installer_certification_number',
  'installer_certification_authority',
  isOnlinePortalFieldName,
];
export enum DsoRegistrationInstallerDataViewModes {
  NEW = 'new',
  SELECTING = 'selecting',
  REMOVING = 'removing',
}
