import { ListTableHeader } from '+shared/components';
import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Loader, PageSubheadline, Table } from '@sonnen/shared-web';
import { isEmpty } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { CertificateEmptyMessage } from '../CertificateEmptyMessage';
import { CertificateListItem } from '../CertificateListItem';
import { getCertificateList } from './CertificateList.helper';

import './CertificateList.component.scss';

interface Props {
  certificates: UserCertificate[];
  isLoading: boolean;
}

export const CertificateList: React.FC<Props> = ({ certificates, isLoading }) => {
  const certificatesList = getCertificateList();
  
  return (
    <div className={'c-certificate-list'}>
      <PageSubheadline mediumGap={true}>
        {I18n.t(T.myAccount.certification.title)}
      </PageSubheadline>
      {isLoading ? <Loader /> : !isEmpty(certificates)
        ? <div className={'c-certificate-list__table'}>
            <div className={'c-certificate-list__headers'}>
              {certificatesList.map((header) => (
                <ListTableHeader item={header} key={uuid()} className={'c-certificate-list__header'}/>
              ))}
            </div>
            <Table striped={true}>
              {certificates.map((item) => (
                <CertificateListItem item={item} key={uuid()}/>
              ))}
            </Table>
          </div>
        : <CertificateEmptyMessage />
      }
    </div>
  );
};
