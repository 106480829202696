import { LeadConfigurationNew } from '+app/+lead/+configuration/containers/LeadConfigurationNew';
import { LeadHardwareNew } from '+app/+lead/+hardware';
import { LeadImpactAnalysis } from '+app/+lead/+impactAnalysis';
import { SetupLeadList } from '+app/+lead/+list/containers/SetupLeadList';
import { LeadOfferComposer } from '+app/+lead/+offer/containers';
import { SetupLeadOverview } from '+app/+lead/+overview';
import { LEAD_IN_SETUP_STAGE, ROUTES } from '+app/router';
import { RestrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { setupLeadTabAllowedRoles } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/index';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

const mapStateToProps = (state: StoreState) => ({
  userProfileRoles: getUserProfileRoles(state),
});

const mapDispatchToProps = mapActions({});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

class SetupComponent extends React.PureComponent<Props> {
  render() {
    const { userProfileRoles } = this.props;

    return (
      <RestrictedToRolesOr404 allowedRoles={setupLeadTabAllowedRoles} userRoles={userProfileRoles}>
        <Switch>
          <Route
            path={ROUTES.SETUP_LEAD_IMPACT_ANALYSIS}
            render={() => (
              <LeadImpactAnalysis leadStage={LEAD_IN_SETUP_STAGE} />
            )}
          />
          <Route
            path={ROUTES.SETUP_LEAD_OFFER}
            render={() => (
              <LeadOfferComposer leadStage={LEAD_IN_SETUP_STAGE} />
            )}
          />
          <Route
            path={ROUTES.SETUP_LEAD_CONFIGURATION_NEW}
            render={() => (
              <LeadConfigurationNew leadStage={LEAD_IN_SETUP_STAGE} />
            )}
          />
          <Route
            path={ROUTES.SETUP_LEAD_HARDWARE_NEW}
            render={() => (
              <LeadHardwareNew leadStage={LEAD_IN_SETUP_STAGE} />
            )}
          />
          <Route
            path={ROUTES.SETUP_LEAD_OVERVIEW}
            component={SetupLeadOverview}
          />
          <Route
            path={[ROUTES.SETUP_LEADS[0]]}
            component={SetupLeadList}
          />
        </Switch>
      </RestrictedToRolesOr404>
    );
  }
}

export const SetupLead = connect(mapStateToProps, mapDispatchToProps)(SetupComponent);
