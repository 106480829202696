import { setHintCategory, setHintColor, setTagName } from '+app/+newsChannel/containers/NewsSection/News.helper';
import * as React from 'react';

import './NewsTag.component.scss';

interface Props {
  category: number;
  tag: string;
}

export const NewsTag: React.FC<Props> = ({
  tag,
  category,
}) => (
  <div className={'news-item__tag'}>
    <i className={`news-tag__icon news-tag__icon--${setHintColor(category)}`}/>
    <p className={'news-tag__text'}>{tag ? setTagName(tag) : setHintCategory(category)}</p>
  </div>
);
