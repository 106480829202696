import { DetailListType } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { formatPeakPower, formatPercentage } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { PeakPowerUnit } from '../../../../App.constants';

export const factorizeDetailList = (battery: Battery): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.overview.pvSystem.pvSize),
    value: !isNil(battery.pvPeakPower)
      ? formatPeakPower(battery.pvPeakPower, PeakPowerUnit.KWP, 2)
      : undefined,
  },
  {
    label: I18n.t(T.customerSingle.overview.pvSystem.feedinLimit),
    value: !isNil(battery.pvGridFeedInLimit)
      ? formatPercentage(battery.pvGridFeedInLimit)
      : undefined,
  },
];
