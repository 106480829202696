import { Query, QueryStatus } from './query.state';

const isPending = (query: Query) => query.status === QueryStatus.PENDING;
const isNotReady = (query: Query) => !query.status || query.status === QueryStatus.FAILURE;
const hasFailed = (query: Query) => query.status === QueryStatus.FAILURE;
const hasSucceeded = (query: Query) => query.status === QueryStatus.SUCCESS;

export const getStatus = (query: Query<any>) => ({
  success: hasSucceeded(query),
  error: hasFailed(query),
  pending: isPending(query),
  notReady: isNotReady(query),
});

export const getCombinedStatus = (statuses: Array<ReturnType<typeof getStatus>>) => {
  if (statuses.some(status => status.error)) {
    return getStatus({ status: QueryStatus.FAILURE });
  }

  if (statuses.some(status => status.pending)) {
    return getStatus({ status: QueryStatus.PENDING });
  }

  if (statuses.every(status => status.success)) {
    return getStatus({ status: QueryStatus.SUCCESS });
  }

  return getStatus({ status: undefined });
};

export type QueryStatusMap = ReturnType<typeof getStatus>;
