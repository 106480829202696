import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { useDebounce } from '+shared/hooks/useDebounce';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Dropdown } from '@sonnen/shared-web';
import { isEmpty } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './StatusFilter.component.scss';

type Status = {
  name: string;
  label: string;
  color?: StatusTileColors;
};

interface Props {
  initialFilters?: string[];
  statusGroups: Status[][];
  setStatusFilters: (filters: any[]) => void;
  clearStatusFilters?: () => void;
  setPreviousStatusFilters?: (filters: any[]) => void;
  clearPreviousStatusFilters?: () => void;
  getList?: () => void;
}

export const StatusFilter: React.FC<Props> = ({
  initialFilters = [],
  statusGroups,
  setStatusFilters,
  clearStatusFilters,
  setPreviousStatusFilters,
  clearPreviousStatusFilters,
  getList,
}) => {
  const [filters, setFilters] = React.useState<string[]>(initialFilters);
  const debouncedFilters = useDebounce(filters, 500);
  const firstUpdate = React.useRef(true);
  const areInitialDebouncedFilters = React.useRef(true);

  React.useEffect(() => {
    if (clearPreviousStatusFilters && isEmpty(initialFilters)) {
      clearPreviousStatusFilters();
    }

    return () => {
      if (clearStatusFilters) {
        clearStatusFilters();
      }
    };
  }, []);

  React.useEffect(() => {
    // it's necessary since we were fetching the lead list twice due to isEmpty(filters) check
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    setStatusFilters(filters);

    if (setPreviousStatusFilters) {
      setPreviousStatusFilters(filters);
    }

    if (getList && isEmpty(filters)) {
      getList();
    }
  }, [filters]);

  React.useEffect(() => {
    // we should't fetch lead list when initial filters are applied
    if (areInitialDebouncedFilters.current) {
      areInitialDebouncedFilters.current = false;
      return;
    }

    if (getList && !isEmpty(debouncedFilters)) {
      getList();
    }
  }, [debouncedFilters]);

  const renderFilterCount = () => (filters.length > 0) &&
    I18n.t(T.statusFilter.filtersSelected, { filterCount: filters.length });

  const handleFilterClick = (status: string) => (
    filters.includes(status)
      ? setFilters(filters.filter((filter) => filter !== status))
      : setFilters([...filters, status])
  );

  const handleFilterClear = () => {
    setFilters([]);
  };

  const triggerComponent = (
    <div className={'c-status-filter__trigger'}>
      <div className={'c-status-filter__trigger-content'}>
        {I18n.t(T.statusFilter.status)} {renderFilterCount()}
      </div>
      <div className={'c-status-filter__trigger-icon'} />
    </div>
  );

  return (
    <div className={'c-status-filter'}>
      <div className={'c-status-filter__dropdown-wrapper'}>
        <div className={'c-status-filter__label'}>
          {I18n.t(T.statusFilter.filterBy)}
        </div>
        <Dropdown
          className={'c-status-filter__dropdown'}
          trigger={triggerComponent}
          id={'lead-list-status-filter'}
          isClosingOnContentClick={false}
        >
          <div className={'c-status-filter__dropdown-content'}>
            <div
              className={'c-status-filter__info'}
            >
              {I18n.t(T.statusFilter.clickToSelect)}
            </div>
            <div className={'c-status-filter__status-groups-wrapper'}>
              {statusGroups.map((group, index) => (
                <div key={`status-group-${index}`} className={'c-status-filter__status-group'}>
                  {group.map(({ name, label, color = StatusTileColors.GREEN }) => (
                    <StatusTile
                      label={label}
                      color={filters.includes(name) ? color : StatusTileColors.GRAY}
                      onClick={() => handleFilterClick(name)}
                      key={name}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </Dropdown>
      </div>
      {!isEmpty(filters) ?
        <div className={'c-status-filter__button-wrapper'}>
          <Button
            label={I18n.t(T.statusFilter.clearAll)}
            theme={ButtonTheme.OUTLINE}
            size={ButtonSize.SECONDARY}
            isBlock={true}
            onClick={handleFilterClear}
          />
        </div>
        : null}
    </div>
  );
};
