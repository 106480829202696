import { ListTableHeaderItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getInboxLeadListTableHeaders = (): ListTableHeaderItem[] => [
  { label: I18n.t(T.lead.list._salessolution_.name) },
  { label: I18n.t(T.lead.list._salessolution_.location) },
  { label: I18n.t(T.lead.list._salessolution_.consumption) },
  { label: I18n.t(T.lead.list._salessolution_.roofSize) },
  { label: '' },
  { label: '' },
];
