import {
  DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
  DsoCommissioningActions,
  getDsoCommissioningFields,
} from '+setupTool/+dsoCommissioning/store';
import { DsoRegistrationFormActions, getMeasuringDeviceForm } from '+setupTool/+form/store';
import {
  getVppDocumentationFields,
  getVppSubmissionStatus,
  VPP_CONFIG_SUBMIT_QUERY,
  VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
  VppDocumentationActions,
  VppRegisterStatus,
} from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep, SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { mapActionTypeToErrorMessage } from '+setupTool/store/+setupTool.helpers';
import { getSubmissionId, getVppFields } from '+setupTool/store/+setupTool.selectors';
import { DsoRegisterActions, DsoRegisterRepository, getConfiguration } from '+shared/store/setupTool';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapToState, ofType, processQuery } from '+utils/index';
import { isEqual } from 'lodash';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { DocumentSource } from '../../../shared/store/setupTool/types';
import { VppDocumentationFiles } from './schemas';

type Action$ = ActionsObservable<VppDocumentationActions>;
type State$ = StateObservable<StoreState>;

const saveDataAndPerformVpp$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(VppDocumentationActions.saveDataAndPerformVpp),
  mapToState(state$),
  map((state) => SetupToolActions.saveSubmissionWithCallback(
    DsoRegisterActions.postVpp({
      submissionId: getSubmissionId(state)!,
      queryKey: VPP_CONFIG_SUBMIT_QUERY,
      fields: getVppFields(state),
    }),
    {
      step: SubmissionStep.VPP_DOCUMENTATION,
      query: DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY, // TODO: Change to vpp submission query
    },
  )),
);

const setUploadedDocuments$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setUploadedDocuments),
  map(action => action.data),
  map(documents =>
    documents.filter(
      document =>
        document.source === DocumentSource.INPUT &&
        document.upload &&
        VppDocumentationFiles.indexOf(document.upload.category) !== -1,
    ),
  ),
  mergeMap(dataGuard(VppDocumentationActions.setVppUploadedDocuments)),
);

const setVppSubmissionStatusAfterPerformedVpp$ = (action$: Action$) => action$.pipe(
  ofType(DsoRegisterActions.postVppSuccess),
  mergeMap(action => concat(
    of(VppDocumentationActions.setVppAdditionalFeatures(action.response.vppAdditionalFeatures)),
    of(VppDocumentationActions.setSubmissionStatus(VppRegisterStatus.FINISH)),
  )),
);

const generateVppCompletionNotification$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(VppDocumentationActions.generateCompletionNotification),
  mapToState(state$),
  mergeMap(state => of({}).pipe(
    processQuery(
      VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY,
      () =>
        DsoRegisterRepository.generateVppCompletionNotification(getConfiguration(state).submissionId!), {
        onSuccess: res => concat(
          dataGuard(VppDocumentationActions.setCompletionNotificationDocument)
          (res!.data.documents.find(document => document.stepType === SubmissionStep.VPP_COMPLETION_NOTIFICATION)),
          dataGuard(VppDocumentationActions.setCompletionNotificationRegeneratedAt)
          (res!.data.stepsUpdatedAt[SubmissionStepCamelCase.VPP_COMPLETION_NOTIFICATION]),
        ),
        onFailure: err => of(SetupToolActions.appendError(
          mapActionTypeToErrorMessage(VppDocumentationActions.generateCompletionNotification.type),
        )),
      }),
  )),
);

const setLatestModification$ = (action$: Action$) => action$.pipe(
  ofType(SetupToolActions.setLatestModificationDate),
  map(action => action.data[SubmissionStepCamelCase.VPP_COMPLETION_NOTIFICATION]),
  mergeMap((data) => of(VppDocumentationActions.setCompletionNotificationRegeneratedAt(data))),
);

const synchronizeVppFieldsWithDsoCommissioning$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoCommissioningActions.setFields),
  mapToState(state$),
  map((state) => {
    if (getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH) {
      const vppFields = getVppDocumentationFields(state);
      const dsoCommissioningFields = getDsoCommissioningFields(state);
      const newFields = {
        ...vppFields,
        battery_commissioning_date: dsoCommissioningFields.battery_commissioning_date,
        pv_commissioning_date: dsoCommissioningFields.pv_commissioning_date || vppFields.pv_commissioning_date,
      };

      if (!isEqual(vppFields, newFields)) {
        return VppDocumentationActions.setFields(newFields);
      }
    }
    return ({ type: '' });
  }),
);

const synchronizeVppFieldsWithMeasuringDevice$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(DsoRegistrationFormActions.setDsoMeasuringDevice),
  mapToState(state$),
  map((state) => {
    if (getVppSubmissionStatus(state) !== VppRegisterStatus.FINISH) {
      const vppFields = getVppDocumentationFields(state);
      const measuringDeviceFields = getMeasuringDeviceForm(state);

      if (measuringDeviceFields) {
        const newFields = {
          ...vppFields,
          meter_number: measuringDeviceFields.meter_number,
          type_of_grid_reference_meter: measuringDeviceFields.type_of_grid_reference_meter,
        };

        if (!isEqual(vppFields, newFields)) {
          return VppDocumentationActions.setFields(newFields);
        }
      }
    }
    return ({ type: '' });
  }),
);

export const epics = combineEpics(
  saveDataAndPerformVpp$,
  setUploadedDocuments$,
  setVppSubmissionStatusAfterPerformedVpp$,
  generateVppCompletionNotification$,
  setLatestModification$,
  synchronizeVppFieldsWithDsoCommissioning$,
  synchronizeVppFieldsWithMeasuringDevice$,
);
