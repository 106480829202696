import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { createSelector } from 'reselect';
import {
  INBOX_LEAD_COLLECTION_GET_QUERY,
  LEAD_COLLECTION_GET_QUERY,
  SETUP_LEAD_COLLECTION_GET_QUERY,
} from './+leadList.state';

export const getLeadListPageState = (state: StoreState) => state.page.lead.list;

// leads
export const getLeadCollection = createSelector(
  getLeadListPageState,
  state => state.collection,
);

export const getLeadsCollectionTotalCount = createSelector(
  getLeadListPageState,
  state => state.totalResourceCount,
);

export const getLeadsCollectionQuery = createSelector(
  getLeadListPageState,
  state => state[LEAD_COLLECTION_GET_QUERY],
);

export const getLeadsCollectionQueryStatus = createSelector(
  getLeadsCollectionQuery,
  query => getStatus(query),
);

export const getLeadStatusFilters = createSelector(
  getLeadListPageState,
  state => state.statusFilters,
);

export const getSetupLeadStatusFilters = createSelector(
  getLeadListPageState,
  state => state.setupStatusFilters,
);

export const getPreviousLeadStatusFilters = createSelector(
  getLeadListPageState,
  state => state.previousStatusFilters,
);

export const getPreviousSetupLeadStatusFilters = createSelector(
  getLeadListPageState,
  state => state.previousSetupStatusFilters,
);

// inbox leads
export const getInboxLeadCollection = createSelector(
  getLeadListPageState,
  state => state.inboxCollection,
);

export const getInboxLeadsCollectionTotalCount = createSelector(
  getLeadListPageState,
  state => state.totalInboxResourceCount,
);

export const getInboxLeadsCollectionQuery = createSelector(
  getLeadListPageState,
  state => state[INBOX_LEAD_COLLECTION_GET_QUERY],
);

export const getInboxLeadsCollectionQueryStatus = createSelector(
  getInboxLeadsCollectionQuery,
  query => getStatus(query),
);

// setup leads
export const getSetupLeadCollection = createSelector(
  getLeadListPageState,
  state => state.setupCollection,
);

export const getSetupLeadCollectionTotalCount = createSelector(
  getLeadListPageState,
  state => state.totalSetupResourceCount,
);

export const getSetupLeadCollectionQuery = createSelector(
  getLeadListPageState,
  state => state[SETUP_LEAD_COLLECTION_GET_QUERY],
);

export const getSetupLeadCollectionQueryStatus = createSelector(
  getSetupLeadCollectionQuery,
  query => getStatus(query),
);
