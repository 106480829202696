import { ActionsUnion, createAction } from '../../../utils';
import { GET_SITE_STATISTICS_QUERY } from './site.state';
import {
  Site,
  SiteCharger,
  SiteLiveState,
  SiteMeasurements,
  SiteStatistics,
} from './types'; 
import { SiteStatisticsFilters } from './types/siteStatistics.interface';

export enum SITE_ACTIONS {
  GET_SITE_MEASUREMENTS = '[Site] GET_SITE_MEASUREMENTS',
  SET_SITE_MEASUREMENTS = '[Site] SET_SITE_MEASUREMENTS',
  GET_SITE_CHARGER_LIST = '[Site] GET_SITE_CHARGER_LIST',
  SET_SITE_CHARGER_LIST = '[Site] SET_SITE_CHARGER_LIST',
  GET_SITE_STATISTICS = '[Site] GET_SITE_STATISTICS',
  SET_SITE_STATISTICS = '[Site] SET_SITE_STATISTICS',
  GET_SITE_LIVE_STATE = '[Site] GET_SITE_LIVE_STATE',
  SET_SITE_LIVE_STATE = '[Site] SET_SITE_LIVE_STATE',
  CLEAR_SITE = '[Site] CLEAR_SITE',
  GET_SITE = '[Site] GET_SITE',
  SET_SITE = '[Site] SET_SITE',
  START_POLLING = '[Site] START_POLLING',
  STOP_POLLING = '[Site] STOP_POLLING',
}

export const SiteActions = {
  getSiteMeasurements: createAction(
    SITE_ACTIONS.GET_SITE_MEASUREMENTS,
    (opts: { queryKey: string, siteId: string, start?: Date, end?: Date }) => ({
      queryKey: opts.queryKey,
      siteId: opts.siteId,
      start: opts.start,
      end: opts.end,
    }),
  ),
  setSiteMeasurements: createAction(
    SITE_ACTIONS.SET_SITE_MEASUREMENTS,
    (siteMeasurements?: SiteMeasurements) => ({ siteMeasurements }),
  ),
  getSiteChargerList: createAction(
    SITE_ACTIONS.GET_SITE_CHARGER_LIST,
    (opts: { queryKey: string, siteId: string }) => ({
      queryKey: opts.queryKey,
      siteId: opts.siteId,
    }),
  ),
  setSiteChargerList: createAction(
    SITE_ACTIONS.SET_SITE_CHARGER_LIST,
    (siteChargers: SiteCharger[]) => ({ siteChargers }),
  ),
  getSiteStatistics: createAction(
    SITE_ACTIONS.GET_SITE_STATISTICS,
    (siteId: string, filters?: SiteStatisticsFilters) => ({ 
      queryKey: GET_SITE_STATISTICS_QUERY,
      siteId,
      filters,
    }),
  ),
  setSiteStatistics: createAction(
    SITE_ACTIONS.SET_SITE_STATISTICS,
    (siteStatistics: SiteStatistics) => ({ statistics: siteStatistics }),
  ),
  getSiteLiveState: createAction(
    SITE_ACTIONS.GET_SITE_LIVE_STATE,
    (opts: { queryKey: string, siteId: string }) => ({
      queryKey: opts.queryKey,
      siteId: opts.siteId,
    }),
  ),
  setSiteLiveState: createAction(
    SITE_ACTIONS.SET_SITE_LIVE_STATE,
    (liveState: SiteLiveState | undefined) => ({ liveState }),
  ),
  clearSite: createAction(
    SITE_ACTIONS.CLEAR_SITE,
  ),
  getSite: createAction(
    SITE_ACTIONS.GET_SITE,
    (opts: { queryKey: string, siteId: string }) => ({
      queryKey: opts.queryKey,
      siteId: opts.siteId,
    }),
  ),
  setSite: createAction(
    SITE_ACTIONS.SET_SITE,
    (site: Site) => ({ site }),
  ),
  startPolling: createAction(
    SITE_ACTIONS.START_POLLING,
    (siteId: string) => ({ siteId }),
  ),
  stopPolling: createAction(
    SITE_ACTIONS.STOP_POLLING,
    () => ({}), 
  ),
};

export type SiteActions = ActionsUnion<typeof SiteActions>;
