import * as React from 'react';

export type SuccessTrackingStatusMap = {
  pending: boolean;
  success: boolean;
};

export type ErrorTrackingStatusMap = {
  pending: boolean;
  error: boolean;
};

export type TrackingStatusCombinedMap = SuccessTrackingStatusMap & ErrorTrackingStatusMap;
export type TrackingStatusMap = SuccessTrackingStatusMap | ErrorTrackingStatusMap;

export const createUseStatusTracking = <T, >({ extractPendingStatus, extractTrackedStatus }: {
  extractPendingStatus: (queryStatus: T) => boolean;
  extractTrackedStatus: (queryStatus: T) => boolean;
}) => (trackingFn: () => void, queryStatus?: T) => {
  const queryStatusRef = React.useRef<T | null>(null);

  React.useEffect(() => {
    if (!queryStatus) {
      return;
    }

    const trackedStatus = extractTrackedStatus(queryStatus);
    if (!queryStatusRef.current && trackedStatus) {
      trackingFn();
    }

    queryStatusRef.current = queryStatus;
  }, []);

  React.useEffect(() => {
    if (!queryStatus) {
      return;
    }

    const pendingStatus = extractPendingStatus(queryStatus);
    const trackedStatus = extractTrackedStatus(queryStatus);
    if (
      !pendingStatus &&
      (queryStatusRef.current && extractPendingStatus(queryStatusRef.current)) &&
      trackedStatus
    ) {
      trackingFn();
    }

    queryStatusRef.current = queryStatus;
  });
};
