import { ROUTES } from '+app/router';
import { restrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { contactsAllowedRoles } from '+shared/store/contact';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { ContactList } from '../../+list/containers/ContactList';

const mapStateToProps = (state: StoreState) => ({
  userRoles: getUserProfileRoles(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps<{}>
  ;

export class ContactComponent extends React.PureComponent<Props> {
  render() {
    const { userRoles } = this.props;

    return (
      <Switch>
        <Route 
          path={ROUTES.CONTACTS} 
          component={restrictedToRolesOr404(ContactList, contactsAllowedRoles, userRoles)}
        />
      </Switch>
    );
  }
}

export const Contact = connect(mapStateToProps, null)(ContactComponent);
