import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const enum LeadsAlertType {
  LEAD_CLOSED = 'leadClosed',
  MOVED_TO_SETUP = 'movedToSetup',
}

export const mapAlertMessage = (type: LeadsAlertType) => ({
  [LeadsAlertType.LEAD_CLOSED]: I18n.t(T.lead.list._salessolution_.toastMessage.closedLead),
  [LeadsAlertType.MOVED_TO_SETUP]: I18n.t(T.lead.list._salessolution_.toastMessage.movedToSetup),
})[type];
