import { SetupToolStepStatus } from '+setupTool/components';
import { StatusName } from '+setupTool/store/+setupTool.dictionary';
import { SetupToolStep } from '+setupTool/store/types';
import { ButtonDirection, RoundButton } from '+shared/components';
import { InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import {
  breakpointDown,
  breakpointUp,
  Button,
  ButtonSize,
  ButtonTheme,
  Card,
  TableCell,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import './SetupToolStepsListItem.component.scss';

const mapDispatchToProps = mapActions({
  goTo: (path: string) => push(path),
});

interface ComponentProps {
  item: SetupToolStep;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const statusTableCell = (item: any) =>
  <TableCell
    className={`c-setup-tool-step-list-table-item__cell c-setup-tool-step-list-table-item__cell--status`}>
    <SetupToolStepStatus
      status={item.status}
      label={item.status === StatusName.DONE
        ? I18n.t(T.setupTool.info.completed)
        : item.status === StatusName.NEEDS_REGENERATION
          ? I18n.t(T.setupTool.info.dataNotCurrent)
          : I18n.t(T.setupTool.info.inProgress)}
      id={`setup_tool_step_${item.uniqueIndexForID || item.index}_status`}
    />
  </TableCell>;

export const SetupToolStepsListItemComponent: React.FC<Props> = ({ actions, item }) => {
  const { track } = useTracking();

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    actions.goTo(item.route);

    track(InteractionEvents.buttonClick({
      category: item.gtmCategory,
      action: item.gtmAction,
      event,
    }));
  };

  return (
    <div
      className={
        classNames(`c-setup-tool-step-list-table-item`, {
          'c-setup-tool-step-list-table-item--disabled': item.disabled,
        })
      }
    >
      <Card
        footerContent={item.appendix &&
          <div className={'c-setup-tool-step-list-table-item__appendix'}>
            {item.appendix()}
          </div>
        }
      >
        <>
          <TableCell
            className={`c-setup-tool-step-list-table-item__cell c-setup-tool-step-list-table-item__cell--info`}
          >
            <RoundButton
              label={item.index}
              outline={true}
              direction={ButtonDirection.LEFT}
              size={30}
            />
            <div>
              <h4>
                {item.name}
              </h4>
              {item.date && <h5>{I18n.t(T.setupTool.step.lastModification)} {item.date}</h5>}

              <Media query={{ maxWidth: breakpointDown('LG') }}>
                {(isMedium: boolean) => isMedium ? (
                  !!item.status && statusTableCell(item)
                  ) : (
                    null
                  )}
              </Media>
            </div>
          </TableCell>
          <Media query={{ minWidth: breakpointUp('LG') }}>
            {(isMedium: boolean) => isMedium ? (
              !!item.status && statusTableCell(item)
              ) : (
              null
              )}
          </Media>
          <TableCell
            className={`c-setup-tool-step-list-table-item__cell c-setup-tool-step-list-table-item__cell--start`}
          >
            {item.buttonVisible && (
              <Button
                name={`setup_tool_step_${item.uniqueIndexForID || item.index}_display`}
                size={ButtonSize.SECONDARY}
                theme={ButtonTheme.OUTLINE}
                label={item.buttonLabel || I18n.t(T.setupTool.info.start)}
                isDisabled={item.buttonDisabled || item.disabled}
                onClick={onButtonClick}
              />
            )}
          </TableCell>
        </>
      </Card>
    </div>
  );
};

export const SetupToolStepsListItem = connect(null, mapDispatchToProps)(SetupToolStepsListItemComponent);
