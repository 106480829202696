import {
  GET_PRE_COMMISSIONING_DATA_QUERY,
  MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY,
} from '+setupTool/+preCommissioning/store/+preCommissioning.state';
import { PreCommissioningInterface, PreCommissioningStatus } from '+setupTool/+preCommissioning/store/types';
import { ActionsUnion, createAction } from '+utils/index';

export enum PRE_COMMISSIONING_ACTIONS {
  GET_PRE_COMMISSIONING_DATA = '[+PreCommissioning] GET_PRE_COMMISSIONING_DATA',
  SET_PRE_COMMISSIONING_DATA = '[+PreCommissioning] SET_PRE_COMMISSIONING_DATA',
  SET_PRE_COMMISSIONING_STATUS = '[+PreCommissioning] SET_PRE_COMMISSIONING_STATUS',
  MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT = '[+PreCommissioning] MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT',

  CLEAR_PRE_COMMISSIONING_GENERATION_QUERY_STATUS =
    '[+PreCommissioning] CLEAR_PRE_COMMISSIONING_GENERATION_QUERY_STATUS',
}

export const PreCommissioningActions = {
  getPreCommissioningData: createAction(
    PRE_COMMISSIONING_ACTIONS.GET_PRE_COMMISSIONING_DATA,
    (customerId: string) => ({ queryKey: GET_PRE_COMMISSIONING_DATA_QUERY, customerId }),
  ),
  setPreCommissioningData: createAction(
    PRE_COMMISSIONING_ACTIONS.SET_PRE_COMMISSIONING_DATA,
    (data: PreCommissioningInterface) => ({ data }),
  ),
  setPreCommissioningStatus: createAction(
    PRE_COMMISSIONING_ACTIONS.SET_PRE_COMMISSIONING_STATUS,
    (status: PreCommissioningStatus) => ({ status }),
  ),
  markGenerationOfPreCommissioningDocument: createAction(
    PRE_COMMISSIONING_ACTIONS.MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT,
    (customerId: string, fields: PreCommissioningInterface) =>
      ({ queryKey: MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY, customerId, fields }),
  ),
  clearPreCommissioningGenerationQueryStatus: createAction(
    PRE_COMMISSIONING_ACTIONS.CLEAR_PRE_COMMISSIONING_GENERATION_QUERY_STATUS,
  ),
};

export type PreCommissioningActions = ActionsUnion<typeof PreCommissioningActions>;
