import { FeatureName } from '+config';
import { insertIf } from '+utils/array.util';
import { Font } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/service';
import { BatteryStatusesKey, EnergyFlowSeriesKey, LegendDataSeriesMarker, SharedChartColors, useFeature } from '@sonnen/shared-web';
import { I18n } from 'react-redux-i18n';

export const CHART_FONT: Font = {
  fontFamily: 'Brandon Text',
  fontWeight: 500,
  fontSize: 10,
};

export const getAvailableFilters = () => {
  const { isEnabled: isBatteryChargingDischargingEnabled } = useFeature(FeatureName.BATTERY_CHARGING_DISCHARGING);
  const { isEnabled: isBatteryVppActivityEnabled } = useFeature(FeatureName.BATTERY_VPP_ACTIVITY);

  return [
    {
      key: EnergyFlowSeriesKey.PRODUCTION_POWER,
      color: SharedChartColors.PRODUCTION,
      name: I18n.t(T.customerSingle.analysis.dayChart.productionFilter),
    },
    {
      key: EnergyFlowSeriesKey.CONSUMPTION_POWER,
      color: SharedChartColors.CONSUMPTION,
      name: I18n.t(T.customerSingle.analysis.dayChart.consumptionFilter),
    },
    {
      key: EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
      color: SharedChartColors.DIRECT_USAGE,
      name: I18n.t(T.customerSingle.analysis.dayChart.directUsageFilter),
    },
    {
      key: EnergyFlowSeriesKey.BATTERY_USOC,
      color: SharedChartColors.USOC,
      name: I18n.t(T.customerSingle.analysis.dayChart.batteryUSOCFilter),
      markerType: LegendDataSeriesMarker.LINE,
    },
    ...insertIf(isBatteryVppActivityEnabled, {
      key: BatteryStatusesKey.VPP_ACTIVITY,
      color: SharedChartColors.VPP_ACTIVITY,
      name: I18n.t(T.customerSingle.analysis.dayChart.vppActivityFilter),
    }),
    ...insertIf(isBatteryChargingDischargingEnabled, {
      key: EnergyFlowSeriesKey.BATTERY_CHARGING,
      color: SharedChartColors.BATTERY_CHARGING_OUTLINE,
      name: I18n.t(T.customerSingle.analysis.dayChart.chargingFilter),
    }),
    ...insertIf(isBatteryChargingDischargingEnabled, {
      key: EnergyFlowSeriesKey.BATTERY_DISCHARGING,
      color: SharedChartColors.BATTERY_DISCHARGING,
      name: I18n.t(T.customerSingle.analysis.dayChart.dischargingFilter),
    }),
  ];
};
