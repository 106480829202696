import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import pageImage from '../../../../../assets/img/telescope.svg';

import './LeadInboxEmpty.component.scss';

export const LeadInboxEmpty: React.FC = () => {
  return (
    <div className={'c-lead-inbox-empty'}>
      <img
        className={'c-lead-inbox-empty__icon'}
        src={pageImage}
        alt={'telescope'}
      />
      <div className={'c-lead-inbox-empty__headline'}>
        {I18n.t(T.lead.list._salessolution_.emptyList.headline)}
      </div>
      <div className={'c-lead-inbox-empty__description'}>
        {I18n.t(T.lead.list._salessolution_.emptyList.description)}
      </div>
    </div>
  );
};
