import { formatEnergy } from '+utils/format.util.old';
import { trim } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { isEmpty, isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { EnergyUnit } from '../../../App.constants';
import { LeadOffer } from '../lead/types/leadOffer.interface';
import { LeadProductBattery } from '../lead/types/leadProductBattery.interface';
import { SiteLiveState } from '../site/types';
import { Battery, BatteryAttributes } from './types/battery.interface';

export const getBatteryModelWithCapacity = ({ product, batteryCapacity }: Battery) =>
  trim`
    ${getBatteryProductName(product)}
    ${!isNil(batteryCapacity) ? `(${formatEnergy({
      whValue: batteryCapacity,
      unit: EnergyUnit.KWH,
      precision: 1,
      minPrecision: 0,
    })})` : undefined}
  `;

export const visibleBatterySystemsStatuses = [
  'installed',
  'registered',
];

export const isBatteryInStandby = (siteLiveState: SiteLiveState | undefined): boolean =>
  !!siteLiveState && siteLiveState.batteryInverterState === 'standby';

export const isBatteryInstalled = (battery: Battery | undefined): boolean =>
  !!battery && !!battery.installationDate;

export const hasPvSystem = (battery: Battery | undefined): boolean =>
  !!battery && !isNil(battery.pvPeakPower) && !isNil(battery.pvGridFeedInLimit);

export const hasBatteryBackupDevice = (battery: Battery | undefined): boolean =>
  !!battery && !isNil(battery.backupDeviceType) && battery.backupDeviceType !== 'none';

export const findBatteryInBatteryList = (offer: LeadOffer, productBatteryList: LeadProductBattery[]): LeadProductBattery | undefined =>
  productBatteryList.find(battery => battery.id === offer.battery);

export const isAnyBatteryOnSiteVisible = (batterySystems: BatteryAttributes[]) =>
  !isEmpty(batterySystems.filter(battery => visibleBatterySystemsStatuses.includes(battery.assetStatus)));

export const getBatteryProductName = (product: Battery['product']): string =>
  isNil(product?.quoteNameDe)
    ? I18n.t(T.customerSingle.batteryDetails.defaultBattery)
    : product.quoteNameDe;
