import { from } from 'rxjs';
import { Config } from '../../../../config';
import { jsonApiClient } from '../../network';
import { ChargerLiveStateData } from './types/chargerLiveState.interface';

const getChargerLiveState = (id: string) => from(
  jsonApiClient.get<ChargerLiveStateData>(`${Config.API_URL}/chargers/${id}/live-state`)
    .send());

export const ChargerRepository = {
  getChargerLiveState,
};
