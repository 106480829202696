import { hasSiteStatistics } from '+app/+customer/+analysis/store/helpers/statistics.helpers';
import { SiteOptions } from '+app/shared/store/site/types/site.interface';
import { isNil, isUndefined } from 'lodash/fp';
import { createSelector } from 'reselect';
import { getStatus } from '../query/query.utils';
import { StoreState } from '../store.interface';
import { GET_SITE_LIVE_STATE_QUERY } from './site.state';
import { GET_SITE_STATISTICS_QUERY } from './site.state';

export const getSiteState = (store: StoreState) => store.shared.site;

export const getSite = createSelector(
  getSiteState,
  (siteState) => siteState.site,
);

export const getSiteLiveState = createSelector(
  getSiteState,
  (siteState) => siteState.liveState,
);

export const getSiteLiveStateQueryStatus = createSelector(
  getSiteState,
  (siteState) => getStatus(siteState[GET_SITE_LIVE_STATE_QUERY]),
);

export const siteHasBattery = createSelector(
  getSiteState,
  (siteState) => !!(siteState.site && siteState.site.batterySystems && siteState.site.batterySystems.length),
);

export const getSiteOptions = createSelector(
  getSite,
  state => !isUndefined(state) ? state.options : [],
);

export const hasSiteReadingsOption = createSelector(
  getSiteOptions,
  state => state.includes(SiteOptions.READINGS),
);

export const getSiteStatistics = createSelector(
  getSiteState,
  state => state.statistics,
);

export const getSiteConsumedEnergy = createSelector(
  getSiteStatistics,
  siteStatistics => siteStatistics?.consumedEnergy,
);

const getSiteConsumedEnergyFirstValue = createSelector(
  getSiteConsumedEnergy,
  consumedEnergyState => !isNil(consumedEnergyState)
    ? consumedEnergyState[0]
    : undefined,
);

export const getSiteConsumedEnergyFormated = createSelector(
  getSiteConsumedEnergyFirstValue,
  consumedEnergyState => !isNil(consumedEnergyState)
    ? consumedEnergyState
    : undefined,
);

export const getSiteGridPurchasedEnergy = createSelector(
  getSiteStatistics,
  siteStatistics => siteStatistics ? siteStatistics.gridPurchaseEnergy : undefined,
);

const getSiteGridPurchasedEnergyFirstValue = createSelector(
  getSiteGridPurchasedEnergy,
  gridPurchaseEnergyState => !isNil(gridPurchaseEnergyState)
    ? gridPurchaseEnergyState[0]
    : undefined,
);

export const getSiteGridPurchasedEnergyFormated = createSelector(
  getSiteGridPurchasedEnergyFirstValue,
  gridPurchaseEnergyState => !isNil(gridPurchaseEnergyState)
    ? gridPurchaseEnergyState
    : undefined,
);

export const getStatisticsQueryStatus = createSelector(
  getSiteState,
  state => getStatus(state[GET_SITE_STATISTICS_QUERY]),
);

export const hasStatistics = createSelector(
  getSiteState,
  state => hasSiteStatistics(state.statistics),
);
