import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web/src/components/Icon/index';
import { PageSubheadline } from '@sonnen/shared-web/src/components/PageSubheadline/PageSubheadline.component';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { SonnenDriveBenefitsSlide } from '../SonnenDriveBenefitsSlide';
import { getSlides } from './SonnenDriveBenefits.helper';

import './SonnenDriveBenefits.component.scss';

export const SonnenDriveBenefits: React.FC = () => (
  <div className={'c-sonnen-drive-benefits'}>
    <PageSubheadline>
      {I18n.t(T.drive.benefits.title)}
    </PageSubheadline>
    <div className={'c-sonnen-drive-benefits__slides'}>
      {getSlides().map(({ icon, name, text }) => {
          // tslint:disable-next-line:naming-convention
        const Item = Icon[icon];

        return (
          <SonnenDriveBenefitsSlide
            key={uuid.v4()}
            icon={<Item />}
            iconName={icon}
            name={I18n.t(name)}
            text={I18n.t(text)}
          />
        );
      })}
    </div>
  </div>
);
