import { Icon } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';
import * as uuid from 'uuid';
import { ButtonDirection, RoundButton } from '../RoundButton';

import './WidgetAccordion.component.scss';

export type AccordionProps = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onHeaderClick: () => void;
  className?: string;
  isDisabled?: boolean;
};

export const WidgetAccordion: React.FC<AccordionProps> = ({
  title,
  isOpen,
  onHeaderClick,
  children,
  className,
  isDisabled = false,
}) => {
  const id = `accordion-${uuid.v4()}`;

  const renderHeader = (id: string, title: string, onClick: () => void) => (
    <div
      className={
        classNames('c-widget-accordion__header', className, {
          'c-widget-accordion__header--disabled': isDisabled,
        })
      }
      onClick={isDisabled ? undefined : onClick}
      id={id}
    >
      <div className={'c-widget-accordion__header-inner'}>
        <label
          className={'c-widget-accordion__label'}
          htmlFor={id}
        >
          {title}
        </label>
        <RoundButton
          label={<Icon.Angle className={'c-widget-accordion__icon-inner'} />}
          direction={ButtonDirection.RIGHT}
          size={22}
          className={classNames('c-widget-accordion__icon', {
            'is-rotated': isOpen,
          })}
          color={isDisabled ? 'inactive' : 'info'}
        />
      </div>
    </div>
  );

  return (
    <li className={classNames('c-widget-accordion', className, {
      'c-widget-accordion--disabled': isDisabled,
    })}>
      {renderHeader(id, title, onHeaderClick)}
      <div className={classNames('c-widget-accordion__content-wrapper', {
        'c-widget-accordion__content-wrapper--opened': isOpen,
      })}>
        <div className={'c-widget-accordion__content'}>
          {children}
        </div>
      </div>
    </li>
  );
};
