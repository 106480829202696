import { EnergyUnit } from '+app/App.constants';
import { BatteryBackupDeviceType } from '+app/shared/store/battery/types/battery.interface';
import { DetailListType } from '+shared/components';
import { formatDate, formatPercentage, formatPower } from '+utils/format.util';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { BackupBoxCard } from './types/BackupBoxCard.types';

export const generateBoxHeader = (deviceType: BatteryBackupDeviceType) => deviceType === 'protect'
  ? I18n.t(T.customerSingle.batteryDetails.backupBox.headline.protect)
  : I18n.t(T.customerSingle.batteryDetails.backupBox.headline.backup);

export const factorizeBackupBoxDetailList = (locale: string) => (backupBox: BackupBoxCard): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.backupBox.status),
    value: backupBox.isActive ? I18n.t(T.general.states.on) : I18n.t(T.general.states.off),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.backupBox.outputPower),
    value: !isNil(backupBox.nominalPower) ? formatPower({ locale, unit: EnergyUnit.KW, precision: 1 })(backupBox.nominalPower) : '0 KW',
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.backupBox.powerOutage),
    value: !isNil(backupBox.lastPowerOutageAt) ? formatDate(backupBox.lastPowerOutageAt) : '-',
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.backupBox.powerBuffer),
    value: !isNil(backupBox.powerBuffer) ? formatPercentage({ locale })(backupBox.powerBuffer) : '0%',
  },
];
