import { CountryCode, CountryFlagProvider } from '@sonnen/shared-web';

export enum CountryFeatureName {
  CUSTOMER_CONTRACT = 'CustomerContract',
  SETUP_PHASE = 'SetupPhase',
}

CountryFlagProvider.createFeaturesConfiguration({
  [CountryFeatureName.CUSTOMER_CONTRACT]: {
    whitelist: {
      user: [
        CountryCode.DE,
        CountryCode.AU,
        CountryCode.IT,
      ],
    },
  },
  [CountryFeatureName.SETUP_PHASE]: {
    whitelist: {
      user: [
        CountryCode.DE,
      ],
    },
  },
});
