import { formatPercentage } from '+utils/format.util.old';
import { isNil } from 'lodash/fp';
import * as React from 'react';

import './BatteryLiveCardSoc.component.scss';

interface Props {
  soc: number;
}

export const BatteryLiveCardSoc: React.SFC<Props> = ({ soc }) => (
  <span className={'c-battery-live-card-soc'}>
    {!isNil(soc) && formatPercentage(soc)}
  </span>
);
