import {
  DsoRegistrationFormContainer,
} from '+setupTool/+form/components';
import { DsoRegistrationBattery } from '+setupTool/+form/components/DsoRegistrationBattery';
import {
  getDsoRegistrationPvSystemInitial,
  getDsoRegistrationSonnenBatteryInitial,
  getDsoRegistrationSonnenBatterySchema,
} from '+setupTool/+form/store/schemas';
import {
  CouplingType,
  DsoRegistrationPvSystemInterface,
  DsoRegistrationSonnenBatteryInterface,
} from '+setupTool/+form/store/types';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SetupDataStepKey, SetupDataStepStatus, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import {
  FormWizardStep,
} from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  setAllFormFieldsTouched,
} from '../../store/+form.helpers';
import {
  getActiveStep,
  getBatteryNames,
  getPvSystemForm,
  getSonnenBatteryForm,
} from '../../store/+form.selectors';
import './DsoRegistrationSonnenBattery.component.scss';

interface OwnProps {
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  sonnenBatteryForm: getSonnenBatteryForm(state),
  batteryNames: getBatteryNames(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
  pvSystemForm: getPvSystemForm(state),
});

const mapDispatchToProps = mapActions({
  setDsoSonnenBattery: DsoRegistrationFormActions.setDsoSonnenBattery,
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  setDsoPvSystem: DsoRegistrationFormActions.setDsoPvSystem,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const DsoRegistrationSonnenBatteryComponent: React.FC<Props> = ({
  actions,
  sonnenBatteryForm,
  batteryNames,
  activeStep,
  stepStatus,
  registrationSubject,
  pvSystemForm,
}) => {
  actions.toggleModal(true);
  const wizard = React.useContext(WizardContext);
  const formRef = React.useRef<FormikProps<DsoRegistrationSonnenBatteryInterface>>({} as any);
  const validationSchema = getDsoRegistrationSonnenBatterySchema(registrationSubject);
  const dsoRegistrationSonnenBatteryInitial = getDsoRegistrationSonnenBatteryInitial(registrationSubject);
  const dsoRegistrationPvSystemInitial = getDsoRegistrationPvSystemInitial(registrationSubject);
  const currentBattery = (formRef.current && formRef.current.values)
    ? batteryNames.find(battery => battery.name === formRef.current.values.battery_name)
    : undefined;
  const currentBatteryRef = React.useRef(currentBattery);
  const dcCoupledBattery = pvSystemForm && pvSystemForm.dc_coupled_battery;
  const dcCoupledBatteryRef = React.useRef(dcCoupledBattery);
  const sonnenBatteryFormRef = React.useRef(sonnenBatteryForm);

  React.useEffect(() => {
    currentBatteryRef.current = currentBattery;
  }, [currentBattery]);

  React.useEffect(() => {
    dcCoupledBatteryRef.current = dcCoupledBattery;
  }, [dcCoupledBattery]);

  React.useEffect(() => {
    sonnenBatteryFormRef.current = sonnenBatteryForm;
  }, [sonnenBatteryForm]);

  React.useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_SONNEN_BATTERY);
    activeStep = SubmissionStep.SETUP_DATA_SONNEN_BATTERY;

    return saveValues;
  }, []);

  React.useEffect(() => {
    if (sonnenBatteryForm) {
      formRef.current.setValues({
        ...dsoRegistrationSonnenBatteryInitial,
        ...sonnenBatteryForm,
      });

      if (stepStatus === SetupDataStepStatus.STARTED || stepStatus === SetupDataStepStatus.COMPLETED) {
        setAllFormFieldsTouched(formRef.current);
      }
    }
  }, [sonnenBatteryForm]);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
      document.body.classList.add('body--disabled');
    }
  };

  const saveValues = () => {
    const currentBattery = currentBatteryRef.current;
    const dcCoupledBattery = dcCoupledBatteryRef.current;
    const sonnenBatteryForm = sonnenBatteryFormRef.current;
    const values = formRef.current.values;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
    const newStepStatus = invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;
    const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
      ({...prev, [fieldKey]: dsoRegistrationSonnenBatteryInitial[fieldKey]}), {});

    if (atLeastOneFieldTouched && sonnenBatteryForm && !isEqual(sonnenBatteryForm, values)) {
      let changedPvSystem = false;

      // code for clearing PV System
      if (dcCoupledBattery === false && currentBattery && currentBattery.couplingType === CouplingType.DC) {
        actions.setDsoPvSystem({
          dc_coupled_battery: true,
          pv_inverter_type: dsoRegistrationPvSystemInitial.pv_inverter_type,
          pv_inverter_id: dsoRegistrationPvSystemInitial.pv_inverter_id,
          number_identical_inverters: dsoRegistrationPvSystemInitial.number_identical_inverters,
        });
        changedPvSystem = true;
      }

      if (dcCoupledBattery === true && currentBattery && currentBattery.couplingType === CouplingType.AC) {
        actions.setDsoPvSystem({
          dc_coupled_battery: false,
        });
        changedPvSystem = true;
      }

      actions.setSetupDataStatuses({ sonnenBattery: newStepStatus });
      actions.setDsoSonnenBattery({ ...values, ...invalidOrEmptyValues });
      actions.checkForm(registrationSubject);
      if (changedPvSystem) {
        actions.saveData(SubmissionStep.SETUP_DATA_PV_SYSTEM_AND_SONNEN_BATTERY, {
          [SetupDataStepKey.PV_SYSTEM]: SetupDataStepStatus.STARTED,
          [SetupDataStepKey.SONNEN_BATTERY]: newStepStatus,
        });
      } else {
        actions.saveData(activeStep, { [SetupDataStepKey.SONNEN_BATTERY]: newStepStatus });
      }
    }

    if (
      sonnenBatteryForm
      && !isEqual(values, dsoRegistrationPvSystemInitial)
      && stepStatus !== newStepStatus
    ) {
      actions.saveData(activeStep, { [SetupDataStepKey.SONNEN_BATTERY]: newStepStatus });
    }
  };

  return (
    <Formik
      initialValues={dsoRegistrationSonnenBatteryInitial}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={form => (
        <Form className={'c-form-wizard__form'}>
          <FormWizardStep
            onBeforeSubmit={() => {
              // Enable saving partially complete data
              onSubmit();
            }}
          >
            <DsoRegistrationFormContainer>
              <DsoRegistrationBattery
                form={form}
                isBatteryOnly={registrationSubject === RegistrationSubjectType.BATTERY}
              />
            </DsoRegistrationFormContainer>
          </FormWizardStep>
        </Form>
      )
      }
    />
  );
};

export const DsoRegistrationSonnenBattery = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationSonnenBatteryComponent);
