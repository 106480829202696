import { history } from '+app/router/store';
import { formatUrl } from '+shared/GoogleTagManager/events/eventDecorator/eventDecorator.helper';
import * as React from 'react';

export const useUrlHistory = () => {
  const currentUrlRef = React.useRef(formatUrl(window.location.href));
  const previousUrlRef = React.useRef(formatUrl(document.referrer || window.location.href));

  React.useEffect(() => {
    const unsubscribe = history.listen((x) => {
      const urlCandidate = formatUrl(window.location.href);

      if (urlCandidate !== currentUrlRef.current) {
        previousUrlRef.current = `${currentUrlRef.current}`;
        currentUrlRef.current = `${window.location.href}`;
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return React.useCallback(() => {
    return {
      previousUrl: previousUrlRef.current,
      currentUrl: currentUrlRef.current,
    };
  }, []);
};
