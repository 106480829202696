import { EnergyUnit } from '+app/App.constants';
import { getStatisticsQueryStatus, hasStatistics, siteHasBattery } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { BarChartView, DataContainerTooltipExtension, TooltipEvent, TooltipEventHandler } from '@kanva/charts';
import { View } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/service';
import {
  AnalysisChartCrosshair,
  AnalysisPieChart,
  AnalysisTooltip,
  AnalysisTooltipMeasurementItem,
  autonomyStyle,
  Card,
  factorizeTooltipDate,
  Icon,
  InfoBanner,
  mapSeriesForLegend,
  MediaQuery,
  PageSubheadline,
  prepareTooltipData,
  selfConsumptionStyle,
  SharedChartColors,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { AnalysisBarChart } from '../../components/AnalysisBarChart';
import { AnalysisActions } from '../../store';
import { getStatisticsSelectedDate, shouldStatisticsAppear } from '../../store/+analysis.selector';
import { getBarChartSeries, getPieChartSeries } from '../../store/+analysis.selector';
import { visibleStatisticsSeriesKeys } from '../../store/helpers/+analysisStatistics.helpers';
import { chartTooltipStyles, seriesKeyTranslationMap } from '../../store/helpers/tooltip.helpers';
import { CustomerAnalysisStatisticsToolbar } from '../CustomerAnalysisStatisticsToolbar';
import {
  CHART_HEIGHT,
  getPieChartValueProperties,
  getSeriesKeyTranslations,
} from './CustomerAnalysisStatistics.helpers';

import './CustomerAnalysisStatistics.component.scss';

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface State {
  viewBarChart: View<any> | undefined;
  tooltipEvent?: TooltipEvent;
}

const mapStateToProps = (state: StoreState) => ({
  shouldStatisticsAppear: shouldStatisticsAppear(state),
  statisticsQueryStatus: getStatisticsQueryStatus(state),
  hasStatistics: hasStatistics(state),
  hasBattery: siteHasBattery(state),
  pieChartSeries: getPieChartSeries(state),
  barChartSeries: getBarChartSeries(state),
  statisticsSelectedDate: getStatisticsSelectedDate(state),
});

const mapDispatchToProps = mapActions({
  setDate: AnalysisActions.setStatisticsDate,
});

export class CustomerAnalysisStatisticsComponent extends React.PureComponent<Props, State> {
  private tooltipExtension?: DataContainerTooltipExtension;

  constructor(props: Props) {
    super(props);

    this.tooltipExtension = new DataContainerTooltipExtension({
      onTooltipEvent: this.handleTooltipEvent,
    });

    this.state = {
      viewBarChart: undefined,
    };
  }

  handleTooltipEvent: TooltipEventHandler = (event) => {
    if (!Object.values(event.match.values).every(point => point && isNil(point.y))) {
      this.setState({ tooltipEvent: event });
    }
  };

  handleTooltipPositionChange = (x: number) => (view: View<any> | undefined) => {
    if (this.tooltipExtension && view) {
      this.setState({ viewBarChart: view });
      this.tooltipExtension.simulateAbsoluteCanvasPosition(view as BarChartView, { x, y: 0 });
    }
  };

  fromPointer: React.PointerEventHandler = ({ nativeEvent }) => {
    const { tooltipEvent } = this.state;
    if (tooltipEvent) {
      this.handleTooltipPositionChange(nativeEvent.pageX - tooltipEvent.pointerEvent.offset.left)
        (this.state.viewBarChart);
    }
  };

  render() {
    const {
      pieChartSeries,
      shouldStatisticsAppear,
      statisticsQueryStatus,
      hasBattery,
      hasStatistics,
      barChartSeries,
      statisticsSelectedDate,
    } = this.props;

    const { tooltipEvent, viewBarChart } = this.state;

    const shouldStatisticsChartsAppear = statisticsQueryStatus.pending
    // To clarify: the condition hasBattery probably is redundant
    // as the MKMeter User never has a battery, see shouldStatisticsAppear
  || (statisticsQueryStatus.success && hasBattery && hasStatistics);

    const seriesKeyTranslations = getSeriesKeyTranslations();
    const defaultPieChartValueProperties = { production: '0', consumption: '0', fontSize: 23 };

    const pieChartValues = pieChartSeries
      ? getPieChartValueProperties({
        autonomy: pieChartSeries.autonomy,
        selfConsumption: pieChartSeries.selfConsumption,
      })
      : defaultPieChartValueProperties;

    return shouldStatisticsAppear ? (
      <section className={'c-customer-analysis-statistics'}>
        <PageSubheadline mediumGap={true}>
          {I18n.t(T.customerSingle.analysis.statistics.headline)}
        </PageSubheadline>
          <div className={'c-customer-analysis-statistics__card-content'}>
            <Card header={<CustomerAnalysisStatisticsToolbar />}>
                {shouldStatisticsChartsAppear ? (
                  <div className={'o-grid'}>
                    <div className={'o-grid__column o-grid__column--md-8'}>
                      {barChartSeries &&
                        <div onPointerMove={this.fromPointer}>
                          <div className={'c-customer-analysis-statistics__tooltip'}>
                            <AnalysisTooltip
                              type={'horizontal'}
                              isVisible={statisticsQueryStatus.success}
                              header={factorizeTooltipDate(
                                statisticsSelectedDate.period,
                                tooltipEvent && tooltipEvent.match.primary.x,
                                undefined,
                                statisticsSelectedDate.date,
                              )}
                            >
                              {prepareTooltipData(
                                tooltipEvent && tooltipEvent.match.values,
                                seriesKeyTranslationMap,
                                visibleStatisticsSeriesKeys,
                                chartTooltipStyles,
                              ).map(props => (
                                <AnalysisTooltipMeasurementItem
                                  key={props.seriesKey}
                                  {...props}
                                />
                              ))}
                            </AnalysisTooltip>
                          </div>
                          <div className={'c-customer-analysis-statistics__bar-chart'}>
                            <AnalysisBarChart
                              key={`bar-${statisticsQueryStatus.success}`}
                              dataSeries={barChartSeries}
                              statisticsSelectedDate={statisticsSelectedDate}
                              queryStatus={statisticsQueryStatus}
                              tooltipExtension={this.tooltipExtension}
                              onMount={this.handleTooltipPositionChange}
                            />
                            <Media query={MediaQuery.UP_SM}>
                            {(isDesktop: boolean) => isDesktop ? (
                                <AnalysisChartCrosshair
                                  height={CHART_HEIGHT.WEB}
                                  isVisible={statisticsQueryStatus.success}
                                  crosshairPosition={tooltipEvent?.snap.x || 0}
                                  offset={-18}
                                />
                              ) : (
                                <AnalysisChartCrosshair
                                  height={CHART_HEIGHT.MOBILE}
                                  isVisible={statisticsQueryStatus.success}
                                  crosshairPosition={tooltipEvent?.snap.x || 0}
                                  offset={-18}
                                />
                              )}
                            </Media>
                          </div>
                        </div>
                      }
                    </div>
                    <div className={'o-grid__column o-grid__column--md-4'}>
                    { pieChartSeries && (
                      <div className={'c-customer-analysis-statistics__pie-charts-container'}>
                        <div className={'c-customer-analysis-statistics__pie-chart'}>
                          <AnalysisPieChart
                            title={I18n.t(T.customerSingle.analysis.statistics.consumption)}
                            unit={EnergyUnit.KWH}
                            displayedSeriesKey={StatisticsSeriesKey.CONSUMED_ENERGY}
                            series={pieChartSeries.autonomy}
                            style={autonomyStyle}
                            isPending={statisticsQueryStatus.pending}
                            value={pieChartValues.consumption}
                            valueColor={SharedChartColors.CONSUMPTION}
                            valueFontSize={pieChartValues.fontSize}
                            legendItems={mapSeriesForLegend(
                              pieChartSeries.autonomy,
                              autonomyStyle,
                              seriesKeyTranslations,
                            )}
                          />
                        </div>
                        <div className={'c-customer-analysis-statistics__pie-chart'}>
                          <AnalysisPieChart
                            title={I18n.t(T.customerSingle.analysis.statistics.production)}
                            unit={EnergyUnit.KWH}
                            displayedSeriesKey={StatisticsSeriesKey.PRODUCED_ENERGY}
                            series={pieChartSeries.selfConsumption}
                            style={selfConsumptionStyle}
                            isPending={statisticsQueryStatus.pending}
                            value={pieChartValues.production}
                            valueColor={SharedChartColors.GOLD}
                            valueFontSize={pieChartValues.fontSize}
                            legendItems={mapSeriesForLegend(
                              pieChartSeries.selfConsumption,
                              selfConsumptionStyle,
                              seriesKeyTranslations,
                            )}
                          />
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                ) : (
                  <div className={'c-customer-analysis-statistics__banner'}>
                    <InfoBanner
                      icon={<Icon.HistoryError />}
                      title={I18n.t(T.customerSingle.analysis.statistics.noResultsBanner.title)}
                      subtitle={I18n.t(T.customerSingle.analysis.statistics.noResultsBanner.subtitle)}
                    />
                  </div>
                )}
            </Card>
          </div>
    </section>
  ) : null;
    }
}

export const  CustomerAnalysisStatistics =
  connect(mapStateToProps, mapDispatchToProps)
  (CustomerAnalysisStatisticsComponent);
