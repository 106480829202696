import { useDispatchInputEvent } from '+shared/hooks/useDispatchInputEvent';
import { T } from '@sonnen/shared-i18n/service';
import { Select } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { validateField } from '../Form.helpers';
import { FormFieldError } from '../FormFieldError';

import './FormInputSelect.component.scss';

interface Props<T, U> {
  name: string;
  collection: U[];
  form: FormikProps<T>;
  mapper?: (val: U) => string;
  label?: string;
  placeholder?: string;
  className?: ClassValue;
  search?: (val: string) => U[];
  onSelect?: (val: U) => void;
  hasNoGap?: boolean;
  disableSearchForEmptyCollection?: boolean;
  resetValueIfNoItemSelected?: boolean;
  id?: string;
  onInputChange?: (val: string) => void;
  noResultsComponent?: React.ReactNode;
  additionalInfoText?: any;
  controlledValue?: string;
  isSearchIcon?: boolean;
  isRequired?: boolean;
  suppressValuesInHotjar?: boolean;
  isDisabled?: boolean;
}

export const FormInputSelect = <T extends any = any, U extends any = string>({
  form,
  mapper,
  name,
  collection,
  label,
  placeholder,
  className,
  search,
  onSelect,
  hasNoGap = false,
  disableSearchForEmptyCollection = false,
  resetValueIfNoItemSelected = false,
  id,
  onInputChange,
  noResultsComponent,
  additionalInfoText,
  controlledValue,
  isSearchIcon,
  isRequired = false,
  suppressValuesInHotjar = false,
  isDisabled = false,
}: Props<T, U>) => {
  const { onChange: onChangeDispatch } = useDispatchInputEvent();

  const validation = validateField({ name, form });
  const itemSelected = collection.find(t => JSON.stringify(t) === JSON.stringify(get(name)(form.values)));

  const [items, setItems] = React.useState(collection);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (itemSelected) {
      const val = mapper ? mapper(itemSelected) : String(itemSelected);
      setValue(val);

      if (search) {
        setItems(search(val));
      }

      if (onInputChange) {
        onInputChange(val);
      }
    } else if (disableSearchForEmptyCollection && search && !collection.length) {
      setValue('');
      setItems(search(''));
    } else if (resetValueIfNoItemSelected) {
      setValue('');
      if (search) {
        setItems(search(''));
      }
    }
  }, [itemSelected, collection.length]);

  return (
    <div className={classNames('c-form-input-select', className, {
      'c-form-input-select--no-gap': hasNoGap && !validation.hasError,
    })}>
      {label && <label className={'c-form-input-select__label'}>
        {label} {isRequired && <span className={'c-form-input-select__star'}>*</span>}
      </label>}
      <div className={classNames('c-form-input-select__field-wrapper', {
        'c-form-input-select__field-wrapper--disabled': isDisabled,
      })}
        data-hj-suppress={suppressValuesInHotjar}
      >
        <Field
          name={name}
          component={Select}
          hasError={validation.hasError}
          className={classNames({
            'c-form-input-select__field--error': validation.hasError,
            'c-form-input-select__field--disabled': isDisabled,
          })}
          items={!!value && search ? items : collection}
          placeholder={placeholder}
          itemsSelected={itemSelected}
          itemFactory={mapper || String}
          onSelect={(val: U) => {
            if (onSelect) {
              onSelect(val);
            }
            // we  need this setTimeout, because Formik (as of version 2.2.0)
            // doesn't validate complex forms correctly without it
            setTimeout(() => {
              form.setFieldValue(name, val);
              form.setFieldTouched(name, true);
              onChangeDispatch(name, val);
            }, 0);
          }}
          searchProps={!!search || !!onInputChange ? {
            onInputChange(v: string) {
              form.setFieldValue(name, '');
              form.setFieldTouched(name, true);
              setValue(v);

              if (search) {
                setItems(search(v));
              }

              if (onInputChange) {
                onInputChange(v);
              }
            },
            inputValue: controlledValue ? controlledValue : value,
          } : undefined
          }
          isControlled={true}
          hasBottomGap={true}
          id={id}
          noResultsComponent={noResultsComponent}
          noResultsText={I18n.t(T.general.errors.general.noResults)}
          isSearchIcon={isSearchIcon}
          additionalInfoText={additionalInfoText}
        />
      </div>
      <FormFieldError name={name} form={form} />
    </div>
  );
};
