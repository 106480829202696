import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './LeadOverviewEditWarning.component.scss';

interface Props {
  message: string;
}

export const LeadOverviewEditWarning: React.FC<Props> = ({ message }) => (
  <div className={'c-lead-overview-edit-warning'}>
    <Icon.Warning className={'c-lead-overview-edit-warning__icon'}/>
    {message}
  </div>
);
