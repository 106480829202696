import { Card } from '@sonnen/shared-web';
import * as React from 'react';
import { RegisterCardHeader } from '../RegisterCardHeader';

import './RegisterCard.component.scss';

interface Props {
  children: React.ReactNode;
}

export const RegisterCard: React.SFC<Props> = ({ children }) => (
  <div className={'c-register-card'}>
    <Card
      header={<RegisterCardHeader />}
      noHeaderGap={true}
    >
      {children}
    </Card>
  </div>
);
