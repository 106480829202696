import { CaseListHelper } from '+app/+case/containers/CaseList/CaseList.helper';
import { CaseAggregatedStatus, CaseStatus as CaseStatusProps } from '+shared/store/case/types/case.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Tooltip } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { setCaseStatus, setCaseStatusAggregated } from './CaseStatus.component.helper';

import './CaseStatus.component.scss';

type Props = {
  status: CaseStatusProps;
  lastModifiedDate?: string | null;
};

export const CaseStatus: React.FC<Props> = ({ status, lastModifiedDate }) => {
  const aggregatedStatus = React.useMemo(() => CaseListHelper.getAggregatedName(status), []);

  return (
    <div className={'c-case-status'}>
      {/* TODO waiting for sonnen service decision about what statuses we can show */}
      {/* <Tooltip
        noMargins={true}
        noMinWidth={true}
        trigger={ */}
          <div
            className={classNames('c-case-status__trigger', {
              'c-case-status__trigger--open': aggregatedStatus === CaseAggregatedStatus.OPEN, 
              'c-case-status__trigger--resolved': aggregatedStatus === CaseAggregatedStatus.RESOLVED,
              'c-case-status__trigger--in-progress': aggregatedStatus === CaseAggregatedStatus.IN_PROGRESS,
            })}
          >
            {aggregatedStatus ? setCaseStatusAggregated(aggregatedStatus) : '-'}
          </div>
        {/* }
      >
        <div
          className={'c-case-status__tooltip-text'}
          dangerouslySetInnerHTML={{
            __html: I18n.t(T.cases.statusTooltip.statusChanged, {
              date: isNil(lastModifiedDate) ? '' : lastModifiedDate,
              from: '',
              to: setCaseStatus(status),
            }),
          }}
        />
      </Tooltip> */}
    </div>
  );
};
