import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { LeadImpactAnalysisTile } from '+app/+lead/+impactAnalysis/components/LeadImpactAnalysisTile';
import { isFlatOnlyOffer } from '+app/+lead/+offer/store/+offer.helper';
import { LeadOverviewVppActionBox } from '+app/+lead/+overview/containers/LeadOverviewVppActionBox';
import { isConfigurationMultiplePv, isVppMeteringMandatory } from '+app/+lead/store/+lead.helper';
import { FeatureName } from '+config/featureFlags';
import { getCurrencyForMarket } from '+lead/+configuration/components/LeadConfigurationPreview';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { LeadConfigurationResponse, LeadImpactAnalysisResponse, LeadOffer } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, useFeature, WarningText } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationLine, LeadConfigurationLineType } from '../LeadConfigurationLine';
import {
  LeadConfigurationTile,
  LeadConfigurationTileProps,
  LeadConfigurationTileStatus,
} from '../LeadConfigurationTile';
import { mapConfigurationTileStatusForHardwareOffer, renderStatus } from '../LeadConfigurationTile/LeadConfigurationTile.helper';
import {
  factorizeBatteryDetails,
  factorizeConfDetails,
  factorizeConfMainDetails,
  factorizeHouseholdDetails,
  factorizeMultiplePvSystemDetails,
  factorizePvSystemDetails,
  getImpactAnalysisNotAvailableMsg,
  getProductName,
} from './LeadFlatConfigurationTile.helper';

import './LeadFlatConfigurationTile.component.scss';

interface OwnProps {
  configuration: LeadConfigurationResponse;
  isTileActionDisabled: boolean;
  areAnyOfferSent?: boolean;
  isAnyOfferAccepted?: boolean;
  impactAnalysis?: LeadImpactAnalysisResponse;
  isDsoBlackListed?: boolean;
  isStatusUpdateDisabled?: boolean;
  hardwareStatus?: LeadConfigurationTileStatus;
  isHardwareProductAccepted?: boolean;
  isOfferBlocked?: boolean;
  isComposingOfferDisabled?: boolean;
  offer: LeadOffer;
  onImpactAnalysisStart?: () => void;
  onImpactAnalysisDownload?: (event: React.MouseEvent<any>) => void;
  onImpactAnalysisDelete?: () => void;
  onImpactAnalysisSend?: () => void;
  onHardwareStatusModalOpen?: (event: React.MouseEvent) => void;
}

type Props =
  & Omit<LeadConfigurationTileProps,
    'isOfferCreationDisabled' |
    'creationDate' |
    'offerButtonLabel' |
    'productName'
  >
  & OwnProps
  ;

const DetailsParagraph: React.FC<{ title?: string, detailsArray: Array<{ title: string, value: string }> }> =
  ({ title, detailsArray }) => (
    <>
      {title ?
        <p className={'c-lead-flat-configuration-tile__config-values-category-name'}>
          {title}
        </p>
        : null
      }
      {detailsArray.map(({ title, value }, index) =>
        <LeadConfigurationLine
          title={title}
          value={value}
          key={`detail-${index}`}
          type={LeadConfigurationLineType.BOLD}
        />,
      )}
    </>
  );

export const LeadFlatConfigurationTile: React.FC<Props> = ({
  configuration,
  isTileActionDisabled,
  areAnyOfferSent,
  isAnyOfferAccepted,
  impactAnalysis,
  isDsoBlackListed = false,
  status = LeadConfigurationTileStatus.PREVIEW,
  isStatusUpdateDisabled,
  hardwareStatus = LeadConfigurationTileStatus.PREVIEW,
  isHardwareProductAccepted = false,
  isOfferBlocked = false,
  isComposingOfferDisabled = false,
  onImpactAnalysisStart,
  onImpactAnalysisDelete,
  onImpactAnalysisDownload,
  onImpactAnalysisSend,
  onHardwareStatusModalOpen,
  offer,
  ...restTileProps
}) => {
  const isOfferSentOrAccepted = () => [
    LeadConfigurationTileStatus.SENT,
    LeadConfigurationTileStatus.ACCEPTED,
  ].includes(status);

  const isVppCheckDone = configuration.vppCheckDone || isOfferSentOrAccepted();
  const { onCompose } = restTileProps;
  const shouldDisplayVppBonusCheck = onCompose && (configuration.powerPlant.vppMeteringRecommended || isVppCheckDone);

  const isFlatOfferCreationDisabled = isComposingOfferDisabled
    || (!!shouldDisplayVppBonusCheck && !isVppCheckDone);

  const isIaCreationDisabled = isAnyOfferAccepted
    && !(impactAnalysis && impactAnalysis.status === 'sent')
    || (!!shouldDisplayVppBonusCheck && !isVppCheckDone)
    || isFlatDirectConfiguration(configuration)
    || isDsoBlackListed
    || isConfigurationMultiplePv(configuration)
    || isOfferBlocked;

  const shouldDisplayImpactAnalysisTile = [
    onImpactAnalysisStart,
    onImpactAnalysisDelete,
    onImpactAnalysisDownload,
    onImpactAnalysisSend,
  ].some(action => action !== undefined);

  const renderFlatTileBody = (configuration: LeadConfigurationResponse) => {
    const currency = getCurrencyForMarket(configuration.market);
    const sonnenFlatHeaderDetails = factorizeConfMainDetails(configuration, currency);
    const sonnenFlatDetails = factorizeConfDetails(configuration, currency);
    const householdDetails = factorizeHouseholdDetails(configuration);

    const { isEnabled: vppQuestionForFlatDirect } = useFeature(FeatureName.VPP_QUESTION_FOR_FLAT_DIRECT);

    return (
      <>
        <div className={'c-lead-flat-configuration-tile__header'}>
          <p className={'c-lead-flat-configuration-tile__header-subheadline'}>
            {I18n.t(T.customerSingle.overview.sonnenFlat.headline)}
          </p>
          <div className={'c-lead-configuration-tile__header-detail'}>
            {renderStatus(status)}
          </div>
        </div>
        <div className={'c-lead-flat-configuration-tile__header-details'}>
          {sonnenFlatHeaderDetails.map(({ title, value, type }, index) =>
            <LeadConfigurationLine
              title={title}
              value={value}
              type={type}
              key={`detail-${index}`}
            />,
          )}
        </div>
        {sonnenFlatDetails.map(({ title, value, type }, index) =>
          <LeadConfigurationLine
            title={title}
            value={value}
            type={type}
            key={`detail-${index}`}
          />,
        )}

        <div className={'c-lead-flat-configuration-tile__vat'}>
          <FormInputInfo isBold={true}>
            {I18n.t(T.lead.configuration._salessolution_.preview.vatInfo,
              { vat: parseFloat(configuration.valueAddedTax.value.toString()) })}
          </FormInputInfo>
        </div>

        <p className={'c-lead-flat-configuration-tile__paragraph--bold'}>
          {I18n.t(T.lead.configuration._salessolution_.preview.calculationBasedOn)}
        </p>
        <DetailsParagraph
          detailsArray={householdDetails}
        />

        {(configuration.vppCheckDone || !isVppMeteringMandatory(configuration)) &&
          <FormInputInfo isBold={true}>
            {isVppMeteringMandatory(configuration)
              ? isFlatDirectConfiguration(configuration) && vppQuestionForFlatDirect
                ? I18n.t(T.lead.configuration._salessolution_.meterDocumented)
                : I18n.t(T.lead.configuration._salessolution_.meterPreparationRequired)
              : I18n.t(T.lead.configuration._salessolution_.noMeterPreparation)
            }
          </FormInputInfo>
        }
      </>
    );
  };

  const renderFlatConfigurationDetails = (configuration: LeadConfigurationResponse) => {
    const pvSystemDetails = factorizePvSystemDetails(configuration);
    const batteryDetails = factorizeBatteryDetails(configuration);
    const mappedHardwareStatus = mapConfigurationTileStatusForHardwareOffer(hardwareStatus);

    return (
      <>
        <div className={'c-lead-flat-configuration-tile__header'}>
          <p className={'c-lead-flat-configuration-tile__header-subheadline'}>
            {I18n.t(T.lead.hardware._salessolution_.configurationTile.headline.hardware)}
            {isFlatOnlyOffer(offer)
              ? <span className={'c-lead-flat-configuration-tile__header-subheadline-span'}>
                  {I18n.t(T.lead.configuration._salessolution_.preview.alreadyExisting)}
                </span>
              : null
            }
          </p>
          <div className={'c-lead-configuration-tile__header-detail'}>
            {renderStatus(mappedHardwareStatus)}
          </div>
        </div>

        {configuration.photovoltaicSystem.subSystems ?
          configuration.photovoltaicSystem.subSystems.map((subSystem, index) => (
            <DetailsParagraph
              title={subSystem.systemName!}
              detailsArray={factorizeMultiplePvSystemDetails(subSystem)}
              key={`sub-system-${index}`}
            />
          ))
          :
          <DetailsParagraph
            title={I18n.t(T.lead.configuration._salessolution_.preview.pvSystem)}
            detailsArray={pvSystemDetails}
          />
        }

        <DetailsParagraph
          title={I18n.t(T.lead.configuration._salessolution_.preview.sonnenBattery)}
          detailsArray={batteryDetails}
        />
        {onHardwareStatusModalOpen && !isFlatOnlyOffer ?
          <div className={'c-lead-flat-configuration-tile__status-button-wrapper'}>
            <Button
              label={I18n.t(T.lead.hardware._salessolution_.form.statusChange.button.updateStatus)}
              onClick={onHardwareStatusModalOpen}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.OUTLINE}
              isDisabled={isStatusUpdateDisabled}
            />
          </div>
          : null
        }
      </>
    );
  };

  return (
    <div className={'c-lead-flat-configuration-tile'}>
      <LeadConfigurationTile
        creationDate={configuration.createdAt}
        isOfferCreationDisabled={isFlatOfferCreationDisabled}
        isTileActionDisabled={isTileActionDisabled || isHardwareProductAccepted}
        offerButtonLabel={I18n.t(T.lead.overview._salessolution_.composeAnOffer)}
        productName={getProductName(configuration, offer)}
        status={status} // @Future: change place of displaying status
        hasDeletionWarning={status === LeadConfigurationTileStatus.SENT}
        offerId={offer?.id}
        {...restTileProps}
      >
        <div className={'c-lead-flat-configuration-tile__body'}>
          {renderFlatTileBody(configuration)}
        </div>
        <div className={'c-lead-flat-configuration-tile__body'}>
          {renderFlatConfigurationDetails(configuration)}
        </div>

        {shouldDisplayVppBonusCheck &&
          <LeadOverviewVppActionBox
            isVppCheckDone={isVppCheckDone}
            configurationId={configuration.id}
            isConfigurationTileDisabled={isComposingOfferDisabled}
          />
        }
        {shouldDisplayImpactAnalysisTile && (
          <LeadImpactAnalysisTile
            impactAnalysis={impactAnalysis}
            onStart={onImpactAnalysisStart}
            onDelete={onImpactAnalysisDelete}
            onDownload={onImpactAnalysisDownload}
            onSend={onImpactAnalysisSend}
            isSendable={[
              LeadConfigurationTileStatus.SENT,
              LeadConfigurationTileStatus.ACCEPTED,
            ].includes(status) || !!areAnyOfferSent}
            isDisabled={isIaCreationDisabled}
            isOfferAccepted={status === LeadConfigurationTileStatus.ACCEPTED}
            warningText={getImpactAnalysisNotAvailableMsg(configuration)}
          />
        )}
      </LeadConfigurationTile>

      {shouldDisplayVppBonusCheck
        && !isVppCheckDone
        && !isTileActionDisabled
        && !isOfferBlocked &&
        <div className={'c-lead-flat-configuration-tile__vpp-warning'}>
          <WarningText text={I18n.t(T.lead.overview._salessolution_.fillVppWarning)} />
        </div>
      }
    </div>
  );
};
