import { makeEventDecorator } from '@sonnen/shared-web';
import {
  EventHitType,
  formatActionParts,
  formatCategory,
  formatLabelParts,
  formatPageView,
} from './eventDecorator';

type PageViewEventInput = {
  page: string;
};

type BasicEventInput = {
  category: string;
  action: string;
};

type LinkEventInput = {
  category: string;
  action: string;
  event: React.SyntheticEvent<HTMLAnchorElement>;
};

type ButtonEventInput = {
  category: string;
  action: string;
  event?: React.SyntheticEvent<HTMLButtonElement>;
  labels?: string[],
};

type SearchEventInput = {
  category: string;
  action: string;
};

const pageViewEventDecorator = makeEventDecorator({
  page: formatPageView,
});

const clickEventDecorator = makeEventDecorator({
  category: formatCategory,
  action: formatActionParts,
  label: formatLabelParts,
});

export const InteractionEvents = {
  pageView: pageViewEventDecorator(({ page }: PageViewEventInput) => ({
    hitType: EventHitType.MANUAL_PAGEVIEW_TRIGGER,
    page,
  })),
  iconClick: clickEventDecorator(({ category, action }: BasicEventInput) => {
    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Icon click', action],
      labelParts: [],
    };
  }),
  linkClick: clickEventDecorator(({ category, action, event }: LinkEventInput) => {
    const linkTarget = (event.currentTarget || event.target as HTMLAnchorElement);
    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Link click', action],
      labelParts: linkTarget ? [linkTarget.innerText, linkTarget.href] : [],
    };
  }),
  buttonClick: clickEventDecorator(({ category, action, event, labels = [] }: ButtonEventInput) => {
    const buttonTarget = event && (event.currentTarget || event.target as HTMLButtonElement) || '';
    const labelParts = buttonTarget ?
        [buttonTarget.innerText, ...labels]
        : [...labels];

    return {
      hitType: EventHitType.MANUAL_TRIGGER,
      category,
      actionParts: ['Button click', action],
      labelParts,
    };
  }),
  searchTrigger: clickEventDecorator(({ category, action }: SearchEventInput) => ({
    hitType: EventHitType.MANUAL_TRIGGER,
    category,
    actionParts: ['Search trigger', action],
    labelParts: [],
  })),
};
