import { ActionsUnion, createAction } from '../../../utils';
import { ConfigurationForm } from './types';

// @TODO: change all occourencies where feature actions are involved (keep the following format)
export enum CONFIGURATION_PAGE_ACTIONS {
  SET_CONFIGURATION_FORM = '[+Configuration] SET_CONFIGURATION_FORM',
  CREATE_RECOMMENDATION = '[+Configuration] CREATE_RECOMMENDATION',
  SET_CONFIGURATION_HINT = '[+Configuration] SET_CONFIGURATION_HINT',
  SET_CONFIGURATION_PROPOSAL = '[+Configuration] SET_CONFIGURATION_PROPOSAL',
  SETUP_CLEAR_CONFIGURATION_HINT = '[+Configuration] SETUP_CLEAR_CONFIGURATION_HINT',
  CLEAR_CONFIGURATION_HINT = '[+Configuration] CLEAR_CONFIGURATION_HINT',
  CLEAR_DATA = '[+Configuration] CLEAR_DATA',
  REMOVE_CONFIGURATION = '[+Configuration] REMOVE_CONFIGURATION',
  SAVE_CONFIGURATION = '[+Configuration] SAVE_CONFIGURATION',
  UPDATE_AUTARKY = '[+Configuration] UPDATE_AUTARKY',
}

export const ConfigurationPageActions = {
  setConfigurationForm: createAction(
    CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_FORM,
    (configurationForm: ConfigurationForm) => ({ configurationForm }),
  ),
  createRecommendation: createAction(
    CONFIGURATION_PAGE_ACTIONS.CREATE_RECOMMENDATION,
    (configurationForm: ConfigurationForm) => ({ configurationForm }),
  ),
  setConfigurationHint: createAction(
    CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_HINT,
    (configurationHint: any /* @TODO */) => ({ configurationHint }),
  ),
  setupClearConfigurationHint: createAction(
    CONFIGURATION_PAGE_ACTIONS.SETUP_CLEAR_CONFIGURATION_HINT,
  ),
  clearConfigurationHint: createAction(
    CONFIGURATION_PAGE_ACTIONS.CLEAR_CONFIGURATION_HINT,
  ),
  setConfigurationProposal: createAction(
    CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_PROPOSAL,
    (configurationProposal: any /* @TODO */) => ({ configurationProposal }),
  ),
  clearData: createAction(
    CONFIGURATION_PAGE_ACTIONS.CLEAR_DATA,
  ),
  saveConfiguration: createAction(
    CONFIGURATION_PAGE_ACTIONS.SAVE_CONFIGURATION,
    (configurationId: string, offerId?: string, batteryId?: string) =>
      ({ configurationId, offerId, batteryId }),
  ),
  removeConfiguration: createAction(
    CONFIGURATION_PAGE_ACTIONS.REMOVE_CONFIGURATION,
    (configurationId: string) => ({ configurationId }),
  ),
  updateAutarky: createAction(
    CONFIGURATION_PAGE_ACTIONS.UPDATE_AUTARKY,
    (autarky: number) => ({ autarky }),
  ),
};

export type ConfigurationPageActions = ActionsUnion<typeof ConfigurationPageActions>;
