import { getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { sortByDate } from '+app/utils';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { REMOVE_FILES_QUERY, SEND_FILES_QUERY } from '+setupTool/+form/store/+form.dictionary';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import { PVRegisterActions } from '+setupTool/+pvRegister/store';
import { VppDocumentationActions } from '+setupTool/+vppDocumentation/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { StatusName } from '+setupTool/store/+setupTool.dictionary';
import { LeadStatus, LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { DsoRegisterActions, getDsoRegisterForm } from '+shared/store/setupTool';
import { DsoRegistrationFormAttributes, DsoRegistrationFormResponse } from '+shared/store/setupTool/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileId } from '+shared/store/user/user.selectors';
import { isStatusSet } from '+utils/status.util';
import { getDsoCommissioningFields } from '../+dsoCommissioning/store/+dsoCommissioning.selectors';
import { DsoCommissioningInterface } from '../+dsoCommissioning/store/types';
import {
  getFasteningTypeConsumptionFieldVisibility,
  getMeterCabinetPreparedFieldVisibility,
  getProductionMeterFieldVisibility,
  getVppDocumentationFields,
  getVppDsoReferenceNumberPvVisibility,
} from '../+vppDocumentation/store/+vppDocumentation.selectors';
import { VppDocumentationInterface } from '../+vppDocumentation/store/types';
import { getRegistrationSubject, getSubmissionId } from './+setupTool.selectors';

import { T } from '@sonnen/shared-i18n/service';
import { includes, isNull, isUndefined, omit } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { of, zip } from 'rxjs';

export const isEmptyValue = (value: any) =>
  isUndefined(value) || isNull(value) || value === '';

export const getLatestSubmission = (submissions: DsoRegistrationFormResponse[], key: string) =>
  submissions.sort(sortByDate(key)).pop();

export const getSubmissionData$ = (state: StoreState) => zip(
  of(getDsoRegisterForm(state) as DsoRegistrationFormAttributes),
  of(getDsoCommissioningFields(state) as DsoCommissioningInterface),
  of(getVppDocumentationFields(state) as VppDocumentationInterface),
  of(getVppDsoReferenceNumberPvVisibility(state)),
  of(getMeterCabinetPreparedFieldVisibility(state)),
  of(getFasteningTypeConsumptionFieldVisibility(state)),
  of(getProductionMeterFieldVisibility(state)),
  of(getLeadId(state)),
  of(getUserProfileId(state)),
  of(getSubmissionId(state)),
  of(getRegistrationSubject(state)),
);

const getErrorMessages = () => {
  return ({
    [DsoRegisterActions.postRegisterDso.type]: I18n.t(T.setupTool.error.postRegisterDso),
    [DsoRegisterActions.patchSubmission.type]: I18n.t(T.setupTool.error.patchSubmission),
    [SetupToolActions.saveRegistrationSubjectType.type]: I18n.t(T.setupTool.error.saveRegistrationSubjectType),
    [DsoRegisterActions.postSendDsoDocument.type]: I18n.t(T.setupTool.error.postSendDsoDocument),
    [DsoRegisterActions.removeDsoDocument.type]: I18n.t(T.setupTool.error.removeDsoDocument),
    [DsoRegisterActions.postRegisterPV.type]: I18n.t(T.setupTool.error.postRegisterPV),
    [PVRegisterActions.registerPvrManually.type]: I18n.t(T.setupTool.error.registerPvrManually),
    [DsoRegisterActions.postVpp.type]: I18n.t(T.setupTool.error.postVpp),
    [DsoRegistrationFormActions.getDsoList.type]: I18n.t(T.setupTool.error.getDsoList),
    [PVRegisterActions.startGettingSubmissions.type]: I18n.t(T.setupTool.error.startGettingSubmissions),
    [SetupToolActions.getSetupToolSubmissionData.type]: I18n.t(T.setupTool.error.getSetupToolSubmissionData),
    [PreCommissioningActions.getPreCommissioningData.type]: I18n.t(T.setupTool.error.getPreCommissioningData),
    [PreCommissioningActions.markGenerationOfPreCommissioningDocument.type]:
    I18n.t(T.setupTool.error.markGenerationOfPreCommissioningDocument),
      [DsoRegistrationFormActions.getPVModuleVendors.type]: I18n.t(T.setupTool.error.getPVModuleVendors),
    [DsoRegistrationFormActions.getPVModules.type]: I18n.t(T.setupTool.error.getPVModules),
    [DsoRegistrationFormActions.getPVInverterVendors.type]: I18n.t(T.setupTool.error.getPVInverterVendors),
    [DsoRegistrationFormActions.getPVInverters.type]: I18n.t(T.setupTool.error.getPVInverters),
    [DsoRegistrationFormActions.getBatteryNames.type]: I18n.t(T.setupTool.error.getBatteryNames),
    [DsoRegistrationFormActions.getBatteries.type]: I18n.t(T.setupTool.error.getBatteries),
    [DsoRegistrationFormActions.getInstallers.type]: I18n.t(T.setupTool.error.getInstallers),
    [DsoRegistrationFormActions.removeInstallers.type]: I18n.t(T.setupTool.error.removeInstallers),
    [VppDocumentationActions.generateCompletionNotification.type]:
    I18n.t(T.setupTool.error.generateCompletionNotification),
  });
};

export const mapActionTypeToErrorMessage = (actionType: string) => {
  const errorMessages = getErrorMessages();

  return errorMessages[actionType] || I18n.t(T.lead.boc._salessolution_.form.generalValidationError);
};

export const mapErrorToAnalyticsType = (actionType: string) => {
  const errorMessages = getErrorMessages();

  const analyticsTypes = {
    [errorMessages[DsoRegisterActions.postRegisterDso.type]]: 'DSO registration failed',
    [errorMessages[DsoRegisterActions.patchSubmission.type]]: 'Patch submision failed',
    [errorMessages[SetupToolActions.saveRegistrationSubjectType.type]]: 'Save registration subject failed',
    [errorMessages[DsoRegisterActions.postSendDsoDocument.type]]: 'Send DSO document failed',
    [errorMessages[DsoRegisterActions.removeDsoDocument.type]]: 'Remove DSO document failed',
    [errorMessages[DsoRegisterActions.postRegisterPV.type]]: 'PV registration failed',
    [errorMessages[PVRegisterActions.registerPvrManually.type]]: 'PV manually registration failed',
    [errorMessages[DsoRegisterActions.postVpp.type]]: 'VPP send documentation failed',
    [errorMessages[DsoRegistrationFormActions.getDsoList.type]]: 'Get DSO list failed',
    [errorMessages[PVRegisterActions.startGettingSubmissions.type]]: 'PVR getting submissions failed',
    [errorMessages[SetupToolActions.getSetupToolSubmissionData.type]]: 'Get submission data failed',
    [errorMessages[PreCommissioningActions.getPreCommissioningData.type]]: 'Get precommissioning data failed',
    [errorMessages[PreCommissioningActions.markGenerationOfPreCommissioningDocument.type]]:
      'Mark generation of precommissioning document failed',
    [errorMessages[DsoRegistrationFormActions.getPVModuleVendors.type]]: 'Get PV module vendors failed',
    [errorMessages[DsoRegistrationFormActions.getPVModules.type]]: 'Get PV modules failed',
    [errorMessages[DsoRegistrationFormActions.getPVInverterVendors.type]]: 'Get PV inverter vendors failed',
    [errorMessages[DsoRegistrationFormActions.getPVInverters.type]]: 'Get PV inverters failed',
    [errorMessages[DsoRegistrationFormActions.getBatteryNames.type]]: 'Get battery names failed',
    [errorMessages[DsoRegistrationFormActions.getBatteries.type]]: 'Get batteries failed',
    [errorMessages[DsoRegistrationFormActions.getInstallers.type]]: 'Get installers failed',
    [errorMessages[DsoRegistrationFormActions.removeInstallers.type]]: 'Remove instalers failed',
    [errorMessages[VppDocumentationActions.generateCompletionNotification.type]]:
      'VPP generate completion notification faled',
  };

  return analyticsTypes[actionType] || 'undefined error';
};

export const getActiveStatusManagementStatuses = ({ summary }: LeadStatus) => {
  const registrationStatuses = [
    LeadStatusName.DSO_REGISTRATION_COMPLETED,
    LeadStatusName.DSO_COMMISSIONING_COMPLETED,
    LeadStatusName.BNETZA_REGISTRATION_COMPLETED,
    LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED,
  ];
  const hardwareSetupStatuses = [
    LeadStatusName.HW_COMMISSIONING_COMPLETED,
    LeadStatusName.DSO_METER_INSTALLED,
  ];
  const sonnenFlatSetupStatuses = [
    LeadStatusName.FLAT_OFFER_SENT,
    LeadStatusName.FLAT_OFFER_ACCEPTED,
    LeadStatusName.FLAT_ORDER_CONFIRMED,
    // TODO uncomment when Date of Delivery status will be needed
    // LeadStatusName.START_OF_DELIVERY_FIXED,
    LeadStatusName.VPP_PREPARATION_DONE,
  ];

  const activeStatuses = Object.keys(summary).filter(status => isStatusSet(summary[status]));
  const activeRegistrationStatuses = activeStatuses.filter(status => includes(registrationStatuses, status));
  const activeHardwareSetupStatuses = activeStatuses.filter(status => includes(hardwareSetupStatuses, status));
  const activeSonnenFlatSetupStatuses = activeStatuses.filter(status => includes(sonnenFlatSetupStatuses, status));

  return ({
    registration: activeRegistrationStatuses,
    hardwareSetup: activeHardwareSetupStatuses,
    sonnenFlatSetup: activeSonnenFlatSetupStatuses,
    registrationCompleted: activeRegistrationStatuses.length === registrationStatuses.length,
    hardwareSetupCompleted: activeHardwareSetupStatuses.length === hardwareSetupStatuses.length,
    sonnenFlatSetupCompleted: activeSonnenFlatSetupStatuses.length === sonnenFlatSetupStatuses.length,
  });
};

export const getDSORegCommStatus = (
  isFormFulfilled: boolean,
  latestDocumentsGeneration: string,
  latestFormModification: string | undefined,
) =>
  isFormFulfilled && latestDocumentsGeneration
    ? moment(latestDocumentsGeneration).isBefore(latestFormModification)
      ? StatusName.IN_PROGRESS
      : StatusName.DONE
    : undefined;

export const omitFormValues = <T extends object>(allValues: T, valuesToOmit: string[]) =>
  omit(allValues, valuesToOmit) as T;

export const sendFile = (
  file: any,
  category: string,
  userId: string,
  leadId: string,
  submissionId: any,
  sendDsoDocumentsFile: any,
) => {
  sendDsoDocumentsFile({
    file: file[0],
    userId,
    customerId: leadId,
    submissionId,
    category,
    queryKey: SEND_FILES_QUERY[category],
  });
};

export const removeFile = (
  documentId: any,
  submissionId: any,
  removeDsoDocumentsFile: any,
) => {
  removeDsoDocumentsFile({
    documentId,
    submissionId,
    queryKey: REMOVE_FILES_QUERY,
  });

  return documentId;
};
