import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import {
  getAddressAutosuggestions,
  getAddressAutosuggestionsQueryStatus,
  getAddressDetails,
} from '+app/+lead/store/+lead.selectors';
import { LeadForm } from '+app/+lead/store/types';
import { FormInputSelect } from '+shared/components';
import { useDebounce } from '+shared/hooks/useDebounce';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { clearAddressFields, renderNoResultsComponent } from './LeadAutosuggestedAddress.helper';

import './LeadAutosuggestedAddress.component.scss';

const mapStateToProps = (state: StoreState) => ({
  addressAutosuggestions: getAddressAutosuggestions(state),
  addressAutosuggestionsQueryStatus: getAddressAutosuggestionsQueryStatus(state),
  addressDetails: getAddressDetails(state),
});

const mapDispatchToProps = mapActions({
  getAddressAutosuggestions: LeadPageActions.getAddressAutosuggestions,
  getAddressDetails: LeadPageActions.getAddressDetails,
  clearAddressData: LeadPageActions.clearAddressData,
});

interface ComponentProps {
  form: FormikProps<LeadForm>;
  setInputsAppear: (arg: boolean) => void;
  shouldInputsAppear: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

export const LeadAutosuggestedAddressComponent: React.FC<Props> = ({
  form,
  actions,
  addressDetails,
  setInputsAppear,
  addressAutosuggestions,
  addressAutosuggestionsQueryStatus,
  shouldInputsAppear,
}) => {
  const [sessionId, setSessionId] = React.useState<string>(uuid.v4());
  const [isFinderFocused, setIsFinderFocused] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [selectedAddress, setSelectedAddress] = React.useState<string>('');
  const [selectedAddressId, setSelectedAddressId] = React.useState<string>('');
  const [userVisibleAddress, setUserVisibleAddress] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(searchValue, 400);

  const switchToManualAddress = () => {
    setInputsAppear(true);
    clearAddressFields(form);

    setUserVisibleAddress('');
    setSearchValue('');
  };

  const handleFocusIn = () => {
    setIsFinderFocused(true);
  };

  const handleFocusOut = () => {
    setIsFinderFocused(false);
  };

  // event listeners are necessary to pick something for the user if they don't choose anything from the list
  React.useEffect(() => {
    const autosuggestedAddressInput = document.getElementsByClassName('sw-select__input');

    if (!isEmpty(autosuggestedAddressInput)) {
      autosuggestedAddressInput[0].addEventListener('focusin', handleFocusIn);
      autosuggestedAddressInput[0].addEventListener('focusout', handleFocusOut);
    }

    return () => {
      actions.clearAddressData();

      if (!isEmpty(autosuggestedAddressInput)) {
        autosuggestedAddressInput[0].removeEventListener('focusin', handleFocusIn);
        autosuggestedAddressInput[0].removeEventListener('focusout', handleFocusOut);
      }
    };
  }, []);

  // get autocomplete address suggestions
  React.useEffect(() => {
    if (form.dirty) {
      actions.getAddressAutosuggestions(debouncedSearchValue, form.values.deliveryAddress.country, sessionId);
    }
  }, [debouncedSearchValue]);

  // set error or address on focus out
  React.useEffect(() => {
    if (!isEmpty(addressAutosuggestions) && !isFinderFocused) {
      setInputsAppear(true);
      setSelectedAddress(addressAutosuggestions[0].description);
      setSelectedAddressId(addressAutosuggestions[0].id);
      setUserVisibleAddress(addressAutosuggestions[0].description);
    }

    if (isEmpty(addressAutosuggestions) && !isFinderFocused && form.touched.autosuggestedAddress) {
      setInputsAppear(true);
      clearAddressFields(form);
    }
  }, [isFinderFocused]);

  // get address details for selected address
  React.useEffect(() => {
    if (!isEmpty(selectedAddressId) && !isFinderFocused) {
      actions.getAddressDetails(selectedAddressId, sessionId, selectedAddress, form.values.deliveryAddress.country);
    }
  }, [selectedAddressId]);

  // set address details to form fields
  React.useEffect(() => {
    if (addressDetails && addressDetails.street && addressDetails.streetNumber) {
      // first case covers scenarios for both street and house number existing
      // atm street returned from API is a glued street + number, so we need a substring to have street only
      // TODO: it will be changed on the backend once we decide on this version of autosuggestion
      form.setFieldValue('deliveryAddress.street', addressDetails.street.substring(0,
        addressDetails.street.length - addressDetails.streetNumber.length));
      form.setFieldValue('deliveryAddress.houseNumber', addressDetails.streetNumber);
    } else if (addressDetails && addressDetails.street) {
      // then house number doesn't exist, but we need to clear houseNumber if it was found previously
      form.setFieldValue('deliveryAddress.street', addressDetails.street);
      form.setFieldValue('deliveryAddress.houseNumber', '');
    } else {
      // if there's ONLY city name typed, all others need to be cleared
      form.setFieldValue('deliveryAddress.street', '');
      form.setFieldValue('deliveryAddress.houseNumber', '');
    }

    if (addressDetails && addressDetails.zipCode) {
      form.setFieldValue('deliveryAddress.zipCode', addressDetails.zipCode);
    } else {
      form.setFieldValue('deliveryAddress.zipCode', '');
    }

    if (addressDetails) {
      // set timeout is required because Formik (as of version 2.2.0) has troubles when setting field value
      // and it still thinks the field is empty without it
      setTimeout(() => {
        // city will always be returned, so no need to check it
        form.setFieldValue('deliveryAddress.city', addressDetails.city);
      }, 0);
      form.setFieldValue('autosuggestedAddress', addressDetails.description);

      // session id has to be changed after making req to google places
      setSessionId(uuid.v4());
    }
  }, [addressDetails]);

  return (
    <FormInputSelect
      className={'c-lead-autosuggested-address__select'}
      form={form}
      name={'autosuggestedAddress'}
      label={I18n.t(T.lead.boc._salessolution_.form.personalDetails.findAdress)}
      noResultsComponent={renderNoResultsComponent(
        form, debouncedSearchValue, addressAutosuggestionsQueryStatus, switchToManualAddress)}
      collection={addressAutosuggestions}
      mapper={key => key.description}
      onInputChange={query => {
        setSearchValue(query);
        form.setFieldValue('autosuggestedAddress', query);
        setUserVisibleAddress(query);
      }}
      controlledValue={userVisibleAddress}
      isSearchIcon={true}
      onSelect={selected => {
        // prepares data for address details when selected from list
        setSelectedAddress(selected.description);
        setSelectedAddressId(selected.id);
        setUserVisibleAddress(selected.description);
      }}
      isRequired={!shouldInputsAppear}
      suppressValuesInHotjar={true}
    />
  );
};

export const LeadAutosuggestedAddress =
  connect(mapStateToProps, mapDispatchToProps)(LeadAutosuggestedAddressComponent);
