import { SalesforceTokenStorage } from '+shared/store/auth/auth.state';
import { ActionsUnion } from '+utils/redux/actionsUnion.util';
import { createAction } from '+utils/redux/createAction.util';
import { OAuth2TokenResponse } from '@coolio/auth-interceptor';
import { ReverseChannelTokenAttributes } from './types/reverseChannel.interface';

export enum AUTH_ACTIONS {
  LOGIN = '[Auth] LOGIN',
  LOGIN_START = '[Auth] LOGIN_START',
  LOGOUT = '[Auth] LOGOUT',
  SET_TOKEN_STORAGE = '[Auth] SET_TOKEN_STORAGE',
  SET_SALESFORCE_TOKEN_STORAGE = '[Auth] SET_SALESFORCE_TOKEN_STORAGE',
  REFRESH_TOKEN = '[Auth] REFRESH_TOKEN',
  REVOKE_TOKENS = '[Auth] REVOKE_TOKENS',
  CLEAR_STORAGE = '[Auth] CLEAR_STORAGE',
  IS_REHYDRATED = '[Auth] IS_REHYDRATED',
  MIGRATE_AUTH = '[Auth] MIGRATE_AUTH',
  // GENERATE_REVERSE_CHANNEL_TOKEN = '[Auth] GENERATE_REVERSE_CHANNEL_TOKEN',
  SAVE_REVERSE_CHANNEL_TOKEN = '[Auth] SAVE_REVERSE_CHANNEL_TOKEN',
}

export const AuthActions = {
  login: createAction(AUTH_ACTIONS.LOGIN),

  loginStart: createAction(AUTH_ACTIONS.LOGIN_START, (verifier: string) => ({ verifier })),

  logout: createAction(AUTH_ACTIONS.LOGOUT),

  setTokenStorage: createAction(
    AUTH_ACTIONS.SET_TOKEN_STORAGE,
    (tokenStorage?: OAuth2TokenResponse) => ({
      tokenStorage,
    }),
  ),

  setSalesforceTokenStorage: createAction(AUTH_ACTIONS.SET_SALESFORCE_TOKEN_STORAGE,
    (salesforceTokenStorage?: SalesforceTokenStorage) => ({
      salesforceTokenStorage,
    }),
  ),

  revokeTokens: createAction(AUTH_ACTIONS.REVOKE_TOKENS),

  refreshToken: createAction(
    AUTH_ACTIONS.REFRESH_TOKEN,
    (
      accessToken: string | undefined,
      refreshToken: string | undefined,
      tokenType: string | undefined,
    ) =>
      ({ accessToken, refreshToken, tokenType }),
  ),

  clearStorage: createAction(AUTH_ACTIONS.CLEAR_STORAGE),

  isRehydrated: createAction(AUTH_ACTIONS.IS_REHYDRATED),

  migrateAuth: createAction(AUTH_ACTIONS.MIGRATE_AUTH),

  // generateReverseChannelToken:  createAction(
  //   AUTH_ACTIONS.GENERATE_REVERSE_CHANNEL_TOKEN,
  //   ({ batteryId, queryKey }: { batteryId: string, queryKey: string }) => ({ batteryId, queryKey }),
  // ),

  saveReverseChannelToken: createAction(
    AUTH_ACTIONS.SAVE_REVERSE_CHANNEL_TOKEN,
    (reverseChannelToken: ReverseChannelTokenAttributes) => 
      ({ reverseChannelToken })),
};

export type AuthActions = ActionsUnion<typeof AuthActions>;
