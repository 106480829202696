import * as classNames from 'classnames';
import * as React from 'react';
import { ContractEnergyInfo } from '../ContractEnergyInfo';
import { ContractEnergyMeter } from '../ContractEnergyMeter';
import { getUsedEnergyPercentage, quotaAlmostExceededPercentage } from './ContractEnergyMeterSection.helper';

import './ContractEnergyMeterSection.component.scss';

interface Props {
  maxEnergyAmount: number;
  total: number;
  isGrayOut?: boolean;
}

export const ContractEnergyMeterSection: React.FC<Props> = ({
  maxEnergyAmount,
  total,
  isGrayOut = false,
}) => {
  const percentage = getUsedEnergyPercentage(total, maxEnergyAmount);
  const isExceededPercentage = percentage >= quotaAlmostExceededPercentage;

  return (
    <div className={classNames('c-contract-energy-meter-section', {
      'c-contract-energy-meter-section--gray-out': isGrayOut,
    })}>
      <div className={'o-grid'}>
        <div className={classNames('o-grid__column',
          {'o-grid__column o-grid__column--sm-7': isExceededPercentage },
        )}>
          <ContractEnergyMeter
            percentage={percentage}
            maxEnergyAmount={maxEnergyAmount}
          />
        </div>
        {isExceededPercentage && (
          <div className={'o-grid__column o-grid__column--sm-5'}>
            <ContractEnergyInfo />
          </div>
        )}
      </div>
    </div>
  );
};
