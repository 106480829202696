import CustomerWithContract from '+assets/img/customerWithContract.svg';
import * as React from 'react';
import './LeadConfigurationPreviewInitial.component.scss';

export const LeadConfigurationPreviewInitial = () => (
  <div className={'c-lead-configuration-preview-initial'}>
    <div className={'c-lead-configuration-preview-initial__image-wrapper'}>
      <img
        src={CustomerWithContract}
        className={'c-lead-configuration-preview-initial__image'}
        alt={'Customer with contract'}
      />
    </div>
  </div>
);
