import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { T } from '@sonnen/shared-i18n/service';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { DsoCommissioningInterface } from '../types';

export const DsoCommissioningSchemaPvAndBattery = () => Yup.object().shape({
  dso_reference_number: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters)),
  pv_commissioning_date: Yup
    .string()
    .test(
      'invalidDateFormat',
      I18n.t(T.setupTool.validation.invalidDateFormat),
      value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
    ).test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
      value => !!value ? moment(value).isAfter('1899-12-31') : true,
    ).test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
      value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
    )
    .required(I18n.t(T.register.alert.requiredField)),
  battery_commissioning_date: Yup
    .string()
    .test(
      'invalidDateFormat',
      I18n.t(T.setupTool.validation.invalidDateFormat),
      value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
    ).test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
      value => !!value ? moment(value).isAfter('1899-12-31') : true,
    ).test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
      value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
    )
    .required(I18n.t(T.register.alert.requiredField)),
});

export const DsoCommissioningInitialPvAndBattery: DsoCommissioningInterface = {
  dso_reference_number: '',
  pv_commissioning_date: '',
  battery_commissioning_date: '',
};

export const DsoCommissioningSchemaBatteryOnly = () => Yup.object().shape({
  dso_reference_number: Yup
    .string()
    .trim()
    .max(50, I18n.t(T.register.alert.max50Characters)),
  battery_commissioning_date: Yup
    .string()
    .test(
      'invalidDateFormat',
      I18n.t(T.setupTool.validation.invalidDateFormat),
      value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
    ).test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
      value => !!value ? moment(value).isAfter('1899-12-31') : true,
    ).test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
      value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
    )
    .required(I18n.t(T.register.alert.requiredField)),
});

export const DsoCommissioningInitialBatteryOnly: DsoCommissioningInterface = {
  dso_reference_number: '',
  battery_commissioning_date: '',
};

export const getDsoCommissioningSchema = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoCommissioningSchemaPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoCommissioningSchemaBatteryOnly;
      break;
    default:
      return DsoCommissioningSchemaPvAndBattery;
  }
};

export const getDsoCommissioningInitial = (registrationSubjectType?: RegistrationSubjectType) => {
  if (!registrationSubjectType) { return DsoCommissioningInitialPvAndBattery; }
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoCommissioningInitialPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoCommissioningInitialBatteryOnly;
      break;
    default:
      return DsoCommissioningInitialPvAndBattery;
  }
};
export const saveableDsoCommissioningFields = [
  'dso_reference_number',
  'pv_commissioning_date',
  'battery_commissioning_date',
];
