import { Container } from '+shared/components';
import { restrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { StoreState } from '+shared/store/store.interface';
import { companyTabAllowedRoles } from '+shared/store/user';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Help } from '../../+faqAndContact/containers/Help';
import { Videos } from '../../+videos/containers/Videos';
import { ROUTES } from '../../../router';
import { HelpHeader } from '../../components';

export const HelpPage: React.FC = () => (
  <>
    <HelpHeader />
    <Container withVerticalPadding={false} withHorizontalPadding={false}>
      <Switch>
        <Route exact={true} path={ROUTES.HELP} component={Help} />
        <Route exact={true} path={ROUTES.VIDEOS} component={Videos} />
      </Switch>
    </Container>
  </>
);
