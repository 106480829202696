// @TODO refactor - NavSelect & NavUser
import { Dropdown } from '@sonnen/shared-web';
import { MediaQuery } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { NavLink } from '../NavLink';
import { NavSelectTrigger } from './NavSelectTrigger.component';

import './NavSelect.component.scss';

interface Props {
  navItems: React.ReactNode;
  triggerName: string;
  handleShow?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
}

const setDropdownContentClass = (isDesktop: boolean) =>
  classNames(
    'c-nav-select__dropdown-content',
      isDesktop
        ? 'c-nav-select__dropdown-content--desktop'
        : 'c-nav-select__dropdown-content--mobile',
  );

export const NavSelect: React.FC<Props> = ({ navItems, triggerName, handleShow }) => (
  <Media query={MediaQuery.UP_NAV}>
    {(isDesktop: boolean) => (
      <NavLink>
        <Dropdown
          className={'c-nav-select'}
          trigger={<NavSelectTrigger name={triggerName} />}
          isOpen={false}
          handleShow={handleShow}
        >
          <div className={setDropdownContentClass(isDesktop)}>
            <ul className={'c-nav-select__navigation'}> 
              {navItems}
            </ul>
          </div>
        </Dropdown>
      </NavLink>
    )}
  </Media>
);
