import { 
  CaseCountry,
  CaseOrigin,
  CasePriority,
  CaseRecordType,
  CaseStatus,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { MissingBatteryCaseFormValues } from './MissingBatteryCaseForm.interface';

interface Values extends MissingBatteryCaseFormValues {
  contactId: string;
}

export const generateCaseAttributes = (values: Values) => {
  const { serialNumber, customerLastName, postalCode, pvPeakPower, description, contactId } = values;

  return ({
    recordType: CaseRecordType.TECHNICAL_SUPPORT_CASE,
    subject: `Partner Portal | Partner needs battery assigned | look into knowledge article | ${serialNumber}`,
    description: `Serial number: ${serialNumber},
      Last name: ${customerLastName || '-'},
      ZIP code: ${postalCode || '-'},
      PV size: ${pvPeakPower || '-'},
      Description: ${description}`,
      type: CaseType.REQUEST,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      country: CaseCountry.DACH,
      contactId,
      origin: CaseOrigin.PARTNER_PORTAL,
  });
};
