import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { WarningFactory } from '../warnings/warnings.types';

export const offlineWarningFactory: WarningFactory<string> = (lastMeasurementAt) => {
  const date = formatDate(lastMeasurementAt, I18n.t(T.general.date.default));

  return {
    message: I18n.t(T.warning.batteryOffline, {date}),
  };
};
