import { EnergyUnit } from '+app/App.constants';
import { getDeliveryStatus } from '+customer/+contract/containers/CustomerContract/CustomerContract.helper';
import { DetailListType } from '+shared/components';
import { Contract, MeterInstallation } from '+shared/store/contract';
import { ContractType } from '+shared/store/contract/contract.helpers';
import { getContractWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { CustomerContractWidgetStatusTile } from '../../components/CustomerContractWidgetStatusTile';

enum Statuses {
  ACTIVATED = 'Activated',
  DELIVERED = 'Delivered',
}

export type FactorizeFlatContractDetails
  = (input: { contract: Contract; meterInstallation?: MeterInstallation }) => DetailListType[];

const setContractStatus = (contract: Contract): string | undefined => {
  const { contractType } = contract;
  if (contractType === ContractType.SONNEN_STROM || contractType === ContractType.SONNEN_ENERGY) {
    if (contract.status === Statuses.ACTIVATED) {
      const deliveryActive = I18n.t(T.customerSingle.overview[contractType].states.deliveryActive);
      return `${deliveryActive} ${formatDate(contract.activatedAt)}`;
    }
    if (contract.deliveryStartAt) {
      const deliveryStart = I18n.t(T.customerSingle.overview[contractType].states.deliveryStart);
      return `${deliveryStart} ${formatDate(contract.deliveryStartAt)}`;
    }
    return I18n.t(T.customerSingle.overview[contractType].states.deliveryUnknown);
  }
  return undefined;
};

export const factorizeFlatContractTypeDetailList: FactorizeFlatContractDetails = ({ contract, meterInstallation }) => {
  const warnings = getContractWarnings({ contract, meterInstallation });

  return [
    {
      label: I18n.t(T.customerSingle.overview.sonnenFlat.deliveryStart),
      value: contract.deliveryStartAt ? formatDate(contract.deliveryStartAt) : '-',
    },
    {
      label: I18n.t(T.customerSingle.overview.sonnenFlat.quotaIncl),
      value: !!contract.amountOfFreeEnergy && `${contract.amountOfFreeEnergy} ${EnergyUnit.KWH}` || '-',
    },
    {
      label: I18n.t(T.customerSingle.overview.sonnenFlat.contractNumber),
      value: contract.contractNumber,
    },
    {
      label: I18n.t(T.customerSingle.overview.sonnenFlat.state),
      value: contract.deliveryStartAt ? getDeliveryStatus(contract.deliveryStartAt) : '-',
    },
    {
      label: I18n.t(T.customerSingle.overview.contract.meter),
      value: warnings.meterInstallationWarningFactory && (
        <CustomerContractWidgetStatusTile
          message={warnings.meterInstallationWarningFactory.message}
          messageExtended={warnings.meterInstallationWarningFactory.messageExtended}
          type={warnings.meterInstallationWarningFactory.type || StatusType.DEFAULT}
        />
      ),
    },
    {
      label: I18n.t(T.customerSingle.overview.contract.fst),
      value: warnings.missingPvRemoteControl && (
        <CustomerContractWidgetStatusTile
          message={warnings.missingPvRemoteControl.message}
          messageExtended={warnings.missingPvRemoteControl.messageExtended}
          type={warnings.missingPvRemoteControl.type || StatusType.DEFAULT}
        />
      ),
    },
  ];
};

export const factorizeSonnenStromDetailList: FactorizeFlatContractDetails = ({ contract }) => [
  {
    label: I18n.t(T.customerSingle.overview.sonnenStrom.orderDate),
    value: !!contract.orderedAt && formatDate(contract.orderedAt),
  },
  {
    label: I18n.t(T.customerSingle.overview.sonnenStrom.contractNumber),
    value: !!contract.contractNumber && contract.contractNumber,
  },
  {
    label: I18n.t(T.customerSingle.overview.sonnenStrom.state),
    value: !!contract.status && setContractStatus(contract),
  },
];

export const factorizeSonnenEnergyDetailList: FactorizeFlatContractDetails = ({ contract }) => {
  const warnings = getContractWarnings({ contract });

  return [
    {
      label: I18n.t(T.customerSingle.overview.sonnenEnergy.orderDate),
      value: !!contract.orderedAt && formatDate(contract.orderedAt),
    },
    {
      label: I18n.t(T.customerSingle.overview.sonnenEnergy.contractNumber),
      value: !!contract.contractNumber && contract.contractNumber,
    },
    {
      label: I18n.t(T.customerSingle.overview.sonnenEnergy.state),
      value: !!contract.status && setContractStatus(contract),
    },
    {
      label: I18n.t(T.customerSingle.overview.contract.meter),
      value: warnings.meterInstallationWarningFactory && (
        // TODO check mariano italiano energy contract should it be visible or not? Imho is always empty
        <CustomerContractWidgetStatusTile
          message={warnings.meterInstallationWarningFactory.message}
          messageExtended={warnings.meterInstallationWarningFactory.messageExtended}
          type={warnings.meterInstallationWarningFactory.type || StatusType.DEFAULT}
        />
      ),
    },
  ];
};

export const getContractWidgetHeadingMap = (contract: Contract) => ({
  [ContractType.SONNEN_ENERGY]: I18n.t(T.customerSingle.overview.contractHeader.sonnenEnergy),
  [ContractType.SONNEN_STROM]: I18n.t(T.customerSingle.overview.contractHeader.sonnenStrom),
  [ContractType.SONNEN_FLAT]: I18n.t(T.customerSingle.overview.contractHeader.sonnenFlat),
  [ContractType.SONNEN_FLAT_X]: I18n.t(T.customerSingle.overview.contractHeader.sonnenFlatX),
}[contract.contractType]);
