import { VideoYoutube } from '+shared/store/youtube/types/youtube.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';
import { Button, ButtonSize } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { AddVideoForm, getYouTubeId, youtubeLink } from '../AddVideo/AddVideo.helper';

interface Props {
  youtubeVideo: VideoYoutube | undefined;
  form: FormikProps<AddVideoForm>;
  getYoutubeVideo: typeof YoutubeActions.getYoutubeVideoList;
}

export const AddVideoFormButtons: React.FC<Props> = ({
  youtubeVideo,
  form,
  getYoutubeVideo,
}) => {
  const verifyVideo = () => {
    if (form.values) {
      const id = getYouTubeId(form.values[youtubeLink]);
      if (id) {
        getYoutubeVideo(id);
      }
    }
  };

  return youtubeVideo
    ? (<Button
      label={'Submit'}
      size={ButtonSize.PILL}
      type={'submit'}
      isDisabled={
        !form.touched[youtubeLink]
        || !isEmpty(form.errors)
      }
    />)
  : (<Button
      label={'Verify video'}
      size={ButtonSize.PILL}
      onClick={verifyVideo}
      isDisabled={
        !form.values[youtubeLink]
        || !isEmpty(form.errors)
      }
    />);
};
