import { Config } from '+config';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { isObject } from 'lodash';
import { filterPrivateData } from './privacy.util';

const init = () => {
  if (Config.DEVELOPMENT_MODE) {
    return;
  }
  Sentry.init({
    dsn: Config.SENTRY_DSN,
    environment: Config.ENVIRONMENT,
    release: Config.VERSION,
  });
};

const log = (...args: [any, ...any[]]) => {
  Sentry.addBreadcrumb({
    level: Severity.Log,
    type: 'default',
    category: 'log',
    data: args.length <= 1
      ? args[0]
      : args.map(arg => isObject(arg) ? filterPrivateData(arg) : arg),
  });
};

const reportError = (err: Error) => {
  Sentry.captureException(err);
};

export const Reporter = {
  init,
  log,
  reportError,
};
