import { FormInputInfo } from '+shared/components/FormInputInfo';
import { formatNumber } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { factorizeConfigurationHint } from './LeadConfigurationHint.helper';

import './LeadConfigurationHint.component.scss';

interface Props {
  reference: 'pv' | 'battery';
  isPending: boolean;
  hintValue?: string | number;
}

interface RecommendationProps {
  isPvReference: boolean;
  hintValue?: string | number;
}

const Recommendation: React.FC<RecommendationProps> = ({ hintValue, isPvReference }) => {
  const hint = factorizeConfigurationHint(hintValue);
  const value = isPvReference ? hint.pv.hasValue : hint.battery.hasValue;
  const unitLabel = isPvReference ? T.units.pvPower : T.units.batteryCapacity;
  const precision = isPvReference ? 3 : 1;

  return (
    <FormInputInfo>
      <span className={'c-lead-configuration-pv-hint__text c-lead-configuration-pv-hint__text--dark'}>
          {I18n.t(T.lead.configuration._salessolution_.minimum)}{' '}
          {I18n.t(unitLabel, { value: formatNumber({ precision })(Number(hintValue)) })}{' '}
        </span>
        {value}
    </FormInputInfo>
  );
};

export const LeadConfigurationHint: React.FC<Props> = ({
  hintValue,
  reference,
  isPending,
}) => {
  const isVisible = isPending || hintValue;
  const isPvReference = reference === 'pv';

  return (
    <div className={'c-lead-configuration-pv-hint'}>
      {isVisible && (
        isPending ? (
          <>
            <Loader className={'c-lead-configuration-pv-hint__loader'} />
            <p className={'c-lead-configuration-pv-hint__text'}>
              {isPvReference ?
                factorizeConfigurationHint().pv.isPending
                : factorizeConfigurationHint().battery.isPending
              }
            </p>
          </>
        ) : (
            <Recommendation hintValue={hintValue} isPvReference={isPvReference} />
          )
      )}
    </div>
  );
};
