import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import {
  getAddressDetails,
  getLeadForm,
  getLeadSubmitQuery,
  getLeadSubmitQueryStatus,
} from '+app/+lead/store/+lead.selectors';
import { LEAD_SUBMIT_QUERY } from '+app/+lead/store/+lead.state';
import { LeadCreationFormSchema, leadFormInitial } from '+app/+lead/store/schemas';
import { LeadForm } from '+app/+lead/store/types';
import {
  FormCheckbox,
  FormErrorBanner,
} from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { EventCategory, FormName } from '+shared/GoogleTagManager';
import { LayoutActions } from '+shared/store/layout';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Card } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadNewForm } from '../LeadNewForm';
import { mapLeadErrorTitleToTranslationKey } from './LeadNew.helper';

import './LeadNew.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadForm: getLeadForm(state),
  leadSubmitQuery: getLeadSubmitQuery(state),
  leadSubmitQueryStatus: getLeadSubmitQueryStatus(state),
  addressDetails: getAddressDetails(state),
});

const mapDispatchToProps = mapActions({
  createLead: LeadPageActions.createLead,
  toggleModal: LayoutActions.toggleModal,
  clearQuery: QueryActions.init,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface Error {
  title: string;
}

const LeadNewComponent: React.FC<Props> = ({
  actions,
  leadSubmitQuery,
  leadSubmitQueryStatus,
}) => {
  const layout = React.useContext(LayoutContext);
  const [leadErrors, setLeadErrors] = React.useState<string[]>([]);

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.GRAY);

    return () => {
      layout.resetBackground();
      actions.clearQuery(LEAD_SUBMIT_QUERY);
    };
  }, []);

  const onSubmit = (values: LeadForm) => {
    actions.createLead(values);
  };

  React.useEffect(() => {
    if (leadSubmitQuery.error && leadSubmitQuery.error.response) {
      leadSubmitQuery.error.response.parsedBody().then(res => {
        const filteredErrors = res.errors
          .filter((error: Error) => !isEmpty(error.title))
          .map(({ title }: Error) => mapLeadErrorTitleToTranslationKey(title || ''))
          .filter(
            // only unique keys
            (v: string, i: number, a: string[]) => a.indexOf(v) === i,
          );
        setLeadErrors(filteredErrors);
      });
    }
  }, [leadSubmitQuery.error]);

  const gtmErrorProps = {
    category: EventCategory.LEAD_CREATION,
    formName: FormName.LEAD_CREATION,
    queryStatus: leadSubmitQueryStatus,
  };

  return (
    <Formik
      initialValues={leadFormInitial}
      validationSchema={LeadCreationFormSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      render={form => (
        <Form className={'c-lead-new'}>
          <div className={'c-form-wizard-step'}>
            <Card footerContent={(
              <div className={'c-lead-new__footer'}>
                <Button
                  type={'submit'}
                  label={I18n.t(T.lead._salessolution_.create)}
                  size={ButtonSize.SECONDARY}
                  isLoading={leadSubmitQueryStatus.pending}
                />
              </div>
            )}>
              <div className={'c-form-wizard-step__content'}>
                <LeadNewForm
                  form={form}
                />
                {leadErrors ?
                  leadErrors
                    .map(translationKey => (
                      <FormErrorBanner
                        key={translationKey}
                        isVisible={leadSubmitQueryStatus.error}
                        error={I18n.t(translationKey)}
                        gtm={gtmErrorProps}
                      />
                    ))
                  : <FormErrorBanner
                    isVisible={leadSubmitQueryStatus.error}
                    error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                    gtm={gtmErrorProps}
                  />
                }
              </div>

              <div className={'c-lead-new__checkboxes'}>
                <div className={'c-lead-new__ckeckbox'}>
                  <FormCheckbox
                    form={form}
                    label={I18n.t(T.lead._salessolution_.gdprConsent)}
                    name={'agreedToGdpr'}
                    isRequired={true}
                  />
                </div>

                <div className={'c-lead-new__checkboxes-separator'} />

                <div className={'c-lead-new__ckeckbox'}>
                  <FormCheckbox
                    form={form}
                    label={I18n.t(T.lead._salessolution_.marketingMailing)}
                    name={'agreedToMarketingMailing'}
                  />
                  <p className={'c-lead-new__optional-checkbox-info'}>({I18n.t(T.lead._salessolution_.optional)})</p>
                </div>

                <div className={'c-lead-new__submit-annotation'}>
                  <span className={'c-lead-new__submit-star'}>*</span>{' '}
                  {I18n.t(T.lead.boc._salessolution_.form.personalDetails.requiredFields)}
                </div>
              </div>
            </Card>
          </div>
        </Form>
      )}
    />
  );
};

export const LeadNew =
  connect(mapStateToProps, mapDispatchToProps)(LeadNewComponent);
