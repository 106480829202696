import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Button, Icon, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationTileAction } from '../LeadConfigurationTileAction';
import { LeadConfigurationTileStatus } from './LeadConfigurationTile.interface';

import './LeadConfigurationTile.component.scss';

export interface LeadConfigurationTileProps {
  title: string;
  isTileActionDisabled: boolean;
  isOfferCreationDisabled?: boolean;
  creationDate: string;
  offerButtonLabel?: string;
  productName: string;
  status?: LeadConfigurationTileStatus;
  isOfferInvalid?: boolean;
  isPending: boolean;
  hasDeletionWarning?: boolean;
  offerId?: string;
  onCompose?: () => void;
  onRemove?: () => void;
  onDownload?: () => void;
}

export const LeadConfigurationTile: React.FC<LeadConfigurationTileProps> = ({
  status = LeadConfigurationTileStatus.PREVIEW,
  title,
  productName,
  isPending,
  onRemove,
  onCompose,
  onDownload,
  isTileActionDisabled,
  isOfferCreationDisabled,
  creationDate,
  offerButtonLabel,
  isOfferInvalid = false,
  children,
  hasDeletionWarning = false,
  offerId,
}) => {
  const isOfferSentOrAccepted = () => [
    LeadConfigurationTileStatus.SENT,
    LeadConfigurationTileStatus.ACCEPTED,
  ].includes(status);

  const isOfferSent = status === LeadConfigurationTileStatus.SENT;
  const isOfferAccepted = status === LeadConfigurationTileStatus.ACCEPTED;
  const isOfferConfirmed = status === LeadConfigurationTileStatus.CONFIRMED;

  const isComposeButtonHidden = !onCompose
    || !offerButtonLabel
    || isOfferSentOrAccepted()
    || isOfferInvalid;

  return (
    <>
      <div
        id={'lead-configuration'}
        className={classNames('c-lead-configuration-tile', {
          'c-lead-configuration-tile--hoverable': !isTileActionDisabled && !isPending,
          'c-lead-configuration-tile--yellow': isOfferSent,
          'c-lead-configuration-tile--green': isOfferAccepted || isOfferConfirmed,
          'c-lead-configuration-tile--red': isOfferInvalid,
        })}
        data-testid={offerId}
      >
        <div className={'c-lead-configuration-tile__configuration-type'}>
          {productName}
          {isOfferInvalid ?
            <p className={'c-lead-configuration-tile__configuration-type-paragraph'}>
              {I18n.t(T.lead._salessolution_.configurations.product.unavailableDueToVpp)}
            </p>
            : null}
        </div>
        <div className={'c-lead-configuration-tile__header'}>
          <div className={'c-lead-configuration-tile__header-upper'}>
            <p className={'c-lead-configuration-tile__header-title'}>{title}</p>
            <div className={'c-lead-configuration-tile__header-actions'} >
              <LeadConfigurationTileAction
                action={onDownload}
                icon={<Icon.Download />}
              />
              {onRemove ?
                <LeadConfigurationTileAction
                  action={onRemove}
                  icon={<Icon.Trash />}
                  isDisabled={isTileActionDisabled}
                  hasConfirmationPopup={true}
                  hasWarning={hasDeletionWarning}
                />
                : null
              }
            </div>
          </div>
          <div className={'c-lead-configuration-tile__header-detail-container'}>
            <div className={'c-lead-configuration-tile__header-detail'}>
              {I18n.t(T.lead._salessolution_.configurations.created)}{' '}
              {formatDate(creationDate)}
            </div>
          </div>
        </div>
        {isPending && (
          <div className={'c-lead-configuration-tile__loader'}>
            <Loader />
          </div>
        )}
        {children}
        {!isComposeButtonHidden &&
          <div className={'c-lead-configuration-tile__offer-btn'}>
            <Button
              label={offerButtonLabel}
              isDisabled={isOfferCreationDisabled}
              onClick={onCompose}
            />
          </div>
        }
      </div>
    </>
  );
};
