import {
  PVRegisterFormInterface,
} from '+setupTool/+pvRegister/store/types';
import { Document } from '+shared/store/setupTool/types';
import { ActionsUnion, createAction } from '+utils/index';

export enum DSO_COMMISSIONING_ACTIONS {
  GENERATE_DOCUMENTS = '[+DsoCommissioning] GENERATE_DOCUMENTS',
  GENERATE_DOCUMENTS_SUCCESS = '[+DsoCommissioning] GENERATE_DOCUMENTS_SUCCESS',
  SET_GENERATED_DOCUMENTS = '[+DsoCommissioning] SET_GENERATED_DOCUMENTS',
  SET_FIELDS = '[+DsoCommissioning] SET_FIELDS',
  SAVE_SUBMISSION_AND_GENERATE_DOCUMENTS = '[+DsoCommissioning] SAVE_SUBMISSION_AND_GENERATE_DOCUMENTS',
  SET_MODIFICATION_DATE = '[+DsoCommissioning] SET_MODIFICATION_DATE',
  SET_DOCUMENTS_GENERATION_DATE = '[+DsoCommissioning] SET_DOCUMENTS_GENERATION_DATE',
}

export const DsoCommissioningActions = {
  saveSubmissionAndGenerateDocuments: createAction(
    DSO_COMMISSIONING_ACTIONS.SAVE_SUBMISSION_AND_GENERATE_DOCUMENTS,
  ),
  generateDocuments: createAction(
    DSO_COMMISSIONING_ACTIONS.GENERATE_DOCUMENTS,
  ),
  setDsoCommissioningGeneratedDocuments: createAction(
    DSO_COMMISSIONING_ACTIONS.SET_GENERATED_DOCUMENTS,
    (documents: Document[]) => ({ documents }),
  ),
  setFields: createAction(
    DSO_COMMISSIONING_ACTIONS.SET_FIELDS,
    (fields: PVRegisterFormInterface) => ({ fields }),
  ),
  setDsoCommissioningModificationDate: createAction(
    DSO_COMMISSIONING_ACTIONS.SET_MODIFICATION_DATE,
    (date: string) => ({ date }),
  ),
  setDsoCommissioningDocumentsGenerationDate: createAction(
    DSO_COMMISSIONING_ACTIONS.SET_DOCUMENTS_GENERATION_DATE,
    (date: string) => ({ date }),
  ),

};

export type DsoCommissioningActions = ActionsUnion<typeof DsoCommissioningActions>;
