import { Data, MergedData } from '@coolio/json-api';
import { Measurement } from '@sonnen/shared-web';

export enum MeasurementMethod {
  BATTERY = 'battery',
  METER_GRID = 'meter-grid',
  METER_GRID_PV = 'meter-grid-pv',
  METER_GRID_PV_FEED_IN = 'meter-grid-pv-feed-in',
  METER_ERROR = 'meter-error',
} 

export type MeasurementResolution =
  | '1m'
  | '3m'
  | '10m'
  ;

export interface SiteMeasurementsAttributes {
  measurementMethod: MeasurementMethod;
  start: string;
  end: string;
  resolution: MeasurementResolution;
  productionPower: Measurement;
  consumptionPower: Measurement;
  directUsagePower: Measurement;
  batteryCharging: Measurement;
  batteryDischarging: Measurement;
  gridFeedin: Measurement;
  gridPurchase: Measurement;
  batteryUsoc: Measurement;
  heaterPower: Measurement;
  heaterWaterTemp: Measurement;
}

export type SiteMeasurementsData = Data<SiteMeasurementsAttributes>;
export type SiteMeasurements = MergedData<SiteMeasurementsData>;
