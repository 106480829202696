import { parseInteger } from './number.util';

type Query = Record<string, string>;

export const createQueryString = <T extends object = Query>(params: T): string => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return queryString ? `?${queryString}` : '';
};

export const getQueryParams = <T extends object = Query>(queryString: string): T | {} => {
  const queryParams = {};

  if (!queryString.startsWith('?')) {
    return queryParams;
  }

  queryString.slice(1)
    .split('&')
    .map(param => param.split('='))
    .forEach(([key, value]) => {
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    });

  return queryParams;
};

export const computeOffset = (page: number | string | undefined) =>
  parseInteger(page, 1) - 1;
