import { setHintColor } from '+app/+newsChannel/containers/NewsSection/News.helper';
import {
  removeDuplicateSpaces,
  removeHtmlTags,
} from '+app/+newsMain/store/newsMain.helpers';
import { DeleteButton } from '+shared/components/DeleteButton';
import { GenericNews, ListItemNews, News } from '+shared/store/firebase/types/news.interface';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, LinkButton, NewsMessage, StatusDecorator, TableCell } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './NewsListTableItem.component.scss';

interface Props {
  item: ListItemNews<News>;
  deleteNews: (news: GenericNews) => void;
  openNewsEditMode: (news: GenericNews) => void;
}

export const NewsListTableItem: React.FC<Props> = ({ item, deleteNews, openNewsEditMode }) => {
  const messageWithoutHtmlTags = removeHtmlTags(item.introduction ? item.introduction : item.message);
  const messageWithoutDuplicateSpaces = removeDuplicateSpaces(messageWithoutHtmlTags);

  const isDateInTheFuture = (date: string) => moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'));

  return (
    <>
      {item.isActive && (
        <div className={'c-news-list-table-item__status-decorator'}>
          <StatusDecorator type={setHintColor(item.levelValue)} />
        </div>
      )}
      <div className={'c-news-list-table-item'}>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            {item.shortMessage}
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper c-news-list-table-item__text-wrapper--ellipsis'}>
            <NewsMessage message={messageWithoutDuplicateSpaces}/>
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__text-wrapper'}>
            {item.roles &&
            item.roles.map(role =>
              <React.Fragment key={role}>
                {I18n.t(T.news.channel.rolesNames[role])}
                <br/>
              </React.Fragment>,
            )
            }
          </div>
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {item.date && formatDate(moment(new Date(item.date.seconds * 1000)))}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {item.expiryDate ? (
            <><i className={classNames('c-news-list-table-item__dot', {
              'c-news-list-table-item__dot--active': isDateInTheFuture(item.expiryDate),
              'c-news-list-table-item__dot--inactive': !isDateInTheFuture(item.expiryDate),
            })
            }/>{item.expiryDate && (formatDate(moment(new Date(item.expiryDate))))}</>
          ) : (<><i className={'c-news-list-table-item__dot c-news-list-table-item__dot--active'}/>
              {I18n.t(T.news.channel.table.notApplicable)}</>)}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          {item.notificationExpiryDate ? (
            <><i className={classNames('c-news-list-table-item__dot', {
              'c-news-list-table-item__dot--active': isDateInTheFuture(item.notificationExpiryDate),
              'c-news-list-table-item__dot--inactive': !isDateInTheFuture(item.notificationExpiryDate),
            })
            }/>{item.notificationExpiryDate && formatDate(moment(new Date(item.notificationExpiryDate)))}</>
          ) : I18n.t(T.news.channel.table.notApplicable)}
        </TableCell>
        <TableCell className={'c-news-list-table-item__cell'}>
          <div className={'c-news-list-table-item__icon-wrapper'}>
            <LinkButton
              onClick={() => openNewsEditMode(item)}
              className={'c-news-list-table-item__icon-with-text'}
            >
              <Icon.Pen className={'c-news-list-table-item__icon'}/>
              {I18n.t(T.news.channel.edit)}
            </LinkButton>
          </div>
          <DeleteButton onDelete={() => deleteNews(item)}/>
        </TableCell>
      </div>
    </>
  );
};
