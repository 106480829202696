import { LeadCreationValidationErrorTitles } from '+lead/containers/LeadNew/LeadNew.helper';
import { ModalId } from '+shared/store/layout';
import { LeadAddress, LeadAddressKind } from '+shared/store/lead/types';
import { Query } from '+shared/store/query';
import { QueryStatusMap } from '+shared/store/query/query.utils';
import * as React from 'react';

const isInvalidAddressError = (error: any) => error.title === LeadCreationValidationErrorTitles.LEAD_INVALID_ADDRESS;

export const useInvalidAddressErrorModal = (
  query: Query,
  queryStatus: QueryStatusMap,
  toggleModal: (isModalOpen: boolean, modalId: ModalId) => void,
) => {
  React.useEffect(() => {
    if (
      queryStatus.error &&
      query.error &&
      query.error.response
    ) {
      // @TODO: handle errors here
      // eg. formRef.current.setFieldError('lastName', 'Error!');

      query.error.response.parsedBody().then(res => {
        if (res.errors.some(isInvalidAddressError)) {
          toggleModal(true, ModalId.LEAD_INVALID_ADDRESS);
        }
      });

    }
  }, [queryStatus]);
};

export const mapLeadWithReviewedAddress = <T extends { deliveryAddress: LeadAddress }>(lead: T) => {
  const updatedLead = {
    ...lead,
    deliveryAddress:
      { ...lead.deliveryAddress, kind: LeadAddressKind.REVIEWED },
  };

  return updatedLead;
};
