import { mapActions } from '+app/utils';
import { LeadInvalidAddressModal } from '+lead/components';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getLeadUpdateQuery, getLeadUpdateQueryStatus } from '+lead/store/+lead.selectors';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId } from '+shared/store/layout/layout.selectors';
import { LeadAddress } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { mapLeadWithReviewedAddress, useInvalidAddressErrorModal } from './LeadManualAddressModal.helper';

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
  query: getLeadUpdateQuery(state),
  queryStatus: getLeadUpdateQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  onSubmit: LeadPageActions.updateLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    formValues?: {
      deliveryAddress: LeadAddress,
    },
  }
  ;

const LeadUpdateManualAddressModalComponent: React.FC<Props> = ({
  query,
  queryStatus,
  actions,
  formValues,
  openModalId,
}) => {
  useInvalidAddressErrorModal(query, queryStatus, actions.toggleModal);

  const handleSubmit = () => {
    if (formValues) {
      actions.onSubmit(mapLeadWithReviewedAddress(formValues));
    }
  };

  return formValues ? (
    <LeadInvalidAddressModal
      isModalOpen={openModalId === ModalId.LEAD_INVALID_ADDRESS}
      isPending={false}
      toggleModal={actions.toggleModal}
      address={formValues.deliveryAddress}
      onSubmit={handleSubmit}
      submitLabel={I18n.t(T.lead._salessolution_.invalidAdressModal.update)}
    />
  ) : null;
};

export const LeadUpdateManualAddressModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadUpdateManualAddressModalComponent);
