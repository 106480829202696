import {
  NewsItem,
} from '+app/+newsMain/components/NewsItem/NewsItem.component';
import { sortList } from '+app/+newsMain/store/newsMain.helpers';
import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { NewsInboxListRouteQueryParams, PATHS } from '+app/router';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { mapActions } from '+app/utils';
import { PageBlock, Pagination } from '+shared/components';
import {
  getFirebaseNewsList,
  getFirebaseNewsListFetched,
  getFirebaseReadNewsList,
  getFirebaseReadNewsListFetched,
} from '+shared/store/firebase/firebase.selectors';
import { ReadNewsPP } from '+shared/store/firebase/types/news.interface';
import { getActiveNewsId, NewsMainActions } from '+shared/store/newsMain';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Loader, MediaQuery, ScrollableContainer } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';

import './NewsContainer.component.scss';

const mapStateToProps = (state: StoreState) => ({
  newsList: getFirebaseNewsList(state),
  activeNewsId: getActiveNewsId(state),
  isNewsListFetched: getFirebaseNewsListFetched(state),
  isReadNewsListFetched: getFirebaseReadNewsListFetched(state),
  readNewsList: getFirebaseReadNewsList(state),
});

const mapDispatchToProps = mapActions({
  setActiveNewsId: NewsMainActions.setActiveNewsId,
  goToNewsInbox: (urlParams: NewsInboxListRouteQueryParams) => push(PATHS.NEWS_INBOX(urlParams)),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    newsPlacement: NewsPlacementType;
    maxNumNews?: number;
    paginationPage?: number;
  };

const NewsContainerComponent: React.FC<Props> = ({
  actions,
  newsList,
  activeNewsId,
  isNewsListFetched,
  newsPlacement,
  maxNumNews = TOTAL_RESOURCE_COUNT_PER_PAGE,
  paginationPage = 1,
  isReadNewsListFetched,
  readNewsList,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const newsIdFromUrl = urlParams.get('newsId');
  const sortedNewsList = sortList(newsList);
  const latestNewsList = sortedNewsList.slice(
    (paginationPage - 1) * TOTAL_RESOURCE_COUNT_PER_PAGE,
    (paginationPage - 1) * TOTAL_RESOURCE_COUNT_PER_PAGE + maxNumNews,
  ) as any;

  const shouldShowPagination = newsPlacement === NewsPlacementType.NEWS_INBOX_SIDEBAR
    && latestNewsList.length < newsList.length;

  React.useEffect(() => {
    if (activeNewsId === '') {
      actions.setActiveNewsId(isEmpty(latestNewsList) ? '' : newsIdFromUrl || latestNewsList[0].id);
    }
  }, [latestNewsList]);

  const renderNewsListContainer = () => (
    <>
      <div className={'news-container__list'}>
        {latestNewsList.map((newsItem: any) => {
          return (
            <NewsItem
              key={uuid.v4()}
              item={newsItem}
              showPreviewOnly={true}
              newsPlacement={newsPlacement}
              isRead={readNewsList.map((readNews: ReadNewsPP) => readNews.newsId).includes(newsItem.id)}
            />
          );
        })}
      </div>
      {shouldShowPagination && <PageBlock className={`news-container__pagination`}>
        <Pagination
          page={paginationPage}
          recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
          recordsTotal={newsList.length}
          onPageChange={(page: any) => {
            urlParams.set('page', page);
            actions.setActiveNewsId('');
            actions.goToNewsInbox({
              newsId: undefined,
              newsTitle: undefined,
              page: urlParams.get('page') || undefined,
            });
          }}
        />
      </PageBlock>}
    </>
  );

  return (
    <>
      <p className={'news-container__label'}>{I18n.t(T.news.channel.label)}</p>
      <p className={'news-container__title'}>{I18n.t(T.news.channel.description)}</p>
      {isNewsListFetched && isReadNewsListFetched && newsList.length === 0 &&
        (<p className={'news-container__empty-message'}>{I18n.t(T.news.channel.emptyMessage)}</p>)
      }
      {!isNewsListFetched && !isReadNewsListFetched && <Loader className={'news-container__loader'} />}
      <Media query={MediaQuery.UP_NAV}>
        {(isDesktopMedia: boolean) =>
          isDesktopMedia ?
            (
              <ScrollableContainer
                height={'100%'}
                autoHeight={true}
                autoHeightMax={'calc(100vh - 320px)'}
              >
                {renderNewsListContainer()}
              </ScrollableContainer>
            )
            :
            (renderNewsListContainer())
        }
      </Media>
    </>
  );
};

export const NewsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsContainerComponent);
