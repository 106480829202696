import {
  isOfferBlocked,
} from '+app/+lead/+overview/containers/LeadOverviewConfigurations/LeadOverviewConfigurations.helper';
import { LeadFlatConfigurationTile } from '+app/+lead/components';
import { isConfigurationMultiplePv, isVppBonusGranted } from '+app/+lead/store/+lead.helper';
import { getLead, getLeadQueryStatus } from '+app/+lead/store/+lead.selectors';
import { NotFound } from '+app/+static/containers/NotFound/NotFound.component';
import { PATHS } from '+app/router';
import { Container, FormHeader } from '+shared/components';
import { LayoutBackground, LayoutContext } from '+shared/containers/Layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, Loader } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOfferScrollButton } from '../../components/LeadOfferScrollButton';
import { LeadOfferPageActions } from '../../store/+offer.actions';
import { isFlatOnlyOffer } from '../../store/+offer.helper';
import { getLeadOfferConfigurationData, getLeadOfferData } from '../../store/+offer.selectors';
import { OfferForm } from '../OfferForm';

import './LeadOfferComposer.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offer: getLeadOfferData(state),
  configuration: getLeadOfferConfigurationData(state),
  leadQueryStatus: getLeadQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToLead: (leadId: string, leadStage?: string) => push(PATHS.LEAD_OVERVIEW({ leadId }, leadStage)),
  clearData: LeadOfferPageActions.clearData,
  getEnergyProviders: LeadOfferPageActions.getEnergyProviders,
  getUtilityData: LeadOfferPageActions.getUtilityData,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const LeadOfferComposerComponent: React.FC<Props> = ({
  actions,
  lead,
  leadStage,
  offer,
  configuration,
  leadQueryStatus,
}) => {
  const layout = React.useContext(LayoutContext);

  React.useEffect(() => {
    if (lead) {
      actions.getUtilityData(lead.id);
    }
  }, [lead]);

  React.useEffect(() => {
    layout.setBackground(LayoutBackground.GRAY);
    actions.getEnergyProviders('');

    return () => {
      layout.resetBackground();
      actions.clearData();
    };
  }, []);

  return (
    <>
      <FormHeader
        onClick={() => lead && actions.goToLead(lead.id, leadStage)}
        breadcrumb={I18n.t(T.lead.offer._salessolution_.buttonBack)}
        title={lead
          ? I18n.t(T.lead.offer._salessolution_.headline, {
            firstName: lead!.firstName,
            lastName: lead!.lastName,
          })
          : ''
        }
        suppressTitleInHotjar={true}
        isLoading={leadQueryStatus.pending}
      />

      <Media query={{ minWidth: breakpointUp('SM') }}>
        {(isDesktopMedia: boolean) => (
          <Container
            withHorizontalPadding={isDesktopMedia}
            className={'c-lead-offer-composer'}
          >
            {offer && configuration ?
              isOfferBlocked(offer) ?
                <NotFound isFullScreen={false} />
                : (
                  <div className={'o-grid'}>
                    <div className={'o-grid__column o-grid__column--sm-12 o-grid__column--no-gap-sm'}>
                      <LeadOfferScrollButton
                        isVppBonus={isVppBonusGranted(configuration)}
                      />
                    </div>

                    <div className={'o-grid__column o-grid__column--md-8'}>
                      <OfferForm offer={offer} isMultiplePv={isConfigurationMultiplePv(configuration)} />
                    </div>

                    <div className={'o-grid__column o-grid__column--md-4 c-lead-offer-composer__sidebar'}>
                      <LeadFlatConfigurationTile
                        title={I18n.t(T.lead.offer._salessolution_.configurationName)}
                        configuration={configuration}
                        isTileActionDisabled={true}
                        isPending={false}
                        offer={offer}
                      />
                    </div>
                  </div>
                ) : (
                <Loader />
              )}
          </Container>
        )}
      </Media>
    </>
  );
};

export const LeadOfferComposer = connect(mapStateToProps, mapDispatchToProps)(LeadOfferComposerComponent);
