import { GET_LEAD_QUERY, LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { hasFlatOfferAcceptedStatus, isLeadSonnenNowInterested, isTsoAssigned } from '+app/+lead/store/+lead.helper';
import { NotFound } from '+app/+static';
import { LEAD_IN_SETUP_STAGE, LeadRouteParams, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store';
import { hasFlatOfferSentStatus, hasNotAcceptedOfferYet } from '+lead/store/+lead.helper';
import {
  getAsyncLeadStatusToBeSaved,
  getLead,
  getLeadQueryStatus,
  getLeadUpdateQuery,
  getLeadUpdateQueryStatus,
} from '+lead/store/+lead.selectors';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import { PreCommissioningInitial } from '+setupTool/+preCommissioning/store/schemas';
import { PreCommissioningStatus } from '+setupTool/+preCommissioning/store/types/preCommissioning.interface';
import { VppDocumentationActions } from '+setupTool/+vppDocumentation/store';
import { SetupTool } from '+setupTool/index';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { Container } from '+shared/components';
import { Tutorial } from '+shared/containers/Tutorial';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { LeadActions } from '+shared/store/lead';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompanyName, getUserProfileQueryStatus } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Alert, AlertTheme, Loadable, Loader } from '@sonnen/shared-web';
import { find, get, getOr, has, isEmpty, isNil } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Route, RouteComponentProps, Switch } from 'react-router';
import {
  hasFullDeliveryAddress,
  LeadOverviewActionFailedModal,
  LeadOverviewDocumentModal,
  SetupLeadOverviewHeader,
} from '../../components';
import { LeadOverviewEmailChangedModal } from '../../components/LeadOverviewEmailChangedModal';
import { retrieveIsSet } from '../../components/LeadOverviewHeader/LeadOverviewHeader.helper';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import {
  isDsoBlackListed,
  isDsoValidationPostponed,
  removeDsoValidationPostponed,
} from '../../store/+overview.helper';
import {
  getCloseLeadQueryStatus,
  getDocumentUrl,
  getLastUpdatedLeadSection,
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityStatus,
  getNewAddressErrorActivity,
  getOfferConfigurationList,
  getSendCGInvitationQueryStatus,
  isOpeningDocument,
} from '../../store/+overview.selectors';
import { LeadOverviewConfigurations } from '../LeadOverviewConfigurations';
import { LeadOverviewDetailsWidgets } from '../LeadOverviewDetailsWidgets/LeadOverviewDetailsWidgets.component';
import { LeadOverviewDsoValidationModal } from '../LeadOverviewDsoValidationModal';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadQueryStatus: getLeadQueryStatus(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  leadUpdateQuery: getLeadUpdateQuery(state),
  lastUpdatedLeadSection: getLastUpdatedLeadSection(state),
  isOpeningDocument: isOpeningDocument(state),
  documentUrl: getDocumentUrl(state),
  configurations: getOfferConfigurationList(state),
  isModalOpen: isModalOpen(state),
  userProfileQueryStatus: getUserProfileQueryStatus(state),
  userCompanyName: getUserCompanyName(state),
  sendCGInvitationQueryStatus: getSendCGInvitationQueryStatus(state),
  openModalId: getOpenModalId(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityQueryStatus: getLeadOverviewProductAvailabilityStatus(state),
  isNewAddressErrorActive: getNewAddressErrorActivity(state),
  closeLeadQueryStatus: getCloseLeadQueryStatus(state),
  asyncLeadStatusToBeSaved: getAsyncLeadStatusToBeSaved(state),
});

const mapDispatchToProps = mapActions({
  goBackToLeads: RouterActions.goBackToSetupLeads,
  getLead: LeadActions.getLead,
  clearLeadData: LeadPageActions.clearLeadData,
  updateLead: LeadPageActions.updateLead,
  clearOffers: LeadOverviewPageActions.clearOffers,
  clearQuery: QueryActions.init,
  documentOpened: LeadOverviewPageActions.documentOpened,
  markAsSeen: LeadPageActions.markLeadAsSeen,
  clearPartnerNotes: LeadOverviewPageActions.clearPartnerNotes,
  toggleModal: LayoutActions.toggleModal,
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  sendCGInvitation: LeadOverviewPageActions.sendCGInvitation,
  getProductAvailability: LeadOverviewPageActions.getProductAvailability,
  clearProductAvailability: LeadOverviewPageActions.clearProductAvailability,
  setNewAddressErrorActive: LeadOverviewPageActions.setNewAddressErrorActive,
  setAsyncFlatOfferSentStatus: LeadPageActions.setAsyncFlatOfferSentStatus,
  getSetupToolSubmissionData: SetupToolActions.getSetupToolSubmissionData,
  getPreCommissioningData: PreCommissioningActions.getPreCommissioningData,
  clearSetupToolsForm: DsoRegistrationFormActions.clearData,
  clearSetupToolsConfigurationData: SetupToolActions.clearConfigurationData,
  setSubmissionId: SetupToolActions.setSubmissionId,
  clearVppDocumentationData: VppDocumentationActions.setInitialState,
  setPreCommissioningData: PreCommissioningActions.setPreCommissioningData,
  setPreCommissioningStatus: PreCommissioningActions.setPreCommissioningStatus,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<LeadRouteParams>
  ;

const SetupLeadOverviewComponent: React.FC<Props> = ({
  actions,
  lead,
  leadQueryStatus,
  match,
  leadUpdateQueryStatus,
  lastUpdatedLeadSection,
  isOpeningDocument,
  documentUrl,
  configurations,
  isModalOpen,
  userProfileQueryStatus,
  userCompanyName,
  sendCGInvitationQueryStatus,
  openModalId,
  productAvailability,
  productAvailabilityQueryStatus,
  isNewAddressErrorActive,
  closeLeadQueryStatus,
  asyncLeadStatusToBeSaved,
}) => {
  const firstLeadUpdate = React.useRef(true);
  const [sendingLinkModalActive, setSendingLinkModalActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    actions.clearProductAvailability();
    actions.getLead(match.params.leadId, GET_LEAD_QUERY);
    if (window.location.pathname.includes('setup-tool')) {
      actions.getPreCommissioningData(match.params.leadId);
      actions.getSetupToolSubmissionData(match.params.leadId);
    }

    return () => {
      actions.clearQuery(LEAD_UPDATE_QUERY);
      actions.clearOffers();
      actions.clearPartnerNotes();
      actions.clearLeadData();
      actions.setNewAddressErrorActive(false);

      // Clear Setup Tools data
      actions.clearSetupToolsForm();
      actions.clearSetupToolsConfigurationData(),
        actions.setSubmissionId(undefined);
      actions.clearVppDocumentationData();
      actions.setPreCommissioningData(PreCommissioningInitial);
      actions.setPreCommissioningStatus(PreCommissioningStatus.NOT_GENERATED);
    };
  }, []);

  React.useEffect(() => {
    if (lead && lead.isNewlyAssigned) {
      actions.markAsSeen();
    }
  }, [lead && lead.isNewlyAssigned]);

  React.useEffect(() => {
    if (sendCGInvitationQueryStatus.error) {
      setSendingLinkModalActive(true);
    }
  }, [sendCGInvitationQueryStatus]);

  React.useEffect(() => {
    if (lead && lead.deliveryAddress &&
      hasFullDeliveryAddress({ deliveryAddress: lead.deliveryAddress }) &&
      !hasFlatOfferAcceptedStatus(lead)
    ) {
      actions.getProductAvailability();
    }
  }, [leadQueryStatus]);

  React.useEffect(() => {
    if (leadQueryStatus.success && asyncLeadStatusToBeSaved) {
      actions.setAsyncFlatOfferSentStatus();
    }
  }, [leadQueryStatus]);

  React.useEffect(() => {
    // multiple german TSO won't be chosen from and therefore won't be saved into SF
    // so in order to stop the infinite loop of updating lead we should only update it once
    if (!firstLeadUpdate.current) {
      return;
    }

    // @NOTE: automatic dso&tso assign, when just one is available
    // if there is multiple tso, just dso may be updated
    // if multiple dso -> we can't patch tso only
    if (
      lead && (!lead.dso || !lead.tso || isEmpty(lead.tso.name)) &&
      lead.deliveryAddress &&
      !hasFlatOfferAcceptedStatus(lead) &&
      (productAvailability.length === 1)
    ) {
      const tso = productAvailability[0].tsos.length === 1 ? productAvailability[0].tsos[0] : null;
      const deliveryAddress = lead.deliveryAddress;

      const isTheSameData = (productAvailability[0].id === get('dso.id', lead) && !tso && !isTsoAssigned(lead.tso));
      if (isTheSameData) {
        return;
      }
      actions.updateLead({
        dso: { id: productAvailability[0].id },
        tso,
        deliveryAddress,
      });
      firstLeadUpdate.current = false;
    }
  }, [productAvailability]);

  React.useEffect(() => {
    if (lead && has('dso', lastUpdatedLeadSection)) {
      removeDsoValidationPostponed(lead.id);
      actions.getProductAvailability();
    }
  }, [lastUpdatedLeadSection]);

  const hasConfigurations = () => configurations.length > 0;

  const isNewOfferSentAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && hasFlatOfferSentStatus(lead)
    && hasNotAcceptedOfferYet(lead) && (
      has('firstName', lastUpdatedLeadSection)
      || has('phone', lastUpdatedLeadSection)
      || has('invoiceAddress', lastUpdatedLeadSection));

  const isSalesConfiguratorRecalculatedAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && !hasFlatOfferSentStatus(lead)
    && has('deliveryAddress', lastUpdatedLeadSection);

  const isSalesConfiguratorRecalculatedOfferSentAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && hasFlatOfferSentStatus(lead)
    && has('deliveryAddress', lastUpdatedLeadSection);

  const alertMsgs = [
    {
      condition: isNewOfferSentAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.newOfferSent),
      theme: AlertTheme.INFO,
    },
    {
      condition: isSalesConfiguratorRecalculatedAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.salesConfiguratorRecalculated),
      theme: AlertTheme.INFO,
    },
    {
      condition: isSalesConfiguratorRecalculatedOfferSentAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.salesConfiguratorRecalculatedOfferSent),
      theme: AlertTheme.INFO,
    },
    {
      condition: isNewAddressErrorActive,
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.newAddressError),
      theme: AlertTheme.ERROR,

    },
  ];

  const isAlertOpen = () => isNewOfferSentAlert()
    || isSalesConfiguratorRecalculatedAlert()
    || isSalesConfiguratorRecalculatedOfferSentAlert()
    || isNewAddressErrorActive;

  const changeLeadStatus = (leadId: string) =>
    (...statusNames: LeadStatusName[]) =>
      actions.changeLeadStatus(leadId, statusNames);

  const shouldRenderDsoValidationModal = () => !!lead
    && productAvailability.length > 1
    && !lead.dso
    && !hasFlatOfferAcceptedStatus(lead)
    && !isDsoValidationPostponed(lead.id)
    || openModalId === ModalId.DSO_VALIDATION;

  const shouldRenderSalesConfigurationWarning =
    !hasFlatOfferAcceptedStatus(lead) && lead && isNil(lead.dso) ||
    lead && isDsoBlackListed(productAvailability, lead.dso);

  return (
    <Loadable predicate={leadQueryStatus.pending || userProfileQueryStatus.pending}>
      {leadQueryStatus.error || !lead ||
        (!retrieveIsSet(lead && lead.status.summary[LeadStatusName.IN_SETUP])) ? (
          <NotFound />
        ) : (
          <div className={'c-lead-overview'}>
            <Alert
              message={getOr('', 'msg', find((alertMsg) => alertMsg.condition, alertMsgs))}
              isOpen={isAlertOpen()}
              theme={get('theme', find((alertMsg) => alertMsg.condition, alertMsgs))}
            />
            <Alert
              message={I18n.t(T.lead.overview._salessolution_.invitationAlert)}
              isOpen={sendCGInvitationQueryStatus.success}
            />
            <SetupLeadOverviewHeader
              lead={lead}
              leadStage={LEAD_IN_SETUP_STAGE}
              breadcrumbAction={actions.goBackToLeads}
              isModalOpen={isModalOpen}
              toggleModal={actions.toggleModal}
              changeLeadStatus={changeLeadStatus(lead.id)}
              userCompanyName={userCompanyName}
              sendCGInvitation={actions.sendCGInvitation}
              sendCGInvitationQueryStatus={sendCGInvitationQueryStatus}
              salesConfigurationWarning={shouldRenderSalesConfigurationWarning}
            />
            <Switch>
              <Route path={ROUTES.SETUP_LEAD_SETUP_TOOL} render={() => (
                <SetupTool leadStage={LEAD_IN_SETUP_STAGE} />
              )} />
              <Route path={ROUTES.SETUP_LEAD_CONFIGURATION} render={() => (
                <Container withVerticalPadding={true}>
                  <div className={'o-grid'}>
                    <section className={'o-grid__column o-grid__column--md-12'}>
                      <LeadOverviewConfigurations leadStage={LEAD_IN_SETUP_STAGE} />
                    </section>
                  </div>
                </Container>
              )} />
              <Route path={match.path} render={() => (
                <Container withVerticalPadding={true}>
                  {(closeLeadQueryStatus.pending
                    || leadUpdateQueryStatus.pending
                    || productAvailabilityQueryStatus.pending) && (
                      <Loader className={'c-lead-overview__loader'} />
                    )}
                  <Tutorial id={'leadDetails'} />
                  <LeadOverviewDetailsWidgets lead={lead} />
                </Container>
              )} />
            </Switch>
            {shouldRenderDsoValidationModal() &&
              <LeadOverviewDsoValidationModal lead={lead} />
            }
            <LeadOverviewDocumentModal
              isOpeningDocument={isOpeningDocument}
              documentUrl={documentUrl}
              onDocumentOpen={() => actions.documentOpened()}
            />
            <LeadOverviewEmailChangedModal
              isModalOpen={openModalId === ModalId.EMAIL_CHANGED}
              handleClose={() => actions.toggleModal(false)}
              handleConfirm={() => { actions.sendCGInvitation(lead.id); actions.toggleModal(false); }}
              email={lead.email}
            />
            <LeadOverviewActionFailedModal
              isFailingDocument={sendingLinkModalActive}
              handleClick={() => {
                setSendingLinkModalActive(false);
                actions.sendCGInvitation(lead.id);
              }}
              handleClose={() => setSendingLinkModalActive(false)}
              headline={I18n.t(T.lead.overview._salessolution_.linkErrorModal.heading)}
              description={I18n.t(T.lead.overview._salessolution_.linkErrorModal.content)}
            />
          </div>
        )}
    </Loadable>
  );
};

export const SetupLeadOverview = connect(mapStateToProps, mapDispatchToProps)(SetupLeadOverviewComponent);
