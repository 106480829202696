import { StatusName, SubmissionStepCamelCase } from '+setupTool/store/+setupTool.dictionary';
import { GET_SETUP_TOOL_SUBMISSION_DATA_QUERY } from '+setupTool/store/+setupTool.dictionary';
import { LeadProductType } from '+shared/store/lead/types';
import { Query } from '+shared/store/query';

export interface SetupToolStep {
  index: string;
  uniqueIndexForID?: string;
  name: string;
  disabled: boolean;
  route: string;
  appendix?: () => React.ReactNode;
  date?: string;
  buttonLabel?:  string;
  buttonDisabled?: boolean;
  buttonVisible?: boolean;
  status?: StatusName;
  gtmCategory: string;
  gtmAction: string;
}

export enum RegistrationSubjectType {
  PV_AND_BATTERY = 'pv-and-battery',
  BATTERY = 'battery',
}

export interface SetupToolConfigurationState {
  submissionId?: string;
  isVppCapable?: boolean | null;
  registrationSubject?: RegistrationSubjectType;
  errors: string[];
  [GET_SETUP_TOOL_SUBMISSION_DATA_QUERY]: Query;
}

export const configurationInitialState: SetupToolConfigurationState = {
  submissionId: undefined,
  isVppCapable: false,
  registrationSubject: undefined,
  errors: [],
  [GET_SETUP_TOOL_SUBMISSION_DATA_QUERY]: {},
};

export interface SetupToolStepsUpdatedAt {
  [SubmissionStepCamelCase.SETUP_DATA]?: string;
  [SubmissionStepCamelCase.DSO_REGISTRATION]?: string;
  [SubmissionStepCamelCase.DSO_COMMISSIONING_DATA]?: string;
  [SubmissionStepCamelCase.DSO_COMMISSIONING_DOCUMENTS]?: string;
  [SubmissionStepCamelCase.PV_REGISTER]?: string;
  [SubmissionStepCamelCase.VPP_DOCUMENTATION]?: string;
  [SubmissionStepCamelCase.VPP_PERFORMANCE]?: string;
}
