import { FormInputSelect, FormSectionParagraph } from '+shared/components';
import { EventCategory, RequestEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { filterByValue, mapActions, searchByKey } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  getGetPvInvertersQueryStatus,
  getGetPvInverterVendorsQueryStatus,
  getPVInverters,
  getPvInverterVendorsAllFetched,
} from '../../store/+form.selectors';

interface OwnProps<T> {
  vendorFieldName: string;
  typeFieldName: string;
  form: FormikProps<T>;
  isNew?: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  pvInverters: getPVInverters(state),
  pvInverterVendorsAllFetched: getPvInverterVendorsAllFetched(state),
  getPvInverterVendorsQueryStatus: getGetPvInverterVendorsQueryStatus(state),
  getPvInvertersQueryStatus: getGetPvInvertersQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getPVInverters: DsoRegistrationFormActions.getPVInverters,
  setPvInverterVendorsAllFetched: DsoRegistrationFormActions.setPvInverterVendorsAllFetched,
});

type Props<T> =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps<T>
  ;

type DsoRegistrationPvInverterComponentI<T = any> = React.FC<Props<T>>;

export const DsoRegistrationPvInverterComponent: DsoRegistrationPvInverterComponentI = ({
  form,
  pvInverters,
  pvInverterVendorsAllFetched,
  getPvInverterVendorsQueryStatus,
  getPvInvertersQueryStatus,
  actions,
  vendorFieldName,
  typeFieldName,
}) => {
  const [currentVendorId, setCurrentVendorId] = React.useState(form.values[vendorFieldName] || '');
  const currentVendor = pvInverters.find(vendor => vendor.id === currentVendorId);
  const { track } = useTracking();

  React.useEffect(() => {
    if (form.values[vendorFieldName]) {
      setCurrentVendorId(form.values[vendorFieldName]);
    }
    if (currentVendorId && form.values[vendorFieldName] !== currentVendorId) {
      form.setFieldValue(typeFieldName, '');
      setCurrentVendorId('');
    }
  }, [form.values[vendorFieldName]]);

  React.useEffect(() => {
    if (
      pvInverterVendorsAllFetched
      && currentVendorId
      && !!pvInverters.length
      && currentVendor && !currentVendor.inverters
    ) {
      actions.getPVInverters(currentVendorId);
    }
  }, [currentVendorId, pvInverterVendorsAllFetched]);

  return (<>
    <FormSectionParagraph>{I18n.t(T.setupTool.form.inverter)}</FormSectionParagraph>
    <FormInputSelect
      className={classNames('c-setup-tool-pv-system__item', {
        'c-form-input-select--error': getPvInverterVendorsQueryStatus.error,
      })}
      form={form}
      label={I18n.t(T.setupTool.form.manufacturer)}
      id={'setup-tool-pv-system__' + vendorFieldName}
      name={vendorFieldName}
      placeholder={I18n.t(T.setupTool.selectPlaceholder)}
      collection={pvInverters.map(v => v.id)}
      mapper={key => searchByKey('id', key, pvInverters, 'name')}
      search={val =>
        filterByValue(pvInverters, 'name')(val).map(item => item.id)
      }
      noResultsComponent={!!getPvInverterVendorsQueryStatus.pending ? <Loader /> : null}
      {...(getPvInverterVendorsQueryStatus.error ?
        {additionalInfoText: <Translate value={T.setupTool.info.noResults.inverters} dangerousHTML={true}/>}
        : {})}
    />

    <FormInputSelect
      className={classNames('c-setup-tool-pv-system__item', {
        'c-form-input-select--error': getPvInvertersQueryStatus.error,
      })}
      form={form}
      label={I18n.t(T.setupTool.form.type)}
      id={'setup-tool-pv-system__' + typeFieldName}
      name={typeFieldName}
      placeholder={I18n.t(T.setupTool.selectPlaceholder)}
      collection={currentVendor && currentVendor.inverters ? currentVendor.inverters!.map(m => m.id) : []}
      mapper={key => currentVendor && currentVendor.inverters ?
        searchByKey(
          'id',
          key,
          currentVendor.inverters,
          'name',
        ) : null
      }
      search={val => currentVendor && currentVendor.inverters
        ? filterByValue(currentVendor.inverters, 'name')(val).map(item => item.id)
        : []
      }
      disableSearchForEmptyCollection={true}
      noResultsComponent={!!getPvInvertersQueryStatus.pending ? <Loader /> : null}
      {...(getPvInvertersQueryStatus.error ?
        {additionalInfoText: <Translate value={T.setupTool.info.noResults.inverters} dangerousHTML={true}/>}
        : {})}
    />
  </>);
};

export const DsoRegistrationPvInverter = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationPvInverterComponent);
