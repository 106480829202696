import { ListTable, ListTableHeader } from '+shared/components';
import { GenericNews, ListItemNews, Portal } from '+shared/store/firebase/types/news.interface';
import { isSPNews } from '+utils/news.util';
import * as classNames from 'classnames';
import * as React from 'react';
import { CPNewsListTableItem } from '../NewsListTableItem/CPNewsListTableItem.component';
import { NewsListTableItem } from '../NewsListTableItem/NewsListTableItem.component';
import { getTableHeaders } from './NewsListTable.helper';

import './NewsListTable.component.scss';

interface Props {
  items: ListItemNews[];
  isPending: boolean;
  openNewsEditMode: (news: GenericNews) => void;
  deleteNews: (news: GenericNews) => void;
  newsPortal: Portal;
}

export const NewsListTable: React.FC<Props> = ({
  items,
  isPending,
  openNewsEditMode,
  deleteNews,
  newsPortal,
}) => (
  <ListTable
    className={classNames('c-list-table--news-list', {
      'c-list-table--news-list--cp' : newsPortal === Portal.CP,
      'c-list-table--news-list--sp' : newsPortal === Portal.SP,
    })
    }
    headers={getTableHeaders(newsPortal)}
    items={items}
    isPending={isPending}
    hoverable={true}
    renderHeader={header => (
      <ListTableHeader
        className={'c-list-table__header--news-list'}
        item={header}
        newsPortal={newsPortal}
      />
    )}
    renderItem={item => isSPNews(item) ? (
      <NewsListTableItem
        item={item}
        openNewsEditMode={openNewsEditMode}
        deleteNews={deleteNews}
      />
    ) : (
      <CPNewsListTableItem
        item={item}
        openNewsEditMode={openNewsEditMode}
        deleteNews={deleteNews}
      />
    )}
  />
);
