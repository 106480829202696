import { SetupToolStepsListItem } from '+setupTool/components';
import { SetupToolStep } from '+setupTool/store/types';
import * as React from 'react';

interface Props {
  items: SetupToolStep[];
}

export const SetupToolStepsList: React.FC<Props> = ({
  items,
}) => (
  <>
  {items
    ? items.map(item => {
      return <SetupToolStepsListItem key={item.route} item={item} />;
    })
    : null}
  </>
);
