import { getStatus } from '+shared/store/query/query.utils';
import { MeasurementMethod } from '+shared/store/site';
import { StoreState } from '+shared/store/store.interface';
import { Point } from '@kanva/core';
import { EnergyFlowSeriesKey, TimeHelper } from '@sonnen/shared-web';
import { createSelector } from 'reselect';
import { GET_SITE_MEASUREMENTS_QUERY } from './+analysis.state';
import { getMaxForecastsDate, hasMeasurements, isMKMeterUser } from './helpers/+analysis.helpers';

export const getAnalysisState = (store: StoreState) => store.page.customer.analysis;

const getAnalysisDayChart = createSelector(
  getAnalysisState,
  state => state.dayChart,
);

export const getSelectedDate = createSelector(
  getAnalysisDayChart,
  state => TimeHelper.getDateFromUnixDate(state.selectedDate),
);

export const getSelectedDataSeriesKeys = createSelector(
  getAnalysisDayChart,
  state => state.selectedDataSeriesKeys,
);

export const getDataSeries = createSelector(
  getAnalysisDayChart,
  state => state.dataSeries,
);

export const getForecastProductionSeries = createSelector(
  getDataSeries,
  (series) => series[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
);

export const getForecastConsumptionSeries = createSelector(
  getDataSeries,
  (series) => series[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
);

export const getSiteMeasurements = createSelector(
  getAnalysisDayChart,
  state => state.siteMeasurements,
);

export const hasSiteMeasurements = createSelector(
  getSiteMeasurements,
  measurements => hasMeasurements(measurements),
);

export const getSiteMeasurementsMethod = createSelector(
  getSiteMeasurements,
  measurements => measurements && measurements.measurementMethod,
);

export const isMK1 = createSelector(
  getSiteMeasurementsMethod,
  (method) => method === MeasurementMethod.METER_GRID,
);

export const isUnsupportedBattery = createSelector(
  getAnalysisDayChart,
  state => state.isUnsupportedBattery,
);

export const getSiteMeasurementsQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_SITE_MEASUREMENTS_QUERY]),
);

export const areDataSeriesEmpty = createSelector(
  getDataSeries,
  dataSeries => !(
    dataSeries.productionPower.length
      && dataSeries.consumptionPower.length
      && dataSeries.directUsagePower.length
      && dataSeries.batteryUsoc.length
  ),
);

export const getAreaChartProduction = createSelector(
  getAnalysisDayChart,
  state => state.dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER] as Point[],
);

export const getAreaChartConsumption = createSelector(
  getAnalysisDayChart,
  state => state.dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER] as Point[],
);

export const getForecastConsumptionFull = createSelector(
  getAnalysisDayChart,
  state => state.forecastConsumptionFull,
);

export const getForecastProductionFull = createSelector(
  getAnalysisDayChart,
  state => state.forecastProductionFull,
);

export const hasForecast = createSelector(
  getForecastProductionSeries,
  getForecastConsumptionSeries,
  (consumption, production): boolean => Boolean(consumption && consumption.length || production && production.length),
);

export const getDatepickerMaxDate = createSelector(
  getForecastProductionFull,
  getForecastConsumptionFull,
  (production, consumption): Date => getMaxForecastsDate(production, consumption),
);

const getStatistics = createSelector(
  getAnalysisState,
  state => state.statistics,
);

export const getStatisticsSelectedDate = createSelector(
  getStatistics,
  statistics => statistics.selectedDate,
);

export const shouldStatisticsAppear = createSelector(
  getAnalysisState,
  state => state.dayChart.siteMeasurements
    && !isMKMeterUser(state.dayChart.siteMeasurements.measurementMethod),
);

export const getPieChartSeries = createSelector(
  getStatistics,
  statistics => statistics.pieChartSeries,
);

export const getBarChartSeries = createSelector(
  getStatistics,
  statistics => statistics.barChartSeries,
);
