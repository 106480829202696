import { getFlatProduct, getHardwareProduct, isAnyOfferFlatOnly } from '+app/+lead/+offer/store/+offer.helper';
import { LeadEditCommonStatus } from '+app/+lead/store/types';
import { validateForm } from '+shared/components/Form/Form.helpers';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { Lead, LeadOffer, OfferProductStatus } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { insertIf } from '+utils/array.util';
import { isStatusSet } from '+utils/status.util';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import { isEmpty, isNil } from 'lodash';
import { difference, filter, flow, isUndefined, map, sortBy, uniqBy } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export const formFields = {
  CONTACTED: 'contacted',
  ON_SITE_VISIT_ARRANGED: 'onSiteVisitArranged',
  ON_SITE_VISIT_DONE: 'onSiteVisitDone',
  IN_SETUP: 'inSetup',
  HARDWARE_SENT: 'hardwareSent',
  HARDWARE_CONFIRMED: 'hardwareConfirmed',
  HARDWARE_ALREADY_SOLD: 'hardwareAlreadySold', // this one is for flat only
} as const;

type LeadEditStatusFormFieldsMap = typeof formFields;
type LeadEditStatusFormFields = LeadEditStatusFormFieldsMap[keyof LeadEditStatusFormFieldsMap];

export interface LeadEditStatusForm {
  contacted: boolean;
  onSiteVisitArranged: boolean;
  onSiteVisitDone: boolean;
  inSetup: boolean;
  hardwareSent: string[];
  hardwareConfirmed: string[];
  hardwareAlreadySold: boolean;
}

export const leadEditStatusSchema = () =>
  Yup.object().shape({
    hardwareConfirmed: Yup
      .array()
      .max(1, I18n.t(T.lead.list._salessolution_.editStatusModal.errors.multipleHwConfirmStatuses))
      .when(formFields.HARDWARE_ALREADY_SOLD, {
        is: true,
        then: Yup
          .array()
          .max(0, I18n.t(T.lead.list._salessolution_.editStatusModal.errors.multipleHwConfirmStatuses)),
      }),
    hardwareAlreadySold: Yup
      .boolean()
      .when(formFields.HARDWARE_CONFIRMED, {
        is: hardwareConfirmed => !isEmpty(hardwareConfirmed),
        then: Yup
          .boolean()
          .oneOf([false], I18n.t(T.lead.list._salessolution_.editStatusModal.errors.multipleHwConfirmStatuses)),
      }),
  }, [[formFields.HARDWARE_CONFIRMED, formFields.HARDWARE_ALREADY_SOLD]]);

export const getIconByStatus = (status: unknown | null) => status === null
  ? <Icon.StatusAutomatic className={'c-lead-edit_status-modal__automatic-status-icon'} />
  : <Icon.Checkmark
    className={
      'c-lead-edit_status-modal__automatic-status-icon c-lead-edit_status-modal__automatic-status-icon--active'
    }
  />;

export const mapUserStatusToInitialValues = (lead: Lead, offers: LeadOffer[]): LeadEditStatusForm => {
  const { status: { summary } } = lead;

  return ({
    contacted: isStatusSet(summary.contacted),
    onSiteVisitArranged: isStatusSet(summary.onSiteVisitArranged),
    onSiteVisitDone: isStatusSet(summary.onSiteVisitDone),
    inSetup: isStatusSet(summary.inSetup),
    hardwareSent: mapOfferHardwareProductsToFormFieldsByStatus(
      OfferProductStatus.SENT,
      OfferProductStatus.ACCEPTED,
      OfferProductStatus.CONFIRMED,
    )(offers),
    hardwareConfirmed: mapOfferHardwareProductsToFormFieldsByStatus(OfferProductStatus.CONFIRMED)(offers),
    hardwareAlreadySold: isStatusSet(summary.hardwareAlreadySold),
  });
};

export const onContactedValueChange = (form: FormikProps<LeadEditStatusForm>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    if (!value) {
      form.setFieldValue(formFields.ON_SITE_VISIT_ARRANGED, false);
      form.setFieldValue(formFields.ON_SITE_VISIT_DONE, false);
      form.setFieldValue(formFields.HARDWARE_SENT, []);
      form.setFieldValue(formFields.HARDWARE_CONFIRMED, []);
    }

    validateForm(form);
  };

export const onSiteVisitArrangedValueChange = (form: FormikProps<LeadEditStatusForm>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    if (value) {
      form.setFieldValue(formFields.CONTACTED, true);
    } else {
      form.setFieldValue(formFields.ON_SITE_VISIT_DONE, false);
      form.setFieldValue(formFields.HARDWARE_SENT, []);
      form.setFieldValue(formFields.HARDWARE_CONFIRMED, []);
    }

    validateForm(form);
  };

export const onSiteVisitDoneValueChange = (form: FormikProps<LeadEditStatusForm>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    if (value) {
      form.setFieldValue(formFields.CONTACTED, true);
      form.setFieldValue(formFields.ON_SITE_VISIT_ARRANGED, true);
    } else {
      form.setFieldValue(formFields.HARDWARE_SENT, []);
      form.setFieldValue(formFields.HARDWARE_CONFIRMED, []);
    }

    validateForm(form);
  };

export const onHwSentValueChange = (offer: LeadOffer) => (form: FormikProps<LeadEditStatusForm>) =>
  ({ value }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    const currentHardwareConfirmedState = form.values[formFields.HARDWARE_CONFIRMED];
    const targetProductId = getHardwareProduct(offer)?.productId;

    if (!targetProductId) return;

    if (value) {
      form.setFieldValue(formFields.CONTACTED, true);
      form.setFieldValue(formFields.ON_SITE_VISIT_ARRANGED, true);
      form.setFieldValue(formFields.ON_SITE_VISIT_DONE, true);
    } else {
      form.setFieldValue(formFields.HARDWARE_CONFIRMED, currentHardwareConfirmedState.filter(productId => productId !== targetProductId));
    }

    validateForm(form);
  };

export const onHwConfirmedValueChange = (offer: LeadOffer) => (form: FormikProps<LeadEditStatusForm>) =>
  ({ }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    const currentHardwareSentState = form.values[formFields.HARDWARE_SENT];
    const targetProductId = getHardwareProduct(offer)?.productId;

    if (!targetProductId) return;

    if (!currentHardwareSentState.includes(targetProductId)) {
      form.setFieldValue(formFields.CONTACTED, true);
      form.setFieldValue(formFields.ON_SITE_VISIT_ARRANGED, true);
      form.setFieldValue(formFields.ON_SITE_VISIT_DONE, true);
      form.setFieldValue(formFields.HARDWARE_SENT, [...currentHardwareSentState, targetProductId]);
    }

    form.setFieldTouched(formFields.HARDWARE_CONFIRMED);
    validateForm(form);
  };

export const onHwAlreadySoldValueChange = (form: FormikProps<LeadEditStatusForm>) =>
  ({ }: FormInputSubscriptionPayload<LeadEditStatusForm>) => {
    form.setFieldTouched(formFields.HARDWARE_ALREADY_SOLD);
  };

const findHighestStatus = (initialValues: LeadEditCommonStatus, values: LeadEditCommonStatus) => {
  const sortedStatuses = [
    formFields.ON_SITE_VISIT_DONE,
    formFields.ON_SITE_VISIT_ARRANGED,
    formFields.CONTACTED,
  ];

  return sortedStatuses.find(rank => values[rank] && !initialValues[rank]);
};

const mapToStatusName = (formStatus: LeadEditStatusFormFields) => ({
  [formFields.ON_SITE_VISIT_ARRANGED]: LeadStatusName.ON_SITE_VISIT_ARRANGED,
  [formFields.ON_SITE_VISIT_DONE]: LeadStatusName.ON_SITE_VISIT_DONE,
  [formFields.CONTACTED]: LeadStatusName.CONTACTED,
})[formStatus];

export const findLeadStatusToUpdate = (initialValues: LeadEditCommonStatus, values: LeadEditCommonStatus) => {
  const formStatus = findHighestStatus(initialValues, values);

  return formStatus ? mapToStatusName(formStatus) : undefined;
};

export type HardwareProductToUpdate = {
  offerId: string;
  productId: string;
  status: OfferProductStatus;
};

export const findProductsStatusesToUpdate = (offers: LeadOffer[]) =>
  (
    initialValues: LeadEditStatusForm,
    values: LeadEditStatusForm,
  ): HardwareProductToUpdate[] => {
    const productsToUpdateWithSent = difference(values.hardwareSent)(initialValues.hardwareSent);
    const productsToUpdateWithConfirmed = difference(values.hardwareConfirmed)(initialValues.hardwareConfirmed);

    return [
      ...difference(productsToUpdateWithSent)(productsToUpdateWithConfirmed).map(productId => ({
        offerId: offers.find(offer => getHardwareProduct(offer)?.productId === productId)?.id!,
        productId,
        status: OfferProductStatus.SENT,
      })),
      ...productsToUpdateWithConfirmed.map(productId => ({
        offerId: offers.find(offer => getHardwareProduct(offer)?.productId === productId)?.id!,
        productId,
        status: OfferProductStatus.CONFIRMED,
      })),
    ];
  };

export const getUniqueOffersWithHardwareProductsSortedByBundles =
  flow<LeadOffer[], LeadOffer[], LeadOffer[], LeadOffer[]>(
    filter(offer => !isUndefined(getHardwareProduct(offer))),
    sortBy(offer => !!getFlatProduct(offer) ? 0 : 1),
    uniqBy(offer => getHardwareProduct(offer)?.productId),
  );

export const mapOfferHardwareProductsToFormFieldsByStatus = (...targetStatus: OfferProductStatus[]) =>
  flow<LeadOffer[], LeadOffer[], LeadOffer[], string[]>(
    getUniqueOffersWithHardwareProductsSortedByBundles,
    filter(offer => {
      const hardwareProduct = getHardwareProduct(offer);
      return Boolean(hardwareProduct && targetStatus.includes(hardwareProduct.status));
    }),
    map(offer => getHardwareProduct(offer)?.productId!),
  );

export const isHardwareStatusFormFieldActive = (field: typeof formFields.HARDWARE_SENT | typeof formFields.HARDWARE_CONFIRMED) =>
  (offer: LeadOffer) => (form: LeadEditStatusForm): string | undefined =>
    form[field].find(id => id === getHardwareProduct(offer)?.productId);

export const isOneHardwareConfirmedChecked = (formValues: FormikProps<LeadEditStatusForm>['values']) =>
  formValues[formFields.HARDWARE_CONFIRMED].length === 1;

export const canMoveLeadToSetup = ({ statusSummary, values, offers, initialValues }: {
  statusSummary: Lead['status']['summary'],
  values: LeadEditStatusForm,
  offers: LeadOffer[],
  initialValues: LeadEditStatusForm,
}): boolean => {
  const hardwareProductCase = !isNil(statusSummary.hardwareOrderConfirmed)
    || isOneHardwareConfirmedChecked(values);
  const flatOnlyCase = isAnyOfferFlatOnly(offers)
    && (values.hardwareAlreadySold || isStatusSet(statusSummary.hardwareAlreadySold));
  const eitherFlatOrHardwareCaseSelected = isEmpty(values.hardwareConfirmed) || !values.hardwareAlreadySold;

  return !initialValues.inSetup
    && (hardwareProductCase || flatOnlyCase)
    && eitherFlatOrHardwareCaseSelected;
};

export const getLeadStatusListToUpdate = ({ values, initialValues, productsStatusesToUpdate }: {
  values: LeadEditStatusForm,
  initialValues: LeadEditStatusForm,
  productsStatusesToUpdate: HardwareProductToUpdate[],
}) => {
  const commonLeadStatusToUpdate = findLeadStatusToUpdate(initialValues, values);

  return [
    // these should only be fired when there's no product status update
    ...insertIf(isEmpty(productsStatusesToUpdate) && !isEmpty(commonLeadStatusToUpdate), commonLeadStatusToUpdate),
    ...insertIf(values.hardwareAlreadySold && !initialValues.hardwareAlreadySold, LeadStatusName.HW_ALREADY_SOLD),
    ...insertIf(values.inSetup, LeadStatusName.IN_SETUP),
  ];
};
