import { LeadAddress } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import {
  Button,
  ButtonSize,
  Icon,
  LinkButton,
  Loader,
  Modal,
  ModalLayout,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadInvalidAddressModal.component.scss';

interface Props<T> {
  isModalOpen: boolean;
  isPending: boolean;
  address: Partial<LeadAddress>;
  toggleModal: (arg: boolean) => void;
  submitLabel: string;
  onSubmit: () => void;
  onClose?: () => void;
}

export const LeadInvalidAddressModal = <T extends { deliveryAddress: LeadAddress }>({
  isModalOpen,
  toggleModal,
  isPending,
  address,
  submitLabel,
  onSubmit,
  onClose,
}: Props<T>) => {
  const closeModal = () => {
    toggleModal(false);
    if (onClose) {
      onClose();
    }
  };

  const submitAnyway = () => {
    toggleModal(false);
    onSubmit();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      size={'medium'}
    >
      {isPending &&
        <Loader className={'c-lead-invalid-address-modal__loader'} />
      }
      <div className={'c-lead-invalid-address-modal'}>
        <ModalLayout
          footer={
            <div className={'c-lead-invalid-address-modal__buttons'}>
              <div className={'c-lead-list-assign-modal__buttons-wrapper'}>
                <LinkButton
                  className={'c-lead-invalid-address-modal__button-back'}
                  onClick={closeModal}
                >
                  {I18n.t(T.lead._salessolution_.invalidAdressModal.goBack)}
                </LinkButton>
                <Button
                  label={submitLabel}
                  size={ButtonSize.PRIMARY}
                  onClick={submitAnyway}
                />
              </div>
            </div>
          }
        >
          <div className={'c-lead-invalid-address-modal__info'}>
            {I18n.t(T.lead._salessolution_.invalidAdressModal.info)}
          </div>
          <div className={'c-lead-invalid-address-modal__address'} data-hj-suppress={true}>
            {`${address.street || '-'}, ${address.city || '-'} ${address.zipCode || '-'}, `}
            {address.country
              ? I18n.t(T.lead.boc._salessolution_.dictionary.countries[address.country.toLowerCase()])
              : '-'
            }
          </div>
          <div className={'c-lead-invalid-address-modal__warning-wrapper'}>
            <Icon.Warning className={'c-lead-invalid-address-modal__warning-icon'} />
            <div className={'c-lead-invalid-address-modal__warning-text'}>
              <p>
                {I18n.t(T.lead._salessolution_.invalidAdressModal.warning.correctSpelling)}
              </p>
              <p>
                {I18n.t(T.lead._salessolution_.invalidAdressModal.warning.ifAddressCorrect, {
                  submitLabel,
                })}
              </p>
            </div>
          </div>
        </ModalLayout>
      </div>
    </Modal>
  );
};
