import { MeterInstallation } from '+app/shared/store/contract/types/contract.interface';
import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web';
import { isUndefined } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { MeterPreparationStatus, ResultMeterChange } from '../../contract';
import { CustomerWarning, WarningFactory } from './warnings.types';

const getPreparationStatus = (meterInstallation: MeterInstallation) => {
  const preparationStatus = meterInstallation.meterPreparationStatus || null;
  switch (preparationStatus) {
    case MeterPreparationStatus.NOT_NEEDED: {
      return ({
        message: I18n.t(T.warning.meterPreparationIsNotNeeded),
        messageExtended: I18n.t(T.warning.meterPreparationIsNotNeededExtended),
        type: StatusType.SUCCESS,
      }) as CustomerWarning;
    }
    case MeterPreparationStatus.MISSING: {
      return ({
        message: I18n.t(T.warning.meterPreparationMissing),
        messageExtended: I18n.t(T.warning.meterPreparationMissingExtended),
        type: StatusType.DANGER,
      }) as CustomerWarning;
    }
    case MeterPreparationStatus.SUBMITTED: {
      return ({
        message: I18n.t(T.warning.meterExchangeSubmitted),
        type: StatusType.DEFAULT,
      }) as CustomerWarning;
    }
    case MeterPreparationStatus.IN_CLEARING: {
      return ({
        message: I18n.t(T.warning.meterPreparationInClearing),
        messageExtended: I18n.t(T.warning.meterPreparationInClearingExtended),
        type: StatusType.DANGER,
      }) as CustomerWarning;
    }
    case MeterPreparationStatus.CHECKED: {
      return ({
        message: I18n.t(T.warning.meterPreparationPresent),
        type: StatusType.SUCCESS,
      }) as CustomerWarning;
    }
    case null: {
      return ({
        message: I18n.t(T.warning.meterPreparationMissing),
        messageExtended: I18n.t(T.warning.meterPreparationMissingExtended),
        type: StatusType.DANGER,
      }) as CustomerWarning;
    }
    default: return undefined;
  }
};

const getWimProcessStatus = (meterInstallation: MeterInstallation) => {
  const { startWimProcess } = meterInstallation;
  if (startWimProcess) {
    return ({
      message: I18n.t(T.warning.meterExchangeInPreparation),
      type: StatusType.DEFAULT,
    }) as CustomerWarning;
  }

  return getPreparationStatus(meterInstallation);
};

export const meterInstallationWarningFactory: WarningFactory<MeterInstallation | undefined> = meterInstallation => {
  if (isUndefined(meterInstallation)) {
    return ({
      message: I18n.t(T.warning.meterPreparationMissing),
      messageExtended: I18n.t(T.warning.meterPreparationMissingExtended),
      type: StatusType.DANGER,
    }) as CustomerWarning;
  }

  const { resultMeterChange } = meterInstallation;
  if (resultMeterChange === ResultMeterChange.SUCCESSFUL) {
    return ({
      message: I18n.t(T.warning.meterExchangeDone),
      type: StatusType.SUCCESS,
    }) as CustomerWarning;
  }

  if (resultMeterChange === ResultMeterChange.ABORTED) {
    return ({
      message: I18n.t(T.warning.meterExchangeNotSuccessful),
      type: StatusType.DEFAULT,
    }) as CustomerWarning;
  }

  return getWimProcessStatus(meterInstallation);
};
