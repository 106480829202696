import { Form as FormikForm, FormikFormProps } from 'formik';
import * as React from 'react';
import { Omit } from 'react-redux';

type Props = Omit<FormikFormProps, 'noValidate'>;

export const Form: React.FC<Props> = ({ children, ...props }) => {
  return (
    <FormikForm {...props} noValidate={true}>
      {children}
    </FormikForm>
  );
};
