import { queryReducer } from '+shared/store/query';
import { createReducer } from '../../../utils';
import { SITE_ACTIONS, SiteActions } from './site.actions';
import {
  GET_SITE_LIVE_STATE_QUERY,
  GET_SITE_QUERY,
  GET_SITE_STATISTICS_QUERY,
  initialState,
  SiteState,
} from './site.state';

export const reducer = createReducer<SiteState, SiteActions>(
  (state = initialState, action): SiteState => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITE:
        return {
          ...state,
          site: action.site,
        };
      case SITE_ACTIONS.SET_SITE_LIVE_STATE:
        return {
          ...state,
          liveState: action.liveState,
        };
      case SITE_ACTIONS.CLEAR_SITE:
        return {
          ...initialState,
        };
      case SITE_ACTIONS.SET_SITE_STATISTICS:
        return {
          ...state,
          statistics: action.statistics,
        };
      default:
        return queryReducer(state, action, [
          GET_SITE_QUERY,
          GET_SITE_LIVE_STATE_QUERY,
          GET_SITE_STATISTICS_QUERY,
        ],
      );
    }
  }, 
);
