import { ActionsObservable, combineEpics } from 'redux-observable';
import { iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { dataGuard, ofType, processQuery } from '../../../utils';
import { ContractActions } from './contract.actions';
import { ContractRepository } from './contract.repository';

type Action$ = ActionsObservable<ContractActions>;

export const getContract$ = (action$: Action$) => action$.pipe(
  ofType(ContractActions.getContract),
  mergeMap(({ queryKey, contractId }) => iif(
    () => !!contractId,
    of({}).pipe(
      processQuery(
        queryKey,
        () => ContractRepository.getContract(contractId), 
        {
          onSuccess: res => dataGuard(ContractActions.setContract)(res!.element),
        },
      ),
    ),
    of(),
  )),
);

export const epics = combineEpics<any>(
  getContract$,
);
