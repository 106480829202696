import { CustomerNote, FormState } from '+app/+customer/containers/CustomerNote';
import { Input } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, ClickOutside, Icon, LinkButton, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, FieldProps, Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CustomersTableNote.component.scss';

interface Props {
  customerId: string;
  customerNote: string;
}

export const CustomersTableNote: React.FC<Props> = ({ customerId, customerNote }) => (
  <CustomerNote
    customerId={customerId}
    customerNote={customerNote}
    renderForm={({ onFormSubmit, cancelEdit, editNote, formState, isEditActive }) => (
      <Formik
        initialValues={{ note: customerNote }}
        onSubmit={onFormSubmit}
        enableReinitialize={true}
        render={form => (
          <ClickOutside
            onClick={() => {
              form.handleReset();
              cancelEdit();
            }}
          >
            <div className={classNames({ 'c-customer-table-note--edit-active': isEditActive })}>
              <form 
                onSubmit={form.handleSubmit}
                className={classNames('c-customer-table-note__form', {
                  'c-customer-table-note__form--edit-active': isEditActive,
                })
              }>
                <div className={classNames('c-customer-table-note__input', {
                  'c-customer-table-note__input--edit-active': isEditActive,
                })}
                >
                  <Field
                    name={'note'}
                    render={({ field }: FieldProps) => (
                      <div
                        className={'gtm-customer-table-note__input'}
                        onClick={event => {
                          event.stopPropagation();
                          if (formState === FormState.FORM) { editNote(); }
                        }}
                      >
                        <Input
                          name={'note'}
                          value={field.value || ''}
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          type={'textarea'}
                          placeholder={I18n.t(T.customerSingle.overview.notes.placeholder)}
                          error={form.errors.note}
                          isTouched={form.touched.note}
                          disabled={formState === FormState.FORM_PENDING}
                        />
                        {formState === FormState.FORM &&
                          <Icon.Pen className={'c-customer-table-note__edit-icon'} />
                        }
                        {formState !== FormState.FORM &&
                          <div className={'c-customer-table-note__hint'}>
                            {formState === FormState.FORM_PENDING ? <Loader /> : ''}
                          </div>
                        }
                      </div>
                    )}
                  />
                  {isEditActive &&
                    <div className={'c-customer-table-note__footer'}
                      onClick={event => {
                        event.stopPropagation();
                        if (formState === FormState.FORM) { editNote(); }
                      }}
                    >
                      <div>
                        <LinkButton
                          onClick={() => {
                            if (formState !== FormState.FORM) {
                              form.handleReset();
                              cancelEdit();
                            }
                          }}
                          className={'c-customer-table-note__cancel gtm-customer-table-note__cancel'}
                        >
                          {I18n.t(T.general.basicActions.cancel)}
                        </LinkButton>
                      </div>
                      <div>
                        <div className={'c-customer-table-note__save gtm-customer-table-note__save'}>
                          <Button
                            theme={ButtonTheme.PRIMARY}
                            size={ButtonSize.PILL}
                            label={I18n.t(T.general.basicActions.save)}
                            onClick={() => formState !== FormState.FORM ? form.handleSubmit() : null}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </form>
            </div>
          </ClickOutside>
        )}
      />
    )}
    renderError={(error) => (
      <div className={'c-customer-table-note__hint'}>{error}</div>
    )}
  />
);
