import { FormSubmitButton } from '+shared/components';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { LeadConfigurationResponse } from '+shared/store/lead/types';
import {
  LeadImpactAnalysisRecommendationResponseAttributes,
} from '+shared/store/lead/types/leadImpactAnalysisRecommendationResponse.interface';
import { T } from '@sonnen/shared-i18n/service';
import { ButtonTheme, LinkButton, TimeHelper, WizardContext } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  isAmortizationOver20Years,
  // tslint:disable-next-line: max-line-length
} from '../../containers/LeadImpactAnalysisCalculationResults/page-detailed-calculation-20-years/ResultsCalculation20Years.helpers';

import './LeadImpactAnalysisWizardFooter.component.scss';

interface Props {
  recommendation?: LeadImpactAnalysisRecommendationResponseAttributes;
  isPending?: boolean;
  configuration?: LeadConfigurationResponse;
}

export const LeadImpactAnalysisWizardFooter: React.FC<Props> = ({
  isPending,
  recommendation,
  configuration,
}) => {
  const { track } = useTracking();
  const wizard = React.useContext(WizardContext);
  const isFistStep = wizard.progress === 1;
  const isLastStep = wizard.isLastStep;

  const onCreateButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    const amortizationOver20Years = recommendation && isAmortizationOver20Years(recommendation.amortizationTimeFrame);
    const isPvAlreadyExisting = configuration && 
      !TimeHelper.isTodayOrAfter(new Date(configuration.photovoltaicSystem.commissioningDate));

    if (amortizationOver20Years) {
      track(InteractionEvents.buttonClick({
        category: EventCategory.IMPACT_ANALYSIS,
        action: 'Create Impact Analysis with amortization over 20y',
        event,
      }));
    }
    if (isPvAlreadyExisting) {
      track(InteractionEvents.buttonClick({
        category: EventCategory.IMPACT_ANALYSIS,
        action: 'Create Impact Analysis for existing PV system',
        event,
      }));
    }
  };

  return (
    <div className={'c-impact-analysis-wizard-footer'}>
      {wizard.prev && !isFistStep && (
        <div className={'c-impact-analysis-wizard-footer__navigation-prev'}>
          <LinkButton onClick={wizard.prev}>
            {I18n.t(T.lead.impactAnalysis._salessolution_.back)}
          </LinkButton>
        </div>
      )}
      <FormSubmitButton
        theme={ButtonTheme.PRIMARY}
        label={isLastStep ?
          I18n.t(T.lead.impactAnalysis._salessolution_.result.createPDF)
          : I18n.t(T.lead.impactAnalysis._salessolution_.next)}
        isSubmitting={isPending}
        onClick={isLastStep ? onCreateButtonClick : undefined}
      />
    </div>
  );
};
