import { LeadConfigurationBatteryCapacities } from '+lead-configuration/components/LeadConfigurationBatteryCapacities';
import { FormInputRadioGroup, FormInputSelect } from '+shared/components';
import { batteryModelNameMap } from '+shared/store/lead/lead.helpers';
import { BatteryModelName, DC_BATTERIES, LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Link, WarningText } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { ConfigurationForm } from '../../store/types';
import { formFields, mapBatteryModelNameCollection } from './LeadConfigurationBattery.helper';

import './LeadConfigurationBattery.component.scss';

interface Props {
  form: FormikProps<ConfigurationForm>;
  productBatteryList: LeadProductBattery[];
  // this one is when we're adding flat tariff to an existing offer
  existingOffer?: LeadOffer;
  // here we're creating another bundle offer while HW only was previously accepted/confirmed
  previouslyAcceptedHardwareOffer?: LeadOffer;
  isHardwareAlreadySold?: boolean;
}

export const LeadConfigurationBattery: React.FC<Props> = ({
  form,
  productBatteryList,
  existingOffer,
  previouslyAcceptedHardwareOffer,
  isHardwareAlreadySold,
}) => {
  const modelName = form.values.modelName;

  return (
    <div className={'c-lead-configuration-battery'}>
      {/* NEW OR EXISTING BATTERY */}
      <p className={'c-lead-configuration-battery__new-battery-question'}>
        {I18n.t(T.lead.configuration._salessolution_.battery.newOrExisting)}
      </p>
      <div className={'c-lead-configuration-battery__new-battery-radio-btns'}>
        <FormInputRadioGroup
          name={formFields.NEW_BATTERY}
          form={form}
          // this field is disabled only when we want to add tariff to an existing offer with HW only
          // or when creating another bundle offer while HW only was previously accepted/confirmed
          disabled={!!existingOffer || !!previouslyAcceptedHardwareOffer || isHardwareAlreadySold}
          collection={[
            {
              label: I18n.t(T.lead.configuration._salessolution_.battery.new),
              value: true,
            },
            {
              label: I18n.t(T.lead.configuration._salessolution_.battery.existing),
              value: false,
            },
          ]}
        />
      </div>

      <div className={'c-lead-configuration-battery__columns-wrapper'}>
        {/* MODEL NAME */}
        <div className={'c-lead-configuration-battery__column'}>
          <div className={'c-lead-configuration-battery__input'}>
            <FormInputSelect
              form={form}
              label={I18n.t(T.lead.configuration._salessolution_.battery.model)}
              collection={mapBatteryModelNameCollection(productBatteryList, form)}
              name={formFields.MODEL_NAME}
              mapper={key => batteryModelNameMap(key)}
              id={'lead-configuration-battery-model'}
              hasNoGap={true}
              // this field is disabled only when we want to add tariff to an existing offer with HW only
              // or when creating another bundle offer while HW only was previously accepted/confirmed
              isDisabled={!!existingOffer || !!previouslyAcceptedHardwareOffer}
            />
          </div>
          {DC_BATTERIES.includes(modelName) &&
            <>
              <div className={'c-lead-configuration-battery__dc-warning'}>
                <WarningText text={
                  modelName === BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC
                    ? I18n.t(T.lead.configuration._salessolution_.battery.warning.dcDeliveredIn2022)
                    : I18n.t(T.lead.configuration._salessolution_.battery.dcWarning)}
                />
              </div>
              <p className={'c-lead-configuration-battery__message'}>
                <Link
                  href={'https://pvsizing.sonnengroup.com/'}
                  label={I18n.t(T.lead.configuration._salessolution_.battery.link)}
                >
                  {I18n.t(T.lead.configuration._salessolution_.battery.link)}
                </Link>
                {' '}{I18n.t(T.lead.configuration._salessolution_.battery.dcMessage)}
              </p>
            </>
          }
        </div>
        {form.values.multiplePv && (
          /* BATTERY CAPACITY */
          <div className={'c-lead-configuration-battery__column'}>
            <div className={'c-lead-configuration-battery__input'}>
              <LeadConfigurationBatteryCapacities
                form={form}
                productBatteryList={productBatteryList}
                existingOffer={existingOffer}
                previouslyAcceptedHardwareOffer={previouslyAcceptedHardwareOffer}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
