import { AccountPageActions } from '+app/+account/store';
import { ContactListPageActions } from '+contact/+list/store';
import { ContactActions } from '+shared/store/contact';
import { dataGuard } from '+utils/guards.util';
import { ofType } from '+utils/operators/ofType.operator';
import { processQuery } from '+utils/operators/processQuery.operator';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ContactRepository } from './contact.repository';

type Action$ = ActionsObservable<ContactActions>;

export const getContactList$ = (action$: Action$) => action$.pipe(
  ofType(ContactListPageActions.getContactList),
  mergeMap(({ queryKey, queryParams }) => of({}).pipe(
    processQuery(
      queryKey,
      () => ContactRepository.getContactList(queryParams),
      { onSuccess: res => of(ContactListPageActions.setContactList(res!.elements, res!.meta.totalResourceCount)) }),
  )),
);

export const getContactCertificateList$ = (action$: Action$) => action$.pipe(
  ofType(AccountPageActions.getCertificateList),
  mergeMap(({ contactId, queryKey}) => of({}).pipe(
    processQuery(
      queryKey,
      () => ContactRepository.getContactCertificateList(contactId),
      { onSuccess: res => dataGuard(AccountPageActions.setCertificateList)(res!.elements) },
    ),
  )),
);

export const epics = combineEpics(
  getContactList$,
  getContactCertificateList$,
);
