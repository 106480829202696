import { Header, Logo } from '@sonnen/shared-web';
import * as React from 'react';

import './RegisterCardHeader.component.scss';

export const RegisterCardHeader: React.SFC = () => (
  <div className={'c-register-card-header'}>
    <Header
      isContentFluid={true}
      theme={'dark'}
      logo={<Logo />}
    />
  </div>
);
