import { goTo } from '+app/utils';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CertificateEmptyMessage.component.scss';

export const CertificateEmptyMessage: React.FC = () => (
  <p className={'c-cerfificate-empty-message'}>
    <span dangerouslySetInnerHTML={{__html: I18n.t(T.myAccount.certification.noCertificatesTextBegin)}}/>
    <LinkButton
      className={'c-cerfificate-empty-message__link'}
      onClick={() => goTo(I18n.t(T.myAccount.certification.link), '_blank')}
    >
      {I18n.t(T.myAccount.certification.noCertificatesTextLinkLabel)}
    </LinkButton>
    {I18n.t(T.myAccount.certification.noCertificatesTextEnd)}
  </p>
);
