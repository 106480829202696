import { LeadModalLeadName } from '+app/+lead/components/LeadModalLeadName';
import { FormErrorBanner } from '+shared/components';
import { Contact } from '+shared/store/contact/types';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, LinkButton, Loader, Modal, ModalLayout } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import pageImage from '../../../../../assets/img/telescope.svg';
import { LeadListAssignModalForm } from '../LeadListAssignModalForm';

import './LeadListAssignModal.component.scss';

interface Props {
  isModalOpen: boolean;
  chosenLead?: Lead;
  partnerEmployeeList: Contact[];
  userProfileSalesforceContactId: string;
  isPending: boolean;
  isError: boolean;
  toggleAssignLeadModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
  assignPartner: (leadId: string, partnerId: string) => void;
}

export const LeadListAssignModal: React.FC<Props> = ({
  isModalOpen,
  toggleAssignLeadModal,
  toggleModal,
  chosenLead,
  partnerEmployeeList,
  assignPartner,
  userProfileSalesforceContactId,
  isPending,
  isError,
}) => {
  const closeLeadAssignModal = () => {
    toggleAssignLeadModal(false);
    toggleModal(false);
  };

  const onSubmit = (values: { chosenPartnerId: string }) => {
    if (chosenLead) {
      assignPartner(chosenLead.id, values.chosenPartnerId);
    }
  };

  const myEmployeeList = partnerEmployeeList.filter(partner =>
    partner.salesforceContactId !== userProfileSalesforceContactId);

  return chosenLead ? (
    <Modal
      isOpen={isModalOpen}
      onClose={closeLeadAssignModal}
      size={'medium'}
    >
      {isPending &&
        <Loader className={'c-lead-list-assign-modal__accept-loader'} />
      }
      <div className={'c-lead-list-assign-modal'}>
        <Formik
          initialValues={{
            chosenPartnerId:
              myEmployeeList.length > 0 ? myEmployeeList[0].salesforceContactId : '',
          }}
          validateOnBlur={false}
          validateOnChange={true}
          onSubmit={onSubmit}
          render={form => (
            <Form className={'c-lead-list-assign-modal-form'}>
              <ModalLayout
                title={I18n.t(T.lead.list._salessolution_.assignModal.assignLead)}
                subtitle={I18n.t(T.lead.list._salessolution_.assignModal.description)}
                footer={
                  <div className={'c-lead-list-assign-modal__buttons'}>
                    {!isPending ?
                      <div className={'c-lead-list-assign-modal__buttons-wrapper'}>
                        <LinkButton
                          className={'c-lead-list-assign-modal__footer-button'}
                          onClick={() => assignPartner(chosenLead.id, userProfileSalesforceContactId)}
                        >
                          {I18n.t(T.lead.list._salessolution_.assignModal.assignToMyself)}
                        </LinkButton>
                        <Button
                          label={I18n.t(T.lead.list._salessolution_.assignModal.assignLead)}
                          size={ButtonSize.NORMAL}
                          type={'submit'}
                          isDisabled={isEmpty(myEmployeeList)}
                        />
                      </div>
                      : null
                    }
                  </div>
                }
              >
                <LeadModalLeadName lead={chosenLead} />
                {!isEmpty(myEmployeeList) ?
                  <LeadListAssignModalForm
                    partnerEmployeeList={myEmployeeList}
                    form={form}
                  />
                  : <div className={'c-lead-list-assign-modal__empty-list'}>
                    <img
                      className={'c-lead-list-assign-modal__empty-list-icon'}
                      src={pageImage}
                      alt={'telescope'}
                    />
                    <div className={'c-lead-list-assign-modal__empty-list-headline'}>
                    {I18n.t(T.lead.list._salessolution_.assignModal.noPartners)}
                    </div>
                  </div>
                }
                <FormErrorBanner
                  isVisible={isError}
                  error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                />
              </ModalLayout>
            </Form>
          )}
        />
      </div>
    </Modal>
  ) : null;
};
