import { TableCell } from '@sonnen/shared-web';
import * as React from 'react';

import './CustomersTableNoData.component.scss';

interface Props {
  message?: string;
}

export const CustomersTableNoData: React.FC<Props> = ({ message }) => (
  <TableCell className={'c-customers-table-no-data'}>
    {message || '—'}
  </TableCell>
);
