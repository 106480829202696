import { AccountPageActions } from '+app/+account/store';
import { getUploadCompanyDocsQueryStatus } from '+app/+account/store/+account.selector';
import { mapActions } from '+app/utils';
import { DetailListTable } from '+shared/components';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { Company } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DocUploadForm } from '../../components';
import { getDocumentsList } from './LegalDocuments.helper';

import './LegalDocuments.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  queryStatus: getUploadCompanyDocsQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
  submit: AccountPageActions.submitCompanyDocs,
  resetQueryStatus: AccountPageActions.resetUploadCompanyDocsQuery,
});

interface LegalDocumentsProps {
  userCompany: Company | undefined;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & LegalDocumentsProps
  ;

const LegalDocumentsComponent: React.FC<Props> = ({ actions, openModalId, queryStatus, userCompany }) => {
  const onOpen = () =>
    actions.toggleModal(true, ModalId.DOCS_UPLOAD);

  const onClose = () => {
    actions.resetQueryStatus();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
  };

  const onTryAgain = () => {
    actions.resetQueryStatus();
    setFormState(FormState.FORM);
  };

  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (queryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }

    if (queryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }
  }, [queryStatus]);

  const documents = {
    termsAndConditions: userCompany?.termsAndConditionsUrl,
    dataPrivacyPolicy: userCompany?.dataPrivacyPolicyUrl,
    cancellationPolicy: userCompany?.cancellationPolicyUrl,
  };

  return (
    <div className={'c-request-access'}>
      <PageSubheadline mediumGap={true}>
        <div className={'c-logo__wrapper'}>
          {I18n.t(T.companyAccount.legalDocuments.title)}
          <LinkButton
            onClick={onOpen}
            className={'c-logo__button'}
          >
            {I18n.t(T.companyAccount.legalDocuments.buttons.open)}
          </LinkButton>
        </div>
      </PageSubheadline>
      <DetailListTable list={getDocumentsList(documents)} suppressRowValuesInHotjar={true} />
      <FormModal
        isModalOpen={openModalId === ModalId.DOCS_UPLOAD}
        status={formState}
        headlineSuccess={I18n.t(T.companyAccount.legalDocuments.modal.success)}
        headlineFailure={I18n.t(T.companyAccount.legalDocuments.modal.failure)}
        onClose={onClose}
        onTryAgain={onTryAgain}
        defaultForm={
          <DocUploadForm
            documents={documents}
            submitForm={actions.submit}
            onClose={onClose}
            isLoading={queryStatus.pending}
          />
        }
      />
    </div>
  );
};

export const LegalDocuments = connect(mapStateToProps, mapDispatchToProps)(LegalDocumentsComponent);
