import { getCaseCollection, getCaseCollectionQueryStatus } from '+app/shared/store/case/case.selectors';
import { StoreState } from '+shared/store/store.interface';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { CaseCard } from '../../components';

type Props = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: StoreState) => ({
  cases: getCaseCollection(state),
  queryStatus: getCaseCollectionQueryStatus(state),
});

const CustomerCasesComponent: React.FC<Props> = ({ cases, queryStatus }) => (
  <>
    {queryStatus.pending
      ? <Loader />
      : cases.map(item => (
        <CaseCard 
          key={item.caseNumber} 
          caseItem={item} 
        />
      ))
    }
  </>
);

export const CustomerCases = connect(mapStateToProps)(CustomerCasesComponent);
