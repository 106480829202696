import { Data, MergedData, Relationship } from '@coolio/json-api';
import { LeadProductType } from '.';
import { LeadDocument } from './leadDocument.interface';

/**
 * @deprecated: Use `OfferProductStatus` instead
 */
export enum OfferStatus {
  DELETED = 'deleted',
  BLOCKED = 'blocked',
  DRAFT = 'draft',
  SENT = 'sent',
  BINDINGLY_ACCEPTED = 'bindingly-accepted',
}

export enum OfferProductStatus {
  UNKNOWN = 'unknown',
  DRAFT = 'draft',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
}

export interface OfferAcceptance {
  type: string;
  date: string;
  identifier: string | null;
}

export interface LeadOfferProduct {
  productId: string;
  productType: LeadProductType;
  status: OfferProductStatus;
  expiresOn?: string;
}

export interface LeadOfferAttributes {
  status: OfferStatus;
  createdAt: string;
  updatedAt: string;
  bankAccountHolder?: { firstName: string; lastName: string };
  bankBic?: string;
  bankIban?: string;
  bankPayment?: string;
  flatOffers: LeadDocument[];
  flatConfigurations: LeadDocument[];
  accepted?: OfferAcceptance[];
  products: LeadOfferProduct[];
}

export type LeadOfferRelationships = {
  configuration: Relationship;
  battery: Relationship;
};

export interface LeadOfferData extends Data<LeadOfferAttributes, LeadOfferRelationships> {}
export interface LeadOffer extends MergedData<LeadOfferData> {}
