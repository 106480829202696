import { CurrencyUnit, EnergyUnit, OtherUnit, PeakPowerUnit } from '+app/App.constants';
import { Data, MergedData } from '@coolio/json-api';
import { BatteryModelName } from './leadBattery.interface';
import { LeadMeasurement } from './leadMeasurement.interface';
import { LeadProductType } from './leadProductType.interface';

export enum LeadConfigurationMarket {
  DE = 'DE',
}

export enum LeadConfigurationStatus {
  DRAFT = 'draft',
  PREVIEW = 'preview',
  OFFER = 'offer',
}

export interface LeadConfigurationResponsePvSystem {
  peakPower: LeadMeasurement<PeakPowerUnit.KWP>;
  inclination?: LeadMeasurement<OtherUnit.DEGREE>;
  orientation?: LeadMeasurement<OtherUnit.DEGREE>;
  specificYieldPerYear?: LeadMeasurement<EnergyUnit.KWH_PER_KWP>;
  commissioningDate: string;
  systemName?: string;
  systemKey?: string;
}

export interface LeadConfigurationResponseAttributes {
  market: LeadConfigurationMarket;
  product: LeadProductType;
  createdAt: string;
  productVersion: string;
  status: LeadConfigurationStatus;
  usageAllowancePerYear: LeadMeasurement<EnergyUnit.KWH>;
  cashbackTariffGrossPerKwh: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
  excessTariffGrossPerKwh: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
  initialAdvancePaymentGrossPerMonth: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
  sonnenEstimatedAutarky: LeadMeasurement<OtherUnit.PERCENT, number>;
  deliveryAddress: {
    street: string;
    postalCode: string;
    city: string;
    country: string;
  };
  photovoltaicSystem: {
    peakPower: LeadMeasurement<PeakPowerUnit.KWP>;
    inclination: LeadMeasurement<OtherUnit.DEGREE>;
    orientation: LeadMeasurement<OtherUnit.DEGREE>;
    commissioningDate: string;
    specificYieldPerYear: LeadMeasurement<EnergyUnit.KWH_PER_KWP>;
    feedInTariffPerKwh: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
    subSystems?: LeadConfigurationResponsePvSystem[];
  };
  battery: {
    batteryVppCapable: boolean;
    capacityGross: LeadMeasurement<EnergyUnit.KWH>;
    modelName: LeadMeasurement<OtherUnit.STRING, BatteryModelName>;
  };
  expectedElectricityConsumption: {
    totalConsumptionPerYear: LeadMeasurement<EnergyUnit.KWH>;
    electricCar?: {
      totalConsumptionPerYear?: LeadMeasurement<EnergyUnit.KWH>;
      mileagePerYear?: LeadMeasurement<OtherUnit.KM>;
    };
    heatPump?: {
      totalConsumptionPerYear?: LeadMeasurement<EnergyUnit.KWH>;
    };
  };
  powerPlant: {
    estimatedAutarkyUpperLimit: LeadMeasurement<OtherUnit.PERCENT, number>;
    expectedAutarky: LeadMeasurement<OtherUnit.PERCENT, number>;
    expectedSelfConsumptionPerYear: LeadMeasurement<EnergyUnit.KWH>;
    expectedGridConsumptionPerYear: LeadMeasurement<EnergyUnit.KWH>;
    expectedExcessEnergyPerYear: LeadMeasurement<EnergyUnit.KWH>;
    expectedBonusPerYear: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
    nonPhotovoltaicEnergyProducingSystemsInstalled: boolean;
    transformerMeteringMandatory: boolean;
    productionMeteringMandatory: boolean;
    vppMeteringCapable: boolean;
    vppMeteringRecommended: boolean;
    vppMeteringMandatory: boolean;
    guaranteedVppBonusGrossPerYear?: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
    guaranteedVppBonusGranted: boolean;
    expectedCashbackGrossPerYear: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
    expectedUnusedUsageAllowancePerYear: LeadMeasurement<EnergyUnit.KWH>;
    dsoConsentToCombinePhotovoltaicSystems: boolean;
  };
  vppCheckDone: boolean;
  valueAddedTax: LeadMeasurement<OtherUnit.PERCENT, number>;
  baseFeeGrossPerMonth: LeadMeasurement<CurrencyUnit.CURRENCY_EUR>;
}

export type LeadConfigurationResponseData = Data<LeadConfigurationResponseAttributes>;
export type LeadConfigurationResponse = MergedData<LeadConfigurationResponseData>;
