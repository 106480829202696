import { CouplingType, DsoRegistrationRadioInvestmentType } from '+setupTool/+form/store/types';
import { FormBanner, FormInputDate, FormInputSelect } from '+shared/components';
import { EventCategory, RequestEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { filterByValue, mapActions, searchByKey } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  getBatteryNames,
  getGetBatteriesQueryStatus,
  getGetBatteryNamesQueryStatus,
  getPvSystemForm,
} from '../../store/+form.selectors';

interface OwnProps<T> {
  form: FormikProps<T>;
  isBatteryOnly?: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  batteryNames: getBatteryNames(state),
  pvSystemForm: getPvSystemForm(state),
  getBatteriesQueryStatus: getGetBatteriesQueryStatus(state),
  getBatteryNamesQueryStatus: getGetBatteryNamesQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getBatteries: DsoRegistrationFormActions.getBatteries,
});

type Props<T> =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps<T>
  ;

type DsoRegistrationBatteryComponentI<T = any> = React.FC<Props<T>>;

export const DsoRegistrationBatteryComponent: DsoRegistrationBatteryComponentI = ({
  form,
  isBatteryOnly,
  batteryNames,
  pvSystemForm,
  getBatteriesQueryStatus,
  getBatteryNamesQueryStatus,
  actions,
}) => {
  const [currentBatteryName, setCurrentBatteryName] = React.useState('');
  const currentBattery = batteryNames.find(battery => battery.name === currentBatteryName);
  const shouldShowOnlyACBatteries = isBatteryOnly
    || (pvSystemForm && pvSystemForm.pv_type === DsoRegistrationRadioInvestmentType.EXTENSION);
  const [shouldShowInverterBanner, setShouldShowInverterBanner] = React.useState(false);
  const { track } = useTracking();

  React.useEffect(() => {
    const formValueBatteryName = form.values.battery_name;
    const currentBattery = batteryNames.find(battery => battery.name === formValueBatteryName);

    if (formValueBatteryName) {
      setCurrentBatteryName(formValueBatteryName);
    }
    if (currentBatteryName && formValueBatteryName !== currentBatteryName) {
      form.setFieldValue('battery_nominal_capacity_kWh', '');
      form.setFieldValue('battery_id', '');
    }

    if (
      formValueBatteryName && currentBattery && !isBatteryOnly
      && pvSystemForm && pvSystemForm.pv_type !== DsoRegistrationRadioInvestmentType.EXTENSION
      &&
      ((currentBattery.couplingType === CouplingType.AC && pvSystemForm.dc_coupled_battery === true)
        ||
        (currentBattery.couplingType === CouplingType.DC && pvSystemForm.dc_coupled_battery === false))
    ) {
      setShouldShowInverterBanner(true);
    } else {
      setShouldShowInverterBanner(false);
    }
  }, [form.values.battery_name]);

  React.useEffect(() => {
    if (
      currentBatteryName
      && !!batteryNames.length
      && currentBattery && !currentBattery.batteries
    ) {
      actions.getBatteries(currentBatteryName);
    }
  }, [currentBatteryName, batteryNames]);

  return (<>
    <FormInputSelect
      className={classNames('c-setup-tool-sonnen-battery__item', {
      'c-form-input-select--error': getBatteryNamesQueryStatus.error,
      })}
      form={form}
      label={I18n.t(T.setupTool.form.type)}
      id={'setup-tool-sonnen-battery__battery_name'}
      name={'battery_name'}
      placeholder={I18n.t(T.setupTool.selectPlaceholder)}
      resetValueIfNoItemSelected={true}
      collection={(shouldShowOnlyACBatteries
        ? batteryNames.filter(v => v.couplingType === CouplingType.AC)
        : batteryNames)
        .map(v => v.name)}
      search={val =>
        filterByValue(batteryNames, 'name')(val)
          .filter(v => shouldShowOnlyACBatteries
            ? v.couplingType === CouplingType.AC
            : true,
          )
          .map(item => item.name)
      }
      noResultsComponent={!!getBatteryNamesQueryStatus.pending ? <Loader /> : null}
      {...(getBatteryNamesQueryStatus.error ?
        {additionalInfoText: <Translate value={T.setupTool.info.noResults.batteries} dangerousHTML={true}/>}
        : {})}
    />

    <FormInputSelect
      className={classNames('c-setup-tool-sonnen-battery__item', {
        'c-form-input-select--error': getBatteriesQueryStatus.error,
      })}
      form={form}
      label={I18n.t(T.setupTool.form.batteryCapacity)}
      placeholder={I18n.t(T.setupTool.selectPlaceholder)}
      id={'setup-tool-sonnen-battery__battery_id'}
      name={'battery_id'}
      collection={currentBattery && currentBattery.batteries
        ? currentBattery.batteries.filter(item => item.name === currentBattery.name).map(el => el.id)
        : []
      }
      onSelect={val => {
        if (currentBattery) {
          const item = searchByKey('id', val, currentBattery.batteries, 'nominalCapacitykWh');
          form.setFieldValue('battery_nominal_capacity_kWh', item);
        }
      }}
      mapper={key => currentBattery ?
        searchByKey(
          'id',
          key,
          currentBattery.batteries,
          'nominalCapacitykWh',
        ) : null
      }
      noResultsComponent={!!getBatteriesQueryStatus.pending ? <Loader /> : null}
      {...(getBatteriesQueryStatus.error ?
        {additionalInfoText: <Translate value={T.setupTool.info.noResults.batteries} dangerousHTML={true}/>}
        : {})}
    />

    {isBatteryOnly &&
      <FormInputDate
        className={'c-setup-tool-pv-system__item'}
        form={form}
        label={I18n.t(T.setupTool.form.preferredInstallationDate)}
        name={'preferred_installation_date'}
        minDate={new Date('1900-01-01')}
      />
    }

    <FormBanner
      isVisible={shouldShowInverterBanner}
      type={'info'}
      id={'inverterBannerInfo'}
      message={I18n.t(T.setupTool.sonnenBattery.inverterBannerInfo.title)}
      description={I18n.t(T.setupTool.sonnenBattery.inverterBannerInfo.description)}
      icon={<Icon.Info />}
    />
  </>);
};

export const DsoRegistrationBattery = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationBatteryComponent);
