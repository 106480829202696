import { getLead } from '+app/+lead/store/+lead.selectors';
import { getVppLabelsForFlatDirect } from '+app/+setupTool/+vppDocumentation/store/+vppDocumentation.selectors';
import { LEAD_IN_SETUP_STAGE } from '+app/router';
import { CountryFeatureName, FeatureName } from '+config';
import { PreCommissioningActions } from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import { ErrorBox, SetupToolInfo, SetupToolTable } from '+setupTool/components';
import { SetupToolRegistrationSelect, SetupToolRegistrationSubjectConfirmationModal } from '+setupTool/containers';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { getDashboardStepList, getErrors, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { Container, PageBlock, RolloutLimiter } from '+shared/components';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { Tutorial } from '+shared/containers/Tutorial';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { isStatusSet } from '+utils/status.util';
import { T } from '@sonnen/shared-i18n/service';
import { Feature, Loadable, useCountryFeature, useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './SetupToolDashboard.component.scss';

const mapStateToProps = (state: StoreState) => ({
  stepList: getDashboardStepList(state),
  submissionId: getSubmissionId(state),
  errors: getErrors(state),
  isVppLabelsForFlatDirect: getVppLabelsForFlatDirect(state),
  lead: getLead(state),
});

const mapDispatchToProps = mapActions({
  getSetupToolSubmissionData: SetupToolActions.getSetupToolSubmissionData,
  getPreCommissioningData: PreCommissioningActions.getPreCommissioningData,
  clearErrors: SetupToolActions.clearErrors,
  clearPreCommissioningGenerationQueryStatus: PreCommissioningActions.clearPreCommissioningGenerationQueryStatus,
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const SetupToolDashboardComponent: React.FC<Props> = ({
  stepList,
  submissionId,
  errors,
  actions,
  lead,
  isVppLabelsForFlatDirect,
}) => {
  const isRolloutEnabled = useFeature(FeatureName.ROLLOUT).isEnabled;
  const {
    isEnabled: isSetupPhaseEnabled,
    isDisabled: isSetupPhaseDisabled,
  } = useCountryFeature(CountryFeatureName.SETUP_PHASE);

  const isLoading = isRolloutEnabled && isSetupPhaseDisabled ? false : !submissionId;
  const isLeadInSetup = isStatusSet(lead?.status.summary.inSetup);

  React.useEffect(() => {
    if (!lead) {
      return;
    }

    const isLeadAllowedToDoPrecomissioningInLeadPhase =
      !isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)
      && isStatusSet(lead?.status.summary.hardwareOrderConfirmed);

    if (isLeadInSetup || isLeadAllowedToDoPrecomissioningInLeadPhase) {
      actions.getPreCommissioningData(lead.id);
    }

    if (isRolloutEnabled) {
      if (isSetupPhaseEnabled) {
        actions.getSetupToolSubmissionData(lead.id);
      }
    } else {
      actions.getSetupToolSubmissionData(lead.id);
    }

    return () => {
      actions.clearErrors();
      actions.clearPreCommissioningGenerationQueryStatus();
    };
  }, []);

  return (
    <Container>
      {!submissionId && <ErrorBox errors={errors} />}

      <Loadable predicate={isLoading}>
        <Tutorial id={'setupConfigurator'} />
        <PageBlock>
          {!isLeadInSetup ?
            isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)
              ? (
                <SetupToolInfo contentText={T.setupTool.infoBox.leadSectionFunctionalities} />
              )
              : (
                <SetupToolInfo contentText={T.setupTool.infoBox.leadSectionFunctionalitiesNonSetup} />
              )
            : null
          }

          <ErrorBox errors={errors} />

          <SetupToolTable
            headline={I18n.t(T.setupTool.dashboard.preCommissioningTools)}
            description={I18n.t(T.setupTool.dashboard.preCommissioningToolsDescription)}
            isH1Headline={false}
            isCollapsed={isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)
              ? !isLeadInSetup
              : false}
            items={[stepList.preCommissioningConfig!]}
          />

          <RolloutLimiter countryFeatureName={CountryFeatureName.SETUP_PHASE}>
            <SetupToolTable
              headline={I18n.t(T.setupTool.dashboard.registrationTools)}
              description={I18n.t(T.setupTool.dashboard.registrationToolsDescription)}
              isH1Headline={true}
              additionalHeaderContent={<SetupToolRegistrationSelect />}
              items={[
                stepList.setupDataConfig!,
                stepList.dsoRegistrationConfig!,
                stepList.dsoCommissioningConfig!,
                stepList.pvRegisterConfig!,
              ]}
            />

            {stepList.vppConfig && stepList.vppConfig.disabled && (
              <SetupToolInfo contentText={T.setupTool.infoBox.vppFunctionalities} hasCloseButton={false} />
            )}

            <SetupToolTable
              headline={
                isVppLabelsForFlatDirect
                  ? I18n.t(T.setupTool.dashboard.sonnenFlatToolsFlatDirect)
                  : I18n.t(T.setupTool.dashboard.sonnenFlatTools)
              }
              description={
                isVppLabelsForFlatDirect
                  ? I18n.t(T.setupTool.dashboard.sonnenFlatToolsDescriptionFlatDirect)
                  : I18n.t(T.setupTool.dashboard.sonnenFlatToolsDescription)
              }
              isH1Headline={false}
              items={[stepList.vppConfig!]}
            />
          </RolloutLimiter>
        </PageBlock>

        <RolloutLimiter countryFeatureName={CountryFeatureName.SETUP_PHASE}>
          <SetupToolRegistrationSubjectConfirmationModal />
        </RolloutLimiter>
      </Loadable>
    </Container>
  );
};

export const SetupToolDashboard = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupToolDashboardComponent);
