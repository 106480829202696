import { visibleBatterySystemsStatuses } from '+shared/store/battery/battery.helpers';
import { Battery } from '+shared/store/battery/types/battery.interface';
import { Address, Customer } from '+shared/store/customer';
import { Site } from '+shared/store/customer/types/customerSite.type';
import { trim } from '+utils/string.util';
import { isNil } from 'lodash';
import { filter, flow, head } from 'lodash/fp';

export const getAddress = (address: Address): string =>
  (address.city && address.street)
    ? `${address.city}, ${address.street}`
    : (address.street)
      ? `${address.street}`
      : '';

export const getFullAddress = ({ street, postalCode, city }: Site): string =>
  (street && (postalCode || city))
    ? `${street}\n${postalCode || ''} ${city || ''}`
    : '';

export const getInlineAddress = ({ postalCode, city, street }: Site | Customer): string =>
  (!(postalCode || city) && street)
    ? trim`${street && `${street}`}`
    : trim`${postalCode}${city && ` ${city}`}${street && `, ${street}`}`;

export const getPrimaryBatterySystem = ({ batterySystems }: Site) =>
  flow(
    filter((battery: Battery) => visibleBatterySystemsStatuses.includes(battery.assetStatus)),
    head,
  )(batterySystems);

export const getPrimaryContract = ({ contracts }: Site) =>
  !isNil(contracts) && contracts[0];

export const getPrimaryEmail = (customer: Customer): string | null => 
  !isNil(customer.personEmail) 
    ? customer.personEmail 
    : !isNil(customer.accountEmail) 
      ? customer.accountEmail
      : customer.email;
