import { LeadConfigurationActionBox } from '+app/+lead/components/LeadConfigurationActionBox';
import { ActionBoxColors } from '+app/+lead/store/types';
import { RoundButton } from '+shared/components';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { DocumentStatus, LeadImpactAnalysisResponse } from '+shared/store/lead/types';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon, WarningText } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { get, last } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationTileAction } from '../../../components/LeadConfigurationTileAction';

import './LeadImpactAnalysisTile.component.scss';

interface LeadImpactAnalysisTileProps {
  impactAnalysis?: LeadImpactAnalysisResponse;
  isSendable: boolean;
  isDisabled: boolean;
  isOfferAccepted: boolean;
  warningText?: string;
  onStart?: () => void;
  onDownload?: (event: React.MouseEvent) => void;
  onDelete?: () => void;
  onSend?: () => void;
}

export const LeadImpactAnalysisTile: React.FC<LeadImpactAnalysisTileProps> = ({
  onStart,
  onDelete,
  onDownload,
  onSend,
  impactAnalysis,
  warningText,
  isSendable,
  isDisabled,
  isOfferAccepted,
}) => {
  const handleOnClick = () => {
    if (!impactAnalysis && onStart) {
      onStart();
    }
  };

  const isImpactAnalysisSent = get(impactAnalysis, 'status') === 'sent';
  const isImpactAnalysisDone = isImpactAnalysisSent && isOfferAccepted;
  const hasValidDocument = get(last(impactAnalysis && impactAnalysis.documents), 'status') !== DocumentStatus.FAILED;
  const hasLeftBorder = !isImpactAnalysisDone && !isDisabled && !impactAnalysis;
  const { track } = useTracking();

  const onSendButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (onSend) {
      track(InteractionEvents.buttonClick({
        category: EventCategory.IMPACT_ANALYSIS,
        action: 'Send Impact Analysis',
        event,
      }));
      onSend();
    }
  };

  const renderSubtitle = () => (
    impactAnalysis ? (
      `${I18n.t(
        T.lead.impactAnalysis._salessolution_.status.created,
      )} ${formatDate(impactAnalysis.createdAt)}`
    ) : (I18n.t(T.lead.impactAnalysis._salessolution_.subtitle))
  );

  return (
    <LeadConfigurationActionBox
      onClickAction={handleOnClick}
      mainColor={ActionBoxColors.BLUE}
      hoverable={!impactAnalysis}
      hasLeftBorder={hasLeftBorder}
      disabled={isDisabled}
    >
      <div className={'c-lead-impact-analysis-tile'} >
        <div>
          <div className={classNames('c-lead-impact-analysis-tile__header', {
            'c-lead-impact-analysis-tile__header--gray': isDisabled,
            'c-lead-impact-analysis-tile__header--blue': !isDisabled && !impactAnalysis,
          })}
          >
            {I18n.t(T.lead.impactAnalysis._salessolution_.title)}
          </div>
        </div>
        <div className={'c-lead-impact-analysis-tile__action-container'}>
          {impactAnalysis ? (
            !isDisabled &&
            <>
              <LeadConfigurationTileAction
                action={onDownload}
                icon={<Icon.Download />}
              />
              <LeadConfigurationTileAction
                action={onDelete}
                isDisabled={isImpactAnalysisSent}
                icon={<Icon.Trash />}
                hasConfirmationPopup={true}
              />
            </>
          ) : (
              <RoundButton
                label={<Icon.Plus className={'c-lead-impact-analysis-tile__start-icon'} />}
                color={isDisabled ? 'inactive' : 'info'}
              />
            )}
        </div>
      </div>
      <div className={'c-lead-impact-analysis-tile__body'}>
        <p className={classNames('c-lead-impact-analysis-tile__body-text', {
          'c-lead-impact-analysis-tile__body-text--gray': isDisabled,
        })}>
          {renderSubtitle()}
        </p>
        {isImpactAnalysisSent && (
          <div className={'c-lead-impact-analysis-tile__body-status-wrapper'}>
            {isImpactAnalysisDone ?
              <StatusTile
                label={I18n.t(T.lead.impactAnalysis._salessolution_.status.offerAccepted)}
                color={StatusTileColors.GREEN}
                noMargin={true}
              />
              :
              <StatusTile
                label={I18n.t(T.lead.impactAnalysis._salessolution_.status.send)}
                color={StatusTileColors.YELLOW}
                noMargin={true}
              />
            }
          </div>
        )}
      </div>
      {!isDisabled && impactAnalysis && (
        <div>
          {impactAnalysis.status === 'created' && isSendable && hasValidDocument && (
            <div className={'c-lead-impact-analysis-tile__footer-action'}>
              <Button
                theme={ButtonTheme.OUTLINE}
                label={I18n.t(T.lead.impactAnalysis._salessolution_.action.send)}
                size={ButtonSize.NORMAL}
                onClick={onSendButtonClick}
              />
            </div>
          )}
          <div className={'c-lead-impact-analysis-tile__footer-status'}>
            {!isSendable && (
              I18n.t(T.lead.impactAnalysis._salessolution_.offerSentRequired)
            )}
            {!hasValidDocument && (
              I18n.t(T.lead.impactAnalysis._salessolution_.noDocumentsError)
            )}
          </div>
        </div>
      )}
      {warningText &&
        <div className={'c-lead-impact-analysis-tile__vpp-warning'}>
          <WarningText text={warningText} />
        </div>
      }
    </LeadConfigurationActionBox>
  );
};
