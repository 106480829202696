import { mapActions } from '+app/utils';
import { CaseReportSearchSite } from '+shared/components/CaseReportSearchSite';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { EventCategory, FormName } from '+shared/GoogleTagManager';
import { 
  CaseActions,
  getCaseActiveSite,
  getCaseSubmitQueryStatus,
  getCreatedCaseNumber,
} from '+shared/store/case';
import { ContractActions } from '+shared/store/contract';
import { CustomerActions } from '+shared/store/customer';
import { getCustomerList, getCustomerListQueryStatus } from '+shared/store/customer/customer.selectors';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileSalesforceContactId } from '+shared/store/user/user.selectors';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton, Modal } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { 
  CaseReportError,
  CaseReportForm,
  CaseReportSite,
  CaseReportSuccess,
} from '../../components';
import { CaseReportHelper } from './CaseReport.helpers';

const mapStateToProps = (state: StoreState) => ({
  activeSite: getCaseActiveSite(state),
  caseSubmitQueryStatus: getCaseSubmitQueryStatus(state),
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  partnerContactId: getUserProfileSalesforceContactId(state),
  caseNumber: getCreatedCaseNumber(state),
  customerList: getCustomerList(state),
  customerListQueryStatus: getCustomerListQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getContract: ContractActions.getContract,
  createCase: CaseActions.createCase,
  resetCaseSubmit: CaseActions.clearCreatedCase,
  toggleModal: LayoutActions.toggleModal,
  setActiveSite: CaseActions.setCaseReportActiveSite,
  getCustomerList: CustomerActions.getCustomerList,
});

interface ComponentProps {
  canEditSite?: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const CaseReportComponent: React.FC<Props> = React.memo(({
  actions,
  caseSubmitQueryStatus,
  openModalId,
  activeSite,
  partnerContactId,
  caseNumber,
  customerList,
  customerListQueryStatus,
  canEditSite,
}) => {
  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);
  const siteList = React.useMemo(() => CaseReportHelper.getCaseSiteList(customerList), [customerList]);

  React.useEffect(() => {
    if (caseSubmitQueryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }

    if (caseSubmitQueryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }

    if (!caseSubmitQueryStatus.error && 
      !caseSubmitQueryStatus.success && 
      !caseSubmitQueryStatus.pending &&
      caseSubmitQueryStatus.notReady) {
      setFormState(FormState.FORM);
    }
  }, [caseSubmitQueryStatus]);

  const onClose = () => {
    actions.resetCaseSubmit();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
    actions.setActiveSite(); 
  };

  const onMore = () => {
    actions.resetCaseSubmit();
    setFormState(FormState.FORM);
  };

  const onTryAgain = () => {
    actions.resetCaseSubmit();
    setFormState(FormState.FORM);
  };

  const gtmProps = {
    category: EventCategory.REPORT_CASE,
    formName: FormName.REPORT_CASE,
    queryStatus: caseSubmitQueryStatus,
  };

  return (
    <Modal
      isOpen={openModalId === ModalId.CASE_REPORT}
      onClose={onClose}
    >
      {{[FormState.FORM]:
          <CaseReportForm
            activeSite={activeSite}
            isLoading={caseSubmitQueryStatus.pending}
            submitCaseForm={actions.createCase}
            partnerContactId={partnerContactId}
            siteComponent={
              activeSite ? (
                <CaseReportSite
                  address={activeSite.address}
                  name={activeSite.name}
                  batterySerialNumber={activeSite.battery?.serialNumber}
                  contractNumber={activeSite.contract?.number}
                  // TODO translations
                  button={canEditSite
                    ? <LinkButton onClick={() => actions.setActiveSite()}>
                        {I18n.t(T.report.site.editButton.label)}
                      </LinkButton>
                    : undefined
                  }
                />
              ) : (
                <CaseReportSearchSite 
                  getCustomerList={actions.getCustomerList}
                  siteList={siteList}
                  customerListQueryStatus={customerListQueryStatus}
                  setActiveSite={actions.setActiveSite}
                  activeSite={activeSite}
                />
              ) 
            }
          />,
        [FormState.FORM_ERROR]: 
          <CaseReportError
            onTryAgain={onTryAgain}
            gtm={gtmProps}
          />,
        [FormState.FORM_SUCCESS]: 
          <CaseReportSuccess 
            caseNumber={caseNumber}
            onClose={onClose}
            onMore={onMore}
            gtm={gtmProps}
          />,
      }[formState]}
    </Modal>
  );
});

export const CaseReport = connect(mapStateToProps, mapDispatchToProps)(CaseReportComponent);
