import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { RestrictedToRoles } from '+shared/components/RestrictedToRoles';
import { companyTabAllowedRoles, UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { PATHS } from '../../../router';

import './AccountHeader.component.scss';

interface Props {
  userRoles: UserRole[];
}

const renderTabNav = (userRoles: UserRole[]) => {
  const navLinkProps = { border: false, type: 'desktop' as NavLinkType, faded: true };

  return (
    <TabNav
      items={(
        <>
          <NavLink {...navLinkProps} action={PATHS.ACCOUNT()}>
            <Icon.Installer className={'c-account-header__icon'}/>
            {I18n.t(T.myAccount.tabTitle)}
          </NavLink>
          <RestrictedToRoles
            allowedRoles={companyTabAllowedRoles}
            userRoles={userRoles}
          >
            <NavLink {...navLinkProps} action={PATHS.ACCOUNT_COMPANY()}>
              <Icon.CompanyCoordinator className={'c-account-header__icon'}/>
              {I18n.t(T.companyAccount.tabTitle)}
            </NavLink>
          </RestrictedToRoles>
        </>
      )}
    />
  );
};

export const AccountHeader: React.FC<Props> = ({ userRoles }) =>
  <PageHeader
    className={'c-account-header'}
    tabNav={renderTabNav(userRoles)}
    isExpanded={true}
  >
    <h1 className={'c-account-header__title'}>
      {I18n.t(T.myAccount.title)}
    </h1>
  </PageHeader>;
