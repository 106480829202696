import { Lead } from '+shared/store/lead/types';
import * as React from 'react';
import Media from 'react-media';

import './LeadListName.component.scss';

interface Props {
  item: Lead;
}

export const LeadListName: React.FC<Props> = ({ item }) => {
  return (
    <div className={`c-lead-list-name`}>
      <Media query={{ minWidth: 576 }}>
        {(isMedium: boolean) => item.company ? (
          <>
            <p className={`c-lead-list-name__paragraph`} data-hj-suppress={true}>
              {item.company.name}
            </p>
            <p className={`c-lead-list-name__paragraph`} data-hj-suppress={true}>
              {item.lastName} {item.firstName}
            </p>
          </>
        ) : (
            isMedium ? (
              <>
                <p className={`c-lead-list-name__paragraph`} data-hj-suppress={true}>
                  {item.lastName}
                </p>
                <p className={`c-lead-list-name__paragraph`} data-hj-suppress={true}>
                  {item.firstName}
                </p>
              </>
            ) : (
                <p className={`c-lead-list-name__paragraph`} data-hj-suppress={true}>
                  {item.lastName} {item.firstName}
                </p>
              )
          )}
      </Media>
    </div>
  );
};
