
import * as classNames from 'classnames';
import * as React from 'react';

import './LeadConfigurationLine.component.scss';

export enum LeadConfigurationLineType {
  LIGHT = 'light',
  BOLD = 'bold',
  LARGE = 'large',
}

export const LeadConfigurationLine: React.FC<{ title: string, value: string, type: LeadConfigurationLineType }> = ({
  title,
  value,
  type,
}) => (
  <div
    className={classNames('c-lead-configuration-line', {
      [`c-lead-configuration-line--${type}`]: type,
    })}
  >
    <p className={'c-lead-configuration-line__title'}>
      {title}
    </p>
    <p className={'c-lead-configuration-line__value'}>
      {value}
    </p>
  </div>
);
