import {
  CustomerAnalysisWidget,
  CustomerBatteryWidget,
  CustomerCasesWidget,
  CustomerChargerWidget,
  CustomerContractWidget,
  CustomerDetailsWidget,
  CustomerNoteWidget,
  CustomerOldPortalWidget,
  CustomerPvSystemWidget,
} from '+app/+customer/+overview/components';
import { CustomerWarnings } from '+app/+customer/components';
import { getAssetsAvailability, getMeterInstallation, getSite } from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { CustomerRouteParams, PATHS } from '+app/router';
import {
  getSiteConsumedEnergyFormated,
  getSiteGridPurchasedEnergyFormated,
} from '+app/shared/store/site/site.selectors';
import { mapActions } from '+app/utils';
import { CountryFeatureName } from '+config/countryFlags';
import {
  getBattery,
  getBatteryElectricUnits,
  getBatteryElectricUnitsQueryStatus,
  getBatteryQueryStatus,
} from '+customer-battery/store/+battery.selectors';
import {
  getContract,
  getContractQueryStatus,
} from '+customer-contract/store/+contract.selectors';
import { getCharger, getChargersQueryStatus } from '+customer-overview/store/+overview.selector';
import { getCaseCollection } from '+shared/store/case';
import {
  getBatteryWarnings,
  getContractWarnings,
  getOnlyDangerWarnings,
  getWarningMessages,
} from '+shared/store/customer/warnings/warnings.factory';
import { getCombinedStatus } from '+shared/store/query/query.utils';
import { getSiteLiveState, getSiteLiveStateQueryStatus } from '+shared/store/site/site.selectors';
import { StoreState } from '+shared/store/store.interface';
import { useCountryFeature } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { compose } from 'lodash/fp';
import * as React from 'react';
import * as Masonry from 'react-masonry-component';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import './CustomerOverview.component.scss';

type RouteProps = RouteComponentProps<CustomerRouteParams>;

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const meterInstallation = getMeterInstallation(site);
  const cases = getCaseCollection(state);
  const assetsAvailability = getAssetsAvailability(site, state);
  const battery = getBattery(state);
  const siteLiveState = getSiteLiveState(state);
  const batteryElectricUnits = getBatteryElectricUnits(state);
  const batteryQueryStatus = getCombinedStatus([
    getBatteryQueryStatus(state),
    getSiteLiveStateQueryStatus(state),
    getBatteryElectricUnitsQueryStatus(state),
  ]);
  const contract = getContract(state);
  const contractQueryStatus = getContractQueryStatus(state);
  const charger = getCharger(state);
  const chargersQueryStatus = getChargersQueryStatus(state);
  const consumedEnergy = getSiteConsumedEnergyFormated(state);
  const gridPurchaseEnergy = getSiteGridPurchasedEnergyFormated(state);

  return {
    assetsAvailability,
    contract,
    contractQueryStatus,
    customer,
    site,
    battery,
    siteLiveState,
    batteryQueryStatus,
    cases,
    charger,
    chargersQueryStatus,
    consumedEnergy,
    gridPurchaseEnergy,
    batteryElectricUnits,
    meterInstallation,
  };
};

type Location =
  | 'battery'
  | 'analysis'
  | 'contract'
  | 'cases'
  ;

const mapDispatchToProps = mapActions({
  goTo: (params: CustomerRouteParams, location: Location) => ({
    battery: push(PATHS.CUSTOMER_BATTERY(params)),
    analysis: push(PATHS.CUSTOMER_ANALYSIS(params)),
    contract: push(PATHS.CUSTOMER_CONTRACT(params)),
    cases: push(PATHS.CUSTOMER_CASES(params)),
  })[location],
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteProps
  ;

class CustomerOverviewComponent extends React.PureComponent<Props> {
  getWidgets = ({ actions, ...props }: Props) => {
    const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

    const {
      battery,
      siteLiveState,
      batteryElectricUnits,
      batteryQueryStatus,
      cases,
      contract,
      contractQueryStatus,
      customer,
      site,
      assetsAvailability,
      charger,
      chargersQueryStatus,
      meterInstallation,
    } = props;

    const routeParams: CustomerRouteParams = {
      customerId: customer ? customer.id : '',
      siteId: site ? site.id : '',
    };

    return ({
      details: customer && site && (
        <CustomerDetailsWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-details'}
          customer={customer}
          site={site}
        />
      ),
      battery: assetsAvailability.hasBattery && (
        <CustomerBatteryWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-battery'}
          battery={battery}
          siteLiveState={siteLiveState!}
          batteryElectricUnits={batteryElectricUnits}
          queryStatus={batteryQueryStatus}
          action={() => actions.goTo(routeParams, 'battery')}
        />
      ),
      analysis: assetsAvailability.hasBattery && (
        <CustomerAnalysisWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-analysis'}
          siteLiveState={siteLiveState!}
          queryStatus={batteryQueryStatus}
          action={() => actions.goTo(routeParams, 'analysis')}
        />
      ),
      pvSystem: assetsAvailability.hasPvSystem && (
        <CustomerPvSystemWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-pv-system'}
          battery={battery}
          queryStatus={batteryQueryStatus}
        />
      ),
      contract: assetsAvailability.hasContract && isCustomerContractFeatureEnabled && (
        <CustomerContractWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-contract'}
          contract={contract}
          meterInstallation={meterInstallation}
          queryStatus={contractQueryStatus}
          action={() => actions.goTo(routeParams, 'contract')}
        />
      ),
      portal: (
        <CustomerOldPortalWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-old-portal'}
          batterySerialNumber={battery?.serialNumber}
        />
      ),
      cases: !!cases.length && (
        <CustomerCasesWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-cases'}
          cases={cases}
          action={() => actions.goTo(routeParams, 'cases')}
        />
      ),
      charger: assetsAvailability.hasCharger && charger && (
        <CustomerChargerWidget
          className={'c-customer-overview__grid-item'}
          key={'customer-charger'}
          charger={charger}
          queryStatus={chargersQueryStatus}
        />
      ),
      note: customer && site && (
        <div
          className={'c-customer-overview__grid-item'}
          key={'customer-note'}
        >
          <CustomerNoteWidget
            customerId={customer.id}
            customerNote={customer.installerNote || ''}
          />
        </div>
      ),
    });
  };

  render() {
    const { contract, battery, siteLiveState, site, meterInstallation } = this.props;
    const batteryWarnings = compose(getWarningMessages, getBatteryWarnings)({ battery, siteLiveState });
    const contractWarnings = compose(getOnlyDangerWarnings, getWarningMessages, getContractWarnings)
      ({ contract, meterInstallation });
    const warnings = [
      ...batteryWarnings,
      ...contractWarnings,
    ];

    const widgets = this.getWidgets(this.props);
    const orderedWidgets = [
      widgets.details,
      widgets.battery,
      widgets.contract,
      widgets.portal,
      widgets.cases,
      widgets.charger,
      widgets.pvSystem,
      widgets.analysis,
      widgets.note,
    ].sort();

    return (
      <>
        <CustomerWarnings warnings={warnings} />
        <Masonry
          className={'c-customer-overview__grid'}
          elementType={'div'}
          options={{
            percentPosition: true,
            columnWidth: '.c-customer-overview__grid-item ',
            transitionDuration: 0,
          }}
        >
          <div className={'c-customer-overview__grid-item '} />
          {orderedWidgets}
        </Masonry>
      </>
    );
  }
}

export const CustomerOverview = connect(mapStateToProps, mapDispatchToProps)(CustomerOverviewComponent);
