import { PATHS } from '+app/router';
import { NavLink, NavLinkType, PageHeader, TabNav } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, LinkButton } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadOverviewHeaderWrapper.component.scss';

interface Props {
  lead: Lead;
  breadcrumbAction: () => void;
  leadStage?: string;
  salesConfigurationWarning?: boolean;
  children: React.ReactNode;
}

const navLinkProps = {
  border: false,
  faded: true,
  type: 'desktop' as NavLinkType,
};

export const LeadOverviewHeaderWrapper: React.FC<Props> =
  ({
    lead,
    leadStage,
    breadcrumbAction,
    salesConfigurationWarning,
    children,
  }) => {

    return (
      <>
        <PageHeader
          tabNav={
            <TabNav
              items={(
                <>
                  <NavLink {...navLinkProps} action={PATHS.LEAD_OVERVIEW({ leadId: lead.id }, leadStage)}>
                    <Icon.Account className={'c-lead-overview-nav-icon'} />
                    {I18n.t(T.lead.overview._salessolution_.header.leadData)}
                  </NavLink>
                  <NavLink {...navLinkProps} action={PATHS.LEAD_CONFIGURATION({ leadId: lead.id }, leadStage)}>
                    <Icon.Calculation className={'c-lead-overview-nav-icon'} />
                    {I18n.t(T.lead.overview._salessolution_.header.salesConfiguration)}
                    {salesConfigurationWarning &&
                      <Icon.ExclamationMarkCircle className={'c-lead-overview-nav__exclamation-icon'} />
                    }
                  </NavLink>
                  <NavLink {...navLinkProps} action={PATHS.SETUP_TOOL({ leadId: lead.id }, leadStage)}>
                    <Icon.SetupTools className={'c-lead-overview-nav-icon'} />
                    {I18n.t(T.setupTool.setupConfiguration)}
                  </NavLink>
                </>
              )}
            />
          }
          breadcrumb={(
            <LinkButton
              onClick={breadcrumbAction}
              icon={<Icon.Angle />}
            >
              {I18n.t(T.customerSingle.header.breadcrumb)}
            </LinkButton>
          )}
          isExpanded={true}
        >
          {children}
        </PageHeader>
      </>
    );
  };
