import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './NavLinkLabel.component.scss';

export const NavLinkLabel: React.FC = () => (
  <Button
    className={'c-nav-link-label'}
    type={'button'}
    size={ButtonSize.PILL}
    label={I18n.t(T.general.navigation.sonnenDriveLabel)}
    isNotElevatedOnHover={true}
  />
);
