import { getStatus } from '+shared/store/query/query.utils';
import { createSelector } from 'reselect';
import { StoreState } from '../store.interface';
import { getUserName, getUserRoles } from './user.helpers';
import { GET_USER_PROFILE_QUERY, UserRole } from './user.state';

export const getUserState = (state: StoreState) => state.shared.user;

export const getUserProfile = createSelector(
  getUserState,
  state => state.profile,
);

export const getUserCompany = createSelector(
  getUserState,
  state => state.company,
);

export const getUserCompanyName = createSelector(
  getUserCompany,
  company => company ? company.name : '',
);

export const getUserCompanyLogo = createSelector(
  getUserCompany,
  company => company ? company.logotypeUrl : '',
);

export const getUserProfileQuery = createSelector(
  getUserState,
  state => state[GET_USER_PROFILE_QUERY],
);

export const getUserProfileQueryStatus = createSelector(
  getUserProfileQuery,
  query => getStatus(query),
);

export const getUserProfileId = createSelector(
  getUserProfile,
  profile => profile ? profile.id : '',
);

export const getUserProfileCustomerNumber = createSelector(
  getUserProfile,
  profile => profile ? profile.customerNumber : '',
);

export const getUserProfileName = createSelector(
  getUserProfile,
  getUserName,
);

export const getUserProfileRoles = createSelector(
  getUserProfile,
  getUserRoles,
);

export const isSuperUserRole = createSelector(
  getUserProfileRoles,
  roleList => roleList.some(role => role === UserRole.SUPER_USER),
);

export const getUserProfileSalesforceContactId = createSelector(
  getUserProfile,
  profile => profile ? profile.salesforceContactId : '',
);
