import { EnergyUnit } from '+app/App.constants';
import { DetailListType, RoundButton } from '+shared/components';
import { BatteryElectricUnits, BatteryWithProduct } from '+shared/store/battery';
import { getBatteryProductName, isBatteryInStandby } from '+shared/store/battery/battery.helpers';
import { SiteLiveState } from '+shared/store/site/types';
import { formatEnergy, formatPercentage, formatSerialNumber } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, Tooltip } from '@sonnen/shared-web';
import { isEmpty, isNil } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

const SB10_ARTICLE_NUMBER = '3000060';

export const isSB10Battery = (battery: BatteryWithProduct | undefined): boolean =>
  !!(battery && battery.product?.productCode === SB10_ARTICLE_NUMBER);

export const setNumbersHistory = (batteryElectricUnits: BatteryElectricUnits[]) => {
  if (isEmpty(batteryElectricUnits)) {
    return null;
  }

  const batteryElecticUnitsWithoutCurrent = batteryElectricUnits.filter(item => !item.current);
  if (batteryElecticUnitsWithoutCurrent.length <= 0) {
    return null;
  }
  return (
    <>
      {batteryElecticUnitsWithoutCurrent.map(((item, index, array) =>
          (<span key={index}>
              <span className={'c-customer-battery-widget__grayed-out'}>
                {formatSerialNumber(item.serialNumber)}
              </span>
              <Tooltip
                className={'c-customer-battery-widget__tooltip'}
                trigger={
                  <RoundButton
                    className={'c-customer-battery-widget__grayed-out'}
                    color={'inactive'}
                    size={15}
                    label={<Icon.Info />}
                  />
                }
              >
                <span>
                  {I18n.t(T.customerSingle.overview.battery.oldSerialNumbers)}: {item.serialNumber}<br />
                  {I18n.t(T.customerSingle.overview.battery.activationDate)}: : {item.activationDate}
                </span>
              </Tooltip>
              <span className={'c-customer-battery-widget__grayed-out'}>
                {array.length - 1 === index ? '' : ', '}
              </span>
            </span>
          )
        ))
      }
    </>
  );
};

// TODO refactor?
export const factorizeDetailList = (
  battery: BatteryWithProduct,
  siteLiveState: SiteLiveState,
  batteryElectricUnits: BatteryElectricUnits[],
): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.overview.battery.model),
    value: getBatteryProductName(battery.product),
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.serialNumber),
    value: !isNil(battery.serialNumber)
      ? formatSerialNumber(battery.serialNumber)
      : null,
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.oldSerialNumbers),
    value: setNumbersHistory(batteryElectricUnits),
    isGrayedOut: true,
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.capacity),
    value: !isNil(battery.batteryCapacity)
      ? formatEnergy({
        whValue: battery.batteryCapacity,
        unit: EnergyUnit.KWH,
        precision: 1,
        minPrecision: 0,
      })
      : undefined,
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.soc),
    value: !isNil(siteLiveState.batteryUsoc)
      ? formatPercentage(siteLiveState.batteryUsoc)
      : undefined,
    type: 'success',
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.state),
    value: !!siteLiveState.online
      ? isBatteryInStandby(siteLiveState)
        ? I18n.t(T.customerSingle.overview.battery.statusStandby)
        : I18n.t(T.customerSingle.overview.battery.statusOnline)
      : I18n.t(T.customerSingle.overview.battery.statusOffline),
    type: !!siteLiveState.online
      ? 'success'
      : 'warning',
  },
];
