import { matchPath } from '+app/router/store/router.helpers';
import * as classNames from 'classnames';
import { flatten } from 'lodash';
import * as React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { MatchMultiplePaths, NavLinkAction, NavLinkPath } from './NavLink.interface';

import './NavLink.component.scss';

const getActiveClass = (match: MatchMultiplePaths, action: NavLinkPath): ClassValue => {
  const pathsToMatch = match
    ? flatten(match.paths)
    : [action];

  return match
    ? matchPath(pathsToMatch)(match.location.pathname)
      ? 'is-active'
      : undefined
    : 'is-active';
};

export const prepareRenderLink = (classNamePrefix: string) => ({
  children, action, match, onClick,
}: {
  children: React.ReactNode,
  action: NavLinkPath,
  match?: MatchMultiplePaths,
  onClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void,
}) => /^https?:\/\//.test(action) ? (
  <a
    href={action}
    target={'_blank'}
    className={`${classNamePrefix}__action-container`}
    onClick={onClick}
  >
    {children}
  </a>
) : match ? (
  <RouterNavLink
    exact={true}
    className={classNames(
      `${classNamePrefix}__action-container`,
      getActiveClass(match, action),
    )}
    to={action}
  >
    {children}
  </RouterNavLink>
) : (
  <RouterNavLink
    exact={true}
    activeClassName={'is-active'}
    className={`${classNamePrefix}__action-container`}
    to={action}
    onClick={onClick}
  >
    {children}
  </RouterNavLink>
);

export const prepareRenderButton = (classNamePrefix: string) => (children: React.ReactNode, action?: NavLinkAction) =>
  action ? (
    <button
      type={'button'}
      onClick={() => action && action()}
      className={`${classNamePrefix}__action-container`}
    >
      {children}
    </button>
  ) : (
    <div className={`${classNamePrefix}__action-container`}>
      {children}
    </div>
  );

export const classNamePrefix = 'c-nav-link';

export const renderLink = prepareRenderLink(classNamePrefix);
export const renderButton = prepareRenderButton(classNamePrefix);
