import { getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { DsoSummaryFiles } from '+app/+setupTool/+form/components';
import { LEAD_IN_SETUP_STAGE, PATHS } from '+app/router';
import {
  DsoCommissioningActions,
} from '+setupTool/+dsoCommissioning/store/+dsoCommissioning.actions';
import {
  getFormFulfillment,
  getLatestModificationDate,
} from '+setupTool/+form/store/+form.selectors';
import { Overlay } from '+setupTool/components/Overlay';
import { DsoCommissioningDataForm } from '+setupTool/containers/CommissioningDataForm';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { isEmptyValue } from '+setupTool/store/+setupTool.helpers';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import {
  FormErrorBanner,
  FormSectionParagraph,
} from '+shared/components';
import { ActionName, EventCategory, RequestEvents, useTracking } from '+shared/GoogleTagManager';
import { useSuccessTracking } from '+shared/hooks/useStatusTracking';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { formatDate } from '+utils/format.util.old';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, Icofont, Icon, SideInfo } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty, isEqual, omitBy } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY, GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY } from '../../store';
import {
  getDsoCommissioningDocuments,
  getDsoCommissioningFields,
  getDsoCommissioningGenerateDocumentsQuery,
  getDsoCommissioningGenerateDocumentsQueryStatus,
  getDsoCommissioningPatchSubmissionQuery,
  getDsoCommissioningPatchSubmissionQueryStatus,
  getLatestDsoCommissioningDate,
  getLatestDsoCommissioningDocumentsGenerationDate,
} from '../../store/+dsoCommissioning.selectors';
import { getDsoCommissioningInitial, getDsoCommissioningSchema } from '../../store/schemas';
import { DsoCommissioningInterface } from '../../store/types';

import './DsoCommissioning.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  getDsoCommissioningGenerateDocumentsQueryStatus: getDsoCommissioningGenerateDocumentsQueryStatus(state),
  getDsoCommissioningGenerateDocumentsQuery: getDsoCommissioningGenerateDocumentsQuery(state),
  getDsoCommissioningPatchSubmissionQueryStatus: getDsoCommissioningPatchSubmissionQueryStatus(state),
  getDsoCommissioningPatchSubmissionQuery: getDsoCommissioningPatchSubmissionQuery(state),
  documents: getDsoCommissioningDocuments(state),
  latestDsoCommissioningDate: getLatestDsoCommissioningDate(state),
  latestDocumentsGenerationDate: getLatestDsoCommissioningDocumentsGenerationDate(state),
  latestSetupDataFormModification: getLatestModificationDate(state),
  fields: getDsoCommissioningFields(state),
  isSetupDataFulfilled: getFormFulfillment(state),
  registrationSubject: getRegistrationSubject(state),
});

const mapDispatchToProps = mapActions({
  generateDocuments: DsoCommissioningActions.generateDocuments,
  goToSetupDashboard: (leadId: string) => push(PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)),
  goToSetupData: (leadId: string) => push(PATHS.SETUP_TOOL_SETUP_DATA({ leadId }, LEAD_IN_SETUP_STAGE)),
  clearQuery: QueryActions.init,
  toggleModal: LayoutActions.toggleModal,
  saveData: SetupToolActions.saveSubmission,
  saveDataAndGenerateDocuments: DsoCommissioningActions.saveSubmissionAndGenerateDocuments,
  setFields: DsoCommissioningActions.setFields,
  getLead: LeadActions.getLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const DsoCommissioningComponent: React.FC<Props> = ({
  actions,
  leadId,
  getDsoCommissioningGenerateDocumentsQueryStatus,
  getDsoCommissioningGenerateDocumentsQuery,
  getDsoCommissioningPatchSubmissionQueryStatus,
  documents,
  latestDsoCommissioningDate,
  latestDocumentsGenerationDate,
  latestSetupDataFormModification,
  fields,
  isSetupDataFulfilled,
  registrationSubject,
}) => {
  const formRef = React.useRef<FormikProps<DsoCommissioningInterface>>({} as any);
  const validationSchema = getDsoCommissioningSchema(registrationSubject);
  const dsoCommissioningInitial = getDsoCommissioningInitial(registrationSubject);
  const { track } = useTracking();

  React.useEffect(() => {
    if (getDsoCommissioningPatchSubmissionQueryStatus.success) {
      actions.clearQuery(DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY);
    }
  }, [getDsoCommissioningPatchSubmissionQueryStatus]);

  React.useEffect(() => {
    if (leadId && getDsoCommissioningGenerateDocumentsQueryStatus.success) {
      actions.getLead(leadId, LEAD_UPDATE_QUERY);
    }
  }, [getDsoCommissioningGenerateDocumentsQueryStatus]);

  React.useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
      actions.clearQuery(GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY);
      actions.clearQuery(DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY);
    };
  }, []);

  const onSubmit = (values: DsoCommissioningInterface) => {
    if (values && !isEqual(fields, values)) {
      actions.setFields(values);
      actions.saveDataAndGenerateDocuments();
    } else {
      actions.generateDocuments();
    }
  };

  const isFulfilled = latestDocumentsGenerationDate && getDsoCommissioningGenerateDocumentsQueryStatus.success;

  useSuccessTracking(() => {
    if (getDsoCommissioningGenerateDocumentsQueryStatus.success) {
      const requestName = !!latestDocumentsGenerationDate
        ? ActionName.DSO_COMMISSIONING_REGENERATED_DOCUMENTS : ActionName.DSO_COMMISSIONING_GENERATED_DOCUMENTS;
      track(RequestEvents.success({
        category: EventCategory.DSO_COMMISSIONING,
        requestName,
      }));
    }
  }, getDsoCommissioningGenerateDocumentsQueryStatus);

  return (
    <Overlay
      className={'c-setup-tool__dso-summary'}
      title={I18n.t(T.setupTool.step.dsoCommissioning)}
      close={() => {
        actions.goToSetupDashboard(leadId!);

        const values = formRef.current.values;
        const invalidFieldKeys = Object.keys(formRef.current.errors);
        const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
          ({...prev, [fieldKey]: dsoCommissioningInitial[fieldKey]}), {});

        if (!isFulfilled && values && !isEqual(
          omitBy(fields, isEmptyValue),
          omitBy(values, isEmptyValue),
        )) {
          actions.setFields({ ...values, ...invalidOrEmptyValues } as DsoCommissioningInterface);
          actions.saveData(SubmissionStep.DSO_COMMISSIONING_DATA);
        }
      }}
    >
      <div className={'c-overlay__inner-content c-setup-tool__help-content'}>

        {/* todo unify SideInfo for register and commissioning*/}

        <SideInfo className={'c-help__side-info'}>
          <div className={`c-help__side-info-icon`}><Icon.Info /></div>
          <div>
            <h3>{I18n.t(T.setupTool.dsoCommissioning.help.header)}</h3>
            <p>
              <Translate value={T.setupTool.dsoCommissioning.help.text} dangerousHTML={true} />
            </p>
            <br />
            <a onClick={() => actions.goToSetupData(leadId!)}>
              {I18n.t(T.setupTool.dsoCommissioning.help.link)}
            </a>
          </div>
        </SideInfo>
      </div>
      <div className={'c-dso-commissioning-form__container'}>
        <Formik
          initialValues={dsoCommissioningInitial}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={true}
          innerRef={formRef}
          onSubmit={onSubmit}
          render={form => {
            return (
            <Form>
              <DsoCommissioningDataForm form={form} />
              <div className={'c-dso-commissioning-form__submit-button-container'}>
                <Button
                  type={'submit'}
                  label={!!latestDocumentsGenerationDate
                    ? I18n.t(T.setupTool.dsoCommissioning.regenerateDocuments)
                    : I18n.t(T.setupTool.dsoCommissioning.generateDocuments)}
                  isLoading={
                    getDsoCommissioningPatchSubmissionQueryStatus.pending ||
                    getDsoCommissioningGenerateDocumentsQueryStatus.pending
                  }
                  isDisabled={
                    !form.isValid || !isSetupDataFulfilled || isEmpty(omitBy(form.values, isEmptyValue))
                    || (
                      !!latestDocumentsGenerationDate
                      && moment(latestSetupDataFormModification).isBefore(latestDocumentsGenerationDate)
                      && moment(latestDsoCommissioningDate).isBefore(latestDocumentsGenerationDate)
                      && isEqual(omitBy(fields, isEmpty), omitBy(form.values, isEmptyValue))
                    )
                  }
                />
              </div>
            </Form>
          );
        }}
        />
      </div>
      <div
        className={'c-overlay__inner-content'}
      >
        {isFulfilled && <>
          <FormSectionParagraph>
            {I18n.t(T.setupTool.dsoCommissioning.documentsHeader)}
            {getDsoCommissioningGenerateDocumentsQueryStatus.success
              && <span className={'c-setup-tool__dso-summary-status'}>
              <Icofont type={'tick'} /> {I18n.t(T.setupTool.dsoCommissioning.status.success)}
            </span>}
          </FormSectionParagraph>
          <h4>{I18n.t(T.setupTool.dsoCommissioning.createdAt)} {formatDate(latestDocumentsGenerationDate)}</h4>
        </>}

        {getDsoCommissioningPatchSubmissionQueryStatus.error &&
          <FormErrorBanner
            isVisible={getDsoCommissioningPatchSubmissionQueryStatus.error}
            error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)} // TODO custom error message?
          />
        }

        {getDsoCommissioningGenerateDocumentsQueryStatus.error &&
          <FormErrorBanner
            isVisible={getDsoCommissioningGenerateDocumentsQueryStatus.error}
            error={I18n.t(T.setupTool.dsoCommissioning.status.error)}
          />
        }

        {isFulfilled && <>
          <DsoSummaryFiles
            documents={documents}
            pending={getDsoCommissioningGenerateDocumentsQueryStatus.pending}
          />

          <div className={'c-dso-registration-summary__help'}>
            <Icon.Info className={'c-dso-registration-summary__help-icon'} />
            <p>
              {I18n.t(T.setupTool.dsoCommissioning.help.textBottom)}
            </p>
          </div>
        </>}

      </div>
    </Overlay>
  );
};

export const DsoCommissioning = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoCommissioningComponent);
