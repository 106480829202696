import { goTo } from '+app/utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, LinkButton, Status, StatusType } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CertificateWarning.component.scss';

const renderIcon = () => (
  <Icofont
    className={'c-certificate-warning__icon'}
    type={'warning'}
  />
);

export const CertificateWarning: React.FC = () => (
  <div className={'c-certificate-warning'}>
    <Status
      icon={renderIcon()}
      hasDot={false}
      type={StatusType.DANGER}
      label={I18n.t(T.myAccount.certification.warning)}
    />
    <LinkButton
      className={'c-certificate-warning__link-button'}
      onClick={() => goTo(I18n.t(T.myAccount.certification.link), '_blank')}
    >
      {I18n.t(T.myAccount.certification.warningAction)}
    </LinkButton>
  </div>
);
