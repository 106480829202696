import { getLeadId } from '+app/+lead/+overview/store/+overview.selectors';
import { LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { LEAD_IN_SETUP_STAGE, PATHS } from '+app/router';
import { DsoSummaryFiles } from '+setupTool/+form/components';
import { DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY } from '+setupTool/+form/store';
import { getLatestModificationDate } from '+setupTool/+form/store/+form.selectors';
import { Overlay, Ref } from '+setupTool/components/Overlay';
import { FormErrorBanner, FormSectionParagraph } from '+shared/components';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { LayoutActions } from '+shared/store/layout';
import { LeadActions } from '+shared/store/lead';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { formatDate } from '+utils/format.util.old';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, Icofont, Icon, SideInfo } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { GENERATE_DOCUMENTS_QUERY } from '../../store';
import { DsoRegistrationActions } from '../../store/+dsoRegistration.actions';
import {
  getDsoGenerateDocumentsQuery,
  getDsoGenerateDocumentsQueryStatus,
  getDsoRegistrationDocuments,
  getLatestDocumentsGenerationDate,
} from '../../store/+dsoRegistration.selectors';

import './DsoRegistration.component.scss';

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  getDsoGenerateDocumentsQueryStatus: getDsoGenerateDocumentsQueryStatus(state),
  getDsoGenerateDocumentsQuery: getDsoGenerateDocumentsQuery(state),
  documents: getDsoRegistrationDocuments(state),
  latestDocumentsGenerationDate: getLatestDocumentsGenerationDate(state),
  latestFormModification: getLatestModificationDate(state),
});

const mapDispatchToProps = mapActions({
  generateDocuments: DsoRegistrationActions.generateDocuments,
  goToSetupDashboard: (leadId: string) => push(PATHS.SETUP_TOOL({ leadId }, LEAD_IN_SETUP_STAGE)),
  goToSetupData: (leadId: string) => push(PATHS.SETUP_TOOL_SETUP_DATA({ leadId }, LEAD_IN_SETUP_STAGE)),
  clearQuery: QueryActions.init,
  toggleModal: LayoutActions.toggleModal,
  getLead: LeadActions.getLead,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const DsoRegistrationComponent: React.FC<Props> = ({
  actions,
  leadId,
  getDsoGenerateDocumentsQueryStatus,
  getDsoGenerateDocumentsQuery,
  documents,
  latestDocumentsGenerationDate,
  latestFormModification,
}) => {
  const overlayRef = React.useRef<Ref>(null);
  const canGenerateDocuments = getDsoGenerateDocumentsQueryStatus.success
    || (documents.length && moment(latestFormModification).isBefore(latestDocumentsGenerationDate));

  const { track } = useTracking();

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    actions.generateDocuments();

    const isDocumentRegenerated = moment(latestDocumentsGenerationDate).isBefore(latestFormModification);

    track(InteractionEvents.buttonClick({
      category: EventCategory.DSO_REGISTRATION,
      action: isDocumentRegenerated ? ActionName.DSO_REGENERATED_DOCUMENTS : ActionName.DSO_GENERATED_DOCUMENTS,
      event,
    }));
  };

  React.useEffect(() => {
    if (getDsoGenerateDocumentsQueryStatus.success) {
      actions.clearQuery(DSO_REGISTRATION_FORM_CONFIG_SUBMIT_QUERY);
      if (leadId) { actions.getLead(leadId, LEAD_UPDATE_QUERY); }
    }
  }, [getDsoGenerateDocumentsQueryStatus]);

  React.useEffect(() => {
    actions.toggleModal(true);
    return () => {
      actions.toggleModal(false);
      actions.clearQuery(GENERATE_DOCUMENTS_QUERY);
    };
  }, []);

  return (
    <Overlay
      className={'c-setup-tool__dso-summary'}
      title={I18n.t(T.setupTool.step.dsoRegistration)}
      close={() => actions.goToSetupDashboard(leadId!)}
      ref={overlayRef}
    >
      <div className={'c-overlay__inner-content c-setup-tool__help-content'}>
        <SideInfo className={'c-help__side-info'}>
          <div className={`c-help__side-info-icon`}><Icon.Info /></div>
          <div>
            <h3>{I18n.t(T.setupTool.dsoRegistration.help.header)}</h3>
            <p>
              <Translate value={T.setupTool.dsoRegistration.help.text} dangerousHTML={true} />
            </p>
            <br />
            <a onClick={() => actions.goToSetupData(leadId!)}>
              {I18n.t(T.setupTool.dsoRegistration.help.link)}
            </a>
          </div>
        </SideInfo>
        {!canGenerateDocuments && <div>
          <Button
            onClick={onButtonClick}
            label={moment(latestDocumentsGenerationDate).isBefore(latestFormModification)
              ? I18n.t(T.setupTool.dsoRegistration.regenerateDocuments)
              : I18n.t(T.setupTool.dsoRegistration.generateDocuments)
            }
            isLoading={getDsoGenerateDocumentsQueryStatus.pending}
          />
        </div>}
        {getDsoGenerateDocumentsQueryStatus.error &&
          <FormErrorBanner
            isVisible={getDsoGenerateDocumentsQueryStatus.error}
            error={I18n.t(T.setupTool.dsoRegistration.status.error)}
          />
        }
      </div>
      {!!canGenerateDocuments && <div
        className={'c-overlay__inner-content'}
      >
        <FormSectionParagraph>
          {I18n.t(T.setupTool.dsoRegistration.documentsHeader)}
          {!!canGenerateDocuments && <span className={'c-setup-tool__dso-summary-status'}>
            <Icofont type={'tick'} /> {I18n.t(T.setupTool.dsoRegistration.status.success)}
          </span>}
        </FormSectionParagraph>
        <h4>{I18n.t(T.setupTool.dsoRegistration.createdAt)} {formatDate(latestDocumentsGenerationDate)}</h4>

        <DsoSummaryFiles
          documents={documents}
          pending={getDsoGenerateDocumentsQueryStatus.pending}
        />

        <div className={'c-dso-registration-summary__help'}>
          <Icon.Info className={'c-dso-registration-summary__help-icon'} />
          <p>
            {I18n.t(T.setupTool.dsoRegistration.help.textBottom)}
          </p>
        </div>
      </div>}
    </Overlay>
  );
};

export const DsoRegistration = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationComponent);
