import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types';
import { ActionsUnion, createAction } from '+utils/index';
import { AdditionalFeatures, Document } from '../../../shared/store/setupTool/types';
import { VppRegisterStatus } from './+vppDocumentation.dictionary';

export enum VPP_DOCUMENTATION_ACTIONS {
  SET_INITIAL_STATE = '[+VppDocumentation] SET_INITIAL_STATE',
  SET_FIELDS = '[+VppDocumentation] SET_FIELDS',
  SET_DSO_REFERENCE_NUMBER_PV_VISIBILITY = '[+VppDocumentation] SET_DSO_REFERENCE_NUMBER_PV_VISIBILITY',
  SET_COMPLETION_NOTIFICATION_VISIBILITY = '[+VppDocumentation] SET_COMPLETION_NOTIFICATION_VISIBILITY',
  SET_UPLOADED_DOCUMENTS = '[+VppDocumentation] SET_UPLOADED_DOCUMENTS',
  GENERATE_COMPLETION_NOTIFICATION = '[+VppDocumentation] GENERATE_COMPLETION_NOTIFICATION',
  SET_COMPLETION_NOTIFICATION_DOCUMENT = '[+VppDocumentation] SET_COMPLETION_NOTIFICATION_DOCUMENT',
  SET_COMPLETION_MODIFICATION_REGENERATED_AT = '[+VppDocumentation] SET_COMPLETION_MODIFICATION_REGENERATED_AT',
  SET_SUBMISSION_STATUS = '[+VppDocumentation] SET_SUBMISSION_STATUS',
  SAVE_DATA_AND_PERFORM_VPP = '[+VppDocumentation] SAVE_DATA_AND_PERFORM_VPP',
  SET_VPP_LABELS_FOR_FLAT_DIRECT = '[+VppDocumentation] SET_VPP_LABELS_FOR_FLAT_DIRECT',
  SET_PRODUCTION_METER_FIELD_VISIBILITY = '[+VppDocumentation] SET_PRODUCTION_METER_FIELD_VISIBILITY',
  SET_METER_CABINET_PREPARED_FIELD = '[+VppDocumentation] SET_METER_CABINET_PREPARED_FIELD',
  SET_FASTENING_TYPE_GRID_FIELD = '[+VppDocumentation] SET_FASTENING_TYPE_GRID_FIELD',
  SET_VPP_ADDITIONAL_FEATURES = '[+VppDocumentation] SET_VPP_ADDITIONAL_FEATURES',
}

export const VppDocumentationActions = {
  setInitialState: createAction(VPP_DOCUMENTATION_ACTIONS.SET_INITIAL_STATE),
  setFields: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_FIELDS,
    (fields: VppDocumentationInterface) => ({ fields }),
  ),
  setDsoReferenceNumberPvVisibility: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_DSO_REFERENCE_NUMBER_PV_VISIBILITY,
    (showDsoReferenceNumberPv: boolean) => ({ showDsoReferenceNumberPv }),
  ),
  setCompletionNotificationVisibility: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_VISIBILITY,
    (showCompletionNotification: boolean) => ({ showCompletionNotification }),
  ),
  setVppUploadedDocuments: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_UPLOADED_DOCUMENTS,
    (documents: Document[]) => ({ documents }),
  ),
  generateCompletionNotification: createAction(
    VPP_DOCUMENTATION_ACTIONS.GENERATE_COMPLETION_NOTIFICATION,
  ),
  setCompletionNotificationDocument: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_NOTIFICATION_DOCUMENT,
    (document: Document) => ({ document }),
  ),
  setCompletionNotificationRegeneratedAt: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_COMPLETION_MODIFICATION_REGENERATED_AT,
    (regeneratedAt: string) => ({ regeneratedAt }),
  ),
  setSubmissionStatus: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_SUBMISSION_STATUS,
    (submissionStatus: VppRegisterStatus | undefined) => ({ submissionStatus }),
  ),
  saveDataAndPerformVpp: createAction(
    VPP_DOCUMENTATION_ACTIONS.SAVE_DATA_AND_PERFORM_VPP,
    (fields: VppDocumentationInterface) => ({ fields }),
  ),
  setVppLabelsForFlatDirect: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_VPP_LABELS_FOR_FLAT_DIRECT,
    (vppLabelsForFlatDirect: boolean) => ({ vppLabelsForFlatDirect }),
  ),
  setProductionMeterFieldVisibility: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_PRODUCTION_METER_FIELD_VISIBILITY,
    (productionMeterField: boolean) => ({ productionMeterField }),
  ),
  setMeterCabinetPreparedField: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_METER_CABINET_PREPARED_FIELD,
    (meterCabinetPreparedField: boolean) => ({ meterCabinetPreparedField }),
  ),
  setFasteningTypeConsumptionField: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_FASTENING_TYPE_GRID_FIELD,
    (fasteningTypeConsumptionField: boolean) => ({ fasteningTypeConsumptionField }),
  ),
  setVppAdditionalFeatures: createAction(
    VPP_DOCUMENTATION_ACTIONS.SET_VPP_ADDITIONAL_FEATURES,
    (vppAdditionalFeatures: AdditionalFeatures[] | null) => ({ vppAdditionalFeatures }),
  ),
};

export type VppDocumentationActions = ActionsUnion<typeof VppDocumentationActions>;
