import { CHART_FONT } from '+app/+customer/+analysis/+analysis.consts';
import {
  AreaChartViewStyle,
  AxisViewStyle,
  ChartGridViewStyle,
  ChartZoomViewStyle,
  DataDisplayType,
  LineChartViewStyle,
} from '@kanva/charts';
import { Paint, Rect, rgba, TextAlign, TextBaseline } from '@kanva/core';
import { AnalysisChartColors, BatteryStatusesKey, EnergyFlowSeriesKey, SharedChartColors } from '@sonnen/shared-web';

const AREA_SELECT_BORDER_WIDTH = 1;

export const AreaSeriesStyle: Record<EnergyFlowSeriesKey, AreaChartViewStyle> = {
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER], .75))
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.GOLD),
  },
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER], .75))
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.BLUE_DARK),
  },
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.DIRECT_USAGE_POWER], 1)),
  },
  [EnergyFlowSeriesKey.BATTERY_CHARGING]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_CHARGING].area, .75))
      .setLineWidth(1)
      .setStrokeStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_CHARGING].outline, 1)),
  },
  [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_DISCHARGING], .75)),
  },
  [EnergyFlowSeriesKey.BATTERY_USOC]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(2)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC]),
  },
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER]),
  },
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER]),
  },
  [EnergyFlowSeriesKey.GRID_FEEDIN]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.GRID_FEEDIN]),
  },
  [EnergyFlowSeriesKey.GRID_PURCHASE]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.GRID_PURCHASE]),
  },
};

export const LineSeriesStyle: Record<BatteryStatusesKey, LineChartViewStyle> = {
  [BatteryStatusesKey.VPP_ACTIVITY]: {
    foreground: {
      paint: new Paint().setFillStyle(AnalysisChartColors[BatteryStatusesKey.VPP_ACTIVITY]),
      width: 8,
      radius: 8,
    },
    background: {
      paint: new Paint().setFillStyle(rgba(SharedChartColors.DARK, .05)),
      width: 4,
      radius: 4,
    },
    minChunkLength: { domain: .1, px: 1 },
  },
};

export const chartGridStyle: ChartGridViewStyle = {
  paint: new Paint()
    .setStrokeStyle(rgba(SharedChartColors.DARK, .05))
    .setLineWidth(1.5),
};

const labelPaint = new Paint()
  .setFillStyle(rgba(SharedChartColors.DARK, .5))
  .setFont(CHART_FONT);

const yAxisPaint = new Paint()
  .setFillStyle(rgba(SharedChartColors.DARK, .5))
  .setTextBaseline(TextBaseline.BOTTOM)
  .setFont(CHART_FONT);

export const xAxisStyle: AxisViewStyle = {
  labelPaint: labelPaint.clone()
    .setTextAlign(TextAlign.CENTER)
    .setTextBaseline(TextBaseline.MIDDLE),
};

export const yAxisLeftStyle: AxisViewStyle = {
  labelPaint: yAxisPaint.clone()
    .setTextAlign(TextAlign.LEFT),
};

export const yAxisRightStyle: AxisViewStyle = {
  labelPaint: yAxisPaint.clone()
    .setTextAlign(TextAlign.RIGHT),
};

export const selectAreaZoomStyle: ChartZoomViewStyle = {
  paint: new Paint()
    .setFillStyle(rgba(SharedChartColors.AREA_SELECT, .3))
    .setStrokeStyle(SharedChartColors.AREA_SELECT),
  borders: new Rect({ left: AREA_SELECT_BORDER_WIDTH, right: AREA_SELECT_BORDER_WIDTH, top: 0, bottom: 0 }),
};
