import { getCurrentLocale } from '+app/i18n/i18n.selectors';
import { StoreState } from '+app/shared/store/store.interface';
import * as React from 'react';
import { connect } from 'react-redux';
import { LocaleContext } from './locale.provider';

type ComponentProps = {
  children: React.ReactNode;
};

type Props =
  & ComponentProps
  & ReturnType<typeof mapStateToProps>
  ;

const mapStateToProps = (state: StoreState) => ({
  currentLocale: getCurrentLocale(state),
});

const LocaleProviderComponent: React.FC<Props> = ({ currentLocale, children }) => {
  const memoizedLocale = React.useMemo(() => ({ currentLocale }), [currentLocale]);

  return (
    <LocaleContext.Provider value={{ locale: memoizedLocale.currentLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export const LocaleProvider = connect(mapStateToProps)(LocaleProviderComponent);
