import { isObject } from 'lodash';

export const FILTER_KEYWORD = '[Filtered]';
const PRIVATE_KEYWORDS: string[] = [
  'token',
  'password',
  'secret',
  'card',
  'billing',
  'shipping',
  'contracting_part',
  'bank',
  'street',
  'postal_code',
  'phone',
  'mobile',
  'first_name',
  'last_name',
  'city',
];

export const filterPrivateData = (data: any) => {
  const result = {};
  for (const key in data) {
    if (!data.hasOwnProperty(key)) {
      continue;
    }
    const value = data[key];

    if (PRIVATE_KEYWORDS.find(keyword => String(key).includes(keyword))) {
      result[key] = FILTER_KEYWORD;
    } else if (isObject(value)) {
      result[key] = filterPrivateData(value);
    } else {
      result[key] = value;
    }
  }
  return result;
};
