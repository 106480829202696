import { Role } from '+shared/store/role/types/role.interface';
import { Loader } from '@sonnen/shared-web';
import { lowerCase } from 'lodash/fp';
import * as React from 'react';

import './ContactRole.component.scss';

interface Props {
  role: Role;
  isChecked: boolean;
  isLoading: boolean;
  onChange: (role: Role, isChecked: boolean) => void;
}

export const ContactRole: React.FC<Props> = ({ role, isChecked, isLoading, onChange }) => (
  <div className={'c-contact-role'}>
    { isLoading
      ? <Loader />
      : <input
        className={'c-contact-role__input'}
        type={'checkbox'}
        name={role.name}
        checked={isChecked}
        onChange={() => onChange(role, isChecked)}
      />
    }
    <label htmlFor={name}>
      {lowerCase(role.name)}
    </label>
  </div>
);
