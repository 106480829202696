import { FormInputSelect } from '+shared/components';
import { batteryCapacityLabelsMapper, formatLabelToNumber } from '+shared/store/lead/lead.helpers';
import { LeadOffer } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';
import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { formFields } from '../../components/LeadConfigurationBattery/LeadConfigurationBattery.helper';
import { ConfigurationForm } from '../../store/types';

interface Props {
  form: FormikProps<ConfigurationForm>;
  productBatteryList: LeadProductBattery[];
  // this one is when we're adding flat tariff to an existing offer
  existingOffer?: LeadOffer;
  // here we're creating another bundle offer while HW only was previously accepted/confirmed
  previouslyAcceptedHardwareOffer?: LeadOffer;
}

export const LeadConfigurationBatteryCapacities: React.FC<Props> = ({
  form,
  productBatteryList,
  existingOffer,
  previouslyAcceptedHardwareOffer,
}) => {
  const { locale } = useLocaleContext();
  const batteriesForModel = productBatteryList.filter(battery => battery.modelName === form.values.modelName);

  return (
    <FormInputSelect
      form={form}
      label={I18n.t(T.lead.configuration._salessolution_.battery.capacity)}
      collection={batteriesForModel
        .sort((capacityLabelOne, capacityLabelTwo) =>
          formatLabelToNumber(capacityLabelOne.name) - formatLabelToNumber(capacityLabelTwo.name))
        .map(battery => battery.id)}
      name={formFields.CAPACITY_GROSS}
      mapper={(key: string) => batteryCapacityLabelsMapper(locale)(key, productBatteryList)}
      hasNoGap={true}
      id={'lead-configuration-battery-capacity'}
      // this field is disabled only when we want to add tariff to an existing offer with HW only
      // or when creating another bundle offer while HW only was previously accepted/confirmed
      isDisabled={!!existingOffer || !!previouslyAcceptedHardwareOffer}
    />
  );
};
