import * as classNames from 'classnames';
import * as React from 'react';

import './FormFieldLabel.component.scss';

interface Props {
  label: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

export const FormFieldLabel: React.FC<Props> = ({
  label,
  children,
  isRequired = false,
  isDisabled = false,
}) => (
  <>
    <label className={classNames('c-form-field-label', {
      'c-form-field-label--disabled': isDisabled,
    })}>
      {label} 
      {isRequired &&
        <span className={'c-form-field-label__star'}>*</span>
      }
    </label>
    {children}
  </>
);
