import { ActionBoxColors } from '+app/+lead/store/types';
import * as classNames from 'classnames';
import * as React from 'react';
import './LeadConfigurationActionBox.component.scss';

interface Props {
  onClickAction: () => void;
  mainColor: ActionBoxColors;
  hoverable: boolean;
  hasLeftBorder: boolean;
  disabled?: boolean;
}

export const LeadConfigurationActionBox: React.FC<Props> = ({
  onClickAction,
  mainColor,
  hoverable,
  hasLeftBorder,
  disabled,
  children,
}) => {
  const handleClick = () => {
    if (!disabled) { return onClickAction(); }
    return;
  };

  return (
    <div
      role={'button'}
      onClick={handleClick}
      className={classNames('c-lead-configuration-action-box', {
        'c-lead-configuration-action-box--hoverable': hoverable,
        'c-lead-configuration-action-box--border-left': hasLeftBorder,
        'c-lead-configuration-action-box--disabled': disabled,
        [`c-lead-configuration-action-box--color-${mainColor}`]: !!mainColor,
      })}
    >
      {children}
    </div>
  );
};
