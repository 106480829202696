import { hasFlatDirectProduct, hasFlatXProduct, isHardwareOnlyOffer } from '+app/+lead/+overview/store/+overview.helper';
import { isNotNil } from '+app/utils';
import { getHardwareProduct, isAnyOfferFlatOnly } from '+lead/+offer/store/+offer.helper';
import { MAX_ALLOWED_CONFIGURATION_COUNT } from '+lead/+overview/containers/LeadOverviewConfigurations/LeadOverviewConfigurations.helper';
import { FormErrorBanner, WidgetSimple } from '+shared/components';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { findBatteryInBatteryList } from '+shared/store/battery/battery.helpers';
import { batteryCapacityLabelMapper, batteryModelNameMap } from '+shared/store/lead/lead.helpers';
import { Lead, LeadOffer } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { renderErrorMessage } from '+utils/error.util';
import { useLocaleContext } from '+utils/react/locale.provider';
import { isStatusSet } from '+utils/status.util';
import { T } from '@sonnen/shared-i18n/service';
import {
  Bubble,
  ClickOutside,
  DefaultParagraph,
  Icon,
  LinkButton,
  WarningText,
  WarningTextTheme,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { isEmpty, isUndefined } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { checkboxTextGenerator } from '../LeadEditStatusModal.component';
import {
  formFields,
  getUniqueOffersWithHardwareProductsSortedByBundles,
  isHardwareStatusFormFieldActive,
  isOneHardwareConfirmedChecked,
  LeadEditStatusForm,
  onContactedValueChange,
  onHwAlreadySoldValueChange,
  onHwConfirmedValueChange,
  onHwSentValueChange,
  onSiteVisitArrangedValueChange,
  onSiteVisitDoneValueChange,
} from '../LeadEditStatusModal.helper';

import './LeadEditStatusHardware.component.scss';

interface LeadEditStatusHardwareProps {
  form: FormikProps<LeadEditStatusForm>;
  userCompanyName: string;
  leadStatusSummary: Lead['status']['summary'];
  hasErrors: boolean;
  offers: LeadOffer[];
  productBatteryList: LeadProductBattery[];
  isMoveToSetupEnabled: (leadStatusSummary: Lead['status']['summary'], values: LeadEditStatusForm) => boolean;
  goToCreateHardwareOffer: () => void;
  displayMaxOffersWarning: () => void;
}

export const LeadEditStatusHardware: React.FC<LeadEditStatusHardwareProps> = ({
  form,
  userCompanyName,
  leadStatusSummary,
  hasErrors,
  offers,
  productBatteryList,
  isMoveToSetupEnabled,
  goToCreateHardwareOffer,
  displayMaxOffersWarning,
}) => {
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);
  const hardwareProducts = getUniqueOffersWithHardwareProductsSortedByBundles(offers);
  const { initialValues, values: formValues } = form;
  const isAnyHardwareConfirmed = !isEmpty(initialValues.hardwareConfirmed);
  const isAnyFlatSold = isNotNil(leadStatusSummary.flatOfferAccepted);
  const isHardwareAlreadySold = isStatusSet(leadStatusSummary.hardwareAlreadySold);
  const { locale } = useLocaleContext();

  const renderTooltip = (tooltipMsg: string) => (
    <ClickOutside
      onClick={() => toggleTooltipActivity(false)}
    >
      <div
        className={'c-lead-edit-status-modal__tooltip'}
        onClick={() => toggleTooltipActivity(!isTooltipActive)}
      >
        <Icon.Info className={'c-lead-edit-status-modal__info-icon'} />
        {isTooltipActive &&
          <div className={'c-lead-edit-status-modal__bubble-wrapper'} >
            <Bubble
              side={'top-right'}
              isThemeDense={false}
            >
              {tooltipMsg}
            </Bubble>
          </div>
        }
      </div>
    </ClickOutside>
  );

  const renderCheckboxesPerOffer = (offer: LeadOffer, checkboxIndex: number) => {
    const battery = findBatteryInBatteryList(offer, productBatteryList);
    const productId = getHardwareProduct(offer)?.productId;
    const isHardwareSentActive = isHardwareStatusFormFieldActive(formFields.HARDWARE_SENT)(offer);
    const isHardwareConfirmedActive = isHardwareStatusFormFieldActive(formFields.HARDWARE_CONFIRMED)(offer);

    if (isUndefined(battery) || isUndefined(productId)) return null;

    const getProductName = (offer: LeadOffer): string => {
      if (isHardwareOnlyOffer(offer)) return I18n.t(T.lead.list._salessolution_.editStatusModal.offer.hwOnly);
      if (hasFlatXProduct(offer)) return I18n.t(T.lead.list._salessolution_.editStatusModal.offer.sonnenFlatX);
      if (hasFlatDirectProduct(offer)) return I18n.t(T.lead.list._salessolution_.editStatusModal.offer.sonnenFlatDirect);
      return 'Unknown offer';
    };

    return (
      <>
        <p className={'c-lead-edit-status-modal__battery-details'}>
          {getProductName(offer)}: {batteryModelNameMap(battery.modelName)} - {batteryCapacityLabelMapper(locale)(battery.name)}
        </p>
        <FormFieldObserver<LeadEditStatusForm> onChange={onHwSentValueChange(offer)(form)}>
          <FormControlledCheckbox
            form={form}
            id={`${formFields.HARDWARE_SENT}[${checkboxIndex}]`}
            label={checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.hwOfferSent),
              userCompanyName, true)}
            value={productId}
            name={formFields.HARDWARE_SENT}
            isConnected={true}
            isConnectionActive={Boolean(isHardwareConfirmedActive(formValues) && !isHardwareConfirmedActive(initialValues))}
            disabled={Boolean(isHardwareSentActive(initialValues)) || isAnyHardwareConfirmed || isHardwareAlreadySold}
          />
        </FormFieldObserver>

        <FormFieldObserver<LeadEditStatusForm> onChange={onHwConfirmedValueChange(offer)(form)}>
          <FormControlledCheckbox
            form={form}
            id={`${formFields.HARDWARE_CONFIRMED}[${checkboxIndex}]`}
            label={checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.hwConfirmed),
              userCompanyName, true)}
            value={productId}
            name={formFields.HARDWARE_CONFIRMED}
            suppressErrorHighlight={!isHardwareConfirmedActive(formValues)}
            disabled={!!isHardwareConfirmedActive(initialValues) || isAnyHardwareConfirmed || isHardwareAlreadySold}
          />
        </FormFieldObserver>
      </>
    );
  };

  const renderHardwareAlreadySoldCheckbox = () => (
    <>
      <p className={'c-lead-edit-status-modal__battery-details'}>
        {I18n.t(T.lead.list._salessolution_.editStatusModal.hwAlreadySold.headline)}
      </p>
      <FormFieldObserver<LeadEditStatusForm> onChange={onHwAlreadySoldValueChange(form)}>
        <FormControlledCheckbox
          form={form}
          id={formFields.HARDWARE_ALREADY_SOLD}
          label={checkboxTextGenerator(
            I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.hwAlreadySold),
            userCompanyName, true)}
          name={formFields.HARDWARE_ALREADY_SOLD}
          disabled={isAnyHardwareConfirmed || isHardwareAlreadySold || isAnyFlatSold}
        />
      </FormFieldObserver>
    </>
  );

  return (
    <div>
      <div className={'c-lead-edit-status-modal__widget-wrapper'}>
        <WidgetSimple
          heading={I18n.t(T.lead.list._salessolution_.editStatusModal.firstContact)}
        >
          <FormFieldObserver<LeadEditStatusForm> onChange={onContactedValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={checkboxTextGenerator(
                I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.contacted),
                userCompanyName, true)}
              isConnected={true}
              isConnectionActive={formValues[formFields.ON_SITE_VISIT_ARRANGED] &&
                !initialValues[formFields.CONTACTED]}
              name={formFields.CONTACTED}
              disabled={initialValues[formFields.CONTACTED]}
            />
          </FormFieldObserver>
          <FormFieldObserver<LeadEditStatusForm> onChange={onSiteVisitArrangedValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={checkboxTextGenerator(
                I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.onSiteVisitArranged),
                userCompanyName, true)}
              isConnected={true}
              isConnectionActive={formValues[formFields.ON_SITE_VISIT_DONE] &&
                !initialValues[formFields.ON_SITE_VISIT_ARRANGED]}
              name={formFields.ON_SITE_VISIT_ARRANGED}
              disabled={initialValues[formFields.ON_SITE_VISIT_ARRANGED]}
            />
          </FormFieldObserver>
          <FormFieldObserver<LeadEditStatusForm> onChange={onSiteVisitDoneValueChange(form)}>
            <FormControlledCheckbox
              form={form}
              label={checkboxTextGenerator(
                I18n.t(T.lead.list._salessolution_.editStatusModal.hwStatuses.onSiteVisitDone),
                userCompanyName, true)}
              name={formFields.ON_SITE_VISIT_DONE}
              disabled={initialValues[formFields.ON_SITE_VISIT_DONE]}
            />
          </FormFieldObserver>
        </WidgetSimple>
      </div>

      {(!isEmpty(hardwareProducts) || !isAnyFlatSold || isAnyOfferFlatOnly(offers)) && (
        <div className={'c-lead-edit-status-modal__widget-wrapper'}>
          <WidgetSimple
            heading={I18n.t(T.lead.list._salessolution_.editStatusModal.HWOffer)}
          >
            {isAnyOfferFlatOnly(offers) ? renderHardwareAlreadySoldCheckbox() : null}
            {hardwareProducts.map(renderCheckboxesPerOffer)}

            {form.errors && (
              <div className={'c-lead-edit-status-modal__warning-wrapper'}>
                {(form.errors.hardwareConfirmed && form.touched.hardwareConfirmed) &&
                  renderErrorMessage(form.errors.hardwareConfirmed)(errorMessage => (
                    <WarningText
                      theme={WarningTextTheme.ERROR}
                      text={errorMessage}
                    />
                  ))
                }
              </div>
            )}

            {form.dirty
              && (isOneHardwareConfirmedChecked(formValues) || formValues.hardwareAlreadySold)
              && isEmpty(form.errors) ?
              <div className={'c-lead-edit-status-modal__warning-wrapper'}>
                <WarningText
                  text={I18n.t(T.lead.hardware._salessolution_.statusModal.warning.hwConfirmed)}
                />
              </div>
              : null}

            {!isAnyHardwareConfirmed && !isAnyFlatSold && !isHardwareAlreadySold && (
              <>
                <DefaultParagraph className={'c-lead-edit-status-modal__new-hardware-paragraph'}>
                  {isEmpty(hardwareProducts) && !isAnyOfferFlatOnly(offers)
                    ? I18n.t(T.lead.list._salessolution_.editStatusModal.createNewHardware.description)
                    : I18n.t(T.lead.list._salessolution_.editStatusModal.createNewHardware.descriptionExisting)}
                </DefaultParagraph>
                <LinkButton
                  className={'c-lead-edit-status-modal__new-hardware-link'}
                  iconClass={'c-lead-edit-status-modal__new-hardware-link-icon'}
                  onClick={offers.length < MAX_ALLOWED_CONFIGURATION_COUNT
                    ? goToCreateHardwareOffer
                    : displayMaxOffersWarning}
                  icon={<Icon.Calculation />}
                >
                  {I18n.t(T.lead.list._salessolution_.editStatusModal.createNewHardware.link)}
                </LinkButton>
              </>
            )}
          </WidgetSimple>
        </div>
      )}

      <div className={classNames('c-lead-edit-status-modal__setup-wrapper', {
        'c-lead-edit-status-modal__setup-wrapper--green': isMoveToSetupEnabled(leadStatusSummary, formValues),
      })}>
        <div className={'c-lead-edit-status-modal__setup-upper'}>
          <div className={'c-lead-edit-status-modal__setup-headline'}>
            <div className={classNames('c-lead-edit-status-modal__star-icon-wrapper', {
              'c-lead-edit-status-modal__star-icon-wrapper--green': isMoveToSetupEnabled(leadStatusSummary, formValues),
            })}>
              <Icon.Star className={'c-lead-edit-status-modal__star-icon'} />
            </div>
            {checkboxTextGenerator(
              I18n.t(T.lead.list._salessolution_.editStatusModal.moveToSetup.headline),
              userCompanyName, true)}
          </div>
          {!isMoveToSetupEnabled(leadStatusSummary, formValues) &&
            renderTooltip(I18n.t(T.lead.list._salessolution_.editStatusModal.moveToSetup.tooltip))}
        </div>
        {isMoveToSetupEnabled(leadStatusSummary, formValues) &&
          <p className={'c-lead-edit-status-modal__setup-lower'}>
            {I18n.t(T.lead.list._salessolution_.editStatusModal.moveToSetup.summary)}
          </p>
        }
      </div>

      {form.dirty && (
        <div className={'c-lead-edit-status-modal__warning-wrapper'}>
          <WarningText
            text={I18n.t(T.lead.list._salessolution_.editStatusModal.warning)}
          />
        </div>
      )}

      <FormErrorBanner
        isVisible={hasErrors}
        error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
      />
    </div>
  );
};
