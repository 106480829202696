import { DetailListType } from '+shared/components';
import { Battery } from '+shared/store/battery';
import { formatDate } from '+utils/format.util.old';
import { trim } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { Status, StatusType } from '@sonnen/shared-web';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

export const factorizeInstallationLocation = ({
  installationStreet, installationPostalCode, installationCity, installationCountryCode,
}: Battery) =>
    trim`${installationStreet}`
    + trim`${installationPostalCode && `,\n${installationPostalCode}`}`
    + `${installationCity ? ` ${installationCity}` : ''}`
    + `${installationCountryCode ? `, ${installationCountryCode}` : ''}`
    ;

export const factorizeInstaller = ({
  installerName, installerStreet, installerPostalCode, installerCity, installerCountry,
}: Battery): string =>
  trim`${installerName}`
  + `${installerStreet ? `\n${installerStreet}` : ''}`
  + `${installerPostalCode ? `\n${installerPostalCode}` : ''}`
  + `${installerCity ? ` ${installerCity}` : ''}`
  + trim`${installerCountry && `, ${installerCountry}`}`
  ;

export const factorizeBatteryInstallationDetailList = (battery: Battery): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installationLocation),
    value: factorizeInstallationLocation(battery),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installationDate),
    value: !isNil(battery.installationDate)
      ? formatDate(battery.installationDate)
      : (
        <Status
          type={StatusType.WARNING}
          label={I18n.t(T.warning.missingInstallationDate)}
        />
      ),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.installation.installer),
    value: factorizeInstaller(battery),
  },
];
