import { Icon, LinkButton } from '@sonnen/shared-web';
import * as React from 'react';
import { PageHeader } from '../../PageHeader';
import './FormHeader.component.scss';

interface Props {
  onClick: () => void;
  title: string;
  breadcrumb?: string;
  isLoading?: boolean;
  suppressTitleInHotjar?: boolean;
}

export const FormHeader: React.FC<Props> = ({
  onClick,
  breadcrumb,
  title,
  isLoading = false,
  suppressTitleInHotjar = false,
}) => (
  <PageHeader
    breadcrumb={
      <LinkButton onClick={onClick} icon={<Icon.Angle />}>
        {breadcrumb}
      </LinkButton>
    }
    isLoading={isLoading}
  >
    <p className={'c-form-header__title'} data-hj-suppress={suppressTitleInHotjar}>{title}</p>
  </PageHeader>
);
