import { getStatus } from '+shared/store/query/query.utils';
import { createSelector } from 'reselect';
import { getLeadHardware } from '../../store/+lead.selectors';
import {
  HARDWARE_SAVE_QUERY,
  UPDATE_HARDWARE_STATUS_QUERY,
} from './+hardware.state';

export const getHardwareSaveQuery = createSelector(
  getLeadHardware,
  state => state[HARDWARE_SAVE_QUERY],
);

export const getHardwareSaveQueryStatus = createSelector(
  getHardwareSaveQuery,
  query => getStatus(query),
);

export const getHardwareStatusUpdateQuery = createSelector(
  getLeadHardware,
  state => state[UPDATE_HARDWARE_STATUS_QUERY],
);

export const getHardwareStatusUpdateQueryStatus = createSelector(
  getHardwareStatusUpdateQuery,
  query => getStatus(query),
);

export const getNewHardwareStatus = createSelector(
  getLeadHardware,
  state => state.newHardwareStatus,
);
