import { createAnalyticsComponents } from '@sonnen/shared-web';
import { GoogleTagUrlHistory } from './events';
import { GoogleTagEventData } from './GoogleTagManager';

const {
  pageViewFactory,
  reduxTrackerFactory,
  useTracking,
  AnalyticsTracker,
} = createAnalyticsComponents<GoogleTagEventData, GoogleTagUrlHistory>();

export {
  pageViewFactory,
  reduxTrackerFactory,
  useTracking,
  AnalyticsTracker,
};
