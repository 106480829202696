import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import { DsoRegistrationRadioType } from '+setupTool/+form/store/types';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types';
import { insertIf } from '+utils/array.util';
import { T } from '@sonnen/shared-i18n/service';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

const getVppDocumentationSchemaStandardObj = () => ({
  battery_commissioning_date: Yup
    .string()
    .test(
      'invalidDateFormat',
      I18n.t(T.setupTool.validation.invalidDateFormat),
      value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
    ).test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, { date: '01.01.1900' }),
      value => !!value ? moment(value).isAfter('1899-12-31') : true,
    ).test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, { years: 2 }),
      value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
    )
    .required(I18n.t(T.register.alert.requiredField)),
  fastening_type_grid: Yup
    .string()
    .required(I18n.t(T.register.alert.requiredField)),
  meter_number: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  type_of_grid_reference_meter: Yup
    .string()
    .required(I18n.t(T.register.alert.requiredField)),
  [FileCategoryName.METER_PICTURES]: Yup
    .string()
    .required(I18n.t(T.register.alert.requiredField)),
  [FileCategoryName.WIRING_DIAGRAM_VPP]: Yup
    .string()
    .required(I18n.t(T.register.alert.requiredField)),
  pv_reduction: Yup
    .boolean().oneOf([true])
    .required(I18n.t(T.register.alert.requiredField)),
});

export const getVppDocumentationSchema = (
  dsoReferenceNumberPvVisible?: boolean,
  completionNotificationVisible?: boolean,
  meterCabinetPreparedFieldVisible?: boolean,
  fasteningTypeConsumptionFieldVisible?: boolean,
  multiplePvSystemsEnabled?: boolean,
  productionMeterFieldVisible?: boolean,
) => {
  let schemaObj = { ...getVppDocumentationSchemaStandardObj() };
  if (dsoReferenceNumberPvVisible) {
    schemaObj = {
      ...schemaObj,
      dso_reference_number_pv: Yup
        .string()
        .max(20, I18n.t(T.validation.maxCharacters, { max: 20 }))
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  }

  if (productionMeterFieldVisible) {
    schemaObj = {
      ...schemaObj,
      production_meter: Yup
        .string()
        .matches(/(Ja|Nein)/)
        .required(I18n.t(T.register.alert.requiredField)),
      number_production_meter: Yup
        .string()
        .when('production_meter', {
          is: DsoRegistrationRadioType.YES,
          then: Yup
            .string()
            .trim()
            .required(I18n.t(T.register.alert.requiredField)),
      }),
    } as any;
  }

  if (completionNotificationVisible) {
    schemaObj = {
      ...schemaObj,
      [FileCategoryName.COMPLETION_NOTIFICATION]: Yup
        .string()
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  }

  if (meterCabinetPreparedFieldVisible) {
    schemaObj = {
      ...schemaObj,
      meter_cabinet_prepared: Yup
        .boolean().oneOf([true])
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  }

  if (fasteningTypeConsumptionFieldVisible) {
    schemaObj = {
      ...schemaObj,
      fastening_type_consumption: Yup
        .string()
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  }

  if (multiplePvSystemsEnabled) {
    schemaObj = {
      ...schemaObj,
      virtual_pv_system_subsystem_1_commissioning_date: Yup
        .string()
        .test(
          'invalidDateFormat',
          I18n.t(T.setupTool.validation.invalidDateFormat),
          value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
        ).test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
          value => !!value ? moment(value).isAfter('1899-12-31') : true,
        ).test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
          value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
        )
        .required(I18n.t(T.register.alert.requiredField)),
      virtual_pv_system_subsystem_2_commissioning_date: Yup
        .string()
        .test(
          'invalidDateFormat',
          I18n.t(T.setupTool.validation.invalidDateFormat),
          value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
        ).test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
          value => !!value ? moment(value).isAfter('1899-12-31') : true,
        ).test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
          value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
        )
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  } else {
    schemaObj = {
      ...schemaObj,
      pv_commissioning_date: Yup
        .string()
        .test(
          'invalidDateFormat',
          I18n.t(T.setupTool.validation.invalidDateFormat),
          value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
        ).test(
          'earliestDate',
          I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
          value => !!value ? moment(value).isAfter('1899-12-31') : true,
        ).test(
          'invalidDateNewerThan',
          I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
          value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
        )
        .required(I18n.t(T.register.alert.requiredField)),
    } as any;
  }
  return () => Yup.object().shape(schemaObj);
};

export const VppDocumentationInitialStandard: VppDocumentationInterface = {
  pv_commissioning_date: '',
  battery_commissioning_date: '',
  production_meter: DsoRegistrationRadioType.NO,
  number_production_meter: '',
  fastening_type_grid: '',
  fastening_type_consumption: '',
  meter_number: '',
  type_of_grid_reference_meter: '',
  [FileCategoryName.METER_PICTURES]: undefined,
  [FileCategoryName.WIRING_DIAGRAM_VPP]: undefined,
  [FileCategoryName.COMPLETION_NOTIFICATION]: undefined,
  pv_reduction: false,
  meter_cabinet_prepared: false,
  virtual_pv_system: undefined,
  virtual_pv_system_subsystem_1_name: undefined,
  virtual_pv_system_subsystem_2_name: undefined,
  virtual_pv_system_subsystem_1_commissioning_date: undefined,
  virtual_pv_system_subsystem_2_commissioning_date: undefined,
};

export const VppDocumentationInitialWithDsoReferenceNumberPv: VppDocumentationInterface = {
  ...VppDocumentationInitialStandard,
  dso_reference_number_pv: undefined,
};

export const getVppDocumentationInitial = (dsoReferenceNumberPvVisible?: boolean) =>
  dsoReferenceNumberPvVisible
    ? VppDocumentationInitialWithDsoReferenceNumberPv
    : VppDocumentationInitialStandard;

export const VppDocumentationFiles = [
  FileCategoryName.METER_PICTURES,
  FileCategoryName.WIRING_DIAGRAM_VPP,
  FileCategoryName.COMPLETION_NOTIFICATION,
];

export const saveableVppFieldsStandard = [
  'battery_commissioning_date',
  'fastening_type_grid',
  'meter_number',
  'type_of_grid_reference_meter',
  'pv_reduction',
];

export const getSaveableVppDocumentationFields = (
  dsoReferenceNumberPvVisible?: boolean,
  isFasteningTypeConsumptionVisible?: boolean,
  isMeterCabinetPreparedVisible?: boolean,
  multiplePvSystemEnabled?: boolean,
  isProductionMeterVisible?: boolean,
) => [
    ...saveableVppFieldsStandard,
    ...insertIf(!!dsoReferenceNumberPvVisible, 'dso_reference_number_pv'),
    ...insertIf(!!isFasteningTypeConsumptionVisible, 'fastening_type_consumption'),
    ...insertIf(!!isMeterCabinetPreparedVisible, 'meter_cabinet_prepared'),
    ...insertIf(!!isProductionMeterVisible, 'production_meter'),
    ...insertIf(!!isProductionMeterVisible, 'number_production_meter'),
    ...insertIf(!!multiplePvSystemEnabled, 'virtual_pv_system_subsystem_1_commissioning_date'),
    ...insertIf(!!multiplePvSystemEnabled, 'virtual_pv_system_subsystem_2_commissioning_date'),
    ...insertIf(!multiplePvSystemEnabled, 'pv_commissioning_date'),
  ];
