import { SiteLiveState } from '+shared/store/site/types';
import { isUndefined } from 'lodash/fp';
import { SiteMeasurements } from '../../../../shared/store/site';
import { Battery } from '../../battery/types/battery.interface';
import { isContractSonnenFlatX } from '../../contract/contract.helpers';
import { isContractSonnenFlat } from '../../contract/contract.helpers';
import { Contract, MeterInstallation } from '../../contract/types/contract.interface';
import { missingLatestAnalysisDataFactory } from './analysisWarning.factory';
import { missingInstallationDateWarningFactory } from './installationWarning.factory';
import { meterInstallationWarningFactory } from './meterInstallationWarning.factory';
import { offlineWarningFactory } from './offlineWarning.factory';
import { missingPvRemoteControlWarningFactory } from './pvRemoteControlWarning.factory';
import { CustomerWarning } from './warnings.types';

interface BatteryWarningsFactoryInput {
  battery: Battery | undefined;
  siteLiveState: SiteLiveState | undefined;
}

interface AnaysisWarningsFactoryInput {
  siteMeasurements: SiteMeasurements | undefined;
  battery: Battery | undefined;
}

type Warnings =
  | ReturnType<typeof getBatteryWarnings>
  | ReturnType<typeof getContractWarnings>
  | ReturnType<typeof getAnalysisWarnings>
  ;

export const getBatteryWarnings = ({ battery, siteLiveState }: BatteryWarningsFactoryInput) => ({
  batteryOffline: (battery && siteLiveState && !siteLiveState.online)
    ? offlineWarningFactory(battery.lastMeasurementAt)
    : undefined,
  missingInstallationDate: !isUndefined(battery)
    ? missingInstallationDateWarningFactory(battery)
    : undefined,
});

export const getContractWarnings = ({ contract, meterInstallation }:
  {contract?: Contract, meterInstallation?: MeterInstallation}) => {
  if (isUndefined(contract)) {
    return ({
      missingPvRemoteControl: undefined,
      meterInstallationWarningFactory: undefined,
    });
  }
  return({
    missingPvRemoteControl:
      (isContractSonnenFlat(contract) || isContractSonnenFlatX(contract))
        ? missingPvRemoteControlWarningFactory(meterInstallation)
        : undefined,
    meterInstallationWarningFactory:
      (isContractSonnenFlat(contract) 
      || isContractSonnenFlatX(contract)) 
        ? meterInstallationWarningFactory(meterInstallation)
        : undefined,
  });
};

export const getAnalysisWarnings = ({ siteMeasurements, battery }: AnaysisWarningsFactoryInput) => ({
  missingLatestAnalysisData: !isUndefined(siteMeasurements)
    ? missingLatestAnalysisDataFactory({siteMeasurements, battery})
    : undefined,
});

export const getWarningMessages = (warnings: Warnings, extended?: boolean) =>
  Object
    .values(warnings)
    .filter(Boolean)
    .map(warning => warning as NonNullable<CustomerWarning>);

export const getOnlyDangerWarnings = (warnings: CustomerWarning[]) => warnings
  .filter(warning => warning.type === 'danger');
