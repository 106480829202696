import { TutorialSlide } from '+shared/components/TutorialSlide';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { TutorialActions } from '+shared/store/tutorial';
import { getTutorialInitialSlideObject, getTutorialOpenedObject } from '+shared/store/tutorial/tutorial.selectors';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import { Carousel } from '@sonnen/shared-web/src/components/Carousel/Carousel.component';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { getSlides } from './Tutorial.helper';

import './Tutorial.component.scss';

interface TutorialProps {
  id: string;
}

const mapStateToProps = (state: StoreState) => ({
  tutorialInitialSlideObject: getTutorialInitialSlideObject(state),
  tutorialOpenedObject: getTutorialOpenedObject(state),
});

const mapDispatchToProps = mapActions({
  toggleTutorial: TutorialActions.toggleTutorial,
  appendCurrentlyDisplayedSlides: TutorialActions.appendCurrentlyDisplayedSlides,
  removeCurrentlyDisplayedSlides: TutorialActions.removeCurrentlyDisplayedSlides,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & TutorialProps
  ;

const TutorialComponent: React.FC<Props> = ({ id, tutorialInitialSlideObject, tutorialOpenedObject, actions }) => {
  const isTutorialOpened = tutorialOpenedObject[id];
  const initialSlideNumber = tutorialInitialSlideObject[id];
  const slides = getSlides(id);
  const { track } = useTracking();

  const toggleTutorialWithTracking = () => {
    const actionName = !isTutorialOpened ? 'Setup Tools - Tutorial opened' : 'Setup Tools - Tutorial closed';
    track(InteractionEvents.buttonClick({
      category: EventCategory.SETUP_TOOLS_TUTORIAL,
      action: actionName,
    }));

    actions.toggleTutorial(id, !isTutorialOpened);
  };

  const trackTutorialSlideChange = () => {
    const actionName = 'Setup Tools - Tutorial slide changed';
    track(InteractionEvents.buttonClick({
      category: EventCategory.SETUP_TOOLS_TUTORIAL,
      action: actionName,
    }));
  };

  React.useEffect(() => {
    actions.appendCurrentlyDisplayedSlides(slides);

    const tutorialContentElement = document.getElementById('_' + id);
    if (tutorialContentElement) {
      const arrowElements = tutorialContentElement.getElementsByClassName('sw-carousel-arrow__button') as any;
      const videoElements = document.getElementsByClassName('tutorial-step__video') as any;
      if (arrowElements) {
        for (const arrowElement of arrowElements) {
          arrowElement.addEventListener('click', () => {
            if (videoElements) {
              Array.prototype.forEach.call(videoElements, videoElement => {
                videoElement.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), '*');
              });
            }
            trackTutorialSlideChange();
          });
        }
      }
    }

    return () => {
      actions.removeCurrentlyDisplayedSlides();
    };
  }, [isTutorialOpened]);

  return (
    <section className={'tutorial'} id={'_' + id}>
      <header className={'tutorial__header'}>
        <p className={'tutorial__title'}>
          <Icon.PlayVideo className={'tutorial__icon'} />
          {I18n.t(T.tutorialComponent.title)}</p>
        <Button
          name={'tutorial-visibility-switcher'}
          size={ButtonSize.NORMAL}
          theme={ButtonTheme.OUTLINE}
          label={isTutorialOpened ? I18n.t(T.tutorialComponent.hideGuide) : I18n.t(T.tutorialComponent.showGuide)}
          onClick={toggleTutorialWithTracking}
        />
      </header>
      {isTutorialOpened && (<div className={'tutorial__content'}>

        <Carousel
          arrowsOnDesktop={true}
          arrowsOnMobile={true}
          autoplay={false}
          infinite={true}
          initialSlide={initialSlideNumber}
          goToInitialSlideAfterComponentUpdate={true}
          dots={false}
          slidesToShow={1}
          slidesToScroll={1}
          speed={600}
        >
          {slides.map(({
             slideNumber,
             slideType,
             title,
             description,
             linkHref,
             linkText,
             imgSrc,
             videoSrc,
           }) => (
              <TutorialSlide
                key={uuid.v4()}
                numAllSlides={slides.length}
                slideNumber={slideNumber}
                slideType={slideType}
                title={title}
                description={description}
                linkHref={linkHref}
                linkText={linkText}
                imgSrc={imgSrc}
                videoSrc={videoSrc}
              />
            ))}
        </Carousel>
      </div>)}
    </section>
  );
};

export const Tutorial = connect(mapStateToProps, mapDispatchToProps)(TutorialComponent);
