import { ListTableHeaderItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const getTableHeaders = (): ListTableHeaderItem[] => [
  { label: I18n.t(T.contacts.list.name) },
  { label: I18n.t(T.contacts.list.email) },
  { label: I18n.t(T.contacts.list.company) },
  { label: I18n.t(T.contacts.list.sfContactID) },
  { label: I18n.t(T.contacts.list.roles) },
];
