import {
  PRE_COMMISSIONING_ACTIONS,
  PreCommissioningActions,
} from '+setupTool/+preCommissioning/store/+preCommissioning.actions';
import {
  GET_PRE_COMMISSIONING_DATA_QUERY,
  initialState,
  MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY,
  PreCommissioningState,
} from '+setupTool/+preCommissioning/store/+preCommissioning.state';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/index';

export const reducer = createReducer<PreCommissioningState, PreCommissioningActions>(
  (state = initialState, action): PreCommissioningState => {
    switch (action.type) {
      case PRE_COMMISSIONING_ACTIONS.SET_PRE_COMMISSIONING_DATA:
        return {
          ...state,
          fields: action.data,
        };
      case PRE_COMMISSIONING_ACTIONS.SET_PRE_COMMISSIONING_STATUS:
        return {
          ...state,
          status: action.status,
        };
      case PRE_COMMISSIONING_ACTIONS.CLEAR_PRE_COMMISSIONING_GENERATION_QUERY_STATUS:
        return {
          ...state,
          [MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY]:
            initialState[MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY],
        };
      default:
        return queryReducer(state, action, [
          GET_PRE_COMMISSIONING_DATA_QUERY,
          MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY,
        ]);
    }
  },
);
