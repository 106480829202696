import { DataContainer } from '@kanva/charts';
import { Point } from '@kanva/core';
import { StatisticsSelectedDate, TimeUnit } from '@sonnen/shared-web';

export const setYAxisPrecision = (
  statisticsSelectedDate: StatisticsSelectedDate,
  dataContainer: DataContainer<Point>,
) => {
  if (statisticsSelectedDate.period === TimeUnit.DAY) {
    dataContainer.setYAxisParameters({
      ...dataContainer.getYAxisParameters(),
      roundTo: 100,
    });
  }
};
