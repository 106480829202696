import { Portal } from '+shared/store/firebase/types/news.interface';
import { Icofont } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { ListTableHeaderItem } from './ListTableHeader.interface';

import './ListTableHeader.component.scss';

interface Props<T> {
  item: T;
  className?: ClassValue;
  newsPortal?: Portal;
}

export const ListTableHeader = <T extends ListTableHeaderItem>({ item, className }: Props<T>) => (
  <div className={classNames('c-list-table-header', className)}>
    {item.icon && (
      <div className={'c-list-table-header__icon'}>
        <Icofont type={item.icon} />
      </div>
    )}
    <div className={'c-list-table-header__label'}>
      {item.label}
    </div>
  </div>
);
