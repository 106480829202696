import { IconType } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';

import './WidgetWarning.component.scss';

interface Props {
  type: 'success' | 'warning' | 'danger' | 'info';
  icon?: IconType;
}

export const WidgetWarning: React.FC<Props> = ({
  type = 'info',
  icon,
  children,
}) => (
  <span className={classNames('c-widget-warning', {
    [`c-widget-warning--${type}`]: type,
  })}>
    {icon && <span className={`icofont-${icon} c-widget-warning__icon`} />}
    <span className={'c-widget-warning__content'}>
      {children}
    </span>
  </span>
);
