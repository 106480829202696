import { FormInput } from '+shared/components';
import { QueryState } from '+shared/store/query';
import { VideoYoutube } from '+shared/store/youtube/types/youtube.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';
import { Button, ButtonSize } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { AddVideoForm, youtubeLink } from '../AddVideo/AddVideo.helper';

import './AddVideoLink.component.scss';

interface Props {
  youtubeVideoQueryStatus: QueryState;
  clearYoutubeVideoList: typeof YoutubeActions.clearYoutubeVideoList;
  youtubeVideo: VideoYoutube | undefined;
  form: FormikProps<AddVideoForm>;
}

export const AddVideoLink: React.FC<Props> = ({
  youtubeVideoQueryStatus,
  youtubeVideo,
  clearYoutubeVideoList,
  form,
}) => (youtubeVideoQueryStatus.success 
  ? (<div className={'c-add-video-link__movie-preview'}>
        {youtubeVideo 
          ? (<div className={'c-add-video-link__movie-details'}>
                <img src={youtubeVideo.snippet.thumbnails.default.url}/>
                <h3 className={'c-add-video-link__movie-title'}>
                  {youtubeVideo.snippet.title}
                </h3>
              </div>
            ) : (
              <span className={'c-add-video-link__movie-not-found'}>
                There was no video found
              </span>
            )
        }
        <Button
          size={ButtonSize.PILL}
          label={'Change link'}
          onClick={() => clearYoutubeVideoList()}
        />
      </div>
  ) : (
    <FormInput
      form={form}
      name={youtubeLink}
      label={'Youtube link'}
    />
  ));
