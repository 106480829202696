import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';
import { xorDecrypt } from '+utils/crypto.util';
import { getQueryParams } from '+utils/queryParams.util';
import { findLastIndex } from 'lodash';
import { createSelector } from 'reselect';
import { matchPath } from './router.helpers';

export const getRouterState = (state: StoreState) => state.router;

export const getRouterLocation = createSelector(
  getRouterState,
  state => state.location,
);

export const getRouterLocationSearch = createSelector(
  getRouterLocation,
  location => location.search,
);

export const getRouterLocationPath = createSelector(
  getRouterLocation,
  location => location.pathname,
);

export const getRouterLocationPathFirstSegment = createSelector(
  getRouterLocationPath,
  path => path.split('/')[1],
);

export const isCustomerPath = createSelector(
  getRouterLocationPathFirstSegment,
  segment => segment === 'customer',
);

export const getRouteQueryParams = createSelector(
  getRouterLocationSearch,
  (search) => getQueryParams(search),
);

export const getDecryptedRouteQueryParams = createSelector(
  getRouteQueryParams,
  getUserProfileCustomerNumber,
  (params: any, userProfileCustomerNumber) => ({
    ...params,
    search: params.search && xorDecrypt(params.search, userProfileCustomerNumber),
  }),
);

export const getRouterHistory = createSelector(
  getRouterState,
  state => state.history,
);

export const getRouterAction = createSelector(
  getRouterState,
  state => state.action,
);

export const isGoBackAction = createSelector(
  getRouterAction,
  action => action === 'POP',
);

export const getLastMatchingHistoryElement = (routePath: string | string[]) => createSelector(
  getRouterHistory,
  history => {
    const index = findLastIndex(history, location => matchPath(routePath)(location.pathname));
    return history[index];
  },
);

export const getLastLocationIndex = (routePath: string | string[]) => createSelector(
  getRouterHistory,
  history => {
    const index = findLastIndex(history, location => matchPath(routePath)(location.pathname));
    return index < 0 ? 0 : index - (history.length - 1);
  },
);

export const getHistoryLastTwoElements = createSelector(
  getRouterHistory,
  history => {
    if (history.length < 2) { return undefined; }
    const lastIndex = findLastIndex(history);
    return [history[lastIndex - 1], history[lastIndex]];
  },
);

export const isLastPathChanged = createSelector(
  getHistoryLastTwoElements,
  elements => {
    if (!elements) { return true; }
    const userJustLogin = elements[0].search.includes('?code');
    return userJustLogin || elements[0].pathname !== elements[1].pathname;
  },
);

export const isPathChangingFromCustomerToAnother = createSelector(
  getHistoryLastTwoElements,
  elements => {
    if (!elements) { return false; }
    return elements[0].pathname.includes('customer') && !elements[1].pathname.includes('customer');
  },
);
