import { ContactListRouteQueryParams, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store';
import { getRouteQueryParams } from '+app/router/store/router.selectors';
import { mapToState, matchPath, ofType } from '+app/utils';
import { RoleRepository } from '+shared/store/role/role.repository';
import { StoreState } from '+shared/store/store.interface';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { processQuery } from '../../../utils';
import { ContactListPageActions } from './+contactList.actions';
import { CONTACT_ASSIGN_ROLE_QUERY, CONTACT_COLLECTION_GET_QUERY, CONTACT_DENY_ROLE_QUERY } from './+contactList.state';

type Action$ = ActionsObservable<ContactListPageActions>;
type State$ = StateObservable<StoreState>;

export const getContactCollection$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  matchPath(ROUTES.CONTACTS[0]),
  map(getRouteQueryParams),
  map(queryParams => queryParams as ContactListRouteQueryParams),
  map(queryParams => ContactListPageActions.getContactList({ 
    queryKey: CONTACT_COLLECTION_GET_QUERY,
    queryParams,
  })),
);
 
export const assignRole$ = (action$: Action$) => action$.pipe(
  ofType(ContactListPageActions.assignRole),
  mergeMap(({ role, contactId }) => of({}).pipe(
    processQuery(
      CONTACT_ASSIGN_ROLE_QUERY,
      () => RoleRepository.postRole(role.name, contactId),
      {
        onSuccess: res => of(ContactListPageActions.setRole(res!.element, contactId)),
        onFailure: _ => of(ContactListPageActions.setCurrentlyEditedRole(contactId, role.name)),
      },
    ),
  )),
);

export const denyRole$ = (action$: Action$) => action$.pipe(
  ofType(ContactListPageActions.denyRole),
  mergeMap(({ role, contactId }) => of({}).pipe(
    processQuery(
      CONTACT_DENY_ROLE_QUERY,
      () => RoleRepository.deleteRole(role.id),
      {
        onSuccess: () => of(ContactListPageActions.resetRole(role, contactId)),
        onFailure: _ => of(ContactListPageActions.setCurrentlyEditedRole(contactId, role.name)),
      },
    ),
  )),
);

export const epics = combineEpics(
  getContactCollection$,
  assignRole$,
  denyRole$,
);
