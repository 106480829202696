import { YoutubeRepository } from '+app/shared/store/youtube/youtube.repository';
import { ofType, processQuery } from '+utils/index';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { YoutubeActions } from './youtube.actions';
import { GET_YOUTUBE_VIDEO_LIST_QUERY } from './youtube.state';

type Action$ = ActionsObservable<YoutubeActions>;

export const getYoutubeVideoList$ = (action$: Action$) => action$.pipe(
  ofType(YoutubeActions.getYoutubeVideoList),
  mergeMap(({videoIds}) => of(videoIds).pipe(
    processQuery(
      GET_YOUTUBE_VIDEO_LIST_QUERY,
      () => YoutubeRepository.getYoutubeVideoList(videoIds),
      {onSuccess:
        res => of(YoutubeActions.setYoutubeVideoList(res.items)),
      },
    ),
  )),
);

export const epics = combineEpics(
  getYoutubeVideoList$,
);
