import { Config } from '+config';
import { defer } from 'rxjs';
import { jsonApiClient } from '../../network';
import { BatteryElectricUnitsData, BatteryWithProductData } from './types';
import { BatteryStatusesData } from './types/batteryStatuses.interface';

const getBattery = (id: string) =>
  defer(() => jsonApiClient
    .get<BatteryWithProductData>(`${Config.API_URL}/battery-systems/${id}?include=product`)
    .resolveIncluded()
    .send());

const getBatteryElectricUnits = (id: string) =>
  defer(() => jsonApiClient
    .getList<BatteryElectricUnitsData>(`${Config.API_URL}/battery-systems/${id}/electric-units`)
    .send());

const getBatteryStatuses = (id: string, start: Date, end: Date) =>
  defer(() => jsonApiClient
    .getList<BatteryStatusesData>(`${Config.API_URL}/battery-systems/${id}/statuses`)
    .filter('key', 'latest_battery_special_status')
    .filter('start_at', start.toISOString())
    .filter('end_at', end.toISOString())
    .send(),
  );

export const BatteryRepository = {
  getBattery,
  getBatteryElectricUnits,
  getBatteryStatuses,
};
