import {
  getLargeConsumersMessage,
} from '+app/+lead/components/LeadFlatConfigurationTile/LeadFlatConfigurationTile.helper';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { StoreState } from '+shared/store/store.interface';
import { formatNumber } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadConfigurationAutarkyForm } from '../../containers/LeadConfigurationAutarkyForm';
import { getConfigurationProposal, getConfigurationSubmitQueryStatus } from '../../store/+configuration.selectors';

import './LeadConfigurationAutarky.component.scss';

const mapStateToProps = (state: StoreState) => ({
  configurationSubmitQueryStatus: getConfigurationSubmitQueryStatus(state),
  configProposal: getConfigurationProposal(state),
});

interface ComponentProps {
  expectedAutarky: React.ReactText;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ComponentProps
  ;

const LeadConfigurationAutarkyComponent: React.FC<Props> = ({
  expectedAutarky,
  configurationSubmitQueryStatus,
  configProposal,
}) => {
  const hasEVehicle = !isEmpty(configProposal?.expectedElectricityConsumption.electricCar);
  const hasHeatPump = !isEmpty(configProposal?.expectedElectricityConsumption.heatPump);

  return (
    <>
      <p className={'c-lead-configuration-autarky'}>
        {I18n.t(T.lead.configuration._salessolution_.autarkyInfoValue)}{' '}
        <span
          className={'c-lead-configuration-autarky-value'}
        >
          {formatNumber({ precision: 2 })(Number(expectedAutarky))}%
          {getLargeConsumersMessage(hasEVehicle, hasHeatPump)}
        </span>
      </p>

      <div className={'c-lead-configuration-autarky__form-wrapper'}>
        {!configurationSubmitQueryStatus.pending &&
          <LeadConfigurationAutarkyForm
            expectedAutarky={expectedAutarky}
          />
        }
        <div className={'c-lead-configuration-autarky__info'}>
          <FormInputInfo>
            {I18n.t(T.lead.configuration._salessolution_.autarkyWarning)}
          </FormInputInfo>
        </div>
      </div>
    </>
  );
};

export const LeadConfigurationAutarky = connect(mapStateToProps)(LeadConfigurationAutarkyComponent);
