import { CurrencyUnit } from '+app/App.constants';
import {
  Lead,
  LeadBudget,
  LeadBudgetInEuro,
  LeadFinancingInterest,
  LeadHomeOwnership,
  LeadMotivation,
  LeadProductInterest,
  LeadPurchasingTimeframe,
} from '+shared/store/lead/types';
import { formatCurrency } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

type LeadAdditionalData = Lead['additionalData'];

const mapSingleProductInterest = (interest: string): string | undefined => {
  switch (interest) {
    case LeadProductInterest.BACK_UP_BOX:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.backUpBox);
    case LeadProductInterest.BATTERY_EXTENSION:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.batteryExtension);
    case LeadProductInterest.CHARGER:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.charger);
    case LeadProductInterest.ECO:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.eco);
    case LeadProductInterest.HEATER:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.heater);
    case LeadProductInterest.HYBRID:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.hybrid);
    case LeadProductInterest.PV:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.photovoltaics);
    case LeadProductInterest.PRO:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.pro);
    case LeadProductInterest.PRODUCER_SONNEN_COMMUNITY:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.producerSonnenCommunity);
    case LeadProductInterest.PV_UPGRADE:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.pvUpgrade);
    case LeadProductInterest.SONNEN_FLAT_CITY:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenBatteryCity);
    case LeadProductInterest.SONNEN_FLAT_HOME:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenFlat);
    case LeadProductInterest.SONNEN_STROM_PLUS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenStromPlus);
    case LeadProductInterest.SONNEN_STROM_START:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenStromStart);
    case LeadProductInterest.SONNEN_STROM_SOLAR:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenStromSolar);
    case LeadProductInterest.SONNEN_FLAT_X:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenFlatX);
    case LeadProductInterest.SONNEN_DRIVE:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenDrive);
    case LeadProductInterest.SONNEN_NOW:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow);
    case LeadProductInterest.SONNEN_BATTERIE_10:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenBatterie10);
    case LeadProductInterest.SONNEN_BATTERIE_10_PERFORMANCE:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenBatterie10Performance);
    default:
      return undefined;
  }
};

const mapProductInterests = (additionalData: LeadAdditionalData) => (
  additionalData.productInterest ?
    Object.keys(additionalData.productInterest)
      .filter(key => Boolean(additionalData.productInterest![key]))
      .map(mapSingleProductInterest)
      .filter(Boolean)
      .join(', ') : ''
);

const mapHomeOwner = (additionalData: LeadAdditionalData): string | undefined => {
  switch (additionalData!.homeOwnership) {
    case LeadHomeOwnership.EXISTING:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.existing);
    case LeadHomeOwnership.NEW:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.new);
    case LeadHomeOwnership.NO:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.no);
    case LeadHomeOwnership.PLANNED:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.planned);
    case LeadHomeOwnership.YES:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.yes);
    default:
      return undefined;
  }
};

const mapSingleMotivation = (motivation: string): string | undefined => {
  switch (motivation) {
    case LeadMotivation.AUTONOMY:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.autonomy);
    case LeadMotivation.BLACK_OUTS_PROTECTION:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.blackOutsProtection);
    case LeadMotivation.ENERGY_INDEPENDENCE:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.independence);
    case LeadMotivation.ENVIRONMENTAL_REASONS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.envReasons);
    case LeadMotivation.FIRST_MOVER_WITH_NEW_TECHNOLOGY:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.firstMover);
    case LeadMotivation.INVESTMENT:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.investment);
    case LeadMotivation.SAVING_ELECTRICTY_COSTS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.savingCosts);
    default:
      return undefined;
  }
};

const mapMotivations = (additionalData: LeadAdditionalData) => (
  additionalData.motivation ?
    Object.keys(additionalData.motivation)
      .filter(key => Boolean(additionalData.motivation![key]))
      .map(mapSingleMotivation)
      .filter(Boolean)
      .join(', ') : ''
);

const mapPurchasingTime = (additionalData: LeadAdditionalData): string | undefined => {
  switch (additionalData!.purchasingTimeframe) {
    case LeadPurchasingTimeframe.BTW_1_TO_3_MONTHS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.from1to3months);
    case LeadPurchasingTimeframe.BTW_3_TO_6_MONTHS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.from3to6months);
    case LeadPurchasingTimeframe.BTW_6_TO_12_MONTHS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.from6to12months);
    case LeadPurchasingTimeframe.GT_12_MONTHS:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.greaterThan12months);
    case LeadPurchasingTimeframe.IMMEDIATELY:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.immediately);
    default:
      return undefined;
  }
};

const mapBudget = (budget: LeadBudget<CurrencyUnit.CURRENCY_EUR>): string | undefined => {
  const { value, currency } = budget;
  const opts = { precision: 0 };

  switch (value) {
    case LeadBudgetInEuro.LT_5000:
      return `< ${formatCurrency(5000, currency, opts)}`;
    case LeadBudgetInEuro.BTW_5000_AND_10000:
      return `${formatCurrency(5000, currency, opts)} - ${formatCurrency(10000, currency, opts)}`;
    case LeadBudgetInEuro.BTW_10000_AND_15000:
      return `${formatCurrency(10000, currency, opts)} - ${formatCurrency(15000, currency, opts)}`;
    case LeadBudgetInEuro.BTW_15000_AND_20000:
      return `${formatCurrency(15000, currency, opts)} - ${formatCurrency(20000, currency, opts)}`;
    case LeadBudgetInEuro.BTW_20000_AND_25000:
      return `${formatCurrency(20000, currency, opts)} - ${formatCurrency(25000, currency, opts)}`;
    case LeadBudgetInEuro.BTW_25000_AND_30000:
      return `${formatCurrency(25000, currency, opts)} - ${formatCurrency(30000, currency, opts)}`;
    case LeadBudgetInEuro.GT_30000:
      return `> ${formatCurrency(30000, currency, opts)}`;
    default:
      return undefined;
  }
};

const mapFinancingInterest = (interest: LeadFinancingInterest) => {
  switch (interest) {
    case LeadFinancingInterest.YES:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.financingInterest.yes);
    case LeadFinancingInterest.NO:
      return I18n.t(T.lead.boc._salessolution_.additionalDetails.financingInterest.no);
    default:
      return undefined;
  }
};

export const getLeadAdditionalDetailsList = ({ additionalData }: Lead) => ([
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.label),
    value: mapProductInterests(additionalData),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.homeOwner.label),
    value: mapHomeOwner(additionalData),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.numberOfResidents),
    value: additionalData ? additionalData.numberOfResidents : '',
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.motivation.label),
    value: mapMotivations(additionalData),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.purchasingTime.label),
    value: mapPurchasingTime(additionalData),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.budget),
    value: additionalData!.budget ? mapBudget(additionalData!.budget) : '',
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.additionalDetails.financingInterest.label),
    value: mapFinancingInterest(additionalData!.financingInterest!),
  },
]);
