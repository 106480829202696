import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web';
import { isUndefined } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { MeterInstallation } from '../../contract';
import { isFSTCheckFalse, isFSTCheckNotRequired } from '../../contract/contract.helpers';
import { WarningFactory } from './warnings.types';

export const missingPvRemoteControlWarningFactory: WarningFactory<MeterInstallation | undefined> =
  meterInstallation => {
    if (isUndefined(meterInstallation) || isFSTCheckFalse(meterInstallation.fstCheckSuccessful)) {
      return ({
        message: I18n.t(T.warning.fstNotYetTested),
        messageExtended: I18n.t(T.warning.fstNotYetTestedExtended),
        type: StatusType.DANGER,
      });
    }
    if (isFSTCheckNotRequired(meterInstallation.fstCheckSuccessful)) {
      return ({
        message: I18n.t(T.warning.fstNotNeeded),
        type: StatusType.DEFAULT,
      });
    }
    return ({
      message: I18n.t(T.warning.fstCheckSuccessful),
      type: StatusType.SUCCESS,
    });
  };
