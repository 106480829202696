import { LEAD_IN_SETUP_STAGE, ROUTES } from '+app/router';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getLead } from '+lead/store/+lead.selectors';
import { DsoCommissioning } from '+setupTool/+dsoCommissioning';
import { DsoRegistration } from '+setupTool/+dsoRegistration';
import { DsoRegistrationForm } from '+setupTool/+form';
import { getFormFulfillment } from '+setupTool/+form/store/+form.selectors';
import { PreCommissioning } from '+setupTool/+preCommissioning/containers';
import { PVRegister } from '+setupTool/+pvRegister';
import { VppDocumentation } from '+setupTool/+vppDocumentation/containers';
import { SetupToolContact } from '+setupTool/components/SetupToolContact';
import { SetupToolDashboard } from '+setupTool/index';
import { getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { getVppCapability } from '+setupTool/store/+setupToolSteps.selectors';
import { StoreState } from '+shared/store/store.interface';
import { Loadable } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';

import './SetupTool.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  submissionId: getSubmissionId(state),
  isFormFulfilled: getFormFulfillment(state),
  isVppCapable: getVppCapability(state),
});

const mapDispatchToProps = () => ({
  markAsSeen: LeadPageActions.markLeadAsSeen,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

class SetupToolComponent extends React.PureComponent<Props> {
  componentDidMount() {
    const { lead } = this.props;

    if (lead && lead.isNewlyAssigned) {
      this.props.markAsSeen();
    }
  }

  render() {
    const { submissionId, isFormFulfilled, isVppCapable, lead, leadStage } = this.props;

    return (
      <>
        <Loadable predicate={!(lead && lead.id)}>
          <SetupToolDashboard />
          <Switch>
            {!!submissionId &&
              <>
                <Route
                  exact={true}
                  path={ROUTES.LEAD_SETUP_TOOL_SETUP_DATA}
                  component={DsoRegistrationForm}
                />
                <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_SETUP_DATA}
                  render={(props) => (
                    <DsoRegistrationForm {...props} leadStage={LEAD_IN_SETUP_STAGE} />
                  )}
                />
                {isFormFulfilled && <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_REGISTRATION}
                  component={DsoRegistration}
                />}
                <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_DSO_COMMISSIONING}
                  component={DsoCommissioning}
                />
                {isFormFulfilled && <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_PV_REGISTER}
                  component={PVRegister}
                />}
                <Route
                  exact={true}
                  path={ROUTES.LEAD_SETUP_TOOL_METER_PREPARATION}
                  component={DsoRegistrationForm}
                />
                {isVppCapable && <Route
                  exact={true}
                  path={ROUTES.LEAD_SETUP_TOOL_VPP_DOCUMENTATION}
                  component={VppDocumentation}
                />}
                {isVppCapable && <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_VPP_DOCUMENTATION}
                  render={() => (
                    <VppDocumentation leadStage={LEAD_IN_SETUP_STAGE} />
                  )}
                />}
                <Route
                  exact={true}
                  path={ROUTES.SETUP_LEAD_SETUP_TOOL_PRE_COMMISSIONING}
                  render={() => (
                    <PreCommissioning leadStage={LEAD_IN_SETUP_STAGE} />
                  )}
                />
              </>}
            {/* this one doesn't require submissionId as it's available only for some countries if rollout FF is enabled */}
            <Route
              exact={true}
              path={ROUTES.LEAD_SETUP_TOOL_PRE_COMMISSIONING}
              component={PreCommissioning}
            />
          </Switch>
        </Loadable>
        <SetupToolContact />
      </>
    );
  }
}

export const SetupTool = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupToolComponent);
