import { Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { Container } from '../Container';

import './PageHeader.component.scss';

interface Props {
  className?: ClassValue;
  breadcrumb?: React.ReactNode;
  tabNav?: React.ReactNode;
  isExpanded?: boolean;
  isLoading?: boolean;
}

export const PageHeader: React.SFC<Props> = ({
  tabNav,
  children,
  className,
  breadcrumb,
  isExpanded = false,
  isLoading = false,
}) => (
  <header className={classNames('c-page-header', className)}>
    <Media query={'(min-width: 768px)'}>
      {(isDesktopMedia: boolean) => (
        <Container
          withVerticalPadding={false}
          withHorizontalPadding={isDesktopMedia}
        >
          <div className={'c-page-header__container'}>
            {breadcrumb && (
              <div className={'c-page-header__breadcrumb'}>
                {breadcrumb}
              </div>
            )}
            <div className={classNames('c-page-header__headline', {
              'c-page-header__headline--expanded': isExpanded,
            })}>
              {isLoading ?
                <Loader className={'c-page-header__loader'} />
                : children
              }
            </div>
          </div>
          {tabNav && (
            <div className={'c-page-header__tab-nav'}>
              {tabNav}
            </div>
          )}
        </Container>
      )}
    </Media>
  </header>
);
