import { Contact } from '+shared/store/contact/types';
import { TableCell } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { ClassValue } from 'classnames/types';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';
import { InboxLeadListPartnerName } from '../InboxLeadListPartnerName';
import { LeadListAssignModalFormType } from '../LeadListAssignModalForm/LeadListAssignModalForm.helper';

import './InboxLeadListTableRadioBtn.component.scss';

interface Props {
  name: string;
  partner: Contact;
  value: string;
  form: FormikProps<LeadListAssignModalFormType>;
  className?: ClassValue;
}

export const InboxLeadListTableRadioBtn: React.FC<Props> = ({
  form,
  name,
  partner,
  value,
  className,
}) => {
  const id = `${name}-${value}`;

  const handleOnChange = () => {
    form.setFieldValue(name, value);
    form.setFieldTouched(name);
  };

  return (
    <div className={classNames('inbox-lead-list-rable-radio-btn', className)}>
      <input
        id={id}
        className={'inbox-lead-list-rable-radio-btn-radio'}
        type={'radio'}
        checked={get(name)(form.values) === value}
        onChange={handleOnChange}
      />
      <label
        className={'inbox-lead-list-rable-radio-btn-label'}
        htmlFor={id}
      >
        <TableCell className={'inbox-lead-list-rable-radio-btn__cell'}>
          <InboxLeadListPartnerName partner={partner} />
        </TableCell>
        <TableCell className={'inbox-lead-list-rable-radio-btn__cell inbox-lead-list-rable-radio-btn__address'}>
          {/* TODO: place address of partner when sonnenAPI ready */}
        </TableCell>
        <span className={'inbox-lead-list-rable-radio-btn-circle'} />
      </label>
    </div>
  );
};
