import { getLeadData } from '+app/+lead/+overview/store/+overview.selectors';
import {
  DsoRegistrationFormContainer,
  DsoRegistrationFormRadioGroup,
  DsoRegistrationPvSystemExtension,
  DsoRegistrationPvSystemShared,
} from '+setupTool/+form/components';
import {
  FieldsRequiredOnlyForExtension,
  getDsoRegistrationPvSystemInitial,
  getDsoRegistrationPvSystemSchema,
  getDsoRegistrationSonnenBatteryInitial,
} from '+setupTool/+form/store/schemas';
import {
  CouplingType,
  DsoRegistrationPvSystemInterface,
  DsoRegistrationRadioInvestmentType,
} from '+setupTool/+form/store/types';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SetupDataStepKey, SetupDataStepStatus, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import {
  FormInputRadioGroup,
  FormWizardStep,
} from '+shared/components';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DsoRegistrationFormActions } from '../../store/+form.actions';
import {
  setAllFormFieldsTouched,
} from '../../store/+form.helpers';
import {
  getActiveStep,
  getBatteryNames,
  getPVInverters,
  getPVModules,
  getPvSystemForm,
  getSonnenBatteryForm,
} from '../../store/+form.selectors';
import './DsoRegistrationPvSystem.component.scss';

interface OwnProps {
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  pvSystemForm: getPvSystemForm(state),
  lead: getLeadData(state),
  pvModules: getPVModules(state),
  pvInverters: getPVInverters(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
  sonnenBatteryForm: getSonnenBatteryForm(state),
  batteryNames: getBatteryNames(state),
});

const mapDispatchToProps = mapActions({
  setDsoPvSystem: DsoRegistrationFormActions.setDsoPvSystem,
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  setDsoSonnenBattery: DsoRegistrationFormActions.setDsoSonnenBattery,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const DsoRegistrationPvSystemComponent: React.FC<Props> = ({
  actions,
  pvSystemForm,
  activeStep,
  stepStatus,
  registrationSubject,
  sonnenBatteryForm,
  batteryNames,
}) => {
  actions.toggleModal(true);
  const wizard = React.useContext(WizardContext);
  const formRef = React.useRef<FormikProps<DsoRegistrationPvSystemInterface>>({} as any);
  const validationSchema = getDsoRegistrationPvSystemSchema(registrationSubject);
  const dsoRegistrationPvSystemInitial = getDsoRegistrationPvSystemInitial(registrationSubject);
  const dsoRegistrationSonnenBatteryInitial = getDsoRegistrationSonnenBatteryInitial(registrationSubject);
  const isBatteryOnly = registrationSubject === RegistrationSubjectType.BATTERY;
  const currentBattery = sonnenBatteryForm
    ? batteryNames.find(battery => battery.name === sonnenBatteryForm.battery_name)
    : undefined;
  const currentBatteryRef = React.useRef(currentBattery);
  const pvSystemFormRef = React.useRef(pvSystemForm);
  const radioInputCollectionPvAndBattery = [
    {
      value: DsoRegistrationRadioInvestmentType.NEW,
      label: I18n.t(T.setupTool.form.newInvestment),
    },
    {
      value: DsoRegistrationRadioInvestmentType.EXTENSION,
      label: I18n.t(T.setupTool.form.extensionInvestment),
    },
  ];
  const radioInputCollectionBatteryOnly = [
    {
      value: DsoRegistrationRadioInvestmentType.NEW,
      label: I18n.t(T.setupTool.form.newInvestment),
    },
  ];
  const radioInputCollection = isBatteryOnly ? radioInputCollectionBatteryOnly : radioInputCollectionPvAndBattery;

  React.useEffect(() => {
    pvSystemFormRef.current = pvSystemForm;
  }, [pvSystemForm]);

  React.useEffect(() => {
    currentBatteryRef.current = currentBattery;
  }, [currentBattery]);

  React.useEffect(() => {
    if (pvSystemForm) {
      formRef.current.setValues(pvSystemForm);

      if (stepStatus === SetupDataStepStatus.STARTED || stepStatus === SetupDataStepStatus.COMPLETED) {
        setAllFormFieldsTouched(formRef.current, FieldsRequiredOnlyForExtension);
      }
    }
  }, [pvSystemForm]);

  React.useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_PV_SYSTEM);
    activeStep = SubmissionStep.SETUP_DATA_PV_SYSTEM;

    return saveValues;
  }, []);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
      document.body.classList.add('body--disabled');
    }
  };

  const saveValues = () => {
    const currentBattery = currentBatteryRef.current;
    const pvSystemForm = pvSystemFormRef.current;
    const values = formRef.current.values;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
    const newStepStatus = invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;
    const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
      ({...prev, [fieldKey]: dsoRegistrationPvSystemInitial[fieldKey]}), {});
    const pvFormFieldsImplyACBattery = formRef.current
      && (formRef.current.values.pv_type === DsoRegistrationRadioInvestmentType.EXTENSION
        || formRef.current.values.dc_coupled_battery === false);
    const pvFormFieldsImplyDCBattery = formRef.current
      && formRef.current.values.dc_coupled_battery === true;

    if (atLeastOneFieldTouched && pvSystemForm && !isEqual(pvSystemForm, values)) {
      let changedSonnenBattery = false;

      // code for clearing sonnen battery
      if (
        currentBattery
        && ((currentBattery.couplingType === CouplingType.DC && pvFormFieldsImplyACBattery)
        || (currentBattery.couplingType === CouplingType.AC && pvFormFieldsImplyDCBattery))
      ) {
        actions.setDsoSonnenBattery({
          ...sonnenBatteryForm,
          battery_nominal_capacity_kWh: dsoRegistrationSonnenBatteryInitial.battery_nominal_capacity_kWh,
          battery_id: dsoRegistrationSonnenBatteryInitial.battery_id,
          battery_name: dsoRegistrationSonnenBatteryInitial.battery_name,
        });
        changedSonnenBattery = true;
      }

      actions.setSetupDataStatuses({ pvSystem: newStepStatus });
      actions.setDsoPvSystem({ ...values, ...invalidOrEmptyValues });
      actions.checkForm(registrationSubject);
      if (changedSonnenBattery) {
        actions.saveData(SubmissionStep.SETUP_DATA_PV_SYSTEM_AND_SONNEN_BATTERY, {
          [SetupDataStepKey.SONNEN_BATTERY]: SetupDataStepStatus.STARTED,
          [SetupDataStepKey.PV_SYSTEM]: newStepStatus,
        });
      } else {
        actions.saveData(activeStep, { [SetupDataStepKey.PV_SYSTEM]: newStepStatus });
      }
    }
  };

  return (
    <Formik
      initialValues={dsoRegistrationPvSystemInitial}
      validationSchema={validationSchema}
      validate={() => {
        if (!isBatteryOnly && !!formRef.current.values.dc_coupled_battery &&
          (formRef.current.values.pv_inverter_type !== dsoRegistrationPvSystemInitial.pv_inverter_type ||
            formRef.current.values.pv_inverter_id !== dsoRegistrationPvSystemInitial.pv_inverter_id ||
            formRef.current.values.number_identical_inverters
              !== dsoRegistrationPvSystemInitial.number_identical_inverters)
        ) {
          formRef.current.setValues({
            ...formRef.current.values,
            pv_inverter_type: dsoRegistrationPvSystemInitial.pv_inverter_type,
            pv_inverter_id: dsoRegistrationPvSystemInitial.pv_inverter_id,
            number_identical_inverters: dsoRegistrationPvSystemInitial.number_identical_inverters,
          });
        }
      }}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={form => (
        <Form className={'c-form-wizard__form'}>
            <FormWizardStep
              onBeforeSubmit={() => {
                // Enable saving partially complete data
                onSubmit();
              }}
            >
              <DsoRegistrationFormContainer>
                <DsoRegistrationFormRadioGroup
                  className={isBatteryOnly
                    ? 'c-dso-registration-form-radio-group--hidden'
                    : 'c-dso-registration-form-radio-group--controlling-section'
                }>
                  <FormInputRadioGroup
                    collection={radioInputCollection}
                    form={form}
                    name={'pv_type'}
                    text={I18n.t(T.setupTool.form.investmentTypeQuestion)}
                  />
                </DsoRegistrationFormRadioGroup>
                {form.values.pv_type === DsoRegistrationRadioInvestmentType.EXTENSION
                  ? <DsoRegistrationPvSystemExtension
                    form={form}
                    />
                    : <DsoRegistrationPvSystemShared
                    form={form}
                    isNew={true}
                    isBatteryOnly={isBatteryOnly}
                  />}
              </DsoRegistrationFormContainer>
            </FormWizardStep>
          </Form>
        )
      }
    />
  );
};

export const DsoRegistrationPvSystem = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationPvSystemComponent);
