import { getLeadOfferData } from '+app/+lead/+offer/store/+offer.selectors';
import { getLead } from '+app/+lead/store/+lead.selectors';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { isNil } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  LeadConfigurationAutarky,
  LeadConfigurationPreview,
} from '../../components';
import { LeadConfigurationPreviewInitial } from '../../components/LeadConfigurationPreviewInitial';
import { ConfigurationPageActions } from '../../store/+configuration.actions';
import {
  getConfigurationForm,
  getConfigurationProposal,
  getConfigurationSubmitQueryStatus,
  getSaveConfigurationQueryStatus,
  getSetConfigurationProposalQueryStatus,
} from '../../store/+configuration.selectors';

import './LeadConfigurationSummary.component.scss';

const mapStateToProps = (state: StoreState) => ({
  configurationProposal: getConfigurationProposal(state),
  lead: getLead(state),
  setConfigurationProposalQueryStatus: getSetConfigurationProposalQueryStatus(state),
  saveConfigurationQueryStatus: getSaveConfigurationQueryStatus(state),
  configurationSubmitQueryStatus: getConfigurationSubmitQueryStatus(state),
  configurationForm: getConfigurationForm(state),
  offer: getLeadOfferData(state),
});

const mapDispatchToProps = mapActions({
  saveConfiguration: ConfigurationPageActions.saveConfiguration,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const LeadConfigurationSummaryComponent: React.FC<Props> = ({
  configurationProposal,
  lead,
  configurationForm,
  setConfigurationProposalQueryStatus,
  saveConfigurationQueryStatus,
  configurationSubmitQueryStatus,
  actions,
  offer,
}) => {
  const [loaderText, setLoaderText] = React.useState<string | null>(null);
  const isPending = !!loaderText;
  const expectedAutarky = configurationProposal && configurationProposal.powerPlant.expectedAutarky?.value;
  const isSubmitDisabled = setConfigurationProposalQueryStatus.pending
    || saveConfigurationQueryStatus.pending;
  const { track } = useTracking();

  React.useEffect(() => {
    // Autarky change
    if (setConfigurationProposalQueryStatus.pending) {
      setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.recalculating));
    } else {
      setLoaderText(null);
    }
  }, [setConfigurationProposalQueryStatus]);

  React.useEffect(() => {
    if (configurationSubmitQueryStatus.pending) {
      configurationProposal
        ? setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.recalculating))
        : setLoaderText(I18n.t(T.lead.configuration._salessolution_.configurations.calculating));
    } else {
      setLoaderText(null);
    }
  }, [configurationSubmitQueryStatus]);

  const onSave = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (configurationProposal) {
      track(InteractionEvents.buttonClick({
        category: EventCategory.SALES_CONFIGURATOR,
        action: 'Save Configuration',
        event,
      }));

      if (configurationForm && configurationForm.newBattery) {
        // TODO: replace capacityGross with a proper form name
        const batteryId = configurationForm.capacityGross;
        actions.saveConfiguration(configurationProposal.id, offer?.id, batteryId);
      } else {
        actions.saveConfiguration(configurationProposal.id, offer?.id);
      }
    }
  };

  return (
    <div className={classNames('c-lead-configuration-summary', {
      'c-lead-configuration-summary--initial': !configurationProposal,
    })}>
      {isPending &&
        <div className={'c-lead-configuration-summary__loader-wrapper'}>
          <Loader className={'c-lead-configuration-summary__loader'} />
          <p>{loaderText}</p>
        </div>
      }
      <div className={'c-lead-configuration-summary__header'}>
        <h2 className={'c-lead-configuration-summary__headline'}>
          {I18n.t(T.lead.configuration._salessolution_.summary.header)}
        </h2>
        <p className={'c-lead-configuration-summary__description'}>
          {configurationProposal ?
            I18n.t(T.lead.configuration._salessolution_.summary.initialText)
            : I18n.t(T.lead.configuration._salessolution_.summary.summaryText)
          }
        </p>
      </div>
      {configurationProposal ?
        <>
          <LeadConfigurationPreview
            lead={lead!}
            configProposal={configurationProposal!}
          />
          {!isNil(expectedAutarky) && (
            <LeadConfigurationAutarky
              expectedAutarky={expectedAutarky}
            />
          )}

          <div className={'c-lead-configuration-summary__vat'}>
            <FormInputInfo isBold={true}>
              {I18n.t(T.lead.configuration._salessolution_.preview.vatInfo,
                { vat: parseFloat(configurationProposal.valueAddedTax.value.toString()) })}
            </FormInputInfo>
          </div>

          <div className={'c-lead-configuration-summary__separator'} />
          <div className={'c-lead-configuration-summary__btn'}>
            <Button
              label={I18n.t(T.lead.configuration._salessolution_.summary.save)}
              size={ButtonSize.PRIMARY}
              onClick={onSave}
              isDisabled={isSubmitDisabled}
              isLoading={saveConfigurationQueryStatus.pending}
            />
          </div>
        </>
        : <LeadConfigurationPreviewInitial />}
    </div>
  );
};

export const LeadConfigurationSummary =
  connect(mapStateToProps, mapDispatchToProps)(LeadConfigurationSummaryComponent);
