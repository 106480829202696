import { StoreType } from '+shared/store/store.interface';
import { storeProvider } from '+shared/store/store.provider';
import { loadTranslations } from 'react-redux-i18n';
import { DEFAULT_LOCALE, Locale } from './i18n.config';

export const getLanguage = async (languageCode: string) => {
  switch (languageCode) {
      case 'de': return await import('@sonnen/shared-i18n/dist/service/languages/de');
      case 'en-gb': return await import('@sonnen/shared-i18n/dist/service/languages/en-gb');
      case 'en-us': return await import('@sonnen/shared-i18n/dist/service/languages/en-us');
      case 'fr': return await import('@sonnen/shared-i18n/dist/service/languages/fr');
      case 'it': return await import('@sonnen/shared-i18n/dist/service/languages/it');
      default: return await import('@sonnen/shared-i18n/dist/service/languages/en-us');
  }
};

export const getBrowserLocale = (): Locale => {
  const urlLocale = new URLSearchParams(window.location.search).get('lang');
  const storageLocale = localStorage && localStorage.getItem('languageCode');
  const navigatorLocale = navigator.language.split('-')[0];
  const locale: Locale = (urlLocale || storageLocale || navigatorLocale || DEFAULT_LOCALE) as Locale;

  return locale;
};

export const setBrowserLocale = (locale: Locale) => {
  localStorage.setItem('languageCode', locale);
};

export const loadCurrentTranslations = async (store: StoreType, locale: Locale) => {
  const translations = await getLanguage(locale);
  loadTranslations({ [locale]: translations.locale } as any)(store.dispatch);
};

export const provideLocale = () =>
  storeProvider.getState().i18n.locale;
