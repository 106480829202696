import { UserRole } from '../user';

// @TODO: temporary
export const customerTypes = ['private', 'business'];
export const salutation = ['mr', 'ms'];
export const countries = ['CH', 'DE', 'AT'];

// Not temporary:
export const leadTabAllowedRoles = [UserRole.SALES_AGENT, UserRole.COMPANY_COORDINATOR];

export const setupLeadTabAllowedRoles = [
    UserRole.SALES_AGENT,
    UserRole.COMPANY_COORDINATOR,
    UserRole.SETUP_COORDINATOR,
];
