import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { SetupDataStepStatuses } from '+shared/store/setupTool/types';
import { ActionsUnion, createAction } from '../../../utils';
import {
  DsoRegistrationFormResponse,
  DsoRegistrationPatchSubmissionResponse,
  DsoSubmissionData,
} from './types';

export enum DSO_REGISTER_ACTIONS {
  POST_REGISTER_DSO = '[DSO_Register] POST_REGISTER_DSO',
  POST_REGISTER_DSO_SUCCESS = '[DSO_Register] POST_REGISTER_DSO_SUCCESS',
  POST_REGISTER_DSO_FAILURE = '[DSO_Register] POST_REGISTER_DSO_FAILURE',

  POST_SEND_DSO_DOCUMENT = '[DSO_Register] POST_SEND_DSO_DOCUMENT',
  POST_SEND_DSO_DOCUMENT_SUCCESS = '[DSO_Register] POST_SEND_DSO_DOCUMENT_SUCCESS',
  POST_SEND_DSO_DOCUMENT_FAILURE = '[DSO_Register] POST_SEND_DSO_DOCUMENT_FAILURE',

  REMOVE_DSO_DOCUMENT = '[DSO_Register] REMOVE_DSO_DOCUMENT',
  REMOVE_DSO_DOCUMENT_SUCCESS = '[DSO_Register] REMOVE_DSO_DOCUMENT_SUCCESS',
  REMOVE_DSO_DOCUMENT_FAILURE = '[DSO_Register] REMOVE_DSO_DOCUMENT_FAILURE',

  POST_REGISTER_PV = '[DSO_Register] POST_REGISTER_PV',
  POST_REGISTER_PV_SUCCESS = '[DSO_Register] POST_REGISTER_PV_SUCCESS',
  POST_REGISTER_PV_FAILURE = '[DSO_Register] POST_REGISTER_PV_FAILURE',

  POST_VPP = '[DSO_Register] POST_VPP',
  POST_VPP_SUCCESS = '[DSO_Register] POST_VPP_SUCCESS',
  POST_VPP_FAILURE = '[DSO_Register] POST_VPP_FAILURE',

  GET_SUBMISSIONS_POLLING_SUCCESS = '[DSO_Register] GET_SUBMISSIONS_POLLING_SUCCESS',
  GET_SUBMISSIONS_POLLING_FAILURE = '[DSO_Register] GET_SUBMISSIONS_POLLING_FAILURE',

  POST_SOLVE_CAPTCHA_PV = '[DSO_Register] POST_SOLVE_CAPTCHA_PV',

  GENERATE_DOCUMENTS = '[DSO_Register] GENERATE_DOCUMENTS',

  PATCH_SUBMISSION = '[DSO_Register] PATCH_SUBMISSION',
  PATCH_SUBMISSION_SUCCESS = '[DSO_Register] PATCH_SUBMISSION_SUCCESS',
  PATCH_SUBMISSION_FAILURE = '[DSO_Register] PATCH_SUBMISSION_FAILURE',

  PATCH_REGISTRATION_TYPE_SUBMISSION = '[DSO_Register] PATCH_REGISTRATION_TYPE_SUBMISSION',
}

export const DsoRegisterActions = {
  postRegisterDso: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_DSO,
    (opts: { data: DsoSubmissionData, queryKey: string }) => ({ ...opts }),
  ),
  postRegisterDsoSuccess: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_DSO_SUCCESS,
    (config: { data: DsoRegistrationFormResponse }) => ({ config }),
  ),
  postRegisterDsoFailure: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_DSO_FAILURE,
    (error: any /* @TODO API CHANGE */) => ({ error }),
  ),
  postSendDsoDocument: createAction(
    DSO_REGISTER_ACTIONS.POST_SEND_DSO_DOCUMENT,
    (opts: {
      userId: string, customerId: string, submissionId: string, category: string, file: any, queryKey: string,
    }) => ({ ...opts }),
  ),
  postSendDsoDocumentSuccess: createAction(
    DSO_REGISTER_ACTIONS.POST_SEND_DSO_DOCUMENT_SUCCESS,
    (response: any /* @TODO API CHANGE */) => ({ response }),
  ),
  postSendDsoDocumentFailure: createAction(
    DSO_REGISTER_ACTIONS.POST_SEND_DSO_DOCUMENT_FAILURE,
    (error: any /* @TODO API CHANGE */) => ({ error }),
  ),
  removeDsoDocument: createAction(
    DSO_REGISTER_ACTIONS.REMOVE_DSO_DOCUMENT,
    (opts: {
      submissionId: string, documentId: string, queryKey: string,
    }) => ({ ...opts }),
  ),
  removeDsoDocumentSuccess: createAction(
    DSO_REGISTER_ACTIONS.REMOVE_DSO_DOCUMENT_SUCCESS,
    (response: any /* @TODO API CHANGE */) => ({ response }),
  ),
  removeDsoDocumentFailure: createAction(
    DSO_REGISTER_ACTIONS.REMOVE_DSO_DOCUMENT_FAILURE,
    (error: any /* @TODO API CHANGE */) => ({ error }),
  ),
  postRegisterPV: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_PV,
    (opts: { submissionId: string, queryKey: string }) => ({ ...opts }),
  ),
  postRegisterPVSuccess: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_PV_SUCCESS,
    (response: any /* @TODO */) => ({ response }),
  ),
  postRegisterPVFailure: createAction(
    DSO_REGISTER_ACTIONS.POST_REGISTER_PV_FAILURE,
    (error: any /* @TODO */) => ({ error }),
  ),
  postVpp: createAction(
    DSO_REGISTER_ACTIONS.POST_VPP,
    (opts: { submissionId: string, queryKey: string, fields: any }) => ({ ...opts }),
  ),
  postVppSuccess: createAction(
    DSO_REGISTER_ACTIONS.POST_VPP_SUCCESS,
    (response: DsoRegistrationFormResponse) => ({ response }),
  ),
  postVppFailure: createAction(
    DSO_REGISTER_ACTIONS.POST_VPP_FAILURE,
    (error: any /* @TODO */) => ({ error }),
  ),
  postSolveCaptchaPV: createAction(
    DSO_REGISTER_ACTIONS.POST_SOLVE_CAPTCHA_PV,
    (opts: { id: string, solution: string, queryKey: string, submissionId: string }) => ({ ...opts }),
  ),
  getSubmissionsPollingSuccess: createAction(
    DSO_REGISTER_ACTIONS.GET_SUBMISSIONS_POLLING_SUCCESS,
    (response: any /* @TODO API CHANGE */) => ({ response }),
  ),
  getSubmissionsPollingFailure: createAction(
    DSO_REGISTER_ACTIONS.GET_SUBMISSIONS_POLLING_FAILURE,
    (error: any /* @TODO  API CHANGE */) => ({ error }),
  ),
  patchSubmission: createAction(
    DSO_REGISTER_ACTIONS.PATCH_SUBMISSION,
    (opts: {
      data: DsoSubmissionData,
      submissionId: string,
      registrationSubject: RegistrationSubjectType,
      dsoReferenceNumberPvVisible: boolean,
      fasteningTypeConsumptionVisible: boolean,
      meterCabinetPreparedVisible: boolean,
      productionMeterVisible: boolean,
      stepStatuses?: SetupDataStepStatuses,
      queryKey: string,
    }) => ({ ...opts }),
  ),
  patchSubmissionSuccess: createAction(
    DSO_REGISTER_ACTIONS.PATCH_SUBMISSION_SUCCESS,
    (config: { data: DsoRegistrationPatchSubmissionResponse }) => ({ config }),
  ),
  patchSubmissionFailure: createAction(
    DSO_REGISTER_ACTIONS.PATCH_SUBMISSION_FAILURE,
    (error: any /* @TODO */) => ({ error }),
  ),
  patchRegistrationTypeSubmission: createAction(
    DSO_REGISTER_ACTIONS.PATCH_REGISTRATION_TYPE_SUBMISSION,
    (opts: {
      submissionId: string,
      queryKey: string,
    }) => ({ ...opts }),
  ),
};

export type DsoRegisterActions = ActionsUnion<typeof DsoRegisterActions>;
