import { LeadListPageActions } from '+app/+lead/+list/store';
import {
  getPreviousLeadStatusFilters,
  getPreviousSetupLeadStatusFilters,
} from '+app/+lead/+list/store/+leadList.selectors';
import { mapToState, ofType } from '+app/utils';
import { AuthActions } from '+shared/store/auth';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { LayoutActions } from '+shared/store/layout';
import { SiteActions } from '+shared/store/site';
import { StoreState } from '+shared/store/store.interface';
import { go, push } from 'connected-react-router';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, iif, of } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { PATHS, ROUTES } from '../routes';
import { RouterActions } from './router.actions';
import { getLastLocationIndex, getLastMatchingHistoryElement, isGoBackAction } from './router.selectors';

type Action$ = ActionsObservable<AuthActions | RouterActions>;
type State$ = StateObservable<StoreState>;

export const isReady$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.isRehydrated, AuthActions.setTokenStorage, RouterActions.locationChange),
  withLatestFrom(state$),
  filter(([_, state]) => isAuthenticated(state)),
  mergeMap(([action, state]) => iif(
    () => isGoBackAction(state),
    of(
      RouterActions.isReady(action.type === RouterActions.locationChange.type),
      LayoutActions.toggleNavigation(false),
      LayoutActions.toggleModal(false),
      SiteActions.clearSite(),
    ),
    of(
      RouterActions.isReady(action.type === RouterActions.locationChange.type),
      LayoutActions.toggleNavigation(false),
      LayoutActions.scrollToTop(),
    ),
  ),
  ),
);

export const goToDashboard$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.goToDashboard),
  mapToState(state$),
  map(getLastLocationIndex(ROUTES.DASHBOARD)),
  map(index => index ? go(index) : push(PATHS.DASHBOARD())),
);

export const goToCustomers$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.goToCustomers),
  mapToState(state$),
  map(getLastLocationIndex(ROUTES.CUSTOMERS)),
  map(index => index ? go(index) : push(PATHS.CUSTOMERS({}))),
);

export const goBackToLeads$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.goBackToLeads),
  mapToState(state$),
  mergeMap(state => forkJoin(
    of(state).pipe(
      map(getLastMatchingHistoryElement(ROUTES.LEADS)),
    ),
    of(state).pipe(
      map(getPreviousLeadStatusFilters),
    ),
  )),
  mergeMap(([location, statusList]) =>
    concat(
      of(LeadListPageActions.setLeadStatusFilters(statusList)),
      of(push(location || PATHS.LEADS())),
    ),
  ),
);

export const goBackToSetupLeads$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.goBackToSetupLeads),
  mapToState(state$),
  mergeMap(state => forkJoin(
    of(state).pipe(
      map(getLastMatchingHistoryElement(ROUTES.SETUP_LEADS)),
    ),
    of(state).pipe(
      map(getPreviousSetupLeadStatusFilters),
    ),
  )),
  mergeMap(([location, statusList]) =>
    concat(
      of(LeadListPageActions.setSetupLeadStatusFilters(statusList)),
      of(push(location || PATHS.SETUP_LEADS())),
    ),
  ),
);

export const changeCustomerSite$ = (action$: Action$) => action$.pipe(
  ofType(RouterActions.changeCustomerSite),
  map((action) => push(PATHS.CUSTOMER({ customerId: action.customerId, siteId: action.siteId }))),
);

export const goToNewsInbox$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.goToNewsInbox),
  mapToState(state$),
  map(getLastLocationIndex(ROUTES.NEWS_INBOX)),
);

export const epics = combineEpics<any>(
  isReady$,
  goToDashboard$,
  goToCustomers$,
  goBackToLeads$,
  goBackToSetupLeads$,
  changeCustomerSite$,
  goToNewsInbox$,
);
