import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserRepository } from '../user/user.repository';
import { SyncData } from './sync.state';

type SyncHandler = (data: SyncData) => Observable<SyncData>;

const getUserMe: SyncHandler = () =>
  UserRepository.getUserMe().pipe(
    map(({ element: profile }) => ({ profile })),
    catchError(error => throwError({ error, data: {} })),
  );

export const SyncRepository = {
  getUserMe,
};
