import { FileCategoryName } from '+setupTool/+form/store/+form.dictionary';
import {
  DsoRegistrationRadioInvestmentType,
  DsoRegistrationSetupDataStatusesInterface,
} from '+setupTool/+form/store/types';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { anyValueGuard } from '+utils/index';
import * as Yup from 'yup';
import {
  DsoRegistrationCustomerDataSchema,
  DsoRegistrationDocumentsSchema,
  DsoRegistrationInstallerDataSchema,
  getDsoRegistrationMeasuringDeviceSchema,
  getDsoRegistrationPvSystemSchema,
  getDsoRegistrationSonnenBatterySchema,
} from './schemas';
import {
  DsoRegistrationInstallerDataInterface,
  DsoRegistrationInstallerDataInterfaceForCreatingInstaller,
} from './types';

import { T } from '@sonnen/shared-i18n/service';
import { FormikProps } from 'formik';
import { isUndefined, omit, omitBy, pick } from 'lodash';
import { I18n } from 'react-redux-i18n';

// This helper function cannot be moved to setupTool.helpers because of circular dependency issues
export const trimFieldValues = (fieldValues: any): ReturnType<typeof fieldValues> => {
  if (!fieldValues) {
    return;
  }

  Object.keys(fieldValues).map(prop => fieldValues[prop] = typeof fieldValues[prop] === 'string'
    ? fieldValues[prop].trim()
    : fieldValues[prop]);
  return fieldValues;
};

export const getCustomerDataProps = (data: any) => {
  return anyValueGuard({
    customer_salutation: data.customerSalutation,
    customer_first_name: data.customerFirstName,
    customer_last_name: data.customerLastName,
    customer_street: data.customerStreet,
    customer_street_number: data.customerStreetNumber,
    customer_zip_code: data.customerZipCode,
    customer_city: data.customerCity,
    customer_email: data.customerEmail,
    customer_phone: data.customerPhone,
    customer_birthday: data.customerBirthday,
    customer_type: data.customerType,
    asset_street: data.assetStreet,
    asset_street_number: data.assetStreetNumber,
    asset_zip_code: data.assetZipCode,
    asset_city: data.assetCity,
    lot_number: data.lotNumber,
    dso_id: data.dsoId,
    is_online_portal: data.isOnlinePortal,
    origin_customer_street_and_number: data.originCustomerStreetAndNumber,
    origin_asset_street_and_number: data.originAssetStreetAndNumber,
  });
};

export const getPvSystemProps = (data: any, registrationSubject: RegistrationSubjectType) => {
  const pvSystemProps = anyValueGuard({
    pv_type: data.pvType,
    panel_location: data.panelLocation,
    panel_alignment: data.panelAlignment,
    tilt_angle: data.tiltAngle,
    pv_module_id: data.pvModuleId,
    pv_module_vendor: data.pvModuleVendor,
    pv_module_type: data.pvModuleType,
    pv_module_count: data.pvModuleCount,
    dc_coupled_battery: data.dcCoupledBattery,
    pv_inverter_id: data.pvInverterId,
    pv_inverter_name: data.pvInverterName,
    pv_inverter_type: data.pvInverterType,
    number_identical_inverters: data.numberIdenticalInverters,
    new_pv_size: data.newPvSize,
    date_of_application: data.dateOfApplication,
    existing_pv_size: data.existingPvSize,
    existing_pv_infeed: data.existingPvInfeed,
    mastr_number: data.mastrNumber,
    existing_pv_inverter_manufacturer: data.existingPvInverterManufacturer,
    existing_pv_inverter_type: data.existingPvInverterType,
    existing_pv_inverter_count: data.existingPvInverterCount,
    total_pv_size: data.totalPvSize,
  });
  return registrationSubject === RegistrationSubjectType.PV_AND_BATTERY
    ? omitBy({
      ...pvSystemProps,
      preferred_installation_date: data.preferredInstallationDate,
    }, isUndefined)
    : omitBy({
      ...pvSystemProps,
      pv_type: DsoRegistrationRadioInvestmentType.NEW,
    }, isUndefined);
};

export const getSonnenBatteryProps = (data: any, registrationSubject: RegistrationSubjectType) => {
  const sonnenBatteryProps = anyValueGuard({
    battery_name: data.batteryName,
    battery_id: data.batteryId,
    battery_nominal_capacity_kWh: data.batteryNominalCapacityKWh,
  });
  return registrationSubject === RegistrationSubjectType.BATTERY
    ? {
      ...sonnenBatteryProps,
      preferred_installation_date: data.preferredInstallationDate,
    }
    : sonnenBatteryProps;
};

export const getMeasuringDeviceProps = (data: any) => {
  return anyValueGuard({
    meter_number: data.meterNumber,
    type_of_grid_reference_meter: data.typeOfGridReferenceMeter,
    measuring_concept: data.measuringConcept,
    feed_in_management: data.feedInManagement,
    serial_number_FRE: data.serialNumberFRE,
    house_connection_power_A: data.houseConnectionPowerA,
    meter_fuse: data.meterFuse,
    meter_fastening_type: data.meterFasteningType,
  });
};
export const getDocumentsFileProps = (data: any) => {
  return anyValueGuard({
    standardSolarCircuitDiagram: data.fields.standardSolarCircuitDiagram,
    [FileCategoryName.SITE_PLAN]: data.documents
      .find((file: any) => file.upload.category === FileCategoryName.SITE_PLAN),
    [FileCategoryName.WIRING_DIAGRAM]: data.documents
      .find((file: any) => file.upload.category === FileCategoryName.WIRING_DIAGRAM),
    [FileCategoryName.ADDITIONAL_DOCUMENTS]: data.documents
      .find((file: any) => file.upload.category === FileCategoryName.ADDITIONAL_DOCUMENTS),
  });
};

export const getInstallerDataProps = (data: any) => {
  return anyValueGuard({
    installer_id: data.installerId,
    installer_company: data.installerCompany,
    installer_first_name: data.installerFirstName,
    installer_last_name: data.installerLastName,
    installer_full_name: data.installerFullName,
    installer_company_street: data.installerCompanyStreet,
    installer_company_street_number: data.installerCompanyStreetNumber,
    installer_zip_code: data.installerZipCode,
    installer_city: data.installerCity,
    installer_email: data.installerEmail,
    installer_phone: data.installerPhone,
    installer_certification_number: data.installerCertificationNumber,
    installer_certification_authority: data.installerCertificationAuthority,
    user_name: data.userName,
    password: data.password,
    is_online_portal: data.isOnlinePortal,
  });
};

export const getSetupDataSchemas = (registrationSubject?: RegistrationSubjectType) => Yup.object()
  .concat(DsoRegistrationCustomerDataSchema())
  .concat(getDsoRegistrationPvSystemSchema(registrationSubject)())
  .concat(getDsoRegistrationMeasuringDeviceSchema(registrationSubject)())
  .concat(getDsoRegistrationSonnenBatterySchema(registrationSubject)())
  .concat(DsoRegistrationInstallerDataSchema())
  .concat(DsoRegistrationDocumentsSchema());

export const setupDataStatusList = [
  'customerData',
  'pvSystem',
  'sonnenBattery',
  'measuringDevice',
  'uploadDocuments',
  'installerData',
];

export const getCompletedSteps = (
  setupDataStatuses: DsoRegistrationSetupDataStatusesInterface,
  registrationSubject?: RegistrationSubjectType,
) => {
  const setupDataStatusNameTranslations = {
    customerData: I18n.t(T.setupTool.step.customerData),
    pvSystem: registrationSubject === RegistrationSubjectType.BATTERY
      ? I18n.t(T.setupTool.step.pvSystemBatteryOnly)
      : I18n.t(T.setupTool.step.pvSystem),
    sonnenBattery: I18n.t(T.setupTool.step.sonnenBattery),
    measuringDevice: I18n.t(T.setupTool.step.measuringDevice),
    uploadDocuments: I18n.t(T.setupTool.step.upload),
    installerData: I18n.t(T.setupTool.step.installerData),
  };
  const statusCompleted = 'completed';
  const completedSteps = [] as string[];

  setupDataStatusList.forEach(status => {
    if (setupDataStatuses[status] === statusCompleted) {
      completedSteps.push(setupDataStatusNameTranslations[status]);
    }
  });

  return completedSteps;
};

export function setAllFormFieldsTouched<T>(
  form: FormikProps<T>,
  additionalFieldsToTouch?: string[],
  fieldsToExcludeFromTouching?: string[],
) {
  if (form && form.values) {
    const fieldNames = Object.keys(form.values);
    const fieldsToTouchBeforeExclusion = [...fieldNames, ...(additionalFieldsToTouch || [])]
      .reduce((prev, fieldName) => ({...prev, [fieldName]: true}), {});
    const fieldsToTouch = omit(fieldsToTouchBeforeExclusion, fieldsToExcludeFromTouching || []);

    form.setTouched(fieldsToTouch);
    return fieldsToTouch;
  }
  return {};
}

export const mapInstallerFormValuesToInstallerEndpoint = (formValues: DsoRegistrationInstallerDataInterface) => {
  return Object.keys(formValues).reduce((prev, fieldKey) => (
    fieldKey.includes('installer_')
      ? {...prev, [fieldKey.replace('installer_', '')]: formValues[fieldKey]}
      : {...prev})
    , {}) as DsoRegistrationInstallerDataInterfaceForCreatingInstaller;
};

export const removeExponentialValues = (form: any, fieldElementId: string) => {
  const tmpValue = (document.getElementById(fieldElementId) as HTMLInputElement).value;
  const exponentialValues = ['e', 'E'];
  const regExpValues = new RegExp(exponentialValues.join('|'));

  if (regExpValues.test(tmpValue)) {
    const tmpValueWithoutE = parseFloat(tmpValue.toString().replace(regExpValues, ''));
    form.setFieldValue(fieldElementId, tmpValueWithoutE);
    form.setFieldTouched(fieldElementId);
  }
};
