import { formatMeasurementPeakPower } from '+lead-configuration/components';
import { Lead, LeadPvSystem, LeadPvSystemStatus } from '+shared/store/lead/types';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

const mapPvSystemStatus = (status: LeadPvSystemStatus): string | undefined => {
  switch (status) {
    case LeadPvSystemStatus.INSTALLED:
      return I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvStatus.installed);
    case LeadPvSystemStatus.PLANNED:
      return I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvStatus.planned);
    case LeadPvSystemStatus.PLANNED_EXTENSTION:
      return I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvStatus.plannedExtension);
    default:
      return undefined;
  }
};

export const getLeadPvSystemDetailsList = (pvSystem: LeadPvSystem | null) => ([
  {
    label: I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvPlant),
    value: pvSystem && pvSystem.status ? mapPvSystemStatus(pvSystem.status) : undefined,
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvPlantPower),
    value: pvSystem && pvSystem.peakPower ? formatMeasurementPeakPower(pvSystem.peakPower) : undefined,
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.pvSystemDetails.pvCommissioningDate),
    value: pvSystem && pvSystem.commissioningDate ? formatDate(pvSystem.commissioningDate) : undefined,
  },
]);
