import { T } from '@sonnen/shared-i18n/service';
import { TimeHelper } from '@sonnen/shared-web';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

export const DATE_FORMATS = {
  day: I18n.t(T.general.date.default),
  month: I18n.t(T.general.date.month),
  year: I18n.t(T.general.date.year),
};

export const getFormattedDate = (
  date?: moment.MomentInput,
  format: string = T.general.date.default,
): string | null => date ? I18n.l(moment(date).unix() * 1000, { dateFormat: format }) : null;

export const formatDate = (date: moment.MomentInput, format?: string) => {
  if (TimeHelper.isToday(date)) {
    return I18n.t(T.customerSingle.analysis.dateLabels.today);
  }
  if (TimeHelper.isYesterday(date)) {
    return I18n.t(T.customerSingle.analysis.dateLabels.yesterday);
  }

  return String(getFormattedDate(date, format));
};
