import { CaseStatus, RoundButton } from '+shared/components';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { Case } from '+shared/store/case';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, TableCell } from '@sonnen/shared-web';
import { breakpointUp } from '@sonnen/shared-web/src/utils/breakpoints';
import { get, isNil } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';
import { CaseListTableModal } from '../CaseListTableModal';

import './CaseListTableItem.component.scss';

interface Props {
  item: Case;
}

export const CaseListTableItem: React.FC<Props> = ({ item }) => {
  const { status, caseNumber, subject, createdDate } = item;
  const [isModalOpen, toggleModalOpen] = React.useState<boolean>(false);
  const { track } = useTracking();

  const handlePreviewClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    toggleModalOpen(true);
    track(InteractionEvents.buttonClick({
      category: EventCategory.REPORT_CASE,
      action: ActionName.OPEN_CASE_PREVIEW_MODAL,
      event,
    }));
  };

  return (
    <div className={'c-case-list-table-item c-case-list-table-item--updated'}>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--id'}>
        {caseNumber || '-'}
      </TableCell>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--case-status'}>
        <CaseStatus status={status} />
      </TableCell>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--name'}>
        <span>{!isNil(item.account) && get(item, 'account.firstName', '')} {get(item, 'account.lastName', '')}</span>
      </TableCell>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--subject'}>
        {subject}
      </TableCell>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--date'}>
        <Media query={{ minWidth: breakpointUp('XS') }}>
          {(isMedium: boolean) => !isMedium && (
            <span className={'c-case-list-table-item__label'}>
              {I18n.t(T.cases.list.header.createdAt) + ':'}
            </span>
          )}
        </Media>
        {!isNil(createdDate) ? formatDate(createdDate) : '-'}
      </TableCell>
      <TableCell className={'c-case-list-table-item__cell c-case-list-table-item__cell--preview'}>
        <RoundButton
          label={<Icon.Eye className={'c-case-list-table-item__icon'}/>}
          onClick={handlePreviewClick}
        />
      </TableCell>
      <CaseListTableModal
        caseItem={item}
        isOpen={isModalOpen}
        onClose={() => toggleModalOpen(false)}
      />
    </div>
  );
};
