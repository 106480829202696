import { setNumbersHistory }
  from '+app/+customer/+overview/components/CustomerBatteryWidget/CustomerBatteryWidget.helper';
import { EnergyUnit } from '+app/App.constants';
import { DetailListType } from '+shared/components';
import { BatteryElectricUnits, BatteryWithProduct } from '+shared/store/battery';
import { getBatteryProductName } from '+shared/store/battery/battery.helpers';
import { formatEnergy, formatPower } from '+utils/format.util.old';
import { trim } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';

const factorizeSerialAndProductNumber = (serialNumber: string, productCode: string | null | undefined): string =>
  `${serialNumber || '-'} / ${productCode || '-'}`;

export const factorizeBatteryTechSpecsDetailList = (
  battery: BatteryWithProduct,
  batteryElectricUnits: BatteryElectricUnits[],
): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.model),
    value: getBatteryProductName(battery.product),
  },
  {
    label: trim`
      ${I18n.t(T.customerSingle.batteryDetails.techSpecs.serialNumber)}
       /
      ${I18n.t(T.customerSingle.batteryDetails.techSpecs.articleNumber)}
    `,
    value: factorizeSerialAndProductNumber(battery.serialNumber, battery.product?.productCode),
  },
  {
    label: I18n.t(T.customerSingle.overview.battery.oldSerialNumbers),
    value: setNumbersHistory(batteryElectricUnits),
    isGrayedOut: true,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.capacity),
    value: !isNil(battery.batteryCapacity) &&
      formatEnergy({
        whValue: battery.batteryCapacity,
        unit: EnergyUnit.KWH,
        precision: 1,
        minPrecision: 0,
      }),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.maxOutputPower),
    value: !isNil(battery.batteryInverterDesignPower) &&
      formatPower(
        battery.batteryInverterDesignPower,
        EnergyUnit.KW,
        1,
      ),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.numberOfModules),
    value: battery.batteryModules,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.display.headline),
    value: isNil(battery.product?.batteryWithDisplay) ? undefined : battery.product?.batteryWithDisplay
      ? I18n.t(T.customerSingle.batteryDetails.techSpecs.display.true)
      : I18n.t(T.customerSingle.batteryDetails.techSpecs.display.false),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.techSpecs.batteryTechnology),
    value: battery.product?.cellTechnology,
  },
];
