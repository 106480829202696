import { getPrecision } from '+utils/number.util';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export const MAX_CURRENT_HOUSEHOLD_ELECTRICITY_CENTS_PER_KWH = 500;
export const MAX_CURRENT_ELECTRICITY_BASE_FEE_EUROS = 70;
export const MAX_EXPECTED_ELECTRICITY_PRICE_INCREASE_PERCENT_PER_YEAR = 5;

export const ImpactAnalysisConsumptionFormSchema = () =>
  Yup.object().shape({
    baselineTariffPerKwh: Yup
      .number()
      .test('decimal-places', I18n.t(T.validation.lessDecimalPlaces), val => getPrecision(val) <= 1)
      .positive(I18n.t(T.validation.greaterThan0))
      .required(I18n.t(T.register.alert.requiredField))
      .max(
        MAX_CURRENT_HOUSEHOLD_ELECTRICITY_CENTS_PER_KWH,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_CURRENT_HOUSEHOLD_ELECTRICITY_CENTS_PER_KWH }),
      ),
    baselineFeePerMonth: Yup
      .number()
      .test('decimal-places', I18n.t(T.validation.lessDecimalPlaces), val => getPrecision(val) <= 2)
      .positive(I18n.t(T.validation.greaterThan0))
      .required(I18n.t(T.register.alert.requiredField))
      .max(
        MAX_CURRENT_ELECTRICITY_BASE_FEE_EUROS,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_CURRENT_ELECTRICITY_BASE_FEE_EUROS }),
      ),
    averageElectricityPriceIncreasePerYear: Yup
      .number()
      .test('decimal-places', I18n.t(T.validation.lessDecimalPlaces), val => getPrecision(val) <= 1)
      .positive(I18n.t(T.validation.greaterThan0))
      .required(I18n.t(T.register.alert.requiredField))
      .max(
        MAX_EXPECTED_ELECTRICITY_PRICE_INCREASE_PERCENT_PER_YEAR,
        I18n.t(T.validation.lessThanOrEqual, { max: MAX_EXPECTED_ELECTRICITY_PRICE_INCREASE_PERCENT_PER_YEAR }),
      ),
  });
