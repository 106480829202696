import { SiteActions } from '+shared/store/site/site.actions';
import { combineReducers } from 'redux';
import * as analysis from '../+analysis/store';
import * as battery from '../+battery/store';
import * as contract from '../+contract/store';
import * as list from '../+list/store';
import * as overview from '../+overview/store';
import { queryReducer } from '../../shared/store/query';
import { createReducer } from '../../utils/redux/createReducer.util';
import { CUSTOMER_ACTIONS, CustomerActions } from './+customer.actions';
import {
  CUSTOMER_ACTIONS as CUSTOMER_PAGE_ACTIONS,
  CustomerActions as CustomerPageActions,
} from './+customer.actions';
import {
  CustomerPageCommonState,
  GET_CUSTOMER_QUERY,
  initialCommonState,
  PUT_CUSTOMER_NOTE_QUERY,
} from './+customer.state';

const commonReducer = createReducer<CustomerPageCommonState, CustomerActions | SiteActions | CustomerPageActions>(
  (state = initialCommonState, action): CustomerPageCommonState => {
    switch (action.type) {
      case CUSTOMER_ACTIONS.SET_CUSTOMER:
        return {
          ...state,
          customer: action.customer,
        };
      case CUSTOMER_PAGE_ACTIONS.CLEAR_CUSTOMER_DATA:
        return {
          ...initialCommonState,
        };
        case CUSTOMER_ACTIONS.SET_CUSTOMER_NOTE:
          const { customer } = state;
          return {
            ...state,
            customer: customer ? { ...customer, installerNote: action.customer.installerNote } : undefined,
        };
      default:
        return queryReducer(state, action, [
          GET_CUSTOMER_QUERY,
          PUT_CUSTOMER_NOTE_QUERY,
        ]);
    }
  },
);

export const reducer = combineReducers({
  analysis: analysis.reducer,
  battery: battery.reducer,
  contract: contract.reducer,
  common: commonReducer,
  overview: overview.reducer,
  list: list.reducer,
});
