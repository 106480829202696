import { FormUploadInput } from '+shared/components';
import { maxAttachmentSize } from '+shared/components/Form/Form.helpers';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { CaseActions, getCaseCategoryCollection, SelectItem } from '+shared/store/case';
import { 
  CaseCategory,
  CaseCountry,
  CaseOrigin,
  CasePriority,
  CaseRecordType,
  CaseStatus,
  CaseSubcategory,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, DefaultParagraph, Select } from '@sonnen/shared-web';
import { Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { SupportCaseForm, SupportFormHelper } from './SupportForm.helper';

import './SupportForm.component.scss';

interface Props {
  submitForm: typeof CaseActions.createCase;
  isLoading: boolean;
  status: FormState;
  contactId: string;
}

export const SupportForm: React.FC<Props> = ({submitForm, isLoading, status, contactId}) => {
  const caseRecordTypesCollection = SupportFormHelper.getCaseRecordTypesCollection();
  const caseCategoryCollection = getCaseCategoryCollection();

  // TODO: think about refactoring this state usage here
  const [ 
    caseRecordType,
    setCaseRecordType,
  ] = React.useState<SelectItem>(caseRecordTypesCollection[0]);
  const [
    caseCategory,
    setCaseCategory,
  ] = React.useState<SelectItem>(caseCategoryCollection[0]);
  const isEServices = caseRecordType.value === CaseRecordType.E_SERVICES;

  const [ attachment, setAttachment ] = React.useState<null | string>(null);
  const [ attachmentError, setAttachmentError ] = React.useState<string>('');

  const onFormSubmit = (values: SupportCaseForm) => {
    submitForm({
      recordType: isEServices
        ? CaseRecordType.E_SERVICES
        : CaseRecordType.TECHNICAL_SUPPORT_CASE,
      subject:  SupportFormHelper.supportTitle(caseRecordType.value),
      description: values.description,
      category: isEServices && caseCategory.value as CaseCategory || undefined,
      subcategory: isEServices && CaseSubcategory.SPECIAL_REQUESTS || undefined,
      type: isEServices ? CaseType.REQUEST : CaseType.INCIDENT,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      country: CaseCountry.DACH,
      contactId,
      origin: CaseOrigin.PARTNER_PORTAL,

    });
  };

  const onReset = () => {
    setAttachment(null);
    setAttachmentError('');

    return null;
  };

  const onUpload = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      if (result) {
        const stringResult = result.toString();
        const base64Result = stringResult.slice(stringResult.indexOf(',') + 1);
        setAttachment(base64Result);
        setAttachmentError('');
      }
    };

    return file.name;
  };
 
  return (
    <Formik
      initialValues={SupportFormHelper.supportInitValues}
      validationSchema={SupportFormHelper.supportSchema}
      onSubmit={onFormSubmit}
      render={form => (
        <form onSubmit={form.handleSubmit}>
          <DefaultParagraph className={'c-support-form__title'}>
          {I18n.t(T.help.supportForm.title)}
          </DefaultParagraph>
          <h3 className={'c-support-form__input-title'}>
            {I18n.t(T.help.supportForm.fields.topic)}:
          </h3>
          <Select
            name={'recordType'}
            items={caseRecordTypesCollection}
            itemsSelected={caseRecordTypesCollection
              .find(type => type.value === caseRecordType.value)}
            itemFactory={(option: SelectItem) => option.name}
            onSelect={(recordType: SelectItem) => 
              setCaseRecordType({ ...recordType })}
          />
          <DefaultParagraph className={'c-support-form__hint'}>
            {{
              'eServices': I18n.t(T.help.supportForm.fields.topicHints.eServices),
              'Technical': I18n.t(T.help.supportForm.fields.topicHints.technology),
            }[caseRecordType.value]}  
          </DefaultParagraph>
          {
            isEServices &&
            <div className={'c-support-form__category'}>
              <h3 className={'c-support-form__input-title'}>
                {I18n.t(T.help.supportForm.fields.category)}:
              </h3>
              <Select
                name={'category'}
                items={caseCategoryCollection}
                itemsSelected={caseCategoryCollection
                  .find(type => type.value === caseCategory.value)}
                itemFactory={(option: SelectItem) => option.name}
                onSelect={(category: SelectItem) => 
                  setCaseCategory(category)}
              />
            </div>
          }
          <h3 className={'c-support-form__input-title'}>
            {I18n.t(T.help.supportForm.fields.message)}:
          </h3>
          <FormTextarea
            isRequired={true}
            form={form}
            placeholder={I18n.t(T.help.supportForm.fields.messagePlaceholder)}
            name={'description'}
          />
          {/* TODO: uncomment when attachment upload available in new SF endpoint or remove */}
          {/* <h3 className={'c-support-form__input-title'}>
            {I18n.t(T.help.supportForm.fields.attachment)}:
          </h3> */}
          {/* <FormUploadInput
            form={form}
            name={'attachment'}
            className={'c-support-form__upload-input'}
            onUpload={file => onUpload(file[0])}
            accept={['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']}
            onReset={onReset}
            maxSize={maxAttachmentSize.value}
            maxSizeLabel={maxAttachmentSize.label}
            attachmentError={attachmentError}
          /> */}
          {/* <DefaultParagraph className={'c-support-form__hint'}>
            {I18n.t(T.help.supportForm.fields.attachmentHint)}
          </DefaultParagraph> */}
          <Button
            className={'c-case-report-form__button'}
            type={'submit'}
            size={ButtonSize.NORMAL}
            isLoading={isLoading}
            label={I18n.t(T.help.supportForm.buttons.submit)}
          />
        </form>
      )}
    />
  );
};
