import { Table, TableCell, TableRow, TableStack } from '@sonnen/shared-web';
import { Loadable } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './ListTable.component.scss';

export enum ListTableBorderColor {
  GREEN = 'green',
  YELLOW = 'yellow',
}

interface Props<T, U> {
  headers?: U[];
  items: T[];
  isPending: boolean;
  hoverable?: boolean;
  striped?: boolean;
  className?: ClassValue;
  fitWidth?: boolean;
  noBorder?: boolean;
  noScroll?: boolean;
  smallHeader?: boolean;
  mapBorderLeftColor?: (item: T) => ListTableBorderColor | undefined;
  rowSmallGap?: boolean;
  renderItem: (item: T, index: number) => React.ReactNode;
  renderHeader?: (item: U, index: number) => React.ReactNode;
  renderNoResults?: () => React.ReactNode;
  suppressItemsInHotjar?: boolean;
}

export class ListTable<T, U extends { key?: string }> extends React.PureComponent<Props<T, U>> {
  private headerRef = React.createRef<HTMLDivElement>();

  handleTableScroll = (event: React.SyntheticEvent<HTMLDivElement>) =>
    this.headerRef.current!.scrollLeft = event.currentTarget.scrollLeft;

  render() {
    const {
      className,
      headers,
      hoverable,
      items,
      isPending,
      renderHeader,
      renderItem,
      renderNoResults,
      mapBorderLeftColor,
      striped = true,
      fitWidth = false,
      noBorder = false,
      smallHeader = false,
      suppressItemsInHotjar = false,
    } = this.props;
    const isEmpty = !items.length;

    return (
      <div className={classNames('c-list-table', className)}>
        <TableRow
          className={classNames('c-list-table__header', {
            'c-list-table__header--narrow-border': noBorder,
            'c-list-table__header--small': smallHeader,
          })}
          ref={this.headerRef}
        >
          {headers && renderHeader && (
            <div className={classNames('c-list-table__header-wrapper', {
              'c-list-table__header-wrapper--fit-width': fitWidth,
            })}>
              {headers.map((header, i) => (
                <TableCell
                  key={`header-${header.key || i}`}
                  className={`c-list-table__header-item c-list-table__header-item--${header.key || i}`}
                  stack={TableStack.HORIZONTAL}
                >
                  {renderHeader(header, i)}
                </TableCell>
              ))}
            </div>
          )}
        </TableRow>
        <div
          className={classNames('c-list-table__table', {
            'c-list-table__table--no-border': noBorder,
          })}
          onScroll={this.handleTableScroll}
        >
          <Loadable
            predicate={isPending}
            transition={'fade'}
          >
            <Table
              className={
                classNames('c-list-table__table-wrapper', {
                  'c-list-table__table-wrapper--fit-width': fitWidth,
                })}
              hoverable={hoverable}
              striped={striped}
            >
              {items.map((item, i) => {
                const borderColor = mapBorderLeftColor && mapBorderLeftColor(item);

                return (
                  <div
                    className={'c-list-table__table-row-wrapper'} key={`item-${i}`}
                    data-hj-suppress={suppressItemsInHotjar}
                  >
                    <TableRow className={classNames('c-list-table__table-row', {
                      [`c-list-table__table-row--border-${borderColor}`]: borderColor,
                    })}>
                      {renderItem(item, i)}
                    </TableRow>
                  </div>
                );
              })}
            </Table>
          </Loadable>
        </div>
        {isEmpty && !isPending && renderNoResults && (
          <div className={'c-list-table__no-results'}>
            {renderNoResults()}
          </div>
        )}
      </div>
    );
  }
}
