import { LanguageSelectItem } from '@sonnen/shared-web';
import { intersection } from 'lodash/fp';

import flagFrance from '../../assets/img/flag-france.svg';
import flagGermany from '../../assets/img/flag-germany.svg';
import flagItaly from '../../assets/img/flag-italy.svg';
import flagUK from '../../assets/img/flag-uk.svg';
import flagUSA from '../../assets/img/flag-usa.svg';

export enum Locale {
  FR = 'fr',
  EN_GB = 'en-gb',
  EN_US = 'en-us',
  DE = 'de',
  IT = 'it',
}

export const LANGUAGES: LanguageSelectItem[] = [
  {
    locale: Locale.FR,
    name: 'French',
    icon: flagFrance,
  },
  {
    locale: Locale.EN_GB,
    name: 'English (UK)',
    icon: flagUK,
  },
  {
    locale: Locale.EN_US,
    name: 'English (US)',
    icon: flagUSA,
  },
  {
    locale: Locale.DE,
    name: 'Deutsch',
    icon: flagGermany,
  },
  {
    locale: Locale.IT,
    name: 'Italiano',
    icon: flagItaly,
  },
];

export const DEFAULT_LOCALE = Locale.EN_US;
export const DEFAULT_LANGUAGE = LANGUAGES.find(lang => lang.locale === DEFAULT_LOCALE);

export const SUPPORTED_LOCALES: string[] = [
  Locale.EN_GB,
  Locale.EN_US,
  Locale.DE,
];

export const AVAILABLE_LOCALES = intersection(SUPPORTED_LOCALES, LANGUAGES.map(lang => lang.locale));

export const AVAILABLE_LANGUAGES = LANGUAGES.filter(lang =>
  AVAILABLE_LOCALES.includes(lang.locale),
);
