import { HttpCode } from '@coolio/http';
import { push } from 'connected-react-router';
import { compose } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { iif, of } from 'rxjs';
import { ignoreElements, map, mergeMap } from 'rxjs/operators';
import { PATHS, PUBLIC_ROUTES } from '../../router';
import { RouterActions } from '../../router/store';
import { getSearchParams } from '../../router/store/router.helpers';
import { getRouterLocationSearch } from '../../router/store/router.selectors';
import { AuthRepository } from '../../shared/store/auth/auth.repository';
import { StoreState } from '../../shared/store/store.interface';
import { UserRepository } from '../../shared/store/user/user.repository';
import { mapToState, matchPath, ofType, processQuery } from '../../utils';
import { RegisterActions } from './+register.actions';
import { getRegistration } from './+register.selectors';
import { SUBMIT_REGISTRATION_QUERY, VERIFY_TOKEN_QUERY } from './+register.state';

type Action$ = ActionsObservable<RouterActions | RegisterActions>;
type State$ = StateObservable<StoreState>;

export const validateRegisterToken$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.locationChange),
  mapToState(state$),
  matchPath(PUBLIC_ROUTES.REGISTER[0]),
  map(getRouterLocationSearch),
  map(path => ({
    token: getSearchParams('token')(path),
    email: compose(decodeURIComponent, getSearchParams('email'))(path),
  })),
  mergeMap(({ token, email }) => iif(
    () => !!(token && email),
    of(token).pipe(
      processQuery(
        VERIFY_TOKEN_QUERY,
        token => of(AuthRepository.verifyToken('invitation', token)),
        {
          onSuccess: () => of(RegisterActions.setRegisterCredentials(token, email)),
          onFailure: () => of(push(PATHS.REGISTER_FAILURE())),
        },
      ),
    ),
    of(push(PATHS.REGISTER_FAILURE())),
  )),
);

export const submitRegisterForm$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RegisterActions.setRegisterForm),
  mapToState(state$),
  map(getRegistration),
  processQuery(
    SUBMIT_REGISTRATION_QUERY,
    registration => UserRepository.putUserRegistration(registration!),
    {
      onSuccess: () => of(push(PATHS.REGISTER_SUCCESS())),
      onFailure: error => error && error.status !== HttpCode.UNPROCESSABLE_ENTITY
        ? of(push(PATHS.REGISTER_FAILURE()))
        : of(error).pipe(ignoreElements()),
    },
  ),
);

export const epics = combineEpics(
  validateRegisterToken$,
  submitRegisterForm$,
);
