import { T } from '@sonnen/shared-i18n/service';
import { TooltipConfirmation } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadConfigurationTileAction.component.scss';

interface Props {
  isDisabled?: boolean;
  icon: React.ReactNode;
  action?: (event: React.MouseEvent) => void;
  hasConfirmationPopup?: boolean;
  hasWarning?: boolean;
}

export const LeadConfigurationTileAction: React.FC<Props> = ({
  action,
  icon,
  isDisabled,
  hasConfirmationPopup,
  hasWarning,
}) => {
  const popupConfirmationWidth = 220;
  const [rightOffset, setRightOffset] = React.useState(0);
  const tileActionRef = React.useRef<HTMLDivElement>(null);
  const [isPopupConfirmationOpen, togglePopupConfirmation] = React.useState<boolean>(false);

  const buttonAction = hasConfirmationPopup
    ? () => togglePopupConfirmation(true)
    : action;

  const countRightOffset = () => {
    const element = tileActionRef && tileActionRef.current;

    if (element) {
      const bodyRect = document.body.getBoundingClientRect();
      const elementRect = element.getBoundingClientRect();
      const offset = bodyRect.right - elementRect.right;
      setRightOffset(offset as any);
    }
  };

  React.useEffect(() => {
    countRightOffset();
    window.addEventListener('resize', countRightOffset);
    return () => window.removeEventListener('resize', countRightOffset);
  }, []);

  return buttonAction ? (
    <div ref={tileActionRef} className={'c-lead-configuration-tile-action'}>
      <button
        onClick={!isDisabled ? buttonAction : undefined}
        className={classNames('c-lead-configuration-tile-action__button', {
          'is-disabled': isDisabled,
        })}
      >
        <div className={'c-lead-configuration-tile-action__icon'}>
          {icon}
        </div>
      </button>
      {hasConfirmationPopup &&
        <TooltipConfirmation
          isOpen={isPopupConfirmationOpen}
          closeAction={() => togglePopupConfirmation(false)}
          confirmAction={action! as () => void}
          confirmationText={I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.message)}
          confirmBtnText={I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.yes)}
          cancelBtnText={I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.no)}
          isCentered={rightOffset >= (popupConfirmationWidth / 2) ? true : false}
          warningText={hasWarning
            ? I18n.t(T.lead.configuration._salessolution_.actions.confirmPopup.warningText)
            : undefined
          }
        />}
    </div>
  ) : null;
};
