import { createSelector } from 'reselect';
import { StoreState } from '../store.interface';

export const getTutorialState = (store: StoreState) => store.shared.tutorial;

export const getTutorialInitialSlideObject = createSelector(
  getTutorialState,
  state => state.initialSlide,
);

export const getTutorialOpenedObject = createSelector(
  getTutorialState,
  state => state.isOpened,
);

export const getTutorialTooltipVisibilityObject = createSelector(
  getTutorialState,
  state => state.isTooltipVisible,
);

export const getTutorialCurrentlyDisplayedSlidesObject = createSelector(
  getTutorialState,
  state => state.currentlyDisplayedSlides,
);
