import { FeatureProvider } from '@sonnen/shared-web';
import { Env } from './environment';

export enum FeatureName {
  EDIT_BACKUP_BUFFER = 'EditBackupBuffer',
  CONFIGURATION_LARGE_CONSUMERS = 'ConfigurationLargeConsumers',
  VPP_QUESTIONS_PER_LEAD = 'vppQuestionsPerLead',
  CONTRACT_ENERGY_METER_SECTION = 'contractEnergyMeterSection',
  HARDWARE_ONLY_NEW_TILE = 'hardwareOnlyNewTile',
  VPP_QUESTION_FOR_FLAT_DIRECT = 'vppQuestionForFlatDirect',
  ROLLOUT = 'rollout',
  BATTERY_CHARGING_DISCHARGING = 'batteryChargingDischarging',
  BATTERY_VPP_ACTIVITY = 'batteryVppActivity',
}

FeatureProvider.createFeatureConfiguration({
  [FeatureName.CONFIGURATION_LARGE_CONSUMERS]: [Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.VPP_QUESTION_FOR_FLAT_DIRECT]: [Env.DEVELOPMENT, Env.STAGING, Env.DEMO, Env.PRODUCTION],
  [FeatureName.EDIT_BACKUP_BUFFER]: [Env.DEVELOPMENT, Env.STAGING],
  [FeatureName.ROLLOUT]: [Env.DEVELOPMENT],
  [FeatureName.BATTERY_CHARGING_DISCHARGING]: [Env.DEVELOPMENT],
  [FeatureName.BATTERY_VPP_ACTIVITY]: [Env.DEVELOPMENT],
  [FeatureName.VPP_QUESTIONS_PER_LEAD]: [],
  [FeatureName.CONTRACT_ENERGY_METER_SECTION]: [],
  [FeatureName.HARDWARE_ONLY_NEW_TILE]: [],
});
