import { LeadCountry } from '+shared/store/lead/types';
import * as React from 'react';
import { FormInput, FormInputProps } from '../FormInput';
import { getAreaCallingCode } from './FormInputPhone.helper';

import './FormInputPhone.component.scss';

interface Props<T> extends FormInputProps<T> {
  country: LeadCountry;
}

export const FormInputPhone = <T extends any = any>({
  country, ...props
}: Props<T>) => (
  <div className={'c-form-input-phone'}>
    <span className={'c-form-input-phone__country-code'}>
      {getAreaCallingCode(country)}
    </span>
    <FormInput {...props} />
  </div>
);
