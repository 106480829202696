import { CarouselSlide } from '+shared/containers/Tutorial/Tutorial.helper';

export enum TooltipSide {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
}

export interface TutorialState {
  isOpened: object;
  isTooltipVisible: object;
  initialSlide: object;
  currentlyDisplayedSlides: CarouselSlide[];
}

export const initialState: TutorialState = {
  isOpened: {
    leadDetails: localStorage.getItem('tutorial_opened_leadDetails') !== 'false',
    setupConfigurator: localStorage.getItem('tutorial_opened_setupConfigurator') !== 'false',
    customersList: localStorage.getItem('tutorial_opened_customersList') !== 'false',
    // TODO uncomment when we enable tutorial tooltips
    // casesList: localStorage.getItem('tutorial_opened_casesList') !== 'false',
    // leadsInbox: localStorage.getItem('tutorial_opened_leadsInbox') !== 'false',
  },
  isTooltipVisible: {
    // TODO uncomment when we enable tutorial tooltips
    // invitationToPreportal: localStorage.getItem('tutorial_tooltip_visibility_invitationToPreportal') !== 'false',
    // statusManagementList: localStorage.getItem('tutorial_tooltip_visibility_statusManagementList') !== 'false',
    // statusManagementHeader: localStorage.getItem('tutorial_tooltip_visibility_statusManagementHeader') !== 'false',
  },
  initialSlide: {
    leadDetails: 0,
    setupConfigurator: 0,
    customersList: 0,
    // TODO uncomment when we enable tutorial tooltips
    // casesList: 0,
    // leadsInbox: 0,
  },
  currentlyDisplayedSlides: [],
};
