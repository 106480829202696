import { UserPasswordForm } from '+shared/store/user/types/userPassword.interface';
import { ActionsUnion, createAction } from '../../../utils';
import { Company, User, UserProfileExtended } from './user.state';

export enum USER_ACTIONS {
  GET_USER_PROFILE = '[User] GET_USER_PROFILE',
  SET_USER_PROFILE = '[User] SET_USER_PROFILE',
  SET_PROFILE = '[User] SET_PROFILE',
  SET_COMPANY = '[User] SET_COMPANY',
  GET_COMPANY = '[User] GET_COMPANY',
  EDIT_PASSWORD = '[User] EDIT PASSWORD',
  RESET_UPLOAD_LOGO_QUERY = '[User] RESET_UPLOAD_LOGO_QUERY',
}

export const UserActions = {
  getUserProfile: createAction(
    USER_ACTIONS.GET_USER_PROFILE,
    (profile: User) => ({ profile }),
  ),
  setUserProfile: createAction(
    USER_ACTIONS.SET_USER_PROFILE,
    (profileExtended: UserProfileExtended) => ({ profileExtended }),
  ),
  setProfile: createAction(
    USER_ACTIONS.SET_PROFILE,
    (profile: User) => ({ profile }),
  ),
  getCompany: createAction(
    USER_ACTIONS.GET_COMPANY,
    (profile: User) => ({ profile }),
  ),
  setCompany: createAction(
    USER_ACTIONS.SET_COMPANY,
    (company: Company) => ({ company }),
  ),
  resetUploadCompanyLogoQuery: createAction(
    USER_ACTIONS.RESET_UPLOAD_LOGO_QUERY,
    () => ({ uploadLogoQuery: {} }),
  ),
  editPassword: createAction(
    USER_ACTIONS.EDIT_PASSWORD,
    ({ queryKey, passwordForm, userId}: { queryKey: string, passwordForm: UserPasswordForm, userId: string }) =>
      ({ queryKey, passwordForm, userId }),
  ),
};

export type UserActions = ActionsUnion<typeof UserActions>;
