import { EnergyUnit } from '+app/App.constants';
import { DetailListType } from '+shared/components';
import { formatPower } from '+utils/format.util';
import { getFormattedTemperature } from '+utils/temperature';
import { T } from '@sonnen/shared-i18n/service';
import { TimeHelper } from '@sonnen/shared-web';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { HeaterCard } from './types/HeaterCard.types';

const checkIfOnline = (lastTimestamp: string) => {
  const offlineThreshold = 120;
  const isOnline = TimeHelper.getTimeDurationInMinutesTillNow(lastTimestamp) <= offlineThreshold;
  return isOnline;
};

export const factorizeHeaterDetailList = (locale: string) => (heater: HeaterCard): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.heater.status),
    value: checkIfOnline(heater.timestamp) && heater.power
      ? I18n.t(T.general.states.on)
      : I18n.t(T.general.states.off),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.heater.power),
    value: !isNil(heater.power) ? formatPower({ locale, unit: EnergyUnit.KW, precision: 1 })(heater.power) : '-',
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.heater.waterTemperature),
    value: !isNil(heater.waterTemperature) ? getFormattedTemperature(heater.waterTemperature) : '-',
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.heater.maxWaterTemperature),
    value: !isNil(heater.maxWaterTemperature) ? getFormattedTemperature(heater.maxWaterTemperature) : '-',
  },
];
