import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadListEmpty.component.scss';

export const LeadListEmpty: React.FC = () => {
  return (
    <div className={'c-lead-list-empty'}>
      <Icon.Ufo className={'c-lead-list-empty__icon'} />
      <div className={'c-lead-list-empty__headline'}>
        {I18n.t(T.lead.list._salessolution_.search.emptyHeadline)}
      </div>
      <div className={'c-lead-list-empty__description'}>
        {I18n.t(T.lead.list._salessolution_.search.emptyDescription)}
      </div>
    </div>
  );
};
