import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { WarningText, WarningTextSize } from '@sonnen/shared-web';
import * as React from 'react';
import { ConfigurationBoxHeaderSize } from '../LeadConfigurationPreview';

import * as classNames from 'classnames';
import './LeadConfigurationBox.component.scss';

interface Parameter {
  title: string;
  value: string;
  size: ConfigurationBoxHeaderSize;
  tileText?: string;
}

interface Props {
  parameters: Parameter[];
  warningText?: string;
}

export const LeadConfigurationBox: React.SFC<Props> = ({ parameters, warningText }) => parameters.length > 0 ? (
  <section className={'c-lead-configuration-box'}>
    <div className={'c-lead-configuration-box__body'}>
      {parameters.map(({ title, value, size, tileText }) =>
        <>
          <h3 className={`c-lead-configuration-box__headline c-lead-configuration-box__headline--${size}`}>
            {title}
          </h3>
          <div className={'c-lead-configuration-box__subheadline-wrapper'}>
            <p
              className={classNames(
                `c-lead-configuration-box__subheadline c-lead-configuration-box__subheadline--${size}`, {
                  'c-lead-configuration-box__subheadline--right-margin': tileText,
                })}>
              {value}
            </p>
            {tileText &&
              <div className={'c-lead-configuration-box__status-tile'}>
                <StatusTile label={tileText} color={StatusTileColors.YELLOW} />
              </div>
            }
          </div>
        </>,
      )}
    </div>
    {warningText &&
      <div className={'c-lead-configuration-box__warning'}>
        <WarningText text={warningText} size={WarningTextSize.MEDIUM} />
      </div>
    }
  </section>
) : null;
