import { TableRow } from '@sonnen/shared-web';
import * as React from 'react';

import './CustomersTableRow.component.scss';

export interface AccessDeniedNotification {
  isNotificationVisible: boolean;
  hideNotification: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface Props {
  isConsentGiven: boolean;
  children: (notificationProps: AccessDeniedNotification) => JSX.Element;
}

export const CustomersTableRow: React.FC<Props> = ({ isConsentGiven, children }) => {
  const [isNotificationVisible, toggleNotification] = React.useState(false);
  return (
    <TableRow
      className={'c-customers-table-row'}
      onClick={!isConsentGiven ? () => toggleNotification(true) : undefined}
    >
      {
        children({
          isNotificationVisible,
          hideNotification: (
            e: React.MouseEvent<HTMLButtonElement>,
          ) => {
            toggleNotification(false);
            e.stopPropagation();
          },
        })
      }
    </TableRow>
  );
};
