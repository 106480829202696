import {
  DetailedCostRow, getSignByValue, HeadingCostRow, HeadingText, isAmortizationLessThan1Year,
  isAmortizationOver20Years,
  mapToPageData,
  SubHeading,
  // tslint:disable-next-line:max-line-length
} from '+lead/+impactAnalysis/containers/LeadImpactAnalysisCalculationResults/page-detailed-calculation-20-years/ResultsCalculation20Years.helpers';
import { CostsFormVersion } from '+lead/+impactAnalysis/store/+impactAnalysis.interface';
import { IconWarningCircleComponent } from '+shared/components/IconWarningCircle';
import {
  LeadImpactAnalysisRecommendationResponse,
} from '+shared/store/lead/types/leadImpactAnalysisRecommendationResponse.interface';
import { T } from '@sonnen/shared-i18n/service';
import { WarningText } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { PageDetailedCalculation20YearsData, PropsAmortizationInformation } from './ResultsCalculation20Years.types';

import './ResultsCalculation20Years.styles.scss';

interface Props {
  version?: CostsFormVersion;
  data: LeadImpactAnalysisRecommendationResponse;
}

const InvestmentCosts: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <>
    <SubHeading
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.title)}
      value={data.hardwareCostsAfter20Years.investmentCosts.cumulative}
      sign={'-'}
    />
    {!isNil(data
      .hardwareCostsAfter20Years
      .investmentCosts
      .acquisitionCostsPVSAndBattery)
      && (
        <div className="c-impact-analysis-results-background-gray">
          <DetailedCostRow
            sign={'-'}
            text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.acquisition)}
            value={data.hardwareCostsAfter20Years.investmentCosts.acquisitionCostsPVSAndBattery}
          />
        </div>
      )}
    {!isNil(data
      .hardwareCostsAfter20Years
      .investmentCosts
      .installationCostsPVSAndBattery)
      && (
        <div className="c-impact-analysis-results-background-gray">
          <DetailedCostRow
            sign={'-'}
            text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.installation)}
            value={data.hardwareCostsAfter20Years.investmentCosts.installationCostsPVSAndBattery}
          />
        </div>
      )}
    {!isNil(data
      .hardwareCostsAfter20Years
      .investmentCosts
      .otherOneOffCosts)
      && (
        <div className="c-impact-analysis-results-background-gray">
          <DetailedCostRow
            sign={'-'}
            text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.investment.other)}
            value={data.hardwareCostsAfter20Years.investmentCosts.otherOneOffCosts}
          />
        </div>
      )}
  </>
);

const OperatingCosts: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <>
    {!isNil(data.hardwareCostsAfter20Years.operatingCosts.cumulative) && (
      <SubHeading
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.operating.title)}
        value={data.hardwareCostsAfter20Years.operatingCosts.cumulative}
        sign={'-'}
      />
    )}
    {!isNil(data
      .hardwareCostsAfter20Years
      .operatingCosts
      .maintenanceAndReplacementCosts)
      && (
        <div className="c-impact-analysis-results-background-gray">
          <DetailedCostRow
            sign={'-'}
            text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.operating.maintenance)}
            value={data.hardwareCostsAfter20Years.operatingCosts.maintenanceAndReplacementCosts}
          />
        </div>
      )}
    {!isNil(data
      .hardwareCostsAfter20Years
      .operatingCosts
      .otherRunningCosts)
      && (
        <div className="c-impact-analysis-results-background-gray">
          <DetailedCostRow
            sign={'-'}
            text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.operating.other)}
            value={data.hardwareCostsAfter20Years.operatingCosts.otherRunningCosts}
          />
        </div>
      )}
  </>
);

const HardwareCostsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={
      `c-impact-analysis-results-calculation-description-section c-impact-analysis-results-box-blue`
    }
  >
    <HeadingCostRow
      sign={'-'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.title)}
      value={data.hardwareCostsAfter20Years.cumulative}
    />
    <InvestmentCosts data={data} />
    <OperatingCosts data={data} />
    {!isNil(data.hardwareCostsAfter20Years.allowableTaxation) && (
      <SubHeading
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.hardware.allowableTaxation)}
        value={data.hardwareCostsAfter20Years.allowableTaxation}
        sign={'+'}
      />
    )}
  </div>
);

const SonnenFlatBenefitsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  return data.sonnenFlatBenefitsAfter20Years.cumulative !== undefined ? (
    <div
      className={
        `c-impact-analysis-results-calculation-description-section
         c-impact-analysis-results-box-dark-blue`
      }
    >
      <HeadingCostRow
        sign={getSignByValue(data.sonnenFlatBenefitsAfter20Years.cumulative)}
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.benefitsTitle)}
        value={Math.abs(data.sonnenFlatBenefitsAfter20Years.cumulative)}
      />
      {!isNil(data.sonnenFlatBenefitsAfter20Years.cashbackSonnenFlat) && (
        <DetailedCostRow
          sign={'+'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.cashback)}
          value={data.sonnenFlatBenefitsAfter20Years.cashbackSonnenFlat}
        />
      )}
      {!isNil(data.sonnenFlatBenefitsAfter20Years.vppBonus) && (
        <DetailedCostRow
          sign={'+'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.vppbonus)}
          value={data.sonnenFlatBenefitsAfter20Years.vppBonus}
        />
      )}
    </div>
  ) : <div />;
};

const SonnenFlatCostsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => {
  return data.sonnenFlatBenefitsAfter20Years.cumulative !== undefined ? (
    <div
      className={
        `c-impact-analysis-results-calculation-description-section
         c-impact-analysis-results-box-dark-blue`
      }
    >
      {!isNil(data.sonnenFlatBenefitsAfter20Years.monthlyPrepaymentExcessEnergy) && (
        <HeadingCostRow
          sign={'-'}
          text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.costsTitle)}
          value={data.sonnenFlatBenefitsAfter20Years.monthlyPrepaymentExcessEnergy}
        />
      )}
      <DetailedCostRow
        text={I18n.t(T.lead.impactAnalysis._salessolution_.results.sonnenflat.prepayment)}
      />
    </div>
  ) : <div />;
};

const PowerBillSavingsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={
      `c-impact-analysis-results-calculation-description-section
         c-impact-analysis-results-box-yellow`
    }
  >
    <HeadingCostRow
      sign={getSignByValue(data.powerBillSavings.cumulative)}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.powerbill.title)}
      value={data.powerBillSavings.cumulative ? Math.abs(data.powerBillSavings.cumulative) : undefined}
    />
    <DetailedCostRow
      sign={'+'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.powerbill.info)}
    />
  </div>
);

const TaxOnSelfConsumptionAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={
      `c-impact-analysis-results-calculation-description-section
         c-impact-analysis-results-box-dark-green`
    }
  >
    <HeadingCostRow
      sign={'-'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.taxation.title)}
      value={Math.abs(data.cumulativeSonnenFlatVatToBePaid)}
    />
    <DetailedCostRow
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.taxation.info)}
    />
  </div>
);

const CumulativeSavingsAfter20Years: React.FC<{
  data: PageDetailedCalculation20YearsData;
}> = ({ data }) => (
  <div
    className={
      `c-impact-analysis-results-calculation-description-section
           c-impact-analysis-results-box-green
           c-impact-analysis-results-background-green-tint-3`
    }
  >
    <HeadingCostRow
      boldHeading={true}
      sign={getSignByValue(data.savingsAfter20Years)}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.savings.title)}
      value={Math.abs(data.savingsAfter20Years)}
    />
    <DetailedCostRow
      sign={'+'}
      text={I18n.t(T.lead.impactAnalysis._salessolution_.results.savings.info)}
    />
  </div>
);

const AmortizationInformation: React.FC<PropsAmortizationInformation> = ({ amortizationTimeFrame }) => (
  <>
    <div
      className={
        `c-impact-analysis-results-calculation-description-section
           c-impact-analysis-results-box-mint`
      }
    >
      <div className="c-impact-analysis-results-calculation-description-heading ">
        <HeadingText>{I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.title)}</HeadingText>
        <div className="c-impact-analysis-results-value-wrapper">
          <HeadingText>
            <div className="c-impact-analysis-results-amortization-info">
              {(isAmortizationOver20Years(amortizationTimeFrame) ||
                isAmortizationLessThan1Year(amortizationTimeFrame)) && (
                  <IconWarningCircleComponent />
                )}
              {isAmortizationOver20Years(amortizationTimeFrame)
                ? I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.warning)
                : isAmortizationLessThan1Year(amortizationTimeFrame)
                  ? I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.lessThan1year)
                  : I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.info, {
                    years: amortizationTimeFrame!.years,
                    months: amortizationTimeFrame!.months,
                  })}
            </div>
          </HeadingText>
        </div>
      </div>
    </div>
    {(isAmortizationOver20Years(amortizationTimeFrame) ||
      isAmortizationLessThan1Year(amortizationTimeFrame)) && (
        <div className={'c-impact-analysis-results-amortization-info-box '}>
          <WarningText
            text={isAmortizationOver20Years(amortizationTimeFrame)
              ? I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.warningbox)
              : I18n.t(T.lead.impactAnalysis._salessolution_.results.amortization.warningCorrectValues)}
          />
        </div>
      )}
  </>
);

export const ResultsCalculation20Years: React.FC<Props> = ({ data, version }) => {
  const pageData = mapToPageData(data, version);
  return (
    <>
      <HardwareCostsAfter20Years data={pageData} />
      <SonnenFlatBenefitsAfter20Years data={pageData} />
      <SonnenFlatCostsAfter20Years data={pageData} />
      <PowerBillSavingsAfter20Years data={pageData} />
      <TaxOnSelfConsumptionAfter20Years data={pageData} />
      <CumulativeSavingsAfter20Years data={pageData} />
      <AmortizationInformation amortizationTimeFrame={data.amortizationTimeFrame} />
    </>
  );
};
