import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';
import { HARDWARE_PAGE_ACTIONS, HardwarePageActions } from './+hardware.actions';
import {
  HARDWARE_SAVE_QUERY,
  HardwarePageState,
  initialState,
  UPDATE_HARDWARE_STATUS_QUERY,
} from './+hardware.state';

export const reducer = createReducer<HardwarePageState, HardwarePageActions>(
  (state = initialState, action): HardwarePageState => {
    switch (action.type) {
      case HARDWARE_PAGE_ACTIONS.SET_NEW_HARDWARE_STATUS:
        return {
          ...state,
          newHardwareStatus: action.status,
        };
      case HARDWARE_PAGE_ACTIONS.CLEAR_NEW_HARDWARE_STATUS:
        return {
          ...state,
          newHardwareStatus: initialState.newHardwareStatus,
        };
      default:
        return queryReducer(state, action, [
          HARDWARE_SAVE_QUERY,
          UPDATE_HARDWARE_STATUS_QUERY,
        ]);
    }
  },
);
