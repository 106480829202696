import { FormInput, FormInputCheckboxGroup } from '+shared/components';
import { CaseActions } from '+shared/store/case';
import { 
  CaseCountry,
  CaseOrigin,
  CasePriority,
  CaseRecordType,
  CaseStatus,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ModalLayout } from '@sonnen/shared-web';
import { Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { RequestAccessFormHelper, RequestPartnerAccessForm } from './RequestAccessForm.helper';

interface Props {
  submitForm: typeof CaseActions.createCase;
  onClose: () => void;
  isLoading: boolean;
  contactId: string;
}

export const RequestAccessForm: React.FC<Props> = ({ submitForm, isLoading, contactId }) => {
  const onFormSubmit = (values: RequestPartnerAccessForm) => {
    submitForm({
      recordType: CaseRecordType.TECHNICAL_SUPPORT_CASE,
      subject: RequestAccessFormHelper.requestAccessTitle,
      description: RequestAccessFormHelper.setRequestAccessDesc(
          values.companyName,
          values.userName,
          values.userLastName,
          values.email,
          values.roles,
      ),
      type: CaseType.REQUEST,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      country: CaseCountry.DACH,
      contactId,
      origin: CaseOrigin.PARTNER_PORTAL,

    });
  };

  return (
    <Formik
      initialValues={RequestAccessFormHelper.requestAccessInitValues}
      validationSchema={RequestAccessFormHelper.requestAccessSchema}
      onSubmit={onFormSubmit}
      render={form => (
        <form
          onSubmit={form.handleSubmit}
          className={'c-case-report-form'}
        >
          <ModalLayout
            title={I18n.t(T.companyAccount.requestAccess.title)}
            subtitle={I18n.t(T.companyAccount.requestAccess.desc)}
            footer={
              <Button
                className={'c-case-report-form__button'}
                type={'submit'}
                size={ButtonSize.SECONDARY}
                isLoading={isLoading}
                label={I18n.t(T.companyAccount.requestAccess.buttons.submit)}
              />
            }
          >
            <>
              <div className={'c-case-report-form__body'}>
                <div className={'c-case-report-form__input'}>
                  <FormInput
                    form={form}
                    label={I18n.t(T.companyAccount.requestAccess.form.companyName)}
                    name={'companyName'}
                    type={'text'}
                  />
                  <FormInput
                    form={form}
                    label={I18n.t(T.companyAccount.requestAccess.form.name)}
                    name={'userName'}
                    type={'text'}
                  />
                  <FormInput
                    form={form}
                    label={I18n.t(T.companyAccount.requestAccess.form.lastName)}
                    name={'userLastName'}
                    type={'text'}
                  />
                  <FormInput
                    form={form}
                    label={I18n.t(T.companyAccount.requestAccess.form.email)}
                    name={'email'}
                    type={'text'}
                  />
                  <FormInputCheckboxGroup
                    collection={RequestAccessFormHelper.getRolesCollection()}
                    form={form}
                    name={'roles'}
                    text={I18n.t(T.companyAccount.requestAccess.form.roles)}
                    className={'c-form-input-checkbox-group--modal-form'}
                  />
              </div>
              </div>
            </>
          </ModalLayout>
        </form>
      )}
    />
  );
};
