import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './FormSubmitButton.component.scss';

interface Props {
  label?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  onClick?: (...args: any[]) => void;
  theme?: ButtonTheme;
}

export const FormSubmitButton: React.FC<Props> = ({
  label,
  isSubmitting,
  isDisabled,
  onClick,
  theme = ButtonTheme.OUTLINE,
}) => (
  <div className={'c-form-submit-button'}>
    <Button
      label={label || I18n.t(T.lead.boc._salessolution_.form.button)}
      theme={theme}
      size={ButtonSize.SECONDARY}
      type={'submit'}
      onClick={onClick}
      isLoading={isSubmitting}
      isDisabled={isDisabled}
    />
  </div>
);
