import { Form, FormErrorBanner, FormInputSelect, FormSubmitButton } from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { ModalId } from '+shared/store/layout';
import { batteryCapacityLabelsMapper, batteryModelNameMap } from '+shared/store/lead/lead.helpers';
import { Lead, OfferProductStatus } from '+shared/store/lead/types';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { useLocaleContext } from '+utils/react/locale.provider';
import { T } from '@sonnen/shared-i18n/service';
import { Card } from '@sonnen/shared-web';
import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { hardwareFormInitial, HardwareFormSchema } from '../../store/schemas/hardwareForm.schema';
import { HardwareForm } from '../../store/types';
import { LeadHardwareFormStatus } from '../LeadHardwareFormStatus';
import {
  formFields,
  mapBatteryCapacityCollection,
  mapBatteryModelNameCollection,
} from './LeadHardwareForm.helper';

import './LeadHardwareForm.component.scss';

interface Props {
  lead: Lead;
  saveHardwareOffer: (batteryId: string) => void;
  productBatteryList: LeadProductBattery[];
  toggleModal: (isOpen: boolean, modalId: ModalId) => void;
  newHardwareStatus?: OfferProductStatus;
  isPending: boolean;
  hasError: boolean;
}

export const LeadHardwareForm: React.FC<Props> = ({
  saveHardwareOffer,
  productBatteryList,
  isPending,
  hasError,
  toggleModal,
  newHardwareStatus,
}) => {
  const { locale } = useLocaleContext();

  const onSubmit = (values: HardwareForm) => {
    saveHardwareOffer(values.capacityGross);
  };

  const onFieldValueChange = (form: FormikProps<HardwareForm>) => (payload: FormInputSubscriptionPayload<HardwareForm>) => {
    if (payload.name === formFields.MODEL_NAME) {
      form.setFieldValue(formFields.CAPACITY_GROSS, '');
      form.setFieldTouched(formFields.CAPACITY_GROSS, false);
    }
  };

  return (
    <Formik
      initialValues={hardwareFormInitial}
      validationSchema={HardwareFormSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      render={form => (
        <Form className={'c-lead-hardware-form'}>
          <Card footerContent={
            <div className={'c-lead-hardware-form__button-wrapper'} >
              <FormSubmitButton
                label={I18n.t(T.lead.hardware._salessolution_.form.button.save)}
                isDisabled={!isEmpty(form.errors)}
                isSubmitting={isPending}
              />
            </div>
          }>
            <div className={'c-lead-hardware-form__container'}>
              <div className={'c-lead-hardware-form__title'}>
                {I18n.t(T.lead.hardware._salessolution_.form.title.provideData)}
              </div>
              <div className={'c-lead-hardware-form__subtitle'}>
                {I18n.t(T.lead.hardware._salessolution_.form.subtitle.thisWillMakeConfEasier)}
              </div>

              <div className={'c-lead-hardware-form__subheadline'}>
                {I18n.t(T.lead.configuration._salessolution_.hardware.sonnenBattery)}
              </div>
              <div className={'c-lead-hardware-form__columns'}>
                {/* MODEL NAME */}
                <div className={'c-lead-hardware-form__column'}>
                  <div className={'c-lead-hardware-form__input'}>
                    <FormFieldObserver<HardwareForm> onChange={onFieldValueChange(form)}>
                      <FormInputSelect
                        form={form}
                        label={I18n.t(T.lead.configuration._salessolution_.battery.model)}
                        collection={mapBatteryModelNameCollection(productBatteryList)}
                        name={formFields.MODEL_NAME}
                        mapper={key => batteryModelNameMap(key)}
                        id={'lead-hardware-battery-model'}
                        hasNoGap={true}
                      />
                    </FormFieldObserver>
                  </div>
                </div>

                {/* BATTERY CAPACITY */}
                <div className={'c-lead-hardware-form__column'}>
                  <div className={'c-lead-hardware-form__input'}>
                    <FormInputSelect
                      form={form}
                      label={I18n.t(T.lead.configuration._salessolution_.battery.capacity)}
                      collection={mapBatteryCapacityCollection(productBatteryList, form.values.modelName)}
                      name={formFields.CAPACITY_GROSS}
                      mapper={(key: string) => batteryCapacityLabelsMapper(locale)(key, productBatteryList)}
                      hasNoGap={true}
                      id={'lead-hardware-battery-capacity'}
                    />
                  </div>
                </div>
              </div>

              <LeadHardwareFormStatus
                openModal={() => toggleModal(true, ModalId.HARDWARE_STATUS_UPDATE_NEW_OFFER)}
                newHardwareStatus={newHardwareStatus}
              />

              <FormErrorBanner
                isVisible={hasError}
                error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
              />
            </div>
          </Card>
        </Form >
      )}
    />
  );
};
