import { createSelector } from 'reselect';
import { StoreState } from '../shared/store/store.interface';
import { DEFAULT_LANGUAGE, LANGUAGES } from './i18n.config';

export const getI18nState = (store: StoreState) => store.i18n;

export const getCurrentLocale = createSelector(
  getI18nState,
  state => state.locale,
);

export const getLanguageFromCurrentLocale = createSelector(
  getCurrentLocale,
  locale => LANGUAGES.find(lang => lang.locale === locale)! || DEFAULT_LANGUAGE,
);
