import {
  CategoryName,
  SelectCategory,
  Video,
  VideoFirebase,
} from '+app/shared/store/firebase/types/video.interface';
import { mapActions } from '+app/utils/redux/mapActions.util';
import { Container } from '+shared/components';
import { withFirebase } from '+shared/components/FirebaseContext';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { getVideoList } from '+shared/store/firebase/firebase.selectors';
import { StoreState } from '+shared/store/store.interface';
import { formatDuration } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Loader, PageSubheadline, Select } from '@sonnen/shared-web';
import { firestore } from 'firebase/app';
import { groupBy, isEmpty, keys } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Video as VideoComponent } from '../../components/Video';
import { getCategories } from './Videos.helper';

import './Videos.component.scss';

const mapStateToProps = (state: StoreState) => ({
  videoList: getVideoList(state),
});

const mapDispatchToProps = mapActions({
  getCombinedVideoList: FirebaseActions.getCombinedVideoList,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & FirebaseProps;

export const VideosComponent: React.FC<Props> = ({ firebase, actions, videoList }) => {
  const categories = getCategories();
  const [category, setCategory] = React.useState<SelectCategory>(categories[0]);
  const [isSectionVisible, setSectionVisibility] = React.useState<boolean>(false);

  React.useEffect(() => {
    firebase.getVideosList()
      .then((querySnapshot: firestore.QuerySnapshot) => {
        const videoList: VideoFirebase[] = [];

        querySnapshot.forEach((doc: any) => {
          const video: VideoFirebase = doc.data();
          videoList.push(video);
        });
        actions.getCombinedVideoList(videoList);
      })
      .catch((error: Error) => console.log('Firebase error:', error)) // @TODO display message for user
      .finally(() => setSectionVisibility(true)); // @TODO move to store
  }, []);

  const onCategoryChange = (category: SelectCategory) => {
    setCategory({ ...category });
    window.location.hash = category.hash;
  };

  const videoListCategorized = groupBy(videoList, (video: Video) =>
    video.categories.map((category: string) => category ));

  const videoCategoriesKeys = keys(videoListCategorized);
  const usedCategories = [
    { label: I18n.t(T.videosSubpage.categories.allVideos), hash: CategoryName.ALL_VIDEOS},
    ...categories.filter((category) => videoCategoriesKeys.includes(category.hash)),
  ];

  return (
    <Container className={'c-videos'} withHorizontalPadding={true}>
      {isSectionVisible
        ? (
          <>
            <h3 className={'c-videos__bold-header'}>
              {I18n.t(T.videosSubpage.selectCategory)}:
            </h3>
            <Select
              className={'c-videos__select'}
              items={usedCategories}
              itemsSelected={usedCategories
                .find((item: SelectCategory) => item.hash === category.hash)}
              itemFactory={(item: SelectCategory) => item.label}
              onSelect={(item: SelectCategory) => onCategoryChange(item)}
            />
            {!isEmpty(videoList) &&
              Object.keys(videoListCategorized)
                .filter((group: string) => category.hash ===  ''
                  ? group
                  : category.hash === group,
                )
                .map((selectedCategory: string, index: number) => {
                  const selectedCategoryObject =
                    usedCategories.filter((category: SelectCategory) =>
                      category.hash === selectedCategory);

                  const categoryName = (selectedCategoryObject.length > 0)
                    ? usedCategories.filter((category: SelectCategory) =>
                        category.hash === selectedCategory)[0]?.label
                    : usedCategories.filter((category: SelectCategory) =>
                        category.hash === CategoryName.OTHER)[0]?.label;

                  const videosOfSelectedCategory = videoListCategorized[selectedCategory];

                  return (
                    <div key={index}>
                      <PageSubheadline smallGap={true}>
                        {categoryName}
                      </PageSubheadline>
                      <div className={'c-videos__grid'}>
                        {videosOfSelectedCategory && (
                          videosOfSelectedCategory.map((video: Video) =>
                            <VideoComponent
                              key={video.id}
                              videoId={video.youtubeId}
                              title={video.snippet.title}
                              thumbnail={video.snippet.thumbnails.high.url}
                              length={formatDuration(video.contentDetails.duration)}
                              category={categoryName}
                            />,
                          )
                        )}
                      </div>
                    </div>
                );
              },
            )}
          </>
        )
        : <Loader />
      }

    </Container>
  );
};

export const Videos =
  connect(mapStateToProps, mapDispatchToProps)
  (withFirebase(VideosComponent));
