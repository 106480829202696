import { LeadConfigurationNew } from '+app/+lead/+configuration/containers/LeadConfigurationNew';
import { LeadHardwareNew } from '+app/+lead/+hardware';
import { LeadImpactAnalysis } from '+app/+lead/+impactAnalysis';
import { LeadOfferComposer } from '+app/+lead/+offer/containers';
import { LeadOverview } from '+app/+lead/+overview';
import { ROUTES } from '+app/router';
import { Leads } from '+lead/+list';
import { RestrictedToRolesOr404 } from '+shared/components/RestrictedToRoles';
import { leadTabAllowedRoles } from '+shared/store/lead';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileRoles } from '+shared/store/user/user.selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { LeadCreator } from '../LeadCreator';

const mapStateToProps = (state: StoreState) => ({
  userProfileRoles: getUserProfileRoles(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>;

class LeadComponent extends React.PureComponent<Props> {
  render() {
    const { userProfileRoles } = this.props;

    return (
      <RestrictedToRolesOr404 allowedRoles={leadTabAllowedRoles} userRoles={userProfileRoles}>
        <Switch>
          <Route
            path={ROUTES.LEAD_NEW}
            component={LeadCreator}
          />
          <Route
            path={ROUTES.LEAD_IMPACT_ANALYSIS}
            component={LeadImpactAnalysis}
          />
          <Route
            path={ROUTES.LEAD_OFFER}
            component={LeadOfferComposer}
          />
          <Route
            path={ROUTES.LEADS_INBOX}
            component={Leads}
          />
          <Route
            path={ROUTES.LEAD_CONFIGURATION_NEW}
            component={LeadConfigurationNew}
          />
          <Route
            path={ROUTES.LEAD_HARDWARE_NEW}
            component={LeadHardwareNew}
          />
          <Route
            path={ROUTES.LEAD_OVERVIEW}
            component={LeadOverview}
          />
          <Route
            path={ROUTES.LEADS}
            component={Leads}
          />
        </Switch>
      </RestrictedToRolesOr404>
    );
  }
}

export const Lead = connect(mapStateToProps)(LeadComponent);
