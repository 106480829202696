import { DetailList, Widget } from '+shared/components';
import { Case, CaseStatus } from '+shared/store/case';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getClosedCaseCount } from './CustomerCasesWidget.helper';

import './CustomerCasesWidget.scss';

interface Props {
  cases: Case[];
  className?: ClassValue;
  action?: () => void;
}

export const CustomerCasesWidget: React.SFC<Props> = ({ className, cases, action }) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icon.OpenFolder className={'c-customer-overview__tile__report-icon'}/>}
    heading={I18n.t(T.customerSingle.overview.cases.headline)}
    action={action}
  >
    <DetailList
      list={[
        {
          label: I18n.t(T.customerSingle.overview.cases.openedCases),
          value: cases.length - getClosedCaseCount(cases),
        },
        {
          label: I18n.t(T.customerSingle.overview.cases.resolvedCases),
          value: getClosedCaseCount(cases),
        },
      ]}
    />
  </Widget>
);
