import { HttpCode } from '@coolio/http';
import { handleRequest, mockRequestHandler } from '@coolio/http/request-handlers/mock';
import { from } from 'rxjs';
import { Config } from '../../../../config';
import { createAuthorizedNetworkClients, jsonApiClient } from '../../network';
import { measurementsResponseMock } from './mocks/measurements.mock';
import {
  defaultStatisticFilters,
  getMeasurementsDefaultDateRange,
  MeasurementsFilters,
  StatisticsFilters,
} from './site.helpers';
import {
  SiteChargerData,
  SiteData,
  SiteLiveStateData,
  SiteMeasurementsData,
  SiteStatisticsData,
} from './types';

const {
  jsonApiClient: mockJsonApiClient,
} = createAuthorizedNetworkClients({
  requestHandler: mockRequestHandler({
    endpoints: [
      {
        match: new RegExp(`^${Config.API_URL}/sites/(.+)/measurements`),
        handler: () => handleRequest(HttpCode.OK, measurementsResponseMock),
      },
    ],
  }),
});

const getSiteMeasurements = (id: string, filters?: MeasurementsFilters) => {
  const { start, end } = filters || getMeasurementsDefaultDateRange();
  return from(jsonApiClient.get<SiteMeasurementsData>(`${Config.API_URL}/sites/${id}/measurements`)
    .filter('start', start.toISOString())
    .filter('end', end.toISOString())
    .send());
};

const getSiteMeasurementsMock = (id: string, filters?: MeasurementsFilters) => {
  const { start, end } = filters || getMeasurementsDefaultDateRange();
  return from(mockJsonApiClient.get(`${Config.API_URL}/sites/${id}/measurements`)
    .filter('start', start.toISOString())
    .filter('end', end.toISOString())
    .send());
};

const getSiteChargers = (id: string) => from(
  jsonApiClient.getList<SiteChargerData>(`${Config.API_URL}/sites/${id}/chargers`)
    .send());

const getSiteStatistics = (id: string, filters?: StatisticsFilters) => {
  const { start, end, resolution } = filters || defaultStatisticFilters;
  return from(jsonApiClient.get<SiteStatisticsData>(`${Config.API_URL}/sites/${id}/statistics`)
    .filter('start', start.toISOString())
    .filter('end', end.toISOString())
    .filter('resolution', resolution)
    .send());
};

const getSiteLiveState = (id: string) => from(
  jsonApiClient.get<SiteLiveStateData>(`${Config.API_URL}/sites/${id}/live-state`)
    .send(),
);

const getSite = (id: string) => from(
  jsonApiClient.get<SiteData>(`${Config.API_URL}/sites/${id}`)
    .send(),
);
 
export const SiteRepository = {
  getSiteChargers,
  getSiteMeasurements,
  getSiteMeasurementsMock,
  getSiteStatistics,
  getSiteLiveState,
  getSite,
};
