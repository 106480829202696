import { createReducer } from '+app/utils';
import { reducer as preCommissioningReducer } from '+setupTool/+preCommissioning/store/+preCommissioning.reducer';
import { GET_SETUP_TOOL_SUBMISSION_DATA_QUERY } from '+setupTool/store/+setupTool.dictionary';
import { configurationInitialState, SetupToolConfigurationState } from '+setupTool/store/types';
import { queryReducer } from '+shared/store/query';
import { combineReducers } from 'redux';
import * as dsoCommissioning from '../+dsoCommissioning/store/+dsoCommissioning.reducer';
import * as dsoRegistration from '../+dsoRegistration/store/+dsoRegistration.reducer';
import * as form from '../+form/store/+form.reducer';
import * as pvRegister from '../+pvRegister/store/+pvRegister.reducer';
import * as vppDocumentation from '../+vppDocumentation/store/+vppDocumentation.reducer';
import { SETUP_TOOL_ACTIONS, SetupToolActions } from './+setupTool.actions';
import { SetupToolPageState } from './+setupTool.state';

export const configurationReducer = createReducer<SetupToolConfigurationState, SetupToolActions>(
  (state = configurationInitialState, action): SetupToolConfigurationState => {
    switch (action.type) {
      case SETUP_TOOL_ACTIONS.CLEAR_CONFIGURATION_DATA:
        return {
          ...configurationInitialState,
        };
      case SETUP_TOOL_ACTIONS.SET_SUBMISSION_ID:
        return {
          ...state,
          submissionId: action.submissionId,
        };
      case SETUP_TOOL_ACTIONS.SET_VPP_CAPABILITY:
        return {
          ...state,
          isVppCapable: action.isVppCapable,
        };
      case SETUP_TOOL_ACTIONS.SET_REGISTRATION_SUBJECT_TYPE:
        return {
          ...state,
          registrationSubject: action.subject,
        };
      case SETUP_TOOL_ACTIONS.APPEND_ERROR:
        return {
          ...state,
          errors: [
            ...state.errors,
            action.error,
          ],
        };
      case SETUP_TOOL_ACTIONS.CLEAR_ERRORS:
        return {
          ...state,
          errors: [],
        };
      default:
        return queryReducer(state, action, [
          GET_SETUP_TOOL_SUBMISSION_DATA_QUERY,
        ]);
    }
  },
);

export const reducer = combineReducers<SetupToolPageState>({
  preCommissioning: preCommissioningReducer,
  form: form.reducer,
  dsoRegistration: dsoRegistration.reducer,
  dsoCommissioning: dsoCommissioning.reducer,
  vppDocumentation: vppDocumentation.reducer,
  configuration: configurationReducer,
  pvRegister: pvRegister.reducer,
});
