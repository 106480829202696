import { PATHS } from '+app/router';
import { goTo } from '+app/utils';
import { DetailListTable } from '+shared/components';
import { User } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getContactDetailsList } from './ContactDetails.helper';

import './ContactDetails.component.scss';

interface Props {
  user: User | void;
}

export const ContactDetails: React.FC<Props> = ({user = null}) => user && (
  <>
    <PageSubheadline mediumGap={true}>
      {I18n.t(T.myAccount.contactDetails.title)}
    </PageSubheadline>
    <DetailListTable list={getContactDetailsList(user)} suppressRowValuesInHotjar={true} />
    <p className={'c-contact-details__text'}>{I18n.t(T.myAccount.contactDetails.supportText)}&#32;
      <a
        onClick={() => goTo(PATHS.HELP())}
        className={'c-contact-details__link'}
      >
        {I18n.t(T.myAccount.contactDetails.support)}
      </a>.
    </p>
  </>
);
