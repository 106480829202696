import { getVppDocumentationInitial } from './schemas/vppDocumentation.schema';
import { VppDocumentationState } from './types';

export const VPP_CONFIG_SUBMIT_QUERY = 'vppDocumentationConfigQuery';
export const VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY = 'vppGenerateCompletionNotificationQuery';

export const initialState: VppDocumentationState = {
  fields: getVppDocumentationInitial(),
  showDsoReferenceNumberPv: false,
  showCompletionNotification: false,
  vppLabelsForFlatDirect: false,
  productionMeterField: false,
  meterCabinetPreparedField: false,
  fasteningTypeConsumptionField: false,
  uploadedDocuments: [],
  completionNotificationDocument: undefined,
  completionNotificationRegeneratedAt: undefined,
  submissionStatus: undefined,
  vppAdditionalFeatures: null,
  [VPP_CONFIG_SUBMIT_QUERY]: {},
  [VPP_GENERATE_COMPLETION_NOTIFICATION_QUERY]: {},
};
