import { AnyAction, Dispatch } from 'redux';
import { StateAccessor } from './store.interface';

class StoreProvider {
  dispatch!: Dispatch<AnyAction>;
  private directGetState?: StateAccessor;

  getState: StateAccessor = () => this.directGetState
    ? this.directGetState()
    : {} as any;

  initialize = (store: { dispatch: Dispatch<AnyAction>, getState: StateAccessor }) => {
    this.directGetState = () => store.getState();
    this.dispatch = action => store.dispatch(action);
  };
}

export const storeProvider = new StoreProvider();
