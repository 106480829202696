import { Query } from '+shared/store/query';
import { Document } from '+shared/store/setupTool/types';
import { getDsoCommissioningInitial } from './schemas';
import { DsoCommissioningInterface } from './types';

export const GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY = 'generateDsoCommissioningDocumentsQuery';
export const DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY = 'patchSubmissionQuery';

export interface DsoCommissioningState {
  fields: DsoCommissioningInterface;
  documents: Document[];
  updatedAt: string;
  documentsUpdatedAt: string;
  [GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY]: Query;
  [DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY]: Query;
}

export const initialState: DsoCommissioningState = {
  fields: getDsoCommissioningInitial(),
  documents: [],
  updatedAt: '',
  documentsUpdatedAt: '',
  [GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY]: {},
  [DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY]: {},
};
