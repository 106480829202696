import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { createSelector } from 'reselect';
import { SUBMIT_REGISTRATION_QUERY, VERIFY_TOKEN_QUERY } from './+register.state';

export const getRegisterPageState = (state: StoreState) => state.page.register;

export const getRegistration = createSelector(
  getRegisterPageState,
  state => state.registration,
);

export const getRegistrationInvitationToken = createSelector(
  getRegistration,
  state => state.invitationToken,
);

export const getRegistrationEmail = createSelector(
  getRegistration,
  state => state.email,
);

export const getVerifyTokenQuery = createSelector(
  getRegisterPageState,
  state => state[VERIFY_TOKEN_QUERY],
);

export const getVerifyTokenQueryStatus = createSelector(
  getRegisterPageState,
  state => getStatus(state[VERIFY_TOKEN_QUERY]),
);

export const getSubmitRegistrationQuery = createSelector(
  getRegisterPageState,
  state => state[SUBMIT_REGISTRATION_QUERY],
);

export const getSubmitRegistrationQueryStatus = createSelector(
  getRegisterPageState,
  state => getStatus(state[SUBMIT_REGISTRATION_QUERY]),
);
