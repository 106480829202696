import {
  MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY,
} from '+setupTool/+preCommissioning/store/+preCommissioning.state';
import { getStatus } from '+shared/store/query/query.utils';
import { getPreCommissioning } from '+shared/store/setupTool';
import { createSelector } from 'reselect';

export const getPreCommissioningFields = createSelector(
  getPreCommissioning,
  state => state.fields,
);

export const getPreCommissioningStatus = createSelector(
  getPreCommissioning,
  state => state.status,
);

export const getPreCommissioningMarkDocumentGenerationQuery = createSelector(
  getPreCommissioning,
  state => state[MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY],
);

export const getPreCommissioningMarkDocumentGenerationQueryStatus = createSelector(
  getPreCommissioningMarkDocumentGenerationQuery,
  query => getStatus(query),
);
