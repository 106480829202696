import { ChangeLogoForm } from '+app/+account/components';
import { mapActions } from '+app/utils';
import { FormModal } from '+shared/components/FormModal';
import { FormState } from '+shared/components/FormModal/FormModal.helper';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { QueryStatusMap } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { LinkButton, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './Logo.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: LayoutActions.toggleModal,
});

interface LogoProps {
  logotypeUrl: string | undefined;
  queryStatus: QueryStatusMap;
  submitLogo: (arg0: any) => any;
  resetUploadLogoQuery: any;
  title: string;
  hint: string;
  headline: string;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & LogoProps
  ;

const LogoComponent: React.FC<Props> = ({
  logotypeUrl,
  openModalId,
  actions,
  queryStatus,
  submitLogo,
  resetUploadLogoQuery,
  title,
  hint,
  headline,
}) => {
  const onOpen = () =>
    actions.toggleModal(true, ModalId.LOGO);

  const onClose = () => {
    resetUploadLogoQuery();
    setFormState(FormState.FORM);
    actions.toggleModal(false);
  };

  const onTryAgain = () => {
    resetUploadLogoQuery();
    setFormState(FormState.FORM);
  };

  const [formState, setFormState] = React.useState<FormState>(FormState.FORM);

  React.useEffect(() => {
    if (queryStatus.success) {
      setFormState(FormState.FORM_SUCCESS);
    }

    if (queryStatus.error) {
      setFormState(FormState.FORM_ERROR);
    }
  }, [queryStatus]);

  return (
    <div className={'c-logo'}>
      <PageSubheadline mediumGap={true}>
        <div className={'c-logo__wrapper'}>
          {headline}
          <LinkButton
            onClick={onOpen}
            className={'c-logo__button'}
          >
            {I18n.t(T.companyAccount.companyLogo.action)}
          </LinkButton>
        </div>
      </PageSubheadline>
      {logotypeUrl &&
        <img
          className={'c-logo__image'}
          src={logotypeUrl}
          alt={'Logo'}
        />
      }
      <FormModal
        isModalOpen={openModalId === ModalId.LOGO}
        status={formState}
        headlineSuccess={I18n.t(T.companyAccount.companyLogo.success)}
        headlineFailure={I18n.t(T.companyAccount.companyLogo.failure)}
        onClose={onClose}
        onTryAgain={onTryAgain}
        defaultForm={
          <ChangeLogoForm
            title={title}
            hint={hint}
            submitForm={submitLogo}
            onClose={onClose}
            isLoading={queryStatus.pending}
            logo={logotypeUrl}
          />
        }
      />
    </div>
  );
};

export const Logo = connect(mapStateToProps, mapDispatchToProps)(LogoComponent);
