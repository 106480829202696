import { BatteryModelName } from '+shared/store/lead/types/leadBattery.interface';
import { LeadOfferProduct, OfferProductStatus } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductType } from '+shared/store/lead/types/leadProductType.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import {
  formFields as formFieldsBattery,
} from '../../components/LeadConfigurationBattery/LeadConfigurationBattery.helper';
import {
  formFields as formFieldsConsumption,
} from '../../components/LeadConfigurationConsumption/LeadConfigurationConsumption.helper';
import { MAX_FLAT_DIRECT_DATE } from '../../store/+configuration.helper';
import { NumberField } from '../../store/types';
import {
  formFields as formFieldsPv,
} from '../LeadConfigurationPv/LeadConfigurationPv.helper';

export const formFields = {
  ...formFieldsBattery,
  ...formFieldsConsumption,
  ...formFieldsPv,
};

export enum ConfigurationCreationValidationErrorTitles {
  SPECIFIC_ERROR = 'ConfigurationCannotGenerateSpecific',
  GENERAL_ERROR = 'ConfigurationCannotGenerateGeneral',
}

export const mapLeadErrorTitleToTranslatedError = (title: string, detail: string) => {
  switch (title) {
    case ConfigurationCreationValidationErrorTitles.SPECIFIC_ERROR:
      return detail;
    case ConfigurationCreationValidationErrorTitles.GENERAL_ERROR:
      return I18n.t(T.lead.boc._salessolution_.form.generalValidationError);
    default:
      return I18n.t(T.lead.boc._salessolution_.form.generalValidationError);
  }
};

export const scrolltoError = () => {
  const error = document.getElementById('form-error-banner');
  if (error !== null) {
    error.scrollIntoView(true);
  }
};

export interface ShouldSkipRecommendationProps {
  batteryName?: BatteryModelName;
  commissioningDate?: NumberField;
}

export const isProposalForPostEeg = (commissioningDate: NumberField) =>
  (new Date(commissioningDate) <= MAX_FLAT_DIRECT_DATE);

export const shouldSkipRecommendation = (
  { batteryName, commissioningDate }: ShouldSkipRecommendationProps,
) =>
  (!!batteryName && [
    BatteryModelName.SONNENBATTERIE_ECO_6,
    BatteryModelName.SONNENBATTERIE_ECO_7,
  ].includes(batteryName))
  || (!!commissioningDate && isProposalForPostEeg(commissioningDate));

export const findAcceptedHwProduct = (products: LeadOfferProduct[]) => products.find(product =>
  product.productType === LeadProductType.BATTERY &&
  [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(product.status));
