import { getBrowserLocale } from '+app/i18n/i18n.helpers';
import { DayPickerSelects } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as moment from 'moment';
import * as React from 'react';
import {
  DatepickerArrowSide,
  getMonthTranslations,
  getWeekDaysShortTranslations,
  getWeekDaysTranslations,
} from './DatePicker.helper';

import './DatePicker.component.scss';

const DEFAULT_MIN_DATE = new Date('1970-01-01');
const DEFAULT_MAX_DATE = moment().add(2, 'years').toDate();

export interface Props {
  isActive: boolean;
  arrowPosition?: DatepickerArrowSide;
  date: Date;
  minDate?: Date;
  maxDate?: Date;
  onDayClick: (date: Date) => void;
}

export const DatePicker: React.FC<Props> = ({
  isActive,
  arrowPosition = DatepickerArrowSide.TOP,
  date,
  minDate,
  maxDate,
  onDayClick,
}) => (
  <div className={classNames('c-date-picker', {
    'is-active': isActive,
    [`c-date-picker--arrow-${arrowPosition}`]: arrowPosition,
  })}> 
    <div className={classNames('c-date-picker__content', {
      [`c-date-picker__content--arrow-${arrowPosition}`]: arrowPosition,
      })}>
      <div className={'c-date-picker__day-picker'}>
        <DayPickerSelects
          locale={getBrowserLocale()}
          months={getMonthTranslations()}
          weekdaysLong={getWeekDaysTranslations()}
          weekdaysShort={getWeekDaysShortTranslations()}
          minDate={minDate || DEFAULT_MIN_DATE}
          maxDate={maxDate || DEFAULT_MAX_DATE}
          selectedDays={date}
          onDayClick={onDayClick}
          isSelectionHighlighted={true}
        />
      </div>
    </div>
  </div>
);
