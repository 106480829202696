export const encode = (data: string) => btoa(data);

export const decode = (base64Encoded: string) => atob(base64Encoded);

export const toBuffer = (data: string) =>
  new Uint8Array(
    data.split('')
      .map(c => c.charCodeAt(0)),
  );

export const download = (fileName: string) => (url: string) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  return url;
};

export const pdf = (buffer: Uint8Array) => {
  const blob = new Blob(
    [buffer],
    { type: 'application/pdf' },
  );

  return URL.createObjectURL(blob);
};

export const revokeUrl = (delay: number) => (url: string) =>
  setTimeout(
    () => URL.revokeObjectURL(url),
    delay,
  );
