import { CUSTOMER_ROUTES, DASHBOARD_ROUTES, LEAD_ROUTES, PATHS, SETUP_ROUTES } from '+app/router';
import { mapActions } from '+app/utils';
import { FeatureName } from '+config';
import { CountryFeatureName } from '+config/countryFlags';
import { NavLinkLabel } from '+shared/components/NavLinkLabel';
import { NavSelect } from '+shared/components/NavSelect';
import { checkRoleRestriction, RestrictedToRoles } from '+shared/components/RestrictedToRoles';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { isAuthenticated } from '+shared/store/auth/auth.selectors';
import { contactsAllowedRoles } from '+shared/store/contact';
import { leadTabAllowedRoles, setupLeadTabAllowedRoles } from '+shared/store/lead';
import { UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature, useFeature } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Nav, NavLink, NavUser } from '../../components';
import { LayoutActions } from '../../store/layout';
import { isNavOpened } from '../../store/layout/layout.selectors';
import { StoreState } from '../../store/store.interface';
import { getUserCompanyLogo, getUserProfile, getUserProfileRoles } from '../../store/user/user.selectors';

const mapDispatchToProps = mapActions({
  toggleNavigation: LayoutActions.toggleNavigation,
});

const mapStateToProps = (state: StoreState) => ({
  location: state.router.location,
  userProfile: getUserProfile(state),
  isAuthenticated: isAuthenticated(state),
  isNavMobileActive: isNavOpened(state),
  userRoles: getUserProfileRoles(state),
  companyLogo: getUserCompanyLogo(state),
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
  ;

export const LayoutNavComponent: React.FC<Props> = (props) => {
  const { track } = useTracking();

  const trackSonnenWorld = (selectOption: string) =>
    (event: React.SyntheticEvent<HTMLAnchorElement>) =>
      track(InteractionEvents.linkClick({
        category: EventCategory.SONNEN_WORLD,
        action: `${ActionName.OPEN_SECTION} - ${selectOption}`,
        event,
      }));

  const renderNavItems = ({ userProfile, location, userRoles }: Props) => {
    const isCompanyCoordinator = checkRoleRestriction(userRoles, [UserRole.COMPANY_COORDINATOR]);
    const isCountryCodeDE = userProfile && userProfile.countryCode === 'DE';

    const isSetupPhaseFeatureEnabled = useCountryFeature(CountryFeatureName.SETUP_PHASE).isEnabled;
    const isRolloutEnabled = useFeature(FeatureName.ROLLOUT).isEnabled;

    const getSetupNav = () => {
      const setupNav = (<RestrictedToRoles
        allowedRoles={setupLeadTabAllowedRoles}
        userRoles={isNil(userProfile) ? [] : userProfile.roles}
      >
        <NavLink
          action={PATHS.SETUP_LEADS()}
          matchMultiplePaths={{
            paths: SETUP_ROUTES,
            location,
          }}
        >
          {I18n.t(T.general.navigation.setup)}
        </NavLink>
      </RestrictedToRoles>);

      if (!isRolloutEnabled) {
        return setupNav;
      }

      return isSetupPhaseFeatureEnabled ? setupNav : null;
    };

    const initialLeadPath =
      isCompanyCoordinator
        ? PATHS.LEADS_INBOX()
        : PATHS.LEADS();

    return (
      <>
        <NavLink
          action={PATHS.DASHBOARD()}
          matchMultiplePaths={{
            paths: DASHBOARD_ROUTES,
            location,
          }}
        >
          {I18n.t(T.general.navigation.home)}
        </NavLink>
        <RestrictedToRoles
          allowedRoles={leadTabAllowedRoles}
          userRoles={isNil(userProfile) ? [] : userProfile.roles}
        >
          <NavLink
            action={initialLeadPath}
            matchMultiplePaths={{
              paths: LEAD_ROUTES,
              location,
            }}
          >
            {I18n.t(T.general.navigation._salessolution_.leads)}
          </NavLink>
        </RestrictedToRoles>

        {getSetupNav()}

        <NavLink
          action={PATHS.CUSTOMERS()}
          matchMultiplePaths={{
            paths: CUSTOMER_ROUTES,
            location,
          }}
        >
          {I18n.t(T.general.navigation.customers)}
        </NavLink>
        <NavLink action={PATHS.CASES()}>{I18n.t(T.general.navigation.cases)}</NavLink>
        <NavSelect
          triggerName={I18n.t(T.general.navigation.sonnenWorld)}
          handleShow={(event) => track(InteractionEvents.linkClick({
            category: EventCategory.SONNEN_WORLD,
            action: ActionName.SHOW_NAV,
            event,
          }))
          }
          navItems={(
            <>
              <NavLink
                action={I18n.t(T.general.navigation.marketingSupportLink)}
                type={'mobile'}
                border={true}
              >
                {I18n.t(T.general.navigation.marketingSupport)}
                <NavLinkLabel />
              </NavLink>
              <NavLink
                action={I18n.t(T.general.navigation.documentsGeneralLink)}
                type={'mobile'}
                border={true}
                onClick={trackSonnenWorld('General documents')}
              >
                {I18n.t(T.general.navigation.documentsGeneral)}
              </NavLink>
              <NavLink
                action={I18n.t(T.general.navigation.documentsLink)}
                type={'mobile'}
                border={true}
                onClick={trackSonnenWorld('Technical and service documents')}
              >
                {I18n.t(T.general.navigation.documentsService)}
              </NavLink>
              {isCountryCodeDE &&
                <NavLink
                  action={PATHS.DRIVE()}
                  type={'mobile'}
                  border={true}
                  onClick={trackSonnenWorld('Sonnen drive')}
                >
                  {I18n.t(T.general.navigation.sonnenDrive)}
                  <NavLinkLabel />
                </NavLink>}
              <NavLink
                action={I18n.t(T.general.navigation.eLearningLink)}
                type={'mobile'}
                border={true}
                onClick={trackSonnenWorld('eAcademy')}
              >
                {I18n.t(T.general.navigation.eLearning)}
              </NavLink>
              <NavLink
                action={I18n.t(T.general.navigation.releaseNotesLink)}
                type={'mobile'}
                border={true}
                onClick={trackSonnenWorld('releaseNotes')}
              >
                {I18n.t(T.general.navigation.releaseNotes)}
              </NavLink>
            </>
          )}
        />
        <NavLink action={PATHS.HELP()}>
          {I18n.t(T.general.navigation.help)}
        </NavLink>
        <NavUser
          user={userProfile}
          navItems={(
            <>
              <NavLink
                action={'/account'}
                type={'mobile'}
                border={true}
              >
                {I18n.t(T.general.navigation.myAccount)}
              </NavLink>
              <RestrictedToRoles
                allowedRoles={contactsAllowedRoles}
                userRoles={isNil(userProfile) ? [] : userProfile.roles}
              >
                <NavLink
                  action={'/contacts'}
                  type={'mobile'}
                  border={true}
                >
                  {I18n.t(T.general.navigation.contacts)}
                </NavLink>
              </RestrictedToRoles>
              <NavLink
                action={PATHS.LOGOUT()}
                type={'mobile'}
                faded={true}
                border={true}
              >
                {I18n.t(T.general.navigation.logOut)}
              </NavLink>
            </>
          )}
        />
      </>
    );
  };

  const { isNavMobileActive, isAuthenticated, actions, companyLogo } = props;

  return (
    <Nav
      isSticky={true}
      isMobileNavActive={isNavMobileActive}
      navItems={isAuthenticated && renderNavItems(props)}
      onToggle={actions.toggleNavigation}
      companyLogo={companyLogo}
    />
  );
};

export const LayoutNav = connect(mapStateToProps, mapDispatchToProps)(withRouter(LayoutNavComponent));
