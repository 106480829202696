import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './AccordionButton.component.scss';

interface Props {
  isAccordionOpen: boolean;
  setAccordionOpen: (arg: boolean) => void;
  messageOpen: string;
  messageClosed: string;
}

export const AccordionButton: React.FC<Props> = ({
  isAccordionOpen,
  setAccordionOpen,
  messageOpen,
  messageClosed,
}) => (
  <button
    onClick={() => setAccordionOpen(!isAccordionOpen)}
    className={'c-accordion-button'}
  >
    <div className={'c-accordion-button__text'}>
      {isAccordionOpen ? messageOpen : messageClosed}
    </div>
    <div className={classNames('c-accordion-button__icon', {
      'is-rotated': isAccordionOpen,
    })}>
      <Icon.Angle />
    </div>
  </button>
);
