import { CustomerContractWidgetStatusTile } from '+app/+customer/+overview/components/CustomerContractWidgetStatusTile';
import { Contract, MeterInstallation } from '+shared/store/contract/types/contract.interface';
import { getContractWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { formatDate } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { StatusType } from '@sonnen/shared-web';
import { isNil } from 'lodash/fp';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

const getFormattedDate = (date: string) => formatDate(date);

export const getMaxEnergyAmount = (amountOfFreeEnergy: number | null) =>
  isNil(amountOfFreeEnergy) ? 0 : amountOfFreeEnergy;

export const getDeliveryStatus = (date: string) => {
  const formattedDeliveryDate = getFormattedDate(date);
  const deliveryDate = moment(date).valueOf();
  const now = moment().valueOf();

  if (!date) {
    return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.inactive);
  }
  if (now > deliveryDate) {
    return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.active, { start: formattedDeliveryDate });
  }

  return I18n.t(T.customerSingle.contract.sonnenFlat.tableValues.notDelivered, { start: formattedDeliveryDate });
};

export const setContractType = (type: string) => type.includes('energyGlobal') ? 'sonnenFlat X' : type;

export const getDefaultContractDetailsItems = (contract: Contract) => [
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.first),
    value: setContractType(contract.contractType),
  },
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.third),
    value: contract.contractNumber,
  },
  {
    label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.forth),
    // TODO: GET RID OF String()
    value: String(getFormattedDate(contract.orderedAt)),
  },
];

export const getFlatProductDetailsItems = (
  contract: Contract,
  meterInstallation: MeterInstallation | undefined,
) => {
  const warnings = getContractWarnings({ contract, meterInstallation });
  const items = [
    ...getDefaultContractDetailsItems(contract),
    {
      label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.fifth),
      value: contract.deliveryStartAt ? getDeliveryStatus(contract.deliveryStartAt) : '-',
    },
    {
      label: I18n.t(T.customerSingle.contract.sonnenFlat.tableLabels.second),
      // TODO: format with getKiloPowerObj
      value: contract.amountOfFreeEnergy ? `${contract.amountOfFreeEnergy} kWh` : null,
    },
    {
      label: I18n.t(T.customerSingle.overview.contract.meter),
      value: warnings.meterInstallationWarningFactory && (
        <CustomerContractWidgetStatusTile
          message={warnings.meterInstallationWarningFactory.message}
          messageExtended={warnings.meterInstallationWarningFactory.messageExtended}
          type={warnings.meterInstallationWarningFactory.type || StatusType.DEFAULT}
        />
      ),
    },
  ];
  { console.log(meterInstallation); }
  if (!!meterInstallation) {
    items.push({
      label: I18n.t(T.customerSingle.overview.contract.fst),
      value: warnings.missingPvRemoteControl && (
        <CustomerContractWidgetStatusTile
          message={warnings.missingPvRemoteControl.message}
          messageExtended={warnings.missingPvRemoteControl.messageExtended}
          type={warnings.missingPvRemoteControl.type || StatusType.DEFAULT}
        />
      ),
    });
  }

  return items;
};
