import { breakpointUp, Bubble } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { StatusTileColors } from '.';

import './StatusTile.component.scss';

export interface Props {
  label: string;
  color?: StatusTileColors;
  id?: string;
  additionalClass?: string;
  compact?: boolean;
  transparent?: boolean;
  inline?: boolean;
  tooltipText?: string;
  bold?: boolean;
  onClick?: () => void;
  noMargin?: boolean;
}

export const StatusTile: React.FC<Props> = ({
  label,
  color,
  additionalClass,
  compact = false,
  transparent = false,
  tooltipText,
  bold = false,
  id,
  onClick,
  noMargin = false,
}) => {
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);

  return (
    <div
      onMouseOver={() => toggleTooltipActivity(true)}
      onMouseOut={() => toggleTooltipActivity(false)}
      onClick={onClick}
      className={classNames('c-status-tile', {
        'c-status-tile--compact': compact,
        'c-status-tile--hoverable': tooltipText || onClick,
        'c-status-tile--no-margin': noMargin,
      })}
    >
      <div id={id} className={classNames('c-status-tile__status-wrapper', {
        [`${additionalClass}`]: additionalClass,
        [`c-status-tile__status-wrapper--${color}`]: color,
        'c-status-tile__status-wrapper--transparent': transparent,
      })}>
        <div className={classNames('c-status-tile__label', {
          'c-status-tile__label--bold': bold,
        })}>
          {label}
        </div>
      </div>
      {tooltipText && isTooltipActive &&
        <div className={'c-status-tile__bubble-wrapper'} >
          <Bubble
            side={window.innerWidth >= breakpointUp('SM') ? 'top' : 'top-left'}
            isThemeDense={false}
          >
            {tooltipText}
          </Bubble>
        </div>
      }
    </div>
  );
};
