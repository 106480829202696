import { getAssignedPartner } from '+app/+lead/+overview/components/LeadOverviewHeader/LeadOverviewHeader.helper';
import { LeadEditStatusModal } from '+app/+lead/components/LeadEditStatusModal';
import { mapCreationDate } from '+app/+lead/store/+lead.helper';
import {
  LeadListLocation,
  LeadListName,
} from '+lead/+list/components';
import { LeadCurrentStatus } from '+lead/components/LeadCurrentStatus';
import { companyCoordinatorRoles, isLeadSonnenNowInterested } from '+lead/store/+lead.helper';
import { RestrictedToRoles } from '+shared/components/RestrictedToRoles';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { Contact } from '+shared/store/contact/types';
import { ModalId } from '+shared/store/layout';
import { Lead } from '+shared/store/lead/types';
import { UserRole } from '+shared/store/user';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, MediaQuery, Status, StatusType, TableCell } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';
import { InboxLeadListPartnerName } from '../InboxLeadListPartnerName';

import './LeadListTableItem.component.scss';

interface Props {
  item: Lead;
  onLeadClick: (leadId: string) => void;
  partnerEmployeeList: Contact[];
  userRoles: UserRole[];
  isModalOpen: boolean;
  toggleModal: (isModalOpen: boolean, openModalId?: ModalId) => void;
  toggleAssignLeadModal: (arg: boolean) => void;
  setChosenLead: (lead: Lead) => void;
}

const displayReassignBtn = (openLeadAssignModal: () => void) => (
  <button
    className={'c-lead-list-table-item__reassign'}
    onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      openLeadAssignModal();
    }}
  >
    <Icon.Pen className={'c-lead-list-table-item__reassign-icon'} />
  </button>
);

export const LeadListTableItem: React.FC<Props> =
  ({
    item,
    onLeadClick,
    isModalOpen,
    toggleModal,
    partnerEmployeeList,
    userRoles,
    toggleAssignLeadModal,
    setChosenLead,
  }) => {
    const [isEditStatusModalOpen, setEditStatusModalOpen] = React.useState<boolean>(false);
    const assignedPartner = getAssignedPartner(partnerEmployeeList, item);
    const { trackFunction } = useTracking();

    const openLeadAssignModal = () => {
      toggleAssignLeadModal(true);
      toggleModal(true);
      setChosenLead(item);
    };

    const openLeadEditStatusModal = trackFunction((event) => {
      setEditStatusModalOpen(true);
      toggleModal(true, ModalId.LEAD_EDIT_STATUS);
    })((event) => {
      return InteractionEvents.buttonClick({
        category: EventCategory.STATUSES,
        action: 'Update & Move to set up from Lead List',
        event,
      });
    });

    return (
      <>
        <div
          className={'c-lead-list-table-item'}
          onClick={() => onLeadClick(item.id)}
        >
          <TableCell className={`c-lead-list-table-item__cell`}>
            <LeadListName item={item} />
          </TableCell>
          <TableCell className={`c-lead-list-table-item__cell`}>
            <LeadListLocation item={item} />
          </TableCell>
          <TableCell className={`c-lead-list-table-item__cell`}>
            {mapCreationDate(item)}
          </TableCell>

          <RestrictedToRoles
            allowedRoles={companyCoordinatorRoles}
            userRoles={userRoles}
          >
            <TableCell className={`c-lead-list-table-item__cell c-lead-list-table-item__cell--partner`}>
              {assignedPartner ? (
                <div className={'c-lead-list-table-item__partner'}>
                  <InboxLeadListPartnerName
                    partner={assignedPartner}
                    showRoles={false}
                    partnerInitials={false}
                    compact={true}
                  />
                </div>
              ) : (
                  <p className={'c-lead-list-table-item__unassigned'}>
                    {I18n.t(T.lead.list._salessolution_.unassigned)}
                  </p>
                )}
              {displayReassignBtn(openLeadAssignModal)}
            </TableCell>
          </RestrictedToRoles>

          <TableCell className={`c-lead-list-table-item__cell c-lead-list-table-item__cell--status`}>
            <Media query={{ ...MediaQuery.DOWN_LG, ...MediaQuery.UP_XS }}>
              {(isMedium: boolean) => (
                <LeadCurrentStatus
                  className={'c-lead-list-table-item__status'}
                  lead={item}
                  setModalOpen={openLeadEditStatusModal}
                  compact={isMedium}
                />
              )}
            </Media>

          </TableCell>
          {item.isNewlyAssigned &&
            <div className={'c-lead-list-table-item__hint'}>
              <Status
                type={StatusType.SUCCESS}
                label={I18n.t(T.lead.list._salessolution_.newSonnenLead)}
              />
            </div>
          }

          {isLeadSonnenNowInterested(item) &&
            <div className={'c-lead-list-table-item__hint'}>
              <Status
                type={StatusType.WARNING}
                label={I18n.t(T.lead.boc._salessolution_.additionalDetails.productInterest.sonnenNow)}
              />
            </div>
          }
        </div>

        <LeadEditStatusModal
          isModalOpen={isEditStatusModalOpen && isModalOpen}
          toggleEditModal={setEditStatusModalOpen}
          toggleModal={toggleModal}
          lead={item}
        />
      </>
    );
  };
