import { ActionsUnion, createAction } from '+utils/redux';
import { RawResponse } from '@coolio/json-api';
import { CLAIM_BATTERY_OWNERSHIP_QUERY, IDENTIFY_MISSING_BATTERY_QUERY } from './partner.state';
import { AssignedBattery, AssignedBatteryData } from './types/assignedBattery.interface';
import { ClaimBatteryAttributes } from './types/claimBattery.interface';

export enum PARTNER_ACTIONS {
  IDENTIFY_MISSING_BATTERY = '[Partner] IDENTIFY_MISSING_BATTERY',
  CLEAR_MISSING_BATTERY_STATE = '[Partner] CLEAR_MISSING_BATTERY_STATE',
  CLAIM_MISSING_BATTERY_OWNERSHIP = '[Partner] CLAIM_MISSING_BATTERY_OWNERSHIP',
  SET_ASSIGNED_BATTERY = '[Partner] SET_ASSIGNED_BATTERY',
}

export const PartnerActions = {
  identifyMissigBattery: createAction(
    PARTNER_ACTIONS.IDENTIFY_MISSING_BATTERY,
    (serialNumber: string) => ({ queryKey: IDENTIFY_MISSING_BATTERY_QUERY, serialNumber }),
  ),
  clearMissingBatteryState: createAction(
    PARTNER_ACTIONS.CLEAR_MISSING_BATTERY_STATE,
  ),
  claimMissingBatteryOwnership: createAction(
    PARTNER_ACTIONS.CLAIM_MISSING_BATTERY_OWNERSHIP,
    (claimBattery: ClaimBatteryAttributes) => ({ 
      queryKey: CLAIM_BATTERY_OWNERSHIP_QUERY,
      claimBattery,
    }),
  ),
  setAssignedBattery: createAction(
    PARTNER_ACTIONS.SET_ASSIGNED_BATTERY,
    (
      { assignedBattery, assignedBatteryRaw }:
      { assignedBattery: AssignedBattery, assignedBatteryRaw: RawResponse<AssignedBatteryData> },
    ) => ({ assignedBattery, assignedBatteryRaw }),
  ),
};

export type PartnerActions = ActionsUnion<typeof PartnerActions>;
