import { SiteLiveState } from '+shared/store/site/types';
import { Data, MergedData } from '@coolio/json-api';
import { BatteryProductAttributes } from './batteryProduct.interface';

export type BatteryBackupDeviceType = 'none' | 'protect' | 'backupbox';

export type BatteryWarrrantyPeriod =
  | '20y'
  | '10y'
  | '5y'
  | '7y'
  | '2y'
  | '10y_full'
  | '10y_basic'
  | '10y_value'
  | 'ibp_missing'
  | 'expired'
  | 'null';

interface BatteryExtension {
  liveState: SiteLiveState;
}

export enum BatteryType {
  EATON = 'eaton',
  SPREE = 'spree',
}

export interface BatteryAttributes extends Partial<BatteryExtension> {
  serialNumber: string;
  model: string;
  installerName: string;
  installerStreet: string;
  installerPostalCode: string;
  installerCity: string;
  installerState: string;
  installerCountry: string;
  installerPhone: string;
  installerEmail: string;
  installerAccountName: string;
  installerNote: string | null;
  installationDate: string | null;
  installationStreet: string;
  installationPostalCode: string;
  installationCity: string;
  installationState: string;
  installationCountryCode: string;
  batteryCapacity: number;
  batteryModules: number;
  batteryInverterDesignPower: number;
  salesforceAssetId: string;
  controllerType: BatteryType;
  hardwareVersion: string;
  softwareVersion: string;
  batteryChargeCycles: number;
  backupPowerBuffer: number | null;
  backupDeviceType: BatteryBackupDeviceType;
  backupNominalPower: number;
  lastPowerOutageAt: string;
  lastMeasurementAt: string;
  warrantyPeriod: BatteryWarrrantyPeriod;
  pvPeakPower: number;
  pvGridFeedInLimit: number;
  online: boolean;
  heaterConnectionStatus: boolean;
  heaterMaxTemperature: number;
  assetStatus: string;
  product: {
    quoteNameDe: string | null;
  };
}

export type BatteryData = Data<BatteryAttributes>;
export type Battery = MergedData<BatteryData>;

export type BatteryWithProductData = Data<BatteryAttributes & { product?: BatteryProductAttributes }>;
export type BatteryWithProduct = MergedData<BatteryWithProductData>;
