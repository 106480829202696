import { getContractQueryStatus } from '+app/+customer/+contract/store/+contract.selectors';
import {
  getCollectionQueryStatus,
  getCustomerCollection,
  getInstalledBatteriesCount,
  getTotalResourceCount,
  hasCustomerCollection,
} from '+app/+customer/+list/store/+customerList.selectors';
import { NewsSection } from '+app/+newsChannel/containers/NewsSection';
import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { CustomerListRouteQueryParams, CustomerRouteParams, PATHS } from '+app/router';
import { getDecryptedRouteQueryParams } from '+app/router/store/router.selectors';
import { MissingBatteryButton } from '+customer/+list/+missingBattery/components/MissingBatteryButton';
import { MissingBatteryModal } from '+customer/+list/+missingBattery/containers/MissingBatteryModal';
import { Container, PageBlock, Pagination, PaginationSummary, SortField } from '+shared/components';
import { AssetCountBadge, SearchField } from '+shared/components';
import { CaseReport } from '+shared/containers/CaseReport';
import { Tutorial } from '+shared/containers/Tutorial';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { CaseActions } from '+shared/store/case';
import { CustomerSortKey } from '+shared/store/customer/types/customerSort.type';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber, getUserProfileRoles, isSuperUserRole } from '+shared/store/user/user.selectors';
import { encryptSearchTerm } from '+utils/crypto.util';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Loadable, PageHeadline } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';
import { CustomerListNoResults } from '../../components/CustomerListNoResults';
import { CustomersTable } from '../../components/CustomersTable';

import './CustomerList.component.scss';

const mapDispatchToProps = mapActions({
  pushCustomerListPage: (params: CustomerListRouteQueryParams) => push(PATHS.CUSTOMERS(params)),
  pushCustomerPage: (params: CustomerRouteParams) => push(PATHS.CUSTOMER(params)),

  toggleModal: LayoutActions.toggleModal,
  setCaseReportActiveSite: CaseActions.setCaseReportActiveSite,
});

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
  customerCollection: getCustomerCollection(state),
  collectionQueryStatus: getCollectionQueryStatus(state),
  batteriesInstalledCount: getInstalledBatteriesCount(state),
  totalResourceCount: getTotalResourceCount(state),
  routerQueryParams: getDecryptedRouteQueryParams(state) as RouteQueryParams,
  userRoles: getUserProfileRoles(state),
  hasCustomers: hasCustomerCollection(state),
  contractQueryStatus: getContractQueryStatus(state),
  isSuperUser: isSuperUserRole(state),
  userProfileCustomerNumber: getUserProfileCustomerNumber(state),
});

type RouteQueryParams = Required<CustomerListRouteQueryParams<CustomerSortKey>>;
type RouteProps = RouteComponentProps<CustomerListRouteQueryParams<CustomerSortKey>>;

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & RouteProps
  ;

const CustomerListComponent: React.FC<Props> = ({
  customerCollection,
  collectionQueryStatus,
  actions,
  totalResourceCount,
  batteriesInstalledCount,
  routerQueryParams,
  userRoles,
  hasCustomers,
  openModalId,
  contractQueryStatus,
  isSuperUser,
  userProfileCustomerNumber,
}) => {
  const { track } = useTracking();

  const queryCustomers = (newParams: CustomerListRouteQueryParams = {}) => {
    actions.pushCustomerListPage(
      encryptSearchTerm({ ...routerQueryParams, ...newParams }, userProfileCustomerNumber),
    );
  };

  const getSortOptions = () => [
    { name: T.dashboard.sort.installationDate, key: CustomerSortKey.INSTALLATION_DATE },
    { name: T.dashboard.sort.lastName, key: CustomerSortKey.LAST_NAME },
  ];

  const handleSearch = (search: string) => {
    queryCustomers({ search, page: '1' });
    track(InteractionEvents.searchTrigger({
      category: EventCategory.CUSTOMER_LIST,
      action: isNaN(Number(search))
        ? ActionName.SEARCH_BY_WORD
        : ActionName.SEARCH_BY_NUMBER,
    }));

  };

  const handleSort = (sort: string, event: React.SyntheticEvent<HTMLAnchorElement> ) => {
    queryCustomers({ sort, page: '1' });

    track(InteractionEvents.linkClick({
      category: EventCategory.CUSTOMER_LIST,
      action: `${ActionName.SELECT_SORT_OPTION} - ${sort}`,
      event,
    }));
  };

  const handleSortDropdownShow = (event: React.SyntheticEvent<HTMLAnchorElement>) =>
    track(InteractionEvents.linkClick({
      category: EventCategory.CUSTOMER_LIST,
      action: ActionName.SHOW_SORT_SELECT,
      event,
    }));

  const handlePageChange = (page?: number) =>
    queryCustomers({ page: String(page) });

  const isPageLoading = collectionQueryStatus.pending && !routerQueryParams.search;
  const hasResults = hasCustomers || !!routerQueryParams.search;

  return (
    <div className={'c-customer-list'}>
      {userRoles && <NewsSection userRoles={userRoles} />}
      <Container className={'c-customer-list__container'}>
      <PageBlock>
        <div className={'c-customer-list__headline-wrapper'}>
          <PageHeadline smallGap={true}>
            {I18n.t(T.dashboard.pageHeadline)}
          </PageHeadline>
        </div>
      </PageBlock>
      <Loadable
        transition={'fade'}
        predicate={isPageLoading}
      >
        {hasResults ? (
          <>
            <PageBlock
              mobileDirection={'column-reverse'}
              desktopDirection={'row'}
              flexFlow={'wrap'}
            >
              <Tutorial id={'customersList'} />
              <div>
                <div className={'c-customer-list__search-field'}>
                  <SearchField
                    isAutoSearch={false}
                    value={routerQueryParams.search}
                    onSearch={handleSearch}
                    placeholder={I18n.t(T.dashboard.searchPlaceholder)}
                  />
                </div>
              </div>
              <AssetCountBadge
                count={batteriesInstalledCount}
                labelTop={I18n.t(`${T.dashboard.batteriesInstalledBadge}.1`)}
                labelBottom={I18n.t(`${T.dashboard.batteriesInstalledBadge}.2`)}
              />
            </PageBlock>
            <PageBlock className={'c-customer-list__missing-battery'}>
              <MissingBatteryButton
                openMissingBatteryModal={() => actions.toggleModal(true, ModalId.MISSING_BATTERY)}
              />
              {(openModalId === ModalId.MISSING_BATTERY) && <MissingBatteryModal/> }
            </PageBlock>
            <PageBlock
              desktopDirection={'row'}
              mobileDirection={'row'}
            >
              <Media query={{ minWidth: 576 }}>
                {(isMedium: boolean) => (
                  <PaginationSummary
                    className={'c-customer-list__pagination-summary'}
                    i18n={isMedium
                      ? T.dashboard.customersSummary
                      : T.dashboard.customersSummaryMobile}
                    page={routerQueryParams.page}
                    recordsTotal={totalResourceCount}
                  />
                )}
              </Media>
              <SortField
                options={getSortOptions()}
                selectedKey={routerQueryParams.sort}
                onOptionChange={handleSort}
                handleShow={handleSortDropdownShow}
              />
            </PageBlock>
            <PageBlock>
              <CaseReport />
              <CustomersTable
                isPending={collectionQueryStatus.pending}
                collection={customerCollection}
                onSiteClick={actions.pushCustomerPage}
                contractQueryStatus={contractQueryStatus}
                toggleModal={actions.toggleModal}
                setCaseReportActiveSite={actions.setCaseReportActiveSite}
                isSuperUser={isSuperUser}
              />
            </PageBlock>
            <PageBlock className={'c-customer-list__pagination'}>
              {(collectionQueryStatus.success || collectionQueryStatus.error) && (
                <Pagination
                  page={routerQueryParams.page}
                  recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
                  recordsTotal={totalResourceCount}
                  onPageChange={handlePageChange}
                />
              )}
            </PageBlock>
          </>
        ) : (
          <PageBlock
            className={'c-customer-list__no-results'}
            desktopDirection={'row'}
            flexFlow={'wrap'}
          >
            <Tutorial id={'customersList'} />
            <CustomerListNoResults />
          </PageBlock>
        )}
      </Loadable>
      </Container>
    </div>
  );
};

export const CustomerList = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerListComponent));
