import { insertIf } from '+utils/array.util';
import { Measurement, Point, StatisticsResolution, TimeHelper } from '@sonnen/shared-web';
import { compose, isNil } from 'lodash/fp';
import * as moment from 'moment';
import { BatteryStatuses } from '../battery/types/batteryStatuses.interface';
import { Address } from '../customer';
import { MeasurementResolution } from './types/siteMeasurements.interface';

export type MeasurementsFilters = {
  start: Date,
  end: Date,
};

export const getMeasurementsStartDate =
  TimeHelper.getStartOfDate();

export const getMeasurementsEndDate = compose(
  TimeHelper.getNextDate,
  TimeHelper.getStartOfDate(),
);

export const getMeasurementsDefaultDateRange = () => ({
  start: moment().startOf('day').toDate(),
  end: moment().endOf('day').toDate(),
});

export const validateMeasurementsFilters = (
  start: Date | undefined,
  end: Date | undefined,
): MeasurementsFilters | undefined => (start && end) ? { start, end } : undefined;

export const factorizeTimestampForResolution = (unixDate: number, res: MeasurementResolution) => (i: number) => {
  switch (res) {
    case '1m':
      return unixDate + (60 * 1 * i);
    case '3m':
      return unixDate + (60 * 3 * i);
    case '10m':
      return unixDate + (60 * 10 * i);
    default:
      throw new Error(`Unknown resolution: ${res}`);
  }
};

export const normalizeMeasurementsToTime = (
  measurements: Measurement,
  resolution: MeasurementResolution,
  startDate: Date,
) => {
  const unixStartDate = TimeHelper.getUnixFromDate(startDate);
  const factorizeTimestamp = factorizeTimestampForResolution(unixStartDate, resolution);
  const normalizedMeasurements = !isNil(measurements) ? measurements.map((measurement, i) => ({
    x: factorizeTimestamp(i),
    y: measurement,
  })) : [];

  return normalizedMeasurements;
};

export const normalizeBatteryStatusesToTime = (
  batteryStatuses: BatteryStatuses[] | undefined,
  startDate: Date,
) => !isNil(batteryStatuses) ?
    batteryStatuses
      .map((batteryStatus: BatteryStatuses) => ({
        x: moment(batteryStatus.timestamp).startOf('minute').unix(),
        y: 1,
      }))
      .reduce((acc, batteryStatusPoint, index, batteryStatusesArray) => {
        if (index === 0) {
          return [
            {
              x: TimeHelper.getUnixFromDate(startDate),
              y: 0,
            },
            ...insertIf(batteryStatusPoint.x - TimeHelper.getUnixFromDate(startDate) > 60, {
              x: batteryStatusPoint.x - 60,
              y: 0,
            }),
            batteryStatusPoint,
          ];
        }

        const differenceBetweenCurrentAndPreviousBatteryStatusPoint = batteryStatusPoint.x - batteryStatusesArray[index - 1].x;

        if (differenceBetweenCurrentAndPreviousBatteryStatusPoint > 60) {
          return [
            ...acc,
            {
              x: batteryStatusesArray[index - 1].x + 60,
              y: 0,
            },
            ...insertIf(differenceBetweenCurrentAndPreviousBatteryStatusPoint > 120, {
              x: batteryStatusPoint.x - 60,
              y: 0,
            }),
            batteryStatusPoint,
          ];
        }

        return [...acc, batteryStatusPoint];
      }, [] as Point[])
    : [];

// NOTE: discuss if needed, if so apply in normalizeMeasurementsToTime in return
const removeLastNullElements = (measurements: Point[]): Point[] => {
  if (measurements.length
    && measurements[measurements.length - 1].y === null) {
    measurements.pop();
    return removeLastNullElements(measurements);
  }
  return measurements;
};

export const defaultStatisticFilters = {
  start: moment().startOf('year').toDate(),
  end: moment().endOf('year').toDate(),
  resolution: StatisticsResolution.TOTAL,
};

export type StatisticsFilters = {
  start: Date,
  end: Date,
  resolution: StatisticsResolution,
};

export const isLiveDataDelayed = (latestLiveDate: string | undefined, delay: number = 60000) =>
  latestLiveDate && TimeHelper.isDelayed(new Date(latestLiveDate), delay);

export const getSiteAddress = (address: Address): string | undefined => {
  const { street, postalCode, city } = address;
  if (street && postalCode && city) {
    return `${city}, ${street} ${postalCode}`;
  }
  return undefined;
};
