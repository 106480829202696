import { TooltipEvent } from '@kanva/charts';
import * as classNames from 'classnames';
import * as React from 'react';

import './AnalysisChartCrosshair.component.scss';

interface Props {
  tooltipEvent: TooltipEvent | undefined;
  isVisible?: boolean;
  offset?: number;
  desktopHeight?: number;
  mobileHeight?: number;
}

export class AnalysisChartCrosshair extends React.PureComponent<Props> {
  get crosshairPosition() {
    const { tooltipEvent } = this.props;

    return tooltipEvent
      ? Math.ceil(tooltipEvent.snap.x)
      : 0;
  }

  render() {
    const {
      offset = 8,
      isVisible,
      desktopHeight,
      mobileHeight,
    } = this.props;

    return (
      <div
        className={classNames('c-analysis-chart-crosshair', {
          'is-hidden': !isVisible,
        })}
        style={{
          '--x': this.crosshairPosition,
          '--offset': offset,
          '--desktopHeight': `${desktopHeight}px`,
          '--mobileHeight': `${mobileHeight}px`,
        } as React.CSSProperties}
      >
        <div className={'c-analysis-chart-crosshair__line'} />
        <div className={'c-analysis-chart-crosshair__handle-hitbox'}>
          <div className={'c-analysis-chart-crosshair__handle'} />
        </div>
      </div>
    );
  }
}
