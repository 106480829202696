import { CaseListTable } from '+app/+case/components';
import { NewsSection } from '+app/+newsChannel/containers/NewsSection';
import { TOTAL_RESOURCE_COUNT_PER_PAGE } from '+app/App.constants';
import { CaseListRouteQueryParams, PATHS } from '+app/router';
import { getDecryptedRouteQueryParams } from '+app/router/store/router.selectors';
import {
  getCaseCollection,
  getCaseCollectionQueryStatus,
  getCaseCollectionTotalCount,
} from '+app/shared/store/case/case.selectors';
import {
  Container,
  PageBlock,
  Pagination,
  PaginationSummary,
  SearchField,
  SortField,
  StatusFilter,
} from '+shared/components';
import { CaseReportButton } from '+shared/components/CaseReportButton';
import { CaseReport } from '+shared/containers';
import { Tutorial } from '+shared/containers/Tutorial';
import { ActionName } from '+shared/GoogleTagManager';
import { CaseAggregatedStatus } from '+shared/store/case';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfileCustomerNumber, getUserProfileRoles } from '+shared/store/user/user.selectors';
import { encryptSearchTerm } from '+utils/crypto.util';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, Icon, PageHeadline } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import { sortOptions } from './CaseList.const';
import { CaseListHelper } from './CaseList.helper';
import { caseListSearchSchema } from './CaseList.schema';

import './CaseList.component.scss';

const mapStateToProps = (state: StoreState) => ({
  caseCollection: getCaseCollection(state),
  caseCollectionTotalCount: getCaseCollectionTotalCount(state),
  caseCollectionQueryStatus: getCaseCollectionQueryStatus(state),
  routerQueryParams: getDecryptedRouteQueryParams(state) as Required<CaseListRouteQueryParams>,
  userRoles: getUserProfileRoles(state),
  userProfileCustomerNumber: getUserProfileCustomerNumber(state),
});

const mapDispatchToProps = mapActions({
  pushCasesPage: (queryParams: Required<CaseListRouteQueryParams>) => push(PATHS.CASES(queryParams)),
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<CaseListRouteQueryParams>
  ;

const CaseListComponent: React.FC<Props> = ({
  actions,
  routerQueryParams,
  caseCollection,
  caseCollectionTotalCount,
  caseCollectionQueryStatus,
  userRoles,
  userProfileCustomerNumber,
}) => {
  const statusGroups = React.useMemo(CaseListHelper.getStatusGroups, []);
  const initialFilters = React.useMemo(() => CaseListHelper.getInitialFilters(routerQueryParams.status), []);
  const shouldShowPagination = caseCollectionQueryStatus.success || caseCollectionQueryStatus.error;

  const queryCases = (newParams: CaseListRouteQueryParams = {}) =>
    actions.pushCasesPage(
      encryptSearchTerm({ ...routerQueryParams, ...newParams}, userProfileCustomerNumber),
    );

  const handlePageChange = (page: number | undefined) =>
    actions.pushCasesPage(
      encryptSearchTerm({ ...routerQueryParams, page: String(page) }, userProfileCustomerNumber),
      );

  const handleSort = (order: string) =>
    actions.pushCasesPage(
      encryptSearchTerm({ ...routerQueryParams, page: '1', order }, userProfileCustomerNumber),
      );

  const handleStatusFilterChange = (statuses: CaseAggregatedStatus[]) => {
    const stringifiedStatuses = CaseListHelper.getStringifiedStatuses(statuses);
    actions.pushCasesPage(
      encryptSearchTerm({ ...routerQueryParams, page: '1', status: stringifiedStatuses },
        userProfileCustomerNumber),
      );
  };

  const handleSearch = (search?: string) => queryCases({ search, page: '1'});

  return (
    <div className={'c-case-list'}>
      {userRoles && <NewsSection userRoles={userRoles} />}
      <Container className={'c-case-list__container'}>
        <PageBlock>
          <CaseReport canEditSite={true}/>
          <div className={'c-case-list__headline-wrapper'}>
            <PageHeadline
              smallGap={true}
              sideContent={
                <CaseReportButton
                  gtmActionName={ActionName.OPEN_MODAL_ON_CASES_PAGE}
                  isLoading={false}
                  onClick={() => actions.toggleModal(true, ModalId.CASE_REPORT)}
                >
                  <Button
                    size={ButtonSize.SECONDARY}
                    label={
                      <>
                        <Icon.OpenFolder className={'c-page-header__icon'} />
                        {I18n.t(T.report.addCase)}
                      </>
                    }
                  />
                </CaseReportButton>
              }
            >
              {I18n.t(T.cases.list.pageHeadline)}
            </PageHeadline>
          </div>
        </PageBlock>
        <PageBlock>
          {/* <Tutorial id={'casesList'} /> */}
          <div className={'c-case-list__header'}>
            <div className={'c-case-list__search-field'}>
              <SearchField
                placeholder={I18n.t(T.cases.list.searchPlaceholder)}
                onSearch={handleSearch}
                isAutoSearch={false}
                value={routerQueryParams.search}
                validationSchema={caseListSearchSchema}
              />
            </div>
            <div className={'c-case-list__status-filter'}>
              <StatusFilter
                initialFilters={initialFilters}
                statusGroups={[statusGroups]}
                setStatusFilters={(statuses) => handleStatusFilterChange(statuses)}
              />
            </div>
          </div>
        </PageBlock>
        <PageBlock
          desktopDirection={'row'}
          mobileDirection={'column'}
          className={'c-case-list__summary'}
        >
          <PaginationSummary
            className={'c-pagination-summary--reordered'}
            i18n={T.cases.list.casesSummary}
            page={routerQueryParams.page}
            recordsTotal={caseCollectionTotalCount}
          />
          <SortField
            className={'c-sort--reordered'}
            options={sortOptions}
            selectedKey={routerQueryParams.order}
            onOptionChange={handleSort}
          />
        </PageBlock>
        <PageBlock>
          <CaseListTable
            items={caseCollection}
            isPending={caseCollectionQueryStatus.pending}
          />
        </PageBlock>
        {shouldShowPagination && (
          <PageBlock className={'c-case-list__pagination'}>
            <Pagination
              page={routerQueryParams.page}
              recordsPerPage={TOTAL_RESOURCE_COUNT_PER_PAGE}
              recordsTotal={caseCollectionTotalCount}
              onPageChange={handlePageChange}
            />
          </PageBlock>
        )}
      </Container>
    </div>
  );
};

export const CaseList = connect(mapStateToProps, mapDispatchToProps)(CaseListComponent);
