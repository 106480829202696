import { CaseActions } from '+shared/store/case/case.actions';
import { getUserProfileCustomerNumber } from '+shared/store/user/user.selectors';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { map } from 'rxjs/operators';
import { CaseListRouteQueryParams, ROUTES } from '../../router';
import { RouterActions } from '../../router/store';
import { getRouteQueryParams } from '../../router/store/router.selectors';
import { StoreState } from '../../shared/store/store.interface';
import { mapToState, matchPath, ofType } from '../../utils';

type Actions = CaseActions;
type Action$ = ActionsObservable<Actions>;
type State$ = StateObservable<StoreState>;

export const getCaseCollection$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.isReady),
  mapToState(state$),
  matchPath(ROUTES.CASES[0]),
  map(getRouteQueryParams),
  map(queryParams => queryParams as CaseListRouteQueryParams),
  map(queryParams => CaseActions.getCaseList(queryParams, getUserProfileCustomerNumber(state$.value))),
);

export const epics = combineEpics(
  getCaseCollection$,
);
