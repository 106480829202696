import { SiteLiveStateData } from '+shared/store/site/types/siteLiveState.interface';
import {
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
  StatisticsResolution,
  StatisticsSelectedDate,
  TimeHelper,
  TimeUnit,
 } from '@sonnen/shared-web';
import { compose } from 'lodash/fp';
import { PersistedState } from 'redux-persist';
import { Query } from '../../../shared/store/query';
import { SiteMeasurements, SiteMeasurementsData } from '../../../shared/store/site';
import { ChartDataSeries, ChartDataSeriesKey } from './types/chartDataSeries.interface';
import { SiteForecastConsumption, SiteForecastProduction } from './types/forecast.interface';

export const GET_SITE_MEASUREMENTS_QUERY = 'getSiteMeasurementsQuery';
export const GET_SITE_LIVE_STATE_QUERY = 'getSiteLiveStateQuery';
export const GET_FORECAST_PRODUCTION_QUERY = 'getForecastProductionQuery';
export const GET_FORECAST_CONSUMPTION_QUERY = 'getForecastConsumptionQuery';

export interface AnalysisPageState extends PersistedState {
  dayChart: {
    selectedDate: number;
    selectedDataSeriesKeys: ChartDataSeriesKey[];
    forecastProductionFull?: SiteForecastProduction[];
    forecastConsumptionFull?: SiteForecastConsumption[];
    dataSeries: ChartDataSeries;
    siteMeasurements?: SiteMeasurements;
    isUnsupportedBattery: boolean;
  };
  statistics: {
    selectedDate: StatisticsSelectedDate;
    pieChartSeries?: PieChartSeries;
    barChartSeries?: BarChartSeries;
  };
  [GET_SITE_MEASUREMENTS_QUERY]: Query<SiteMeasurementsData>;
  [GET_SITE_LIVE_STATE_QUERY]: Query<SiteLiveStateData>;
  [GET_FORECAST_PRODUCTION_QUERY]: Query<SiteForecastProduction[]>;
  [GET_FORECAST_CONSUMPTION_QUERY]: Query<SiteForecastConsumption[]>;
}

export const initialState: AnalysisPageState = {
  dayChart: {
      selectedDate: compose(
      TimeHelper.getUnixFromDate,
      TimeHelper.getStartOfDate(),
    )(new Date()),
    selectedDataSeriesKeys: [
      EnergyFlowSeriesKey.PRODUCTION_POWER,
      EnergyFlowSeriesKey.CONSUMPTION_POWER,
      EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
      EnergyFlowSeriesKey.BATTERY_USOC,
    ],
    dataSeries: {
      productionPower: [],
      consumptionPower: [],
      directUsagePower: [],
      batteryUsoc: [],
      batteryCharging: [],
      batteryDischarging: [],
      forecastProductionPower: [],
      forecastConsumptionPower: [],
      vppActivity: [],
    },
    forecastProductionFull: [],
    forecastConsumptionFull: [],
    isUnsupportedBattery: false,
    siteMeasurements: undefined,
  },
  statistics: {
    barChartSeries: {
      consumedEnergy: [],
      producedEnergy: [],
    },
    pieChartSeries: {
      autonomy: {
        gridPurchaseEnergy: 0,
        consumedEnergy: 0,
      },
      selfConsumption: {
        gridFeedinEnergy: 0,
        producedEnergy: 0,
      },
    },
    selectedDate: {
      period: TimeUnit.DAY,
      date: new Date(),
      resolution: StatisticsResolution.HOUR,
    },
  },
  [GET_SITE_MEASUREMENTS_QUERY]: {},
  [GET_SITE_LIVE_STATE_QUERY]: {},
  [GET_FORECAST_PRODUCTION_QUERY]: {},
  [GET_FORECAST_CONSUMPTION_QUERY]: {},
};
