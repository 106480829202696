import { FeatureName } from '+config/featureFlags';
import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';
import { useFeature } from '@sonnen/shared-web';

export const Views = {
  X_AXIS: 'xAxis',
  Y_AXIS_LEFT: 'yAxisLeft',
  Y_AXIS_RIGHT: 'yAxisRight',
  LINE_WRAPPER: 'lineWrapper',
};

const { isEnabled: isBatteryVppActivityEnabled } = useFeature(FeatureName.BATTERY_VPP_ACTIVITY);

const DESKTOP_PADDING = 10;
const TOP_DISTANCE = 25;
const Y_AXIS_PADDING = 4;
const X_AXIS_PADDING = 4;
const X_AXIS_MARGIN = 10;

export const layout = createLayoutMap({
  wrapper: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    above: Views.X_AXIS,
    alignEnd: PARENT_ID,
    margin: {
      top: TOP_DISTANCE,
    },
  },
  chart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  chartGrid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    above: isBatteryVppActivityEnabled ? Views.LINE_WRAPPER : undefined,
    alignBottom: isBatteryVppActivityEnabled ? undefined : PARENT_ID,
    minHeight: X_AXIS_MARGIN + 20,
    padding: {
      top: X_AXIS_PADDING + DESKTOP_PADDING,
    },
  },
  yAxisLeft: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      left: Y_AXIS_PADDING + DESKTOP_PADDING,
    },
  },
  yAxisRight: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignEnd: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      right: Y_AXIS_PADDING,
    },
  },
  lineWrapper: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
  },
  lineChartLine: {
    width: MATCH_PARENT,
    height: 60,
  },
});
