import { getCustomer } from '+app/+customer/store/+customer.helper';
import { getCustomerListPageState } from '+customer/store/+customer.selectors';
import { getStatus } from '+shared/store/query/query.utils';
import { isEmpty } from 'lodash/fp';
import { createSelector } from 'reselect';
import { GET_COLLECTION_QUERY } from './+customerList.state';

export const getCustomerCollection = createSelector(
  getCustomerListPageState,
  state => state.collection,
);

export const hasCustomerCollection = createSelector(
  getCustomerCollection,
  collection => !isEmpty(collection),
);

export const getCollectionQueryStatus = createSelector(
  getCustomerListPageState,
  state => getStatus(state[GET_COLLECTION_QUERY]),
);

export const getInstalledBatteriesCount = createSelector(
  getCustomerListPageState,
  state => state.installedBatteriesCount,
);

export const getTotalResourceCount = createSelector(
  getCustomerListPageState,
  state => state.totalResourceCount,
);

export const getCurrentCustomer = (customerId: string) => createSelector(
  getCustomerCollection,
  getCustomer(customerId),
);
