import { slugify } from '+utils/string.util';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

type FaqTopics = typeof T.help.faqTopics['0'];
type FaqQuestions = typeof T.help.faqTopics['0']['questions'];

const OPEN_PREFIX = '&open=';

const factorizeFaqTopics = () =>
  Object
    .values(I18n.t(T.help.faqTopics._all))
    .map(item => item as any as FaqTopics)
    .filter(item => item.title);

const factorizeFaqQuestions = (questions: FaqQuestions) =>
  Object
    .values(questions)
    .map(q => q as { question: string; answer: string })
    .filter(q => q.question);

export const getHelpItems = () =>
  factorizeFaqTopics()
    .map(({ title, questions }) => ({
      title,
      questions: factorizeFaqQuestions(questions),
      hash: '#' + slugify(title),
    }));

export const getSelectedTopic = () => {
  const helpItems = getHelpItems();
  return helpItems.find(item => item.hash === location.hash.split(OPEN_PREFIX)[0]) || helpItems[0];
};

export const checkAccordionOpen = (index: number) => {
  const locationHash = location.hash.split(OPEN_PREFIX);
  const locationHashOpen = locationHash[1];
  if (!locationHashOpen) {
    return false;
  }
  const openAccordions = locationHashOpen.split(',').map(Number);
  return openAccordions.includes(index);
};
