import { FormikProps } from 'formik';
import * as React from 'react';

interface Props {
  children: React.ReactNode;
  form: FormikProps<any>;
  fieldName: string;
  errorMessage: string;
  isError: boolean;
}

export const FormInputControlledError: React.FC<Props> = ({
  children,
  form,
  fieldName,
  errorMessage,
  isError,
}) => {
  React.useEffect(() => {
    if (isError) {
      form.setFieldError(fieldName, errorMessage);
    }
  }, [isError]);

  return (
    <div>{children}</div>
  );
};
