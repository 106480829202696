import { StatusTile } from '+app/shared/components/StatusTile';
import { StatusType } from '@sonnen/shared-web';
import * as React from 'react';
import { setLabelColor } from './CustomerContractWidgetStatusTile.helper';

import './CustomerContractWidgetStatusTile.component.scss';

interface Props {
  message: string;
  messageExtended?: string;
  type: StatusType;
}

export const CustomerContractWidgetStatusTile: React.FC<Props> = ({
  message,
  messageExtended,
  type,
}) => (
  <div className={'c-customer-contract-widget-status-tile'}>
    <StatusTile
      label={message}
      tooltipText={messageExtended}
      compact={true}
      color={setLabelColor(type)}
      inline={true}
      bold={true}
    />
  </div>
);
