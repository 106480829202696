import * as React from 'react';

type PaginationModes = {
  [K in number]: [number, number?]
};

export const useConfigurationsPagination = (length: number, modes: PaginationModes) => {
  const getMode = (width: number) => {
    return Object.keys(modes).reduce((mode, key) => {
      const [lowerBound, higherBound] = modes[key];
      if ((!lowerBound || width >= lowerBound) && (!higherBound || width <= higherBound)) {
        return parseInt(key, 10);
      }
      return mode;
    }, 1);
  };

  const [activeConfigurations, setActiveConfigurations] = React.useState(getMode(window.innerWidth));
  const [selectedConfigurationIndex, setSelectedConfigurationIndex] = React.useState(0);

  const handleResize = (event: any) => {
    const width = event.target.window.innerWidth;
    const mode = getMode(width);
    setActiveConfigurations(mode);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const shouldRenderConfiguration = React.useCallback((index: number) => {
    if (activeConfigurations === 1) {
      return selectedConfigurationIndex === index;
    }

    if (activeConfigurations === 2) {
      return (
        selectedConfigurationIndex === index
        || selectedConfigurationIndex === index - 1
        || selectedConfigurationIndex === index + 1
      );
    }
    return true;
  }, [selectedConfigurationIndex, activeConfigurations]);

  return {
    shouldRenderConfiguration,
    shouldEnablePagination: () => length > activeConfigurations,
    shouldEnableNext: () => selectedConfigurationIndex !== length - 1,
    shouldEnablePrev: () => selectedConfigurationIndex !== 0,
    handlePrevPage: () => setSelectedConfigurationIndex(selectedConfigurationIndex - activeConfigurations),
    handleNextPage: () => setSelectedConfigurationIndex(selectedConfigurationIndex + activeConfigurations),
  };
};
