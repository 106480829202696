import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';
import { FieldNames } from '../../containers/MissingBatteryModal/types';

export interface MissingBatteryClaimFormValues {
  [FieldNames.SERIAL_NUMBER]: string;
  [FieldNames.POSTAL_CODE]: string;
  [FieldNames.LAST_NAME]: string;
  [FieldNames.PV_POWER]: string;
}

export const missingBatteryClaimSchema = () => Yup.object({
  serialNumber: Yup.string()
    .required(I18n.t(T.missingBattery.validateSerialNumber.noEmptyValues)),
  postalCode: Yup.string()
    .required(I18n.t(T.missingBattery.validateZipCode.noEmptyValues)),
  customerLastName: Yup.string()
    .required(I18n.t(T.missingBattery.validateName.noEmptyValues)),
  pvPeakPower: Yup.number()
    .required(I18n.t(T.missingBattery.validatePVSize.noEmptyValues)),
});
