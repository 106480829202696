import { findAcceptedHwProduct } from '+app/+lead/+configuration/containers/LeadConfigurationForm/LeadConfigurationForm.helper';
import { HardwarePageActions } from '+app/+lead/+hardware/store';
import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { ImpactAnalysisActions } from '+app/+lead/+impactAnalysis/store/+impactAnalysis.actions';
import { getHardwareProduct, getOfferProductStatus, isFlatOnlyOffer } from '+app/+lead/+offer/store/+offer.helper';
import { LeadFlatConfigurationTile, LeadHardwareConfigurationTile } from '+app/+lead/components';
import { LeadHardwareStatusModal } from '+app/+lead/components/LeadHardwareStatusModal';
import { mapActions } from '+app/utils';
import { FeatureName } from '+config/featureFlags';
import { getImpactAnalysisList } from '+lead/+impactAnalysis/store/+impactAnalysis.selectors';
import { isLeadDataMissing } from '+lead/+overview/containers/LeadOverviewBasicDetails';
import { LeadOverviewPageActions } from '+lead/+overview/store/+overview.actions';
import {
  getAreAnyFlatOfferSent,
  getDisplayMaxOffersWarning,
  getLeadOfferDeleteQueryStatus,
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityStatus,
  getOfferConfigurationList,
  getOfferConfigurationListQueries,
  getOfferIdToChangeStatus,
  getOfferList,
  getOfferListQueryStatus,
  getProductBatteryList,
  getProductBatteryListQueryStatus,
  getRecalculateConfigurationQueryStatus,
} from '+lead/+overview/store/+overview.selectors';
import { getConfigurationById } from '+lead/store/+lead.helper';
import { getLead, getLeadUpdateQueryStatus } from '+lead/store/+lead.selectors';
import { AccordionButton } from '+shared/components';
import { EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { getOpenModalId, LayoutActions, ModalId } from '+shared/store/layout';
import { isLeadFlatOfferAccepted } from '+shared/store/lead/lead.helpers';
import { FlatDocumentType, Lead, LeadConfigurationResponse, LeadProductType } from '+shared/store/lead/types';
import { LeadOffer, OfferProductStatus } from '+shared/store/lead/types/leadOffer.interface';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { getStatus, QueryStatusMap } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { isStatusSet } from '+utils/status.util';
import { T } from '@sonnen/shared-i18n/service';
import {
  Feature,
  Icon,
  LinkButton,
  LinkButtonIconPosition,
  Loader,
  MediaQuery,
  useFeature,
  WarningText,
  WarningTextSize,
} from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { find, getOr, isNil } from 'lodash/fp';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid/v4';
import { LEAD_IN_SETUP_STAGE, PATHS } from '../../../../router';
import { LeadOverviewConfigurationEmpty, LeadOverviewNewConfigurationPane } from '../../components';
import { LeadOverviewVppBonusCheck } from '../../components/LeadOverviewVppBonusCheck';
import { isDsoBlackListed, isHardwareOnlyOffer } from '../../store/+overview.helper';
import { LeadOverviewVppCheckModal } from '../LeadOverviewVppCheckModal';
import {
  configurationWarningMap,
  isInvalidSonnenDirectOffer,
  isLastFlatOnlyOffer,
  isLastFlatSentOffer,
  isLastHardwareOnlySentOffer,
  isNewConfigurationPaneVisible,
  isOfferBlocked,
  mapOfferStatus,
  MAX_ALLOWED_CONFIGURATION_COUNT,
} from './LeadOverviewConfigurations.helper';
import { useConfigurationsPagination } from './LeadOverviewConfigurations.hook';

import './LeadOverviewConfigurations.component.scss';

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  offers: getOfferList(state),
  offersQueryStatus: getOfferListQueryStatus(state),
  offerDeleteQueryStatus: getLeadOfferDeleteQueryStatus(state),
  configurations: getOfferConfigurationList(state),
  configurationsQueries: getOfferConfigurationListQueries(state),
  impactAnalysisList: getImpactAnalysisList(state),
  areAnyFlatOfferSent: getAreAnyFlatOfferSent(state),
  recalculateConfigurationQueryStatus: getRecalculateConfigurationQueryStatus(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityQueryStatus: getLeadOverviewProductAvailabilityStatus(state),
  offerIdToChangeStatus: getOfferIdToChangeStatus(state),
  productBatteryList: getProductBatteryList(state),
  productBatteryListQueryStatus: getProductBatteryListQueryStatus(state),
  hardwareStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
  isMaxConfigurationsWarningVisible: getDisplayMaxOffersWarning(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  deleteOffer: LeadOverviewPageActions.deleteOffer,
  getDocument: LeadOverviewPageActions.getOfferDocument,
  composeOffer: (leadId: string, offerId: string) => push(PATHS.LEAD_OFFER({ leadId, offerId })),
  startImpactAnalysis: (leadId: string, offerId: string) => push(PATHS.LEAD_IMPACT_ANALYSIS({ leadId, offerId })),
  setupComposeOffer: (leadId: string, offerId: string) => push(
    PATHS.LEAD_OFFER({ leadId, offerId }, LEAD_IN_SETUP_STAGE),
  ),
  setupStartImpactAnalysis: (leadId: string, offerId: string) =>
    push(PATHS.LEAD_IMPACT_ANALYSIS({ leadId, offerId }, LEAD_IN_SETUP_STAGE)),
  downloadImpactAnalysisFile: ImpactAnalysisActions.downloadImpactAnalysisFile,
  deleteImpactAnalysis: ImpactAnalysisActions.deleteImpactAnalysis,
  sendImpactAnalysis: ImpactAnalysisActions.sendImpactAnalysis,
  goToNewConfiguration: (leadId: string, leadStage?: string) => push(
    PATHS.LEAD_CONFIGURATION_NEW({ leadId }, leadStage)),
  goToNewConfigurationForExistingHardware: (leadId: string, leadStage?: string, offerId?: string) => push(
    PATHS.LEAD_CONFIGURATION_NEW_FOR_HW({ leadId, offerId }, leadStage)),
  goToHardwareConfiguration: (leadId: string, leadStage?: string) => push(
    PATHS.LEAD_HARDWARE_NEW({ leadId }, leadStage)),
  setAsyncFlatOfferSentStatus: LeadOverviewPageActions.setAsyncFlatOfferSentStatus,
  toggleModal: LayoutActions.toggleModal,
  updateHardwareStatus: HardwarePageActions.updateHardwareStatus,
  clearMaxOffersWarning: LeadOverviewPageActions.clearMaxOffersWarning,
});

type ProductData = {
  offer: LeadOffer,
  configuration: LeadConfigurationResponse | undefined,
  battery: LeadProductBattery | undefined,
  configurationName: string;
  status: QueryStatusMap;
  impactAnalysis?: any;
};

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

export const LeadOverviewConfigurationsComponent: React.FC<Props> = ({
  actions,
  lead,
  leadStage,
  leadUpdateQueryStatus,
  offers,
  offersQueryStatus,
  offerDeleteQueryStatus,
  configurations,
  configurationsQueries,
  impactAnalysisList,
  areAnyFlatOfferSent,
  recalculateConfigurationQueryStatus,
  productAvailability,
  productAvailabilityQueryStatus,
  offerIdToChangeStatus,
  productBatteryList,
  productBatteryListQueryStatus,
  hardwareStatusUpdateQueryStatus,
  isMaxConfigurationsWarningVisible,
  openModalId,
}) => {
  const [showMoreProducts, setShowMoreProducts] = React.useState<boolean>(false);
  const offerToUpadateRef = React.useRef<LeadOffer | undefined>(undefined);

  React.useEffect(() => () => {
    actions.clearMaxOffersWarning();
  }, []);

  React.useEffect(() => {
    if (offersQueryStatus.success && offerIdToChangeStatus) {
      actions.setAsyncFlatOfferSentStatus(offerIdToChangeStatus);
    }
  }, [offersQueryStatus]);

  const {
    shouldEnablePagination,
    shouldRenderConfiguration,
    shouldEnableNext,
    shouldEnablePrev,
    handleNextPage,
    handlePrevPage,
  } = useConfigurationsPagination(offers.length, {
    1: [0, MediaQuery.DOWN_SM.maxWidth],
    2: [MediaQuery.UP_SM.minWidth, MediaQuery.DOWN_MD.maxWidth],
    3: [MediaQuery.UP_MD.minWidth],
  });
  const { track } = useTracking();
  const isLeadHardwareOrderConfirmed = (lead?: Lead) => isStatusSet(lead?.status.summary.hardwareOrderConfirmed);

  // DSO warnings (blacklisted or empty DSO), SonnenNow warning
  const isConfigurationWarningVisible =
    !!configurationWarningMap(lead, productAvailability).find(warning => warning.condition);

  const isCreatingNewProductDisabled = (productType: LeadProductType) => {
    if (productType === LeadProductType.BATTERY) {
      return isLeadHardwareOrderConfirmed(lead);
    } else {
      return isLeadFlatOfferAccepted(lead)
        || lead && isLeadDataMissing(lead)
        || isConfigurationWarningVisible;
    }
  };

  const getImpactAnalysis = (configurationId: string) => impactAnalysisList.find(
    impactAnalysis => impactAnalysis.configurations === configurationId,
  );

  const productsMap: Record<string, ProductData> = offers.reduce(
    (acc, offer, i) => ({
      ...acc, [offer.id]: {
        offer,
        status: isHardwareOnlyOffer(offer) ?
          productBatteryListQueryStatus : getStatus(configurationsQueries[offer.configuration] || {}),
        configuration: getConfigurationById(configurations)(offer.configuration),
        configurationName: I18n.t(T.lead.overview._salessolution_.configurations.title, {
          configurationName: String.fromCharCode(65 + i),
        }),
        impactAnalysis: getImpactAnalysis(offer.configuration),
        battery: productBatteryList.find(battery => battery.id === offer.battery),
      },
    }),
    {},
  );

  const mappedProducts: ProductData[] = offers
    .map(offer => productsMap[offer.id])
    .filter(Boolean);

  if (offersQueryStatus.pending || productAvailabilityQueryStatus.pending || productBatteryListQueryStatus.pending) {
    return (
      <div
        key={uuid()}
        className={'o-grid__column o-grid__column--md-12 c-lead-overview-configurations__loader'}
      >
        <Loader />
      </div>
    );
  }

  // @TODO: HANDLE ERRORS IN CONFIGURATION AND IMPACT ANALYSIS ACTIONS

  const isComposingOfferDisabled = (offer: LeadOffer) => leadUpdateQueryStatus.pending
    || isLeadFlatOfferAccepted(lead)
    || (lead !== undefined && !isNil(lead.dso) ? isLeadDataMissing(lead) : false)
    || !!lead && isDsoBlackListed(productAvailability, lead.dso)
    || isOfferBlocked(offer);

  const isRemoveHwOfferDisabled = (offer: LeadOffer): boolean => {
    const status = getOfferProductStatus(offer, LeadProductType.BATTERY);
    return status ? [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(status) : false;
  };

  const isRemoveFlatOfferDisabled = (offer: LeadOffer): boolean => {
    const status = getOfferProductStatus(offer, LeadProductType.FLAT_X)
      || getOfferProductStatus(offer, LeadProductType.FLAT_DIRECT);
    const isStatusAcceptedOrConfirmed = status
      ? [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(status)
      : false;

    return isStatusAcceptedOrConfirmed || leadUpdateQueryStatus.pending;
  };

  const getImpactAnalysisHandlers = (offerId: string, configurationId: string) => {
    const impactAnalysis = getImpactAnalysis(configurationId);

    if (!impactAnalysis) {
      return {
        onImpactAnalysisStart: leadStage === LEAD_IN_SETUP_STAGE
          ? () => actions.setupStartImpactAnalysis(lead!.id, offerId)
          : () => actions.startImpactAnalysis(lead!.id, offerId),
      };
    }

    return {
      onImpactAnalysisDelete: () => actions.deleteImpactAnalysis(lead!.id, impactAnalysis.id),
      onImpactAnalysisDownload: (event: React.SyntheticEvent<HTMLButtonElement>) => {
        track(InteractionEvents.buttonClick({
          category: EventCategory.IMPACT_ANALYSIS,
          action: 'Download PDF document',
          event,
        }));
        actions.downloadImpactAnalysisFile(lead!.id, impactAnalysis.id);
      },
      onImpactAnalysisSend: () => actions.sendImpactAnalysis(lead!.id, impactAnalysis.id),
    };
  };

  const renderConfigurationWarning = () => (
    isConfigurationWarningVisible &&
    <div className={'c-lead-overview-configurations__warning'}>
      <WarningText
        text={
          getOr('', 'msg', find((warning) => warning.condition, configurationWarningMap(lead, productAvailability)))
        }
      />
    </div>
  );

  const onNewConfiguration = (productType: LeadProductType) => {
    if (lead) {
      if (productType === LeadProductType.BATTERY) {
        actions.goToHardwareConfiguration(lead.id, leadStage);
      } else {
        actions.goToNewConfiguration(lead.id, leadStage);
      }
    }
  };

  const updateHardwareStatus = (status: OfferProductStatus) => {
    const offer = offerToUpadateRef.current;
    if (!lead || !offer) { return; }

    const product = getHardwareProduct(offer);
    if (!product) {
      return;
    }

    actions.updateHardwareStatus(lead.id, [{
      offerId: offer.id,
      productId: product.productId,
      status,
    }]);
  };

  const openHardwareStatusModal = (event: React.MouseEvent, offer: LeadOffer) => {
    offerToUpadateRef.current = offer;
    event.stopPropagation();
    actions.toggleModal(true, ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW);
  };

  const renderNewConfigurationPane = (
    productType: LeadProductType,
    isMobile?: boolean,
  ) => <LeadOverviewNewConfigurationPane
      isDisabled={isCreatingNewProductDisabled(productType) || !isNewConfigurationPaneVisible(offers.length)}
      onNewConfiguration={() => onNewConfiguration(productType)}
      productType={productType}
      isMobile={isMobile}
    />;

  const isHwStatusUpdateDisabled = (offer: LeadOffer): boolean => isLeadHardwareOrderConfirmed(lead) || isOfferBlocked(offer);

  return (
    <>
      {lead && isLeadDataMissing(lead) && !isNil(lead.dso) &&
        <p className={'c-lead-overview-configurations__general-error'}>
          {I18n.t(T.lead.boc._salessolution_.form.personalDetails.mandatoryHeader)}
        </p>
      }

      {isMaxConfigurationsWarningVisible && (offers.length >= MAX_ALLOWED_CONFIGURATION_COUNT) && (
        <div className={'c-lead-overview-configurations__max-configurations-warning-wrapper'}>
          <WarningText
            text={I18n.t(T.lead.overview._salessolution_.configurations.maxConfigurationsWarning)}
            size={WarningTextSize.MEDIUM}
          />
        </div>
      )}

      <Feature name={FeatureName.VPP_QUESTIONS_PER_LEAD}>
        <LeadOverviewVppBonusCheck />
      </Feature>

      <Media queries={{
        isMobile: { maxWidth: MediaQuery.DOWN_SM.maxWidth },
        isTablet: { minWidth: MediaQuery.UP_SM.minWidth, maxWidth: MediaQuery.DOWN_MD.maxWidth },
      }}>
        {(matches: { isMobile: boolean, isTablet: boolean }) => {
          const { isMobile, isTablet } = matches;

          return isMobile || isTablet ? (
            <>
              <div className={'c-lead-overview-configurations__new-configuration--mobile'}>
                <div className={'c-lead-overview-configurations__new'}>
                  {isMobile
                    ? renderNewConfigurationPane(LeadProductType.FLAT_X, isMobile)
                    : renderNewConfigurationPane(LeadProductType.FLAT_X, isTablet)}
                  {renderConfigurationWarning()}
                </div>

                {isMobile && offers.length !== 0 &&
                  <div className={'c-lead-overview-configurations__accordion-wrapper'}>
                    <AccordionButton
                      isAccordionOpen={showMoreProducts}
                      setAccordionOpen={setShowMoreProducts}
                      messageOpen={I18n.t(T.lead.overview._salessolution_.configurations.showLessProducts)}
                      messageClosed={I18n.t(T.lead.overview._salessolution_.configurations.showMoreProducts)}
                    />
                  </div>
                }

                <Feature name={FeatureName.HARDWARE_ONLY_NEW_TILE}>
                  {isMobile ?
                    (offers.length === 0 || showMoreProducts) &&
                    <div className={'c-lead-overview-configurations__new c-lead-overview-configurations__new--middle'}>
                      {renderNewConfigurationPane(LeadProductType.BATTERY, isMobile)}
                    </div>
                    :
                    <div className={'c-lead-overview-configurations__new c-lead-overview-configurations__new--middle'}>
                      {renderNewConfigurationPane(LeadProductType.BATTERY, isTablet)}
                    </div>
                  }
                </Feature>
              </div>
            </>
          ) : null;
        }}
      </Media>

      {shouldEnablePagination() && (
        <div className={'c-lead-overview-configurations__pagination__nav'}>
          <div className={'c-lead-overview-configurations__pagination__btn'}>
            {shouldEnablePrev() && (
              <LinkButton icon={<Icon.Angle />} onClick={handlePrevPage}>
                {I18n.t(T.lead.configuration._salessolution_.preview.prevConfig)}
              </LinkButton>
            )}
          </div>
          <div className={'c-lead-overview-configurations__pagination__btn'}>
            {shouldEnableNext() && (
              <LinkButton icon={<Icon.Angle />} iconPosition={LinkButtonIconPosition.AFTER} onClick={handleNextPage}>
                {I18n.t(T.lead.configuration._salessolution_.preview.nextConfig)}
              </LinkButton>
            )}
          </div>
        </div>

      )}
      <div className={'o-grid c-lead-overview-configurations'}>
        <>
          {mappedProducts.map((
            { configuration, configurationName, offer, status, impactAnalysis, battery },
            index,
          ) => shouldRenderConfiguration(index) && (
            <div
              key={uuid()}
              className={'o-grid__column o-grid__column--sm-6 o-grid__column--md-4'}
            >
              {isHardwareOnlyOffer(offer) ? (
                battery ? (
                  <LeadHardwareConfigurationTile
                    title={configurationName}
                    status={mapOfferStatus(getOfferProductStatus(offer, LeadProductType.BATTERY))}
                    battery={battery}
                    isTileActionDisabled={isRemoveHwOfferDisabled(offer)}
                    isPending={status.pending || offerDeleteQueryStatus.pending}
                    onRemove={() => actions.deleteOffer({
                      leadId: lead!.id,
                      offerId: offer.id,
                      isLastFlatOfferSent: false,
                      isLastHardwareOnlyOfferSent: isLastHardwareOnlySentOffer(offer.id, offers),
                      isLastFlatOnlyOffer: false,
                    })}
                    isStatusUpdateDisabled={isHwStatusUpdateDisabled(offer)}
                    addSonnenTarriff={() =>
                      actions.goToNewConfigurationForExistingHardware(lead!.id, leadStage, offer.id)}
                    isAddFlatDisabled={isCreatingNewProductDisabled(LeadProductType.FLAT_X) || isOfferBlocked(offer)}
                    onHardwareStatusModalOpen={(event: React.MouseEvent) => openHardwareStatusModal(event, offer)}
                    createdAt={offer.createdAt}
                    offerId={offer.id}
                  />
                ) : (
                    <LeadOverviewConfigurationEmpty
                      isLoading={true}
                      title={configurationName}
                    />
                  )
              ) : (
                  configuration ? (
                    <LeadFlatConfigurationTile
                      title={configurationName}
                      status={mapOfferStatus(
                        getOfferProductStatus(offer, LeadProductType.FLAT_X)
                        || getOfferProductStatus(offer, LeadProductType.FLAT_DIRECT),
                        isInvalidSonnenDirectOffer(offer, productAvailability),
                      )}
                      hardwareStatus={mapOfferStatus(getOfferProductStatus(offer, LeadProductType.BATTERY))}
                      configuration={configuration}
                      impactAnalysis={impactAnalysis}
                      isTileActionDisabled={isRemoveFlatOfferDisabled(offer)}
                      isComposingOfferDisabled={isComposingOfferDisabled(offer)}
                      isHardwareProductAccepted={!!findAcceptedHwProduct(offer.products)}
                      isPending={
                        status.pending ||
                        offerDeleteQueryStatus.pending ||
                        recalculateConfigurationQueryStatus.pending
                      }
                      areAnyOfferSent={areAnyFlatOfferSent}
                      onRemove={() => actions.deleteOffer({
                        leadId: lead!.id,
                        offerId: offer.id,
                        isLastFlatOfferSent: isLastFlatSentOffer(offer.id, offers),
                        isLastHardwareOnlyOfferSent: isLastHardwareOnlySentOffer(offer.id, offers),
                        isLastFlatOnlyOffer: isLastFlatOnlyOffer(offer.id, offers),
                      })}
                      onCompose={leadStage === LEAD_IN_SETUP_STAGE
                        ? () => actions.setupComposeOffer(lead!.id, offer.id)
                        : () => actions.composeOffer(lead!.id, offer.id)
                      }
                      {...getImpactAnalysisHandlers(offer.id, configuration.id)}
                      onDownload={() => actions.getDocument({
                        leadId: lead!.id,
                        offerId: offer.id,
                        documentType: FlatDocumentType.CONFIGURATION,
                      })}
                      isAnyOfferAccepted={isLeadFlatOfferAccepted(lead)}
                      isDsoBlackListed={!!lead && isDsoBlackListed(productAvailability, lead.dso)}
                      isOfferInvalid={isInvalidSonnenDirectOffer(offer, productAvailability)}
                      isStatusUpdateDisabled={isHwStatusUpdateDisabled(offer)}
                      onHardwareStatusModalOpen={(event: React.MouseEvent) => openHardwareStatusModal(event, offer)}
                      isOfferBlocked={isOfferBlocked(offer)}
                      offer={offer}
                    />
                  ) : (
                      <LeadOverviewConfigurationEmpty
                        isLoading={true}
                        title={configurationName}
                      />
                    )
                )}
            </div>
          ))}
          <Media query={{ minWidth: MediaQuery.UP_MD.minWidth }}>
            {(isDesktop: boolean) => isDesktop ? (
              isNewConfigurationPaneVisible(offers.length) && (
                <>
                  <div className={'o-grid__column o-grid__column--md-4'}>
                    {renderNewConfigurationPane(LeadProductType.FLAT_X)}
                    {renderConfigurationWarning()}

                    <Feature name={FeatureName.HARDWARE_ONLY_NEW_TILE}>
                      {offers.length !== 0 &&
                        <div className={'c-lead-overview-configurations__accordion-wrapper'}>
                          <AccordionButton
                            isAccordionOpen={showMoreProducts}
                            setAccordionOpen={setShowMoreProducts}
                            messageOpen={I18n.t(T.lead.overview._salessolution_.configurations.showLessProducts)}
                            messageClosed={I18n.t(T.lead.overview._salessolution_.configurations.showMoreProducts)}
                          />
                        </div>
                      }
                      {offers.length !== 0 && showMoreProducts ?
                        <div className={'c-lead-overview-configurations__new--middle'}>
                          {renderNewConfigurationPane(LeadProductType.BATTERY)}
                        </div>
                        : null
                      }
                    </Feature>
                  </div>
                  <Feature name={FeatureName.HARDWARE_ONLY_NEW_TILE}>
                    {offers.length === 0 &&
                      <div className={'o-grid__column o-grid__column--md-4'}>
                        {renderNewConfigurationPane(LeadProductType.BATTERY)}
                      </div>
                    }
                  </Feature>
                </>
              )) : null}
          </Media>
          <LeadOverviewVppCheckModal />

          {openModalId === ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW &&
            <LeadHardwareStatusModal
              modalId={ModalId.HARDWARE_STATUS_UPDATE_OVERVIEW}
              offerRef={offerToUpadateRef}
              submitAction={(status) => updateHardwareStatus(status)}
              queryStatus={hardwareStatusUpdateQueryStatus}
            />
          }
        </>
      </div>
    </>
  );
};

export const LeadOverviewConfigurations =
  connect(mapStateToProps, mapDispatchToProps)(LeadOverviewConfigurationsComponent);
