import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { isBatteryInstalled } from '../../battery/battery.helpers';
import { Battery } from '../../battery/types/battery.interface';
import { WarningFactory } from './warnings.types';

export const missingInstallationDateWarningFactory: WarningFactory<Battery> = battery =>
  !isBatteryInstalled(battery) ? ({
    message: I18n.t(T.warning.missingInstallationDate),
  }) : undefined;
