import * as classNames from 'classnames';
import * as React from 'react';
import { TextClassDictionary, tokenize } from './FormattedText.helper';

interface Props {
  className?: ClassValue;
  textClassNames: TextClassDictionary;
  children: string;
}

export class FormattedText extends React.PureComponent<Props> {
  render() {
    const { className, textClassNames, children } = this.props;
    let tokens;
    try {
      tokens = tokenize(children, textClassNames);
    } catch (error) {
      tokens = children.replace(/<.*?>/g, '');
      console.error(error);
    }
    return (
      <span className={classNames(className)}>
        {tokens}
      </span>
    );
  }
}
