import * as classNames from 'classnames';
import * as React from 'react';
import { RoundButton } from '../RoundButton';

import './Widget.component.scss';

interface Props {
  icon?: React.ReactNode;
  heading?: string;
  action?: () => void;
  customAction?: React.ReactNode;
  image?: string;
  className?: ClassValue;
  notification?: React.ReactNode;
}

export const Widget: React.SFC<Props> = ({
  icon, heading, action, customAction, image, className, children, notification,
}) => {
  const widgetClasses = classNames(
    'c-widget',
    { 'c-widget--clickable': !!action },
    className,
  );

  return (
    <div
      className={widgetClasses}
      onClick={action}
    >
      <div className={'c-widget__header'}>
        <div className={'c-widget__header-inner'}>
          <div className={'c-widget__icon'}>{icon}</div>
          <div className={'c-widget__heading'}>{heading}</div>
        </div>
        <div className={'c-widget__header-item'}>
          {action && (
            <RoundButton label={<span className={'icofont-angle'} />} />
          )}
          {customAction && customAction}
        </div>
      </div>
      <div className={'c-widget__body-wrapper'}>
        {image && <img src={image} alt={'Card image'} className={'c-widget__img'} />}
        <div className="c-widget__body">
          {children}
        </div>
        {notification && (
          <div className={'c-widget__notification'}>
            {notification}
          </div>
        )}
      </div>
    </div>
  );
};
