import { T } from '@sonnen/shared-i18n/service';
import { Card, Icon, LinkButton, WizardContext } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { FormSubmitButton } from '../FormSubmitButton';
import './FormWizardStep.component.scss';

interface Props {
  isPending?: boolean;
  isSubmitButtonDisabled?: boolean;
  showPreviousNavButton?: boolean;
  showCancelButton?: boolean;
  submitButtonLabel?: string;
  previousButtonLabel?: string;
  cancelButtonLabel?: string;
  onBeforeSubmit?: (...args: any[]) => void;
  cancelButtonHandler?: (...args: any[]) => void;
}

export const FormWizardStep: React.FC<Props> = ({
  children,
  isPending,
  isSubmitButtonDisabled = false,
  showPreviousNavButton = true,
  showCancelButton,
  submitButtonLabel,
  previousButtonLabel,
  cancelButtonLabel,
  onBeforeSubmit,
  cancelButtonHandler,
}) => {
  const wizard = React.useContext(WizardContext);
  const isFirstStep = wizard.progress === 1;

  return (
    <div className={'c-form-wizard-step'}>
      <Card
        footerContent={
          <div className={'c-form-wizard-step__navigation'}>
            {wizard.prev && !isFirstStep && showPreviousNavButton && (
              <div className={'c-form-wizard-step__navigation-prev'}>
                <LinkButton
                  onClick={() => wizard.prev!()}
                  icon={<Icon.Angle />}
                >
                  {previousButtonLabel || I18n.t(T.setupTool.step.back)}
                </LinkButton>
              </div>
            )}
            {showCancelButton && <div className={'c-form-wizard-step__navigation-cancel'}>
              <LinkButton
                onClick={cancelButtonHandler}
              >
                {cancelButtonLabel || I18n.t(T.general.basicActions.cancel)}
              </LinkButton>
            </div>}
            <div className={'c-form-wizard-step__navigation-next'}>
              <FormSubmitButton
                onClick={onBeforeSubmit}
                label={submitButtonLabel ? submitButtonLabel : I18n.t(T.setupTool.step.continue)}
                isSubmitting={isPending}
                isDisabled={isSubmitButtonDisabled}
              />
            </div>
          </div>
        }
      >
        <div className={'c-form-wizard-step__content'}>{children}</div>
      </Card>
    </div>
  );
};
