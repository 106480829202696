import { DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './SonnenDriveProcessSlide.component.scss';

interface Props {
  icon: React.ReactNode;
  iconName: string;
  text: string;
  arrow?: boolean;
}

export const SonnenDriveProcessSlide: React.FC<Props> = ({
  icon,
  iconName,
  text,
  arrow,
}) => (
  <div className={'c-sonnen-drive-process-slide'}>
    <div className={'c-sonnen-drive-process-slide__inner'}>
      <div className={`c-sonnen-drive-process-slide__icon c-sonnen-drive-process-slide__icon--${iconName}`}>
        {icon}
      </div>
      <DefaultParagraph className={'c-sonnen-drive-process-slide__text'}>
        {text}
      </DefaultParagraph>
    </div>
    {arrow && <Icon.Arrow className={'c-sonnen-drive-process-slide__arrow'} />}
  </div>
);
