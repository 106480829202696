import { Config } from '+config';
import {
  BodyCasing,
  bodyParser,
  bodySerializer,
  ContentType,
  HttpClient,
  HttpClientConfig,
  HttpResponse,
  Interceptors,
} from '@coolio/http';
import { fetchRequestHandler } from '@coolio/http/request-handlers/fetch';
import { JsonApiClient } from '@coolio/json-api';
import { authInterceptor, impersonateInterceptor } from './authInterceptor';

export const createHttpClient = <T extends HttpResponse>(options: Partial<HttpClientConfig<T>> = {}) =>
  new HttpClient({
    requestHandler: fetchRequestHandler({
      defaultRequestOptions: {
        mode: 'cors',
      },
    }),
    bodyParser: bodyParser({
      bodyCasing: BodyCasing.CAMEL_CASE,
    }),
    bodySerializer: bodySerializer({
      bodyCasing: BodyCasing.SNAKE_CASE,
    }),
    headers: {
      'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
      'Content-Type': ContentType.JSON,
    },
    baseUrl: Config.API_URL,
    ...options,
  })
    .addInterceptor(Interceptors.createErrorInterceptor());

export const forecastHttpClient = new HttpClient({
    requestHandler: fetchRequestHandler(),
    bodyParser: bodyParser({
      bodyCasing: BodyCasing.CAMEL_CASE,
    }),
    bodySerializer: bodySerializer({
      bodyCasing: BodyCasing.SNAKE_CASE,
    }),
    headers: {
      'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
      'Content-Type': ContentType.JSON,
    },
  })
    .addInterceptor(Interceptors.createErrorInterceptor());

export const createPartnerPortalHttpClient = <T extends HttpResponse>(options: Partial<HttpClientConfig<T>> = {}) =>
  new HttpClient({
    requestHandler: fetchRequestHandler(),
    bodyParser: bodyParser({
      bodyCasing: BodyCasing.CAMEL_CASE,
    }),
    headers: {
      'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
      'Content-Type': ContentType.JSON,
    },
    baseUrl: Config.PARTNER_PORTAL_API_URL,
    ...options,
  })
  .addInterceptor(Interceptors.createErrorInterceptor());

/**
 * Use this as a base for creating clients for sonnen APIs.
 * Authorization and error handling is provided by default.
 * You can specify "baseUrl" in options in order to configure a different URL beginning than sonnenAPI.
 */
export const createAuthorizedNetworkClients = <T extends HttpResponse>(options: Partial<HttpClientConfig<T>> = {}) => {
  const httpClient = createHttpClient(options)
    .addInterceptor(authInterceptor)
    .addInterceptor(impersonateInterceptor);

  return {
    httpClient,
    jsonApiClient: new JsonApiClient(httpClient),
  };
};

export const createAuthorizedPartnerPortalNetworkClients = 
  <T extends HttpResponse>(options: Partial<HttpClientConfig<T>> = {}) => {
  const partnerPortalHttpClient = createPartnerPortalHttpClient(options)
    .addInterceptor(authInterceptor)
    .addInterceptor(impersonateInterceptor);

  return {
    partnerPortalHttpClient,
    partnerPortalJsonApiClient: new JsonApiClient(partnerPortalHttpClient),
  };
};

/**
 * Default sonnenAPI clients.
 */
export const { httpClient, jsonApiClient } = createAuthorizedNetworkClients();

/**
 * Dedicated Partner Portal Api clients.
 */
export const { partnerPortalHttpClient, partnerPortalJsonApiClient } = createAuthorizedPartnerPortalNetworkClients();

/**
 * HttpClient used for authorization purposes, as it does not have Auth & Impersonation built-in using interceptors..
 */
export const authlessHttpClient = createHttpClient();
