import { getInlineAddress } from '+shared/store/lead/lead.helpers';
import { Lead } from '+shared/store/lead/types';
import * as React from 'react';
import Media from 'react-media';

import './LeadListLocation.component.scss';

interface Props {
  item: Lead;
}

export const LeadListLocation: React.FC<Props> = ({ item }) => (
  <div className={`c-lead-list-location`}>
    <Media query={{ minWidth: 576 }}>
      {(isMedium: boolean) => isMedium && item.deliveryAddress ? (
        <>
          <p className={'c-lead-list-location__paragraph'} data-hj-suppress={true}>
            {item.deliveryAddress.street}
          </p>
          <p className={'c-lead-list-location__paragraph'} data-hj-suppress={true}>
            {item.deliveryAddress.zipCode} {item.deliveryAddress.city}
          </p>
        </>
      ) : (
          <p className={'c-lead-list-location__paragraph'} data-hj-suppress={true}>
            {item.deliveryAddress && getInlineAddress(item.deliveryAddress)}
          </p>
        )}
    </Media>
  </div>
);
