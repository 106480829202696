import * as React from 'react';
import { getUserEmail, getUserName } from '../../store/user/user.helpers';
import { User } from '../../store/user/user.state';

import './NavUserTrigger.component.scss';

interface Props {
  user: User;
  isMobile: boolean;
}

export const NavUserTrigger: React.SFC<Props> = ({ user, isMobile }) => (
  <div className={'c-nav-user-trigger'}>
    <div className={'c-nav-user-trigger__content'}>
      <div className={'c-nav-user-trigger__content-item'} data-hj-suppress={true}>
        {getUserName(user)}
      </div>
      <div
        className={'c-nav-user-trigger__content-item c-nav-user-trigger__content-item--faded'}
        data-hj-suppress={true}
      >
        {isMobile && getUserEmail(user)}
      </div>
    </div>
    <div className={'c-nav-user-trigger__arrow'} />
    {!isMobile && <span className={'c-nav-user-trigger__icon icon-user'} />}
  </div>
);
