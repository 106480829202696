import { DocumentsUploadForm } from './types/DocUploadForm.interface';

export const getInitialValues = (documents: DocumentsUploadForm) => ({ 
  termsAndConditions: documents.termsAndConditions 
    ? documents.termsAndConditions.substring(documents.termsAndConditions.lastIndexOf('/') + 1) 
    : null,
  cancellationPolicy: documents.cancellationPolicy 
    ? documents.cancellationPolicy.substring(documents.cancellationPolicy.lastIndexOf('/') + 1) 
    : null,
  dataPrivacyPolicy: documents.dataPrivacyPolicy 
    ? documents.dataPrivacyPolicy.substring(documents.dataPrivacyPolicy.lastIndexOf('/') + 1) 
    : null,
});
