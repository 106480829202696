import { DashboardGreeting } from '+app/+dashboard/components/DashboardGreeting';
import {
  NewsContainer,
} from '+app/+newsMain/containers/NewsContainer/NewsContainer.component';
import { sortList } from '+app/+newsMain/store/newsMain.helpers';
import { PATHS } from '+app/router';
import { NewsPlacementType } from '+app/shared/store/newsMain/types/newsMain.interface';
import { Container } from '+shared/components';
import { withFirebase } from '+shared/components/FirebaseContext/Firebase.context';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { FirebaseProps } from '+shared/store/firebase/firebase.client';
import { fetchReadNews } from '+shared/store/firebase/firebase.helpers';
import { getFirebaseNewsList, getFirebaseReadNewsListFetched } from '+shared/store/firebase/firebase.selectors';
import { NewsMainActions } from '+shared/store/newsMain/newsMain.actions';
import { getActiveNewsId } from '+shared/store/newsMain/newsMain.selectors';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, MediaQuery } from '@sonnen/shared-web/index';
import { push } from 'connected-react-router';
import { isEmpty } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './Dashboard.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userProfile: getUserProfile(state),
  newsList: getFirebaseNewsList(state),
  isFirebaseReadNewsListFetched: getFirebaseReadNewsListFetched(state),
  activeNewsId: getActiveNewsId(state),
});

const mapDispatchToProps = mapActions({
  setReadNewsList: FirebaseActions.setReadNewsList,
  goToNewsInbox: () => push(PATHS.NEWS_INBOX()),
  markNewsAsRead: FirebaseActions.markNewsAsRead,
  setActiveNewsId: NewsMainActions.setActiveNewsId,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & FirebaseProps;

const DashboardComponent: React.FC<Props> = ({
  actions,
  userProfile,
  newsList,
  firebase,
  isFirebaseReadNewsListFetched,
  activeNewsId,
}) => {
  const sortedNewsList = sortList(newsList);
  React.useEffect(() => {
    if (userProfile) {
      fetchReadNews(userProfile.id, firebase, actions.setReadNewsList);
    }
  }, [userProfile]);

  React.useEffect(() => {
    actions.setActiveNewsId(isEmpty(sortedNewsList) ? '' : sortedNewsList[0].id);
  }, [sortedNewsList]);

  return userProfile && isFirebaseReadNewsListFetched ? (
    <div className={'dashboard'}>
      <Container className={'dashboard__container'}>
        <DashboardGreeting
          userName={userProfile.firstName + ' ' + userProfile.lastName}
          city={userProfile.city}
          countryCode={userProfile.countryCode}
        />

        <div className={'news-container'}>
          <NewsContainer
            maxNumNews={3}
            newsPlacement={NewsPlacementType.DASHBOARD}
          />

          <Media query={MediaQuery.UP_LG}>
            {(isDesktop: boolean) => (
              <Button
                className={'news-item__read-all-news'}
                label={I18n.t(T.news.channel.readAllNews)}
                theme={ButtonTheme.OUTLINE}
                size={ButtonSize.NORMAL}
                onClick={() => {
                  if (isDesktop) {
                    actions.markNewsAsRead(activeNewsId, userProfile.id);
                  }

                  actions.goToNewsInbox();
                }}
                isDisabled={newsList.length === 0}
              />
            )}
          </Media>

        </div>
      </Container>
    </div>
  ) : null;
};

export const Dashboard =
  connect(mapStateToProps, mapDispatchToProps)
    (withFirebase(DashboardComponent));
