import { CHARGER_ACTIONS, ChargerActions } from '+shared/store/charger';
import { queryReducer } from '+shared/store/query';
import { SITE_ACTIONS, SiteActions } from '+shared/store/site';
import { isEmpty } from 'lodash/fp';
import { createPersistedReducer } from '../../../utils/redux/createPersistedReducer.util';
import { GET_CHARGERS_QUERY, initialState, OverviewPageState } from './+overview.state';

export const reducer = createPersistedReducer<OverviewPageState, SiteActions | ChargerActions>(
  (state = initialState, action): OverviewPageState => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITE_CHARGER_LIST:
        const { siteChargers } = action;
        return {
          ...state,
          charger: !isEmpty(siteChargers) ? siteChargers[0] : undefined,
        };
      case CHARGER_ACTIONS.SET_CHARGER_LIVE_STATE:
        const { charger } = state;
        return {
          ...state,
          charger: charger ? { ...charger, liveState: action.chargerLiveState } : undefined,
        };
      default:
        return queryReducer(state, action, [GET_CHARGERS_QUERY]);
    }
  },
)({
  key: 'customer-page-overview',
  blacklist: [
    GET_CHARGERS_QUERY,
  ],
}); 
