import { NumberField } from '+app/+lead/+configuration/store/types';
import { queryReducer } from '+shared/store/query';
import { fromMinorToMajor } from '+utils/currency.util';
import { createReducer } from '+utils/redux/createReducer.util';
import { IMPACT_ANALYSIS_ACTIONS, ImpactAnalysisActions } from './+impactAnalysis.actions';
import {
  CREATE_IMPACT_ANALYSIS_QUERY,
  CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY,
  DELETE_IMPACT_ANALYSIS_QUERY,
  GET_IMPACT_ANALYSIS_LIST_QUERY,
  ImpactAnalysisState,
  initialState,
  SEND_IMPACT_ANALYSIS_QUERY,
} from './+impactAnalysis.state';

export const reducer = createReducer<ImpactAnalysisState, ImpactAnalysisActions>((state = initialState, action) => {
  switch (action.type) {
    case IMPACT_ANALYSIS_ACTIONS.SET_CONSUMPTION_FORM:
      return {
        ...state,
        consumptionForm: action.consumptionForm
          ? {
            ...action.consumptionForm,
            baselineTariffPerKwh: action.consumptionForm.baselineTariffPerKwh
              // converting cents from the layout to eur
              ? fromMinorToMajor(action.consumptionForm.baselineTariffPerKwh)
              : '' as NumberField,
          }
          : undefined,
      };
    case IMPACT_ANALYSIS_ACTIONS.SET_COSTS_FORM:
      return {
        ...state,
        costsForm: action.costsForm,
      };
    case IMPACT_ANALYSIS_ACTIONS.SET_PRODUCT_SETUP_FORM:
      return {
        ...state,
        productSetupForm: action.productSetupForm,
      };
    case IMPACT_ANALYSIS_ACTIONS.SET_PDF_CREATION:
      return {
        ...state,
        pdfCreationForm: action.pdfCreationForm,
      };
    case IMPACT_ANALYSIS_ACTIONS.CLEAR_DATA:
      return {
        ...initialState,
      };
    case IMPACT_ANALYSIS_ACTIONS.SET_IMPACT_ANALYSIS:
      return {
        ...initialState,
        impactAnalysisList: state.impactAnalysisList.map(impactAnalysis =>
          impactAnalysis.id === action.impactAnalysis.id ? action.impactAnalysis : impactAnalysis,
        ),
      };
    case IMPACT_ANALYSIS_ACTIONS.GET_IMPACT_ANALYSIS_LIST_SUCCESS:
      return {
        ...state,
        impactAnalysisList: action.impactAnalysisList,
      };
    case IMPACT_ANALYSIS_ACTIONS.DELETE_IMPACT_ANALYSIS_SUCCESS:
      return {
        ...state,
        impactAnalysisList: state.impactAnalysisList.filter(
          impactAnalysis => impactAnalysis.id !== action.deletedImpactAnalysisId,
        ),
      };
    case IMPACT_ANALYSIS_ACTIONS.CREATE_IMPACT_ANALYSIS_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        impactAnalysisRecommendation: action.impactAnalysisRecommendation,
      };
    case IMPACT_ANALYSIS_ACTIONS.CLEAR_IMPACT_ANALYSIS_RECOMMENDATION:
      return {
        ...state,
        impactAnalysisRecommendation: undefined,
      };
    default:
      return queryReducer(state, action, [
        CREATE_IMPACT_ANALYSIS_QUERY,
        CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY,
        SEND_IMPACT_ANALYSIS_QUERY,
        DELETE_IMPACT_ANALYSIS_QUERY,
        GET_IMPACT_ANALYSIS_LIST_QUERY,
      ]);
  }
});
