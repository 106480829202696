import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

export const factorizeConfigurationHint = (hintValue?: string | number) => (
  {
    pv: {
      isPending: I18n.t(T.lead.configuration._salessolution_.pv.hintPending),
      hasValue: I18n.t(T.lead.configuration._salessolution_.pv.hint),
    },
    battery: {
      isPending: I18n.t(T.lead.configuration._salessolution_.battery.hintPending),
      hasValue: I18n.t(T.lead.configuration._salessolution_.battery.hint),
    },
  }
);
