import { StoreState } from '+shared/store/store.interface';
import { createSelector } from 'reselect';
import { getStatus } from '../query/query.utils';
import { GET_CUSTOMER_LIST_QUERY } from './customer.state';

export const getCustomerState = (store: StoreState) => store.shared.customer;

export const getCustomerList = createSelector(
  getCustomerState,
  customer => customer.list,
);

const getCustomerListQuery = createSelector(
  getCustomerState,
  customer => customer[GET_CUSTOMER_LIST_QUERY],
);

export const getCustomerListQueryStatus = createSelector(
  getCustomerListQuery,
  query => getStatus(query),
);
