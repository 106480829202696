import { DsoRegistrationRadioType, FileCategoryName } from '+setupTool/+form/store';
import { VppDocumentationInterface } from '+setupTool/+vppDocumentation/store/types';
import { SetupToolStepsUpdatedAt } from '+setupTool/store/types';
import {
  AdditionalFeatures,
  DsoRegistrationFormResponse,
} from '+shared/store/setupTool/types/dsoRegistrationResponse.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { VppRegisterStatus } from './+vppDocumentation.dictionary';

export const getVppDocumentationProps = (data: any): VppDocumentationInterface => {
  const fieldValuesFromVppStep = data.vpp
    ? { ...data.vpp }
    : {};
  return {
    pv_commissioning_date: fieldValuesFromVppStep.pvCommissioningDate || data.fields.pvCommissioningDate,
    battery_commissioning_date: data.fields.batteryCommissioningDate,
    production_meter: fieldValuesFromVppStep.productionMeter || data.fields.productionMeter,
    number_production_meter: fieldValuesFromVppStep.numberProductionMeter || data.fields.numberProductionMeter,
    fastening_type_grid: fieldValuesFromVppStep.fasteningTypeGrid || data.fields.fasteningTypeGrid,
    fastening_type_consumption: fieldValuesFromVppStep.fasteningTypeConsumption
      || data.fields.fasteningTypeConsumption,
    meter_number: fieldValuesFromVppStep.meterNumber || data.fields.meterNumber,
    type_of_grid_reference_meter: fieldValuesFromVppStep.typeOfGridReferenceMeter
      || data.fields.typeOfGridReferenceMeter,
    dso_reference_number_pv: fieldValuesFromVppStep.dsoReferenceNumberPv || data.fields.dsoReferenceNumberPv,
    [FileCategoryName.METER_PICTURES]: data.fields.SUBMISSIONVPPMETERPICTURE,
    [FileCategoryName.WIRING_DIAGRAM_VPP]: data.fields.SUBMISSIONWIRINGDIAGRAM,
    pv_reduction: data.fields.pvReduction,
    meter_cabinet_prepared: data.fields.meterCabinetPrepared,
    virtual_pv_system: fieldValuesFromVppStep.virtualPvSystem || data.fields.virtualPvSystem,
    virtual_pv_system_subsystem_1_name: fieldValuesFromVppStep.virtualPvSystemSubsystem1Name
      || data.fields.virtualPvSystemSubsystem1Name,
    virtual_pv_system_subsystem_2_name: fieldValuesFromVppStep.virtualPvSystemSubsystem2Name
      || data.fields.virtualPvSystemSubsystem2Name,
    virtual_pv_system_subsystem_1_commissioning_date: fieldValuesFromVppStep.virtualPvSystemSubsystem1CommissioningDate
      || data.fields.virtualPvSystemSubsystem1CommissioningDate,
    virtual_pv_system_subsystem_2_commissioning_date: fieldValuesFromVppStep.virtualPvSystemSubsystem2CommissioningDate
      || data.fields.virtualPvSystemSubsystem2CommissioningDate,
  };
};

export const getVppSubmissionStatusFromStepsUpdatedAt = (data: SetupToolStepsUpdatedAt) => {
  return data.vppPerformance
    ? VppRegisterStatus.FINISH
    : data.vppDocumentation
      ? VppRegisterStatus.IN_PROGRESS
      : undefined;
};

export const getAdditionalFeatureVisibilityProp = (
  additionalFeatureName: AdditionalFeatures,
  data?: DsoRegistrationFormResponse,
): boolean =>
  !!data?.additionalFeatures?.includes(additionalFeatureName);

export const getProductionMeterCollection = () => [
  {
    value: DsoRegistrationRadioType.YES,
    label: I18n.t(T.setupTool.common.yes),
  },
  {
    value: DsoRegistrationRadioType.NO,
    label: I18n.t(T.setupTool.common.no),
  },
];
