import { DetailListType } from '+shared/components';
import { HighlightChangeElement } from '+shared/containers/HighlightChangeElement';
import { Battery, BatteryType } from '+shared/store/battery';
import { isBatteryInStandby } from '+shared/store/battery/battery.helpers';
import { LeadCountry } from '+shared/store/lead/types';
import { SiteLiveState } from '+shared/store/site/types';
import { formatPercentage } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, Link } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { BatteryLiveCardSoc } from '../BatteryLiveCardSoc';

const operatingModeMap = {
  'Self-Consumption': T.customerSingle.batteryDetails.batteryLive.operatingMode.selfConsumption,
  'Time of Use': T.customerSingle.batteryDetails.batteryLive.operatingMode.timeOfUse,
  'Energy Reserve': T.customerSingle.batteryDetails.batteryLive.operatingMode.energyReserve,
  'Cell balancing': T.customerSingle.batteryDetails.batteryLive.operatingMode.cellBalancing,
};

const showOnlineStatusWithDetails = (siteLiveState: SiteLiveState) => {
  const { batteryCharging, batteryDischarging, gridFeedin, gridPurchase } = siteLiveState;

  const batteryOnlineStatus = I18n.t(T.customerSingle.overview.battery.statusOnline);

  const batteryChargingStatus = () => {
    if (batteryCharging === 0 && batteryDischarging === 0) {
      return '';
    } else {
      return batteryCharging > batteryDischarging
        ? ` & ${I18n.t(T.customerSingle.overview.battery.statusCharging)}`
        : ` & ${I18n.t(T.customerSingle.overview.battery.statusDischarging)}`;
    }
  };

  const batteryFeedinOrPurchaseStatus = () => {
    if (gridFeedin === 0 && gridPurchase === 0) {
      return '';
    } else {
      return gridFeedin > gridPurchase
        ? ` & ${I18n.t(T.customerSingle.overview.battery.statusFeedingIn)}`
        : ` & ${I18n.t(T.customerSingle.overview.battery.statusPurchasing)}`;
    }
  };

  const batteryStatusWithDetails =
    `${batteryOnlineStatus}${batteryChargingStatus()}${batteryFeedinOrPurchaseStatus()}`;

  return batteryStatusWithDetails;
};

const getBatteryState = (siteLiveState: SiteLiveState) =>
  !!siteLiveState.online
  ? isBatteryInStandby(siteLiveState)
    ? I18n.t(T.customerSingle.overview.battery.statusStandby)
    : showOnlineStatusWithDetails(siteLiveState)
  : I18n.t(T.customerSingle.overview.battery.statusOffline);

const isReadyForReleaseNote = ({ installationCountryCode, controllerType }: Battery) =>
  installationCountryCode === LeadCountry.DE && controllerType === BatteryType.SPREE;

const getBatteryChargeCyclesComponent = ({ batteryChargeCycles }: Battery) =>
  (!isNil(batteryChargeCycles) && batteryChargeCycles !== 0)
    ? (
      <HighlightChangeElement value={batteryChargeCycles}>
        {batteryChargeCycles}
      </HighlightChangeElement>
    ) : null;

export const factorizeBatteryLiveDetailList = (
  battery: Battery,
  siteLiveState: SiteLiveState,
): DetailListType[] => [
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.stateOfCharge),
    value:
      <HighlightChangeElement value={siteLiveState.batteryUsoc}>
        <BatteryLiveCardSoc soc={siteLiveState.batteryUsoc} />
      </HighlightChangeElement>,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.state),
    value:
      <HighlightChangeElement value={getBatteryState(siteLiveState)}>
        {getBatteryState(siteLiveState)}
      </HighlightChangeElement>,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.operatingMode.headline),
    value:
      <HighlightChangeElement value={I18n.t(operatingModeMap[siteLiveState.batteryOperatingMode])}>
        {I18n.t(operatingModeMap[siteLiveState.batteryOperatingMode])}
      </HighlightChangeElement>,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.chargeCycles),
    value: getBatteryChargeCyclesComponent(battery),
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.backup),
    value:
      <HighlightChangeElement value={`${battery.backupPowerBuffer}`}>
        {!isNil(battery.backupPowerBuffer) ? formatPercentage(battery.backupPowerBuffer) : '-'}
      </HighlightChangeElement>,
  },
  {
    label: I18n.t(T.customerSingle.batteryDetails.batteryLive.softwareVersion),
    value:
      <HighlightChangeElement value={battery.softwareVersion}>
        {!isNil(battery.softwareVersion) ? battery.softwareVersion : '-'}
        {isReadyForReleaseNote(battery) && (
          <>
            <br />
            <Link
              label={I18n.t(T.batterySystem.details.checkReleaseNote.title)}
              href={I18n.t(T.batterySystem.details.checkReleaseNote.link)}
              isTargetBlank={true}
              icon={<Icon.Angle className={'c-battery-live-card__link-arrow'} />}
            />
          </>
        )}
      </HighlightChangeElement>,
  },
];
