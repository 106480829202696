import { formatToKwh } from '+shared/store/lead/lead.helpers';
import {
  AvailableEco6BatteryCapacities,
  AvailableEco7BatteryCapacities,
  AvailableEco8BatteryCapacities,
  BatteryModelName,
} from '+shared/store/lead/types';

export const eco8BatteryCapacityMap = (capacity: number) => ({
  [AvailableEco8BatteryCapacities.CAPACITY_4200]: formatToKwh(4000),
  [AvailableEco8BatteryCapacities.CAPACITY_5000]: formatToKwh(5000),
  [AvailableEco8BatteryCapacities.CAPACITY_6300]: formatToKwh(6000),
  [AvailableEco8BatteryCapacities.CAPACITY_7500]: formatToKwh(7500),
  [AvailableEco8BatteryCapacities.CAPACITY_8400]: formatToKwh(8000),
  [AvailableEco8BatteryCapacities.CAPACITY_10000]: `${formatToKwh(10000)} (sonnenModul)`,
  [AvailableEco8BatteryCapacities.CAPACITY_10500]: `${formatToKwh(10000)} (Sony Modul)`,
  [AvailableEco8BatteryCapacities.CAPACITY_12500]: formatToKwh(12500),
  [AvailableEco8BatteryCapacities.CAPACITY_12600]: formatToKwh(12000),
  [AvailableEco8BatteryCapacities.CAPACITY_14700]: formatToKwh(14000),
  [AvailableEco8BatteryCapacities.CAPACITY_15000]: formatToKwh(15000),
  [AvailableEco8BatteryCapacities.CAPACITY_16800]: formatToKwh(16000),
})[capacity];

export const eco7BatteryCapacityMap = (capacity: number) => ({
  [AvailableEco7BatteryCapacities.CAPACITY_2100]: formatToKwh(2000),
  [AvailableEco7BatteryCapacities.CAPACITY_4200]: formatToKwh(4000),
  [AvailableEco7BatteryCapacities.CAPACITY_6300]: formatToKwh(6000),
  [AvailableEco7BatteryCapacities.CAPACITY_8400]: formatToKwh(8000),
  [AvailableEco7BatteryCapacities.CAPACITY_10500]: formatToKwh(10000),
  [AvailableEco7BatteryCapacities.CAPACITY_12600]: formatToKwh(12000),
  [AvailableEco7BatteryCapacities.CAPACITY_14700]: formatToKwh(14000),
  [AvailableEco7BatteryCapacities.CAPACITY_16800]: formatToKwh(16000),
})[capacity];

export const eco6BatteryCapacityMap = (capacity: number) => ({
  [AvailableEco6BatteryCapacities.CAPACITY_2100]: formatToKwh(2000),
  [AvailableEco6BatteryCapacities.CAPACITY_4200]: formatToKwh(4000),
  [AvailableEco6BatteryCapacities.CAPACITY_6300]: formatToKwh(6000),
  [AvailableEco6BatteryCapacities.CAPACITY_8400]: formatToKwh(8000),
  [AvailableEco6BatteryCapacities.CAPACITY_10500]: formatToKwh(10000),
  [AvailableEco6BatteryCapacities.CAPACITY_12600]: formatToKwh(12000),
  [AvailableEco6BatteryCapacities.CAPACITY_14700]: formatToKwh(14000),
  [AvailableEco6BatteryCapacities.CAPACITY_16800]: formatToKwh(16000),
})[capacity];

export type EcoBatteryType =
  | BatteryModelName.SONNENBATTERIE_ECO_6
  | BatteryModelName.SONNENBATTERIE_ECO_7
  | BatteryModelName.SONNENBATTERIE_ECO_8
  ;

export const isEcoBattery = (modelName: string): modelName is EcoBatteryType => [
  BatteryModelName.SONNENBATTERIE_ECO_8,
  BatteryModelName.SONNENBATTERIE_ECO_7,
  BatteryModelName.SONNENBATTERIE_ECO_6,
].includes(modelName as BatteryModelName);
