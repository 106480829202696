import { Icofont } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { clamp, compose } from 'lodash/fp';
import * as React from 'react';
import { getPages, getPagesCount, getPagesToRender, isPaginationHead, isPaginationTail } from './Pagination.helper';

import './Pagination.component.scss';

interface Props {
  page: number | string | undefined;
  recordsPerPage: number;
  recordsTotal: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.SFC<Props> = ({
  recordsTotal, page, recordsPerPage, onPageChange,
}) => {
  const pages = compose(getPages(1), getPagesCount)({ recordsTotal, recordsPerPage });
  const minPage = 1;
  const maxPage = pages.length;
  const activePage = clamp(minPage, maxPage)(Number(page) || minPage);
  const pagesToRender = getPagesToRender(activePage)(pages);

  return recordsTotal ? (
    <div className={'c-pagination'}>
      <button
        className={'c-pagination__btn-prev'}
        disabled={minPage === activePage}
        onClick={() => onPageChange(activePage - 1)}
      >
        <Icofont type={'angle'} />
      </button>
      <span className={'c-pagination__page-list'}>
        {!isPaginationHead(activePage) && (
          <>
            <button
              key={minPage}
              className={'c-pagination__page'}
              onClick={() => onPageChange(minPage)}
            >
              {minPage}
            </button>
            <span className={'c-pagination__rest'}>{'...'}</span>
          </>
        )}
        {pagesToRender.map(page => (
          <button
            key={page}
            className={classNames('c-pagination__page', {
              'is-active' : activePage === page,
            })}
            onClick={() => activePage !== page && onPageChange(page)}
          >
            {page}
          </button>
        ))}
        {!isPaginationTail(activePage, maxPage) && (
          <>
            <span className={'c-pagination__rest'}>{'...'}</span>
            <button
              key={maxPage}
              className={'c-pagination__page'}
              onClick={() => onPageChange(maxPage)}
            >
              {maxPage}
            </button>
          </>
        )}
      </span>
      <button
        className={'c-pagination__btn-next'}
        disabled={maxPage === activePage}
        onClick={() => onPageChange(activePage + 1)}
      >
        <Icofont type={'angle'} />
      </button>
    </div>
  ) : null;
};
