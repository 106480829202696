import Battery from '+assets/img/battery.svg';
import CustomerWithContract from '+assets/img/customerWithContract.svg';
import GirlWithOffer from '+assets/img/girlWithOffer.svg';
import { FeatureName } from '+config/featureFlags';
import { StatusTile, StatusTileColors } from '+shared/components/StatusTile';
import { ActionName, EventCategory, InteractionEvents } from '+shared/GoogleTagManager';
import { useTracking } from '+shared/GoogleTagManager/GoogleTagManager.helper';
import { LeadProductType } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonTheme, useFeature } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadOverviewNewConfigurationPane.component.scss';

interface Props {
  productType: LeadProductType;
  onNewConfiguration: () => void;
  isDisabled?: boolean;
  isMobile?: boolean;
}

export const LeadOverviewNewConfigurationPane: React.FC<Props> = ({
  isDisabled,
  onNewConfiguration,
  productType,
  isMobile = false,
}) => {
  const isVppQuestionsPerLeadEnabled = useFeature(FeatureName.VPP_QUESTIONS_PER_LEAD).isEnabled;

  const offerImage = (product: LeadProductType): string => ({
    [LeadProductType.FLAT_X]: CustomerWithContract,
    [LeadProductType.FLAT_DIRECT]: GirlWithOffer,
    [LeadProductType.BATTERY]: Battery,
  }[product]
  );

  const { track } = useTracking();

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!isDisabled) {
      onNewConfiguration();
    }

    track(InteractionEvents.buttonClick({
      category: EventCategory.SALES_CONFIGURATOR,
      action: ActionName.START_NEW_CONFIGURATION,
      event,
    }));
  };

  const renderHeadline = (lines: string[]) => (
    lines.map(line => <>{line}<br /></>)
  );

  const renderInfoText = (text: string, boldText: string) => (
    <p className={'c-overview-new-configuration-pane__info'}>
      {text} <span className={'c-overview-new-configuration-pane__info--bold'}>{boldText}</span>
    </p>
  );

  const renderProductHeadline = (productType: LeadProductType) => {
    const productTitle = (productType: LeadProductType) => {
      switch (productType) {
        case LeadProductType.FLAT_X:
          return [
            I18n.t(T.lead.configuration._salessolution_.products.flat),
          ];
        case LeadProductType.FLAT_DIRECT:
          return [
            I18n.t(T.lead.configuration._salessolution_.products.flatDirectNHardware),
            I18n.t(T.lead.configuration._salessolution_.products.flatDirectOnly),
          ];
        case LeadProductType.BATTERY:
          return [
            I18n.t(T.lead.configuration._salessolution_.products.hardwareOnly),
          ];
        default:
          return [
            I18n.t(T.lead.configuration._salessolution_.products.flatNHardware),
            I18n.t(T.lead.configuration._salessolution_.products.flatOnly),
          ];
      }
    };

    return (
      <div className={'c-overview-new-configuration-pane__headline-wrapper'}>
        <p className={'c-overview-new-configuration-pane__headline'}>
          {renderHeadline(productTitle(productType))}
        </p>
      </div>
    );
  };

  // if there are separate tiles for flatX and flatDirect then this should be displayed for all product types
  const renderProductInfo = (productType: LeadProductType) => {
    if (productType === LeadProductType.FLAT_X) {
      return renderInfoText(
        I18n.t(T.lead.configuration._salessolution_.products.flatDescription),
        I18n.t(T.lead.configuration._salessolution_.products.flatDescriptionBold),
      );
    }

    if (productType === LeadProductType.BATTERY) {
      return renderInfoText(
        I18n.t(T.lead.configuration._salessolution_.products.hardwareOnlyDescription),
        I18n.t(T.lead.configuration._salessolution_.products.hardwareOnlyDescriptionBold),
      );
    }

    return renderInfoText(
      I18n.t(T.lead.configuration._salessolution_.products.flatDirectDescription),
      I18n.t(T.lead.configuration._salessolution_.products.flatDirectDescriptionBold),
    );
  };

  return (
    // it's a temporary solution - when HW only offers appear, we will not use this component anymore
    // instead, there will be two separate ones (for HW and flat), which will look completely different
    <button
      className={classNames('c-overview-new-configuration-pane', {
        'c-overview-new-configuration-pane--disabled': isDisabled,
      })}
      onClick={onButtonClick}
    >
      {isVppQuestionsPerLeadEnabled ?
        <div className={'c-overview-new-configuration-pane__tag'}>
          {/* TODO: there is translation ready for `unavailable`, show it based on product availability */}
          <StatusTile
            label={I18n.t(T.lead.configuration._salessolution_.tags.available)}
            color={StatusTileColors.GREEN}
          />
        </div>
        : null
      }
      <div>
        {!isMobile &&
          <img
            src={offerImage(productType)}
            className={'c-overview-new-configuration-pane__image'}
            alt={'Customer with contract'}
          />}

        {renderProductHeadline(productType)}
      </div>

      <>
        <div className={'c-overview-new-configuration-pane__info-wrapper'}>
          {renderProductInfo(productType)}
        </div>

        <Button
          theme={ButtonTheme.OUTLINE}
          label={productType === LeadProductType.BATTERY
            ? I18n.t(T.lead.configuration._salessolution_.addHardwareOnly)
            : I18n.t(T.lead.configuration._salessolution_.startNewConfiguration)
          }
          isNotElevatedOnHover={true}
          isDisabled={isDisabled}
        />
      </>
    </button>
  );
};
