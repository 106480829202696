import { getHardwareStatusUpdateQueryStatus } from '+app/+lead/+hardware/store/+hardware.selectors';
import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { getOfferList, getOfferListQueryStatus, getProductBatteryList } from '+app/+lead/+overview/store/+overview.selectors';
import { PATHS, ROUTES } from '+app/router';
import { HardwarePageActions } from '+lead/+hardware/store';
import { LeadPageActions } from '+lead/store/+lead.actions';
import { getChangeLeadStatusQueryStatus } from '+lead/store/+lead.selectors';
import { SelectButton } from '+shared/components';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { getUserCompanyName } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  Loader,
  MediaQuery,
  Modal,
  ModalLayout,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { Form, Formik, FormikProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';
import { LeadEditStatusHardware } from './LeadEditStatusHardware/LeadEditStatusHardware.component';
import {
  canMoveLeadToSetup,
  findProductsStatusesToUpdate,
  getLeadStatusListToUpdate,
  LeadEditStatusForm,
  leadEditStatusSchema,
  mapUserStatusToInitialValues,
} from './LeadEditStatusModal.helper';
import { LeadEditStatusSonnenFlat } from './LeadEditStatusSonnenFlat';

import './LeadEditStatusModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userCompanyName: getUserCompanyName(state),
  offers: getOfferList(state),
  productBatteryList: getProductBatteryList(state),
  changeLeadQueryStatus: getChangeLeadStatusQueryStatus(state),
  offerListQueryStatus: getOfferListQueryStatus(state),
  hardwareProductStatusUpdateQueryStatus: getHardwareStatusUpdateQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goToCreateHardwareOffer: (leadId: string) => push(PATHS.LEAD_HARDWARE_NEW({ leadId })),
  getOfferList: LeadOverviewPageActions.getOfferList,
  clearOffers: LeadOverviewPageActions.clearOffers,
  changeLeadStatus: LeadPageActions.changeLeadStatus,
  changeHardwareProductStatus: HardwarePageActions.updateHardwareStatus,
  displayMaxOffersWarning: LeadOverviewPageActions.displayMaxOffersWarning,
});

interface ComponentProps {
  isModalOpen: boolean;
  lead: Lead;
  toggleEditModal: (arg: boolean) => void;
  toggleModal: (arg: boolean) => void;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  & RouteComponentProps
  ;

enum LeadStatuesGroup {
  HARDWARE = 'hardware',
  SONNEN_FLAT = 'sonnen-flat',
}

export const checkboxTextGenerator = (label: string, owner: string, bold: boolean = false) => (
  <div>
    <p className={'c-lead-edit-status-modal__status-name'}>{label}</p>
    <p data-hj-suppress={true} className={classNames('c-lead-edit-status-modal__status-owner', {
      'c-lead-edit-status-modal__status-owner--bold': bold,
    })}>{owner}</p>
  </div>
);

const LeadEditStatusModalComponent: React.FC<Props> = ({
  actions,
  isModalOpen,
  lead,
  changeLeadQueryStatus,
  userCompanyName,
  offers,
  location,
  productBatteryList,
  offerListQueryStatus,
  hardwareProductStatusUpdateQueryStatus,
  toggleEditModal,
  toggleModal,
}) => {
  const { track } = useTracking();
  const [selectedStatusesGroup, setSelectedStatusesGroup] = React.useState<LeadStatuesGroup>(
    LeadStatuesGroup.HARDWARE,
  );

  React.useEffect(() => {
    return () => {
      closeLeadEditStatusModal();
    };
  }, []);

  React.useEffect(() => {
    if (isModalOpen && isEmpty(offers) && !offerListQueryStatus.success) {
      actions.getOfferList(lead.id);
    }
  }, [isModalOpen]);

  const closeLeadEditStatusModal = () => {
    toggleModal(false);
    toggleEditModal(false);

    // on the lead list offers have to be cleared each time the modal is closed
    // to fetch new ones while opening it for a different lead
    if (location.pathname === ROUTES.LEADS[0]) {
      actions.clearOffers();
    }
  };

  const initialValues = mapUserStatusToInitialValues(lead, offers);

  const hasErrors = hardwareProductStatusUpdateQueryStatus.error || changeLeadQueryStatus.error;
  const isPending = hardwareProductStatusUpdateQueryStatus.pending || hardwareProductStatusUpdateQueryStatus.pending;

  const isMoveToSetupEnabled = (statusSummary: Lead['status']['summary'], values: LeadEditStatusForm): boolean =>
    canMoveLeadToSetup({ statusSummary, values, offers, initialValues }) && !changeLeadQueryStatus.pending;

  const onSubmit = (values: LeadEditStatusForm) => {
    if (!lead) return;

    const productsStatusesToUpdate = findProductsStatusesToUpdate(offers)(initialValues, values);
    if (!isEmpty(productsStatusesToUpdate)) actions.changeHardwareProductStatus(lead.id, productsStatusesToUpdate);

    const leadStatusList = getLeadStatusListToUpdate({ initialValues, values, productsStatusesToUpdate });
    if (!isEmpty(leadStatusList)) actions.changeLeadStatus(lead.id, leadStatusList);
  };

  const moveToSetup = (event: React.SyntheticEvent<HTMLButtonElement>, form: FormikProps<LeadEditStatusForm>) => {
    track(InteractionEvents.buttonClick({
      category: EventCategory.MOVE_TO_SETUP,
      action: 'Move to setup',
      event,
    }));
    onSubmit({
      ...form.values,
      inSetup: true,
    });
  };

  const onSaveButtonClick = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    form: FormikProps<LeadEditStatusForm>,
  ) => {
    if (isMoveToSetupEnabled(lead.status.summary, form.values)) {
      const { inSetup, ...initialCheckboxes } = initialValues;
      const newStatuses = Object.keys(initialCheckboxes).filter((value) => initialCheckboxes[value] === false);

      track(InteractionEvents.buttonClick({
        category: EventCategory.STATUSES,
        action: 'Save statuses - move to setup active',
        event,
        labels: newStatuses,
      }));
    }
  };

  const renderStatusModalFooter = (form: FormikProps<LeadEditStatusForm>) => (
    <>
      {hasErrors && !isPending && (
        <div className={'c-lead-edit-status-modal__errors'}>
          <div>{I18n.t(T.lead.list._salessolution_.editStatusModal.errors.errorWhileSaving)}</div>
          <div>{I18n.t(T.lead.list._salessolution_.editStatusModal.errors.refreshPage)}</div>
        </div>
      )}
      <Media query={MediaQuery.UP_MD}>
        {(isMedium: boolean) => (
          <div className={'c-lead-edit-status-modal__buttons'}>
            <Button
              label={I18n.t(T.general.basicActions.save)}
              theme={ButtonTheme.OUTLINE}
              size={isMedium ? ButtonSize.PRIMARY : ButtonSize.PILL}
              type={'submit'}
              isDisabled={changeLeadQueryStatus.pending || !form.dirty}
              className={'c-lead-edit-status-modal__button-setup'}
              onClick={(event) => onSaveButtonClick(event, form)}
            />
            {!initialValues.inSetup && (
              <Button
                label={I18n.t(T.lead.list._salessolution_.editStatusModal.moveToSetupBtn)}
                size={isMedium ? ButtonSize.PRIMARY : ButtonSize.PILL}
                isDisabled={!isMoveToSetupEnabled(lead.status.summary, form.values)}
                onClick={(event) => moveToSetup(event, form)}
              />
            )}
          </div>
        )}
      </Media>
    </>
  );

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => {
        closeLeadEditStatusModal();

        track(InteractionEvents.iconClick({
          category: EventCategory.STATUSES,
          action: ActionName.CLOSE_STATUS_MANAGER,
        }));
      }}
      className={'c-lead-edit-status-modal__wrapper'}
    >
      {changeLeadQueryStatus.pending
        || hardwareProductStatusUpdateQueryStatus.pending
        || offerListQueryStatus.pending
        || (!offerListQueryStatus.success && !offerListQueryStatus.error) ?
        <Loader className={'c-lead-edit-status-modal__loader'} />
        :
        <div className={'c-lead-edit-status-modal'} data-hj-suppress={true}>
          <Formik
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={true}
            validationSchema={leadEditStatusSchema}
            onSubmit={onSubmit}
            render={form => (
              <Form className={'c-lead-edit-status-modal-form'}>
                <ModalLayout
                  title={`${lead.firstName && lead.firstName} ${lead.lastName && lead.lastName}`}
                  subtitle={I18n.t(T.lead.list._salessolution_.editStatusModal.description)}
                  footer={renderStatusModalFooter(form)}
                >
                  <div className={'c-lead-edit-status-modal__input'}>
                    <div className={'c-lead-edit-status-modal__widget-wrapper'}>
                      <div className={'c-lead-edit-status-modal__statuses-container'}>
                        <SelectButton
                          className={'c-lead-edit-status-modal__statuses-item'}
                          onClick={() => setSelectedStatusesGroup(LeadStatuesGroup.HARDWARE)}
                          isActive={selectedStatusesGroup === LeadStatuesGroup.HARDWARE}
                          icon={<Icon.SonnenBattery />}
                          label={I18n.t(T.lead.list._salessolution_.editStatusModal.switch.hardware)}
                        />
                        <SelectButton
                          className={'c-lead-edit-status-modal__statuses-item'}
                          onClick={() => setSelectedStatusesGroup(LeadStatuesGroup.SONNEN_FLAT)}
                          isActive={selectedStatusesGroup === LeadStatuesGroup.SONNEN_FLAT}
                          icon={<Icon.NotesWithPencil />}
                          label={I18n.t(T.lead.list._salessolution_.editStatusModal.switch.sonnenFlat)}
                        />
                      </div>
                    </div>

                    {selectedStatusesGroup === LeadStatuesGroup.HARDWARE && (
                      <LeadEditStatusHardware
                        form={form}
                        userCompanyName={userCompanyName}
                        isMoveToSetupEnabled={isMoveToSetupEnabled}
                        hasErrors={changeLeadQueryStatus.error}
                        leadStatusSummary={lead.status.summary}
                        offers={offers}
                        productBatteryList={productBatteryList}
                        goToCreateHardwareOffer={() => actions.goToCreateHardwareOffer(lead.id)}
                        displayMaxOffersWarning={() => actions.displayMaxOffersWarning(lead.id)}
                      />)}

                    {selectedStatusesGroup === LeadStatuesGroup.SONNEN_FLAT && (
                      <LeadEditStatusSonnenFlat
                        leadStatusSummary={lead.status.summary}
                        userCompanyName={userCompanyName}
                      />
                    )}
                  </div>
                </ModalLayout>
              </Form>
            )}
          />
        </div>
      }
    </Modal>
  );
};

export const LeadEditStatusModal =
  connect(mapStateToProps, mapDispatchToProps)(withRouter(LeadEditStatusModalComponent));
