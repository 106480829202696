import { getPVRSubmissionStatus } from '+setupTool/+pvRegister/store/+pvRegister.selectors';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { getRegistrationSubject, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { getDashboardSetupDataConfig } from '+setupTool/store/+setupToolSteps.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './SetupToolRegistrationSelect.component.scss';

const mapStateToProps = (state: StoreState) => ({
  submissionId: getSubmissionId(state),
  registrationSubject: getRegistrationSubject(state),
  pvrSubmissionStatus: getPVRSubmissionStatus(state),
  setupDataConfig: getDashboardSetupDataConfig(state),
});

const mapDispatchToProps = mapActions({
  setRegistrationSubjectType: SetupToolActions.setRegistrationSubjectType,
  saveRegistrationSubjectType: SetupToolActions.saveRegistrationSubjectType,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

export const SetupToolRegistrationSelectComponent: React.FC<Props> = ({
  actions,
  submissionId,
  registrationSubject,
  pvrSubmissionStatus,
  setupDataConfig,
}) => {
  const setupDataStartedOrCompleted = setupDataConfig && !!setupDataConfig.status;

  const saveSelection = (submissionId: string | undefined, registrationType: RegistrationSubjectType) => {
    if (submissionId && !pvrSubmissionStatus) {
      if (setupDataStartedOrCompleted) {
        actions.toggleModal(true, ModalId.REGISTRATION_SUBJECT);
        return;
      }
      actions.setRegistrationSubjectType(registrationType);
      actions.saveRegistrationSubjectType(submissionId, registrationType);
    }
  };

  return (
    <div className={'c-setup-tool-registration-select'}>
      <p className={'c-setup-tool-registration-header__text'}>
        {I18n.t(T.setupTool.registrationSubjectSelection.description)}
      </p>
      <div className={'c-setup-tool-registration-select__buttons'}>
        <button
          className={classNames(`c-setup-tool-registration-select__button`,
            {
              'c-setup-tool-registration-select__button--active':
                registrationSubject ===  RegistrationSubjectType.PV_AND_BATTERY,
            },
            {
              'c-setup-tool-registration-select__button--disabled': !!pvrSubmissionStatus,
            },
          )}
          {...(registrationSubject ===  RegistrationSubjectType.BATTERY && {
            onClick: () => { saveSelection(submissionId, RegistrationSubjectType.PV_AND_BATTERY); }}
          )}
        >
          <Icon.SonnenPvBattery width={73} height={21} viewBox={{x: 0, y: 0, w: 73, h: 21}}
            className={'c-setup-tool-registration-select__icon'}/>
          <span className={'c-setup-tool-registration-select__button-text'}>
            {I18n.t(T.setupTool.registrationSubjectSelection.button.pvPlusBattery)}</span>
        </button>

        <button
          className={classNames(`c-setup-tool-registration-select__button`,
            {
              'c-setup-tool-registration-select__button--active':
                registrationSubject === RegistrationSubjectType.BATTERY,
            },
            {
              'c-setup-tool-registration-select__button--disabled': !!pvrSubmissionStatus,
            },
          )}
          {...(registrationSubject ===  RegistrationSubjectType.PV_AND_BATTERY && {
              onClick: () => { saveSelection(submissionId, RegistrationSubjectType.BATTERY); }}
          )}
        >
          <Icon.SonnenBattery className={'c-setup-tool-registration-select__icon'}/>
          <span className={'c-setup-tool-registration-select__button-text'}>
            {I18n.t(T.setupTool.registrationSubjectSelection.button.onlyBattery)}</span>
        </button>
      </div>
    </div>
  );
};

export const SetupToolRegistrationSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SetupToolRegistrationSelectComponent);
