import { SelectItem } from '+shared/store/case';
import { CaseRecordType } from '+shared/store/case/types/createCase.interface';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

export interface SupportCaseForm {
  description: string;
  recordType: CaseRecordType;
  attachment: string | null;
}

const supportInitValues = {
  recordType: CaseRecordType.E_SERVICES,
  description: '',
  attachment: null,
};

const supportSchema = () => Yup.object({
  description: Yup.string()
    .required(I18n.t(T.companyAccount.requestAccess.alert.requiredField))
    .trim(I18n.t(T.companyAccount.requestAccess.alert.fieldCannotBeEmpty)),
});

const supportTitle = (type: string) =>
  `Partner Portal User support request: ${type}`;

const getCaseRecordTypesCollection = (): SelectItem[] => 
  [
    {name: I18n.t(T.help.supportForm.fields.topics.eServices), value: CaseRecordType.E_SERVICES},
    {name: I18n.t(T.help.supportForm.fields.topics.technology), value: 'Technical'},
    {name: I18n.t(T.help.supportForm.fields.topics.setup), value: 'Setup Configuration'},
  ];

export const SupportFormHelper = {
  getCaseRecordTypesCollection,
  supportInitValues,
  supportSchema,
  supportTitle,
};
