import { isBatteryAvailableForSale } from '+shared/store/lead/lead.helpers';
import { LeadProductBattery } from '+shared/store/lead/types/leadProductBattery.interface';
import { FormikProps } from 'formik';
import { uniq } from 'lodash';
import { ConfigurationForm } from '../../store/types/configuration.interface';

export const formFields = {
  NEW_BATTERY: 'newBattery' as 'newBattery',
  MODEL_NAME: 'modelName' as 'modelName',
  CAPACITY_GROSS: 'capacityGross' as 'capacityGross',
};

export const mapBatteryModelNameCollection =
  (productBatteryList: LeadProductBattery[], form: FormikProps<ConfigurationForm>) =>
    uniq(productBatteryList
      .filter(capacityLabel => {
        // we return all battery models for 'existing battery'
        if (!form.values.newBattery) {
          return true;
        }

        return isBatteryAvailableForSale(capacityLabel);
      })
      .map(productBattery => productBattery.modelName));
