import { T } from '@sonnen/shared-i18n/service';
import { 
  AnalysisDatePicker,
  AnalysisToolbar,
  DateShift,
  DateSwitcher,
  getMinimalMeasurementDate,
  getResolutionFromPeriod,
  Icon,
  shiftDate,
  TimeHelper,
  TimeUnit,
} from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getBatteryInstallationDate } from '+app/+customer/+battery/store/+battery.selectors';
import { provideLocale } from '+app/i18n/i18n.helpers';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { AnalysisActions, getStatisticsSelectedDate } from '../../store';
import { DATE_FORMATS, formatDate } from './CustomerAnalysisStatisticsToolbar.helper';

import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import './CustomerAnalysisStatisticsToolbar.component.scss';

const mapStateToProps = (state: StoreState) => ({
  statisticsSelectedDate: getStatisticsSelectedDate(state),
  batteryInstallationDate: getBatteryInstallationDate(state),
});

const mapDispatchToProps = mapActions({
  setStatisticsSelectedDate: AnalysisActions.setStatisticsDate,
});

interface State {
  isDatePickerOpen: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const CustomerAnalysisStatisticsToolbarComponent: React.FC<Props> = ({
  statisticsSelectedDate,
  batteryInstallationDate,
  actions,
}) => {
  const [isDatePickerOpen, toggleDatePicker] = React.useState(false);
  const { track } = useTracking();

  const changeDate = (selectedDatePeriod: TimeUnit) => (selectedDate: Date ) => {
    track(InteractionEvents.buttonClick({
      category: EventCategory.CUSTOMER_DETAILS,
      action: `${ActionName.SWITCH_DATE_PERIOD} ${selectedDatePeriod}`,
    }));
    
    actions.setStatisticsSelectedDate({
      period: selectedDatePeriod,
      date: selectedDate,
      resolution: getResolutionFromPeriod(selectedDatePeriod),
    });
    toggleDatePicker(false);
  };

  const shiftSelectedDate = (shift: DateShift) => {
    const { period, date } = statisticsSelectedDate;
    changeDate(statisticsSelectedDate.period)(shiftDate(date, period, shift));
  };

  const { period, date } = statisticsSelectedDate;
  const minimalDate = getMinimalMeasurementDate(batteryInstallationDate);

  return (
    <div className={'c-customer-analysis-statistics-toolbar'}>
      <AnalysisToolbar
        // @TODO: Change to proper translation
        dateSwitcherTitle={I18n.t(T.customerSingle.analysis.statistics.datePickerLabel)}
        datePicker={(
          <AnalysisDatePicker
            isActive={isDatePickerOpen}
            date={date}
            minDate={minimalDate}
            onDayClick={changeDate(TimeUnit.DAY)}
            onMonthClick={changeDate(TimeUnit.MONTH)}
            onYearClick={changeDate(TimeUnit.YEAR)}
            activeDatePicker={period}
            tabsTriggers={[
              { label: I18n.t(T.customerSingle.analysis.dateLabels.day) },
              { label: I18n.t(T.customerSingle.analysis.dateLabels.month) },
              { label: I18n.t(T.customerSingle.analysis.dateLabels.year) },
            ]}
            locale={provideLocale()}
          />
        )}
        dateSwitcher={(
          <DateSwitcher
            icon={
              <Icon.Calendar 
                className={'c-customer-analysis-statistics-toolbar__date-switcher-icon'}
              />}
            date={date}
            dateFormatter={() => formatDate(date, DATE_FORMATS[period])}
            canGoPrev={moment(date).isAfter(minimalDate)}
            canGoNext={!TimeHelper.isCurrent(period)(date)}
            onPrevSelected={() => shiftSelectedDate(DateShift.BACK)}
            onNextSelected={() => shiftSelectedDate(DateShift.FORWARD)}
            onDateSelected={() => toggleDatePicker(!isDatePickerOpen)}
          />
        )}
        onDatePickerOutsideClick={() => toggleDatePicker(false)}
      />
    </div>
  );
};

export const CustomerAnalysisStatisticsToolbar = 
  connect(mapStateToProps, mapDispatchToProps)(CustomerAnalysisStatisticsToolbarComponent);
