import * as classNames from 'classnames';
import * as React from 'react';
import { ReactText } from 'react';

export type TextClassDictionary = Record<string, ClassValue>;

export const tokenize = (source: ReactText | undefined, styles: TextClassDictionary, key = 't'): React.ReactNode[] => {
  if (source === undefined) {
    return [];
  }
  const tokens: React.ReactNode[] = [];
  let text = source.toString();
  let index = 0;
  let match: RegExpMatchArray | null;
  // tslint:disable-next-line
  while ((match = text.match(/<([^/].*?)>/)) !== null) {
    const tag = match[1];
    const before = text.slice(0, match.index);
    if (before.length > 0) {
      tokens.push(before);
    }
    text = text.slice(match.index! + match[0].length);
    const endMatch = text.match(new RegExp(`</${tag}>`));
    if (!endMatch) {
      throw new Error(`No closing tag for "<${tag}>" in "${source}" formatted text.`);
    }
    const style = styles[tag];
    if (!style) {
      throw new Error(`Style is missing for tag "${tag}" in "${source}" formatted text.`);
    }
    tokens.push((
      <span className={classNames(styles[tag])} key={key + (index++)}>
        {tokenize(text.slice(0, endMatch.index), styles, key + (index++) + '-')}
      </span>
    ));
    text = text.slice(endMatch.index! + endMatch[0].length);
  }
  if (text.length > 0) {
    tokens.push(text);
  }
  return tokens;
};
