import { T } from '@sonnen/shared-i18n/service';
import { Icofont, PageWidthWrapper } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ImpostorBanner.component.scss';

export const ImpostorBanner: React.SFC = ({}) => (
  <div className={'c-impostor-banner'}>
    <PageWidthWrapper>
      <div className={'c-impostor-banner__content'}>
        <Icofont type={'admin'} className={'c-impostor-banner__icon'} />
        {I18n.t(T.general.impersonateBanner.title)}
      </div>
    </PageWidthWrapper>
  </div>
);
