import { T } from '@sonnen/shared-i18n/service';
import { MediaQuery } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import './LeadOfferScrollButton.component.scss';

interface Props {
  isVppBonus: boolean;
}

const scrolltoConfiguration = () => {
  const configuration = document.getElementById('lead-configuration');
  const header = document.querySelector('.sw-header');
  const headerHeight = getComputedStyle(header!).height;
  if (configuration !== null) {
    const elementTopPosition = configuration.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -parseInt(headerHeight!, 10);
    window.scrollTo({
      top: elementTopPosition + yOffset,
      behavior: 'smooth',
    });
  }
};

export const LeadOfferScrollButton: React.FC<Props> = ({
  isVppBonus,
}) => (
    <Media query={MediaQuery.DOWN_SM}>
      <button
        className={'c-lead-offer-scroll-button'}
        onClick={scrolltoConfiguration}
      >
        <p>{I18n.t(T.lead.offer._salessolution_.scrollButton.label)}</p>
        <span className={classNames('c-lead-offer-scroll-button__subtext', {
          'c-lead-offer-scroll-button__subtext--error': !isVppBonus,
        })}>
          {isVppBonus
            ? I18n.t(T.lead.offer._salessolution_.scrollButton.seeDetails)
            : I18n.t(T.lead.offer._salessolution_.scrollButton.vppUpdated)
          }
        </span>
      </button>
    </Media>
  );
