import { getHardwareProduct } from '+app/+lead/+offer/store/+offer.helper';
import { LeadOverviewPageActions } from '+app/+lead/+overview/store/+overview.actions';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { LEAD_IN_SETUP_STAGE, PATHS, ROUTES, SETUP_TAB_ROUTE_NAME } from '+app/router';
import { getRouterLocationPathFirstSegment } from '+app/router/store/router.selectors';
import { LeadActions } from '+shared/store/lead';
import { LeadRepository } from '+shared/store/lead/lead.repository';
import { OfferProductStatus } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { insertIf, mapPathToParams, processQuery } from '+utils/index';
import { mapToState } from '+utils/operators/mapToState.operator';
import { ofType } from '+utils/operators/ofType.operator';
import { push } from 'connected-react-router';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, iif, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HardwarePageActions } from './+hardware.actions';
import { mapProductStatusToLeadStatus } from './+hardware.helper';
import { getNewHardwareStatus } from './+hardware.selectors';
import { HARDWARE_SAVE_QUERY, UPDATE_HARDWARE_STATUS_QUERY } from './+hardware.state';

type Action$ = ActionsObservable<HardwarePageActions | LeadActions>;
type State$ = StateObservable<StoreState>;

const saveHardwareOffer$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(HardwarePageActions.saveHardwareOffer),
  mergeMap(action => of(action).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      mapPathToParams(ROUTES.LEAD_HARDWARE_NEW[0], ROUTES.SETUP_LEAD_HARDWARE_NEW[0]),
      mergeMap(([leadId]) => of({}).pipe(
        processQuery(
          HARDWARE_SAVE_QUERY,
          () => LeadRepository.postLeadHardwareOffer(leadId, action.batteryId),
          {
            onSuccess: res =>
              concat(
                iif(() => !!getNewHardwareStatus(state) && (getHardwareProduct(res.element)?.status !== getNewHardwareStatus(state)),
                  of(HardwarePageActions.updateHardwareStatus(
                    leadId,
                    [
                      {
                        offerId: res.element.id,
                        productId: getHardwareProduct(res.element)?.productId ?? '',
                        status: getNewHardwareStatus(state)!,
                      },
                    ],
                  )),
                  iif(() => getRouterLocationPathFirstSegment(state) === SETUP_TAB_ROUTE_NAME,
                    of(push(PATHS.LEAD_CONFIGURATION({ leadId }, LEAD_IN_SETUP_STAGE))),
                    of(push(PATHS.LEAD_CONFIGURATION({ leadId }))),
                  ),
                ),
              ),
          }),
      ))),
    )),
  ),
);

const updateHardwareStatus$ = (action$: Action$) => action$.pipe(
  ofType(HardwarePageActions.updateHardwareStatus),
  mergeMap(({ leadId, productStatusChanges }) => of({}).pipe(
    processQuery(
      UPDATE_HARDWARE_STATUS_QUERY,
      () => forkJoin([
        ...productStatusChanges.map(({ offerId, productId, status }) => LeadRepository
          .patchLeadOfferProduct(leadId, offerId, productId, status)),
      ]),
      {
        onSuccess: () => concat(
          ...productStatusChanges.flatMap(({ offerId, productId, status }) => [
            of(LeadOverviewPageActions.setHardwareOfferStatus(status, offerId)),
            of(LeadPageActions.setLeadHardwareStatus(mapProductStatusToLeadStatus(status))),
            ...insertIf(
              [OfferProductStatus.ACCEPTED, OfferProductStatus.CONFIRMED].includes(status),
              of(LeadOverviewPageActions.setBlockedStatusOnDifferentHwOffers(productId)),
            ),
          ]),
        ),
      },
    ))));

export const epics = combineEpics(
  saveHardwareOffer$,
  updateHardwareStatus$,
);
