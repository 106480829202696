import {
  LeadImpactAnalysisRecommendationResponse,
} from '+shared/store/lead/types/leadImpactAnalysisRecommendationResponse.interface';
import { LeadImpactAnalysisResponse } from '+shared/store/lead/types/leadImpactAnalysisResponse.interface';
import { Query } from '+shared/store/query';
import {
  ConsumptionForm,
  CostsForm,
  PdfCreationForm,
  ProductSetupForm,
} from './+impactAnalysis.interface';

export const CREATE_IMPACT_ANALYSIS_QUERY = 'createImpactAnalysisQuery';
export const DOWNLOAD_IMPACT_ANALYSIS_FILE_QUERY = 'downloadImpactAnalysisFileQuery';
export const GET_IMPACT_ANALYSIS_LIST_QUERY = 'getImpactAnalysisListQuery';
export const DELETE_IMPACT_ANALYSIS_QUERY = 'deleteImpactAnalysisQuery';
export const SEND_IMPACT_ANALYSIS_QUERY = 'sendImpactAnalysisQuery';
export const CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY = 'createImpactAnalysisRecommendationQuery';

export interface ImpactAnalysisState {
  impactAnalysisList: LeadImpactAnalysisResponse[];
  impactAnalysisRecommendation?: LeadImpactAnalysisRecommendationResponse;
  consumptionForm?: ConsumptionForm;
  productSetupForm?: ProductSetupForm;
  costsForm?: CostsForm;
  pdfCreationForm?: PdfCreationForm;
  [CREATE_IMPACT_ANALYSIS_QUERY]: Query<any>;
  [CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY]: Query<any>;
  [SEND_IMPACT_ANALYSIS_QUERY]: Query<any>;
  [DELETE_IMPACT_ANALYSIS_QUERY]: Query<any>;
  [GET_IMPACT_ANALYSIS_LIST_QUERY]: Query<any>;
  [DOWNLOAD_IMPACT_ANALYSIS_FILE_QUERY]: Query<any>;
}

export const initialState: ImpactAnalysisState = {
  impactAnalysisList: [],
  consumptionForm: undefined,
  productSetupForm: undefined,
  costsForm: undefined,
  pdfCreationForm: undefined,
  [CREATE_IMPACT_ANALYSIS_QUERY]: {},
  [CREATE_IMPACT_ANALYSIS_RECOMMENDATION_QUERY]: {},
  [SEND_IMPACT_ANALYSIS_QUERY]: {},
  [DELETE_IMPACT_ANALYSIS_QUERY]: {},
  [GET_IMPACT_ANALYSIS_LIST_QUERY]: {},
  [DOWNLOAD_IMPACT_ANALYSIS_FILE_QUERY]: {},
};
