import { UploadLogoForm } from '+shared/store/user/types/companyLogo.interface';
import { UploadDocsForm } from '+shared/store/user/types/legalDocuments.interface';
import { UserCertificate } from '+shared/store/user/types/userCertificate.interface';
import { UserPasswordForm } from '+shared/store/user/types/userPassword.interface';
import { ActionsUnion, createAction } from '+utils/redux';

export enum ACCOUNT_PAGE_ACTIONS {
  SUBMIT_COMPANY_LOGO = '[+Account] SUBMIT LOGO',
  RESET_UPLOAD_COMPANY_LOGO_QUERY = '[+Account] RESET_UPLOAD_COMPANY_LOGO_QUERY',
  SUBMIT_PROFILE_LOGO = '[+Account] SUBMIT PROFILE LOGO',
  RESET_UPLOAD_PROFILE_LOGO_QUERY = '[+Account] RESET_UPLOAD_PROFILE_LOGO_QUERY',
  EDIT_PASSWORD = '[+Account] EDIT PASSWORD',
  RESET_EDIT_PASSWORD_QUERY = '[+Account] RESET EDIT PASSWORD QUERY',
  SUBMIT_COMPANY_DOCS = '[+Account] SUBMIT_COMPANY_DOCS',
  RESET_UPLOAD_COMPANY_DOCS_QUERY = '[+Account] RESET_UPLOAD_COMPANY_DOCS_QUERY',
  GET_CERTIFICATE_LIST = '[+Account] GET_CERTIFICATE_LIST',
  SET_CERTIFICATE_LIST = '[+Account] SET_CERTIFICATE_LIST',
}

export const AccountPageActions = {
  editPassword: createAction(
    ACCOUNT_PAGE_ACTIONS.EDIT_PASSWORD,
    (passwordForm: UserPasswordForm) => ({ passwordForm }),
  ),
  resetPasswordQuery: createAction(
    ACCOUNT_PAGE_ACTIONS.RESET_EDIT_PASSWORD_QUERY,
  ),
  submitCompanyDocs: createAction(
    ACCOUNT_PAGE_ACTIONS.SUBMIT_COMPANY_DOCS,
    (uploadDocsForm: UploadDocsForm) => ({ uploadDocsForm }),
  ),
  resetUploadCompanyDocsQuery: createAction(
    ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_COMPANY_DOCS_QUERY,
  ),
  submitCompanyLogo: createAction(
    ACCOUNT_PAGE_ACTIONS.SUBMIT_COMPANY_LOGO,
    (uploadLogoForm: UploadLogoForm) => ({ uploadLogoForm }),
  ),
  resetUploadCompanyLogoQuery: createAction(
    ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_COMPANY_LOGO_QUERY,
  ),
  submitProfileLogo: createAction(
    ACCOUNT_PAGE_ACTIONS.SUBMIT_PROFILE_LOGO,
    (uploadLogoForm: UploadLogoForm) => ({ uploadLogoForm }),
  ),
  resetUploadProfileLogoQuery: createAction(
    ACCOUNT_PAGE_ACTIONS.RESET_UPLOAD_PROFILE_LOGO_QUERY,
  ),
  getCertificateList: createAction(
    ACCOUNT_PAGE_ACTIONS.GET_CERTIFICATE_LIST,
    ({ contactId, queryKey }: { contactId: string; queryKey: string }) =>
    ({ contactId, queryKey }),
  ),
  setCertificateList: createAction(
    ACCOUNT_PAGE_ACTIONS.SET_CERTIFICATE_LIST,
    (certificates: UserCertificate[]) => ({ certificates }),
  ),
};

export type AccountPageActions = ActionsUnion<typeof AccountPageActions>;
