import { CustomerActions } from '+customer/store';
import { getNoteSubmitQueryStatus } from '+customer/store/+customer.selectors';
import { PUT_CUSTOMER_NOTE_QUERY } from '+customer/store/+customer.state';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { FormState } from './CustomerNote.helper';

interface RenderProps {
  formState: FormState;
  isEditActive: boolean;
  note: string;
  onFormSubmit: (value: CustomerNoteForm) => void;
  cancelEdit: () => void;
  editNote: () => void;
}

interface ComponentProps {
  customerNote: string;
  customerId: string;
  renderForm: (renderProps: RenderProps) => React.ReactNode;
  renderError: (error: string) => React.ReactNode;
}

interface State {
  note: string;
  formState: FormState;
  isEditActive: boolean;
}

const mapStateToProps = (state: StoreState) => ({
  noteSubmitQueryStatus: getNoteSubmitQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  submitCustomerNote: CustomerActions.putCustomerNote,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

interface CustomerNoteForm {
  note: string;
}

class CustomerNoteComponent extends React.PureComponent<Props, State> {
  state = {
    note: this.props.customerNote,
    formState: FormState.FORM,
    isEditActive: false,
  };

  editNote = () => {
    this.setState({
      formState: FormState.FORM_READY,
      isEditActive: true,
    });
  };

  cancelEdit = () => {
    this.setState({
      formState: FormState.FORM,
      isEditActive: false,
    });
  };

  onFormSubmit = (values: CustomerNoteForm) => {
    const { actions, customerId } = this.props;

    actions.submitCustomerNote(PUT_CUSTOMER_NOTE_QUERY, customerId, values.note);
    this.setState({
      formState: FormState.FORM_PENDING,
      isEditActive: false,
      note: values.note,
    });
  };

  componentDidUpdate() {
    const { noteSubmitQueryStatus } = this.props;

    if (this.state.formState === FormState.FORM_PENDING && noteSubmitQueryStatus.success) {
      this.setState({
        formState: FormState.FORM,
        isEditActive: false,
      });
    }
  }

  render() {
    const { noteSubmitQueryStatus, renderForm, renderError } = this.props;
    const { formState, isEditActive, note } = this.state;
    const renderProps = {
      onFormSubmit: this.onFormSubmit,
      cancelEdit: this.cancelEdit,
      editNote: this.editNote,
      formState,
      isEditActive,
      note,
    };

    return (
      <>
        {renderForm(renderProps)}
        {noteSubmitQueryStatus.error && renderError(I18n.t(T.customerSingle.overview.battery.error))}
      </>
    );
  }
}

export const CustomerNote = connect(mapStateToProps, mapDispatchToProps)(CustomerNoteComponent);
