import { ListTable, ListTableHeader, ListTableHeaderItem } from '+shared/components';
import { Contact } from '+shared/store/contact/types';
import * as React from 'react';
import { ContactListTableItem } from '../../containers/ContactListTableItem';
import { getTableHeaders } from './ContactListTable.helper';

interface Props {
  items: Contact[];
  isPending: boolean;
}

export const ContactListTable: React.FC<Props> = ({
  items,
  isPending,
}) => (
  <ListTable
    className={'c-list-table--contact-list'}
    headers={getTableHeaders()}
    items={items}
    isPending={isPending}
    hoverable={true}
    renderHeader={header => (
      <ListTableHeader 
        className={'c-list-table__header--contact-list'} 
        item={header} 
      />
    )}
    renderItem={item => <ContactListTableItem item={item}/>}
  />
);
