import { CustomerRouteParams } from '+app/router';
import { CaseReportHelper } from '+shared/containers';
import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { isAnyBatteryOnSiteVisible } from '+shared/store/battery/battery.helpers';
import { CaseActions } from '+shared/store/case';
import { Customer, Site } from '+shared/store/customer';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getStatus } from '+shared/store/query/query.utils';
import classNames from 'classnames';
import { isEmpty } from 'lodash/fp';
import * as React from 'react';
import { CustomersTableAccessInfo } from '../CustomersTableAccessInfo';
import { AccessDeniedNotification, CustomersTableRow } from '../CustomersTableRow';
import { CustomersTableSite } from '../CustomersTableSite';
import { CustomersTableWithoutSite } from '../CustomersTableWithoutSite';

import './CustomersTableUser.component.scss';

interface Props {
  customer: Customer;
  isSuperUser: boolean;
  toggleModal: typeof LayoutActions.toggleModal;
  setCaseReportActiveSite: typeof CaseActions.setCaseReportActiveSite;
  contractQueryStatus: ReturnType<typeof getStatus>;
  onSiteClick: (params: CustomerRouteParams) => void;
}

export const CustomersTableUser: React.FC<Props> = ({
  customer,
  toggleModal,
  setCaseReportActiveSite,
  contractQueryStatus,
  isSuperUser,
  onSiteClick,
}) => {
  const { track } = useTracking();
  const { id, sites, privacyPolicyAccepted, partnerMonitoringAccepted } = customer;
  const isConsentGiven = privacyPolicyAccepted && partnerMonitoringAccepted;
  const isFirst = (index: number) => index === 0;
  const isLast = (index: number) => index === sites.length - 1;
  const onCustomerSiteClick = (siteId: string) => {
    onSiteClick({ customerId: id, siteId });
    track(InteractionEvents.buttonClick({
      category: EventCategory.CUSTOMER_DETAILS,
      action: ActionName.OPEN_SECTION,
    }));
  };

  const handleCaseReportButton = (site: Site) => {
    const activeSite = CaseReportHelper.transformSite(site, customer);
    toggleModal(true, ModalId.CASE_REPORT);
    setCaseReportActiveSite(activeSite);
  };

  return (
    <CustomersTableRow isConsentGiven={isConsentGiven}>
      {
        ({ isNotificationVisible, hideNotification }: AccessDeniedNotification) =>
          <div>
            {sites
              .filter(site => isAnyBatteryOnSiteVisible(site.batterySystems))
              .map((site, index) => (
                <div
                  key={customer.id + site.id}
                  className={classNames('c-customers-table-user__row', {
                    'c-customers-table-user__row--enabled': isConsentGiven,
                  })}
                  onClick={(isConsentGiven || isSuperUser) ? () => onCustomerSiteClick(site.id) : undefined}
                >
                  <CustomersTableSite
                    contractQueryStatus={contractQueryStatus}
                    onCaseReportButtonClick={() => handleCaseReportButton(site)}
                    key={site.id}
                    customer={customer}
                    site={site}
                    isFirst={isFirst(index)}
                    isConsentGiven={isConsentGiven}
                  />
                  {!isLast(index) && (
                    <div className={'c-customers-table-user__delimiter'} />
                  )}
                </div>
              ))
            }
            {isEmpty(sites) && (
              <CustomersTableWithoutSite
                customer={customer}
                isConsentGiven={isConsentGiven}
              />
            )}
            {!isConsentGiven && isNotificationVisible && (
              <CustomersTableAccessInfo
                hideNotification={hideNotification}
              />
            )}
          </div>
      }
    </CustomersTableRow>
  );
};
