import { Logo } from '+app/+account/containers/Logo';
import { AccountPageActions } from '+app/+account/store';
import {
  getCertificates,
  getCertificatesQueryStatus,
  getUploadProfileLogoQueryStatus,
} from '+app/+account/store/+account.selector';
import { mapActions } from '+app/utils';
import { StoreState } from '+shared/store/store.interface';
import { getUserProfile } from '+shared/store/user/user.selectors';
import { T } from '@sonnen/shared-i18n/service';
import { isEmpty } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { CertificateList } from '../../components/CertificateList';
import { CertificateWarning } from '../../components/CertificateWarning';
import { ContactDetails } from '../../components/ContactDetails';
import { EventsAndTrainings } from '../../components/EventsAndTrainings';
import { PersonalDetails } from '../../components/PersonalDetails';
import { ResetPassword } from '../ResetPassword';

import './MyAccount.component.scss';

const mapStateToProps = (state: StoreState) => ({
  userProfile: getUserProfile(state),
  logoQueryStatus: getUploadProfileLogoQueryStatus(state),
  certificates: getCertificates(state),
  certificatesQueryStatus: getCertificatesQueryStatus(state),
 });

const mapDispatchToProps = mapActions({
  submitProfileLogo: AccountPageActions.submitProfileLogo,
  resetUploadProfileLogoQuery: AccountPageActions.resetUploadProfileLogoQuery,
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

const MyAccountComponent: React.FC<Props> = ({
  userProfile, logoQueryStatus, certificates, actions, certificatesQueryStatus,
}) => {
  const isCertificateListEmpty = isEmpty(certificates) && certificatesQueryStatus.success;
  return (
    <div className={'c-my-account'}>
      {isCertificateListEmpty && <CertificateWarning />}
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <PersonalDetails user={userProfile} />
        </div>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <ContactDetails user={userProfile} />
        </div>
      </div>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 c-account-company__column'} data-hj-suppress={true}>
          <Logo
            title={I18n.t(T.myAccount.logo.modal.title)}
            hint={I18n.t(T.myAccount.logo.modal.hint)}
            headline={I18n.t(T.myAccount.logo.title)}
            logotypeUrl={userProfile?.image}
            queryStatus={logoQueryStatus}
            submitLogo={actions.submitProfileLogo}
            resetUploadLogoQuery={actions.resetUploadProfileLogoQuery}
          />
        </div>
      </div>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          { <CertificateList certificates={certificates} isLoading={certificatesQueryStatus.pending} />}
        </div>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <EventsAndTrainings />
        </div>
      </div>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 c-my-account__column'}>
          <ResetPassword />
        </div>
      </div>
    </div>
  );
};

export const MyAccount = connect(mapStateToProps, mapDispatchToProps)(MyAccountComponent);
