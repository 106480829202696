import { mapConsumption } from '+app/+lead/store/+lead.helper';
import { CurrencyUnit } from '+app/App.constants';
import { Lead, LeadCost, LeadEnergyCostInEuro, LeadLargeEnergyConsumer } from '+shared/store/lead/types';
import { isNotNil } from '+utils/array.util';
import { formatCurrency } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';

type LeadEnergyConsumption = Lead['energyConsumption'];

const mapSingleEnergyConsumer = (consumer: string): string | undefined => {
  switch (consumer) {
    case LeadLargeEnergyConsumer.ELECTRIC_CAR:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.electricCar);
    case LeadLargeEnergyConsumer.HEAT_PUMP:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.heatPump);
    case LeadLargeEnergyConsumer.INFRARED_HEATING:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.infraredHeating);
    case LeadLargeEnergyConsumer.POOL_OR_SAUNA:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.poolOrSauna);
    case LeadLargeEnergyConsumer.STORAGE_HEATER:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.storageHeater);
    case LeadLargeEnergyConsumer.OTHER:
      return I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.largeConsumer.other);
    default:
      return undefined;
  }
};

const mapEnergyConsumers = (energyConsumption: LeadEnergyConsumption) => (
  energyConsumption && isNotNil(energyConsumption.largeConsumers) ?
    Object.keys(energyConsumption.largeConsumers)
      .filter(key => Boolean(energyConsumption.largeConsumers[key]))
      .map(mapSingleEnergyConsumer)
      .filter(Boolean)
      .join(', ')
    : undefined
);

const mapEnergyCosts = (costs: LeadCost<CurrencyUnit.CURRENCY_EUR>): string | undefined => {
  const { value, currency } = costs;
  const opts = { precision: 0 };

  switch (value) {
    case LeadEnergyCostInEuro.LT_1000:
      return `< ${formatCurrency(1000, currency, opts)}`;
    case LeadEnergyCostInEuro.BTW_1000_AND_1500:
      return `${formatCurrency(1000, currency, opts)} - ${formatCurrency(1500, currency, opts)}`;
    case LeadEnergyCostInEuro.BTW_1500_AND_2000:
      return `${formatCurrency(1500, currency, opts)} - ${formatCurrency(2000, currency, opts)}`;
    case LeadEnergyCostInEuro.GT_2000:
      return `> ${formatCurrency(2000, currency, opts)}`;
    default:
      return undefined;
  }
};

export const getLeadEnergyConsumptionDetailsList = ({ energyConsumption }: Lead) => ([
  {
    label: I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.currentEnergyConsumption),
    value: mapConsumption(energyConsumption),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.currentLargeConsumers),
    value: mapEnergyConsumers(energyConsumption),
  },
  {
    label: I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.currentEnergyCosts),
    value: (energyConsumption && energyConsumption.costsPerYear) ?
      mapEnergyCosts(energyConsumption.costsPerYear) : undefined,
  },
]);
