import { getPartnerId, getpartnerNotesId, getUserPermissions } from '+app/+lead/store/+lead.selectors';
import { mapActions } from '+app/utils';
import { FormErrorBanner, Widget } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { Lead } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, Icon, LinkButton, Loader } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import {
  getPartnerNoteQueryStatus,
  getPartnerNotes,
  postPartnerNoteQueryStatus,
} from '../../store/+overview.selectors';

import './LeadOverviewPartnerNotes.component.scss';

const mapStateToProps = (state: StoreState) => ({
  getPartnerNotesQueryStatus: getPartnerNoteQueryStatus(state),
  setPartnerNotesQueryStatus: postPartnerNoteQueryStatus(state),
  partnerNotes: getPartnerNotes(state),
  assignedPartnerContactId: getPartnerId(state),
  partnerNotesId: getpartnerNotesId(state),
  userPermissions: getUserPermissions(state),
});

const mapDispatchToProps = mapActions({
  postPartnerNote: LeadOverviewPageActions.postPartnerNote,
  getPartnerNote: LeadOverviewPageActions.getPartnerNote,
  clearPartnerNotes: LeadOverviewPageActions.clearPartnerNotes,
});

interface ComponentProps {
  lead: Lead;
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  & ComponentProps
  ;

export const LeadOverviewPartnerNotesComponent: React.FC<Props> = ({
  lead,
  actions,
  getPartnerNotesQueryStatus,
  setPartnerNotesQueryStatus,
  partnerNotes,
  partnerNotesId,
  userPermissions,
}) => {
  const [isEditMode, setEditMode] = React.useState<boolean>(false);
  const isInitialLoading = React.useRef<boolean>(true);
  const canPartnerEditNotes = get(userPermissions, 'notes.create', false);

  React.useEffect(() => {
    if (partnerNotesId === undefined) {
      isInitialLoading.current = false;
    }

    if (getPartnerNotesQueryStatus.success) {
      isInitialLoading.current = false;
      setEditMode(false);
    }
  }, [getPartnerNotesQueryStatus, partnerNotesId]);

  React.useEffect(() => {
    setEditMode(!partnerNotes);
  }, [partnerNotes]);

  React.useEffect(() => {
    if (lead.note) {
      actions.getPartnerNote(lead.note);
    }
  }, [lead.note]);

  React.useEffect(() => {
    return () => {
      actions.clearPartnerNotes();
    };
  }, []);

  const isPending = () => setPartnerNotesQueryStatus.pending || getPartnerNotesQueryStatus.pending;
  const isFormValid = (form: FormikProps<{ partnerNotes: string; }>) => !form.isValid && form.dirty;
  const shouldFormDisplay = () => isEditMode || partnerNotes === null || partnerNotes === '';
  const displayNote = () => (
    <>
      {isPending() && isInitialLoading.current &&
        <Loader className={'c-lead-overview-partner-notes__loader'} />
      }
      <div className={'c-lead-overview-partner-notes__shadow'}>
        <div className={'c-lead-overview-partner-notes__text-wrapper'}>
          {partnerNotes && `${partnerNotes}\n\n`}
        </div>
      </div>
    </>);

  const onSubmit = (values: { partnerNotes: string }) => {
    actions.postPartnerNote(values.partnerNotes);
  };

  const renderForm = () => {
    if (!canPartnerEditNotes) {
      return displayNote();
    }

    if (!shouldFormDisplay() || isInitialLoading.current) {
      return displayNote();
    }

    return (
      <Formik
        initialValues={{ partnerNotes: partnerNotes !== null ? partnerNotes : '' }}
        onSubmit={onSubmit}
        render={form => (
          <Form className={'c-lead-overview-partner-notes__form'}>
            <FormTextarea
              form={form}
              placeholder={I18n.t(T.lead.overview._salessolution_.partnerNotes.placeholder)}
              name={'partnerNotes'}
              className={'c-lead-overview-partner-notes__textarea'}
            >
              {isPending() &&
                <Loader className={'c-lead-overview-partner-notes__loader'} />
              }
            </FormTextarea>
            <div className={'c-lead-overview-partner-notes__btn'}>
              <Button
                label={I18n.t(T.general.basicActions.save)}
                size={ButtonSize.NORMAL}
                type={'submit'}
                isDisabled={isFormValid(form) || isPending()}
              />
            </div>
            <FormErrorBanner
              isVisible={getPartnerNotesQueryStatus.error}
              error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
            />

          </Form>
        )}
      />
    );
  };

  return (
    <Widget
      icon={<Icon.NotesWithPencil className={'c-lead-overview-partner-notes__icon'} />}
      className={`c-lead-overview-partner-notes
      ${(!canPartnerEditNotes || !shouldFormDisplay())
          ? 'c-lead-overview-partner-notes--display-mode'
          : 'c-lead-overview-partner-notes--edit-mode'}`}
      heading={I18n.t(T.lead.overview._salessolution_.partnerNotes.headline)}
      customAction={!shouldFormDisplay() && canPartnerEditNotes &&
        <LinkButton onClick={() => setEditMode(true)}>
          {I18n.t(T.general.basicActions.edit)}
        </LinkButton>
      }
    >
      {renderForm()}
    </Widget>
  );
};

export const LeadOverviewPartnerNotes = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadOverviewPartnerNotesComponent);
