import { Config } from '+config';
import { FirebaseContext } from '+shared/components/FirebaseContext';
import { Layout } from '+shared/containers/Layout';
import { AnalyticsTracker, GoogleTagEventData, GoogleTagManager, ReduxActionTracker } from '+shared/GoogleTagManager';
import { useUrlHistory } from '+shared/GoogleTagManager/hooks';
import { Firebase } from '+shared/store/firebase/firebase.client';
import { persistor, store } from '+shared/store/store.config';
import { GoogleTagManagerScript } from '@sonnen/shared-web';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Routing } from './router/Routing.component';
import { history } from './router/store';
import { LocaleProvider } from './utils/react/locale.provider.component';

const GTM_DATA_LAYER = 'dataLayer';
const gtmClient = GoogleTagManager.create(GTM_DATA_LAYER);
const sendGoogleAnalyticsEvent = (data: GoogleTagEventData) => gtmClient.track(data);

export const App = () => {
  const getUrlHistory = useUrlHistory();

  return (
    <>
      <GoogleTagManagerScript
        dataLayer={GTM_DATA_LAYER}
        environment={Config.GOOGLE_TAG_MANAGER}
      />
      <Provider store={store}>
        <FirebaseContext.Provider value={new Firebase()}>
          <ConnectedRouter history={history}>
            <PersistGate persistor={persistor}>
              {(bootstrapped: boolean) => (
                <AnalyticsTracker
                  sendEvent={sendGoogleAnalyticsEvent}
                  getCommonProps={getUrlHistory}
                >
                  <LocaleProvider>
                    <ReduxActionTracker />
                    <Layout isBootstrapped={bootstrapped}>
                      <Routing />
                    </Layout>
                  </LocaleProvider>
                </AnalyticsTracker>
              )}
            </PersistGate>
          </ConnectedRouter>
        </FirebaseContext.Provider>
      </Provider>
    </>
  );
};
