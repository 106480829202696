import { SonnenDriveProcessSlide } from '+drive/components';
import { getSlides } from '+drive/components/SonnenDriveProcess/SonnenDriveProcess.helper';
import { T } from '@sonnen/shared-i18n/service';
import { Carousel } from '@sonnen/shared-web/src/components/Carousel/Carousel.component';
import { Icon } from '@sonnen/shared-web/src/components/Icon/index';
import { PageSubheadline } from '@sonnen/shared-web/src/components/PageSubheadline/PageSubheadline.component';
import { breakpointDown } from '@sonnen/shared-web/src/utils/breakpoints';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';

import './SonnenDriveProcess.component.scss';

export const SonnenDriveProcess: React.FC = () => (
  <div className={'c-sonnen-drive-benefits'}>
    <PageSubheadline>
      {I18n.t(T.drive.process.headline)}
    </PageSubheadline>
    <Carousel
      arrowsOnDesktop={false}
      arrowsOnMobile={false}
      autoplay={true}
      autoplaySpeed={10000}
      slidesToShow={3}
      slidesToScroll={3}
      infinite={false}
      speed={600}
      responsive={[
        {
          breakpoint: breakpointDown('MD'),
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: breakpointDown('XS'),
          settings: {
            autoplaySpeed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 300,
          },
        },
      ]}
    >
      {getSlides().map(({ icon, text, arrow }) => {
          // tslint:disable-next-line:naming-convention
        const IconComponent = Icon[icon];

        return (
          <SonnenDriveProcessSlide
            key={uuid.v4()}
            icon={<IconComponent />}
            iconName={icon}
            text={I18n.t(text)}
            arrow={arrow}
          />
        );
      })}
    </Carousel>
  </div>
);
