import { PageWidthWrapper } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './Container.component.scss';

interface Props {
  withVerticalPadding?: boolean;
  withHorizontalPadding?: boolean;
  className?: ClassValue;
  id?: string;
}

export const Container: React.SFC<Props> = ({
  children,
  className,
  id,
  withHorizontalPadding = true,
  withVerticalPadding = true,
}) => (
  <PageWidthWrapper>
    <section className={classNames('c-container', {
      'c-container--with-h-padding': withHorizontalPadding,
      'c-container--with-v-padding': withVerticalPadding,
    }, className)} id={id}>
        {children}
    </section>
  </PageWidthWrapper>
);
