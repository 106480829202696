import { setLocale } from 'react-redux-i18n';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { ignoreElements, map, tap } from 'rxjs/operators';
import { storeProvider } from '../shared/store/store.provider';
import { ofType } from '../utils';
import { I18nActions } from './i18n.actions';
import { setBrowserLocale } from './i18n.helpers';

type Action$ = ActionsObservable<I18nActions>;

export const setLocale$ = (action$: Action$) => action$.pipe(
  ofType(I18nActions.setLocale),
  map(action => action.locale),
  tap(setBrowserLocale),
  tap(() => window.location.reload()),
  ignoreElements(),
);

export const epics = combineEpics<any>(
  setLocale$,
);
