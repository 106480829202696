import { FormBanner, FormErrorBanner, FormInput } from '+shared/components';
import { EventCategory, FormName } from '+shared/GoogleTagManager';
import { PartnerActions } from '+shared/store/partner';
import { ClaimBatteryAttributes } from '+shared/store/partner/types/claimBattery.interface';
import { QueryState } from '+shared/store/query';
import { T } from '@sonnen/shared-i18n/service';
import { Icon } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { FieldNames } from '../../containers/MissingBatteryModal/types';
import { MissingBatteryBanner } from '../MissingBatteryBanner';
import { MissingBatteryModalLayout } from '../MissingBatteryModalLayout';
import { 
  MissingBatteryClaimFormValues,
  missingBatteryClaimSchema,
} from './MissingBatteryClaimForm.helper';

import './MissingBatteryClaimForm.component.scss';

interface Props {
  claimBatteryData: ClaimBatteryAttributes;
  claimBatteryOwnership: typeof PartnerActions.claimMissingBatteryOwnership;
  queryState: QueryState;
  queryError?: number;
  showCaseReporting: () => void;
}

export const MissingBatteryClaimForm: React.FC<Props> = ({
  claimBatteryData: {serialNumber, postalCode, customerLastName, pvPeakPower},
  queryState,
  claimBatteryOwnership,
  queryError,
  showCaseReporting,
}) => {
  const message = I18n.t(T.missingBattery.banner.serialNumberSuccess);
  const initialValues = {
    serialNumber,
    postalCode,
    customerLastName,
    pvPeakPower: pvPeakPower ? pvPeakPower.toString() : '',
  };
  const [sentValues, setSentValues] = React.useState<MissingBatteryClaimFormValues>(initialValues);
  const handleSubmit = (values: MissingBatteryClaimFormValues) => {
    claimBatteryOwnership({
      ...values,
      pvPeakPower: Number(values.pvPeakPower),
    });
    setSentValues(values);
  };
  
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={missingBatteryClaimSchema}
      onSubmit={handleSubmit}
      render={form => (
        <Form className={'c-missing-battery-claim-form'}>
          <MissingBatteryModalLayout
            isLoading={queryState.pending}
            canSubmit={form.isValid && !isEqual(form.values, sentValues)}
            buttonLabel={queryState.error
              ? I18n.t(T.missingBattery.modal.checkAgainButton)
              : I18n.t(T.missingBattery.modal.nextButton)
            }
          >
            <FormInput
              form={form}
              name={FieldNames.SERIAL_NUMBER}
              label={I18n.t(T.missingBattery.serialNumber)}
              disabled={true}
            />
            {!(queryState.error || queryState.pending) && (
              <div className={'c-missing-battery-claim-form__banner-wrapper'}>
                <FormBanner
                  isVisible={true}
                  type={'default'}
                  message={<span dangerouslySetInnerHTML={{__html: message}} />}
                  icon={<Icon.DoneCircle 
                    className={'c-missing-battery-claim-form__form-banner-icon'}
                    secondaryColor={'#fff'}
                  />}
                  boxShadow={false}
                  iconPositionTop={true}
                />
              </div>
            )}
            <FormInput
              form={form}
              name={FieldNames.POSTAL_CODE}
              label={I18n.t(T.missingBattery.zipCode)}
              disabled={queryState.pending}
            />
            <FormInput
              form={form}
              name={FieldNames.LAST_NAME}
              label={I18n.t(T.missingBattery.lastName)}
              disabled={queryState.pending}
            />
            <FormInput
              form={form}
              name={FieldNames.PV_POWER}
              label={I18n.t(T.missingBattery.pvSize)}
              type={'number'}
              disabled={queryState.pending}
            />
            {/* @TODO make it more generic */}
            {queryState.error && isEqual(form.values, sentValues) && (queryError === 422 
              ? <MissingBatteryBanner
                  message={I18n.t(T.missingBattery.banner.claimDataIncorrect)}
                  formName={FormName.CLAIM_BATTERY}
                  buttonAction={showCaseReporting}
                  queryState={queryState}
                />
              : <FormErrorBanner
                  error={I18n.t(T.general.errors.submitForm)}
                  isVisible={true}
                  toLeft={true}
                />
              )}
          </MissingBatteryModalLayout>
        </Form>
      )}
    />
  );
};
