import { setContractType } from '+app/+customer/+contract/containers/CustomerContract/CustomerContract.helper';
import { StatusTileColors } from '+app/shared/components/StatusTile';
import { Contract } from '+shared/store/contract';
import { getContractWarnings } from '+shared/store/customer/warnings/warnings.factory';
import { TableCell } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { CustomersTableStatusTile } from '../CustomersTableStatusTile';

import './CustomersTableContract.component.scss';

interface Props {
  contract: Contract;
  className?: ClassValue;
}

export const CustomersTableContract: React.FC<Props> = ({
  contract, contract: {meterInstallation}, className,
}) => {
  const classes = classNames('c-customers-table-contract', className);
  const warnings = getContractWarnings({contract, meterInstallation });

  return (
    <TableCell className={classes}>
      <div className={'c-customers-table-contract__container'}>
        <div className={'c-customers-table-contract__row'}>
          <p className={'c-customers-table__paragraph'}>
            {setContractType(contract.name) || '-'}
          </p>
        </div>
        <div className={'c-customers-table-contract__row'}>
          <div className={'c-customers-table-contract__warnings'}>
            {warnings.missingPvRemoteControl
              && warnings.missingPvRemoteControl.type === 'danger'
              && (
                <CustomersTableStatusTile
                  message={warnings.missingPvRemoteControl.message}
                  color={StatusTileColors.RED}
                  tooltipText={warnings.missingPvRemoteControl.messageExtended}
                />
              )}
            {warnings.meterInstallationWarningFactory
              && warnings.meterInstallationWarningFactory.type === 'danger'
              && (
                <CustomersTableStatusTile
                  message={warnings.meterInstallationWarningFactory.message}
                  color={StatusTileColors.RED}
                  tooltipText={warnings.meterInstallationWarningFactory.messageExtended}
                />
              )}
          </div>
        </div>
      </div>
    </TableCell>
  );
};
