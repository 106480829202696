import { getStatus } from '+shared/store/query/query.utils';
import { StoreState } from '+shared/store/store.interface';
import { createSelector } from 'reselect';
import { CONTACT_ASSIGN_ROLE_QUERY, CONTACT_COLLECTION_GET_QUERY, CONTACT_DENY_ROLE_QUERY } from './+contactList.state';

export const getContactListPageState = (state: StoreState) => state.page.contact.list;

export const getContactCollection = createSelector(
  getContactListPageState,
  state => state.collection,
);

export const getContactsCollectionTotalCount = createSelector(
  getContactListPageState,
  state => state.totalResourceCount,
);

export const getCurrentlyEditedRole = createSelector(
  getContactListPageState,
  state => state.currentlyEditedRole,
);

export const getContactsCollectionQuery = createSelector(
  getContactListPageState,
  state => state[CONTACT_COLLECTION_GET_QUERY],
);

export const getContactsCollectionQueryStatus = createSelector(
  getContactsCollectionQuery,
  query => getStatus(query),
);

export const getContactAssignRoleQuery = createSelector(
  getContactListPageState,
  state => state[CONTACT_ASSIGN_ROLE_QUERY],
);

export const getContactAssignRoleQueryStatus = createSelector(
  getContactAssignRoleQuery,
  query => getStatus(query),
);

export const getContactDenyRoleQuery = createSelector(
  getContactListPageState,
  state => state[CONTACT_DENY_ROLE_QUERY],
);

export const getContactDenyRoleQueryStatus = createSelector(
  getContactDenyRoleQuery,
  query => getStatus(query),
);
