import { getAddVideoCategories } from '+app/+help/+videos/containers/Videos/Videos.helper';
import { FormInputSelect } from '+shared/components';
import { FirebaseActions } from '+shared/store/firebase/firebase.actions';
import { Firebase } from '+shared/store/firebase/firebase.client';
import { CategoryName } from '+shared/store/firebase/types';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { QueryState } from '+shared/store/query';
import { VideoYoutube } from '+shared/store/youtube/types/youtube.interface';
import { YoutubeActions } from '+shared/store/youtube/youtube.actions';
import { Button, ButtonSize, ButtonTheme, Modal, ModalLayout } from '@sonnen/shared-web';
import { Form, Formik, FormikHelpers } from 'formik';
import * as React from 'react';
import * as uuid from 'uuid';
import { AddVideoFormButtons } from '../AddVideoFormButtons/AddVideoFormButtons.component';
import { AddVideoLink } from '../AddVideoLink';
import {
  AddVideoForm,
  getYouTubeId,
  validate,
  videoCategory,
  youtubeLink,
} from './AddVideo.helper';

import './AddVideo.component.scss';

interface Props {
  getYoutubeVideo: typeof YoutubeActions.getYoutubeVideoList;
  clearYoutubeVideoList: typeof YoutubeActions.clearYoutubeVideoList;
  toggleModal: typeof LayoutActions.toggleModal;
  addVideo: typeof FirebaseActions.addVideo;
  youtubeVideo?: VideoYoutube;
  firebase: Firebase;
  youtubeVideoQueryStatus: QueryState;
  isModalOpen: boolean;
}

export class AddVideo extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  handleSubmit = async (
    values: AddVideoForm,
    formikActions: FormikHelpers<AddVideoForm>,
  ) => {
    const youtubeId = getYouTubeId(values[youtubeLink]);

    if (values && youtubeId) {
      const video = {
        id: uuid.v4(),
        youtubeId,
        categories: [values[videoCategory].hash as CategoryName],
      };

      try {
        await this.props.firebase.addVideo(video);
        this.props.addVideo(video);
        this.props.clearYoutubeVideoList();
        formikActions.resetForm();
        this.props.toggleModal(false, ModalId.ADD_VIDEO);
      } catch (error) {
        console.error(error);
      }
    }
  };

  render() {
    const {
      youtubeVideo,
      clearYoutubeVideoList,
      getYoutubeVideo,
      youtubeVideoQueryStatus,
      toggleModal,
      isModalOpen,
    } = this.props;

    const openModal = () => toggleModal(true, ModalId.ADD_VIDEO);
    const closeModal = () => toggleModal(false, ModalId.ADD_VIDEO);

    return (
      <>
        <div className={'c-add-video__open-modal'}>
          <Button
            className={'c-add-video__open-modal-button'}
            label={'Add video'}
            onClick={openModal}
            size={ButtonSize.NORMAL}
          />
        </div>
        <Modal
          size={'small'}
          isOpen={isModalOpen}
          onOutsideClick={closeModal}
        >
          <Formik
            initialValues={{
              [videoCategory]: getAddVideoCategories()[0],
              [youtubeLink]: '',
            } as AddVideoForm}
            validateOnBlur={false}
            validateOnChange={true}
            validate={validate}
            onSubmit={this.handleSubmit}
            render={form => (
              <Form className={'c-add-video__modal-form'}>
                <ModalLayout
                  title={'Add video'}
                  footer={
                    <div className={'c-add-video__footer'}>
                      <Button
                        className={'c-add-video__cancel-button'}
                        label={'Cancel'}
                        size={ButtonSize.PILL}
                        theme={ButtonTheme.OUTLINE}
                        onClick={closeModal}
                      />
                      <AddVideoFormButtons
                        form={form}
                        youtubeVideo={youtubeVideo}
                        getYoutubeVideo={getYoutubeVideo}
                      />
                    </div>
                  }
                >
                  <div>
                    <FormInputSelect
                      form={form}
                      label={'Category'}
                      name={videoCategory}
                      collection={getAddVideoCategories()}
                      mapper={key => key.label}
                    />
                    <AddVideoLink
                      youtubeVideoQueryStatus={youtubeVideoQueryStatus}
                      clearYoutubeVideoList={clearYoutubeVideoList}
                      youtubeVideo={youtubeVideo}
                      form={form}
                    />
                  </div>
                </ModalLayout>
              </Form>
            )
            }
          />
        </Modal>
      </>
    );
  }
}
