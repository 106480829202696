type NumberField = number | '';

export enum ImpactAnalysisStatus {
  CREATED = 'created',
  SENT = 'sent',
  DELETED = 'deleted',
}
export interface ConsumptionForm {
  baselineTariffPerKwh: NumberField;
  baselineFeePerMonth: NumberField;
  averageElectricityPriceIncreasePerYear: NumberField;
}

export interface ProductSetupForm {}

export enum CostsFormVersion {
  DETAILED = 'detailed',
  BASIC = 'basic',
}

export interface CostsForm {
  version: CostsFormVersion;
  basic: {
    totalInvestmentCosts: NumberField;
    totalRunningCostsPerYear: NumberField;
  };
  detailed: {
    pvSystemPurchase: NumberField;
    pvSystemInstallation: NumberField,
    inverterAndOtherComponentsPurchase: NumberField,
    batteryPurchase: NumberField;
    batteryInstallation: NumberField
    meterReplacement: NumberField
    otherInstallation: NumberField
    insurancePerYear: NumberField,
    hardwareMaintenancePerYear: NumberField,
    additionalRunningCostsPerYear: NumberField
  };
}

export interface CalculationResultsForm {}

export interface PdfCreationForm {
  pages: {
    tableOfContentsPage: boolean,
    // ---------------------
    productsAndBenefitsPage: boolean,
    // ---------------------
    productsExplanationPage: boolean,
    productsInteractionPage: boolean,
    // ---------------------
    sonnenFlatInfoPage: boolean,
    sonnenFlatContractPage: boolean,
    energyFutureStandardPage: boolean,
    energyFutureSonnenFlatPage: boolean,
    // ---------------------
    financialAnalysisCurrentProviderVsSonnenPage: boolean,
    financialAnalysisDetailedCalculation20YearsPage: boolean,
    financialAnalysisAmortizationChartPage: boolean,
    // ---------------------
    environmentalContributionPage: boolean,
    // ---------------------
    yourSpecialistPage: boolean,
  };
}
