import { CONFIGURATION_SAVE_QUERY } from '+lead-configuration/store';
import { SEND_IMPACT_ANALYSIS_QUERY } from '+lead/+impactAnalysis/store';
import { ImpactAnalysisActions } from '+lead/+impactAnalysis/store/+impactAnalysis.actions';
import {
  getImpactAnalysis,
  getImpactAnalysisRecommendation,
} from '+lead/+impactAnalysis/store/+impactAnalysis.selectors';
import { LEAD_SUBMIT_QUERY } from '+lead/store';
import { DsoRegistrationActions } from '+setupTool/+dsoRegistration/store/+dsoRegistration.actions';
import { DsoRegistrationFormActions } from '+setupTool/+form/store';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { mapErrorToAnalyticsType } from '+setupTool/store/+setupTool.helpers';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { filter, join, map, pipe, toPairs } from 'lodash/fp';
import * as React from 'react';
import { EventCategory, FormEvents, FormName, InteractionEvents, RequestEvents } from '../events';

export const stateSymbol = Symbol('state');

type WithStoreState<T> = T & {
  [stateSymbol]: StoreState;
};

const flattenObject = (formatKey = (prefix: string, key: string) => `${prefix}.${key}`) =>
  (obj: {}, prefix = '') => (
    Object.keys(obj).reduce((acc, key) => {
      const value = obj[key];
      const formattedKey = prefix.length ? formatKey(prefix, key) : key;

      if (typeof value === 'object') {
        Object.assign(acc, flattenObject(formatKey)(value, formattedKey));
        return acc;
      }

      acc[formattedKey] = value;
      return acc;
    }, Object.create(null))
  );

export const createImpactAnalysisToTrackingEvent = ({
  [stateSymbol]: state,
}: WithStoreState<ReturnType<typeof ImpactAnalysisActions.createImpactAnalysis>>) => {
  const impactAnalysis = getImpactAnalysis(state);
  const recommendation = getImpactAnalysisRecommendation(state);

  if (!impactAnalysis || !recommendation) {
    return null;
  }

  const page6n7DisabledLabel = recommendation.isSonnenFlatProfitable
    ? 'page 6 and 7 automatically enabled'
    : 'page 6 and 7 automatically disabled';

  const concatSelectedKeys = pipe(
    toPairs,
    filter(([_, value]) => value),
    map(([key]) => key),
    join(', '),
  );

  const { selectedPages, costs: { version } } = impactAnalysis;
  const selectedPagesLabel = pipe(
    flattenObject((prefix, key) => `${prefix} - ${key}`),
    concatSelectedKeys,
  )(selectedPages);

  return RequestEvents.init({
    category: EventCategory.IMPACT_ANALYSIS,
    requestName: `Create Impact Analysis`,
    labelParts: [version, selectedPagesLabel, page6n7DisabledLabel],
  });
};

export const successQueryToTrackingEvent = ({ queryName }: ReturnType<typeof QueryActions.success>) => {
  switch (queryName) {
    case CONFIGURATION_SAVE_QUERY:
      return RequestEvents.success({
        category: EventCategory.SALES_CONFIGURATOR,
        requestName: 'Save Configuration',
      });
    case LEAD_SUBMIT_QUERY:
      return FormEvents.success({
        category: EventCategory.LEAD_CREATION,
        formName: FormName.LEAD_CREATION,
      });
    case SEND_IMPACT_ANALYSIS_QUERY:
      return RequestEvents.success({
        category: EventCategory.IMPACT_ANALYSIS,
        requestName: 'Create Impact Analysis',
      });
    default:
      return null;
  }
};

export const standardWiringToTrackingEvent =
  (_: ReturnType<typeof DsoRegistrationActions.trackDsoRegistrationStandardWiring>) => {
    return FormEvents.success({
      category: EventCategory.STANDARD_WIRING_DIAGRAM_DSO_REGISTERED,
      formName: FormName.DSO_REGISTRATION,
    });
  };

export const customWiringToTrackingEvent =
  (_: ReturnType<typeof DsoRegistrationActions.trackDsoRegistrationCustomWiring>) => {
    return FormEvents.success({
      category: EventCategory.CUSTOM_WIRING_DIAGRAM_DSO_REGISTERED,
      formName: FormName.DSO_REGISTRATION,
    });
  };

export const setupToolsErrorEvent =
  (action: ReturnType<typeof SetupToolActions.appendError>) => {
    return RequestEvents.error({
      category: EventCategory.SETUP_TOOLS_ERRORS,
      requestName: `Setup Tools - ${mapErrorToAnalyticsType(action.error)}`,
      error: action.error,
    });
  };

export const setupDataCompletedTrackingEvent =
  (_: ReturnType<typeof DsoRegistrationFormActions.trackSetupDataCompleted>) => {
    return FormEvents.success({
      category: EventCategory.SETUP_DATA,
      formName: FormName.SETUP_DATA,
    });
  };
