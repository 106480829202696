import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { CaseCategory } from './types/createCase.interface';

export type SelectItem<T = string> = {
  name: string;
  value: T;
};

export type CategorySelectItem = SelectItem<CaseCategory>;

// Note: should we close this in some object?
export const getCaseCategoryCollection = (): CategorySelectItem[] =>
  [
    {name: I18n.t(T.help.supportForm.fields.categories.serviceActivation), value: CaseCategory.SERVICE_ACTIVATION},
    {name: I18n.t(T.help.supportForm.fields.categories.booking), value: CaseCategory.BOOKING},
    {name: I18n.t(T.help.supportForm.fields.categories.contractDataChange), value: CaseCategory.CONTRACT_DATA_CHANGE},
    {name: I18n.t(T.help.supportForm.fields.categories.invoice), value: CaseCategory.INVOICE},
    {name: I18n.t(T.help.supportForm.fields.categories.meterChange), value: CaseCategory.METER_CHANGE},
    {name: I18n.t(T.help.supportForm.fields.categories.directMarketing), value: CaseCategory.DIRECT_MARKETING},
    {
      name: I18n.t(T.help.supportForm.fields.categories.flexibilityMarketing),
      value: CaseCategory.FLEXIBILITY_MARKETING,
    },
    {name: I18n.t(T.help.supportForm.fields.categories.productInformation), value: CaseCategory.PRODUCT_INFORMATION},
    {name: I18n.t(T.help.supportForm.fields.categories.termination), value: CaseCategory.TERMINATION},
    {name: I18n.t(T.help.supportForm.fields.categories.remaining), value: CaseCategory.REMAINING},
    {name: I18n.t(T.help.supportForm.fields.categories.portal), value: CaseCategory.PORTAL},
  ];
