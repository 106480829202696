import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { DsoRegistrationFile } from '../../components';

import './DsoSummaryFiles.component.scss';

interface Props {
  compact?: boolean;
  pending: boolean;
  documents: any[]; // todo type
}

// todo add isDsoRegistration bool prop for translation labels, classnames?? etc.
export const DsoSummaryFiles: React.FC<Props> = ({ documents, compact, pending }) => {
  const [expanded, setExpanded] = React.useState(documents.length > 0);

  return (
    !!documents.length && !pending ? (
    <div
      className={classNames(`c-dso-registration-summary-files`, {
        'c-dso-registration-summary-files--compact': compact,
        'c-setup-tool-step-list__expanded': expanded,
      })}
    >
      <>
        {compact
          && <div
              className={'c-setup-tool-step-list__trigger'}
              onClick={() => setExpanded(!expanded)}
            >
              <span>
                {I18n.t(T.setupTool.dsoRegistration.setupDataDocuments)}
              </span> <span className={'c-setup-tool-step-list__arrow'} />
            </div>
        }
        {(expanded || !compact) && <div className={'c-dso-registration-summary__container-files'}>
          {documents && documents.map(file => file && file.upload && (
            <DsoRegistrationFile
              key={file.upload.id}
              name={file.fileName}
              downloadLink={file.upload.url}
            />
          ))}
        </div>}
      </>
    </div>) : <Loader />
  );
};
