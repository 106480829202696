import {
  DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY,
  GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY,
} from '+setupTool/+dsoCommissioning/store/+dsoCommissioning.state';
import { getStatus } from '+shared/store/query/query.utils';
import { getDsoCommissioning } from '+shared/store/setupTool/setupTool.selectors';
import { createSelector } from 'reselect';

export const getDsoCommissioningDocuments = createSelector(
  getDsoCommissioning,
  state => state.documents,
);

export const getLatestDsoCommissioningDate = createSelector(
  getDsoCommissioning,
  state => state.updatedAt,
);

export const getLatestDsoCommissioningDocumentsGenerationDate = createSelector(
  getDsoCommissioning,
  state => state.documentsUpdatedAt,
);

export const getDsoCommissioningFields = createSelector(
  getDsoCommissioning,
  state => state.fields,
);

export const getDsoCommissioningPatchSubmissionQuery = createSelector(
  getDsoCommissioning,
  state => state[DSO_COMMISSIONING_PATCH_SUBMISSION_QUERY],
);

export const getDsoCommissioningPatchSubmissionQueryStatus = createSelector(
  getDsoCommissioningPatchSubmissionQuery,
  state => getStatus(state),
);

export const getDsoCommissioningGenerateDocumentsQuery = createSelector(
  getDsoCommissioning,
  state => state[GENERATE_DSO_COMMISSIONING_DOCUMENTS_QUERY],
);

export const getDsoCommissioningGenerateDocumentsQueryStatus = createSelector(
  getDsoCommissioningGenerateDocumentsQuery,
  state => getStatus(state),
);
