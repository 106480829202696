type GoogleTagEventCommon = {
  category?: string;
  action?: string;
  label?: string;
  page?: string;
};

export type GoogleTagEventData = {
  hitType: string;
} & GoogleTagEventCommon;

type GoogleTagDataLayerObject = {
  event: string;
} & GoogleTagEventCommon;

export class GoogleTagManager {
  private dataLayer: GoogleTagDataLayerObject[];

  static create (dataLayerName: string) {
    return new GoogleTagManager(dataLayerName);
  }

  private constructor(dataLayerName: string) {
    if (typeof window[dataLayerName] === 'undefined') {
      window[dataLayerName] = [];
    }

    this.dataLayer = window[dataLayerName];
  }

  track(data: GoogleTagEventData) {
    this.dataLayer.push({
      event: data.hitType,
      category: data.category,
      action: data.action,
      label: data.label,
      page: data.page,
    });
  }
}
