import { DetailListTable, Widget } from '+shared/components';
import { Lead } from '+shared/store/lead/types';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { getLeadEnergyConsumptionDetailsList } from './LeadOverviewEnergyConsumptionDetails.helper';

import './LeadOverviewEnergyConsumptionDetails.component.scss';

interface Props {
  lead: Lead;
}

export const LeadOverviewEnergyConsumptionDetails: React.FC<Props> = ({
  lead,
}) => (
  <Widget
    icon={<Icofont type={'house'} className={'c-lead-overview-energy-consumption__icon'} />}
    heading={I18n.t(T.lead.boc._salessolution_.form.energyConsumptionDetails.headline)}
  >
    <DetailListTable
      list={getLeadEnergyConsumptionDetailsList(lead)}
      filterNil={false}
      lead={lead}
      hasBottomBorder={false}
    />
  </Widget>
);
