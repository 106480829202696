import { mapActions } from '+app/utils';
import {
  ResultsCalculation20Years,
} from '+lead/+impactAnalysis/containers/LeadImpactAnalysisCalculationResults/page-detailed-calculation-20-years';
import { ImpactAnalysisActions } from '+lead/+impactAnalysis/store/+impactAnalysis.actions';
import {
  getCostsForm,
  getImpactAnalysisRecommendation, getImpactAnalysisRecommendationQuery,
} from '+lead/+impactAnalysis/store/+impactAnalysis.selectors';
import { getLeadOfferData } from '+lead/+offer/store/+offer.selectors';
import { getLead } from '+lead/store/+lead.selectors';
import { Form } from '+shared/components';
import { QueryStatus } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Card, PageHeadline, WizardContext } from '@sonnen/shared-web';
import { Loader } from '@sonnen/shared-web/src/components/Loader';
import { Formik } from 'formik';
import { useEffect } from 'react';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadImpactAnalysisWizardFooter } from '../../components/LeadImpactAnalysisWizardFooter';
import { CalculationResultsForm } from '../../store/+impactAnalysis.interface';

const mapDispatchToProps = mapActions({
  getRecommendation: ImpactAnalysisActions.createImpactAnalysisRecommendation,
});

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  offer: getLeadOfferData(state),
  recommendation: getImpactAnalysisRecommendation(state),
  recommendationQuery: getImpactAnalysisRecommendationQuery(state),
  costsForm: getCostsForm(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const  LeadImpactAnalysisCalculationResultsComponent: React.FC<Props> = ({
   actions,
   recommendation,
   recommendationQuery,
   lead,
   offer,
   costsForm,
}) => {
  const wizard = React.useContext(WizardContext);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
    }
  };

  useEffect(() => {
    if (lead && offer && costsForm) {
      actions.getRecommendation(lead.id, offer.id);
    }
  }, []);

  return (
    <Formik<CalculationResultsForm>
      initialValues={{}}
      onSubmit={onSubmit}
      render={form => (
        <Form>
          <Card footerContent={<LeadImpactAnalysisWizardFooter />}>
            <PageHeadline>
              {I18n.t(T.lead.impactAnalysis._salessolution_.results.title)}
            </PageHeadline>

            {recommendation && recommendationQuery.status === QueryStatus.SUCCESS
            ? <ResultsCalculation20Years
                version={costsForm?.version}
                data={recommendation}
              />
            : <Loader />}
          </Card>
        </Form>
      )}
    />
  );
};

export const LeadImpactAnalysisCalculationResults = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeadImpactAnalysisCalculationResultsComponent);
