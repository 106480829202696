import { analysisPointAccessor } from '+app/+customer/+analysis/store/helpers/+analysis.helpers';
import { EnergyUnit } from '+app/App.constants';
import { FeatureName } from '+config';
import { getStatus } from '+shared/store/query/query.utils';
import { formatPower } from '+utils/format.util.old';
import { formatPercentage } from '+utils/format.util.old';
import {
  AreaChartView,
  AxisOrientation,
  DataContainer,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  DataContainerTransformExtensionOptions,
  GridLines,
  PointAccessor,
} from '@kanva/charts';
import {
  AreaChartView as AreaChartViewComponent,
  AxisView,
  ChartGridView,
  ChartZoomView,
  LineChartView as LineChartViewComponent,
} from '@kanva/charts-react';
import { rgba, Visibility } from '@kanva/core';
import { View } from '@kanva/core';
import { Kanva, View as ViewComponent } from '@kanva/react';
import {
  BASE_TICK_COUNT,
  BatteryStatusesKey,
  EnergyFlowSeriesKey,
  Feature,
  Loader,
  SharedChartColors,
  TimeHelper,
  useFeature,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { DeepPartial } from 'redux';
import { resolveAreaXPosition } from '../../containers/CustomerAnalysisEnergyFlow/CustomerAnalysisEnergyFlow.helper';
import { mapDataSeries } from '../../store/helpers/+analysisStatistics.helpers';
import { ChartDataSeries, ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import './AnalysisDayChart.component.scss';
import { layout, Views } from './AnalysisDayChart.layout';
import { LineSeriesStyle } from './AnalysisDayChart.styles';
import {
  AreaSeriesStyle,
  chartGridStyle,
  selectAreaZoomStyle,
  xAxisStyle,
  yAxisLeftStyle,
  yAxisRightStyle,
} from './AnalysisDayChart.styles';

interface Props {
  selectedDate: Date;
  dataSeries: ChartDataSeries;
  measurementsQueryStatus: ReturnType<typeof getStatus>;
  customerQueryStatus: ReturnType<typeof getStatus>;
  selectedDataSeriesKeys: ChartDataSeriesKey[];
  tooltipExtension: DataContainerTooltipExtension;
  transformExtension: DataContainerTransformExtension;
  scaleOptions: DeepPartial<DataContainerTransformExtensionOptions>;
  isChartZoomed: boolean;
  onMount: (x: number) => (view: View<any>) => void;
}

interface State {
  scale: number;
}

export class AnalysisDayChart extends React.PureComponent<Props, State> {
  readonly state: State = { scale: 0 };

  private readonly defaultDataContainer = new DataContainer()
    .setXAxisParameters({
      labelAccessor: TimeHelper.formatUnixToMinutes,
      roundTo: 5,
      tickCount: BASE_TICK_COUNT,
    })
    .setYAxisParameters({
      labelAccessor: value => formatPower(value, EnergyUnit.KW, 2),
      roundTo: 25,
      tickCount: BASE_TICK_COUNT,
    })
    .setXBoundsExtension([0, 24 * 60])
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  private readonly percentageDataContainer = new DataContainer()
    .setYBoundsExtension([0, 100])
    .setXBoundsExtension([0, 24 * 60])
    .setYAxisParameters({
      labelAccessor: value => formatPercentage(value),
      roundTo: 5,
      tickCount: BASE_TICK_COUNT,
    })
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  constructor(props: Props) {
    super(props);
    this.defaultDataContainer.addExtension(props.transformExtension);
  }

  isVisible = (key: ChartDataSeriesKey) =>
    this.props.selectedDataSeriesKeys.includes(key)
      ? Visibility.VISIBLE
      : Visibility.INVISIBLE;

  setDataSeries(dataSeries: ChartDataSeries) {
    this.defaultDataContainer.setData(mapDataSeries({
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER],
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: dataSeries[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER],
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: dataSeries[EnergyFlowSeriesKey.BATTERY_CHARGING],
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: dataSeries[EnergyFlowSeriesKey.BATTERY_DISCHARGING],
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: dataSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: dataSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
      [BatteryStatusesKey.VPP_ACTIVITY]: dataSeries[BatteryStatusesKey.VPP_ACTIVITY],
    }));

    this.percentageDataContainer.setData(mapDataSeries({
      [EnergyFlowSeriesKey.BATTERY_USOC]: dataSeries[EnergyFlowSeriesKey.BATTERY_USOC],
    }));
  }

  handleMount = (view: View<any>) => {
    const { onMount, selectedDate } = this.props;
    const position = resolveAreaXPosition(EnergyFlowSeriesKey.CONSUMPTION_POWER)
      (this.defaultDataContainer, selectedDate);
    const { absoluteX } = (view as AreaChartView).getCanvasPositionForPoint({ x: position, y: 0 });
    onMount(absoluteX)(view);
  };

  componentDidMount() {
    const { tooltipExtension, scaleOptions, transformExtension } = this.props;

    this.setDataSeries(this.props.dataSeries);
    transformExtension.setOptions(scaleOptions);
    this.defaultDataContainer.addExtension(tooltipExtension, transformExtension);
    this.percentageDataContainer.addExtension(tooltipExtension, transformExtension);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { dataSeries } = this.props;
    if (prevProps.dataSeries !== dataSeries) {
      this.setDataSeries(dataSeries);
    }
  }

  componentWillUnmount() {
    const { tooltipExtension, transformExtension } = this.props;

    this.defaultDataContainer.removeExtension(tooltipExtension, transformExtension);
    this.percentageDataContainer.removeExtension(tooltipExtension, transformExtension);
  }

  render() {
    const { measurementsQueryStatus, customerQueryStatus, isChartZoomed } = this.props;
    const { isEnabled: isBatteryVppActivityEnabled } = useFeature(FeatureName.BATTERY_VPP_ACTIVITY);

    return (
      <div className={'c-analysis-day-chart'}>
        <Kanva
          className={classNames('c-analysis-day-chart__canvas', {
            'c-analysis-day-chart__canvas--with-line-chart': isBatteryVppActivityEnabled,
          })}
          enablePointerEvents={true}
        >
          <ViewComponent layoutParams={layout.wrapper}>
            <ChartGridView
              layoutParams={layout.chartGrid}
              dataContainer={this.defaultDataContainer}
              gridLines={GridLines.HORIZONTAL}
              style={chartGridStyle}
            />
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.defaultDataContainer}
              dataSeries={EnergyFlowSeriesKey.PRODUCTION_POWER}
              visibility={this.isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
              style={AreaSeriesStyle.productionPower}
              onMount={this.handleMount}
            />
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.defaultDataContainer}
              dataSeries={EnergyFlowSeriesKey.CONSUMPTION_POWER}
              visibility={this.isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
              style={AreaSeriesStyle.consumptionPower}
            />
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.defaultDataContainer}
              dataSeries={EnergyFlowSeriesKey.DIRECT_USAGE_POWER}
              visibility={this.isVisible(EnergyFlowSeriesKey.DIRECT_USAGE_POWER)}
              style={AreaSeriesStyle.directUsagePower}
            />
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.percentageDataContainer}
              dataSeries={EnergyFlowSeriesKey.BATTERY_USOC}
              visibility={this.isVisible(EnergyFlowSeriesKey.BATTERY_USOC)}
              style={AreaSeriesStyle.batteryUsoc}
            />
            <Feature name={FeatureName.BATTERY_CHARGING_DISCHARGING}>
              <AreaChartViewComponent
                layoutParams={layout.chart}
                dataContainer={this.defaultDataContainer}
                dataSeries={EnergyFlowSeriesKey.BATTERY_CHARGING}
                visibility={this.isVisible(EnergyFlowSeriesKey.BATTERY_CHARGING)}
                style={AreaSeriesStyle.batteryCharging}
              />
              <AreaChartViewComponent
                layoutParams={layout.chart}
                dataContainer={this.defaultDataContainer}
                dataSeries={EnergyFlowSeriesKey.BATTERY_DISCHARGING}
                visibility={this.isVisible(EnergyFlowSeriesKey.BATTERY_DISCHARGING)}
                style={AreaSeriesStyle.batteryDischarging}
              />
            </Feature>
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.defaultDataContainer}
              dataSeries={EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER}
              visibility={this.isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
              style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]}
            />
            <AreaChartViewComponent
              layoutParams={layout.chart}
              dataContainer={this.defaultDataContainer}
              dataSeries={EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER}
              visibility={this.isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
              style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]}
            />
          </ViewComponent>
          <ChartZoomView
            dataContainer={this.defaultDataContainer}
            layoutParams={layout.wrapper}
            style={selectAreaZoomStyle}
          />
          <AxisView
            id={Views.X_AXIS}
            border={{ top: 3 }}
            borderColor={rgba(SharedChartColors.DARK, .1)}
            layoutParams={layout.xAxis}
            dataContainer={this.defaultDataContainer}
            orientation={AxisOrientation.HORIZONTAL}
            style={{ ...xAxisStyle, wrapLabelsOnEdge: !isChartZoomed }}
          />
          <AxisView
            id={Views.Y_AXIS_LEFT}
            border={{ left: 3 }}
            borderColor={rgba(SharedChartColors.DARK, .1)}
            layoutParams={layout.yAxisLeft}
            dataContainer={this.defaultDataContainer}
            orientation={AxisOrientation.VERTICAL}
            style={yAxisLeftStyle}
          />
          <AxisView
            id={Views.Y_AXIS_RIGHT}
            layoutParams={layout.yAxisRight}
            dataContainer={this.percentageDataContainer}
            orientation={AxisOrientation.VERTICAL}
            style={yAxisRightStyle}
            visibility={this.percentageDataContainer.getTotal() !== 0 ? Visibility.VISIBLE : Visibility.INVISIBLE}
          />
          <Feature name={FeatureName.BATTERY_VPP_ACTIVITY}>
            <ViewComponent
              layoutParams={layout.lineWrapper}
              id={Views.LINE_WRAPPER}
            >
              <LineChartViewComponent
                layoutParams={layout.lineChartLine}
                dataContainer={this.defaultDataContainer}
                dataSeries={BatteryStatusesKey.VPP_ACTIVITY}
                visibility={this.isVisible(BatteryStatusesKey.VPP_ACTIVITY)}
                style={LineSeriesStyle[BatteryStatusesKey.VPP_ACTIVITY]}
              />
            </ViewComponent>
          </Feature>
        </Kanva>
        <div className={classNames('c-analysis-day-chart__loader-container', {
          'is-active': measurementsQueryStatus.pending || customerQueryStatus.pending,
        })}>
          <Loader className={'c-analysis-day-chart__loader'} />
        </div>
      </div>
    );
  }
}
