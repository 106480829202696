import { T } from '@sonnen/shared-i18n/service';
import * as moment from 'moment';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import './DashboardGreeting.component.scss';

interface Props {
  userName: string;
  city: string;
  countryCode: string;
}

export const DashboardGreeting: React.FC<Props> = ({ userName, city, countryCode }) => {
  const dateString = moment().format('Do MMMM YYYY');

  return (
    <div className={'dashboard-greeting__container'}>
      <p className={'dashboard-greeting__hello'}>{I18n.t(T.dashboard.greeting.hello, { userName })}</p>
      <p>
        <span className={'dashboard-greeting__date'}>{dateString}</span>
        <span className={'dashboard-greeting__location'}>{`${city}, ${countryCode}`}</span>
      </p>
    </div>
  );
};
