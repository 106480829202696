import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './LeadOverviewRecommendLead.component.scss';

export const LeadOverviewRecommendLead: React.FC = () => {
  const { track } = useTracking();

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    track(InteractionEvents.buttonClick({
      category: EventCategory.LEAD_DETAILS,
      action: ActionName.RECOMMEND_LEAD_BUTTON_CLICKED,
      event,
    }));
  };

  return (
    <>
      <p className={'c-overview-recommend-lead__paragraph'}>
        {I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.text)}
      </p>
      <div className={'c-overview-recommend-lead__button-wrapper'}>
        <a
          target={'_blank'}
          href={I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.link)}
        >
          <Button
            label={I18n.t(T.lead.overview._salessolution_.leadManager.recommendLead.button)}
            theme={ButtonTheme.PRIMARY}
            size={ButtonSize.SECONDARY}
            onClick={onButtonClick}
          />
        </a>
      </div>
    </>
  );
};
