import { GET_LEAD_QUERY, LEAD_UPDATE_QUERY } from '+app/+lead/store';
import { LeadPageActions } from '+app/+lead/store/+lead.actions';
import { hasFlatOfferAcceptedStatus, isTsoAssigned } from '+app/+lead/store/+lead.helper';
import { NotFound } from '+app/+static';
import { LeadRouteParams, ROUTES } from '+app/router';
import { RouterActions } from '+app/router/store';
import { CountryFeatureName } from '+config/countryFlags';
import { ImpactAnalysisActions } from '+lead/+impactAnalysis/store/+impactAnalysis.actions';
import { LeadListAssignModal } from '+lead/+list/components/LeadListAssignModal';
import { LeadOverviewConfigurations } from '+lead/+overview';
import { hasFullDeliveryAddress } from '+lead/+overview/containers/LeadOverviewBasicDetails';
import {
  LeadOverviewDetailsWidgets,
} from '+lead/+overview/containers/LeadOverviewDetailsWidgets';
import { SEND_CG_INVITATION_QUERY } from '+lead/+overview/store';
import { LeadInvalidManualAddressModal } from '+lead/containers/LeadManualAddressModal';
import { hasFlatOfferSentStatus, hasNotAcceptedOfferYet } from '+lead/store/+lead.helper';
import {
  getAssignLeadQueryStatus,
  getAsyncLeadStatusToBeSaved,
  getIsAssignLeadModalOpen,
  getLead,
  getLeadQueryStatus,
  getLeadUpdateQueryStatus,
  getPartnerEmployeeList,
  getSelectedLead,
  getValidateLeadAddressQueryStatus,
} from '+lead/store/+lead.selectors';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { VppDocumentationActions } from '+setupTool/+vppDocumentation/store';
import { SetupTool } from '+setupTool/index';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { Container } from '+shared/components';
import { isRolloutLimitedCountryFeatureEnabled } from '+shared/components/RolloutLimiter/RolloutLimiter.helper';
import { Tutorial } from '+shared/containers/Tutorial';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { LeadActions } from '+shared/store/lead';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { QueryActions } from '+shared/store/query';
import { StoreState } from '+shared/store/store.interface';
import {
  getUserProfileQueryStatus,
  getUserProfileRoles,
  getUserProfileSalesforceContactId,
} from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { Alert, AlertTheme, Loadable, Loader } from '@sonnen/shared-web';
import { find, get, getOr, has, isEmpty } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Route, RouteComponentProps, Switch } from 'react-router';
import {
  LeadOverviewActionFailedModal,
  LeadOverviewDocumentModal,
  LeadOverviewHeader,
} from '../../components';
import { LeadOverviewEmailChangedModal } from '../../components/LeadOverviewEmailChangedModal';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import {
  isDsoValidationPostponed,
  removeDsoValidationPostponed,
} from '../../store/+overview.helper';
import {
  getCloseLeadQueryStatus,
  getDocumentUrl,
  getIsFailingDocument,
  getLastUpdatedLeadSection,
  getLeadOverviewProductAvailability,
  getLeadOverviewProductAvailabilityForAddressStatus,
  getLeadOverviewProductAvailabilityStatus,
  getNewAddressErrorActivity,
  getOfferConfigurationList,
  getSendCGInvitationQueryStatus,
  isOpeningDocument,
} from '../../store/+overview.selectors';
import { LeadOverviewDsoValidationModal } from '../LeadOverviewDsoValidationModal';

import './LeadOverview.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getLead(state),
  leadQueryStatus: getLeadQueryStatus(state),
  leadUpdateQueryStatus: getLeadUpdateQueryStatus(state),
  closeLeadQueryStatus: getCloseLeadQueryStatus(state),
  lastUpdatedLeadSection: getLastUpdatedLeadSection(state),
  isOpeningDocument: isOpeningDocument(state),
  isFailingDocument: getIsFailingDocument(state),
  documentUrl: getDocumentUrl(state),
  configurations: getOfferConfigurationList(state),
  partnerEmployeeList: getPartnerEmployeeList(state),
  userRoles: getUserProfileRoles(state),
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
  userProfileQueryStatus: getUserProfileQueryStatus(state),
  chosenLead: getSelectedLead(state),
  isAssignLeadModalOpen: getIsAssignLeadModalOpen(state),
  userProfileSalesforceContactId: getUserProfileSalesforceContactId(state),
  assignLeadQueryStatus: getAssignLeadQueryStatus(state),
  validateLeadAddressQueryStatus: getValidateLeadAddressQueryStatus(state),
  sendCGInvitationQueryStatus: getSendCGInvitationQueryStatus(state),
  isNewAddressErrorActive: getNewAddressErrorActivity(state),
  productAvailability: getLeadOverviewProductAvailability(state),
  productAvailabilityQueryStatus: getLeadOverviewProductAvailabilityStatus(state),
  productAvailabilityForAddressStatus: getLeadOverviewProductAvailabilityForAddressStatus(state),
  asyncLeadStatusToBeSaved: getAsyncLeadStatusToBeSaved(state),
});

const mapDispatchToProps = mapActions({
  goBackToLeads: RouterActions.goBackToLeads,
  getLead: LeadActions.getLead,
  clearOffers: LeadOverviewPageActions.clearOffers,
  clearQuery: QueryActions.init,
  clearLeadData: LeadPageActions.clearLeadData,
  documentOpened: LeadOverviewPageActions.documentOpened,
  markAsSeen: LeadPageActions.markLeadAsSeen,
  getPartnersEmployeeList: LeadPageActions.getPartnersEmployeeList,
  toggleModal: LayoutActions.toggleModal,
  setChosenLead: LeadPageActions.setSelectedLead,
  toggleAssignLeadModal: LeadPageActions.toggleAssignLeadModal,
  reassignPartnerToLead: LeadPageActions.reassignPartnerToLead,
  closeDocumentFailed: LeadOverviewPageActions.closeDocumentFailed,
  createDocument: LeadOverviewPageActions.createDocument,
  getImpactAnalysisList: ImpactAnalysisActions.getImpactAnalysisList,
  validateLeadAddress: LeadPageActions.validateLeadAddress,
  clearLeadAddress: LeadPageActions.validateLeadAddressClear,
  sendCGInvitation: LeadOverviewPageActions.sendCGInvitation,
  getProductAvailability: LeadOverviewPageActions.getProductAvailability,
  clearProductAvailability: LeadOverviewPageActions.clearProductAvailability,
  updateLead: LeadPageActions.updateLead,
  setNewAddressErrorActive: LeadOverviewPageActions.setNewAddressErrorActive,
  setAsyncLeadFlatStatusOfferSent: LeadPageActions.setAsyncFlatOfferSentStatus,
  getSetupToolSubmissionData: SetupToolActions.getSetupToolSubmissionData,
  clearSetupToolsForm: DsoRegistrationFormActions.clearData,
  clearSetupToolsConfigurationData: SetupToolActions.clearConfigurationData,
  setSubmissionId: SetupToolActions.setSubmissionId,
  clearVppDocumentationData: VppDocumentationActions.setInitialState,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps<LeadRouteParams>
  ;

const LeadOverviewComponent: React.FC<Props> = ({
  actions,
  lead,
  leadQueryStatus,
  match,
  leadUpdateQueryStatus,
  closeLeadQueryStatus,
  lastUpdatedLeadSection,
  isOpeningDocument,
  isFailingDocument,
  documentUrl,
  configurations,
  partnerEmployeeList,
  userRoles,
  isModalOpen,
  openModalId,
  userProfileQueryStatus,
  chosenLead,
  isAssignLeadModalOpen,
  userProfileSalesforceContactId,
  assignLeadQueryStatus,
  validateLeadAddressQueryStatus,
  sendCGInvitationQueryStatus,
  isNewAddressErrorActive,
  productAvailability,
  productAvailabilityQueryStatus,
  productAvailabilityForAddressStatus,
  asyncLeadStatusToBeSaved,
}) => {
  const firstLeadUpdate = React.useRef(true);
  const [sendingLinkModalActive, setSendingLinkModalActive] = React.useState<boolean>(false);

  React.useEffect(() => {
    actions.clearProductAvailability();
    actions.getLead(match.params.leadId, GET_LEAD_QUERY);

    // TODO: remove it and make SetupTool container responsible for such actions
    if (window.location.pathname.includes('setup-tool') && isRolloutLimitedCountryFeatureEnabled(CountryFeatureName.SETUP_PHASE)) {
      actions.getSetupToolSubmissionData(match.params.leadId);
    }

    return () => {
      actions.clearQuery(LEAD_UPDATE_QUERY);
      actions.clearQuery(SEND_CG_INVITATION_QUERY);
      actions.clearOffers();
      actions.clearLeadAddress();
      actions.clearLeadData();
      actions.setNewAddressErrorActive(false);

      // Clear Setup Tools data
      actions.clearSetupToolsForm();
      actions.clearSetupToolsConfigurationData(),
      actions.setSubmissionId(undefined);
      actions.clearVppDocumentationData();
    };
  }, []);

  React.useEffect(() => {
    if (lead && lead.isNewlyAssigned) {
      actions.markAsSeen();

      if (lead.deliveryAddress) {
        actions.validateLeadAddress(lead.deliveryAddress);
      }
    }
  }, [lead && lead.isNewlyAssigned]);

  React.useEffect(() => {
    if (sendCGInvitationQueryStatus.error) {
      setSendingLinkModalActive(true);
    }
  }, [sendCGInvitationQueryStatus]);

  React.useEffect(() => {
    if (lead && lead.deliveryAddress &&
      hasFullDeliveryAddress({ deliveryAddress: lead.deliveryAddress }) &&
      !hasFlatOfferAcceptedStatus(lead)
    ) {
      actions.getProductAvailability();
    }
  }, [leadQueryStatus]);

  React.useEffect(() => {
    if (leadQueryStatus.success && asyncLeadStatusToBeSaved) {
      actions.setAsyncLeadFlatStatusOfferSent();
    }
  }, [leadQueryStatus]);

  React.useEffect(() => {
    // multiple german TSO won't be chosen from and therefore won't be saved into SF
    // so in order to stop the infinite loop of updating lead we should only update it once
    if (!firstLeadUpdate.current) {
      return;
    }

    // @NOTE: automatic dso&tso assign, when just one is available
    // if there is multiple tso, just dso may be updated
    // if multiple dso -> we can't patch tso only
    if (
      lead && (!lead.dso || !lead.tso || isEmpty(lead.tso.name)) &&
      lead.deliveryAddress &&
      !hasFlatOfferAcceptedStatus(lead) &&
      (productAvailability.length === 1)
    ) {
      const tso = productAvailability[0].tsos.length === 1 ? productAvailability[0].tsos[0] : null;
      const deliveryAddress = lead.deliveryAddress;

      const isTheSameData = (productAvailability[0].id === get('dso.id', lead) && !tso && !isTsoAssigned(lead.tso));
      if (isTheSameData) {
        return;
      }
      actions.updateLead({
        dso: { id: productAvailability[0].id },
        tso,
        deliveryAddress,
      });
      firstLeadUpdate.current = false;
    }
  }, [productAvailability]);

  React.useEffect(() => {
    if (lead && has('dso', lastUpdatedLeadSection)) {
      removeDsoValidationPostponed(lead.id);
      actions.getProductAvailability();
    }
  }, [lastUpdatedLeadSection]);

  const hasConfigurations = () => configurations.length > 0;

  const isNewOfferSentAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && hasFlatOfferSentStatus(lead)
    && hasNotAcceptedOfferYet(lead) && (
      has('firstName', lastUpdatedLeadSection)
      || has('phone', lastUpdatedLeadSection)
      || has('invoiceAddress', lastUpdatedLeadSection));

  const isSalesConfiguratorRecalculatedAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && !hasFlatOfferSentStatus(lead)
    && has('deliveryAddress', lastUpdatedLeadSection);

  const isSalesConfiguratorRecalculatedOfferSentAlert = () => leadUpdateQueryStatus.success
    && hasConfigurations()
    && hasFlatOfferSentStatus(lead)
    && has('deliveryAddress', lastUpdatedLeadSection);

  const alertMsgs = [
    {
      condition: isNewOfferSentAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.newOfferSent),
      theme: AlertTheme.INFO,
    },
    {
      condition: isSalesConfiguratorRecalculatedAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.salesConfiguratorRecalculated),
      theme: AlertTheme.INFO,
    },
    {
      condition: isSalesConfiguratorRecalculatedOfferSentAlert(),
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.salesConfiguratorRecalculatedOfferSent),
      theme: AlertTheme.INFO,
    },
    {
      condition: isNewAddressErrorActive,
      msg: I18n.t(T.lead.overview._salessolution_.leadUpdateMsg.newAddressError),
      theme: AlertTheme.ERROR,
    },
  ];

  const isAlertOpen = () => isNewOfferSentAlert()
    || isSalesConfiguratorRecalculatedAlert()
    || isSalesConfiguratorRecalculatedOfferSentAlert()
    || isNewAddressErrorActive;

  const retrieveIsSet = (status?: { isSet?: boolean } | null) => !!(status && status.isSet);

  const shouldRenderDsoValidationModal = () => !!lead
    && productAvailability.length > 1
    && !lead.dso
    && !hasFlatOfferAcceptedStatus(lead)
    && !isDsoValidationPostponed(lead.id)
    || openModalId === ModalId.DSO_VALIDATION;

  return (
    <Loadable predicate={leadQueryStatus.pending
      || userProfileQueryStatus.pending
      || validateLeadAddressQueryStatus.pending
    }>
      {leadQueryStatus.error || !lead || (
        retrieveIsSet(lead && lead.status.summary[LeadStatusName.IN_SETUP])) ? (
          <NotFound />
        ) : (
          <div className={'c-lead-overview'}>
            <Alert
              message={getOr('', 'msg', find((alertMsg) => alertMsg.condition, alertMsgs))}
              isOpen={isAlertOpen()}
              theme={get('theme', find((alertMsg) => alertMsg.condition, alertMsgs))}
            />
            <Alert
              message={I18n.t(T.lead.overview._salessolution_.invitationAlert)}
              isOpen={sendCGInvitationQueryStatus.success}
            />
            <LeadOverviewHeader
              lead={lead}
              breadcrumbAction={actions.goBackToLeads}
              partnerEmployeeList={partnerEmployeeList}
              getPartnersEmployeeList={actions.getPartnersEmployeeList}
              userRoles={userRoles}
              toggleModal={actions.toggleModal}
              isModalOpen={openModalId === ModalId.LEAD_EDIT_STATUS}
              toggleAssignLeadModal={actions.toggleAssignLeadModal}
              setChosenLead={actions.setChosenLead}
              sendCGInvitation={actions.sendCGInvitation}
              sendCGInvitationQueryStatus={sendCGInvitationQueryStatus}
            />
            <Switch>
              <Route path={ROUTES.LEAD_SETUP_TOOL} component={SetupTool} />
              <Route path={ROUTES.LEAD_CONFIGURATION} render={() => (
                <Container withVerticalPadding={true}>
                  <div className={'o-grid'}>
                    <section className={'o-grid__column o-grid__column--md-12'}>
                      <LeadOverviewConfigurations />
                    </section>
                  </div>
                </Container>
              )} />
              <Route path={match.path} render={() => (
                <Container withVerticalPadding={true}>
                  {(closeLeadQueryStatus.pending
                    || leadUpdateQueryStatus.pending
                    || productAvailabilityQueryStatus.pending
                    || productAvailabilityForAddressStatus.pending) && (
                      <Loader className={'c-lead-overview__loader'} />
                    )}
                  <Tutorial id={'leadDetails'} />
                  <LeadOverviewDetailsWidgets lead={lead} />
                </Container>
              )} />
            </Switch>
            {shouldRenderDsoValidationModal() &&
              <LeadOverviewDsoValidationModal
                lead={lead}
              />
            }
            <LeadOverviewDocumentModal
              isOpeningDocument={isOpeningDocument}
              documentUrl={documentUrl}
              onDocumentOpen={() => actions.documentOpened()}
            />
            <LeadListAssignModal
              isModalOpen={isModalOpen && isAssignLeadModalOpen}
              toggleAssignLeadModal={actions.toggleAssignLeadModal}
              toggleModal={actions.toggleModal}
              chosenLead={chosenLead}
              partnerEmployeeList={partnerEmployeeList}
              assignPartner={actions.reassignPartnerToLead}
              userProfileSalesforceContactId={userProfileSalesforceContactId}
              isPending={assignLeadQueryStatus.pending}
              isError={assignLeadQueryStatus.error}
            />
            <LeadInvalidManualAddressModal />
            <LeadOverviewActionFailedModal
              isFailingDocument={isFailingDocument}
              handleClick={() => {
                actions.createDocument(lead.id);
                actions.getImpactAnalysisList(lead.id);
              }}
              handleClose={() => actions.closeDocumentFailed()}
              headline={I18n.t(T.lead.overview._salessolution_.documentErrorModal.heading)}
              description={I18n.t(T.lead.overview._salessolution_.documentErrorModal.content)}
            />
            <LeadOverviewActionFailedModal
              isFailingDocument={sendingLinkModalActive}
              handleClick={() => {
                setSendingLinkModalActive(false);
                actions.sendCGInvitation(lead.id);
              }}
              handleClose={() => setSendingLinkModalActive(false)}
              headline={I18n.t(T.lead.overview._salessolution_.linkErrorModal.heading)}
              description={I18n.t(T.lead.overview._salessolution_.linkErrorModal.content)}
            />
            <LeadOverviewEmailChangedModal
              isModalOpen={openModalId === ModalId.EMAIL_CHANGED}
              handleClose={() => actions.toggleModal(false)}
              handleConfirm={() => { actions.sendCGInvitation(lead.id); actions.toggleModal(false); }}
              email={lead.email}
            />
          </div>
        )}
    </Loadable>
  );
};

export const LeadOverview = connect(mapStateToProps, mapDispatchToProps)(LeadOverviewComponent);
