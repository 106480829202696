import { LeadRouteParams, PATHS } from '+app/router';
import { getLeadId } from '+lead/+overview/store/+overview.selectors';
import {
  DsoRegistrationCustomerData,
  DsoRegistrationDocuments,
  DsoRegistrationInstallerData,
  DsoRegistrationMeasuringDevice,
  DsoRegistrationPvSystem,
  DsoRegistrationSonnenBattery,
} from '+setupTool/+form';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { getCompletedSteps } from '+setupTool/+form/store/+form.helpers';
import {
  getBatteryNames,
  getDsoList,
  getInstallers,
  getPVInverters,
  getPVModules,
  getPvSystemForm,
  getSetupDataStatuses,
  getSonnenBatteryForm,
} from '+setupTool/+form/store/+form.selectors';
import { ErrorBox } from '+setupTool/components/ErrorBox';
import { Overlay, Ref } from '+setupTool/components/Overlay';
import { getErrors, getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { FormBanner } from '+shared/components';
import { PageView } from '+shared/GoogleTagManager';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/index';
import { T } from '@sonnen/shared-i18n/service';
import { FormWizardHeader, Icon, Wizard, WizardStep } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import './DsoRegistrationForm.component.scss';

const OfferWizard = withRouter(Wizard);

interface OwnProps {
  leadStage?: string;
}

const mapStateToProps = (state: StoreState) => ({
  leadId: getLeadId(state),
  errors: getErrors(state),
  stepsStatuses: getSetupDataStatuses(state),
  registrationSubject: getRegistrationSubject(state),
  dsoList: getDsoList(state),
  pvModules: getPVModules(state),
  pvSystemForm: getPvSystemForm(state),
  pvInverters: getPVInverters(state),
  batteryNames: getBatteryNames(state),
  sonnenBatteryForm: getSonnenBatteryForm(state),
  installersList: getInstallers(state),
});

const mapDispatchToProps = mapActions({
  goToSetupDashboard: (leadId: string, leadStage?: string) => push(PATHS.SETUP_TOOL({ leadId }, leadStage)),
  toggleModal: LayoutActions.toggleModal,
  getDsoList: DsoRegistrationFormActions.getDsoList,
  getPVModuleVendors: DsoRegistrationFormActions.getPVModuleVendors,
  getPVModules: DsoRegistrationFormActions.getPVModules,
  getPVInverterVendors: DsoRegistrationFormActions.getPVInverterVendors,
  getPVInverters: DsoRegistrationFormActions.getPVInverters,
  getBatteryNames: DsoRegistrationFormActions.getBatteryNames,
  getBatteries: DsoRegistrationFormActions.getBatteries,
  getInstallers: DsoRegistrationFormActions.getInstallers,
});

type RouteProps = RouteComponentProps<LeadRouteParams>;

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteProps
  & OwnProps
  ;

export const DsoRegistrationFormComponent: React.FC<Props> = ({
  actions,
  leadId,
  errors,
  leadStage,
  stepsStatuses,
  registrationSubject,
  dsoList,
  pvModules,
  pvSystemForm,
  pvInverters,
  batteryNames,
  sonnenBatteryForm,
  installersList,
}) => {
  const overlayRef = React.useRef<Ref>(null);
  const completedSteps = getCompletedSteps(stepsStatuses, registrationSubject);

  React.useEffect(() => {
    actions.toggleModal(true);

    if (!dsoList.length) {
      actions.getDsoList();
    }

    if (!pvModules.length) {
      actions.getPVModuleVendors();
      if (pvSystemForm && pvSystemForm.pv_module_type) {
        actions.getPVModules(pvSystemForm.pv_module_type);
      }
    }

    if (!pvInverters.length) {
      actions.getPVInverterVendors();
      if (pvSystemForm && pvSystemForm.pv_inverter_type) {
        actions.getPVInverters(pvSystemForm.pv_inverter_type);
      }
      if (pvSystemForm && pvSystemForm.existing_pv_inverter_manufacturer) {
        actions.getPVInverters(pvSystemForm.existing_pv_inverter_manufacturer);
      }
    }

    if (!batteryNames.length) {
      actions.getBatteryNames();
      if (sonnenBatteryForm && sonnenBatteryForm.battery_name) {
        actions.getBatteries(sonnenBatteryForm.battery_name);
      }
    }

    if (!installersList || !installersList.length) {
      actions.getInstallers();
    }

    return () => {
      actions.toggleModal(false);
    };
  }, []);

  const handleRef = () => {
    if (overlayRef && overlayRef.current) {
      overlayRef.current.scrollTo(0, 0);
    }
  };

  return (
    <Overlay
      className={'c-setup-tool-form-wizard'}
      title={I18n.t(T.setupTool.setupData)}
      close={() => actions.goToSetupDashboard(leadId!, leadStage)}
      ref={overlayRef}
    >
      <OfferWizard
        header={<>
          <FormWizardHeader
            className={'c-setup-tool-form-wizard__header'}
            markOnlyCompletedSteps={true}
            completedSteps={completedSteps}
          />
          <ErrorBox errors={errors} />
        </>}
        onNextStep={handleRef}
        onPrevStep={handleRef}
        onJumpToStep={handleRef}
        isThemeClickable={true}
      >
        <WizardStep
          name={I18n.t(T.setupTool.step.customerData)}
          canAccess={true}
        >
          <DsoRegistrationCustomerData stepStatus={stepsStatuses.customerData}/>
          <PageView page={'/vpv-setup-data-customer-data'} />
        </WizardStep>
        <WizardStep
          name={registrationSubject === RegistrationSubjectType.BATTERY
            ? I18n.t(T.setupTool.step.pvSystemBatteryOnly)
            : I18n.t(T.setupTool.step.pvSystem)}
          canAccess={true}
        >
          <DsoRegistrationPvSystem stepStatus={stepsStatuses.pvSystem}/>
          <PageView page={'/vpv-setup-data-pv-anlage'} />
        </WizardStep>
        <WizardStep
          name={I18n.t(T.setupTool.step.sonnenBattery)}
          canAccess={true}
        >
          <DsoRegistrationSonnenBattery stepStatus={stepsStatuses.sonnenBattery}/>
          <PageView page={'/vpv-setup-data-batteries'} />
        </WizardStep>
        <WizardStep
          name={I18n.t(T.setupTool.step.measuringDevice)}
          canAccess={true}
        >
          <DsoRegistrationMeasuringDevice stepStatus={stepsStatuses.measuringDevice}/>
          <PageView page={'/vpv-setup-data-measuring-device'} />
        </WizardStep>
        <WizardStep
          name={I18n.t(T.setupTool.step.upload)}
          canAccess={true}
        >
          <DsoRegistrationDocuments stepStatus={stepsStatuses.uploadDocuments}/>
          <PageView page={'/vpv-setup-data-upload'} />
        </WizardStep>
        <WizardStep
          name={I18n.t(T.setupTool.step.installerData)}
          canAccess={true}
        >
          <DsoRegistrationInstallerData
            leadStage={leadStage}
            stepStatus={stepsStatuses.installerData}
          />
          <PageView page={'/vpv-setup-data-installers'} />
        </WizardStep>
      </OfferWizard>
    </Overlay>
  );
};

export const DsoRegistrationForm = connect(mapStateToProps, mapDispatchToProps)(DsoRegistrationFormComponent);
