import { FormCheckbox as SharedCheckbox } from '@sonnen/shared-web';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { validateField } from '../Form.helpers';

import './FormCheckbox.component.scss';

interface Props<T> {
  form: FormikProps<T>;
  name: string;
  label: React.ReactNode;
  isDisabled?: boolean;
  className?: ClassValue;
  isRequired?: boolean;
}

export const FormCheckbox = <T extends any = any>({
  form,
  name,
  label,
  isDisabled = false,
  isRequired = false,
}: Props<T>) => {
  const validation = validateField({name, form});

  return (
    <Field
      name={name}
    >
      {({ field }: FieldProps) => (
        <div className={'c-form-checkbox'}>
          <SharedCheckbox
            name={name}
            label={label}
            checked={field.value}
            isDisabled={isDisabled}
            onChange={isActive => {
              form.setFieldValue(name, isActive);
              form.setFieldTouched(name, isActive);
            }}
            isError={Boolean(validation.hasError)}
          />
          {isRequired && <span className={'c-form-checkbox__star'}>*</span>}
        </div>
      )}
    </Field>
  );
};
