import { FormInputRadioBoxGroup, FormInputSelect } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
// TODO: unify what's in +case and +shared/case
import { CaseActions as SharedCaseActions } from '+shared/store/case';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import {
  CaseCategory,
  CaseCountry,
  CaseOrigin,
  CasePriority,
  CaseRecordType,
  CaseStatus,
  CaseSubcategory,
  CaseType,
} from '+shared/store/case/types/createCase.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ModalLayout } from '@sonnen/shared-web';
import { Formik, FormikProps } from 'formik';
import { isObject } from 'lodash/fp';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { FormInput } from '../Form';
import { CaseFormHelper } from './CaseReportForm.helper';
import {
  CaseReportFormField,
  CaseReportFormValues,
  FormCaseType,
} from './CaseReportForm.types';

import './CaseReportForm.component.scss';

interface Props {
  activeSite?: CaseSite;
  partnerContactId: string;
  isLoading: boolean;
  submitCaseForm: typeof SharedCaseActions.createCase;
  siteComponent: React.ReactNode;
}

export const CaseReportForm: React.FC<Props> = React.memo(({
  isLoading,
  submitCaseForm,
  partnerContactId,
  activeSite,
  siteComponent,
}) => {
  const reportFormRef = React.useRef<FormikProps<CaseReportFormValues>>(null);
  const [isFormValid, setFormValidationState] = React.useState(false);

  const typesComponentData = React.useMemo(
    () => {
      if (reportFormRef.current) {
        CaseFormHelper.resetFormValues(reportFormRef.current);
      }
      return CaseFormHelper.getTypesComponentData(activeSite);
    },
    [activeSite],
  );
  const typesCollection = Object.values(typesComponentData);
  const contract = activeSite?.contract;
  const battery = activeSite?.battery;

  // uncomment when attachment save available on back-end
  // const [ attachment, setAttachment ] = React.useState<null | string>(null);
  // const [ attachmentError, setAttachmentError ] = React.useState<string>('');

  const onFormSubmit = (values: CaseReportFormValues) => {
    if (!values.type) {
      return;
    }

    const { description, topic, type } = values;
    const defaultPrefix = 'XXXXX';
    const contractNumber = contract ? contract.number : '';
    const batteryNumber = battery ? battery.serialNumber : defaultPrefix;
    const subject = CaseFormHelper.getSubject({ type, topic, batteryNumber, contractNumber });

    const isEServices = CaseFormHelper.isEServicesType(type.key);

    const dynamicFields = CaseFormHelper.getDynamicFields(
      contract?.salesforceId,
      activeSite?.salesforceAccountId,
    );

    submitCaseForm({
      recordType: isEServices && CaseRecordType.E_SERVICES
        || CaseRecordType.TECHNICAL_SUPPORT_CASE,
      subject,
      category: isEServices && isObject(topic) ? topic.value : undefined,
      subcategory: isEServices ? CaseSubcategory.SPECIAL_REQUESTS : undefined,
      description,
      contactId: partnerContactId,
      type: isEServices ? CaseType.REQUEST : CaseType.INCIDENT,
      priority: CasePriority.NORMAL,
      status: CaseStatus.NEW,
      country: CaseCountry.DACH,
      origin: CaseOrigin.PARTNER_PORTAL,
      asset: battery?.salesforceId,
      dynamicFields,

      // attachment,
    });
  };

  // const onReset = () => {
  //   setAttachment(null);
  //   setAttachmentError('');

  //   return null;
  // };

  // const onUpload = ({ file }: { file: File }) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     const { result } = reader;
  //     if (result) {
  //       const stringResult = result.toString();
  //       const base64Result = stringResult.slice(stringResult.indexOf(',') + 1);
  //       setAttachment(base64Result);
  //       setAttachmentError('');
  //     }
  //   };

  //   return file.name;
  // };

  const onTypeSelect = (form: FormikProps<CaseReportFormValues>) => {
    form.setFieldValue(CaseReportFormField.TOPIC, '');
  };

  const handleSubmitClick = () => {
    if (reportFormRef.current) {
      reportFormRef.current.submitForm();
    }
  };

  return (
    <ModalLayout
      title={I18n.t(T.report.headline)}
      subtitle={I18n.t(T.report.paragraph)}
      footer={
        <Button
          className={'c-case-report-form__button'}
          type={'submit'}
          size={ButtonSize.NORMAL}
          isLoading={isLoading}
          label={I18n.t(T.report.submitButton)}
          onClick={handleSubmitClick}
          isDisabled={!isFormValid}
        />
      }
    >
      {siteComponent}
      <Formik
        initialValues={CaseFormHelper.initialValues}
        validationSchema={CaseFormHelper.validationSchema}
        isInitialValid={false}
        onSubmit={onFormSubmit}
        innerRef={reportFormRef}
        render={form => {
          const { handleSubmit, values } = form;
          setFormValidationState(form.isValid);
          const topics = values.type
            ? typesComponentData[values.type.key]?.topics
            : undefined;

          return (
            <form
              onSubmit={handleSubmit}
              className={'c-case-report-form'}
            >
              <div className={'c-case-report-form__body'}>
                <div className={'c-case-report-form__section'}>
                  <FormInputRadioBoxGroup
                    name={CaseReportFormField.TYPE}
                    form={form}
                    collection={typesCollection}
                    onCheck={() => onTypeSelect(form)}
                    label={I18n.t(T.report.form.labels.type)}
                    isDisabled={!activeSite}
                  />
                </div>
                <div className={'c-case-report-form__section'}>
                  {topics ? (
                    <FormInputSelect
                      name={CaseReportFormField.TOPIC}
                      form={form}
                      className={'c-case-report-form__topic'}
                      collection={topics}
                      mapper={topic => topic.name}
                      label={I18n.t(T.report.form.labels.topic)}
                    />
                  ) : (
                    <FormInput
                      name={CaseReportFormField.TOPIC}
                      className={'c-case-report-form__topic'}
                      form={form}
                      disabled={!activeSite}
                      label={I18n.t(T.report.form.labels.topic)}
                    />
                  )}
                </div>
                <div className={'c-case-report-form__section'}>
                  <div className={'c-case-report-form__input'}>
                    <FormTextarea
                      form={form}
                      placeholder={I18n.t(T.report.form.placeholders.description)}
                      name={CaseReportFormField.DESCRIPTION}
                      className={'c-case-report-form__textarea'}
                      disabled={!activeSite}
                      label={I18n.t(T.report.form.labels.description)}
                    />
                  </div>
                  {/* TODO: uncomment when attachment upload available in new SF endpoint or remove */}
                  {/* <FormUploadInput
                    form={form}
                    name={'attachment'}
                    className={'c-case-report-form__upload-input'}
                    onUpload={file => onUpload(file[0])}
                    onReset={() => onReset()}
                    maxSize={maxAttachmentSize.value}
                    maxSizeLabel={maxAttachmentSize.label}
                    attachmentError={attachmentError}
                    accept={ACCEPT_IMAGES_AND_PDF}
                    label={I18n.t(T.report.form.placeholders.attachment)}
                  /> */}
                </div>
              </div>
            </form>
          );
        }}
      />
    </ModalLayout>
  );
});
