import { Contract, FstCheckResults } from './types/contract.interface';

export enum ContractType {
  SONNEN_FLAT_IT = 'sonnenFlatIT',
  SONNEN_FLAT = 'sonnenFlat',
  SONNEN_STROM = 'sonnenStrom',
  SONNEN_ENERGY = 'energy',
  SONNEN_FLAT_X = 'energyGlobal',
}

export enum TariffType {
  START = 'start',
  SOLAR = 'solar',
  PLUS = 'plus',
  SONNENBATTERIE_HOME_AT = 'sonnenbatterieHome-AT',
  SONNENBATTERIE_HOME_DE = 'sonnenbatterieHome-DE',
  SONNEN_ENERGY_IT = 'sonnenEnergy-IT',
  SONNEN_FLAT_1500_IT = 'sonnenFlat1500-IT',
}

export const isFSTCheckFalse = (meterFstCheckSuccessful: FstCheckResults | null | undefined): boolean =>
  (meterFstCheckSuccessful === FstCheckResults.NO
    || meterFstCheckSuccessful === null
    || meterFstCheckSuccessful === undefined
  );

export const isFSTCheckNotRequired = (meterFstCheckSuccessful: FstCheckResults | null | undefined): boolean =>
  meterFstCheckSuccessful === FstCheckResults.NOT_REQUIRED;

const isContract = (contractName: string) => (contract: Contract | undefined): boolean => (
  !!contract && (contract.contractType || contract.name || '')
    .toLowerCase()
    .includes(contractName.toLowerCase())
);

export const isContractSonnenFlat = isContract(ContractType.SONNEN_FLAT);

export const isContractSonnenEnergy = isContract(ContractType.SONNEN_ENERGY);

export const isContractSonnenFlatX = isContract(ContractType.SONNEN_FLAT_X);
