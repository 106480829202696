import { getBattery, getSite } from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { 
  dataGuard,
  mapPathToParams,
  mapToState,
  matchPath,
  ofType,
  processQuery,
  redirectTo,
  toCamelCase,
} from '+utils/index';
import { replace } from 'connected-react-router';
import { get } from 'lodash';
import { defaultTo, flow, negate } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, forkJoin, iif, of } from 'rxjs';
import { 
  catchError,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PUBLIC_ROUTES, RESTRICTED_PATHS, ROUTES } from '../../../router/routes';
import { RouterActions } from '../../../router/store/router.actions';
import { isRestrictedRoute } from '../../../router/store/router.helpers';
import { getRouteQueryParams } from '../../../router/store/router.selectors';
import { Battery } from '../battery';
import { SiteActions } from '../site';
import { StoreState } from '../store.interface';
import { AuthActions } from './auth.actions';
import * as AuthHelper from './auth.helper';
import { AuthRepository } from './auth.repository';
import { getAccessToken, getCodeVerifier, getRefreshToken, isAuthenticated } from './auth.selectors';
import { AuthContext, AuthParams, AuthSalesforceParams, GENERATE_REVERSE_CHANNEL_TOKEN_QUERY } from './auth.state';

type Action$ = ActionsObservable<AuthActions | RouterActions>;
type State$ = StateObservable<StoreState>;

const defaultAuth$ = (params: AuthParams, state$: State$) =>
  of(params as AuthParams).pipe(
    withLatestFrom(state$),
    map(([{ code, state }, storeState]) => {
      const verifier = getCodeVerifier(storeState);
      const url = AuthHelper.decodeState<{ url: string }>(state, verifier).url;
      return { code, verifier, url };
    }),
    switchMap(({ code, verifier, url }) => {
      const goToUrl = isRestrictedRoute(url) ? url : ROUTES.DASHBOARD[0];
      return concat(
        of(replace(goToUrl)),
        AuthRepository.getToken({ code, verifier }).pipe(
          map(AuthActions.setTokenStorage),
        ),
      );
    }),
  );

const salesforceAuth$ = (params: AuthSalesforceParams) =>
  of(params).pipe(
    switchMap(({ state }) => concat(
      of(AuthActions.setSalesforceTokenStorage({
        accessToken: params.idToken,
        tokenType: params.tokenType,
        impersonateUser: state && JSON.parse(state).user_id || '',
      })),
    )),
  );

export const checkAuth$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.isRehydrated),
  mapToState(state$),
  matchPath(RESTRICTED_PATHS),
  filter(negate(isAuthenticated)),
  mapTo(window.location),
  map(AuthHelper.getUrlAuthParams),
  map(params => ({ params, context: AuthHelper.getAuthContext(params) })),
  switchMap(({ params, context }) => {
    switch (context) {
      case AuthContext.DEFAULT:
        return defaultAuth$(params as AuthParams, state$);
      case AuthContext.SALESFORCE:
        return salesforceAuth$(params as AuthSalesforceParams);
      default:
        return of<any>(AuthActions.login());
    }
  }),
);

// TODO: remove migration at the end of April 2020
export const migrateOldStore$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.isRehydrated),
  mapToState(state$),
  map(state => state.shared.auth),
  filter(authState => !!get(authState, 'accessToken')),
  map(() => AuthActions.migrateAuth()),
);

export const login$ = (action$: Action$) => action$.pipe(
  ofType(AuthActions.login),
  map(AuthHelper.createCodeVerifier),
  map(AuthHelper.getLoginConfig),
  switchMap(config => concat(
    of(AuthActions.loginStart(config.verifier!)),
    of(config).pipe(
      switchMap(AuthHelper.loginUrlFactory),
      tap(redirectTo),
      ignoreElements(),
    ),
  )),
);

export const loginPage$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(RouterActions.locationChange),
  mapToState(state$),
  matchPath(PUBLIC_ROUTES.LOGIN),
  map(getRouteQueryParams),
  filter(params => !!params),
  map(toCamelCase),
  map((query: { userId: string }) => query.userId),
  switchMap(userId => iif(
    () => !!userId,
    of(userId).pipe(
      map(flow(AuthHelper.getLoginSalesforceConfig, AuthHelper.loginSalesforceUrlFactory(true))),
      tap(redirectTo),
      ignoreElements(),
    ),
    of(replace('/')),
  )),
);

export const logout$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.logout),
  mapToState(state$),
  switchMap(state => forkJoin(
    AuthRepository.revokeToken(getAccessToken(state)),
    AuthRepository.revokeToken(getRefreshToken(state)),
  )),
  catchError((err) => {
    console.error(err);
    return of(null);
  }),
  mapTo(AuthActions.clearStorage()),
);

export const clearStorage$ = (action$: Action$) => action$.pipe(
  ofType(AuthActions.clearStorage),
  tap(() => AuthHelper.logOut()),
  ignoreElements(),
);

export const getReverseChannelToken$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSite),
  mapToState(state$),
  mergeMap((state) =>
    of(state).pipe(
      mergeMap(state => of(state).pipe(
        mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
          mergeMap(([_, siteId]) => of(siteId).pipe(
            map(() => getSelectedCustomer(state)),
            mergeMap(customer => of(customer).pipe(
              filter(customer => !!customer),
              map(flow(
                getSite(siteId),
                getBattery(),
                defaultTo({} as Battery),
              )),
            )),
          )),
      )),
      mergeMap(battery => of({}).pipe(
        processQuery(
          GENERATE_REVERSE_CHANNEL_TOKEN_QUERY,
          () =>  AuthRepository.getReverseChannelToken(battery.id),
          {
            onSuccess: res => dataGuard(AuthActions.saveReverseChannelToken)(res!.element),
          },
        ),
      )),
  )),
);

export const epics = combineEpics(
  migrateOldStore$,
  checkAuth$,
  login$,
  loginPage$,
  logout$,
  clearStorage$,
  getReverseChannelToken$,
);
