import { ROUTES } from '+app/router/routes';
import { FeatureName } from '+config/featureFlags';
import { Header, Logo, useFeature } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import { Hamburger } from '../Hamburger';

import './Nav.component.scss';

interface Props {
  onToggle: (arg0: boolean) => void;
  companyLogo: string;
  isSticky?: boolean;
  isMobileNavActive?: boolean;
  navItems?: React.ReactNode;
}

export const Nav: React.FC<Props> = ({
  isSticky = false,
  isMobileNavActive = false,
  navItems,
  onToggle,
  companyLogo,
}) => {
  const mobileNavClassNames = classNames(
    'c-nav--overlayed',
    { 'is-active': isMobileNavActive },
  );

  return (
    <Media query={'(min-width: 1200px)'}>
      {(isDesktop: boolean) => (
        <Header
          isSticky={isSticky}
          logo={
            <Link to={ROUTES.DASHBOARD[0]}>
              {companyLogo
                ? <img
                    className={'c-nav__logo'}
                    src={companyLogo}
                    alt={'Company Logotype'}
                    data-hj-suppress={true}
                  />
                : <Logo />
              }
            </Link>
          }
        >
          {isDesktop ? (
            <nav>
              <ul className={'c-nav__navigation'}>
                {navItems}
              </ul>
            </nav>
          ) : (
            <Hamburger
              className={'c-nav__hamburger'}
              isActive={isMobileNavActive}
              onClick={() => onToggle(!isMobileNavActive)}
            />
          )}
          {!isDesktop && (
            <nav className={mobileNavClassNames}>
              <ul className={'c-nav__navigation c-nav__navigation--mobile'}>
                {navItems}
              </ul>
            </nav>
          )}
        </Header>
      )}
    </Media>
  );
};
