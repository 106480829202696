import { FormBanner } from '+app/shared/components';
import { ActionName, EventCategory, FormName, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { QueryState } from '+shared/store/query';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './MissingBatteryBanner.component.scss';

interface Props {
  message: string;
  formName: FormName;
  queryState: QueryState;
  buttonAction: () => void;
}

export const MissingBatteryBanner: React.FC<Props> = ({
  message,
  formName,
  queryState,
  buttonAction,
}) => {
  const { track } = useTracking();

  const gtmErrorProps = {
    category: EventCategory.MISSING_BATTERY,
    formName,
    queryStatus: queryState,
    failureMessage: message,
  };

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    buttonAction();

    track(InteractionEvents.buttonClick({
      category: EventCategory.MISSING_BATTERY,
      action: ActionName.OPEN_CASE_FORM,
      event,
    }));
  };
  
  return (
    <FormBanner
      isVisible={true}
      type={'default'}
      message={<span dangerouslySetInnerHTML={{__html: message}} />}
      icon={<Icon.CloseCircle className={'c-missing-battery-banner__close-icon'}/>}
      iconPositionTop={true}
      button={<div className={'c-missing-battery-banner__button-wrapper'}>
        <Button
          label={I18n.t(T.missingBattery.banner.createCaseButton)}
          size={ButtonSize.NORMAL}
          theme={ButtonTheme.OUTLINE}
          onClick={onButtonClick}
        />
        </div>}
      boxShadow={false}
      gtm={gtmErrorProps}
    />
  );
};
