import { getContract, getContractQueryStatus } from '+app/+customer/+contract/store/+contract.selectors';
import { getAssetsAvailability, getSite } from '+app/+customer/store/+customer.helper';
import { NotFound } from '+app/+static';
import { mapActions } from '+app/utils';
import { getBattery } from '+customer-battery/store/+battery.selectors';
import { Container } from '+shared/components';
import { CaseReport, CaseReportHelper } from '+shared/containers/CaseReport';
import { isAnyBatteryOnSiteVisible } from '+shared/store/battery/battery.helpers';
import { CaseActions } from '+shared/store/case';
import { Customer as CustomerData, Site } from '+shared/store/customer';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { isSuperUserRole } from '+shared/store/user/user.selectors';
import { T } from '@sonnen/shared-i18n/service';
import { Icon, InfoBanner, Loadable } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { CustomerAnalysis } from '../../+analysis';
import { CustomerBattery } from '../../+battery';
import { CustomerCases } from '../../+case';
import { CustomerContract } from '../../+contract';
import { CustomerOverview } from '../../+overview';
import { CustomerRouteParams, PATHS, ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { CustomerHeader } from '../../components';
import { getSelectedCustomer, getSelectedCustomerQueryStatus } from '../../store/+customer.selectors';

import './Customer.component.scss';

const mapStateToProps = (state: StoreState, ownProps: RouteProps) => {
  const { siteId } = ownProps.match.params;
  const customer = getSelectedCustomer(state);
  const site = getSite(siteId)(customer);
  const selectedCustomerQueryStatus = getSelectedCustomerQueryStatus(state);
  const assetsAvailability = getAssetsAvailability(site, state);
  const battery = getBattery(state);
  const contract = getContract(state);
  const contractQueryStatus = getContractQueryStatus(state);
  const isSuperUser = isSuperUserRole(state);

  return {
    selectedCustomerQueryStatus,
    assetsAvailability,
    customer,
    contract,
    site,
    battery,
    contractQueryStatus,
    isSuperUser,
  };
};

const mapDispatchToProps = mapActions({
  goToSite: RouterActions.changeCustomerSite,
  goToCustomers: RouterActions.goToCustomers,
  toggleModal: LayoutActions.toggleModal,
  setCaseReportActiveSite: CaseActions.setCaseReportActiveSite,
});

type RouteProps = RouteComponentProps<CustomerRouteParams>;

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteProps
  ;

class CustomerComponent extends React.PureComponent<Props> {
  goToSite = (customer: CustomerData) => (site: Site) =>
    this.props.actions.goToSite(customer.id, site.id);

  getRouteParams = (): CustomerRouteParams => ({
    customerId: this.props.customer ? this.props.customer.id : '',
    siteId: this.props.site ? this.props.site.id : '',
  });

  isConsentGiven = (): boolean => {
    const { customer } = this.props;
    return !!customer && customer.partnerMonitoringAccepted && customer.privacyPolicyAccepted;
  };

  isCustomerNotFound = (): boolean => {
    const { selectedCustomerQueryStatus, customer, site } = this.props;
    return (selectedCustomerQueryStatus.error || selectedCustomerQueryStatus.success) && (!customer || !site);
  };

  isCustomerLoaded = (): boolean => {
    const { customer, site } = this.props;
    return !!(customer && site);
  };

  render() {
    const {
      actions,
      customer: possibleNilCustomer,
      battery: possibleNilBattery,
      contract: possibleNilContract,
      site: possibleNilSite,
      assetsAvailability,
      contractQueryStatus,
      isSuperUser,
    } = this.props;

    const customer = possibleNilCustomer!;
    const battery = possibleNilBattery!;
    const contract = possibleNilContract!;
    const site = possibleNilSite!;
    const routeParams = this.getRouteParams();

    const handleCaseReportButton = () => {
      const activeSite = CaseReportHelper.transformSite(site, customer);
      actions.toggleModal(true, ModalId.CASE_REPORT);
      actions.setCaseReportActiveSite(activeSite);
    };

    if (this.isCustomerNotFound()) {
      return <NotFound />;
    }

    return (
      <Loadable
        predicate={!this.isCustomerLoaded()}
        transition={'fade'}
      >
        {this.isCustomerLoaded() && (this.isConsentGiven() || isSuperUser)
          ? (
            <>
              <CustomerHeader
                customer={customer}
                contract={contract}
                battery={battery}
                siteSelected={site}
                sites={customer.sites.filter(site => isAnyBatteryOnSiteVisible(site.batterySystems))}
                currentSite={site}
                routeParams={routeParams}
                title={customer.name}
                assetsAvailability={assetsAvailability}
                onGoBack={actions.goToCustomers}
                onSiteChange={this.goToSite(customer)}
                contractQueryStatus={contractQueryStatus}
                onCaseReportButtonClick={handleCaseReportButton}
              />
              <CaseReport />
              <Container withVerticalPadding={false}>
                <div className={'c-customer'}>
                  <Switch>
                    <Redirect
                      exact={true}
                      from={ROUTES.CUSTOMER[1]}
                      to={PATHS.CUSTOMER(routeParams)}
                    />
                    <Redirect
                      exact={true}
                      from={ROUTES.CUSTOMER_ANALYSIS[1]}
                      to={PATHS.CUSTOMER_ANALYSIS(routeParams)}
                    />
                    <Redirect
                      exact={true}
                      from={ROUTES.CUSTOMER_BATTERY[1]}
                      to={PATHS.CUSTOMER_BATTERY(routeParams)}
                    />
                    <Redirect
                      exact={true}
                      from={ROUTES.CUSTOMER_CONTRACT[1]}
                      to={PATHS.CUSTOMER_CONTRACT(routeParams)}
                    />
                    <Route exact={true} path={ROUTES.CUSTOMER} component={CustomerOverview} />
                    <Route path={ROUTES.CUSTOMER_BATTERY} component={CustomerBattery} />
                    <Route path={ROUTES.CUSTOMER_ANALYSIS} component={CustomerAnalysis} />
                    <Route path={ROUTES.CUSTOMER_CONTRACT} component={CustomerContract} />
                    <Route path={ROUTES.CUSTOMER_CASES} component={CustomerCases} />
                    <Redirect to={PATHS.CUSTOMER(routeParams)} />
                  </Switch>
                </div>
              </Container>
            </>
          ) : (
            <div className={'c-customer c-customer--no-consent-given'}>
              <InfoBanner
                icon={<Icon.NoEntry />}
                title={I18n.t(T.customerSingle.accessDenied.header)}
                subtitle={I18n.t(T.customerSingle.accessDenied.description)}
              />
            </div>
          )
        }
      </Loadable>
    );
  }
}

export const Customer = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerComponent));
