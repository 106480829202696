import { DSO_REGISTRATION_FORM_ACTIONS, DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/index';
import { DSO_REGISTRATION_ACTIONS, DsoRegistrationActions } from './+dsoRegistration.actions';
import { DsoRegistrationState, GENERATE_DOCUMENTS_QUERY, initialState } from './+dsoRegistration.state';

export const reducer = createReducer<DsoRegistrationState, DsoRegistrationActions | DsoRegistrationFormActions>(
  (state = initialState, action): DsoRegistrationState => {
    switch (action.type) {
      case DSO_REGISTRATION_ACTIONS.SET_GENERATED_DOCUMENTS:
        return {
          ...state,
          documents: action.documents,
        };
      case DSO_REGISTRATION_ACTIONS.SET_MODIFICATION_DATE: {
        return {
          ...state,
          updatedAt: action.date || '',
        };
      }
      case DSO_REGISTRATION_FORM_ACTIONS.CLEAR_DATA:
        return initialState;
      default:
        return queryReducer(state, action, [
          GENERATE_DOCUMENTS_QUERY,
        ]);
    }
  },
);
