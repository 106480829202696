import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { v4 as getKey } from 'uuid';

import './FooterNavItem.component.scss';

interface Props {
  action?: string;
  isExternal?: boolean;
}

export const FooterNavItem: React.FC<Props> = ({ action, children, isExternal }) => (
  <li className={'c-footer-link'} key={getKey()}>
    {action ?
      isExternal ? (
        <a
          className={'c-footer-nav-item__link'}
          href={action}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          {children}
        </a>
      ) : (
        <NavLink
          className={'c-footer-nav-item__link'}
          activeClassName={'is-active'}
          to={action}
        >
          {children}
        </NavLink>
      ) : (
        <div className={'c-footer-nav-item__link'}>
          {children}
        </div>
      )
    }
  </li>
);
