import { PageWidthWrapper } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';

import './Footer.component.scss';

interface Props {
  className?: ClassValue;
  copyrightNotice: string;
  isTransparent: boolean;
  socialItems?: React.ReactNode;
  navItems?: React.ReactNode;
}

export const Footer: React.FC<Props> = ({
  className, copyrightNotice, navItems, socialItems, isTransparent,
}) => (
  <footer className={classNames('c-footer', className, {
    'c-footer--transparent': isTransparent,
  })}>
    <PageWidthWrapper>
      <div className={'c-footer__container'}>
        <span className={'c-footer__copyright'}>&copy;&nbsp;{copyrightNotice}</span>
        <div className={'c-footer__links-container'}>
          <ul className={'c-footer__nav'}>
            {navItems}
          </ul>
          <div className={'c-footer__socials'}>
            {socialItems}
          </div>
        </div>
      </div>
    </PageWidthWrapper>
  </footer>
);
