import { Query } from '+shared/store/query';
import { Site, SiteData, SiteLiveState, SiteLiveStateData, SiteStatistics, SiteStatisticsData } from './types';

export const GET_SITE_LIVE_STATE_QUERY = 'getSiteLiveQuery';
export const GET_SITE_QUERY = 'getSiteQuery';
export const GET_SITE_STATISTICS_QUERY = 'getSiteStatisticsQuery';

export interface SiteState {
  site?: Site;
  liveState?: SiteLiveState;
  statistics?: SiteStatistics;
  [GET_SITE_QUERY]: Query<SiteData>;
  [GET_SITE_LIVE_STATE_QUERY]: Query<SiteLiveStateData>;
  [GET_SITE_STATISTICS_QUERY]: Query<SiteStatisticsData>;
}

export const initialState: SiteState = {
  site: undefined,
  liveState: undefined,
  statistics: undefined,
  [GET_SITE_QUERY]: {},
  [GET_SITE_LIVE_STATE_QUERY]: {},
  [GET_SITE_STATISTICS_QUERY]: {},
};
