import { Table, TableCell, TableRow, TableStack } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { isNil } from 'lodash';
import * as React from 'react';
import './DetailList.component.scss';

export interface DetailListType {
  label: string;
  value?: React.ReactNode;
  type?: 'info' | 'success' | 'warning' | 'danger';
  isGrayedOut?: boolean;
}

interface Props {
  vertical?: boolean;
  separator?: React.ReactNode;
  list: DetailListType[];
  filterNil?: boolean;
}

export const DetailList: React.FC<Props> = ({
  vertical = false,
  separator = ':', list,
  filterNil = false,
}) => {
  const tableItems = filterNil ? list.filter(item => !isNil(item.value)) : list;

  return (
    <Table className={'c-detail-list'}>
      {tableItems.map(({ label, value, type }) => {
        const classes = {
          detailRow: classNames('c-detail-list__row', {
            ['c-detail-list__row--vertical']: vertical,
          }),
          detailKey: classNames('c-detail-list__key', {
            ['c-detail-list__key--vertical']: vertical,
          }),
          detailValue: classNames('c-detail-list__value', {
            ['c-detail-list__value--vertical']: vertical,
            [`c-detail-list__value--${type}`]: type,
          }),
        };

        return (
          <TableRow
            key={`dl-${label}`}
            className={classes.detailRow}
            stack={
              vertical
                ? TableStack.VERTICAL
                : TableStack.HORIZONTAL
            }
          >
            <TableCell
              percentageWidth={vertical ? 1 : (1 / 3)}
              className={classes.detailKey}
            >
              {label}
              {!vertical && separator}
            </TableCell>
            <TableCell
              stack={TableStack.VERTICAL}
              className={classes.detailValue}
            >
              <div data-hj-suppress={true}>
                {value}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </Table>
  );
};
