import { GET_BATTERY_STATUSES_QUERY } from '+app/+customer/+battery/store';
import { isEatonBattery } from '+app/+customer/+battery/store/+battery.selectors';
import { getBattery, getSite } from '+app/+customer/store/+customer.helper';
import { getSelectedCustomer } from '+app/+customer/store/+customer.selectors';
import { hasSiteReadingsOption } from '+app/shared/store/site/site.selectors';
import { GET_SITE_QUERY } from '+app/shared/store/site/site.state';
import { FeatureName } from '+config/featureFlags';
import { CustomerActions } from '+customer/store';
import { AuthActions } from '+shared/store/auth';
import { Battery, BatteryActions } from '+shared/store/battery';
import { SiteActions, SiteMeasurements } from '+shared/store/site';
import {
  getMeasurementsEndDate,
  getMeasurementsStartDate,
  normalizeBatteryStatusesToTime,
  normalizeMeasurementsToTime,
} from '+shared/store/site/site.helpers';
import { getSiteLiveState, siteHasBattery } from '+shared/store/site/site.selectors';
import { SiteLiveState } from '+shared/store/site/types';
import { StoreState } from '+shared/store/store.interface';
import { dataGuard, mapPathToParams, mapToState, ofType, processQuery } from '+utils/index';
import { BatteryStatusesKey, EnergyFlowSeriesKey, TimeHelper, useFeature } from '@sonnen/shared-web';
import { defaultTo, flow } from 'lodash/fp';
import * as moment from 'moment';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { concat, of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { AnalysisActions, isSetDateAction } from './+analysis.actions';
import { AnalysisRepository } from './+analysis.repository';
import { getStatisticsSelectedDate } from './+analysis.selector';
import {
  areDataSeriesEmpty,
  getAreaChartConsumption,
  getAreaChartProduction,
  getDataSeries,
  getForecastConsumptionFull,
  getForecastProductionFull,
  getSelectedDate,
  hasSiteMeasurements,
} from './+analysis.selector';
import {
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_SITE_MEASUREMENTS_QUERY,
} from './+analysis.state';
import {
  getForecastStartDate,
  getLastDataPoint,
  getLiveSeriesPoint,
  transformForecastData,
  updateForecastSeries,
} from './helpers/+analysis.helpers';
import {
  defaultStatisticsFilters,
  transformStatisticsIntoSeries,
  transformToSiteStatisticsFilters,
} from './helpers/+analysisStatistics.helpers';
import { FORECAST_VALUE_CONSUMPTION, FORECAST_VALUE_PRODUCTION } from './types/forecast.interface';

type Action$ = ActionsObservable<RouterActions | AnalysisActions | SiteActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getMeasurements$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer, AnalysisActions.setDayChartDate),
  withLatestFrom(state$),
  mergeMap(([action, state]) => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      filter(() => flow(getSelectedDate, TimeHelper.isTodayOrBefore)(state)),
      map(() =>
        SiteActions.getSiteMeasurements({
          queryKey: GET_SITE_MEASUREMENTS_QUERY,
          siteId,
          start: isSetDateAction(action)
            ? getMeasurementsStartDate(action.date)
            : flow(getSelectedDate, getMeasurementsStartDate)(state),
          end: isSetDateAction(action)
            ? getMeasurementsEndDate(action.date)
            : flow(getSelectedDate, getMeasurementsEndDate)(state),
        }),
      ),
    )),
  )),
);

export const getBatteryStatuses$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer, AnalysisActions.setDayChartDate),
  filter(() => useFeature(FeatureName.BATTERY_VPP_ACTIVITY).isEnabled),
  withLatestFrom(state$),
  mergeMap(([action, state]) => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      filter(() => flow(getSelectedDate, TimeHelper.isTodayOrBefore)(state)),
      map(() => getSelectedCustomer(state)),
      mergeMap(customer => of(customer).pipe(
        filter(() => !!customer),
        map(flow(
          getSite(siteId),
          getBattery(),
          defaultTo({} as Battery),
        )),
      )),
      mergeMap((battery) =>
        of(BatteryActions.getBatteryStatuses({
          batteryId: battery.id,
          queryKey: GET_BATTERY_STATUSES_QUERY,
          start: isSetDateAction(action)
            ? getMeasurementsStartDate(action.date)
            : flow(getSelectedDate, getMeasurementsStartDate)(state),
          end: isSetDateAction(action)
            ? getMeasurementsEndDate(action.date)
            : flow(getSelectedDate, getMeasurementsEndDate)(state),
        })),
      ),
    )),
  )),
);

export const getAnalysisStatistics$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer, AnalysisActions.setStatisticsDate),
  withLatestFrom(state$),
  mergeMap(([action, state]) => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      map(() => SiteActions.getSiteStatistics(
        siteId,
        isSetDateAction(action)
          ? transformToSiteStatisticsFilters(action.statisticsSelectedDate)
          : defaultStatisticsFilters,
      )),
    )))),
);

export const setAnalysisStatistics$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSiteStatistics),
  withLatestFrom(state$),
  mergeMap(([action, state]) => of(state).pipe(
    map(() => transformStatisticsIntoSeries(action.statistics, getStatisticsSelectedDate(state))),
    mergeMap(({ pieChart, barChart }) => concat(
      of(AnalysisActions.setPieChartSeries(pieChart)),
      of(AnalysisActions.setBarChartSeries(barChart)),
    )),
  )),
);

export const getSiteDetails$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer),
  withLatestFrom(state$),
  mergeMap(([_, state]) => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      map(() => SiteActions.getSite({
        queryKey: GET_SITE_QUERY,
        siteId,
      })),
    )))),
);

export const normalizeMeasurements$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSiteMeasurements),
  map(action => action.siteMeasurements),
  filter(Boolean),
  map(measurements => measurements as NonNullable<SiteMeasurements>),
  withLatestFrom(state$),
  map(([measurements, state]) => ({ measurements, selectedDate: getSelectedDate(state) })),
  map(({ measurements, selectedDate }) => ({
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: normalizeMeasurementsToTime(
      measurements.productionPower,
      measurements.resolution,
      selectedDate,
    ),
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: normalizeMeasurementsToTime(
      measurements.consumptionPower,
      measurements.resolution,
      selectedDate,
    ),
    [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: normalizeMeasurementsToTime(
      measurements.directUsagePower,
      measurements.resolution,
      selectedDate,
    ),
    [EnergyFlowSeriesKey.BATTERY_USOC]: normalizeMeasurementsToTime(
      measurements.batteryUsoc,
      measurements.resolution,
      selectedDate,
    ),
    [EnergyFlowSeriesKey.BATTERY_CHARGING]: normalizeMeasurementsToTime(
      measurements.batteryCharging,
      measurements.resolution,
      selectedDate,
    ),
    [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: normalizeMeasurementsToTime(
      measurements.batteryDischarging,
      measurements.resolution,
      selectedDate,
    ),
  })),
  mergeMap((dataSeries) => concat(
    of(AnalysisActions.setDataSeries(dataSeries)),
    of(AnalysisActions.triggerLiveState()),
  )),
);

export const normalizeBatteryStatuses$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(BatteryActions.setBatteryStatuses),
  map(action => action.batteryStatuses),
  map(batteryStatuses => batteryStatuses.filter(batteryStatus => batteryStatus.value.includes('vpp'))),
  withLatestFrom(state$),
  map(([batteryStatuses, state]) => ({
    batteryStatuses,
    selectedDate: getSelectedDate(state),
  })),
  map(({ batteryStatuses, selectedDate }) => ({
    [BatteryStatusesKey.VPP_ACTIVITY]: normalizeBatteryStatusesToTime(
      batteryStatuses,
      selectedDate,
    ),
  })),
  mergeMap((dataSeries) =>
    of(AnalysisActions.setDataSeries(dataSeries)),
  ),
);

export const updateMeasurements$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSiteLiveState),
  map(action => action.liveState as SiteLiveState),
  withLatestFrom(state$),
  map(([liveState, state]) => ({ liveState, dataSeries: getDataSeries(state) })),
  filter(({ liveState }) => !!liveState),
  map(({ liveState, dataSeries }) => (
    {
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER],
        getLiveSeriesPoint(liveState.timestamp, liveState.productionPower),
      ],
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER],
        getLiveSeriesPoint(liveState.timestamp, liveState.consumptionPower),
      ],
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: [
        ...dataSeries[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
        getLiveSeriesPoint(liveState.timestamp, Math.min(liveState.productionPower, liveState.consumptionPower)),
      ],
      [EnergyFlowSeriesKey.BATTERY_USOC]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_USOC],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryUsoc),
      ],
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_CHARGING],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryCharging),
      ],
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: [
        ...dataSeries[EnergyFlowSeriesKey.BATTERY_DISCHARGING],
        getLiveSeriesPoint(liveState.timestamp, liveState.batteryDischarging),
      ],
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: updateForecastSeries({
        forecastSeries: dataSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER] || [],
        liveDataTimestamp: liveState.timestamp,
        liveDataPower: liveState.consumptionPower,
      }),
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: updateForecastSeries({
        forecastSeries: dataSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER] || [],
        liveDataTimestamp: liveState.timestamp,
        liveDataPower: liveState.productionPower,
      }),
    }
  )),
  map(AnalysisActions.setDataSeries),
);

export const startPolling$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(SiteActions.setSite, AnalysisActions.triggerLiveState),
  mapToState(state$),
  mergeMap(state => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
    map(([_, siteId]) => ({
      siteId,
      hasReadings: hasSiteReadingsOption(state),
      isSelectedDateToday: TimeHelper.isToday(getSelectedDate(state)),
      isEaton: isEatonBattery(state),
      hasBattery: siteHasBattery(state),
      hasMeasurements: hasSiteMeasurements(state),
      areDataSeriesEmpty: areDataSeriesEmpty(state),
    })),
    map(({ siteId, hasReadings, isSelectedDateToday, isEaton, hasBattery, hasMeasurements, areDataSeriesEmpty }) => ({
      siteId,
      canStartPolling:
        hasReadings && isSelectedDateToday && hasBattery && hasMeasurements && !areDataSeriesEmpty && !isEaton,
    })),
  )),
  mergeMap(({ siteId, canStartPolling }) => canStartPolling
    ? of(SiteActions.startPolling(siteId))
    : of(SiteActions.stopPolling()),
  ),
);

export const stopPolling$ = (action$: Action$) => action$.pipe(
  ofType(RouterActions.locationChange, AnalysisActions.setDayChartDate),
  map(() => SiteActions.stopPolling()),
);

export const getForecastProductionFull$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.saveReverseChannelToken),
  mergeMap(({ reverseChannelToken }) => of(reverseChannelToken).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
      processQuery(
        GET_FORECAST_PRODUCTION_QUERY,
        () => AnalysisRepository.getSiteForecastProduction({ id: reverseChannelToken.token }),
        {
          onSuccess: (res) => dataGuard(AnalysisActions.setForecastProductionFull)(res && res.data),
          onFailure: _ => of(AnalysisActions.setForecastProductionFull([])),
        },
      ),
    )),
  )),
);

export const getForecastConsumptionFull$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AuthActions.saveReverseChannelToken),
  mergeMap(({ reverseChannelToken }) => of(reverseChannelToken).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      mapPathToParams(ROUTES.CUSTOMER_ANALYSIS[0]),
      processQuery(
        GET_FORECAST_CONSUMPTION_QUERY,
        () => AnalysisRepository.getSiteForecastConsumption({ id: reverseChannelToken.token }),
        {
          onSuccess: (res) => dataGuard(AnalysisActions.setForecastConsumptionFull)(res && res.data),
          onFailure: _ => of(AnalysisActions.setForecastConsumptionFull([])),
        },
      ),
    )),
  )),
);

const setForecastProductionSeries$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AnalysisActions.setForecastProductionFull, AnalysisActions.setDayChartDate),
  mergeMap(() => of({}).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      map(() => ({
        forecastProduction: getForecastProductionFull(state),
        selectedDate: getSelectedDate(state),
        liveState: getSiteLiveState(state),
        isEaton: isEatonBattery(state),
        lastDataPoint: getLastDataPoint(getAreaChartProduction(state)),
        lastLivePoint: getForecastStartDate(getSiteLiveState(state)),
      })),
    )),
  )),
  mergeMap(({
    forecastProduction,
    selectedDate,
    liveState,
    isEaton,
    lastDataPoint,
    lastLivePoint,
  }) => of(AnalysisActions.setDataSeries({
    [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: transformForecastData({
      date: moment(selectedDate),
      lastDataPointTimestamp: !isEaton && liveState
        ? lastLivePoint
        : lastDataPoint.x,
      lastDataPointPower: !isEaton && liveState
        ? liveState.consumptionPower
        : lastDataPoint.y!,
      forecasts: forecastProduction || [],
      forecastDataKey: FORECAST_VALUE_PRODUCTION,
    }),
  })),
  ),
);

const setForecastConsumptionSeries$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(AnalysisActions.setForecastConsumptionFull, AnalysisActions.setDayChartDate),
  mergeMap(() => of({}).pipe(
    mapToState(state$),
    mergeMap(state => of(state).pipe(
      map(() => ({
        forecastConsumption: getForecastConsumptionFull(state),
        selectedDate: getSelectedDate(state),
        liveState: getSiteLiveState(state),
        isEaton: isEatonBattery(state),
        lastDataPoint: getLastDataPoint(getAreaChartConsumption(state)),
        lastLivePoint: getForecastStartDate(getSiteLiveState(state)),
      })),
    )),
  )),
  mergeMap(({
    forecastConsumption,
    selectedDate,
    liveState,
    isEaton,
    lastDataPoint,
    lastLivePoint,
  }) => of(AnalysisActions.setDataSeries({
    [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: transformForecastData({
      date: moment(selectedDate),
      lastDataPointTimestamp: !isEaton && liveState
        ? lastLivePoint
        : lastDataPoint.x,
      lastDataPointPower: !isEaton && liveState
        ? liveState.consumptionPower
        : lastDataPoint.y!,
      forecasts: forecastConsumption || [],
      forecastDataKey: FORECAST_VALUE_CONSUMPTION,
    }),
  })),
  ),
);

export const epics = combineEpics(
  getMeasurements$,
  getBatteryStatuses$,
  normalizeMeasurements$,
  updateMeasurements$,
  startPolling$,
  stopPolling$,
  getForecastProductionFull$,
  getForecastConsumptionFull$,
  setForecastProductionSeries$,
  setForecastConsumptionSeries$,
  getSiteDetails$,
  getAnalysisStatistics$,
  setAnalysisStatistics$,
  normalizeBatteryStatuses$,
);
