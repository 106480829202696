import { PreCommissioningInitial } from '+setupTool/+preCommissioning/store/schemas';
import { PreCommissioningInterface, PreCommissioningStatus } from '+setupTool/+preCommissioning/store/types';
import { Query } from '+shared/store/query';

export const GET_PRE_COMMISSIONING_DATA_QUERY = 'getPreCommissioningDataQuery';
export const MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY = 'markGenerationOfPreCommissioningDocumentQuery';

export interface PreCommissioningState {
  fields: PreCommissioningInterface;
  status: PreCommissioningStatus;
  [GET_PRE_COMMISSIONING_DATA_QUERY]: Query;
  [MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY]: Query;
}

export const initialState: PreCommissioningState = {
  fields: PreCommissioningInitial,
  status: PreCommissioningStatus.NOT_GENERATED,
  [GET_PRE_COMMISSIONING_DATA_QUERY]: {},
  [MARK_GENERATION_OF_PRE_COMMISSIONING_DOCUMENT_QUERY]: {},
};
