import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Status, StatusType } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './WarningBox.component.scss';

interface Props {
  warning?: string;
}

export const WarningBox: React.FC<Props> = ({ warning = I18n.t(T.general.warning.server) }) => (
  <div className={'c-warning-box'}>
    <Status
      icon={(
        <Icofont
          className={'c-warning-box__icon'}
          type={'warning'}
        />
      )}
      hasDot={false}
      type={StatusType.WARNING}
      label={warning}
    />
  </div>
);
