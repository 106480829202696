import { FormInput } from '+shared/components';
import { FormTextarea } from '+shared/components/Form/FormTextarea';
import { CreateCaseAttributes } from '+shared/store/case/types/createCase.interface';
import { ClaimBatteryAttributes } from '+shared/store/partner/types/claimBattery.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { FieldNames } from '../../containers/MissingBatteryModal/types';
import { MissingBatteryModalLayout } from '../MissingBatteryModalLayout';
import { generateCaseAttributes } from './MissingBatteryCaseForm.helper';
import { MissingBatteryCaseFormValues } from './MissingBatteryCaseForm.interface';
import { missingBatteryCaseSchema } from './MissingBatteryCaseForm.schema';

import './MissingBatteryCaseForm.component.scss';

interface Props {
  claimBatteryData: ClaimBatteryAttributes;
  isQueryPending: boolean;
  contactId: string;
  hideCaseReporting: () => void;
  submitCaseForm: (values: CreateCaseAttributes) => void;
}

export const MissingBatteryCaseForm: React.FC<Props> = ({
  hideCaseReporting,
  submitCaseForm,
  isQueryPending,
  contactId,
  claimBatteryData: {serialNumber, postalCode, customerLastName, pvPeakPower},
}) => {
  const onSubmit = (values: MissingBatteryCaseFormValues) => {
    const caseAttributes = generateCaseAttributes({...values, contactId});
    submitCaseForm(caseAttributes);
  };

  return (
    <Formik
      initialValues={{
        serialNumber,
        postalCode,
        customerLastName,
        pvPeakPower: pvPeakPower ? pvPeakPower.toString() : '',
        description: '',
      }}
      validateOnBlur={false}
      validateOnChange={true}
      validationSchema={missingBatteryCaseSchema}
      onSubmit={onSubmit}
      render={form => (
        <Form className={'c-missing-battery-case-form'}>
          <MissingBatteryModalLayout
            isLoading={isQueryPending}
            canSubmit={form.isValid} 
            buttonLabel={I18n.t(T.missingBattery.createCase.submitButton)}
            goBack={hideCaseReporting}
          >
            <span className={'c-missing-battery-case-form__info'}>
              {I18n.t(T.missingBattery.createCase.headline)}
            </span>
            <FormInput
              form={form}
              name={FieldNames.SERIAL_NUMBER}
              label={I18n.t(T.missingBattery.serialNumber)}
              disabled={true}
              isRequired={true}
            />
            <FormInput
              form={form}
              name={FieldNames.POSTAL_CODE}
              label={I18n.t(T.missingBattery.zipCode)}
              disabled={!!postalCode || isQueryPending}
            />
            <FormInput
              form={form}
              name={FieldNames.LAST_NAME}
              label={I18n.t(T.missingBattery.lastName)}
              disabled={!!customerLastName || isQueryPending}
            />
            <FormInput
              form={form}
              name={FieldNames.PV_POWER}
              label={I18n.t(T.missingBattery.pvSize)}
              type={'number'}
              disabled={!!pvPeakPower || isQueryPending}
            />
            <FormTextarea
              form={form}
              name={FieldNames.DESCRIPTION}
              label={I18n.t(T.missingBattery.description)}
              isRequired={true}
              disabled={isQueryPending}
              placeholder={I18n.t(T.missingBattery.descriptionPlaceholder)}
            />
          </MissingBatteryModalLayout>
        </Form>
      )}
    />
  );
};
