
import { useInputEvent } from '+shared/hooks/useInputEvent';
import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';

import './FormInputRadio.component.scss';

interface Props<T, U> {
  name: string;
  label: string;
  value: U;
  form: FormikProps<T>;
  className?: ClassValue;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const ObservableInputElement = ({ form, field, fieldValue, ...props }: FieldProps & { fieldValue: string }) => {
  const { onChange } = useInputEvent(field);

  const handleOnChange = (e: React.ChangeEvent<any>) => {
    onChange(e);
    form.setFieldValue(field.name, fieldValue);
    form.setFieldTouched(field.name);
  };

  const checked = get(field.name)(form.values) === fieldValue;

  return (
    <input
      {...props}
      {...field}
      value={fieldValue}
      checked={checked}
      onChange={handleOnChange}
    />
  );
};

export const FormInputRadio = <T extends any = any, U extends any = any>({
  form,
  name,
  label,
  value,
  className,
  onClick,
  disabled = false,
}: Props<T, U>) => {
  const id = name + '-' + value;

  return (
    <div className={classNames('c-form-input-radio', className)}>
      <Field
        component={ObservableInputElement}
        name={name}
        className={'c-form-input-radio-radio'}
        type={'radio'}
        id={id}
        fieldValue={value}
        onClick={onClick}
        disabled={disabled}
      />
      <label
        className={'c-form-input-radio-label'}
        htmlFor={id}
      >
        <span className={'c-form-input-radio-circle'} />
        {label}
      </label>
    </div>
  );
};
