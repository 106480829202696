import { DatePicker } from '+shared/components';
import { DatepickerArrowSide } from '+shared/components/DatePicker/DatePicker.helper';
import { StoreState } from '+shared/store/store.interface';
import { formatDate } from '+utils/format.util.old';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { ClickOutside, DateSwitcher, Icofont, Legend, TimeHelper } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { AnalysisActions } from '../../store';
import { getDatepickerMaxDate, getSelectedDataSeriesKeys, getSelectedDate } from '../../store/+analysis.selector';
import { ChartDataSeriesKey } from '../../store/types/chartDataSeries.interface';
import './CustomerAnalysisDayToolbar.component.scss';
import { prepareFilters } from './CustomerAnalysisDayToolbar.helpers';

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const mapStateToProps = (state: StoreState) => ({
  selectedDataSeriesKeys: getSelectedDataSeriesKeys(state),
  selectedDate: getSelectedDate(state),
  maxDate: getDatepickerMaxDate(state),
});

const mapDispatchToProps = mapActions({
  toggleDataSeries: AnalysisActions.toggleDataSeries,
  setDate: AnalysisActions.setDayChartDate,
});

const CustomerAnalysisDayToolbarComponent: React.FC<Props> = ({
  actions, selectedDate, selectedDataSeriesKeys, maxDate,
}) => {
  const nextDay = new Date(selectedDate);
  TimeHelper.addDaysToDate(nextDay, 1);

  const canGoNext = TimeHelper.isDateInRange(nextDay, maxDate);
  const minDate = TimeHelper.xYearsAgo(7);
  const [isDatePickerOpen, toggleDatePicker] = React.useState<boolean>(false);

  const onDatePick = (date: Date) => {
    const formatedDate = TimeHelper.formatDate(date);
    if (TimeHelper.isDateInRange(formatedDate, maxDate)) {
      actions.setDate(date);
      toggleDatePicker(false);
    }
  };

  return (
    <div className={'c-customer-analysis-day-toolbar'}>
      <div className={'c-customer-analysis-day-toolbar__date'}>
        <span className={'c-customer-analysis-day-toolbar__label'}>
          {I18n.t(T.customerSingle.analysis.dayChart.chooseDate) + ':'}
        </span>
        <ClickOutside onClick={() => toggleDatePicker(false)}>
          <div className={'c-customer-analysis-day-toolbar__date-switcher-wrapper'}>
            <DateSwitcher
              icon={<Icofont type={'calendar'} />}
              date={selectedDate}
              dateFormatter={formatDate}
              canGoPrev={true}
              canGoNext={canGoNext}
              onPrevSelected={actions.setDate}
              onNextSelected={actions.setDate}
              onDateSelected={() => toggleDatePicker(!isDatePickerOpen)}
            />
            <div className={'c-customer-analysis-day-toolbar__date-picker-wrapper'}>
              <DatePicker
                isActive={isDatePickerOpen}
                arrowPosition={DatepickerArrowSide.TOP}
                date={selectedDate}
                onDayClick={onDatePick}
                maxDate={maxDate}
                minDate={minDate}
              />
            </div>
          </div>
        </ClickOutside>
      </div>
      <div className={'c-customer-analysis-day-toolbar__filters'}>
        <span className={'c-customer-analysis-day-toolbar__label'}>
          {I18n.t(T.customerSingle.analysis.dayChart.filters) + ':'}
        </span>
        <Legend
          onToggle={key => actions.toggleDataSeries(key as ChartDataSeriesKey)}
          dataSeries={prepareFilters(selectedDataSeriesKeys)}
        />
      </div>
    </div>
  );
};

export const CustomerAnalysisDayToolbar = connect
  (mapStateToProps, mapDispatchToProps)(CustomerAnalysisDayToolbarComponent);
