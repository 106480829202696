import { Lead, LeadStatusName } from '+shared/store/lead/types';
import { Query } from '+shared/store/query';

export const LEAD_COLLECTION_GET_QUERY = 'leadCollectionGetQuery';
export const INBOX_LEAD_COLLECTION_GET_QUERY = 'inboxLeadCollectionGetQuery';
export const SETUP_LEAD_COLLECTION_GET_QUERY = 'setupLeadCollectionGetQuery';
export const PARTNER_EMPLOYEE_COLLECTION_GET_QUERY = 'partnerEmployeeCollectionGetQuery';
export const ASSIGN_PARTNER_TO_LEAD_QUERY = 'assignPartnerToLeadQuery';
export const DECLINE_LEAD_QUERY = 'declineLeadQuery';

export interface LeadListPageState {
  collection: Lead[];
  statusFilters: LeadStatusName[];
  setupStatusFilters: LeadStatusName[];
  previousStatusFilters: LeadStatusName[];
  previousSetupStatusFilters: LeadStatusName[];
  totalResourceCount: number;
  inboxCollection: Lead[];
  totalInboxResourceCount: number;
  setupCollection: Lead[];
  totalSetupResourceCount: number;
  isAssignLeadModalOpen: boolean;
  isDeclineLeadModalOpen: boolean;
  [LEAD_COLLECTION_GET_QUERY]: Query<any>;
  [INBOX_LEAD_COLLECTION_GET_QUERY]: Query<any>;
  [SETUP_LEAD_COLLECTION_GET_QUERY]: Query<any>;
  [ASSIGN_PARTNER_TO_LEAD_QUERY]: Query<any>;
  [DECLINE_LEAD_QUERY]: Query<any>;
}

export const initialState: LeadListPageState = {
  collection: [],
  statusFilters: [],
  setupStatusFilters: [],
  previousStatusFilters: [],
  previousSetupStatusFilters: [],
  totalResourceCount: 0,
  inboxCollection: [],
  totalInboxResourceCount: 0,
  setupCollection: [],
  totalSetupResourceCount: 0,
  isAssignLeadModalOpen: false,
  isDeclineLeadModalOpen: false,
  [LEAD_COLLECTION_GET_QUERY]: {},
  [INBOX_LEAD_COLLECTION_GET_QUERY]: {},
  [SETUP_LEAD_COLLECTION_GET_QUERY]: {},
  [ASSIGN_PARTNER_TO_LEAD_QUERY]: {},
  [DECLINE_LEAD_QUERY]: {},
};

export enum StatusManagementTooltipLocation {
  SETUP_LIST = 'setup-list',
  SETUP_HEADER = 'setup-header',
}
