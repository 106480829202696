import { Video, VideoFirebase } from '+app/shared/store/firebase/types/video.interface';
import { YoutubeRepository } from '+app/shared/store/youtube/youtube.repository';
import { StoreState } from '+shared/store/store.interface';
import { ofType, processQuery } from '+utils/index';
import { isEmpty } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { FirebaseActions } from './firebase.actions';
import { FirebaseRepository } from './firebase.repository';
import { getVideoList } from './firebase.selectors';
import { MARK_NEWS_AS_READ_QUERY } from './firebase.state';

type Action$ = ActionsObservable<FirebaseActions>;
type State$ = StateObservable<StoreState>;

export const getCombinedVideoList$ = (action$: Action$) => action$.pipe(
  ofType(FirebaseActions.getCombinedVideoList),
  mergeMap(({ videoListFirebase }) => of(videoListFirebase).pipe(
    map((videoListFirebase) => videoListFirebase
      .map((videoFirebase: VideoFirebase) => videoFirebase.youtubeId)
      .join(),
    ),
    mergeMap((listOfVideosIdsFirebase) => YoutubeRepository.getYoutubeVideoList(listOfVideosIdsFirebase)),
    map((youtubeResponse) => youtubeResponse.items.map((video: Video, index: number) => ({
      ...video,
      youtubeId: video.id,
      id: videoListFirebase[index].id,
      categories: [videoListFirebase[index].categories[0]],
      // @TODO videoListFirebase[index] -> replace index by id, it causes problem when ytAPI doesn't return video
      // @TODO categories[0] -> decide: multicategories or single category for each video
      // multicategories are not supported since beginning :/ we have multiple 'other' instead
    }))),
    map(FirebaseActions.setCombinedVideoList),
  )),
);

export const addVideoToCombinedVideoList$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(FirebaseActions.addVideo),
  map(action => action.video),
  mergeMap((video) => of(video).pipe(
    map(video => video.youtubeId),
    mergeMap((youtubeId) => YoutubeRepository.getYoutubeVideoList(youtubeId)),
    map(youtubeResponse => youtubeResponse.items),
    filter(youtubeVideoList => !isEmpty(youtubeVideoList)),
    withLatestFrom(state$),
    map(([youtubeVideos, state]) => ([
      ...getVideoList(state),
      {
        ...video,
        ...youtubeVideos[0],
      },
    ])),
    map(FirebaseActions.setCombinedVideoList),
  )),
);

export const markNewsAsRead$ = (action$: Action$) => action$.pipe(
  ofType(FirebaseActions.markNewsAsRead),
  mergeMap(({ newsId }) => of({}).pipe(
    processQuery(
      MARK_NEWS_AS_READ_QUERY,
      () => FirebaseRepository.postNewsMarkAsRead(newsId),
      {
        onSuccess: () => of(FirebaseActions.markNewsAsReadSuccess()),
      },
    ),
  )),
);

export const epics = combineEpics(
  getCombinedVideoList$,
  addVideoToCombinedVideoList$,
  markNewsAsRead$,
);
