import { createReducer } from '+app/utils';
import { queryReducer } from '+shared/store/query';
import { defaultTo } from 'lodash/fp';
import { CONTACT_LIST_PAGE_ACTIONS, ContactListPageActions } from './+contactList.actions';
import {
  CONTACT_ASSIGN_ROLE_QUERY,
  CONTACT_COLLECTION_GET_QUERY,
  CONTACT_DENY_ROLE_QUERY,
  ContactListPageState,
  initialState,
} from './+contactList.state';

export const reducer = createReducer<ContactListPageState, ContactListPageActions>(
  (state = initialState, action): ContactListPageState => {
    switch (action.type) {
      case CONTACT_LIST_PAGE_ACTIONS.SET_CONTACT_LIST:
        return {
          ...state,
          collection: action.collection,
          totalResourceCount: defaultTo(state.totalResourceCount)(action.total),
        };
      case CONTACT_LIST_PAGE_ACTIONS.ASSIGN_ROLE:
        return {
          ...state,
          currentlyEditedRole: { contactId: action.contactId, roleName: action.role.name },
        };
      case CONTACT_LIST_PAGE_ACTIONS.SET_ROLE:
        return {
          ...state,
          collection: state.collection.map(contact => contact.id === action.contactId
            ? { ...contact, roles: contact.roles.concat(action.role) }
            : contact,
          ),
          currentlyEditedRole: initialState.currentlyEditedRole,
        };
      case CONTACT_LIST_PAGE_ACTIONS.DENY_ROLE:
        return {
          ...state,
          currentlyEditedRole: { contactId: action.contactId, roleName: action.role.name },
        };
      case CONTACT_LIST_PAGE_ACTIONS.RESET_ROLE:
        return {
          ...state,
          collection: state.collection.map(contact => contact.id === action.contactId
            ? { ...contact, roles: contact.roles.filter(role => role.id !== action.role.id) }
            : contact,
          ),
          currentlyEditedRole: initialState.currentlyEditedRole,
        };
      case CONTACT_LIST_PAGE_ACTIONS.SET_CURRENTLY_EDITED_ROLE:
        return {
          ...state,
          currentlyEditedRole: { contactId: action.contactId, roleName: action.roleName },
        };
      default:
        return queryReducer(state, action,
          [CONTACT_COLLECTION_GET_QUERY, CONTACT_ASSIGN_ROLE_QUERY, CONTACT_DENY_ROLE_QUERY],
        );
    }
  },
);
