import { FormEvents, useTracking } from '+shared/GoogleTagManager';
import { GtmError } from '+shared/GoogleTagManager/events/event.types';
import { ErrorTrackingStatusMap, useErrorTracking } from '+shared/hooks/useStatusTracking';
import * as classNames from 'classnames';
import * as React from 'react';

import './FormErrorBanner.component.scss';

interface Props {
  isVisible: boolean;
  error: string;
  toLeft?: boolean;
  gtm?: {
    category: string;
    formName: string;
    queryStatus: ErrorTrackingStatusMap | ErrorTrackingStatusMap[];
  };
}

export const FormErrorBanner: React.FC<Props> = ({ isVisible, error, toLeft, gtm }) => {
  const { track } = useTracking();

  useErrorTracking(() => {
    if (gtm) {
      track(FormEvents.serverError({
        category: gtm.category,
        formName: gtm.formName,
        error,
      }));
    }
  }, gtm && gtm.queryStatus);

  return isVisible ? (
    <div className={classNames('c-form-error-banner', {
      'c-form-error-banner--left': toLeft,
    })}
         id={'form-error-banner'}>
      {error}
    </div >
  ) : null;
};
