import { getContract, getSite } from '+app/+customer/store/+customer.helper';
import {
  getContractEndAtDate, getContractStartAtDate, isContractActive,
} from '+customer-contract/store/+contract.selectors';
import { CustomerActions } from '+customer/store';
import { Contract, ContractActions } from '+shared/store/contract';
import { SiteActions } from '+shared/store/site/site.actions';
import { StoreState } from '+shared/store/store.interface';
import { StatisticsResolution } from '@sonnen/shared-web';
import { defaultTo, flow } from 'lodash/fp';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ROUTES } from '../../../router';
import { RouterActions } from '../../../router/store';
import { mapPathToParams, mapToState, ofType } from '../../../utils';
import { getSelectedCustomer } from '../../store/+customer.selectors';
import { isContractTypeAnySonnenFlat } from './+contract.selectors';
import { GET_CONTRACT_QUERY } from './+contract.state';

type Action$ = ActionsObservable<RouterActions | CustomerActions>;
type State$ = StateObservable<StoreState>;

export const getContractDetails$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(CustomerActions.setCustomer),
  mapToState(state$),
  mergeMap(state => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER[0], ROUTES.CUSTOMER_CONTRACT[0]),
    mergeMap(([_, siteId]) => of(siteId).pipe(
      map(() => getSelectedCustomer(state)),
      filter(customer => !!customer),
      map(flow(
        getSite(siteId),
        getContract(),
        defaultTo({} as Contract),
      )),
    )),
  )),
  map(contract => ContractActions.getContract({
    queryKey: GET_CONTRACT_QUERY,
    contractId: contract.id,
  })),
);

export const getContractStatistics$ = (action$: Action$, state$: State$) => action$.pipe(
  ofType(ContractActions.setContract),
  withLatestFrom(state$),
  mergeMap(([_, state]) => of(state).pipe(
    mapPathToParams(ROUTES.CUSTOMER_CONTRACT[0]),
      mergeMap(([_, siteId]) => of(siteId).pipe(
        map(() => ({startDate: getContractStartAtDate(state), endDate: getContractEndAtDate(state)})),
        filter(({startDate, endDate}) =>
          isContractTypeAnySonnenFlat(state) && isContractActive(state) && !!startDate && !!endDate,
        ),
        map(({startDate, endDate}) =>
          SiteActions.getSiteStatistics(
            siteId,
            {
              start: startDate!,
              end: endDate!,
              resolution: StatisticsResolution.TOTAL,
            },
          ),
        )),
      ),
    ),
  ),
);

export const epics = combineEpics<any>(
  getContractDetails$,
  getContractStatistics$,
);
