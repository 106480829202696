import { mapActions } from '+app/utils';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { getRegistrationSubject, getSubmissionId } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { LayoutActions, ModalId } from '+shared/store/layout';
import { getOpenModalId, isModalOpen } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme, Modal, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './SetupToolRegistrationSubjectConfirmationModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  registrationSubject: getRegistrationSubject(state),
  submissionId: getSubmissionId(state),
  isModalOpen: isModalOpen(state),
  openModalId: getOpenModalId(state),
});

const mapDispatchToProps = mapActions({
  setRegistrationSubjectType: SetupToolActions.setRegistrationSubjectType,
  saveRegistrationSubjectType: SetupToolActions.saveRegistrationSubjectType,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const SetupToolRegistrationSubjectConfirmationModalComponent: React.FC<Props> = ({
  actions,
  registrationSubject,
  submissionId,
  isModalOpen,
  openModalId,
}) => {
  const oppositeRegistrationSubject = registrationSubject === RegistrationSubjectType.PV_AND_BATTERY
    ? RegistrationSubjectType.BATTERY
    : RegistrationSubjectType.PV_AND_BATTERY;

  const toggleRegistrationSubject = () => {
    if (submissionId) {
      actions.setRegistrationSubjectType(oppositeRegistrationSubject);
      actions.saveRegistrationSubjectType(submissionId, oppositeRegistrationSubject);
      actions.toggleModal(false);
    }
  };

  return (
    <Modal
      className={'c-setup-tool-registration-subject-modal'}
      size={'small'}
      isOpen={isModalOpen && openModalId === ModalId.REGISTRATION_SUBJECT}
      onClose={() => actions.toggleModal(false)}
    >
      <ModalLayout
        title={I18n.t(T.setupTool.registrationSubjectConfirmationModal.title)}
        footer={
          <Button
            name={'c-setup-tool-registration-subject-modal__button'}
            type={'submit'}
            label={I18n.t(T.setupTool.registrationSubjectConfirmationModal.actionButtonLabel)}
            size={ButtonSize.SECONDARY}
            theme={ButtonTheme.PRIMARY}
            onClick={toggleRegistrationSubject}
          />
        }
      >
      <p>{I18n.t(T.setupTool.registrationSubjectConfirmationModal.innerTextBefore)}</p>
      <p><strong>{
        oppositeRegistrationSubject === RegistrationSubjectType.PV_AND_BATTERY
        ? I18n.t(T.setupTool.registrationSubjectSelection.button.pvPlusBattery)
        : I18n.t(T.setupTool.registrationSubjectSelection.button.onlyBattery)
      }</strong></p>
      <p>{I18n.t(T.setupTool.registrationSubjectConfirmationModal.innerTextAfter)}</p>
      </ModalLayout>
    </Modal>
  );
};

export const SetupToolRegistrationSubjectConfirmationModal =
  connect(mapStateToProps, mapDispatchToProps)(SetupToolRegistrationSubjectConfirmationModalComponent);
