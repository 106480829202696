import { CurrencyUnit, EnergyUnit, PeakPowerUnit } from '+app/App.constants';
import {
  LeadConfigurationMarket,
  LeadConfigurationResponseAttributes,
  LeadMeasurement,
} from '+shared/store/lead/types';
import { formatCurrency, formatEnergy, formatPeakPower } from '+utils/format.util.old';
import { T } from '@sonnen/shared-i18n/service';
import { I18n } from 'react-redux-i18n';
import { isFlatDirectConfiguration } from '../../store/+configuration.helper';

export const formatMeasurementEnergy = (measurement: LeadMeasurement<EnergyUnit>, precision = 0) =>
  formatEnergy({
    whValue: Number(measurement.value) * (measurement.unit === EnergyUnit.KWH ? 1000 : 1),
    unit: measurement.unit,
    precision,
  });

export const formatMeasurementPeakPower = (measurement: LeadMeasurement<PeakPowerUnit>) =>
  formatPeakPower(
    Number(measurement.value) * (measurement.unit === PeakPowerUnit.KWP ? 1000 : 1),
    measurement.unit,
    3,
  );

export const formatPricePerKwh = (
  value: number | string,
  currency: CurrencyUnit,
  { asMinorUnit } = { asMinorUnit: false },
) => {
  const corrected = asMinorUnit ? typeof value === 'string' ? parseFloat(value) * 100 : value * 100 : value;
  return formatCurrency(corrected, currency, { isMinorUnit: asMinorUnit }) + `/${EnergyUnit.KWH}`;
};

export const getCurrencyForMarket = (market: LeadConfigurationMarket | undefined) => {
  switch (market) {
    case LeadConfigurationMarket.DE:
      return CurrencyUnit.CURRENCY_EUR;
    default:
      return CurrencyUnit.CURRENCY_EUR;
  }
};

export enum ConfigurationBoxHeaderSize {
  LARGE = 'large',
  MEDIUM = 'medium',
}

export const factorizeFirstBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit,
) => [
    // Free usage allowance
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.userAllowance)}:`,
      value: `${formatMeasurementEnergy(configuration.usageAllowancePerYear)}`
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
    },
    // Basic Fee
    {
      title: isFlatDirectConfiguration(configuration) ?
        `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyFee)}:`
        : `${I18n.t(T.lead.configuration._salessolution_.preview.basicFee)}:`,
      value: formatCurrency(configuration.baseFeeGrossPerMonth.value, currency)
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
      tileText: isFlatDirectConfiguration(configuration) ?
        I18n.t(T.lead.configuration._salessolution_.preview.tile.differentPrice)
        : undefined,
    },
  ];

export const factorizeSecondBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit,
) => [
    // Monthly pre-payment
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.monthlyPrepayment)}:`,
      value: `${formatCurrency(configuration.initialAdvancePaymentGrossPerMonth.value, currency)}`
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.month)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
    },
    // Excess energy
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.excessEnergy)}:`,
      value: `${formatMeasurementEnergy(configuration.powerPlant.expectedExcessEnergyPerYear)}`
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.MEDIUM,
    },
    // Excess tariff
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.excessTarriff)}:`,
      value: formatPricePerKwh(configuration.excessTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
      size: ConfigurationBoxHeaderSize.MEDIUM,
    },
  ];

export const factorizeThirdBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit,
) => [
    // Expected cashback
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedCashback)}:`,
      value: `${formatCurrency(configuration.powerPlant.expectedCashbackGrossPerYear.value, currency)}`
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.LARGE,
    },
    // Expected unused free usage allowance
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.expectedUnusedUsageAllowance)}:`,
      value: `${formatMeasurementEnergy(configuration.powerPlant.expectedUnusedUsageAllowancePerYear)}`
        + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`,
      size: ConfigurationBoxHeaderSize.MEDIUM,
    },
    // Cashback rate
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.preview.cashbackRate)}:`,
      value: formatPricePerKwh(configuration.cashbackTariffGrossPerKwh.value, currency, { asMinorUnit: true }),
      size: ConfigurationBoxHeaderSize.MEDIUM,
    },
  ];

export const factorizeFourthBoxDetails = (
  configuration: LeadConfigurationResponseAttributes,
  currency: CurrencyUnit,
) => [
    // VPP bonus
    {
      title: `${I18n.t(T.lead.configuration._salessolution_.vpp.title)}:`,
      value: configuration.powerPlant.guaranteedVppBonusGranted &&
        configuration.powerPlant.guaranteedVppBonusGrossPerYear ? (
          `${I18n.t(T.lead.configuration._salessolution_.vpp.minimum)} `
          + `${formatCurrency(configuration.powerPlant.guaranteedVppBonusGrossPerYear.value, currency)}`
          + `/${I18n.t(T.lead.configuration._salessolution_.preview.year)}`
        ) : (
          I18n.t(T.lead.configuration._salessolution_.vpp.notEligible)
        ),
      size: ConfigurationBoxHeaderSize.LARGE,
    },
  ];
