import { ContactListRouteQueryParams } from '+app/router';
import { ActionsUnion, createAction } from '+app/utils';
import { Contact } from '+shared/store/contact/types';
import { Role, RoleNames } from '+shared/store/role/types/role.interface';
import { CONTACT_ASSIGN_ROLE_QUERY, CONTACT_DENY_ROLE_QUERY } from './+contactList.state';

export enum CONTACT_LIST_PAGE_ACTIONS {
  GET_CONTACT_LIST = '[+Contact] GET_CONTACT_LIST',
  SET_CONTACT_LIST = '[+Contact] SET_CONTACT_LIST',
  ASSIGN_ROLE = '[+Contact] ASSIGN_ROLE',
  SET_ROLE = '[+Contact] SET_ROLE',
  DENY_ROLE = '[+Contact] DENY_ROLE',
  RESET_ROLE = '[+Contact] RESET_ROLE',
  SET_CURRENTLY_EDITED_ROLE = '[+Contact] SET_CURRENTLY_EDITED_ROLE',
}

export const ContactListPageActions = {
  getContactList: createAction( 
    CONTACT_LIST_PAGE_ACTIONS.GET_CONTACT_LIST,
    ({ queryKey, queryParams }: { queryKey: string, queryParams: ContactListRouteQueryParams }) =>
      ({ queryKey, queryParams }),
  ),
  setContactList: createAction(
    CONTACT_LIST_PAGE_ACTIONS.SET_CONTACT_LIST,
    (collection: Contact[], total?: number) => ({ collection, total }),
  ),
  assignRole: createAction(
    CONTACT_LIST_PAGE_ACTIONS.ASSIGN_ROLE,
    (role: Role, contactId: string) => ({ role, contactId, queryKey: CONTACT_ASSIGN_ROLE_QUERY }),
  ),
  setRole: createAction(
    CONTACT_LIST_PAGE_ACTIONS.SET_ROLE,
    (role: Role, contactId: string) => ({ role, contactId }),
  ),
  denyRole: createAction(
    CONTACT_LIST_PAGE_ACTIONS.DENY_ROLE,
    (role: Role, contactId: string) => ({ role, contactId, queryKey: CONTACT_DENY_ROLE_QUERY }),
  ),
  resetRole: createAction(
    CONTACT_LIST_PAGE_ACTIONS.RESET_ROLE,
    (role: Role, contactId?: string) => ({ role, contactId }),
  ),
  setCurrentlyEditedRole: createAction(
    CONTACT_LIST_PAGE_ACTIONS.SET_CURRENTLY_EDITED_ROLE,
    (contactId: string, roleName: RoleNames) => ({ contactId, roleName }),
  ),
};

export type ContactListPageActions = ActionsUnion<typeof ContactListPageActions>;
