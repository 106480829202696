// tslint:disable:max-line-length

import { Env } from './environment';

interface AuthConfiguration {
  AUTH_URL: string;
  CLIENT_ID: string;
  CLIENT_SECRET?: string;
}

interface AuthSalesforceConfiguration {
  SALESFORCE_URL: string;
  SALESFORCE_TECH_URL: string;
  SALESFORCE_CLIENT_ID: string;
}

interface FirebaseConfiguration {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  firebaseFunctionsUrl: string;
}

interface GoogleTagManagerConfiguration {
  id: string;
  auth: string;
  preview: string;
}

export interface EnvConfiguration {
  ENVIRONMENT: string;
  IS_DEV: boolean;
  PORTAL_URL: string;
  OLD_PORTAL_URL: string;
  API_URL: string;
  PARTNER_PORTAL_API_URL: string;
  SALES_API_URL: string;
  SALES_API_SECRET: string;
  DSO_REGISTRATION_API_URL: string;
  WEBSOCKETS_URL: string;
  AUTH: AuthConfiguration;
  AUTH_SALESFORCE: AuthSalesforceConfiguration;
  DOCS_API: string;
  GOOGLE_TAG_MANAGER: GoogleTagManagerConfiguration;
  AUTH_TEST_TOKEN?: string;
  COMPONENTS_DB_API_URL: string;
  FIREBASE: FirebaseConfiguration;
  YOUTUBE_URL: string;
  YOUTUBE_API: string;
  PRODUCTION_FORECAST: string;
  CONSUMPTION_FORECAST: string;
}

const AUTH_SALESFORCE_EXTERNAL = {
  SALESFORCE_URL: 'https://sonnen--external.my.salesforce.com',
  SALESFORCE_TECH_URL: 'https://sonnen--external.my.salesforce.com',
  SALESFORCE_CLIENT_ID: '3MVG9lcxCTdG2VbtWAnBLcHTY.4_qll7_ezHx.0doqoQSR0z95qrKasbCJufPizdI9KBTAgXUR_LwmXOcnjLe',
};

export const getEnvConfig = (env: Env): EnvConfiguration => {
  switch (env) {
    case Env.TEST:
    case Env.DEVELOPMENT:
      return {
        ENVIRONMENT: env,
        IS_DEV: env === Env.DEVELOPMENT,
        PORTAL_URL: 'http://my-dev.sonnen.de',
        OLD_PORTAL_URL: 'https://meine-dev.sonnenbatterie.de/dashboard',
        API_URL: 'https://api.integration.sonnen.de/v1',
        PARTNER_PORTAL_API_URL: 'https://api-dev.partner-usage.sales.staging.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api-dev.sales.staging.sonnen.de/api/v1',
        SALES_API_SECRET: 'qhkvg4i7CRtGpPrsXQcmZfJLag1txMGyQowFj8Tw',
        DSO_REGISTRATION_API_URL: 'https://setup-tools-dev.sales.staging.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db-dev.sales.staging.sonnen.de/api',
        WEBSOCKETS_URL: 'wss://api.sales.staging.sonnen.de/api/v1/ws',
        AUTH: {
          AUTH_URL: 'https://account.integration.sonnen.de',
          CLIENT_ID: '61da2ce586ad54783da12a8aaa397bafe95356eda0cf9ad9ca1cd160659c11ee',
          // CLIENT_SECRET: '1cf4c5c534b6407df1501390916b67ff2b6947cfe0011ee72a8cebee0e525f45',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_EXTERNAL,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-PL77KGD',
          auth: 'JdBpPkdPxUeNbYlErjXd6Q',
          preview: 'env-10',
        },
        AUTH_TEST_TOKEN: '2oxvr4hw7op6wuw8udgato3sdefpvh1ggucw965kfto2f60xawtdv2r9ih2she7s',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.DEMO:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'http://my.staging.sonnen.de',
        OLD_PORTAL_URL: 'https://meine-dev.sonnenbatterie.de/dashboard',
        API_URL: 'https://api.integration.sonnen.de/v1',
        PARTNER_PORTAL_API_URL: 'https://api-dev.partner-usage.sales.staging.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api-demo.sales.staging.sonnen.de/api/v1',
        SALES_API_SECRET: 'qhkvg4i7CRtGpPrsXQcmZfJLag1txMGyQowFj8Tw',
        DSO_REGISTRATION_API_URL: 'https://setup-tools-demo.sales.staging.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db-staging.sales.staging.sonnen.de/api',
        WEBSOCKETS_URL: 'wss://api.sales.staging.sonnen.de/api/v1/ws',
        AUTH: {
          AUTH_URL: 'https://account.integration.sonnen.de',
          CLIENT_ID: '61da2ce586ad54783da12a8aaa397bafe95356eda0cf9ad9ca1cd160659c11ee',
          // CLIENT_SECRET: '1cf4c5c534b6407df1501390916b67ff2b6947cfe0011ee72a8cebee0e525f45',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_EXTERNAL,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-PL77KGD',
          auth: 'JdBpPkdPxUeNbYlErjXd6Q',
          preview: 'env-10',
        },
        AUTH_TEST_TOKEN: '2oxvr4hw7op6wuw8udgato3sdefpvh1ggucw965kfto2f60xawtdv2r9ih2she7s',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.STAGING:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'http://my.staging.sonnen.de',
        OLD_PORTAL_URL: 'https://meine-dev.sonnenbatterie.de/dashboard',
        API_URL: 'https://api.integration.sonnen.de/v1',
        PARTNER_PORTAL_API_URL: 'https://api-dev.partner-usage.sales.staging.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api.sales.staging.sonnen.de/api/v1',
        SALES_API_SECRET: 'qhkvg4i7CRtGpPrsXQcmZfJLag1txMGyQowFj8Tw',
        DSO_REGISTRATION_API_URL: 'https://setup-tools.sales.staging.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db-staging.sales.staging.sonnen.de/api',
        WEBSOCKETS_URL: 'wss://api.sales.staging.sonnen.de/api/v1/ws',
        AUTH: {
          AUTH_URL: 'https://account.integration.sonnen.de',
          CLIENT_ID: '61da2ce586ad54783da12a8aaa397bafe95356eda0cf9ad9ca1cd160659c11ee',
          // CLIENT_SECRET: '1cf4c5c534b6407df1501390916b67ff2b6947cfe0011ee72a8cebee0e525f45',
        },
        AUTH_SALESFORCE: AUTH_SALESFORCE_EXTERNAL,
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-PL77KGD',
          auth: 'JdBpPkdPxUeNbYlErjXd6Q',
          preview: 'env-10',
        },
        AUTH_TEST_TOKEN: '2oxvr4hw7op6wuw8udgato3sdefpvh1ggucw965kfto2f60xawtdv2r9ih2she7s',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-dev.cloudfunctions.net',
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.PRODUCTION:
    default:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        PORTAL_URL: 'https://my.sonnen.de',
        OLD_PORTAL_URL: 'https://meine.sonnenbatterie.de/dashboard',
        API_URL: 'https://my-api.sonnen.de/v1',
        PARTNER_PORTAL_API_URL: 'https://api.partner-usage.sales.sonnen.de/api',
        PRODUCTION_FORECAST: 'https://apigw.sonnen.de/forecast/production',
        CONSUMPTION_FORECAST: 'https://apigw.sonnen.de/forecast/consumption',
        SALES_API_URL: 'https://api.sales.sonnen.de/api/v1',
        SALES_API_SECRET: 'qhkvg4i7CRtGpPrsXQcmZfJLag1txMGyQowFj8Tw',
        DSO_REGISTRATION_API_URL: 'https://setup-tools.sales.sonnen.de/api',
        COMPONENTS_DB_API_URL: 'https://components-db.sales.sonnen.de/api',
        WEBSOCKETS_URL: 'wss://api.sales.sonnen.de/api/v1/ws',
        AUTH: {
          AUTH_URL: 'https://account.sonnen.de',
          CLIENT_ID: 'bbc1c96032c2f02fbaade1c36a672d7f18942b61bba95022501b254b6c45688f',
          // CLIENT_SECRET: 'acb25f1a0d9825ac1e2f6560ba731f59443f87e102013786d66c8ec9d7707fce',
        },
        AUTH_SALESFORCE: {
          SALESFORCE_URL: 'https://sonnencommunity.force.com',
          SALESFORCE_TECH_URL: 'https://sonnen.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9Rd3qC6oMalVMVGU7MyHfOBxhBIXLyWeI5nPxDktnWBABTuOJPVK3YHOieLpiDlJvQxIMawvzMfxZ_E6w',
        },
        DOCS_API: 'https://sonnen.secure.force.com/services/apexrest/GetDocument/v2',
        YOUTUBE_URL: 'https://www.googleapis.com/youtube/v3/',
        YOUTUBE_API: 'AIzaSyCySF44aMQnxpcTEXlIgQ2U7FcVp7Tkhvk',
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-PL77KGD',
          auth: '1mAUz7T7KJzUFpWeqxbnYA',
          preview: 'env-1',
        },
        FIREBASE: {
          firebaseFunctionsUrl: 'https://us-central1-sonnenportal-production.cloudfunctions.net',
          apiKey: 'AIzaSyApNzzuJlabXzCg9CQRUaYw8PR2zGbgVKc',
          authDomain: 'sonnenportal-production.firebaseapp.com',
          databaseURL: 'https://sonnenportal-production.firebaseio.com',
          projectId: 'sonnenportal-production',
          storageBucket: 'sonnenportal-production.appspot.com',
          messagingSenderId: '445947885271',
          appId: '1:445947885271:web:3235b7a224b7eb4cf0e292',
        },
      };
  }
};
