import { Config } from '+config';
import { from } from 'rxjs';

const getYoutubeVideoList = (listOfVideosIds: string) => from(
  fetch(
    `${Config.YOUTUBE_URL}videos?id=${listOfVideosIds}&part=snippet,contentDetails&key=${Config.YOUTUBE_API}`,
  ).then(response => response.json()),
);

export const YoutubeRepository = {
  getYoutubeVideoList,
};
