import { ActionsUnion, createAction } from '../../../utils';
import { Contract } from './types/contract.interface';

export enum CONTRACT_ACTIONS {
  GET_CONTRACT = '[Contract] GET_CONTRACT',
  SET_CONTRACT = '[Contract] SET_CONTRACT',
}

export const ContractActions = {
  getContract: createAction(
    CONTRACT_ACTIONS.GET_CONTRACT,
    ({ queryKey, contractId }: { queryKey: string, contractId: string }) => ({ queryKey, contractId }),
  ),
  setContract: createAction(
    CONTRACT_ACTIONS.SET_CONTRACT,
    (contract?: Contract) => ({ contract }),
  ),
};

export type ContractActions = ActionsUnion<typeof ContractActions>;
