import { OfferProductStatus } from '+shared/store/lead/types';
import { ActionsUnion, createAction } from '../../../utils';

export enum HARDWARE_PAGE_ACTIONS {
  SAVE_HARDWARE_OFFER = '[+Hardware] SAVE_HARDWARE_OFFER',
  UPDATE_HARDWARE_STATUS = '[+Hardware] UPDATE_HARDWARE_STATUS',
  SET_NEW_HARDWARE_STATUS = '[+Hardware] SET_NEW_HARDWARE_STATUS',
  CLEAR_NEW_HARDWARE_STATUS = '[+Hardware] CLEAR_NEW_HARDWARE_STATUS',
}

export const HardwarePageActions = {
  saveHardwareOffer: createAction(
    HARDWARE_PAGE_ACTIONS.SAVE_HARDWARE_OFFER,
    (batteryId: string) => ({ batteryId }),
  ),
  updateHardwareStatus: createAction(
    HARDWARE_PAGE_ACTIONS.UPDATE_HARDWARE_STATUS,
    (
      leadId: string,
      productStatusChanges: Array<{ offerId: string, productId: string, status: OfferProductStatus }>,
    ) => ({
      leadId,
      productStatusChanges,
    }),
  ),
  // status to patch, when offer creation succeed
  setNewHardwareStatus: createAction(
    HARDWARE_PAGE_ACTIONS.SET_NEW_HARDWARE_STATUS,
    (status: OfferProductStatus) => ({ status }),
  ),
  clearNewHardwareStatus: createAction(
    HARDWARE_PAGE_ACTIONS.CLEAR_NEW_HARDWARE_STATUS,
  ),
};

export type HardwarePageActions = ActionsUnion<typeof HardwarePageActions>;
