import { queryReducer } from '+shared/store/query';
import { createReducer } from '+utils/redux/createReducer.util';
import { CONFIGURATION_PAGE_ACTIONS, ConfigurationPageActions } from './+configuration.actions';
import {
  CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
  CONFIGURATION_REMOVE_QUERY,
  CONFIGURATION_SAVE_QUERY,
  CONFIGURATION_SUBMIT_QUERY,
  ConfigurationPageState,
  initialState,
  SET_CONFIGURATION_PROPOSAL_QUERY,
} from './+configuration.state';

export const reducer = createReducer<ConfigurationPageState, ConfigurationPageActions>(
  (state = initialState, action): ConfigurationPageState => {
    switch (action.type) {
      case CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_FORM:
        return {
          ...state,
          configurationForm: action.configurationForm,
        };
      case CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_HINT:
        return {
          ...state,
          configurationHint: action.configurationHint,
        };
      case CONFIGURATION_PAGE_ACTIONS.CLEAR_CONFIGURATION_HINT:
        return {
          ...state,
          configurationHint: undefined,
        };
      case CONFIGURATION_PAGE_ACTIONS.SET_CONFIGURATION_PROPOSAL:
        return {
          ...state,
          configurationProposal: {
            ...action.configurationProposal,
            powerPlant: {
              ...action.configurationProposal.powerPlant,
              expectedAutarky: {
                ...action.configurationProposal.powerPlant.expectedAutarky,
                value: parseFloat(action.configurationProposal.powerPlant.expectedAutarky?.value.toString()),
              },
            },
          },
        };
      case CONFIGURATION_PAGE_ACTIONS.CLEAR_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          CONFIGURATION_SUBMIT_QUERY,
          CONFIGURATION_RECOMMENDATION_SUBMIT_QUERY,
          CONFIGURATION_REMOVE_QUERY,
          CONFIGURATION_SAVE_QUERY,
          SET_CONFIGURATION_PROPOSAL_QUERY,
        ]);
    }
  },
);
