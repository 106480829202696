import { ActionName, EventCategory, InteractionEvents, useTracking } from '+shared/GoogleTagManager';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import './MissingBatteryButton.component.scss';

interface Props {
  openMissingBatteryModal: () => void;
}

export const MissingBatteryButton: React.FC<Props> = ({
  openMissingBatteryModal,
}) => {
  const { track } = useTracking();

  const onButtonClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    openMissingBatteryModal();

    track(InteractionEvents.buttonClick({
      category: EventCategory.MISSING_BATTERY,
      action: ActionName.OPEN_MODAL,
      event,
    }));
  };

  return (
    <div className={'c-missing-battery-button'}>
      <span className={'c-missing-battery-button__info'}>
        <Translate
          value={T.missingBattery.startProcessInfo}
          dangerousHTML={true}
        />
      </span>
      <div className={'c-missing-battery-button__content'}>
        <Button
          onClick={onButtonClick}
          size={ButtonSize.NORMAL}
          label={I18n.t(T.missingBattery.startProcessButton)}
          theme={ButtonTheme.OUTLINE}
        />
      </div>
    </div>
  );
};
