import { FormEvents, useTracking } from '+shared/GoogleTagManager';
import { Gtm } from '+shared/GoogleTagManager/events/event.types';
import { useErrorTracking, useSuccessTracking } from '+shared/hooks/useStatusTracking';
import * as classNames from 'classnames';
import * as React from 'react';

import './FormBanner.component.scss';

interface Props {
  isVisible: boolean;
  type: string;
  id?: string;
  message: React.ReactNode;
  description?: string;
  icon: React.ReactNode;
  iconPositionTop?: boolean;
  button?: React.ReactNode;
  boxShadow?: boolean;
  bannerVisibilityTime?: number;
  parentElementToScroll?: string;
  gtm?: Gtm;
}

export const FormBanner: React.FC<Props> = ({
  isVisible,
  type,
  id,
  message,
  description,
  icon,
  iconPositionTop = false,
  button,
  boxShadow = true,
  bannerVisibilityTime,
  parentElementToScroll,
  gtm,
}) => {
  const { track } = useTracking();

  useErrorTracking(() => {
    if (gtm && gtm.queryStatus.error) {
      track(FormEvents.serverError({
        category: gtm.category,
        formName: gtm.formName,
        error: gtm.failureMessage!,
      }));
    }
  }, gtm && gtm.queryStatus);

  useSuccessTracking(() => {
    if (gtm && gtm.queryStatus.success) {
      track(FormEvents.success({
        category: gtm.category,
        formName: gtm.formName,
      }));
    }
  }, gtm && gtm.queryStatus);

  const [isBannerVisible, setIsBannerVisible] = React.useState(isVisible);
  const [isAnimationTransitionInProgress, setIsAnimationTransitionInProgress] = React.useState(false);

  const startTimeOfAnimation = bannerVisibilityTime && bannerVisibilityTime > 1000 ? bannerVisibilityTime - 1000 : 0;

  React.useEffect(() => {
    let scrollTimeout: any;
    let isHiddenClassSetTimeout: any;
    let isBannerVisibleTimeout: any;

    if (isVisible) {
      setIsAnimationTransitionInProgress(false);
      setIsBannerVisible(true);

      if (parentElementToScroll) {
        scrollTimeout = setTimeout(() => {
          const element = document.getElementById(parentElementToScroll);
          if (element) {
            element.scrollIntoView({behavior: 'smooth'});
          }
        }, 200);
      }

      if (bannerVisibilityTime) {
        isHiddenClassSetTimeout = setTimeout(() => {
          setIsAnimationTransitionInProgress(true);
        }, startTimeOfAnimation);

        isBannerVisibleTimeout = setTimeout(() => {
          setIsAnimationTransitionInProgress(false);
          setIsBannerVisible(false);
        }, bannerVisibilityTime);
      }
    } else {
      setIsBannerVisible(false);
    }

    return (): any => {
      clearTimeout(scrollTimeout);
      clearTimeout(isHiddenClassSetTimeout);
      clearTimeout(isBannerVisibleTimeout);
    };
  }, [isVisible]);

  return isBannerVisible ? (
    <div
      className={classNames('c-form-banner', {
        'c-form-banner--box-shadow': boxShadow,
        [`c-form-banner--${type}`]: type,
        [`c-form-banner--transition`]: isAnimationTransitionInProgress,
      })}
      id={id}
    >
      <div
        className={classNames('c-form-banner__icon', {
          'c-form-banner__icon--top': iconPositionTop,
        })}
      >{icon}</div>
      <div className={'c-form-banner__body'}>
        <p
          className={classNames('c-form-banner__title', {
            'c-form-banner__title--bold': !!description,
          })}
        >{message}</p>
        {description && (<p className={'c-form-banner__message'}>{description}</p>)}
        {button}
      </div>
    </div >
  ) : null;
};
