import { EnergyUnit, OtherUnit, PeakPowerUnit } from '+app/App.constants';
import { LeadConfigurationResponseAttributes, LeadProductType } from '+shared/store/lead/types';
import { isNumber } from 'lodash/fp';
import * as moment from 'moment';
import { getFormFieldsForEachPv, PvKind } from '../containers/LeadConfigurationPv/LeadConfigurationPv.helper';
import { ConfigurationForm } from './types/configuration.interface';

export const MAX_FLAT_DIRECT_DATE = new Date('2002-12-31');

export const isFlatDirectConfiguration = (configuration: LeadConfigurationResponseAttributes | undefined) =>
  configuration?.product === LeadProductType.FLAT_DIRECT;

export const isFlatXConfiguration = (configuration: LeadConfigurationResponseAttributes | undefined) =>
  configuration?.product === LeadProductType.FLAT_X;

export const createSinglePvSystem = (configurationForm: ConfigurationForm, pvKind: PvKind) => ({
  peakPower: {
    value: configurationForm[getFormFieldsForEachPv(pvKind).peakPower],
    unit: PeakPowerUnit.KWP,
  },
  inclination: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).inclination])
    ? {
      value: configurationForm[getFormFieldsForEachPv(pvKind).inclination],
      unit: OtherUnit.DEGREE,
    }
    : undefined,
  orientation: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).orientation])
    ? {
      value: configurationForm[getFormFieldsForEachPv(pvKind).orientation],
      unit: OtherUnit.DEGREE,
    }
    : undefined,
  specificYieldPerYear: isNumber(configurationForm[getFormFieldsForEachPv(pvKind).specificYield])
    ? {
      value: configurationForm[getFormFieldsForEachPv(pvKind).specificYield],
      unit: EnergyUnit.KWH_PER_KWP,
    }
    : undefined,
  commissioningDate: configurationForm[getFormFieldsForEachPv(pvKind).commissioningDate],
  systemName: configurationForm[getFormFieldsForEachPv(pvKind).systemName],
});

export const arePhotovoltaicSystemsCompatible = (firstPvCommissioningDate: Date, secondPvCommissioningDate: Date) => {
  const diffInMonths = Math.abs(moment(firstPvCommissioningDate).diff(secondPvCommissioningDate, 'months'));

  const acceptableDifferenceMonthsCount = 12;

  return diffInMonths >= acceptableDifferenceMonthsCount;
};
