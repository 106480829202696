import { isFlatDirectConfiguration } from '+app/+lead/+configuration/store/+configuration.helper';
import { FeatureName } from '+config';
import { LeadConfigurationResponseAttributes } from '+shared/store/lead/types/leadConfigurationResponse.interface';
import { insertIf } from '+utils/array.util';
import { T } from '@sonnen/shared-i18n/service';
import { useFeature } from '@sonnen/shared-web';
import { I18n } from 'react-redux-i18n';

export enum VppQuestionsFormFields {
  SEMI_INDIRECT = 'semiIndirect',
  GENERATION_PLANTS = 'generationPlants',
  PRODUCTION_METERING_MANDATORY = 'productionMeteringMandatory',
}

export type VppQuestionsForm = Record<VppQuestionsFormFields, boolean>;

export const VppQuestionsInitialValues: Record<VppQuestionsFormFields, boolean> = {
  [VppQuestionsFormFields.SEMI_INDIRECT]: false,
  [VppQuestionsFormFields.GENERATION_PLANTS]: false,
  [VppQuestionsFormFields.PRODUCTION_METERING_MANDATORY]: false,
};

export type VppQuestionType = {
  question: string;
  optionType: string;
};

const { isEnabled: vppQuestionForFlatDirect } = useFeature(FeatureName.VPP_QUESTION_FOR_FLAT_DIRECT);

export const getVppQuestions = (configuration: LeadConfigurationResponseAttributes | undefined) => [
  {
    question: I18n.t(T.lead.offer._salessolution_.vppQuestions.transformerMeteringMandatory),
    optionType: VppQuestionsFormFields.SEMI_INDIRECT,
  },
  {
    question: I18n.t(T.lead.offer._salessolution_.vppQuestions.nonPhotovoltaicEnergyProducingSystemsInstalled),
    optionType: VppQuestionsFormFields.GENERATION_PLANTS,
  },
  ...insertIf(isFlatDirectConfiguration(configuration) && vppQuestionForFlatDirect,
    {
      question: I18n.t(T.lead.offer._salessolution_.vppQuestions.furtherMeterBehindSonnenFlatMeter),
      optionType: VppQuestionsFormFields.PRODUCTION_METERING_MANDATORY,
    },
  ),
];
