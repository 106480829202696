export enum BatteryModelName {
  SONNENBATTERIE_10_AC = 'Sonnenbatterie 10 AC',
  SONNENBATTERIE_10_DC = 'Sonnenbatterie 10 DC',
  SONNENBATTERIE_10_PERFORMANCE_AC = 'Sonnenbatterie 10 Performance AC',
  SONNENBATTERIE_10_PERFORMANCE_DC = 'Sonnenbatterie 10 Performance DC',
  SONNENBATTERIE_ECO_8 = 'eco 8',
  SONNENBATTERIE_ECO_7 = 'eco 7',
  SONNENBATTERIE_ECO_6 = 'eco 6',
}

export const DC_BATTERIES = [
  BatteryModelName.SONNENBATTERIE_10_DC,
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC,
];

export const PERFORMANCE_BATTERIES = [
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_AC,
  BatteryModelName.SONNENBATTERIE_10_PERFORMANCE_DC,
];

export enum AvailableEco8BatteryCapacities {
  CAPACITY_4200 = 4200,
  CAPACITY_5000 = 5000,
  CAPACITY_6300 = 6300,
  CAPACITY_7500 = 7500,
  CAPACITY_8400 = 8400,
  CAPACITY_10000 = 10000,
  CAPACITY_10500 = 10500,
  CAPACITY_12600 = 12600,
  CAPACITY_12500 = 12500,
  CAPACITY_14700 = 14700,
  CAPACITY_15000 = 15000,
  CAPACITY_16800 = 16800,
}

export enum AvailableEco7BatteryCapacities {
  CAPACITY_2100 = 2100,
  CAPACITY_4200 = 4200,
  CAPACITY_6300 = 6300,
  CAPACITY_8400 = 8400,
  CAPACITY_10500 = 10500,
  CAPACITY_12600 = 12600,
  CAPACITY_14700 = 14700,
  CAPACITY_16800 = 16800,
}

export enum AvailableEco6BatteryCapacities {
  CAPACITY_2100 = 2100,
  CAPACITY_4200 = 4200,
  CAPACITY_6300 = 6300,
  CAPACITY_8400 = 8400,
  CAPACITY_10500 = 10500,
  CAPACITY_12600 = 12600,
  CAPACITY_14700 = 14700,
  CAPACITY_16800 = 16800,
}
