import { formatTextWithSpecialCharacters } from '+utils/format.util.old';
import * as React from 'react';
import { CustomersTableAccessBadge } from '../CustomersTableAccessBadge';

import './CustomersTableName.component.scss';

interface Props {
  firstName: string;
  lastName: string;
  name: string;
  isFirst: boolean;
  isConsentGiven: boolean;
}

export const CustomersTableName: React.FC<Props> = ({
  name,
  firstName,
  lastName,
  isFirst,
  isConsentGiven,
}) => (
  <div className={'c-customers-table-name'}>
    {isFirst && (
      <>
        <div data-hj-suppress={true}>
          {lastName && firstName
            ? <>
              <div className={'c-customers-table-name__lastname'}
                dangerouslySetInnerHTML={{ __html: formatTextWithSpecialCharacters(lastName)}}
              />
              <div>{firstName}</div>
              </>
            : <div>{name}</div>
          }
        </div>
        {!isConsentGiven && <CustomersTableAccessBadge />}
      </>
    )}
  </div>
);
