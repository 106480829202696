import { T } from '@sonnen/shared-i18n/service';
import { Icon, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './DsoRegistrationFile.component.scss';

interface Props {
  name: string;
  downloadLink: string;
  blank?: boolean;
}

export const DsoRegistrationFile: React.FC<Props> = ({
  name,
  downloadLink,
  blank = true,
}) => (
    <div className={'c-dso-registration-file__row'}>
      <div className={'c-dso-registration-file__file-container'}>
        <Icon.Contract className={'c-dso-registration-file__file-icon'} />
        <Link
          label={name}
          href={downloadLink}
          isTargetBlank={true}
        />
      </div>
      <div className={'c-dso-registration-file__download'}>
        <Link
          label={I18n.t(T.setupTool.form.download)}
          href={downloadLink}
          isTargetBlank={true}
          icon={<Icon.Download className={'c-dso-registration-file__download-icon'} />}
        />
      </div>
    </div>
  );
