import { useInputEvent } from '+shared/hooks/useInputEvent';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { validateField } from '../Form.helpers';

import './FormControlledCheckbox.component.scss';

export interface FormInputProps<T> {
  name: string;
  id?: string;
  label?: React.ReactNode;
  form: FormikProps<T>;
  value?: any;
  disabled?: boolean;
  suppressErrorHighlight?: boolean;
  isConnected?: boolean;
  isConnectionActive?: boolean;
  isStatusAutomatic?: boolean;
}

const ObservableInputElement = ({ form, field, ...props }: FieldProps) => {
  const { onChange } = useInputEvent(field);

  return (
    <input
      {...props}
      {...field}
      onChange={event => {
        onChange(event);
        form.handleChange(event);
        form.validateForm();
      }}
    />
  );
};

export const FormControlledCheckbox = <T extends any = any>({
  id,
  form,
  name,
  label,
  value,
  disabled = false,
  suppressErrorHighlight = false,
  isConnected,
  isConnectionActive,
  isStatusAutomatic,
}: FormInputProps<T>) => {
  const validation = validateField({name, form});

  return (
    <div className={classNames('c-form-controlled-checkbox', {
      'c-form-controlled-checkbox--error': validation.hasError && !suppressErrorHighlight,
      'c-form-controlled-checkbox--connected': isConnected,
      'c-form-controlled-checkbox--connection-active': isConnected && isConnectionActive,
    })}>
      <Field
        component={ObservableInputElement}
        name={name}
        value={value}
        className={'c-form-controlled-checkbox__input'}
        type={'checkbox'}
        disabled={isStatusAutomatic ? true : disabled}
        id={id ?? name}
      />
      <label
        className={'c-form-controlled-checkbox__label'}
        htmlFor={id ?? name}
      >
        <span className={classNames('c-form-controlled-checkbox__box', {
          'c-form-controlled-checkbox__box--automatic': isStatusAutomatic,
        })}>
          {isStatusAutomatic ?
            (<Icon.StatusAutomatic
              className={classNames('c-form-controlled-checkbox__box-icon',
                'c-form-controlled-checkbox__box-icon--automatic')} />)
            :
            (<Icon.Checkmark className={'c-form-controlled-checkbox__box-icon'} />)
          }
        </span>
        <div className={'c-form-controlled-checkbox__text'}>
          {label}
        </div>
      </label>
    </div>
  );
};
