import { getConfigurationById } from '+app/+lead/store/+lead.helper';
import { mapActions } from '+app/utils';
import { getOpenModalId } from '+shared/store/layout/layout.selectors';
import { StoreState } from '+shared/store/store.interface';
import { T } from '@sonnen/shared-i18n/service';
import { ButtonTheme, MediaQuery, WarningText, WarningTextSize } from '@sonnen/shared-web';
import { Button } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Form, Formik } from 'formik';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { LeadOverviewVppQuestion } from '../../components/LeadOverviewVppQuestion';
import { VppQuestionsForm } from '../../containers/LeadOverviewVppCheckModal';
import { LeadOverviewPageActions } from '../../store/+overview.actions';
import { getOfferConfigurationList, getUpdatingConfigurationId } from '../../store/+overview.selectors';
import { getVppQuestions, VppQuestionsInitialValues, VppQuestionType } from './LeadOverviewVppBonusCheck.helper';

import './LeadOverviewVppBonusCheck.component.scss';

const mapStateToProps = (state: StoreState) => ({
  openModalId: getOpenModalId(state),
  configurationId: getUpdatingConfigurationId(state),
  configurations: getOfferConfigurationList(state),
});

const mapDispatchToProps = mapActions({
  recalculateConfiguration: LeadOverviewPageActions.recalculateConfiguration,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const LeadOverviewVppBonusCheckComponent: React.FC<Props> = ({
  openModalId,
  configurationId,
  actions,
  configurations,
}) => {
  const [isVppBonusCheckOpen, setIsVppBonusCheckOpen] = React.useState<boolean>(false);
  const configuration = getConfigurationById(configurations)(configurationId ?? '');
  const vppQuestions = getVppQuestions(configuration);

  const onSubmit = (values: VppQuestionsForm) => {
    // TODO: save the VPP answers
    setIsVppBonusCheckOpen(false);
  };

  return (
    <Media query={{ maxWidth: MediaQuery.DOWN_MD.maxWidth }}>
      {(isMedium: boolean) =>
        <div className={'c-lead-vpp-bonus-check'}>
          <div className={classNames('c-lead-vpp-bonus-check__start-pane', {
            'c-lead-vpp-bonus-check__start-pane--open': isVppBonusCheckOpen,
          })}>
            <div className={'c-lead-vpp-bonus-check__start-pane-texts'}>
              <p className={'c-lead-vpp-bonus-check__start-pane-title'}>
                {I18n.t(T.lead.offer._salessolution_.vppCheck.header)}
              </p>
              <p className={'c-lead-vpp-bonus-check__start-pane-description'}>
                {I18n.t(T.lead.offer._salessolution_.vppCheck.explanation)}
              </p>
            </div>
            {!isVppBonusCheckOpen &&
              <div className={'c-lead-vpp-bonus-check__start-pane-buttons'}>
                <Button
                  className={'c-lead-vpp-bonus-check__start-pane-button'}
                  label={I18n.t(T.lead.offer._salessolution_.vppCheck.noDataYet)}
                  theme={ButtonTheme.OUTLINE}
                  isBlock={isMedium}
                  // TODO: proper actions
                  onClick={() => null}
                />
                <Button
                  label={'Start'}
                  // TODO: proper additional actions
                  onClick={() => setIsVppBonusCheckOpen(true)}
                  isBlock={isMedium}
                />
              </div>
            }
          </div>

          {isVppBonusCheckOpen &&
            <Formik
              initialValues={VppQuestionsInitialValues}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={onSubmit}
              render={form => (
                <Form className={'c-lead-vpp-bonus-check__form'}>
                  <div className={'c-lead-vpp-bonus-check__questions-container'}>
                    {vppQuestions.map((vppQuestion: VppQuestionType, index: number) =>
                      <LeadOverviewVppQuestion
                        form={form}
                        question={vppQuestion.question}
                        optionType={vppQuestion.optionType}
                        key={vppQuestion.optionType}
                        questionsFormValues={{
                          semiIndirect: form.values.semiIndirect,
                          generationPlants: form.values.generationPlants,
                          productionMeteringMandatory: form.values.productionMeteringMandatory,
                        }}
                      />,
                    )}
                  </div>
                  {Object.values(form.values).includes(true) && (
                    <div className={'c-lead-vpp-bonus-check__warning'}>
                      <WarningText
                        text={I18n.t(T.lead.offer._salessolution_.vppCheck.error)}
                        size={WarningTextSize.MEDIUM}
                      />
                    </div>
                  )}
                  <div className={'c-lead-vpp-bonus-check__buttons'}>
                    <div className={'c-lead-vpp-bonus-check__buttons-wrapper'}>
                      <Button
                        className={'c-lead-vpp-bonus-check__button'}
                        label={I18n.t(T.lead.offer._salessolution_.vppCheck.noDataYet)}
                        theme={ButtonTheme.OUTLINE}
                        // TODO: proper actions
                        onClick={() => setIsVppBonusCheckOpen(false)}
                      />
                      <Button
                        className={'c-lead-vpp-bonus-check__footer-button'}
                        label={I18n.t(T.general.basicActions.save)}
                        type={'submit'}
                      />
                    </div>
                  </div>
                </Form>
              )}
            />
          }
        </div>
      }
    </Media>
  );
};

export const LeadOverviewVppBonusCheck =
  connect(mapStateToProps, mapDispatchToProps)(LeadOverviewVppBonusCheckComponent);
