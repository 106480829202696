import {
  BatteryElectricUnits,
  BatteryElectricUnitsData,
  BatteryWithProduct,
  BatteryWithProductData,
} from '+shared/store/battery';
import { BatteryStatuses, BatteryStatusesData } from '+shared/store/battery/types/batteryStatuses.interface';
import { Query } from '+shared/store/query';
import { PersistedState } from 'redux-persist';

export const GET_BATTERY_QUERY = 'getBatteryQuery';
export const GET_BATTERY_ELECTRIC_UNITS_QUERY = 'getBatteryElectricUnitsQuery';
export const PUT_BATTERY_NOTE_QUERY = 'putBatteryNoteQuery';
export const GET_BATTERY_STATUSES_QUERY = 'getBatteryStatusesQuery';

export interface BatteryPageState extends PersistedState {
  battery?: BatteryWithProduct;
  batteryElectricUnits: BatteryElectricUnits[] | [];
  [GET_BATTERY_QUERY]: Query<BatteryWithProductData>;
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: Query<BatteryElectricUnitsData>;
  [PUT_BATTERY_NOTE_QUERY]: Query<string>;
  [GET_BATTERY_STATUSES_QUERY]: Query<BatteryStatusesData>;
}

export const initialState: BatteryPageState = {
  battery: undefined,
  batteryElectricUnits: [],
  [GET_BATTERY_QUERY]: {},
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: {},
  [PUT_BATTERY_NOTE_QUERY]: {},
  [GET_BATTERY_STATUSES_QUERY]: {},
};
