import { FormStatus } from '+shared/components/FormStatus';
import { FormEvents, useTracking } from '+shared/GoogleTagManager';
import { Gtm } from '+shared/GoogleTagManager/events/event.types';
import { useErrorTracking } from '+shared/hooks/useStatusTracking';
import { T } from '@sonnen/shared-i18n/service';
import { Button, ButtonSize, DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CaseReportError.component.scss';

interface Props {
  onTryAgain: () => void;
  gtm?: Gtm;
}

export const CaseReportError: React.FC<Props> = ({
  onTryAgain,
  gtm,
}) => {
  const { track } = useTracking();

  useErrorTracking(() => { 
    if (gtm) {
      track(FormEvents.serverError({
        category: gtm.category,
        formName: gtm.formName,
        error: gtm.failureMessage || '',
      }));
    }
  }, gtm && gtm.queryStatus);

  return (
    <FormStatus
      isSuccess={false}
      headline={I18n.t(T.report.error.headline)}
      footer={
        <Button
          label={I18n.t(T.report.error.submitBtn)}
          size={ButtonSize.NORMAL}
          onClick={onTryAgain}
          className={'c-case-report-error__button'}
        />
      }
    >
      <>
        <DefaultParagraph className={'c-case-report-error__paragraph'}>
          {I18n.t(T.report.error.paragraph)}
        </DefaultParagraph>
      </>
    </FormStatus>
  );
};
