import { Contact } from '+shared/store/contact/types';
import { RoleNames } from '+shared/store/role/types/role.interface';
import { keyBy } from 'lodash/fp';

export const sortedRoles = (item: Contact) => {
  const roles = [
    RoleNames.COMPANY_COORDINATOR,
    RoleNames.SALES_AGENT,
    RoleNames.SETUP_COORDINATOR,
    RoleNames.SERVICE_COORDINATOR,
  ];
  const transformedRoles = keyBy('name', item.roles);

  return roles.map(role => transformedRoles[role] ? transformedRoles[role] : { name: role });
};
