import { FeatureName } from '+config';
import { FormInput } from '+shared/components';
import { FormControlledCheckbox } from '+shared/components/Form/FormControlledCheckbox';
import { T } from '@sonnen/shared-i18n/service';
import { Bubble, ClickOutside, Feature, Icon, PageSubheadline } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { ConfigurationForm } from '../../store/types';
import { formFields } from './LeadConfigurationConsumption.helper';

import './LeadConfigurationConsumption.component.scss';

interface Props {
  form: FormikProps<ConfigurationForm>;
}

export const LeadConfigurationConsumption: React.FC<Props> = ({ form }) => {
  const [isTooltipActive, setTooltipActivity] = React.useState<boolean>(false);

  const renderTooltip = (tooltipMsg: string) => (
    <ClickOutside
      onClick={() => setTooltipActivity(false)}
    >
      <div
        className={'c-lead-configuration-consumption__tooltip'}
        onClick={() => setTooltipActivity(!isTooltipActive)}
      >
        <Icon.Info className={'c-lead-configuration-consumption__tooltip-icon'} />
        {isTooltipActive &&
          <div className={'c-lead-configuration-consumption__tooltip-bubble'} >
            <Bubble
              side={'top-left'}
              isThemeDense={false}
            >
              {tooltipMsg}
            </Bubble>
          </div>
        }
      </div>
    </ClickOutside>
  );

  return (
    <div className={'c-lead-configuration-consumption'}>
      <div className={'c-lead-configuration-consumption__headline'}>
        <PageSubheadline>
          {I18n.t(T.lead.configuration._salessolution_.consumption.headline)}
        </PageSubheadline>
      </div>

      <div className={'c-lead-configuration-consumption__subheadline'}>
        {I18n.t(T.lead.configuration._salessolution_.consumption.mainMeter)}
      </div>

      <div className={'c-lead-configuration-consumption__inputs'}>
        {/* TOTAL CONSUMPTION PER YEAR */}
        <div className={'c-lead-configuration-consumption__input'}>
          <FormInput
            form={form}
            label={I18n.t(T.lead.configuration._salessolution_.consumption.label)}
            unit={I18n.t(T.units.kwhPerYear)}
            name={formFields.TOTAL_CONSUMPTION_PER_YEAR}
            type={'number'}
            id={'lead-configuration-total-consumption'}
          />
        </div>

        {/* LARGE CONSUMERS */}
        <Feature name={FeatureName.CONFIGURATION_LARGE_CONSUMERS}>
          <div className={'c-lead-configuration-consumption__input'}>
            <div className={'c-lead-configuration-consumption__tooltip-wrapper'}>
              <p className={'c-lead-configuration-consumption__input-label'}>
                {I18n.t(T.lead.configuration._salessolution_.largeConsumers.highConsumptionUtilitiesHeader)}
              </p>
              {renderTooltip(
                I18n.t(T.lead.configuration._salessolution_.largeConsumers.highConsumptionUtilitiesTooltip),
              )}
            </div>

            <div className={'c-lead-configuration-consumption__checkbox-wrapper'}>
              <div className={'c-lead-configuration-consumption__checkbox'}>
                <FormControlledCheckbox
                  form={form}
                  label={I18n.t(T.lead.configuration._salessolution_.largeConsumers.highConsumptionHeatPump)}
                  name={formFields.HEAT_PUMP}
                  suppressErrorHighlight={true}
                />
              </div>
              <div className={'c-lead-configuration-consumption__checkbox'}>
                <FormControlledCheckbox
                  form={form}
                  label={I18n.t(T.lead.configuration._salessolution_.largeConsumers.highConsumptionUtilitiesEV)}
                  name={formFields.E_VEHICLE}
                  suppressErrorHighlight={true}
                />
              </div>
            </div>
          </div>
        </Feature>
      </div>
    </div>
  );
};
