import { DetailList, Widget, WidgetWarning } from '+shared/components';
import { Contract, MeterInstallation } from '+shared/store/contract';
import { ContractType } from '+shared/store/contract/contract.helpers';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Icofont, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  FactorizeFlatContractDetails,
  factorizeFlatContractTypeDetailList,
  factorizeSonnenEnergyDetailList,
  factorizeSonnenStromDetailList,
  getContractWidgetHeadingMap,
} from './CustomerContractWidget.helper';

interface Props {
  contract: Contract | undefined;
  queryStatus: ReturnType<typeof getStatus>;
  className?: ClassValue;
  meterInstallation?: MeterInstallation;
  action?: () => void;
}

const renderDetailList = (data: Parameters<FactorizeFlatContractDetails>[0]) => ({
  [ContractType.SONNEN_FLAT]:
    <DetailList list={factorizeFlatContractTypeDetailList(data)}/>,
  [ContractType.SONNEN_FLAT_X]:
    <DetailList list={factorizeFlatContractTypeDetailList(data)}/>,
  [ContractType.SONNEN_STROM]:
    <DetailList list={factorizeSonnenStromDetailList(data)}/>,
  [ContractType.SONNEN_ENERGY]:
    <DetailList list={factorizeSonnenEnergyDetailList(data)}/>,
})[data.contract.contractType];

export const CustomerContractWidget: React.FC<Props> = ({
  action,
  contract,
  queryStatus,
  className,
  meterInstallation,
}) => (
  <Widget
    className={classNames('c-customer-overview__tile', className)}
    icon={<Icofont type={'contract'} />}
    // @ TODO: I18n when design will be available
    heading={contract ? getContractWidgetHeadingMap(contract) : 'Contract'}
    action={action}
  >
    {queryStatus.pending && (
      <Loader />
    )}
    {queryStatus.success && contract &&
      renderDetailList({ contract, meterInstallation })
    }
    {queryStatus.error && (
      <WidgetWarning
        type={'warning'}
        icon={'warning'}
      >
        {I18n.t(T.customerSingle.overview.sonnenFlat.error)}
      </WidgetWarning>
    )}
  </Widget>
);
