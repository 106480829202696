import { CategorySelectItem } from '+shared/store/case';

export enum FormCaseType {
  TECHNICAL_CASE = 'technicalCase',
  E_SERVICES = 'eServices',
  OTHER = 'other',
}

export interface FormCaseTypeComponentData {
  key: FormCaseType;
  label: string;
  icon: React.ReactNode;
  description: string;
  disabled?: boolean;
  topics?: CategorySelectItem[];
}

export type FormCaseTypesComponentData = {
  [key in FormCaseType]?: FormCaseTypeComponentData;
};

export enum CaseReportFormField {
  TYPE = 'type',
  TOPIC = 'topic',
  DESCRIPTION = 'description',
  ATTACHMENT = 'attachment',
  CUSTOMER_ID = 'customerId',
  CONTRACT_ID = 'contractId',
}

export interface CaseReportFormValues {
  [CaseReportFormField.TYPE]: FormCaseTypeComponentData | undefined;
  [CaseReportFormField.TOPIC]: CategorySelectItem | string;
  [CaseReportFormField.DESCRIPTION]: string;
  [CaseReportFormField.ATTACHMENT]?: string | null;
  [CaseReportFormField.CUSTOMER_ID]?: string;
  [CaseReportFormField.CONTRACT_ID]?: string;
}
