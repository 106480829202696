import {
  DsoRegistrationFormContainer,
} from '+setupTool/+form/components';
import { DsoRegistrationFormActions } from '+setupTool/+form/store/+form.actions';
import { FEED_IN_MANAGEMENT_07,
  FEED_IN_MANAGEMENT_FRE,
  MEASURING_CONCEPT_SURPLUS,
} from '+setupTool/+form/store/+form.dictionary';
import {
  getDsoRegistrationMeasuringDeviceInitial,
  getDsoRegistrationMeasuringDeviceSchema,
} from '+setupTool/+form/store/schemas';
import {
  DsoRegistrationMeasuringDeviceInterface,
} from '+setupTool/+form/store/types';
import { SetupToolActions } from '+setupTool/store/+setupTool.actions';
import { SetupDataStepKey, SetupDataStepStatus, SubmissionStep } from '+setupTool/store/+setupTool.dictionary';
import { isEmptyValue } from '+setupTool/store/+setupTool.helpers';
import { getRegistrationSubject } from '+setupTool/store/+setupTool.selectors';
import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import {
  FormInput,
  FormInputSelect,
  FormWizardStep,
} from '+shared/components';
import { FormFieldObserver } from '+shared/components/Form/FormFieldObserver';
import { LayoutActions } from '+shared/store/layout';
import { StoreState } from '+shared/store/store.interface';
import { searchByKey } from '+utils/array.util';
import { mapActions } from '+utils/redux/mapActions.util';
import { T } from '@sonnen/shared-i18n/service';
import { WizardContext } from '@sonnen/shared-web';
import { Form, Formik, FormikProps } from 'formik';
import { isEqual, omitBy } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  removeExponentialValues,
  setAllFormFieldsTouched,
} from '../../store/+form.helpers';
import {
  getActiveStep,
  getIsRadioSerialNumberFieldVisible,
  getMeasuringDeviceForm,
} from '../../store/+form.selectors';
import './DsoRegistrationMeasuringDevice.component.scss';

export const getFeedInManagement = () => [
  { name: I18n.t(T.setupTool.form.powerReductionTo70), value: FEED_IN_MANAGEMENT_07 },
  { name: I18n.t(T.setupTool.form.radioRippleControlReceiver), value: FEED_IN_MANAGEMENT_FRE },
  { name: I18n.t(T.setupTool.form.other), value: 'feed_in_management_other' },
];

export const getTypeOfGridReferenceMeter = () => [
  { name: I18n.t(T.setupTool.form.establishmentCounter), value: 'establishment_counter' },
  { name: I18n.t(T.setupTool.form.bidirectionalCounter), value: 'bidirectional_counter' },
];

export const counterSuspension = ['eHz', '3-Punkt', 'Adapter'];

export const getMeasuringConcept = () => [
  { name: I18n.t(T.setupTool.form.excessSupply), value: MEASURING_CONCEPT_SURPLUS },
  { name: I18n.t(T.setupTool.form.fullFeed), value: 'measuring_concept_full' },
];
export const getMeasuringConceptValues = () => getMeasuringConcept().map(i => i.value);

interface OwnProps {
  stepStatus?: SetupDataStepStatus;
}

const mapStateToProps = (state: StoreState) => ({
  measuringDeviceForm: getMeasuringDeviceForm(state),
  isRadioSerialNumberFieldVisible: getIsRadioSerialNumberFieldVisible(state),
  activeStep: getActiveStep(state),
  registrationSubject: getRegistrationSubject(state),
});

const mapDispatchToProps = mapActions({
  setDsoMeasuringDevice: DsoRegistrationFormActions.setDsoMeasuringDevice,
  setIsRadioSerialNumberFieldVisible: DsoRegistrationFormActions.setIsRadioSerialNumberFieldVisible,
  checkForm: DsoRegistrationFormActions.checkFormFulfillment,
  saveData: SetupToolActions.saveSubmission,
  setActiveStep: DsoRegistrationFormActions.setActiveStep,
  setSetupDataStatuses: DsoRegistrationFormActions.setSetupDataStatuses,
  toggleModal: LayoutActions.toggleModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const DsoRegistrationMeasuringDeviceComponent: React.FC<Props> = ({
  actions,
  measuringDeviceForm,
  isRadioSerialNumberFieldVisible,
  activeStep,
  stepStatus,
  registrationSubject,
}) => {
  actions.toggleModal(true);
  const wizard = React.useContext(WizardContext);
  const formRef = React.useRef<FormikProps<DsoRegistrationMeasuringDeviceInterface>>({} as any);
  const validationSchema = getDsoRegistrationMeasuringDeviceSchema(registrationSubject);
  const dsoRegistrationMeasuringDeviceInitial = getDsoRegistrationMeasuringDeviceInitial(registrationSubject);
  const feedInManagementCollection = getFeedInManagement().map(i => i.value);
  const typeOfGridReferenceMeterCollection = getTypeOfGridReferenceMeter().map(i => i.value);

  React.useEffect(() => {
    actions.setActiveStep(SubmissionStep.SETUP_DATA_MEASURING_DEVICE);
    activeStep = SubmissionStep.SETUP_DATA_MEASURING_DEVICE;

    return saveValues;
  }, []);

  React.useEffect(() => {
    if (!formRef.current) {
      return;
    }
    let values = formRef.current.values;

    if (measuringDeviceForm) {
      values = {
        ...values,
        ...omitBy(measuringDeviceForm, isEmptyValue),
      };
    }

    formRef.current.setValues(values);

    if (measuringDeviceForm) {
      toggleRadioSerialNumberFieldVisible(measuringDeviceForm.feed_in_management);
    }
    if (stepStatus === SetupDataStepStatus.STARTED) {
      setAllFormFieldsTouched(formRef.current);
    }
  }, [measuringDeviceForm]);

  const onSubmit = () => {
    if (wizard.next) {
      wizard.next();
      document.body.classList.add('body--disabled');
    }
  };

  const saveValues = () => {
    if (!formRef.current) {
      return;
    }
    const values = formRef.current.values;
    const invalidFieldKeys = Object.keys(formRef.current.errors);
    const atLeastOneFieldTouched = Object.keys(formRef.current.touched).length > 0;
    const newStepStatus = invalidFieldKeys.length === 0 ? SetupDataStepStatus.COMPLETED : SetupDataStepStatus.STARTED;
    const invalidOrEmptyValues = (invalidFieldKeys || []).reduce((prev, fieldKey) =>
      ({...prev, [fieldKey]: dsoRegistrationMeasuringDeviceInitial[fieldKey]}), {});

    if (atLeastOneFieldTouched && measuringDeviceForm
      && !isEqual(
        omitBy(measuringDeviceForm, isEmptyValue),
        omitBy(values, isEmptyValue),
      )
    ) {
      actions.setSetupDataStatuses({ measuringDevice: newStepStatus });
      actions.setDsoMeasuringDevice({ ...values, ...invalidOrEmptyValues });
      actions.checkForm(registrationSubject);
      actions.saveData(activeStep, { [SetupDataStepKey.MEASURING_DEVICE]: newStepStatus });
    } else if (atLeastOneFieldTouched && newStepStatus !== stepStatus) {
      actions.setSetupDataStatuses({ measuringDevice: newStepStatus });
      actions.saveData(activeStep, { [SetupDataStepKey.MEASURING_DEVICE]: newStepStatus });
    }
  };

  const toggleRadioSerialNumberFieldVisible = (value?: string) => {
    actions.setIsRadioSerialNumberFieldVisible(value === FEED_IN_MANAGEMENT_FRE);
  };

  return (
    <Formik
      initialValues={dsoRegistrationMeasuringDeviceInitial}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={true}
      onSubmit={onSubmit}
      innerRef={formRef}
      render={form => (
        <Form className={'c-form-wizard__form'}>
          <FormWizardStep
            onBeforeSubmit={() => {
              // Enable saving partially complete data
              onSubmit();
            }}
          >
            <DsoRegistrationFormContainer>
              <FormInput
                className={'c-setup-tool-measuring-device__item'}
                form={form}
                label={I18n.t(T.setupTool.form.meterNumber)}
                name={'meter_number'}
              />

              <FormInputSelect
                className={'c-setup-tool-measuring-device__item'}
                form={form}
                label={I18n.t(T.setupTool.form.typeOfGridReferenceMeter)}
                collection={typeOfGridReferenceMeterCollection}
                id={'setup-tool-measuring-device__type_of_grid_reference_meter'}
                name={'type_of_grid_reference_meter'}
                mapper={key => searchByKey('value', key, getTypeOfGridReferenceMeter(), 'name')}
                placeholder={I18n.t(T.setupTool.selectPlaceholder)}
              />

              {registrationSubject !== RegistrationSubjectType.BATTERY && <>
                <FormInputSelect
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.measuringConcept)}
                  collection={getMeasuringConceptValues()}
                  id={'setup-tool-measuring-device__measuring_concept'}
                  name={'measuring_concept'}
                  mapper={key => searchByKey('value', key, getMeasuringConcept(), 'name')}
                />

                <FormInputSelect
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.feedInManagement)}
                  collection={feedInManagementCollection}
                  id={'setup-tool-measuring-device__feed_in_management'}
                  name={'feed_in_management'}
                  mapper={key => searchByKey('value', key, getFeedInManagement(), 'name')}
                  onSelect={toggleRadioSerialNumberFieldVisible}
                />
              </>}

              {isRadioSerialNumberFieldVisible && <FormInput
                className={'c-setup-tool-measuring-device__item c-setup-tool-measuring-device__item--margin-right'}
                form={form}
                label={`${I18n.t(T.setupTool.form.radioRippleControlReceiverSerialNumber)} \
                  (${I18n.t(T.setupTool.form.optional)})`}
                name={'serial_number_FRE'}
              />}

              <FormFieldObserver<DsoRegistrationMeasuringDeviceInterface>
                onChange={() => removeExponentialValues(form, 'house_connection_power_A')}>
                <FormInput
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.houseConnectionPower)}
                  name={'house_connection_power_A'}
                  id={'house_connection_power_A'}
                  unit={'A'}
                  type={'number'}
                />
              </FormFieldObserver>

              <FormFieldObserver<DsoRegistrationMeasuringDeviceInterface>
                onChange={() => removeExponentialValues(form, 'meter_fuse')}>
                <FormInput
                  className={'c-setup-tool-measuring-device__item'}
                  form={form}
                  label={I18n.t(T.setupTool.form.meterFuse)}
                  name={'meter_fuse'}
                  id={'meter_fuse'}
                  unit={'A'}
                  type={'number'}
                />
              </FormFieldObserver>

              <FormInputSelect
                className={'c-setup-tool-measuring-device__item'}
                form={form}
                label={I18n.t(T.setupTool.form.meterFasteningType)}
                id={'setup-tool-measuring-device__meter_fastening_type'}
                name={'meter_fastening_type'}
                collection={counterSuspension}
                placeholder={I18n.t(T.setupTool.selectPlaceholder)}
              />
            </DsoRegistrationFormContainer>
          </FormWizardStep>
        </Form>
      )}
    />
  );
};

export const DsoRegistrationMeasuringDevice = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DsoRegistrationMeasuringDeviceComponent);
