import { LeadForm } from '+app/+lead/store/types';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Loader } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

interface NoResultsProps {
  onClick: () => void;
}

export const NoResults: React.FC<NoResultsProps> = ({ onClick }) => (
  <>
    <p className={'c-lead-autosuggested-address__no-results'}>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.cantFindAddress)}
    </p>
    <p className={'c-lead-autosuggested-address__btn'} onClick={onClick}>
      {I18n.t(T.lead.boc._salessolution_.form.personalDetails.addManually)}
    </p>
  </>
);

export const isAutosuggestedAddress = (form: FormikProps<LeadForm>) =>
  form.values.autosuggestedAddress === undefined || form.values.autosuggestedAddress === '';

export const renderNoResultsComponent = (
  form: FormikProps<LeadForm>,
  debouncedSearchValue: string,
  queryStatus: ReturnType<typeof getStatus>,
  switchToManualAddress: () => void,
) => {
  if (queryStatus.pending || form.values.autosuggestedAddress !== debouncedSearchValue) {
    return <Loader />;
  }

  if (isAutosuggestedAddress(form)) {
    return <>{I18n.t(T.lead.offer._salessolution_.utilityData.placeholder)}</>;
  }

  return <NoResults onClick={switchToManualAddress} />;
};

export const clearAddressFields = (form: FormikProps<LeadForm>) => {
  form.setFieldValue('deliveryAddress.street', '');
  form.setFieldValue('deliveryAddress.houseNumber', '');
  form.setFieldValue('deliveryAddress.zipCode', '');
  form.setFieldValue('deliveryAddress.city', '');
};
