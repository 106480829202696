import * as classNames from 'classnames';
import * as React from 'react';
import { seriesKeyTranslationMap } from '../../store/helpers/tooltip.helpers';

import './AnalysisTooltipItem.component.scss';

interface Props {
  value: string | number;
  seriesKey: string;
  inline?: boolean;
  color: string;
  style: string;
}

export type AnalysisTooltipItem = Props;

export const AnalysisTooltipItem: React.FC<Props> = ({
  seriesKey, color, style, value, inline,
}) => (
  <div className={'c-analysis-tooltip-item'}>
    <div className={'c-analysis-tooltip-item__inner'}>
      <div
        className={`c-analysis-tooltip-item__symbol c-analysis-tooltip-item__symbol--${style}`}
        style={{ background: color }}
      />
      <p className={classNames('c-analysis-tooltip-item__text', {
        'c-analysis-tooltip-item__text--inline': inline,
      })}>
        <span className={'c-analysis-tooltip-item__value'}>
          {value}
        </span>
        <span className={'c-analysis-tooltip-item__label'}>
          {seriesKeyTranslationMap(seriesKey)}
        </span>
      </p>
    </div>
  </div>
);
