import { CountryFeatureName } from '+config/countryFlags';
import { CaseSite } from '+shared/store/case/types/caseSite.interface';
import { T } from '@sonnen/shared-i18n/service';
import { useCountryFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CaseReportSearchResultsItem.component.scss';

interface Props {
  item: CaseSite;
  handleClick: () => void;
}

export const CaseReportSearchResultsItem: React.FC<Props> = ({ item, handleClick }) => {
  const { name, address, battery, contract } = item;
  const isCustomerContractFeatureEnabled = useCountryFeature(CountryFeatureName.CUSTOMER_CONTRACT).isEnabled;

  return (
    <div
      className={'c-case-report-search-results-item'}
      onClick={handleClick}
    >
      <div className={'c-case-report-search-results-item__customer'} data-hj-suppress={true}>
        <div className={'c-case-report-search-results-item__name'}>{name}</div>
        <div className={'c-case-report-search-results-item__address'}>{address}</div>
      </div>
      <div className={'c-case-report-search-results-item__asset'}>
        Battery SN: {/* TODO translations */}
        <span className={'c-case-report-search-results-item__number'}>{battery ? battery.serialNumber : '-'}</span>
      </div>
      {isCustomerContractFeatureEnabled && (
        <div className={'c-case-report-search-results-item__asset'}>
          Contract No: {/* TODO translations */}
          <span className={'c-case-report-search-results-item__number'}>{contract ? contract.number : '-'}</span>
        </div>
      )}
    </div>
  );
};
