import { DatepickerArrowSide, FormCheckbox, FormInput, FormInputDate, FormInputRadioGroup } from '+shared/components';
import { FormInputInfo } from '+shared/components/FormInputInfo';
import { ConfigurationPvType } from '+shared/store/lead/types';
import { StoreState } from '+shared/store/store.interface';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/service';
import { breakpointUp, LinkButton, WarningText } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ConfigurationPageActions } from '../../store';
import {
  getConfigurationPeakPowerHint,
  getConfigurationProposal,
  getConfigurationRecommendationSubmitQueryStatus,
} from '../../store/+configuration.selectors';
import { ConfigurationForm } from '../../store/types';
import { isProposalForPostEeg } from '../LeadConfigurationForm/LeadConfigurationForm.helper';
import {
  formFields,
  getFormFieldsForEachPv,
  getMultiplePvLabelNumber,
  minCommissioningDate,
  PvKind,
} from './LeadConfigurationPv.helper';

import './LeadConfigurationPv.component.scss';

const mapStateToProps = (state: StoreState) => ({
  peakPowerHint: getConfigurationPeakPowerHint(state),
  getConfigurationRecommendationSubmitQueryStatus: getConfigurationRecommendationSubmitQueryStatus(state),
  configProposal: getConfigurationProposal(state),
});

const mapDispatchToProps = mapActions({
  createRecommendation: ConfigurationPageActions.createRecommendation,
  setupClearConfigurationHint: ConfigurationPageActions.setupClearConfigurationHint,
});

interface ComponentProps {
  form: FormikProps<ConfigurationForm>;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps;

export const LeadConfigurationPvComponent: React.FC<Props> = ({
  form,
  actions,
  configProposal,
}) => {
  const switchView = (type: ConfigurationPvType, pvKind: PvKind) => {
    form.setFieldValue(getFormFieldsForEachPv(pvKind).pvType, type);
    actions.setupClearConfigurationHint();

    actions.createRecommendation({
      ...form.values,
      expectedAutarky: configProposal && configProposal.powerPlant.expectedAutarky?.value,
      pvType: type,
    });
  };

  const isFieldError = (field: string) => form.errors[field] && form.touched[field];
  const isFormMultiplePv = form.values.multiplePv;

  const renderYieldOrInclinationOrientationFields = (pvKind: PvKind) =>
    form.values[getFormFieldsForEachPv(pvKind).pvType] === ConfigurationPvType.SPECIFIC_YIELD ? (
      <>
        <div className={'c-lead-configuration-pv__input'}>
          {/* PV SPECIFIC YIELD PER YEAR */}
          <FormInput
            form={form}
            label={I18n.t(T.lead.configuration._salessolution_.pv.specificYield)
              + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
            unit={I18n.t(T.units.kwhPerKwpPerYear)}
            name={getFormFieldsForEachPv(pvKind).specificYield}
            type={'number'}
            hasNoGap={true}
            id={`lead-configuration-specific-yield-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
          />
        </div>
        <div className={classNames('c-lead-configuration-pv__message-yield', {
          'c-lead-configuration-pv__message-yield--error':
            isFieldError(getFormFieldsForEachPv(pvKind).specificYield),
        })}>
          <FormInputInfo>
            {I18n.t(T.lead._salessolution_.configurations.switchToYieldMessage)}{' '}
            <LinkButton
              className={'c-lead-configuration-pv__btn'}
              onClick={() => switchView(ConfigurationPvType.DETAILED, pvKind)}
            >
              {I18n.t(T.lead._salessolution_.configurations.switchToYieldButton)}
            </LinkButton>
          </FormInputInfo>
        </div>
      </>
    ) : (
        <Media query={{ minWidth: breakpointUp('SM') }}>
          {(isDesktopMedia: boolean) => (
            <>
              <div className={'c-lead-configuration-pv__input-group'}>
                {/* PV INCLINATION */}
                <div
                  className={classNames(
                    'c-lead-configuration-pv__input',
                    'c-lead-configuration-pv__inclination-input', {
                    'c-lead-configuration-pv__inclination-input--error':
                      isFieldError(getFormFieldsForEachPv(pvKind).inclination),
                  },
                  )}
                >
                  <FormInput
                    form={form}
                    info={I18n.t(T.lead.configuration._salessolution_.pv.inclinationInfo)}
                    label={I18n.t(T.lead.configuration._salessolution_.pv.inclination)
                      + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
                    unit={I18n.t(T.units.degree)}
                    name={getFormFieldsForEachPv(pvKind).inclination}
                    type={'number'}
                    hasNoGap={true}
                    id={`lead-configuration-inclination-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
                  />
                </div>

                {/* PV ORIENTATION */}
                <div className={'c-lead-configuration-pv__input'}>
                  <FormInput
                    form={form}
                    label={I18n.t(T.lead.configuration._salessolution_.pv.orientation)
                      + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
                    info={I18n.t(T.lead.configuration._salessolution_.pvOrientation.info)}
                    unit={I18n.t(T.units.degree)}
                    name={getFormFieldsForEachPv(pvKind).orientation}
                    type={'number'}
                    hasNoGap={!isDesktopMedia || !isFieldError(getFormFieldsForEachPv(pvKind).inclination)}
                    id={`lead-configuration-orientation-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
                  />
                </div>
              </div>

              <div className={classNames('c-lead-configuration-pv__message-inclination', {
                'c-lead-configuration-pv__message-inclination--error':
                  isFieldError(formFields.ORIENTATION) ||
                  (isDesktopMedia && isFieldError(getFormFieldsForEachPv(pvKind).inclination)),
              })}>
                <FormInputInfo>
                  {I18n.t(T.lead._salessolution_.configurations.switchToRoofDetailsMessage)}{' '}
                  <LinkButton
                    className={'c-lead-configuration-pv__btn'}
                    onClick={() => switchView(ConfigurationPvType.SPECIFIC_YIELD, pvKind)}
                  >
                    {I18n.t(T.lead._salessolution_.configurations.switchToRoofDetailsButton)}
                  </LinkButton>
                </FormInputInfo>
              </div>
            </>
          )}
        </Media>
      );

  const renderCommissioningDate = (pvKind: PvKind) =>
    <Media query={{ minWidth: breakpointUp('MD') }}>
      {(isDesktopMedia: boolean) => (
        <div className={classNames('c-lead-configuration-pv__input', {
          'c-lead-configuration-pv__second-pv-datepicker': pvKind === PvKind.SECOND_PV,
        })}>
          <FormInputDate
            tooltipInfo={isFormMultiplePv
              ? I18n.t(T.lead.configuration._salessolution_.pv.commissioningDateTooltip) : undefined}
            minDate={minCommissioningDate(isFormMultiplePv)}
            form={form}
            label={I18n.t(T.lead.configuration._salessolution_.pv.commissioningDate)
              + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
            name={getFormFieldsForEachPv(pvKind).commissioningDate}
            id={`lead-configuration-commissioning-date-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
            arrowPosition={isDesktopMedia ? DatepickerArrowSide.TOP : DatepickerArrowSide.TOP_RIGHT}
          />
        </div>
      )}
    </Media>;

  const renderPvSystemPower = (pvKind: PvKind) => (
    <div className={'c-lead-configuration-pv__input'}>
      <FormInput
        form={form}
        label={I18n.t(T.lead.configuration._salessolution_.pv.systemPower)
          + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
        unit={I18n.t(T.units.kwp)}
        name={pvKind === PvKind.FIRST_PV ? formFields.PEAK_POWER : formFields.SECOND_PV_PEAK_POWER}
        type={'number'}
        hasNoGap={true}
        id={`lead-configuration-pv-power-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
      />
    </div>
  );

  const renderPvSystemName = (pvKind: PvKind) => (
    <>
      <p className={'c-lead-configuration-pv__pv-system-name-wrapper'}>
        {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.pvSystemNameHeadline)}
      </p>
      <div className={'c-lead-configuration-pv__input'}>
        <FormInput
          form={form}
          label={I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.pvSystemName)
            + ' ' + getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}
          name={pvKind === PvKind.FIRST_PV ? formFields.FIRST_PV_SYSEM_NAME : formFields.SECOND_PV_SYSEM_NAME}
          type={'text'}
          hasNoGap={true}
          id={`lead-configuration-system-name-${getMultiplePvLabelNumber(isFormMultiplePv, pvKind)}`}
        />
      </div>
    </>);

  return (
    <div className={'c-lead-configuration-pv'}>
        {/* MULTIPLE PV RADIO BUTTON */}
        <p className={'c-lead-configuration-pv__new-battery-question'}>
          {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.question)}
        </p>
        <div className={'c-lead-configuration-pv__new-battery-radio-btns'}>
          <FormInputRadioGroup
            name={formFields.MULTIPLE_PV}
            form={form}
            collection={[
              {
                label: I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.onePv),
                value: false,
              },
              {
                label: I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.twoPvs),
                value: true,
              },
            ]}
          />
        </div>

      {/* FIRST PV SYSTEM */}
      {isFormMultiplePv ?
        <>
          <div className={'c-lead-configuration-pv__multiple-pv-warning'}>
            <WarningText text={I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.warning)} />
          </div>

          <p className={'c-lead-configuration-pv__multiple-pv-header'}>
            {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.firstPvSystem)}
          </p>
        </>
        : null}
      <div className={'c-lead-configuration-pv__columns-wrapper'}>
        <div className={'c-lead-configuration-pv__column'}>
          {renderYieldOrInclinationOrientationFields(PvKind.FIRST_PV)}

          {isFormMultiplePv ?
            renderPvSystemPower(PvKind.FIRST_PV)
            : null}
        </div>
        <div className={'c-lead-configuration-pv__column'}>
          {renderCommissioningDate(PvKind.FIRST_PV)}
        </div>
      </div>

      {isFormMultiplePv ? renderPvSystemName(PvKind.FIRST_PV) : null}

      {!isFormMultiplePv && isProposalForPostEeg(form.values[formFields.COMMISSIONING_DATE]) &&
        <div className={'c-lead-configuration-pv__post-eeg-warning'}>
          <WarningText text={
            I18n.t(T.lead.configuration._salessolution_.pv.postEegWarning)} />
        </div>
      }

      {/* SECOND PV SYSTEM */}
      {isFormMultiplePv ?
        <>
          <div className={'c-lead-configuration-pv__multiple-pv-separator'} />

          <p className={'c-lead-configuration-pv__multiple-pv-header'}>
            {I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.secondPvSystem)}
          </p>
          <div className={'c-lead-configuration-pv__columns-wrapper'}>
            <div className={'c-lead-configuration-pv__column'}>
              {renderYieldOrInclinationOrientationFields(PvKind.SECOND_PV)}

              {renderPvSystemPower(PvKind.SECOND_PV)}
            </div>

            <div className={'c-lead-configuration-pv__column'}>
              {renderCommissioningDate(PvKind.SECOND_PV)}
            </div>
          </div>

          {renderPvSystemName(PvKind.SECOND_PV)}

          {!isFormMultiplePv && isProposalForPostEeg(form.values[formFields.SECOND_PV_COMMISSIONING_DATE]) &&
            <div className={'c-lead-configuration-pv__post-eeg-warning'}>
              <WarningText text={
                I18n.t(T.lead.configuration._salessolution_.pv.postEegWarning)} />
            </div>
          }

          <div className={'c-lead-configuration-pv__dso-checkbox'}>
            <FormCheckbox
              form={form}
              label={I18n.t(T.lead.configuration._salessolution_.pv.multiplePvs.dsoCheckbox)}
              name={formFields.DSO_CONSENT_TO_COMBINE_PHOTOVOLTAIC_SYSTEMS}
            />
          </div>
        </>
        : null
      }
    </div>
  );
};

export const LeadConfigurationPv = connect(mapStateToProps, mapDispatchToProps)(LeadConfigurationPvComponent);
