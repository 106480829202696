import { mapLeadErrorTitleToTranslationKey } from '+lead/containers/LeadNew/LeadNew.helper';
import { Lead, LeadConfigurationResponse, LeadCustomerType, LeadPatchDto } from '+shared/store/lead/types';
import { LeadStatusName } from '+shared/store/lead/types/leadStatus.interface';
import { Query } from '+shared/store/query';
import { getStatus } from '+shared/store/query/query.utils';
import { T } from '@sonnen/shared-i18n/service';
import { Bubble, BubbleSideProps, ClickOutside, Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { isEmpty, isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';
import { FormErrorBanner } from '../Form/FormErrorBanner';

import './DetailListTable.component.scss';

interface ListProps {
  list: Array<{
    id?: string,
    label: string,
    value: React.ReactNode | LeadCustomerType,
    editableFields?: Partial<LeadPatchDto>,
    editableUntilStatus?: LeadStatusName;
    isFilledField?: boolean;
    tooltipText?: string;
    isTooltipWide?: boolean;
    isClickableTooltip?: boolean;
    isHoverableTooltip?: boolean;
    tooltipBubbleSide?: BubbleSideProps;
    customEditHandle?: () => void;
  }>;
  filterNil?: boolean;
  renderEditComponent?: (obj: { index: number; id?: string }, onClose: () => void) => React.ReactNode;
  lead?: Lead;
  leadUpdateQueryStatus?: ReturnType<typeof getStatus>;
  leadUpdateQuery?: Query<LeadConfigurationResponse>;
  hasBottomBorder?: boolean;
  isGeneralErrorMsg?: boolean;
  generalErrorMsg?: string;
  editItemId?: string;
  suppressRowValuesInHotjar?: boolean;
  isValueEllipsis?: boolean;
}

interface RowProps {
  value: React.ReactNode;
  isEditable: boolean;
  index: number;
  isFilledField: boolean | undefined;
  tooltipText?: string;
  isTooltipWide: boolean;
  isClickableTooltip?: boolean;
  isHoverableTooltip?: boolean;
  tooltipBubbleSide?: BubbleSideProps;
  customEditHandle?: () => void;
  suppressRowValuesInHotjar?: boolean;
  isValueEllipsis?: boolean;
}

interface Error {
  title: string;
}

export const DetailListTable: React.FC<ListProps> = ({
  list,
  filterNil,
  renderEditComponent,
  lead,
  leadUpdateQueryStatus,
  leadUpdateQuery,
  hasBottomBorder = true,
  isGeneralErrorMsg,
  generalErrorMsg,
  editItemId,
  suppressRowValuesInHotjar,
  isValueEllipsis,
}) => {
  const [editedRow, setEditedRow] = React.useState<number>(-1);
  const exitEditMode = () => setEditedRow(-1);
  const [hasEditOpened, setHasEditOpened] = React.useState(false);
  const [leadErrors, setLeadErrors] = React.useState<string[]>([]);
  const [activeTooltipIndex, setActiveTooltipIndex] = React.useState<number | false>(false);

  const tableItems = filterNil ? list.filter(item => !isNil(item.value)) : list;

  React.useEffect(() => {
    if (!editItemId || hasEditOpened) {
      return;
    }

    const index = tableItems.findIndex(item => item.id === editItemId);

    if (index !== -1) {
      setEditedRow(index);
      setHasEditOpened(true);
    }
  }, [list, editItemId]);

  React.useEffect(() => {
    if (leadUpdateQueryStatus && leadUpdateQueryStatus.success) {
      exitEditMode();
    }
  }, [leadUpdateQueryStatus]);

  React.useEffect(() => {
    if (leadUpdateQuery && leadUpdateQuery.error) {
      leadUpdateQuery.error.response.parsedBody().then(res => {
        const filteredErrors = res.errors
          .filter((error: Error) => !isEmpty(error.title))
          .map(({ title }: Error) => mapLeadErrorTitleToTranslationKey(title || ''))
          .filter(
            // only unique keys
            (v: string, i: number, a: string[]) => a.indexOf(v) === i,
          );
        setLeadErrors(filteredErrors);
      });
    }
  }, [leadUpdateQuery]);

  const renderTooltip = (
    rowIndex: number,
    tooltipText: string,
    isTooltipWide: boolean,
    isClickableTooltip: boolean,
    isHoverableTooltip: boolean,
    tooltipBubbleSide: BubbleSideProps,
  ) => (
      <ClickOutside
        onClick={() => setActiveTooltipIndex(false)}
      >
        <div
          className={'c-detail-list-table__tooltip'}
          onClick={() => {
            if (isClickableTooltip) {
              setActiveTooltipIndex(activeTooltipIndex ? false : rowIndex);
            }
          }}
          onMouseEnter={() => {
            if (isHoverableTooltip) {
              setActiveTooltipIndex(rowIndex);
            }
          }}
          onMouseLeave={() => {
            if (isHoverableTooltip) {
              setActiveTooltipIndex(false);
            }
          }}
        >
          <Icon.Info className={'c-detail-list-table__info-icon'} />
          {activeTooltipIndex === rowIndex &&
            <div className={classNames('c-detail-list-table__bubble-wrapper', {
              'c-detail-list-table__bubble-wrapper--wide': isTooltipWide,
            })} >
              <Bubble
                side={tooltipBubbleSide}
                isThemeDense={false}
              >
                {tooltipText}
              </Bubble>
            </div>
          }
        </div>
      </ClickOutside>
    );

  const DataListTableRow: React.FC<RowProps> = ({
    value,
    isEditable,
    index,
    isFilledField,
    tooltipText,
    isTooltipWide,
    isClickableTooltip = true,
    isHoverableTooltip = false,
    tooltipBubbleSide = 'top-right',
    customEditHandle,
    suppressRowValuesInHotjar = false,
    isValueEllipsis = true,
  }) => (
      <div className={classNames('c-detail-list-table__item-value', {
        'c-detail-list-table__item-value--mandatory': isFilledField === false,
      })}>
        <div
          className={classNames('c-detail-list-table__value', {
            'c-detail-list-table__value--ellipsis': isValueEllipsis,
          })}
          data-hj-suppress={suppressRowValuesInHotjar}
        >
          {value}
        </div>
        <div className={'c-detail-list-table__actions'}>
          {isEditable &&
            <span
              onClick={customEditHandle ? customEditHandle : () => setEditedRow(index)}
            >
              <Icon.Pen className={'c-detail-list-table__edit-icon'} />
            </span>
          }
          {tooltipText &&
          renderTooltip(index, tooltipText, isTooltipWide, isClickableTooltip, isHoverableTooltip, tooltipBubbleSide)
          }
        </div>
      </div>
    );

  return (
    <>
      {isGeneralErrorMsg &&
        <p className={'c-detail-list-table__general-error'}>{generalErrorMsg}</p>
      }
      <ul className={classNames('c-detail-list-table', {
        'c-detail-list-table--no-bottom-border': !hasBottomBorder,
      })}>
        {tableItems.map((
          {
            label,
            value,
            editableFields,
            editableUntilStatus,
            isFilledField,
            id,
            tooltipText,
            isTooltipWide = false,
            isClickableTooltip,
            isHoverableTooltip,
            tooltipBubbleSide,
            customEditHandle,
          },
          index,
        ) => {
          const hasNoLeadStatusLimit = editableUntilStatus
            ? lead !== undefined && lead.status.summary[editableUntilStatus] === null
            : true;

          const isEditable = !isEmpty(editableFields)
            && !isNil(renderEditComponent)
            && hasNoLeadStatusLimit;

          return (
            <li
              key={id || uuid.v4()}
              className={classNames('c-detail-list-table__item', {
                'c-detail-list-table__item--with-tooltip': tooltipText,
              })}
            >
              {isEditable && editedRow === index ?
                <div className={'c-detail-list-table__item-title'}>
                  <p
                    dangerouslySetInnerHTML={{ __html: label }}
                  />

                  {leadErrors ?
                    leadErrors
                      .map(translationKey => (
                        <FormErrorBanner
                          key={translationKey}
                          isVisible={leadUpdateQueryStatus ? leadUpdateQueryStatus.error : false}
                          error={I18n.t(translationKey)}
                          toLeft={true}
                        />
                      ))
                    : <FormErrorBanner
                      isVisible={leadUpdateQueryStatus ? leadUpdateQueryStatus.error : false}
                      error={I18n.t(T.lead.boc._salessolution_.form.generalValidationError)}
                      toLeft={true}
                    />
                  }
                </div>
                :
                <p
                  className={'c-detail-list-table__item-title'}
                  dangerouslySetInnerHTML={{ __html: label }}
                />
              }
              {editedRow !== index ? (
                <DataListTableRow
                  value={value}
                  isEditable={isEditable}
                  index={index}
                  isFilledField={isFilledField}
                  tooltipText={tooltipText}
                  isTooltipWide={isTooltipWide}
                  isClickableTooltip={isClickableTooltip}
                  isHoverableTooltip={isHoverableTooltip}
                  tooltipBubbleSide={tooltipBubbleSide}
                  customEditHandle={customEditHandle}
                  suppressRowValuesInHotjar={suppressRowValuesInHotjar}
                  isValueEllipsis={isValueEllipsis}
                />
              ) : (
                  <div className={'c-detail-list-table__edit-container'}>
                    {renderEditComponent && renderEditComponent({ index, id }, exitEditMode)}
                  </div>
                )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
