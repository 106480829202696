import { RegistrationSubjectType } from '+setupTool/store/types/setupToolDashboard.interface';
import { T } from '@sonnen/shared-i18n/service';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import {
  DsoRegistrationSonnenBatteryInterface,
} from '../types';

export const DsoRegistrationSonnenBatterySchemaPvAndBattery = () => Yup.object().shape({
  battery_name: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  battery_id: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  battery_nominal_capacity_kWh: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
});

export const DsoRegistrationSonnenBatterySchemaBatteryOnly = () => Yup.object().shape({
  battery_name: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  battery_id: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  battery_nominal_capacity_kWh: Yup
    .string()
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
  preferred_installation_date: Yup
    .string()
    .test(
      'invalidDateFormat',
      I18n.t(T.setupTool.validation.invalidDateFormat),
      value => !!value ? moment(value, 'YYYY-MM-DD', true).isValid() : true,
    ).test(
      'earliestDate',
      I18n.t(T.setupTool.validation.earliestDate, {date: '01.01.1900'}),
      value => !!value ? moment(value).isAfter('1899-12-31') : true,
    ).test(
      'invalidDateNewerThan',
      I18n.t(T.setupTool.validation.invalidDateNewerThan, {years: 2}),
      value => !!value ? moment().diff(moment(value), 'years') > -2 : true,
    )
    .trim()
    .required(I18n.t(T.register.alert.requiredField)),
});

export const getDsoRegistrationSonnenBatterySchema = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationSonnenBatterySchemaPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationSonnenBatterySchemaBatteryOnly;
      break;
    default:
      return DsoRegistrationSonnenBatterySchemaPvAndBattery;
  }
};

export const DsoRegistrationSonnenBatteryInitialPvAndBattery: DsoRegistrationSonnenBatteryInterface = {
  battery_name: '',
  battery_id: '',
  battery_nominal_capacity_kWh: '',
};

export const DsoRegistrationSonnenBatteryInitialBatteryOnly: DsoRegistrationSonnenBatteryInterface = {
  battery_name: '',
  battery_id: '',
  battery_nominal_capacity_kWh: '',
  preferred_installation_date: '',
};

export const getDsoRegistrationSonnenBatteryInitial = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return DsoRegistrationSonnenBatteryInitialPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return DsoRegistrationSonnenBatteryInitialBatteryOnly;
      break;
    default:
      return DsoRegistrationSonnenBatteryInitialPvAndBattery;
  }
};

export const saveableSonnenBatteryFieldsPvAndBattery = [
  'battery_name',
  'battery_id',
  'battery_nominal_capacity_kWh',
];

export const saveableSonnenBatteryFieldsBatteryOnly = [
  'battery_name',
  'battery_id',
  'battery_nominal_capacity_kWh',
  'preferred_installation_date',
];

export const getSaveableSonnenBatteryFields = (registrationSubjectType?: RegistrationSubjectType) => {
  switch (registrationSubjectType) {
    case RegistrationSubjectType.PV_AND_BATTERY:
      return saveableSonnenBatteryFieldsPvAndBattery;
      break;
    case RegistrationSubjectType.BATTERY:
      return saveableSonnenBatteryFieldsBatteryOnly;
      break;
    default:
      return saveableSonnenBatteryFieldsPvAndBattery;
  }
};
